<app-load-dropdown-data [formName]="formName" (fillDropdownData)="fillDropdownData()"></app-load-dropdown-data>

<div class="row d-flex flex-column mx-0 gap-2 "  [ngClass]="[data?.elementsToHide?'fullheight':'h-100']" >
    <div class="col flex-fill h-0 p-0">
        <app-ag-grid 
            [dataModel]="agGridDataModel"
            [columnSettings]="true"
            (gridReady)="onGridReady($event)"
            >
        </app-ag-grid>
    </div>
</div>

