import { AgColDefsI, AgGridI } from "../../../../../shared/common-components/ag-grid/ag-grid.model";
import { AmountColWidth, NameColWidth, ProductColWidth, StorageName, VoucherColWidth, WeightColWidth } from "../../../../../shared/enum/common-enum";
import { TextRendererComponent } from "../../../../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { DateCellRendererComponent } from "../../../../../shared/common-components/ag-grid/date-cell-renderer/date-cell-renderer.component";
import { NumericCellRendererComponent } from "../../../../../shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { API_URL_GET_ACCOUNTLEDGERREPORT_GET_ACCOUNT_LEDGER_DETAIL_BY_LEDGERID } from "../../../../../shared/url-helper/url-helper";
import { AgGridButtonCellrendererComponent } from "../../../../../shared/common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.component";
import { exportRequest } from "../../../../report/report.model";


export class AccountLedgerAmtInfoRequestModel {
    ledgerId: number = 0
    filters          : string ;
    noOfDecimalPlace: number=3;
    fromDate: string = null;
    toDate: string = null;
    exportAs        : number;
    getAll        : boolean = false;
    exportRequest : exportRequest[];
  }
  

const SRColumn: AgColDefsI = {
    headerName: "Sr No",
    field: "srNo",
    valueGetter: 'node.rowIndex + 1',
    minWidth: 60,
    maxWidth: 60,
    sortable: false,
    editable: false,
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
  };
const LedgerNameColumn: AgColDefsI = {
    headerName: "Ledger",
    field: "ledgerName",
    minWidth: ProductColWidth.MinWidth,
    maxWidth: ProductColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell ",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };

  const AgainstLedgerNameColumn: AgColDefsI = {
    headerName: "Against Ledger",
    field: "againstLedgerName",
    minWidth: ProductColWidth.MinWidth,
    maxWidth: ProductColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell ",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };


  

    
  const VoucherTypeColumn: AgColDefsI = {
    headerName: "Voucher Type",
    field: "voucherTypeId",
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };

  const VoucherNoColumn: AgColDefsI = {
    headerName: "Voucher No",
    field: "voucherNo",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };

  const DebitColumn: AgColDefsI = {
    headerName: "Debit",
    field: "debit",
    cellStyle: params => {
      return { 'text-align': 'right', backgroundColor: '#f2e4f1', color: 'white' };
    },
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  };
  
  const CreditColumn: AgColDefsI = {
    headerName: "Credit",
    field: "credit",
    cellStyle: params => {
      return { 'text-align': 'right', backgroundColor: '#f2e4f1', color: 'white' };
    },
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  };
  const ClosingValueColumn: AgColDefsI = {
    headerName: "CL. Amount",
    field: "closingAmount",
    cellStyle: params => {
      return { 'text-align': 'right', backgroundColor: '#e7cee4', color: 'white' };
    },
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  };
  const DebitPureWtColumn: AgColDefsI = {
    headerName: "Debit Wt",
    field: "debitPureWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    cellStyle: params => {
      return { 'text-align': 'right', backgroundColor: '#ccebeb', color: 'white' };
    },
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  };
  
  const CreditPureWtColumn: AgColDefsI = {
    headerName: "Credit Wt",
    field: "creditPureWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    cellStyle: params => {
      return { 'text-align': 'right', backgroundColor: '#ccebeb', color: 'white' };
    },
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  };
  const ClosingWtColumn: AgColDefsI = {
    headerName: "CL. Wt",
    field: "closingPureWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    cellStyle: params => {
      return { 'text-align': 'right', backgroundColor: '#add6d6', color: 'white' };
    },
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  };

  const TypeOfVoucherColumn: AgColDefsI = {
    headerName: "Type Of Voucher",
    field: "typeOfVoucher",
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };

  const DateColumn: AgColDefsI = {
    headerName: "Date",
    field: "date",
    minWidth: 90,
    
    headerClass: "custom-column-group-cell customHeaderClass",
    cellClass: "custom-column-group-cell text-end",
    editable: false,
    sortable: false,
    cellRenderer: DateCellRendererComponent,
  };
  const InvoiceNoColumn: AgColDefsI = {
    headerName: "Invoice No",
    field: "invoiceNo",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };
  const CommentColumn: AgColDefsI = { 
    headerName: "Comment", 
    field: 'comment', 
    minWidth: 150, 
    sortable: false, 
    editable: false, 
    cellRenderer: TextRendererComponent, 
    cellClass: 'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0", 
    tooltipValueGetter: (params) => params?.value ? params?.value?.split(',    ')?.map(comment => comment?.trim())?.join('\n') : '' 
  };
  const AgainstInvoiceNoColumn: AgColDefsI = {
    headerName: "Against Invoice No",
    field: "againstInvoiceNo",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
   
  };
  const ActionHistoryColumn: AgColDefsI = {
    headerName: "",
    field: "action",
    maxWidth: 70,
    minWidth: 70,
    sortable: false,
    editable: false,
    resizable: false,
    headerComponentParams: { enableSorting: false},
    cellRenderer: AgGridButtonCellrendererComponent, 
    cellRendererParams: { view: true },
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
  };
  //n
  const ledgerType: AgColDefsI = {
    headerName: "Ledger Type",
    field: "ledgerTypeName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    sortable: false,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
  };

  const BranchName: AgColDefsI = { 
    headerName: "Branch Name", 
    headerComponentParams: {enableMenu:false},
    sortable:false,
    headerClass: "px-0 custom-column-group-cell border-0 ",
    field: 'branchName',
    cellClass:'px-0 custom-column-group-cell ',
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:false},
    editable:false, 
    minWidth: 180, flex:2,
  }

  const AccountLedgerDetailsColumnDef :AgColDefsI[] = [
   
    ActionHistoryColumn,
    DateColumn,
    LedgerNameColumn,
    // VoucherTypeColumn,
    // VoucherNoColumn,
    InvoiceNoColumn,
    AgainstLedgerNameColumn,
    AgainstInvoiceNoColumn,
    TypeOfVoucherColumn,
    DebitColumn,
    CreditColumn,
    ClosingValueColumn,
    DebitPureWtColumn,
    CreditPureWtColumn,   
    ClosingWtColumn,
    ledgerType,
    CommentColumn,
    BranchName,
    
  ]

  export const AgGridAccountLedgerDetailInfo: AgGridI = {
    colDefs: AccountLedgerDetailsColumnDef,
    rowSelection: "single",
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    storageName : StorageName.REPORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID_TEMP,
    filterStorageName: StorageName.FILTER_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID,
     sortStorageName: StorageName.SORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID,
    paginationStorageName : StorageName.PAGINATION_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID,
    initialRows:StorageName.REPORT_ACCOUNT_LEDGER_REPORT_INITIAL_GRID,
    rows:[],
    rowHeight:28,
    isFilter: true,
    isServerSidepagination: false,  
    apiUrl : API_URL_GET_ACCOUNTLEDGERREPORT_GET_ACCOUNT_LEDGER_DETAIL_BY_LEDGERID,
    showPagination: false
  };

  