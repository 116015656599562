import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appNumberWithDecimal]'
})
export class NumberWithDecimalDirective {
  @Input('decimalPlaces') decimalPlaces: number = 2;// Set the desired number of decimal places
  @Input('applyDecimal') readOnly :boolean; 
  @HostBinding('autocomplete') public autocomplete;
 
  constructor(private el: ElementRef,private ngModel: NgModel) {
    this.autocomplete = 'off';
    // 
  }
  @HostListener('keypress', ['$event']) public disableKeys(e) {
    const keyCode = e.keyCode || e.which;
    const inputChar = String.fromCharCode(keyCode);
  
    if (keyCode === 8) {
      // Allow backspace
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      // Allow numbers (0-9)
      return true;
    } else if (inputChar === "." && e.target.value.indexOf(".") === -1) {
      // Allow a single decimal point if it doesn't already exist
      return true;
    } else {
      // Prevent any other characters from being entered
      e.preventDefault();
      return false;
    }
  }  

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    if(this.readOnly ==false){
      if (value.trim() === '' && this.decimalPlaces == 2) {
        // Handle empty input, e.g., set it to '0.00' or any desired default
        if(this.ngModel?.viewModel != undefined && this.ngModel?.viewModel?.trim() !== ''){
            this.el.nativeElement.value = this.ngModel.viewModel;
            this.ngModel.update.emit(this.ngModel.viewModel);
        }
        else{
          this.el.nativeElement.value = '0.00';
          this.ngModel.update.emit('0.00');
        }
        
      }
      else if (value.trim() === '' && this.decimalPlaces == 3) {
        // Handle empty input, e.g., set it to '0.000' or any desired default
        if(this.ngModel?.viewModel != undefined && this.ngModel?.viewModel?.trim() !== ''){
            this.el.nativeElement.value = this.ngModel.viewModel;
            this.ngModel.update.emit(this.ngModel.viewModel);
        }
        else{
          this.el.nativeElement.value = '0.000';
          this.ngModel.update.emit('0.000');
        }
      }
      else {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
          const formattedValue = this.formatWithDecimalPlaces(parsedValue, this.decimalPlaces);
          this.el.nativeElement.value = formattedValue;
          this.ngModel.update.emit(formattedValue);
        } else {
          // Handle invalid input, e.g., reset it to '0.00' or any desired default
          this.el.nativeElement.value = '0.00';
          this.ngModel.update.emit('0.00');
        }
      }
    }
  }
  
  formatWithDecimalPlaces(number: number, decimalPlaces: number): string {
    return number.toFixed(decimalPlaces);
  }
}
