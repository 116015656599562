import { StorageName } from "../../../../enum/common-enum";
import { AgColDefsI, AgGridI } from "../../../ag-grid/ag-grid.model";
import { RadioRendererComponent } from "../../../ag-grid/radio-renderer/radio-renderer.component";
import { TextRendererComponent } from "../../../ag-grid/text-renderer/text-renderer.component";

export class SelectPrintFormat {
}

const ActionColumn: AgColDefsI = {
    headerName: "", 
    field:"active", 
    suppressNavigable: true,
    editable: false,
    sortable: false, 
    resizable:false,
    suppressMovable:true,
    lockPinned:true,
    cellRenderer: RadioRendererComponent,
    cellClass:'custom-column-group-cell',minWidth: 33,maxWidth:33,
};

const FileNameColumn: AgColDefsI = { 
    headerName: "", 
    sortable:false,
    headerClass: "px-0 custom-column-group-cell",
    field: 'resourceTitle',
    cellClass:'px-1 custom-column-group-cell',
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:false},
    editable:false, 
    minWidth: 180, flex:2,
  }
export const ColDefsSelectPrintFormat: AgColDefsI[] = [
    ActionColumn,
    FileNameColumn
];

export const AgGridDataModelSelectPrintFormat: AgGridI = {
    colDefs: ColDefsSelectPrintFormat,
    rowSelection: "single",
    storageName : StorageName.SELECT_PRINT_FORMAT_POPUP_GRID,
    rowHeight:28,
    headerHeight:0,
}
