
import { CampaignManagerStatus, StorageName } from '../../../../../../../src/app/shared/enum/common-enum';
import { AgColDefsI, AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { EditDeleteIconComponent } from 'src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component';
import { TextRendererComponent } from 'src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component';
import { CheckboxRendererComponent } from 'src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component';
import {BlockUnblockStatus} from'../../crm.model' 
export class WhatsaapModel {
}
const CustomerNameColumn: AgColDefsI = {
    headerName: "Customer Name",
    field: 'name',
    cellClass: 'custom-column-group-cell text-start',
    headerClass: "px-0 custom-column-group-cell ag_header_start",
    minWidth: 140,
    flex: 1,
    sortable: false,
    resizable:true,
    editable: false,
    lockPosition:"left",
     cellRenderer: TextRendererComponent,
     headerComponentParams: {
      filterValueColumnName: "name",
      filterIdColumnName: "name",
      isInputFilter: true
    },
};

const BlockColumn: AgColDefsI = {
  headerName: "Status",
  field: 'extra1',
  cellClass: 'custom-column-group-cell align-items-center d-flex justify-content-center',
  headerClass: "px-1 f-14 text-gray-color font-semibold custom-column-group-cell customHeaderClass",
   cellRenderer: EditDeleteIconComponent,
  minWidth: 60,
  maxWidth:60,
  sortable: false,
  editable: false,
  resizable:true,
  cellRendererParams: (params: any) => {
    params.value = params?.data?.extra1;
    return { ...params, isCursor: true,blockUnblocked: true,isDeleteIcon:false, };
  },
  // cellRendererParams: { isCursor: true,blockUnblocked: true,isDeleteIcon:false},
  tooltipValueGetter: (params: any) => {
    return params.data.extra1 === '1' ? 'Block Contact' : params.data.extra1 === '0' ? 'Unblock Contact' : '';
  }
};

const ContactColumn: AgColDefsI = {
    headerName: "Contact",
    field: 'contactInfo',
    cellClass: 'custom-column-group-cell',
    headerClass: "px-0 custom-column-group-cell",
    cellRenderer: TextRendererComponent,
    minWidth: 140,
    sortable: false,
    editable: false,
    resizable:true,
    headerComponentParams: {
      filterValueColumnName: "contactInfo",
      filterIdColumnName: "contactInfo",
      isInputFilter: true,
      
    },
};
const checkboxColumn: AgColDefsI = { 
    headerName: "", 
    headerCheckboxSelection: false, 
    headerComponentParams: { isCommonHeaderCheckbox : true ,arrowUpDownIcon:true },
    headerClass: "px-1 custom-column-group-cell d-flex align-items-center justify-content-center",
    field: 'extra4',
    lockPosition:'right',
    lockPinned:true,
    pinned:'right',
    editable:false,
    sortable:false,
    suppressMoveIntoParents: false,
    suppressMovable: false,
    cellRenderer: CheckboxRendererComponent,
   // cellRendererParams: { showLabel: false , isCursor: true, isCommonCheckbox: true}, 
    cellClass:'custom-column-group-cell ag-header-check-center', 
    minWidth: 50, maxWidth: 50 ,
    resizable:false,
    cellRendererParams: (params: any) => {
      return {
        showLabel: false,
        isCursor: params?.data?.extra1 == BlockUnblockStatus.Unblock ? true : false,
        isReadOnly: params?.data?.extra1 == BlockUnblockStatus.Block ? true : false,
        isCommonCheckbox: params?.data?.extra1 == BlockUnblockStatus.Block ? false : true,
      };
    },
  }


const whatsAppTableColDefs: AgColDefsI[] = [
    CustomerNameColumn,
    ContactColumn,
    BlockColumn,
    checkboxColumn
]


export const AgGridDataModelInsertwhatsappList: AgGridI = {
    colDefs: whatsAppTableColDefs,
    rowSelection: "multiple",
    addBlankRowOnEnter: true,
    columnSettings: true,
    singleClickEdit: true,
    rows: [],
    storageName : StorageName.SETTINGS_WHATSAPP_CAMPAIGN_GRID,
    filterStorageName: StorageName.FILTER_SETTINGS_WHATSAPP_CAMPAIGN_GRID,
    sortStorageName: StorageName.SORT_SETTING_WHATSAPP_CAMPAIGN_GRID,
    paginationStorageName : StorageName.PAGINATION_SETTING_WHATSAPP_CAMPAIGN_GRID,
    initialRows: StorageName.WHATSAPP_CAMPAIGN_GRID_INITIAL_ROWS, 
    headerHeight : 70,
    rowHeight:28,
    isFilter: true,  
    // apiUrl :  API_URL_GET_ALL_USER,
    isServerSidepagination : false,
    showPagination: false,
};


export class InsertWhatsAppCampaignRequestModel {
  campaignMasterModel: CampaignMasterModel = new CampaignMasterModel()
}

export class InsertWhatsAppCampaignLedgerMappingRequestModel{
  campaignGroupId : number;
  campaignMappingModel: CampaignMappingModel[];
}

export class CampaignMasterModel {
  caption: string;
  attachmentPath: string;
  date: string;
  statusId: number;
  groupId: any = "0";
}

export class CampaignMappingModel {
  accountLedgerId: number;
  campaignTypeId: number;
  statusId: number = CampaignManagerStatus.Failed;
  statusMessage: string;
  contactInfo: string;
}
