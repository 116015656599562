import { Injectable } from '@angular/core';
import { Subject, tap } from 'rxjs';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService }   from 'src/app/shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn: boolean = false;

  public licenseKeySecurityToken = new Subject<any>();
  licenseKeySecurityToken$ = this.licenseKeySecurityToken.asObservable();

  constructor(public service: DataService,private storageservice  : StorageService,) {
  }

  SendLicenseKeySecurityToken(securityToken: any) {
    this.licenseKeySecurityToken.next(securityToken);
  }

  GetOTP(data) {
    let url = `api/app/UserOnboarding/otp/send`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  VerifyOTP(data) {
    let url = `api/App/UserOnboarding/otp/verify`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  ReSendOTP(data) {
    let url = `api/App/UserOnboarding/otp/resend`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetLicenseKey(data) {
    let url = `api/app/UserOnboarding/licensekey/generate`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  VerifyLicenseKey(data) {
    let jss = JSON.stringify(data)
    let url = `api/app/UserOnboarding/licensekey/verify`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // sign in
  signIn(data: any) {
    let url = 'api/app/account/signin';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  internalsignin(data: any) {
    let url = 'api/app/account/internalsignin';
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((res: any) => {
      this.storageservice.store("authorizationDataIdToken", res?.response.token.accessToken);
      this.storageservice.store(StorageName.WHATSAPP_INTEGRATION_SETTING,res.response.whatsappIntegrationModel)
      this.storageservice.store("user", res?.response.user);
      this.storageservice.store(StorageName.PRINTER_SETTING, res?.response.printerSettingModel);
      this.storageservice.store('layoutDetails', res?.response.layoutDetails)
      this.storageservice.store("branchList", res.response?.branchList);
      this.storageservice.store(StorageName.STORAGE_SYSTEM_FIELDS, res.response?.systemFields);
      this.storageservice.store('financialYear' , {financialYearStartDate : res.response?.financialYearModel?.startingYear , financialYearEndDate : res.response?.financialYearModel?.endingYear});
      this.storageservice.store(StorageName.STORAGE_STATIC_DROPDOWN, res.response?.staticDropdown);

      return res;
    }));
  }

  GetStatus(){
    let url = `api/app/UserOnboarding/status`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
  GetCountryDetailsDropdown(){
    let url = `api/app/company/getcountrydetails`;
    return this.service.get(url, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetProductKey()
  {
    let url = `api/app/useronboarding/getproductkey`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  RecoverCompanyRegistrationId(data: any) {
    let url = 'api/app/company/recovercrid';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}
