import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[AppTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective implements OnChanges{
  @Input('isPercentApplicable') isPercentApplicable: boolean = false;
  @Input('decimalRestrictedDigit') decimalRestrictedDigit: number = 3;
  @Input('isBlureApplicable') isBlureApplicable: boolean = false;

  private regex: RegExp;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.regex = new RegExp(`^-?\\d*\\.?\\d{0,${this.decimalRestrictedDigit}}$`);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, home, control keys, and only one decimal point
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      (event.ctrlKey && (event.key === 'v' || event.key === 'c')) || 
      (event.key === '.' && this.el.nativeElement.value.indexOf('.') !== -1)
    ) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }

    if (this.isPercentApplicable && parseFloat(next) > 100) {
      event.preventDefault();
    }
  }

  // ********************copy paste working *********************
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    const sanitizedText = pastedText.replace(/[^0-9.]/g, '');

    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      sanitizedText,
      current.slice(position),
    ].join('');

    if (sanitizedText && !String(next).match(this.regex)) {
      return;
    }

    if (this.isPercentApplicable && parseFloat(next) > 100) {
      return;
    }

    document.execCommand('insertText', false, sanitizedText);
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent | any): void {
    setTimeout(() => {
      if (!event?.target?.readOnly && this.el.nativeElement.value !== '' && !isNaN(Number(this.el.nativeElement.value))) {
        this.el.nativeElement.select();
      }
    }, 10);
  }

  // @HostListener('blur', ['$event'])
  // onBlur(event: Event | any) {
  //   let value = this.el.nativeElement.value;
  //   if (this.isBlureApplicable && !event?.target?.readOnly) {
  //     if (value !== '' && !isNaN(Number(value))) {
  //       value = Number(value).toFixed(this.decimalRestrictedDigit);
  //       this.el.nativeElement.value = value;
  //     }
  //   }
  // }
}