
<div class="d-flex gap-2 w-100">
    <div class="date-input range-datepicker d-flex p-0">
        <div class="input-group ps-9px"> 
        
            <input 
            name="datepicker" 
            class="input-calendar" 
            placeholder="dd/mm/yyyy - dd/mm/yyyy" 
            ngbDatepicker
            [value]="inputDate" 
            #datepicker="ngbDatepicker" 
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)" 
            [displayMonths]="2" 
            [dayTemplate]="dayTemplate"
            [footerTemplate]="footerTemplate" 
            outsideDays="hidden" 
            [startDate]="fromDate!" 
            tabindex="-1"
            readonly="true" 
            />
                <!-- [maxDate]="displayMonths == 1? getMaxSelectableDate():null" -->
                <!-- [value]="inputDate" -->
                <div class="px-2 calender-2 cursorPointer border-end d-flex align-items-center" >
                    <img src="./assets/images/svg-icon/datepicker_icon.svg" (click)="datepicker.toggle()" alt="">
                </div>
        
            <ng-template #dayTemplate let-date let-focused="focused" (mouseleave)="leave()">
                <span 
                    class="custom-day" 
                    [class.focused]="focused" 
                    [class.range]="isRange(date)"
                    [class.start-date]="isStartDate(date)" 
                    [class.end-date]="isEndDate(date)"
                    [class.faded]="isHovered(date) || isInside(date)" 
                    (mouseenter)="hoveredDate = date ;"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
            </ng-template>
            <!-- Templates -->
            <ng-template #footerTemplate>
                <hr class="my-0">
                <div class="action-buttons">
                    <button class="btn btn-sm btn-pink text-nowrap cursorPointer " (click)="onToday()">Today</button>
                    <button class="btn btn-sm btn-pink text-nowrap cursorPointer " (click)="onApplyFinancialYear()">Apply Financial Year</button> 
                    <button class="btn btn-sm btn-blue text-nowrap cursorPointer " (click)="onApply(true)" [disabled]="!inputDate">Apply</button> 
                </div>
            </ng-template>
    <!-- end Templates -->
        </div>
    </div>
    <!-- <app-button *ngIf="isResetButton" class="" showRefreshIcon="true" (buttonOutputEvent)="onClear()" [buttonClass]="'white_button border-primary px-2 gap-0'"[buttonType]="'button'">
    </app-button> -->
</div>
