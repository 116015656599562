import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-icon-with-input',
  templateUrl: './icon-with-input.component.html',
  styleUrls: ['./icon-with-input.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],

  
})
export class IconWithInputComponent {
  emailPattern: string = "[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\\.[-a-zA-Z0-9_]+)*\\.(com|in|org))(:[0-9]{1,5})?";
  @Input() inputLabel:string;
  @Input() inputName:string;
  @Input() isRequired:boolean;
  @Input() inputPlaceholder:string;
  @Input() inputId:string;
  @Input() inputType:string='text';
  @Input() inputValue:string='';
  @Input() disabled :boolean;
  @Input() inputClass:string='';
  @Input() inputMaxlength:number;
  @Input() decimalPlaces: number =0; // Default to 0, indicating no decimal places by default
  @Input() isOnlyNumbers: boolean = false; // Default to false, indicating no onlyNumbers directive by default
  @Input() isOnlyAlphabets: boolean = false; // Default to false, indicating no onlyAlphabets directive by default
  @Input() isOnlyAlphabetsAndNumbers: boolean = false;
  @Input() appNumberWithDecimal: boolean = false;
  @Input() isAllowDecimal: boolean = true;
  @Input() buttonClass:string='';
  @Input() placeHolderRequired:boolean;
  @Input()isEmailPattern:boolean=false;
  @Input() ngbToolTipContent : string;
  @Input() showNameIcon:boolean =false;
  @Input() showmobileIcon:boolean =false;
  @Input() showpanIcon:boolean =false;
  @Input() showadharIcon:boolean =false;
  @Input() showgstIcon:boolean =false;
  @Input() showmailIcon:boolean =false;
  @Input() showphoneIcon:boolean =false;
  @Input() showdayIcon:boolean =false;
  @Input() showdateIcon:boolean =false;
  @Input() showregistrationIcon:boolean =false;
  @Input() showExportAsIcon:boolean =false;
  @Input() labelName:boolean =false;
  @Input() InputLabel:string;
  @Input() enableFocus: boolean = false;

  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() keydownEmit:EventEmitter<string> = new EventEmitter();

}
