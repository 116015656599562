<!-- <br> -->
<span *ngIf="isFilter"
    class="material-icons cursorPointer filter-icon"
    [ngClass]="{'column-filter-applied': isFilterApplied(valueColumnName)}"
    [matTooltip]="'Filter'"
    matTooltipPosition="below"
    (click)="openFilterModelPopUp(idColumnName,valueColumnName)"
    >
    filter_alt
</span>
<div *ngIf="params.isInputFilter" class="d-flex px-2 pt-1">
    <!-- <input #filterInput class="mat-input f-10 text-gray-color font-regular form-control h-28px flex-p-x" [(ngModel)]="inputValue" (ngModelChange)="!isServerSidePagination?  updateInputFilter(valueColumnName,$event):(isServerSidePagination && !$event)? updateInputFilter(valueColumnName,$event):null " value="{{setFilteredText(valueColumnName)}}" (keypress)="keyPressValue($event)"> -->
    <app-search-input 
    [isVisibleSearchIcon]="false" 
    class="w-100 d-block" 
    [inputSerachValue]="inputValue" 
    (inputSerachValueEmit)="inputValue=$event;updateInputFilter(valueColumnName,inputValue)"  
    (inputActionIconClick)="updateInputFilter(valueColumnName,$event)" 
    (keydown.enter)="handleEnterKey($event)" > 
    </app-search-input>
    <!-- (inputSerachValueEmit)="inputValue = $event ; !$event? updateInputFilter(valueColumnName,inputValue):null" -->
    <!-- <div>
    <app-button 
        *ngIf="isServerSidePagination"
        class=""
        showFilterIcon="true"
        [buttonClass]="'common-filter-size gap-0 h-1_8rem bordernone light_voiletButton'"
        [buttonType]="'button'"
        (buttonOutputEvent)="updateInputFilter(valueColumnName,filterInput?.value)"
        ></app-button>
    </div> -->
    <!-- <div (click)="onClearFilter(valueColumnName)">
        <app-button class="" 
                ResetdarkBlueIcon="true" 
                [buttonClass]="'px-1 py-1 gap-0 h-1_8rem bordernone light_voiletButton'"
                [buttonType]="'button'">
        </app-button>
    </div> -->
    <!-- <input class="mat-input form-control h-20px flex-p-x" (change)="updateInputFilter(valueColumnName,$event)" value="{{setFilteredText(valueColumnName)}}" style="width:-webkit-fill-available;"> -->
    <!-- <button 
        class="filter-close" 
        *ngIf="inputValue" 
        mat-button 
        matSuffix 
        mat-icon-button 
        aria-label="Clear"
        (click)="onClearFilter(valueColumnName)"
        >
        <mat-icon>close</mat-icon>
    </button> -->
</div>

<div *ngIf="isPopUpInputFilter">
    <input class="mat-input form-control h-20px flex-p-x" 
    [(ngModel)]="inputPopUpHeaderValue" 
    (ngModelChange)="emitInputValueForPopUP(inputPopUpHeaderValue)"  
    style="width:-webkit-fill-available;display: block;
    line-height: 18px;
    position: absolute;
    bottom: 5px;">
    <button 
        class="filter-close" 
        *ngIf="inputPopUpHeaderValue" 
        mat-button 
        matSuffix 
        mat-icon-button 
        aria-label="Clear"
        (click)="emitInputValueForPopUP('')"
        >
        <mat-icon>close</mat-icon>
    </button>
</div>


 <div *ngIf="params.floatingDropDown">
    <div>
        <select
        class="form-control"
        style="width:-webkit-fill-available;display: block;
        line-height: 18px;
        position: absolute;
        bottom: 5px;"
        [(ngModel)]="model" 
        value="{{setDropdown(valueColumnName)}}"
        (change)="updateDropdownFilter(valueColumnName,filterStorageName,$event)"
        name="{{name}}">
        <option 
          *ngFor="let item of filterData" 
          [value]="item.id">
          {{item.name}}
        </option>
        </select>
    </div>
    <div>
        <app-button class="" showFilterIcon="true"
        [buttonClass]="'light_voiletButton px-2 py-1 gap-0 h-1_8rem'" [buttonType]="'button'">
        </app-button>
    </div>
</div> 

<div class="row px-2 pt-1 filter_multiselect_popup" *ngIf="params.floatingMultiselectDropDown">
</div>