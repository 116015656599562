<app-load-dropdown-data [formName]="formName" (fillDropdownData)="fillDropdownData()"></app-load-dropdown-data>
<div class="row mx-0 d-flex flex-column gap-2 " [ngClass]="[data?.elementsToHide?'fullheight':'h-100']"  >
    <div class="col flex-fill h-0 p-0">
        <app-ag-grid 
            [dataModel]="agGridDataModel"
            [columnSettings]="true"
            (gridReady)="onGridReady($event)"
            (onPagination)="onPagination($event)"
            (onSortIconClick)="onSortIconClick($event)"
            >
        </app-ag-grid>
    </div>
</div>