import { Injectable } from '@angular/core';
import {DataService} from '../../../../shared/services/data.service';
import {GetAllJewelryCatalogueRequestModel} from './jewelry-catalogue-details.model';
import { tap } from 'rxjs';
import { ResponseModel } from '../../../../shared/models/response-model.model';
import { StorageService } from '../../../../shared/services/storage.service';
import { Data } from 'electron/main';

@Injectable({
  providedIn: 'root'
})
export class JewelryCatalogueDetailsService {

  constructor(public service:DataService,public storageService : StorageService) { }

  insertUpdateJewelryCatalogue(data:any){
    let url = 'api/app/jewelrycatalogue/insertupdatejewelrycatalogue';
    const Json = JSON.stringify(data);
    return this.service.post(url,data,false).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }

  getAlljewelryCatalogue(getAllJewelryCatalogueRequestModel:GetAllJewelryCatalogueRequestModel){
    let url = 'api/app/jewelrycatalogue/getalljewelrycatalogue';
    
    return this.service.post(url,getAllJewelryCatalogueRequestModel,false).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  };
  
  getjewelryCatalogueById(id){
    let url = `api/app/jewelrycatalogue/getjewelrycataloguebyid?Id=${id}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
      if(response.isSuccess == true)
        {
          this.storageService.getInvoice.next(response);
        }
        return response;
        })
      );
  }



  insertJewelaryCatalogue(insertUpdateJewelaryCatalogueModel:any){
    let url = 'api/app/jewelrycatalogue/insertupdatejewelrycatalogue';
    var json = JSON.stringify(insertUpdateJewelaryCatalogueModel);
    return this.service.post(url,insertUpdateJewelaryCatalogueModel,false).pipe<ResponseModel>(tap((response: any) => {
      if(response.isSuccess == true)
      {
        this.storageService.store("jewelaryBillOfMaterialId",0);
        this.storageService.resetInvoice.next("BillOfMaterial");
      }
      return response;
      })
    );
  }


  // getJewelaryBillOfMaterialById(id:number){
  //   let url = `api/app/jewelrycatalogue/getjewelrycataloguebyid?Id=${id}`;
  //   return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
  //     if(response.isSuccess == true)
  //       {
  //         this.storageService.getInvoice.next(response);
  //       }
  //       return response;
  //       })
  //     );
  // }
  
}
