import { Component, Input } from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.scss"],
})
export class SvgIconComponent {
  @Input()
  public name?: string;
  public svgIcon: any;
  @Input() width : number ;
  @Input() height : number ;
  @Input("icon") public icon;
  public iconValue;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    // public layOut: LayoutService
    ) {
  }

  getSvgType() {
    return document.getElementsByClassName("sidebar-wrapper")[0].getAttribute("icon") == "stroke-svg";
  }

  
  public ngOnChanges(): void {
    if (!this.name) {
      this.svgIcon = '';
      return;
    }

    this.httpClient
      .get(`assets/svg/${this.name}.svg`, { responseType: 'text' })
      .subscribe((value) => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(
          value.replace('<svg',`<svg style="width: ${this.width}px; height: ${this.height}px;"`)
        );
      });
  }

}
