import { Injectable } from '@angular/core';
import {DataService} from '../../services/data.service';
import { Observable, tap } from 'rxjs';
import {ResponseModel} from '../../models/response-model.model'
import { GetAllJewelryCatalogueRequestModel } from './jewelry-catalogue-common.model';
@Injectable({
  providedIn: 'root'
})
export class JewelryCatalogueCommonService {

  constructor(public service:DataService) { }

  
  getAlljewelryCatalogue(data ,anonymous?:any){
    let url = 'api/app/jewelrycatalogue/getalljewelrycatalogue';
    if (anonymous) {
      url = anonymous;
    }
    
    return this.service.post(url,data,true ,null,false ).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  };


  getSyncJewellryCatalogue():Observable<ResponseModel>{
    let url = "api/app/jewelrycatalogue/getalljewelrycataloguefromwoocommerceandshopify";
    return this.service.get(url,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
