
<div *ngIf="metalSectionModel?.showMetalSelectionPopupHeader" class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">{{this.data?.barcodeNo}}</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>

<div *ngIf="metalSectionModel?.showMetalSelectionTabs" class="row py-1">
    <div class="col-12 d-flex justify-content-between">
        <div class="" [ngClass]="'col-12'">
            <app-nav-tabs [tabItems]="tabItems" (tabId)="selectTab($event)"></app-nav-tabs>
        </div>
    </div>
</div>
<div class="flex-fill content_tab">
    <div class="d-flex flex-column h-100" container="body">
        <div class="" id="Sales_Invoice position-relative">
            <div class=""*ngFor="let item of tabGroupList">
                <app-ag-grid 
                        [ngClass]="tabId == item.tabId ? 'd-block': 'd-none'"
                        [tableId]="item?.dataModel?.storageName"
                        [class]="'all_aggrid_tables position-relative d-block '+ gridDynamicHeightClass" 
                        [dataModel]="item.dataModel"
                        [gridOptions]="gridOptions"
                        [navigateGridOption]="gridOptionsPayment"
                        (onDeleteIconClick) = "deleteIconClick($event)"
                        (onEditIconClick) = "editIconClick($event)"
                        [columnSettings]="item?.dataModel?.columnSettings"
                        (gridReady)="onGridReady(item.tabIndex, $event)"
                        (onPagination)="onPagination($event)"
                        >
                </app-ag-grid>
            </div>
        </div>
    </div>
</div>