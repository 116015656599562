<ng-container >
    <div class="modal-body p-2">
        <div class="d-flex justify-content-end"><app-svg-icon [name]="'cross'" tabindex="0" [ngClass]="currentHoverEffect" class="cross-button cursorPointer my-1 mx-1" (click)="activeModal.dismiss()"></app-svg-icon></div>
        <div  *ngIf="callApi  === 'Run Migrations'" class="run_icon text-center d-flex justify-content-center my-2 align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="58.725" height="61.641" viewBox="0 0 384 512">
               <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" fill="#1492e6"/>
            </svg>
        </div>
        <!-- reset-icon -->
        <div *ngIf="callApi !== 'Run Migrations' && callApi !== 'Update Status'"
        class="reset_icon text-center d-flex justify-content-center my-1 align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="58.725" height="61.641" viewBox="0 0 14.78 14.78" class="show-icon" fill="#1492e6">
              <g id="Group_3433" data-name="Group 3433" transform="translate(0 0)">
                <path id="Path_51" data-name="Path 51" d="M-1998.906,6721.851c1.331.114,2.6.221,3.9.331-.56,1.232-1.1,2.429-1.671,3.678-.132-.4-.245-.752-.362-1.1-.087-.257-.151-.285-.377-.135a5.53,5.53,0,0,0-2.633,5.193,5.446,5.446,0,0,0,3.57,5.008c.24.1.3.187.219.437-.119.375-.206.763-.3,1.146-.038.158-.078.229-.265.158a7.136,7.136,0,0,1-4.8-5.7,7.42,7.42,0,0,1,3.364-7.761c.234-.151.243-.213.054-.437C-1998.423,6722.406-1998.644,6722.153-1998.906,6721.851Z" transform="translate(2001.759 -6721.851)"/>
                <path id="Path_52" data-name="Path 52" d="M-1886.218,6733.342c.133.409.245.759.362,1.109.083.247.151.277.361.141a5.634,5.634,0,0,0,2.637-4.3,5.676,5.676,0,0,0-3.657-5.946c-.169-.059-.191-.127-.142-.294.115-.4.218-.8.3-1.2.048-.228.123-.284.346-.2a7.22,7.22,0,0,1,4.532,4.829,7.546,7.546,0,0,1-3.114,8.592c-.31.2-.315.235-.074.512.209.239.418.481.661.759-.655-.051-1.254-.1-1.854-.147s-1.206-.1-1.808-.155c-.1-.008-.249.024-.158-.175Z" transform="translate(1895.913 -6722.561)"/>
              </g>
           </svg>
        </div>
        <div>
            <p class="title text-gray-color font-semibold f-16 m-0 text-center">{{data?.callApi}}</p>
            <p class="sub-info font-regular text-gray-color d-flex align-items-center f-14 mb-2 justify-content-center text-center">Are you sure you want to {{data?.callApi}}?</p>
        </div>
    </div>
    <div class="modal-footer border-0">
        <div tabindex="0" class="col d-flex align-items-center justify-content-center cursorPointer m-0"  (click)="onDelete()"><span class="font-semibold px-2 f-16">Yes</span></div>
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="activeModal.close(false)"><span tabindex="0" class=" px-2 font-semibold f-16">No</span></div>
    </div>
</ng-container>


      