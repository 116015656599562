import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllRateResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllRateData              : any[]=[];
  getAllMetalData             : any[]=[];
  getAllCaratData             : any[]=[];

  copyGetAllRateData          : any[]=[];
  editRateData                = new GetAllRateResponseModel();
  isAdd                       : boolean = false;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
  
) 
{
  super(renderer)
  this.sendDataService.rateUpdated$.subscribe(() => {
    this.getAllRateList(); // Refresh rate list when notified
  });
}

ngOnInit(): void {
  this.getAllRateList();
  // this.getAllMetalList();
  // this.getAllCaratList();
}


add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, metalId:null,caratId:null,currentRate:null,metalExchangeRate:null , active:true,}
  
  this.getAllRateData?.push(obj);
  this.copyGetAllRateData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`metalId${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  // 
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllRateData = this.getAllRateData.filter((x)=>x.id != 0)
    this.copyGetAllRateData = this.copyGetAllRateData.filter((x)=>x.id != 0);
  }
  else if(this.editRateData?.id && this.editRateData?.isEdit){
    const index = this.copyGetAllRateData.findIndex((x)=>x.id == this.editRateData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllRateData))[index];
    this.getAllRateData[index] = singleData;
    this.editRateData = new GetAllRateResponseModel()
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editRateData = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
 }
  
//   // *********** RATE API START *********
//   // **********get all Rate *********
  getAllRateList() {
    this.administrationService.getAllRate().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.editRateData = new GetAllRateResponseModel();
          this.getAllRateData = res.response??[];
          this.copyGetAllRateData = JSON.parse(JSON.stringify(this.getAllRateData))??[];
        }else{           
          this.getAllRateData = []; 
          this.copyGetAllRateData = [];
        }  
      },
      error:(err)=>{
        this.getAllRateData = []; 
        this.copyGetAllRateData = [];
      }
    })
  }
  
 // *************insert Rate **************
  InserRateList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertRate(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllRateList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }
    
  // **********update Rate *********
    UpdateRateList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateRate(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            
            this.isAdd = false;
            this.getAllRateList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

  // **********delete Rate *********
    DeleteRateList(id) {
    if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.deleteRate(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllRateList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }



 // **********get all Metal list *********
  getAllMetalList() {
    this.administrationService.getAllMetalData = [];
    this.administrationService.getAllMetal().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.getAllMetalData = res.response??[];
          this.administrationService.getAllMetalData = res.response??[];
        }else{           
          this.getAllMetalData = [];
        }  
      },
      error:(err)=>{
        this.getAllMetalData = [];
        this.administrationService.getAllMetalData = [];
      }
    })
  }
  

  // **********get all carat list *********
  getAllCaratList() {
    this.administrationService.getAllCaratData = [];
    this.administrationService.getAllCarat().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.getAllCaratData = res.response??[];
          this.administrationService.getAllCaratData = res.response??[];
        }else{           
          this.getAllCaratData = [];
        }  
      },
      error:(err)=>{
        this.getAllCaratData = [];
        this.administrationService.getAllCaratData = [];
      }
    })
  }
  // *********** RATE API END *********


}
