import { Component, Input, OnInit } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IconHover } from '../../../enum/common-enum';

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.scss']
})
export class ClosingComponent implements OnInit{
  @Input() data:any;
  message = '';
  currentHoverEffect = IconHover.Pink;
  messages: string[] = [];
  @Input()isjobworkorderlist:boolean;
  private hubConnection: HubConnection | undefined;
  constructor(public activeModal: NgbActiveModal,
              public toaster    : ToastrService
              ) {}
    ngOnInit(): void {
      this.data;
    }

    calculateItem(){
      this.data.purityWt                    =  Number( this.data.lossWt ?? 0) * (Number( this.data.purityPer == null ||  this.data.purityPer == 0 ? 1 :  this.data.purityPer));
  
    }
    close(){
      this.activeModal.close({
        isSuccess:true,
        data: this.data
      });
    }
    dismiss(){
      this.activeModal.close({isSuccess:false})
    }
    ngOnDestroy(): void {
    }
}
