import { NameColWidth, ProductColWidth, QuantityColWidth, StorageName, WeightColWidth } from "../../enum/common-enum";
import { MetalType } from "../../models/common.model";
import { StorageService } from "../../services/storage.service";
import { AgGridCustomDropdownComponent } from "../ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { AgGridCustomInputComponent } from "../ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { AgColDefsI, AgGridI } from "../ag-grid/ag-grid.model";
import { EditDeleteIconComponent } from "../ag-grid/edit-delete-icon/edit-delete-icon.component";
import { TextRendererComponent } from "../ag-grid/text-renderer/text-renderer.component";

export class MetalSectionChildAppraisal {
}

const CategoryColumn: AgColDefsI = {
  headerName: "Category",
  field: "diamondCategoryId",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      var data = storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
      return data;
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const CalctypeColumn: AgColDefsI = {
  headerName: "Calc. Type",
  field: "calculationType",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      var data = storageService.retrieve(storage.CALCULATION_TYPE_DROPDOWN);
      return data;
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const ProductColumn: AgColDefsI = {
  headerName: "Product",
  field: "productDetailId",
  minWidth: ProductColWidth.MinWidth,
  maxWidth: ProductColWidth.MaxWidth,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.PRODUCT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const QuantityColumn: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: 120,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: false,
  resizable: true,
  cellRenderer: TextRendererComponent,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:1,
    isOnlyNumbers:true,
    inputMaxlength:15
  },
}
const GrossWtColumn: AgColDefsI = {
  headerName: "Gross Wt.",
  field: "grossWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

const CaretColumn: AgColDefsI = {
  headerName: "Carat",
  field: "carat",
  colId:'carat', // this is for custom changes..
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const GoldCaratColumn: AgColDefsI = {
  headerName: "Gold Carat",
  field: "goldCaratId",
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const DiamondWtColumn: AgColDefsI = {
  headerName: "Diamond Wt",
  field: "weight",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

export const RateColumn: AgColDefsI = {
  headerName: "Rate",
  field: "rate",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

export const RepnetColumn: AgColDefsI = {
  headerName: "Repnet",
  field: "rapNetValuation",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

export const AmountColumn: AgColDefsI = {
  headerName: "Amount",
  field: "amount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

const GoldWtColumn: AgColDefsI = {
  headerName: "Gold Wt",
  field: "goldWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

export const GoldAmountColumn: AgColDefsI = {
  headerName: "Gold Amount",
  field: "goldAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  },
};
const GoldRateColumn: AgColDefsI = {
  headerName: "Gold Rate",
  field: "goldRate",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

const ActionColumn: AgColDefsI = {
  headerName: "",
  pinned: "right",
  field: "edit",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  cellRendererParams: {
    editIcon: true,isDeleteIcon:true
  },
  cellClass: "mx-0 text-end px-1",
  minWidth: 80,
  maxWidth: 80,
};
export const CutColumn: AgColDefsI = {
  headerName: "Cut",
  field: "cutId",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CUT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const ColorColumn: AgColDefsI = {
  headerName: "Color",
  field: "colorId",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.COLOR_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const SeiveSizeColumn: AgColDefsI = {
  headerName: "SeiveSize",
  field: "seiveSizeId",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SEIVE_SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
export const shapeCatColumn: AgColDefsI = {
  headerName: "Shape Cat.",
  field: "shapeCategoryId",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SHAPE_CATEGORY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const ShapeColumn: AgColDefsI = {
  headerName: "Shape",
  field: "shapeId",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SHAPE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const ClarityColumn: AgColDefsI = {
  headerName: "Clarity",
  field: "clarityId",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CLARITY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerComponentParams: { enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
};

export const UDF1: AgColDefsI = {
  headerName: "UDF1",
  field: "udF1",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF1_DROPDOWN);
    },
  },
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1" && a.extra1 == "1")?.length > 0,
  hide :true,
  isVisibleOnColumnSetting : true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1" && a.extra1 == "1")?.length > 0,

  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
export const UDF2: AgColDefsI = {
  headerName: "UDF2",
  field: "udF2",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF2_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2" && a.extra1 == "1")?.length > 0,

};
export const UDF3: AgColDefsI = {
  headerName: "UDF3",
  field: "udF3",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF3_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3" && a.extra1 == "1")?.length > 0,

};
export const UDF4: AgColDefsI = {
  headerName: "UDF4",
  field: "udF4",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF4_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4" && a.extra1 == "1")?.length > 0,

};
export const UDF5: AgColDefsI = {
  headerName: "UDF5",
  field: "udF5",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF5_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF6: AgColDefsI = {
  headerName: "UDF6",
  field: "udF6",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF7: AgColDefsI = {
  headerName: "UDF7",
  field: "udF7",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7" && a.extra1 == "1")?.length > 0,

};
export const UDF8: AgColDefsI = {
  headerName: "udF8",
  field: "udF8",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8" && a.extra1 == "1")?.length > 0,
};
export const UDF9: AgColDefsI = {
  headerName: "udF9",
  field: "udF9",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,

  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9" && a.extra1 == "1")?.length > 0,
};
export const UDF10: AgColDefsI = {
  headerName: "udF10",
  field: "udF10",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  },
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10" && a.extra1 == "1")?.length > 0,

};

export const UDF11: AgColDefsI = {
  headerName: "UDF11",
  field: "udF11",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF11_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF12: AgColDefsI = {
  headerName: "UDF12",
  field: "udF12",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF12_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF13: AgColDefsI = {
  headerName: "UDF13",
  field: "udF13",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF13_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF14: AgColDefsI = {
  headerName: "UDF14",
  field: "udF14",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF14_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF15: AgColDefsI = {
  headerName: "UDF15",
  field: "udF15",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF15_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};
export const UDF16: AgColDefsI = {
  headerName: "UDF16",
  field: "udF16",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF17: AgColDefsI = {
  headerName: "UDF17",
  field: "udF17",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF18: AgColDefsI = {
  headerName: "UDF18",
  field: "udF18",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF19: AgColDefsI = {
  headerName: "UDF19",
  field: "udF19",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF20: AgColDefsI = {
  headerName: "UDF20",
  field: "udF20",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF21: AgColDefsI = {
  headerName: "UDF21",
  field: "udF21",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF21_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};
export const UDF22: AgColDefsI = {
  headerName: "UDF22",
  field: "udF22",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF22_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF23: AgColDefsI = {
  headerName: "UDF23",
  field: "udF23",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF23_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF24: AgColDefsI = {
  headerName: "UDF24",
  field: "udF24",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF24_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF25: AgColDefsI = {
  headerName: "UDF25",
  field: "udF25",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF25_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF26: AgColDefsI = {
  headerName: "UDF26",
  field: "udF26",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF26_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF27: AgColDefsI = {
  headerName: "UDF27",
  field: "udF27",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF27_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF28: AgColDefsI = {
  headerName: "UDF28",
  field: "udF28",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF28_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF29: AgColDefsI = {
  headerName: "UDF29",
  field: "udF29",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF29_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF30: AgColDefsI = {
  headerName: "UDF30",
  field: "udF30",
  minWidth: 100,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF30_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

const UDF1DynamicColumn = (metalId) => {
  return {
    ...UDF1,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF1_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId);
        return  dropdownValues ??[]
      },
    },

    // isVisibleOnColumnSetting : visibleOncolumnSetting(metalId , "UDF1")
  };
};

const UDF2DynamicColumn = (metalId) => {
  return {
    ...UDF2,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF2_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId);

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF3DynamicColumn = (metalId) => {
  return {
    ...UDF3,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF3_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF4DynamicColumn = (metalId) => {
  return {
    ...UDF4,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF4_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF5DynamicColumn = (metalId) => {
  return {
    ...UDF5,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF5_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF11DynamicColumn = (metalId) => {
  return {
    ...UDF11,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF11_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF12DynamicColumn = (metalId) => {
  return {
    ...UDF12,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF12_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF13DynamicColumn = (metalId) => {
  return {
    ...UDF13,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF13_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF14DynamicColumn = (metalId) => {
  return {
    ...UDF14,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF14_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF15DynamicColumn = (metalId) => {
  return {
    ...UDF15,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF15_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF21DynamicColumn = (metalId) => {
  return {
    ...UDF21,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF21_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF22DynamicColumn = (metalId) => {
  return {
    ...UDF22,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF22_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF23DynamicColumn = (metalId) => {
  return {
    ...UDF23,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF23_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF24DynamicColumn = (metalId) => {
  return {
    ...UDF24,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF24_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF25DynamicColumn = (metalId) => {
  return {
    ...UDF25,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF25_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF26DynamicColumn = (metalId) => {
  return {
    ...UDF26,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF26_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF27DynamicColumn = (metalId) => {
  return {
    ...UDF27,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF27_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF28DynamicColumn = (metalId) => {
  return {
    ...UDF28,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF28_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF29DynamicColumn = (metalId) => {
  return {
    ...UDF29,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF29_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF30DynamicColumn = (metalId) => {
  return {
    ...UDF30,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF30_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
export const ColDefsMetalAppraisal: AgColDefsI[] = [
  CategoryColumn,
  CalctypeColumn,
  ProductColumn,
  QuantityColumn,
  GrossWtColumn,
  CaretColumn,
  DiamondWtColumn,
  RateColumn,
  AmountColumn,
  GoldCaratColumn,
  GoldWtColumn,
  GoldAmountColumn,
  GoldRateColumn,
  CutColumn,
  ColorColumn,
  SeiveSizeColumn,
  ShapeColumn,
  shapeCatColumn,
  ClarityColumn,
  RepnetColumn,
  UDF1DynamicColumn(MetalType.Diamond),
  UDF2DynamicColumn(MetalType.Diamond),
  UDF3DynamicColumn(MetalType.Diamond),
  UDF4DynamicColumn(MetalType.Diamond),
  UDF5DynamicColumn(MetalType.Diamond),
  UDF6,
  UDF7,
  UDF8,
  UDF9,
  UDF10,
  UDF11DynamicColumn(MetalType.Diamond),
  UDF12DynamicColumn(MetalType.Diamond),
  UDF13DynamicColumn(MetalType.Diamond),
  UDF14DynamicColumn(MetalType.Diamond),
  UDF15DynamicColumn(MetalType.Diamond),
  UDF16,
  UDF17,
  UDF18,
  UDF19,
  UDF20, 
  UDF21DynamicColumn(MetalType.Diamond),
  UDF22DynamicColumn(MetalType.Diamond),
  UDF23DynamicColumn(MetalType.Diamond),
  UDF24DynamicColumn(MetalType.Diamond),
  UDF25DynamicColumn(MetalType.Diamond),
  UDF26DynamicColumn(MetalType.Diamond),
  UDF27DynamicColumn(MetalType.Diamond),
  UDF28DynamicColumn(MetalType.Diamond),
  UDF29DynamicColumn(MetalType.Diamond),
  UDF30DynamicColumn(MetalType.Diamond), 
  ActionColumn
];



export const AgGridDataModelMetalAppraisal: AgGridI = {
  colDefs: ColDefsMetalAppraisal,
  rowSelection: "multiple",
  addBlankRowOnEnter:true,
  columnSettings:true,
  storageName : StorageName.PRODUCT_APPRAISAL_ADDITEMS_GRID,
  sortStorageName: StorageName.SORT_PRODUCT_APPRAISAL_ADDITEMS_GRID,
  singleClickEdit:true,
  rows:[],
  headerHeight : 28,
  rowHeight:28,
};

export enum AppraisalTabEnum {
  Jewellery = 1,
  DiamondAndGemstone = 2,
  Summary = 3
}

export enum DiamondStoneCategory {
  Diamonds = 10,
  Jewellery = 30,
  GemStones = 40,
}