import { Component, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { InsertCaratRequestModel } from 'src/app/components/administration/administration.model';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { IconHover } from '../../../enum/common-enum';

@Component({
  selector: "app-carat-popup",
  templateUrl: "./carat-popup.component.html",
  styleUrls: ["./carat-popup.component.scss"],
})
export class CaratPopupComponent extends CommonService {
  currentHoverEffect = IconHover.Pink;
  insertCaratRequestModel = new InsertCaratRequestModel();
  @ViewChild("CaratForm") CaratForm!: NgForm;
  formName = StorageName.FORMNAME_PRODUCT_PRODUCTOPENING;
  getAllDropdownModel: any = {};
  storageName = StorageName;

  constructor(
    public activeModal: NgbActiveModal,
    public administrationService: AdministrationService,
    public renderer: Renderer2,
    public toaster: ToastrService,
    public storageService: StorageService
  ) {
    super(renderer);
    this.getAllDropdownModel.purityDropdown = this.storageService.retrieve(
      StorageName.PURITYGROUP_DROPDOWN
    );
  }

  ngOnInit() {
    this.insertCaratRequestModel.active = true;
  }
  
  InserCaratList() {
    if (this.CaratForm.valid) {
      this.administrationService
        .insertCarat(this.insertCaratRequestModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if (res.isSuccess) {
            this.toaster.success(res.message);
            this.activeModal.close(true);
          } else {
            this.toaster.error(res.message);
          }
        });
    }
  }
}
