import { DepositInto, NameColWidth, PaymentMethod, StorageName } from "../../enum/common-enum";
import { StorageService } from "../../services/storage.service";
import { AgGridCustomDropdownComponent } from "../ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { AgGridCustomInputComponent } from "../ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { AgColDefsI, AgGridI } from "../ag-grid/ag-grid.model";
import { CustomHeaderComponent } from "../ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "../ag-grid/edit-delete-icon/edit-delete-icon.component";
import { InputDatepickerRendererComponent } from "../ag-grid/input-datepicker-renderer/input-datepicker-renderer.component";
import { TextRendererComponent } from "../ag-grid/text-renderer/text-renderer.component";
import { DateCellRendererComponent } from "../ag-grid/date-cell-renderer/date-cell-renderer.component";
import { TranslateModule } from "@ngx-translate/core";
import { DiamondCategory } from "../../models/common.model";


const PaymentListPaymentTypeColumn: AgColDefsI = {
  headerName: "Payment Type",
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "paymentMethodId",
  headerComponentParams: { isRequired: false, enableSorting: false },
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const paymentTypeDropdownList = storageService.retrieve(StorageName.PAYMENTTYPE_DROPDOWN);

    const paymentTypeOptionsList2 = ["MetalExchange", "OldJewellery", "M2A", "A2M"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
    const paymentTypeOptionsList3 = ["MetalExchange", "OldJewellery"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
    const paymentTypeOptionsList4 = ["Cash", "Bank", "Cheque", "UPI", "Card", "A2M", "MetalExchange"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
    const paymentTypeOptionsList5 = ["Cash", "Bank", "Cheque", "UPI", "Card"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);

    switch (params.colDef.storageName) {
      case StorageName.TRANSACTION_SALEINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESQUOTATION_PAYMENT_GRID:
      case StorageName.TRANSACTION_RECEIPTVOUCHER_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESORDER_PAYMENT_GRID:
      case StorageName.ORDERS_REPAIRORDER_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESRETURN_PAYMENT_GRID:
      case StorageName.TRANSACTION_PAYMENTVOUCHER_PAYMENT_GRID:
      case StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEORDER_PAYMENT_GRID:
      case StorageName.MANUFACTURER_JOBWORK_ORDER_PAYMENT_LIST_GRID:
      case StorageName.TRANSACTION_JOBWORKINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_DELIVERYNOTE_PAYMENT_GRID:
      case StorageName.TRANSACTION_REPAIRINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_JOBWORKORDER_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEQUOTATION_PAYMENT_GRID:
      case "Receipt Voucher":
      case "Payment Voucher":
        params.colDef.cellEditorParams = { values: () => { return paymentTypeDropdownList; } };
        break;
      case StorageName.TRANSACTION_PURCHASERETURN_PAYMENT_GRID:
        params.colDef.cellEditorParams = { values: () => { return paymentTypeOptionsList4; } };
        break;
      case StorageName.TRANSACTION_EXPENSES_PAYMENT_GRID:
      case StorageName.TRANSACTION_CREDITNOTE_PAYMENT_GRID:
      case StorageName.TRANSACTION_DEBITNOTE_PAYMENT_GRID:
      case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_WITHDRAW_GRID:
      case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_RECEIPT_GRID:
      case StorageName.TRANSACTION_EXPENSES_PAYMENT_LIST_GRID:
      case StorageName.TRANSACTION_NEW_LOAN_PAYMENT_GRID:

        params.colDef.cellEditorParams = { values: () => { return paymentTypeOptionsList5; } };
        break;


      case StorageName.TRANSACTION_MATERIALIN_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIALOUT_PAYMENT_GRID:
      case StorageName.MANUFACTURER_JOBWORK_QUEUE_PAYMENT_LIST_GRID:
        params.colDef.cellEditorParams = { values: () => { return paymentTypeOptionsList3; } };
        break;



      case StorageName.TRANSACTION_MATERIAL_ISSUE_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID:
        params.colDef.cellEditorParams = { values: () => { return paymentTypeOptionsList2; } };
        break;

      case StorageName.TRANSACTION_NEW_LOAN_PAYMENT_GRID:
        
        break;
    }

    return true;
  },
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 120,
  maxWidth: 250,
  flex: 2,
  isRequired: true,
  valueParser: (params) => String(params?.newValue || ''),
};


const TransferFromColumn: AgColDefsI = {
  headerName: "Transfer From",
  headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "transferFromId",
  headerComponentParams: { enableSorting: false },
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: (params) => {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      switch (Number(params.data.paymentMethodId)) {
        case PaymentMethod.Card:
          return storageService.retrieve(storage.CREDITCARD_DROPDOWN);
          break;
        case PaymentMethod.Cash:
          return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Cash_in_Hand) ?? [];
          break;
        case PaymentMethod.Bank:
        case PaymentMethod.Cheque:
        case PaymentMethod.UPI:
          return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Bank_Account || a.extra2 == DepositInto.Bank_OD_AC) ?? [];
          break;
      }
    }
  },
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 120,
  maxWidth: 250,
  flex: 2,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    switch (Number(params.data.paymentMethodId)) {
      case PaymentMethod.Card:
        params.colDef.cellEditorParams = { values: () => { return storageService.retrieve(storage.CREDITCARD_DROPDOWN); } }
        break;
      case PaymentMethod.Cash:
        params.colDef.cellEditorParams = { values: () => { return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Cash_in_Hand); } }
        break;
      case PaymentMethod.Bank:
      case PaymentMethod.Cheque:
      case PaymentMethod.UPI:
        params.colDef.cellEditorParams = { values: () => { return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Bank_Account || a.extra2 == DepositInto.Bank_OD_AC); } }
        break;
    }
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return (selcetedName == 'M2A' || selcetedName == 'MetalExchange' || selcetedName == "OldJewellery" || selcetedName == "A2M") ? false : true;
  },
  valueParser: (params) => String(params?.newValue || ''),
  isRequired: true,
  cellClassRules: {
    'hide_edit_icon': (params) => params?.data?.paymentMethodId == '60' || params?.data?.paymentMethodId == '70', 
    'show_edit_icon': (params) => params?.data?.paymentMethodId != '60' && params?.data?.paymentMethodId != '70', 
  },
};

const DepositeIntoColumn: AgColDefsI = {
  headerName: "Deposit Into",
  headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "depositeIntoId",
  headerComponentParams: { enableSorting: false },
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: (params) => {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      switch (Number(params.data.paymentMethodId)) {
        case PaymentMethod.Card:
          return storageService.retrieve(storage.CREDITCARD_DROPDOWN);
          break;
        case PaymentMethod.Cash:
          return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Cash_in_Hand) ?? [];
          break;
        case PaymentMethod.Bank:
        case PaymentMethod.Cheque:
        case PaymentMethod.UPI:
          return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Bank_Account || a.extra2 == DepositInto.Bank_OD_AC) ?? [];
          break;
      }
    }
  },
  cellClass: "px-0 custom-column-group-cell",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  flex: 2,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    switch (Number(params.data.paymentMethodId)) {
      case PaymentMethod.Card:
        params.colDef.cellEditorParams = { values: () => { return storageService.retrieve(storage.CREDITCARD_DROPDOWN); } }
        break;
      case PaymentMethod.Cash:
        params.colDef.cellEditorParams = { values: () => { return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Cash_in_Hand); } }
        break;
      case PaymentMethod.Bank:
      case PaymentMethod.Cheque:
      case PaymentMethod.UPI:
        params.colDef.cellEditorParams = { values: () => { return storageService.retrieve(storage.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Bank_Account || a.extra2 == DepositInto.Bank_OD_AC); } }
        break;
    }
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return (selcetedName == 'M2A' || selcetedName == 'MetalExchange' || selcetedName == "OldJewellery" || selcetedName == "A2M") ? false : true;
  },
  isRequired: true
};


export const PaymentListMetalColumn: AgColDefsI = {
  headerName: "Metal",
  headerComponentParams: { isRequired: false, enableSorting: false },
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "metalId",
  sortable: true,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.METAL_DROPDOWN);
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 120,
  maxWidth: 250,
  flex: 2,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == 'MetalExchange' || selcetedName == 'M2A' || selcetedName == 'OldJewellery' || selcetedName == "A2M"
  },
  valueGetter: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedItem = storeData?.find(a => a.id == params.data.paymentMethodId) ?? '';
    let metalId = storageService.retrieve(storage.METAL_DROPDOWN);
    // params.data.metalName = metalname[0]?.id || '';

    if (selcetedItem.name == 'MetalExchange' || selcetedItem.name == 'M2A' || selcetedItem.name == 'OldJewellery' || selcetedItem.name == "A2M") {
      metalId;
    } else {
      params.data.metalId = params.colDef.editable == false ? undefined : params.data.metalId;
    }
    return params.data.metalId;
  },

};

export const PaymentListCategoryColumn: AgColDefsI = {
  headerName: "Diamond Category",
  headerComponentParams: { isRequired: false, enableSorting: false },
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "diamondCategoryId",
  sortable: true,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
       storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
      let categoryId = storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
      let filteredCategories = categoryId.filter((category: any) => category.id !== DiamondCategory.Jewellery.toString());

      return filteredCategories
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 120,
  maxWidth: 250,
  flex: 2,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.METAL_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.metalId)?.name;
    return selcetedName == "Diamond & Stones"
  },
  valueGetter: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.METAL_DROPDOWN);
    const selcetedItem = storeData?.find(a => a.id == params.data.metalId) ?? '';
    let categoryId = storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);

    if (selcetedItem.name === "Diamond & Stones") {
      return params.data.diamondCategoryId; 
    } else {
      params.data.diamondCategoryId = null; 
      return null;
    }
  },

};

const PaymentListTransactionNumberColumn: AgColDefsI = {
  headerName: "Transaction No.",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 text-gray-color f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "transactionNo",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  headerComponentParams: { isRequired: false, enableSorting: false },
  minWidth: 120,
  maxWidth: 250,
  flex: 2,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 0,
    isOnlyNumbers: true,
  },
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == 'Cheque' || selcetedName == 'Bank' || selcetedName == 'UPI' || selcetedName == 'Card';
  },
};

const PaymentListItemCodeColumn: AgColDefsI = {
  headerName: "ItemCode",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 text-gray-color f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "itemCode",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  headerComponentParams: { isRequired: false, enableSorting: false },
  minWidth: 120,
  maxWidth: 250,
  flex: 2,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.id;
    return selcetedName == PaymentMethod.M2A || selcetedName == PaymentMethod.A2M || selcetedName == PaymentMethod.MetalExchange || selcetedName == PaymentMethod.OldJewellery;
  },
};

export const ProductColumn: AgColDefsI = {
  headerName: "Product",
  headerComponentParams: { isRequired: true, enableSorting: false },
  field: "productDetailId",
  minWidth: 120,
  maxWidth: 250,
  sortable: true,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == 'MetalExchange' || selcetedName == 'OldJewellery';
  },
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.PRODUCT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 ag_header_fontSize",
};

const ChequeDtNoColumn: AgColDefsI = {
  headerName: "Cheque Dt. ",
  field: "chequeDate",
  minWidth: 140,
  maxWidth: 250,
  flex: 1,
  resizable: false,
  isRequired: false,
  headerClass: "custom-column-group-cell ag_header_fontSize",
  cellClass: "custom-column-group-cell",
  headerComponentParams: {
    enableMenu: true,
    isRequired: false,
    enableSorting: false
  },
  cellRenderer: DateCellRendererComponent,
  cellEditor: InputDatepickerRendererComponent,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return (selcetedName == 'Cheque') ? true : false;
  },

}

export const PaymentListActionColumn: AgColDefsI = {
  headerName: "",
  suppressNavigable: true,
  editable: false,
  pinned: "right",
  headerComponent: CustomHeaderComponent,
  headerClass: "justify-content-end  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellClass: "custom-column-group-cell",
  cellRendererParams: { editIcon: true, focusOnColumn: "paymentType", isEditRowOnEdit: true, gridName: "paymentList" },
  headerComponentParams: { enableMenu: false, moreButton: true, enableSorting: false, moreButtonId: 'addMorePaymentBtn' },
  minWidth: 80,
  maxWidth: 80,
  lockPosition: 'right',
  lockPinned: true,
  suppressMovable: false,
  field: "action",
  extraField: "Action"
};

export const PaymentListWeightColumn: AgColDefsI = {
  headerName: "Weight",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end ag_header_fontSize",
  field: "grossWt",
  sortable: true,
  headerComponentParams: { enableSorting: false },
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  maxWidth: 250,
  flex: 1,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  },
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == 'M2A' || selcetedName == 'MetalExchange' || selcetedName == "OldJewellery";
  },
};

export const PaymentListQuantityColumn: AgColDefsI = {
  headerName: "Quantity",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end ag_header_fontSize",
  field: "quantity",
  sortable: true,
  headerComponentParams: { enableSorting: false },
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  maxWidth: 250,
  flex: 1,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  },
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == 'M2A' || selcetedName == 'MetalExchange' || selcetedName == "OldJewellery";
  },
};

export const PaymentListPurityColumn: AgColDefsI = {
  headerName: "Purity / Carat",
  headerComponentParams: { isRequired: false, enableSorting: false },
  field: "purityPer",
  minWidth: 130,
  maxWidth: 250,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  },
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == 'M2A' || selcetedName == 'MetalExchange' || selcetedName == "OldJewellery";
  },
  cellClass: "custom-column-group-cell px-0 text-end",
  headerClass: "custom-column-group-cell px-0 ag_header_fontSize",
};

export const PaymentListPurityWtColumn: AgColDefsI = {
  headerName: "Purity Wt",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "purityWt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  headerComponentParams: { enableSorting: false },
  minWidth: 120,
  maxWidth: 250,
  flex: 1,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  },
  // editable: (params) => 
  // {
  //   const storageService = new StorageService();
  //   const storage = StorageName;
  //   const storeData =  storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
  //   const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
  //   return  selcetedName == 'MetalExchange'  || selcetedName ==  "OldJewellery";
  // },
};

const PaymentListRateColumn: AgColDefsI = {
  headerName: "Rate",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize ag_header_end",
  field: "rate",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  headerComponentParams: { enableSorting: false },
  minWidth: 90,
  maxWidth: 250,
  flex: 1,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 2,
    isOnlyNumbers: true,
  },
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == 'M2A' || selcetedName == 'MetalExchange' || selcetedName == "OldJewellery" || selcetedName == "A2M";
  },
};

const PaymentListAmountColumn: AgColDefsI = {
  headerName: "Amount",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end ag_header_fontSize",
  field: "amount",
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  maxWidth: 250,
  flex: 1,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == "A2M" || selcetedName == "Bank" || selcetedName == "OldJewellery" || selcetedName == "Card" || selcetedName == "Cash" || selcetedName == "Cheque" || selcetedName == "UPI";
  },
  cellRenderer: TextRendererComponent,
  headerComponentParams: { enableSorting: false },
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 2,
    isOnlyNumbers: true,
  }
};

const PaymentListBalanceColumn: AgColDefsI = {
  headerName: "Balance",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end ag_header_fontSize",
  field: "balance",
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  maxWidth: 250,
  flex: 1,
  editable: (params) => {
    const storageService = new StorageService();
    const storage = StorageName;
    const storeData = storageService.retrieve(storage.PAYMENTTYPE_DROPDOWN);
    const selcetedName = storeData?.find(a => a.id == params.data.paymentMethodId)?.name;
    return selcetedName == "A2M" || selcetedName == "Bank" || selcetedName == "OldJewellery" || selcetedName == "Card" || selcetedName == "Cash" || selcetedName == "Cheque" || selcetedName == "UPI";
  },
  cellRenderer: TextRendererComponent,
  headerComponentParams: { enableSorting: false },
  cellEditor: AgGridCustomInputComponent,
  cellEditorParams: {
    decimalPlaces: 2,
    isOnlyNumbers: true,
  }
};

const paymentListColumnDef: AgColDefsI[] = [
  PaymentListPaymentTypeColumn,
  PaymentListCategoryColumn,
  PaymentListTransactionNumberColumn,
  TransferFromColumn,
  DepositeIntoColumn,
  ProductColumn,
  ChequeDtNoColumn,
  PaymentListWeightColumn,
  PaymentListMetalColumn,
  PaymentListQuantityColumn,
  PaymentListPurityColumn,
  PaymentListPurityWtColumn,
  PaymentListRateColumn,
  PaymentListAmountColumn,
  PaymentListActionColumn,
  PaymentListItemCodeColumn,
  PaymentListBalanceColumn
];
export const agGridDataModelPaymentTable: AgGridI = {
  colDefs: paymentListColumnDef,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  columnSettings: true,
  singleClickEdit: true,
  moreButton: true,
  tableId: StorageName.TRANSACTION_SALEINVOICE_PAYMENT_GRID,
  rows: [],
  rowHeight: 28,

};

export class PaymentSectionModel {
  agGridDataModel: AgGridI = {
    colDefs: agGridDataModelPaymentTable.colDefs,
    rowSelection: "multiple",
    moreButton: true,
    columnSettings: true,
    rowHeight: 28,
  };
  voucherType: string;
  formData: any;
  additionalAmountList: any;
  storageName: string;
  enableAddStoneDiamond : boolean;
  setVisibility(gridName: any, enableAddStoneDiamond : boolean): any {
    this.enableAddStoneDiamond = enableAddStoneDiamond;
    this.agGridDataModel = this.setNewColDef(this.agGridDataModel, gridName);
  }

  setNewColDef(grirDataModel, storageName, addMoreItemBtnId?): any {

    var editable = (storageName == StorageName.MANUFACTURER_JOBWORK_QUEUE_ITEM_GRID ? true : ((storageName == StorageName.TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID) ? false : undefined));
    grirDataModel.colDefs = grirDataModel.colDefs.map((item, index) => ({
      ...item,
      headerComponentParams: {
        ...item.headerComponentParams,
        storageName: storageName,
        moreButtonId: addMoreItemBtnId
      },
      storageName: storageName,
      editable: editable == undefined ? item.editable : editable
    }));
    grirDataModel.storageName = storageName;
    return grirDataModel;
  }

}
