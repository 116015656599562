import { Injectable, EventEmitter } from '@angular/core';
import { StorageService } from './storage.service';
import { StorageName } from '../enum/common-enum';

@Injectable({
  providedIn: 'root'
})
export class TextProcessingService {
  public calculatedMessageNumber = new EventEmitter<number>();

  constructor(public storageService : StorageService) {}

 fixAmountDecimal(number, noOfDecimal: number = 2): any {
  noOfDecimal = this.storageService.retrieve(StorageName.AMOUNT_DECIMAL) ?? 2;
    if (number == undefined)
      number = 0;
    number = Number(number);
    var data = number.toFixed(noOfDecimal);
    return data === 'NaN' ? '0.00' : data;
  }

  fixWeightDecimal(number, noOfDecimal: number = 3): any {
    if (number == undefined)
      number = 0;
    number = Number(number);
    var data = number.toFixed(noOfDecimal);
    return data;
  }

  fixWeightDecimalString(number, noOfDecimal: number = 3): any {
    if (number == undefined)
      number = 0;
    number = Number(number);
    var data = number.toFixed(noOfDecimal);
    return data;
  }

  updateCharacterAndWordCount(text: string): { words: number; characters: number; messageNumber: number } {
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = text || '';
    const plainText = tempContainer.innerText || '';
    const words = plainText.trim().split(/\s+/).filter(word => word.length > 0).length;
    const characters = plainText.replace(/\s+/g, '').length;
    const messageNumber = this.calculateMessageNumber(characters);

    this.calculatedMessageNumber.emit(messageNumber);

    return { words, characters, messageNumber };
  }
  updateCharacterCount(text: string): { characterCount: number; messageNumber: number } {
    const characterCount = text.replace(/\s+/g, '').length; // Count characters excluding whitespace
    const messageNumber = this.calculateMessageNumber(characterCount);

    this.calculatedMessageNumber.emit(messageNumber); // Emit the calculated message number

    return { characterCount, messageNumber };
  }
  private calculateMessageNumber(characterCount: number): number {
    return Math.ceil(characterCount /300); 
  }
}
