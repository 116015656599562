import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { metalTypeId } from 'src/app/components/financial-statement/transaction-report/transaction-report.component';
import { InvoiceService } from 'src/app/components/Transaction/invoice/invoice.service';
import { AgGridDropdown, DepositInto, PaymentMethod, StorageName } from 'src/app/shared/enum/common-enum';
import { MetalType } from 'src/app/shared/models/common.model';
import { IpcRenderService } from 'src/app/shared/services/ipc-render.service';
import { StorageService } from 'src/app/shared/services/storage.service';
export interface DropdownIcons{
  plusIcon:boolean,
  dropdownVal:string
 }

@Component({
  selector: 'app-ag-grid-custom-dropdown',
  templateUrl: './ag-grid-custom-dropdown.component.html',
  styleUrls: ['./ag-grid-custom-dropdown.component.scss']
})
export class AgGridCustomDropdownComponent implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild("container", { read: ViewContainerRef }) public container;
  constructor(public ipcRenderService : IpcRenderService, public storageService : StorageService ,private renderer: Renderer2 , public invoiceService: InvoiceService,) {}
  oldValue;
  values:any;
  drpdownVal:any
  value: string;
  dropdownname:string
  storageName = StorageName;
 public params: any;
  agGridDropdown = AgGridDropdown;
  valueChanged = new EventEmitter<string>();
  tabIdSubscription: Subscription;
  dropdownvalue
  tabId:any
  enumDepositInto = DepositInto;
  selcetedName:any
  ngAfterViewInit(): void {
    this.params.api.addEventListener('cellEditingStarted', event => {
      const select =  document.querySelector("#custom_select_options") as HTMLSelectElement | any;
      if(select){
        // select?.focus();
        // select?.showPicker()
      }
    })
    // for focus dropdown
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === "ArrowRight" || event.key === "ArrowLeft"){
      event.preventDefault();
      return;
    }

    const select =  document.querySelector("#custom_select_options") as HTMLSelectElement | any;
    if (event.key === "ArrowDown" && document.activeElement !== select) 
        event.preventDefault(); 
      
        console.log(event.key)
    if (event.key === "ArrowDown") 
    {
      if(select){
        console.log("keydown pressed")
        select?.focus();
        if(this.ipcRenderService.isRunningInElectron == false) {
          // Attempt to use showPicker
          try {
            if(select.showPicker) {
              select.showPicker();
            } else {
              // Fallback to simulating a click if showPicker is not available
              select.click();
            }
          } catch (e) {
            console.error("showPicker or click simulation failed:", e);
            // Implement any fallback logic if needed
          }
        } else {
          // Handle the case when running in Electron
          // Fallback logic or other handling can be placed here
          select.click();
        }
      }
    }
    
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      console.log('ArrowDown key pressed');
      // Add your custom logic here
    }
  }

  agInit(params: ICellEditorParams<any> & DropdownIcons): void {
    this.params = params;
    const valuesOrFunction = params.colDef.cellEditorParams?.values;
    this.values = typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction ?? [];

    if ((this.params?.value != undefined && this.params?.value != "" && this.params?.value != "0") && this.values?.length > 0 && (!this.values?.some(value => (value?.id == null) ? '0' : value?.id.toString() === this.params?.value.toString())))
      this.params.value = undefined;
   // this.value = this.values?.length > 0 && (this.params?.value == undefined || this.params?.value == "" || this.params?.value == "0") ? (this.values[0]?.id == null ? undefined : this.values[0]?.id?.toString()) : this.params?.value;

    this.tabIdSubscription = this.invoiceService.TabId.subscribe((TabId) => {
      this.tabId = TabId;
    });

    if (params.colDef.field == 'productDetailId') {
      if (params?.data?.metalDetailId == undefined || params?.data?.metalId != undefined)
        params.data.metalDetailId = params?.data?.metalId;
      var products = params?.colDef?.cellEditorParams?.values();
      var filteredProducts = products?.filter(a => (
        (Number(params.data.paymentMethodId) == PaymentMethod.M2A
          || Number(params.data.paymentMethodId) == PaymentMethod.MetalExchange
          || Number(params.data.paymentMethodId) == PaymentMethod.OldJewellery
          || Number(params.data.paymentMethodId) == PaymentMethod.A2M
          || (params.colDef.headerComponentParams.storageName == StorageName.MANUFACTURER_JOBWORK_QUEUE_ITEM_GRID)
        )
        &&
        (a.extra1 == params.data.metalDetailId)
      )
        || (params.data.paymentMethodId == undefined && params.data.metalDetailId == undefined && a.extra1 == this.tabId?.toString())
      ) ?? [];
      this.values = params.colDef.headerComponentParams.storageName == this.storageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_BYAPI_GRID || params.colDef.headerComponentParams.storageName == this.storageName.TRANSACTION_ADD_STONEGEMS_IN_PAYMENT_BYAPI_GRID || params.colDef.headerComponentParams.storageName == this.storageName.MANUFACTURER_JOBWORKQUEUE_SUMMARY_GRID ? products : filteredProducts
    }

    if (params.colDef.field == 'goldCaratId' || params.colDef.field == 'caratId') {
      var metalId =  params?.data?.metalId ?? this.tabId;
      metalId = (metalId == MetalType.Diamond) ? MetalType.Gold : metalId;

      var carates = params?.colDef?.cellEditorParams?.values();
      var filteredCarates = carates?.filter(a => (a.extra1 == metalId?.toString())
      ) ?? [];
      var caratWithNullId = carates?.find(a => a.id === null);
      if (caratWithNullId) {
          if (!filteredCarates.some(a => a.id === null)) {
              filteredCarates.unshift(caratWithNullId);
          }
      }

      this.values = filteredCarates ?? carates;
    }
    this.value = this.values?.length > 0 && (this.params?.value == undefined || this.params?.value == "" || this.params?.value == "0") ? (this.values[0]?.id == null ? undefined : this.values[0]?.id?.toString()) : this.params?.value;
  }
  // onKeydown(event: KeyboardEvent) {
  //   if (event.key === 'ArrowDown') {
  //     console.log('ArrowDown key pressed');
  //     // Add your custom logic here
  //   }
  // }


  getDropdown(val:string):string{
  let dropdownList =  this.storageService.retrieve(val)
    return dropdownList;
  }

  getValue() {
    // if(this.params.colDef.field == "purityPer")
    // {
    //   return (this.value);
    // }
    // else
    // {
      return (this.value != undefined && this.value != null && this.value != 'null') ?  this.value.toString() : '0';
    // }
    
  }
  isPopup?(): boolean {
    return false;
  }
  getPopupPosition?(): any {
    return 'under'
  }
  isCancelBeforeStart?(): boolean {
    return false;
  }
  isCancelAfterEnd?(): boolean {
    return false
  }
  focusIn?(): void {
  
  }
  focusOut?(): void {
  
  }
  afterGuiAttached?(): void {
    this.getValue()
  }
  ngOnInit(): void {
   
  }



  cellEditingStopped(event) {
    // Logic on cell editing stopped
  }

  cellValueChanged() {
    // Logic on cell value changed
   this.valueChanged.emit(this.value); 
  }

  selected(item) {
    this.dropdownvalue = item;
    const valuesOrFunction = this.values;
    this.selcetedName = valuesOrFunction.find(a => a.id == item).name
    this.setZeroValue(this.selcetedName);
    this.params?.node?.setDataValue(this.params?.colDef?.field ,Number(item));
    this.valueChanged.emit(this.dropdownvalue); 
    if(this.params?.colDef?.field == 'depositeIntoId' ||  this.params?.colDef?.field == 'paymentMethodId' || this.params?.colDef?.field == 'status' || this.params?.colDef?.field == 'entryBy'){
      this.params?.node?.setDataValue(this.params?.colDef?.field ,Number(item));
    }else{
      this.params?.node?.setDataValue(this.params?.colDef?.field ,item);
    }
    if(this.params?.colDef?.field == 'status')
    {
      this.params.data.statusName = this.selcetedName ;
    }
  }
  



newdate():string
  {
    const currentDate = new Date();
    const datePipe = new DatePipe('en-US');
    return  datePipe?.transform(currentDate, 'yyyy-MM-ddTHH:mm:ss.SSS') || '';
  }

setZeroValue(selcetedName)
  {
    if(selcetedName !== "Fix" && this.params.colDef.field == 'makingTypeId')
    {
      this.params.data.makingAmount ="0.00";
      this.params.api.refreshCells({ columns: ["makingAmount"] });
    }
    if(selcetedName == "Fix" && this.params.colDef.field == 'makingTypeId')
    {
      this.params.data.makingRate ="0.0";
      this.params.api.refreshCells({ columns: ["makingRate"] });
    }
    if(selcetedName == "MRP" && this.params.colDef.field == 'makingTypeId')
    {
      this.params.data.makingAmount ="0.00";
      this.params.data.makingRate ="0.00";
      this.params.data.makingDiscount ="0.00";
      this.params.api.refreshCells({ columns: ["makingRate", "makingDiscount","makingAmount"] });
    }
  }
changeChequeDate(selcetedName)
  {
    if ((this.params.colDef.field == 'paymentMethodId' || this.params.colDef.field == 'paymentType')  && selcetedName == 'Cheque')
    {
      this.params.data.chequeDate = this.newdate();
      this.params.api.refreshCells({ columns: ["chequeDate"] });
    }
    else
    {
      this.params.data.chequeDate = null;
      this.params.api.refreshCells({ columns: ["chequeDate"] });
    }
  }
  
  ngOnDestroy() 
  {
    this.tabIdSubscription?.unsubscribe();
  }

}