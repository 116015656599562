import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GetCountryDetailsResponse, LicenseKeyGenerateRequestModel, LicenseKeyGenerateResponse, LicenseKeyVerifyModelRequest, OTPResponse, ProductKeyResponseModel, ResendOTPModel, UserSendOTPModel, VerifyOTPModel } from 'src/app/auth/auth/auth.model';
import { AuthService } from 'src/app/auth/auth/auth.service';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  otp1: any;
  otp2: any;
  otp3: any;
  otp4: any;
  otp5: any;
  otp6: any;

  resendOTPModel = new ResendOTPModel();
  verifyOTPRequestModel = new VerifyOTPModel();
  productKeyResponseModel = new ProductKeyResponseModel();
  licenseKeyVerifyModelRequest = new LicenseKeyVerifyModelRequest();
  licenseKeyGenerateRequestModel = new LicenseKeyGenerateRequestModel();
  licenseKeyGenerateResponse = new LicenseKeyGenerateResponse();
  selectedDialCode: string;
  phoneNumber: string;
  storeToken: string;
  selectedCountryCode: string;
  getCountryDetailsResponseList: GetCountryDetailsResponse[] = [];

  maxLength: number = 10;
  pattern = '[0-9]{10}'
  pattern2 = '[0-9]{9}'

  submitedOTP: boolean = false;
  isLoading: boolean = false;
  isCreateAccount: boolean = true;
  isProceed: boolean = false;
  isVarify: boolean = false;
  isContinue: boolean = false;
  userSendOTPRequestModel = new UserSendOTPModel();
  otpResponseModel = new OTPResponse();

  isBusinessRegistrationView = false;
  countryName
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private storageservice: StorageService,
    public renderer: Renderer2,
    private toaster: ToastrService,
    public sendDataService: SendDataService,
    private location : Location,
  ) {
    // super(renderer);

  }
  ngOnInit(): void {
    this.getCountryDetails();
    this.sendDataService.displayPageName('Add Company');
  }

  // get countryDetail api
  getCountryDetails() {
    this.authService.GetCountryDetailsDropdown().subscribe((res) => {
      if (res.isSuccess) {
        this.getCountryDetailsResponseList = res?.response;
      }
    })
  }

  onChangeDialCode(event) {
    this.selectedDialCode = event.target.value;
    // if (this.selectedDialCode == '971') {
    //   this.maxLength = 9;
    // } else {
    //   this.maxLength = 10;
    // }
    this.maxLength = 12;
  }

  back() {
    this.isProceed = false;
    // Added by T
    this.otp1 = '';
    this.otp2 = '';
    this.otp3 = '';
    this.otp4 = '';
    this.otp5 = '';
    this.otp6 = '';
    // End
  }

  // moveFocusToSecondInput(event: KeyboardEvent, id, secondId) {

  //   if (event.key === 'Backspace') {
  //     const firstInput = document.getElementById(secondId) as HTMLInputElement;
  //     if (firstInput) {
  //       firstInput.value = '';
  //       firstInput.focus()// Clear the value of the first input
  //     }
  //   } else {
  //     const secondInput = document.getElementById(id) as HTMLInputElement;
  //     secondInput.focus();
  //     if (this.otp6 !== undefined && this.otp6 !== null) {
  //       this.varifyOTP()
  //     }
  //   }
  // }

  moveFocusToSecondInput(event: KeyboardEvent, id: string, previousId: string) {
    const currentInput = event.target as HTMLInputElement;
    if (event.key === 'Backspace') {
      if (currentInput.value === '') {
        const previousInput = document.getElementById(previousId) as HTMLInputElement;
        if (previousInput) {
          previousInput.focus();
          previousInput.select();
        }
      } else {
        currentInput.value = '';
      }
    } else if (currentInput.value !== '') {
      const nextInput = document.getElementById(id) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
        nextInput.select();
      }
    }
  }
  moveFocusToSecondInput1(event: KeyboardEvent, id: string, previousId: string) {
    const currentInput = event.target as HTMLInputElement;
  
    if (event.key === 'Backspace') {
        if (currentInput.value === '') {
            const previousInput = document.getElementById(previousId) as HTMLInputElement;
            if (previousInput) {
                previousInput.focus();
            }
        }
    } else {
        if (currentInput.value !== '') {
            const nextInput = document.getElementById(id) as HTMLInputElement;
            if (nextInput) {
                nextInput.focus();
            }
        }
    }
  }


  getOTP() {
    //this.phoneNumber
    if (this.phoneNumber && this.selectedDialCode && this.selectedCountryCode) {
      this.userSendOTPRequestModel.phoneNumber = this.selectedDialCode + this.phoneNumber;
      this.userSendOTPRequestModel.countryCode = this.selectedCountryCode;
      //this.userSendOTPRequestModel.countryCode = this.getCountryDetailsResponseList.find(item => item.dialCode == this.selectedDialCode)?.countryCode;
      this.authService.GetOTP(this.userSendOTPRequestModel).subscribe((res) => {
        if (res.isSuccess) {
          this.otpResponseModel.otpToken = res.response.otpToken;
          this.storeToken = res.response.otpToken;
          this.toaster.success(res.message);
          this.isProceed = true
        }
      })
    }
  }


  onChangeCountry(event) {
    this.selectedCountryCode = event.target.value;
    const countryObj = this.getCountryDetailsResponseList.find(item => item.countryCode == this.selectedCountryCode);
    this.selectedDialCode  = countryObj?.dialCode;
    this.countryName = countryObj?.countryName;
  }
  varifyOTP() {
    if (this.otp1 + this.otp2 + this.otp3 + this.otp4 + this.otp5 + this.otp6) {
      this.verifyOTPRequestModel.phoneNumber = this.selectedDialCode + this.phoneNumber;
      this.verifyOTPRequestModel.otp = this.otp1 + this.otp2 + this.otp3 + this.otp4 + this.otp5 + this.otp6;
      this.verifyOTPRequestModel.otpToken = this.storeToken;
      this.authService.VerifyOTP(this.verifyOTPRequestModel).subscribe((res) => {
        if (res.isSuccess && res.message != 'Invalid OTP.') {
          this.toaster.success(res.message);
          this.isVarify = true;
          this.isProceed = false;
          this.isCreateAccount = false;
          this.isContinue = true;
          this.getProductKey();
        } else {
          this.toaster.error("Invalid OTP")
        }
      });
    }
    else {
      this.submitedOTP = true;
    }
  }

  reSendOTP() {
    if (this.phoneNumber && this.selectedDialCode) {
    this.resendOTPModel.phoneNumber = this.selectedDialCode + this.phoneNumber;
    this.resendOTPModel.otpToken = this.storeToken;
    this.authService.ReSendOTP(this.resendOTPModel).subscribe((res) => {
      if (res.isSuccess == true) {
        this.storeToken = res.response.otpToken;
        this.toaster.success(res.message);
         // Added by T
         this.otp1 = '';
         this.otp2 = '';
         this.otp3 = '';
         this.otp4 = '';
         this.otp5 = '';
         this.otp6 = '';
         // End
      } else {
        this.toaster.error(res.message)
      }
    })
  }
  }

  getLicenseKey() {
    this.licenseKeyGenerateRequestModel.productKey = this.productKeyResponseModel.productKey;
    this.licenseKeyGenerateRequestModel.OTPToken = this.storeToken;
    this.authService.GetLicenseKey(this.licenseKeyGenerateRequestModel).subscribe((res) => {
      this.licenseKeyGenerateResponse = res.response;
      this.toaster.success("License Key Generate");
    })
  }

  getProductKey() {
    this.authService.GetProductKey().subscribe((res) => {
      if (res.isSuccess) {
        this.productKeyResponseModel.productKey = res?.response.productKey;
      }
    })
  }

  verifyLicenseKey() {
    this.licenseKeyVerifyModelRequest.productKey = this.licenseKeyGenerateResponse.productKey;
    this.licenseKeyVerifyModelRequest.licenseKeyToken = this.licenseKeyGenerateResponse.licenseKeyToken;
    this.authService.VerifyLicenseKey(this.licenseKeyVerifyModelRequest).subscribe({
      next:(res) => {
        if (res.isSuccess) {
          this.isLoading = true;
          this.licenseKeyGenerateResponse = res.response;
          let storelicenseKeyToken = res.response.licenseKeySecurityToken;
          this.storageservice.store("storelicenseKeyToken", storelicenseKeyToken);
          this.toaster.success(res?.message);
          this.storageservice.store('country' , this.countryName)
          this.isBusinessRegistrationView = true;
          // this.router.navigate(['/account/business-registration'])
        }else{
          this.toaster.error("The Licence Key Field Required");
        }
      },
      error:(err) =>{
        this.toaster.error("The Licence Key Field Required"); 
      }
    })
  }

  close(){
    this.location.back();
  }
}
