<ng-container >
    <div class="modal-body mx-3 px-4">
        <div class="save_icon text-center d-flex justify-content-center align-items-center">
            <svg id="Group_4453" data-name="Group 4453" xmlns="http://www.w3.org/2000/svg" width="40.139" height="40.135" viewBox="0 0 60.139 60.135">
                <path id="Path_2405" data-name="Path 2405" d="M321.848-1258.059a30.1,30.1,0,0,1,30.056,29.953,30,30,0,0,1-30.312,30.182,29.972,29.972,0,0,1-29.827-29.968A30.047,30.047,0,0,1,321.848-1258.059Z" transform="translate(-291.764 1258.059)" fill="#e11330"/>
                <path id="Path_2406" data-name="Path 2406" d="M398.632-1154.222c.223.044.324.217.453.346q4.522,4.515,9.03,9.045c.368.372.552.432.959.023,3.022-3.033,6.079-6.03,9.109-9.054.335-.334.529-.392.873-.012a23.372,23.372,0,0,0,1.95,1.946c.451.394.331.6-.032.956-3.041,2.984-6.056,5.993-9.1,8.975-.359.353-.405.53-.022.912,3.044,3.039,6.057,6.11,9.1,9.156.323.324.368.5.009.829-.679.618-1.327,1.272-1.95,1.948-.31.336-.484.281-.782-.016q-4.5-4.489-9.032-8.953c-.594-.588-.593-.588-1.161-.02q-4.474,4.474-8.94,8.957c-.322.325-.5.37-.832.02-.646-.681-1.328-1.331-2.026-1.959-.325-.293-.324-.465-.012-.778,3.038-3.046,6.051-6.117,9.1-9.155.392-.392.382-.577-.006-.96-3.015-2.98-5.994-6-9.014-8.971-.416-.41-.363-.608.021-.961.692-.634,1.337-1.321,2-1.984C398.426-1154.035,398.534-1154.13,398.632-1154.222Z" transform="translate(-378.535 1171.613)" fill="#fff"/>
            </svg>              
        </div>
        <div>
            <p class="title text-lighter-gray font-semibold f-16 m-0 text-center">{{data?.title}}</p>
            <p class="text-wrap font-regular text-gray-color d-flex align-items-center pt-2 f-15 mb-2 justify-content-center text-center">{{ data?.message || 'Error'  }}</p>
        </div>
    </div>
    <div class="modal-footer border-0">
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="activeModal.close(false)"><span tabindex="0" (keydown.enter)="onKeyDown($event)" class="text-gray-color px-2 custom_focus font-semibold f-16">Ok</span></div>
    </div>
</ng-container>