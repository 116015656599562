import { StorageName } from "../../enum/common-enum";
import { AgColDefsI, AgGridI } from "../ag-grid/ag-grid.model";
import { CustomHeaderComponent } from "../../../shared/common-components/ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "../../../shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { JwellaryImageComponent } from "../../../shared/common-components/ag-grid/jwellary-image/jwellary-image.component";
import { AgGridInnerHTMLDisplayComponent } from "../../../shared/common-components/ag-grid/ag-grid-inner-htmldisplay/ag-grid-inner-htmldisplay.component";
import { TextRendererComponent } from "../ag-grid/text-renderer/text-renderer.component";

const TaxTableTaxIdColumn: AgColDefsI = {
  headerName: "Tax",
  field: "taxId",
  sortable: true,
  hide : true,
  minWidth: 80,
  flex: 1,
  cellRenderer: TextRendererComponent,
};
const TaxTableTaxColumn: AgColDefsI = {
  headerName: "Tax",
  field: "taxName",
  sortable: false,
  headerClass:
    "justify-content-center  custom-column-group-cell text-gray-color font-semibold custom-column-group-cell",
  minWidth: 80,
  flex: 1,
  cellRenderer: TextRendererComponent,
};

const TaxTableValueColumn: AgColDefsI = {
  headerName: "Value",
  field: "taxAmount",
  minWidth: 80,
  flex: 1,
  cellRenderer: TextRendererComponent,
  // valueFormatter: params => params.data.taxAmount.toFixed(2),
};

const taxListTableColumnDef: AgColDefsI[] = [
  TaxTableTaxIdColumn,
  TaxTableTaxColumn,
  TaxTableValueColumn,
];

export const agGridDataModelTax: AgGridI = {
  colDefs: taxListTableColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_TAX_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[],
  // tableId : StorageName.FORMNAME_TRANSACTION_SALESINVOICE_TAX_TABLE
};

export class TaxSectionModel {
  storageName : string;
  setVisibility(storageName : any)
  {
    switch(storageName)
    {
      case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
        this.storageName =StorageName.TRANSACTION_PURCHASEINVOICE_TAX_GRID
        break;
        case StorageName.FORMNAME_TRANSACTION_PURCHASEQUOTATION:
          this.storageName =StorageName.TRANSACTION_PURCHASEQUOTATION_TAX_GRID
          break;

      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
        this.storageName =StorageName.TRANSACTION_JOBWORKINVOICE_TAX_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
        this.storageName = StorageName.TRANSACTION_SALEINVOICE_TAX_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
        this.storageName = StorageName.TRANSACTION_PURCHASERETURN_TAX_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        this.storageName = StorageName.TRANSACTION_SALESRETURN_TAX_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESORDER:
        this.storageName = StorageName.TRANSACTION_SALESORDER_TAX_GRID
      break;
      case StorageName.FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE:
        this.storageName = StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_TAX_GRID
      break;
      case StorageName.FORMNAME_ORDERS_REPAIRORDER:
        this.storageName = StorageName.ORDERS_REPAIRORDER_TAX_GRID
      break;
      case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
        this.storageName = StorageName.TRANSACTION_REPAIRINVOICE_TAX_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
        this.storageName = StorageName.TRANSACTION_SALESQUOTATION_TAX_GRID
      break;
      case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
        this.storageName = StorageName.TRANSACTION_DELIVERYNOTE_TAX_GRID
      break;
      case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
        this.storageName = StorageName.TRANSACTION_CREDITNOTE_TAX_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
        this.storageName = StorageName.TRANSACTION_DEBITNOTE_TAX_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
        this.storageName = StorageName.TRANSACTION_PURCHASEORDER_TAX_GRID
      break;
      // case StorageName.FORMNAME_TRANSACTION_RECEIPTVOUCHER:
      //   this.storageName = StorageName.TRANSACTION_RECEIPTVOUCHER_TAX_GRID
      // break;
      // case StorageName.FORMNAME_TRANSACTION_PAYMENTVOUCHER:
      //   this.storageName = StorageName.TRANSACTION_PAYMENTVOUCHER_TAX_GRID
      // break;
    }
  }
}