import { Component, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { InsertCategoryRequestModel } from 'src/app/components/administration/administration.model';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { IconHover } from '../../../../../shared/enum/common-enum';

@Component({
  selector: 'app-addeditcategory',
  templateUrl: './addeditcategory.component.html',
  styleUrls: ['./addeditcategory.component.scss']
})
export class AddeditcategoryComponent extends CommonService{

  @ViewChild("CategoryForm") CategoryForm!: NgForm;
  insertCategoryRequestModel = new InsertCategoryRequestModel();
  currentHoverEffect = IconHover.Pink;
  constructor(public activeModal: NgbActiveModal,
    public administrationService    : AdministrationService,
    public renderer                 : Renderer2,
    public toaster                  : ToastrService,) {
      super(renderer)
    }
    ngOnInit(): void {
      this.insertCategoryRequestModel.active = true;
    }
  InserCategoryList() {
    if (this.CategoryForm.valid) {
      this.administrationService.insertCategory(this.insertCategoryRequestModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if (res.isSuccess) {
            this.toaster.success(res.message);
            this.activeModal.close(true);
          } else {
            this.toaster.error(res.errors[0]);
          }
        });
    }
  }

}
