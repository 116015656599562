import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {CheckboxRendererService} from './checkbox-renderer.service'
import { AgGridService } from '../ag-grid.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss']
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  params: any;
  checked: boolean=false;
  labelName:string ='';
  showLabel: boolean = true; 
  isReadOnly:boolean;
  userId:number;
  isCursor: boolean;
  isCommonCheckbox: boolean = false;
  isCommonReverseTax:boolean =false;
  isSms:boolean =false;
  isWhatsapp:boolean =false;
  isReturnWeight:boolean =false;
  isAddWeight:boolean =false;
  isAML:boolean =false;
  isKYC:boolean =false;
  isEmail:boolean =false;
  isSelected:boolean =false;
  enable:boolean =false;
  stopOnError:boolean =false;
  currentUrl: string;
  customCheckbox: boolean = false;
  constructor(private checkboxRendererService:CheckboxRendererService,
    public agGridService : AgGridService,
   private router: Router
  ){

  }
  agInit(params: any): void {
    this.params = params;
    this.currentUrl = this.router.url;

    if (this.params?.isCommonCheckbox == true 
      || this.params?.isCommonReverseTax == true 
      || this.params?.isSms == true 
      || this.params?.isAML 
      || this.params?.isKYC
      || this.params?.isWhatsapp == true 
      || this.params?.isEmail == true 
      || this.params?.isSelected ==true 
      || this.params?.enable == true  
      || this.params?.stopOnError == true  
      ||this.params?.isReturnWeight==true ||this.params?.isAddWeight==true)  {
      this.checked = this.params?.value;
  }
    else{
          this.checked = this.params?.data?.active??false;
          this.labelName = this.params.data.name; 
          this.customCheckbox = this.params?.customCheckbox;
          if(this.labelName ==undefined && this.params.data.out_LedgerName==undefined){
            this.labelName=this.params.data.in_LedgerName
          }
          if(this.params.data.in_LedgerName ==undefined && this.labelName==undefined){
            this.labelName=this.params.data.out_LedgerName
          }
          this.showLabel = this.params.showLabel !== undefined ? this.params.showLabel : true;
          this.userId = this.params.data.id; 
    }
   
    this.isReadOnly = this.params.isReadOnly !== undefined ? this.params.isReadOnly : false; 
    this.isCursor = this.params?.isCursor !== undefined ? params?.isCursor : true;
  }
  
  refresh(params?: any): boolean {
    this.params = params;
    this.checked = !!this.params?.value;
    this.labelName = this.params.data.name;
    this.customCheckbox = this.params?.customCheckbox;
    this.showLabel = this.params.showLabel !== undefined ? this.params.showLabel : true;
    this.isCursor = this.params.isCursor !== undefined ? params.isCursor : true;
    return true;
  }
  
  checkboxStates: { userId: number; active: boolean }[] = [];

  onCheckboxChange(event: any) {
    this.checked = event.target.checked;
    // this.labelName =this.params.data.name;
    // this.params.node.setDataValue(this.checked ,this.labelName); 
    this.params.setValue(this.checked)
    this.params.data.active = this.checked;
    this.params.node.setSelected(this.checked);
    const value = { data: this.params.data, checked: this.checked };
    this.agGridService.selectionChanged.next(value);

    if (!this.params?.isCommonCheckbox 
      || !this.params?.isCommonReverseTax 
      || this.params?.isSms || this.params?.isAML  
      || this.params?.isKYC|| this.params?.isWhatsapp 
      || this.params?.isEmail || this.params?.isSelected 
      || this.params?.enable ||this.params?.stopOnError
      ||this.params?.isReturnWeight ||this.params?.isAddWeight) {
      
      // this.checkboxRendererService.TriggerCheckBox(this.params);
      const customEvent = new CustomEvent('checkBoxClick', {
        detail: { data: this.params },
       });
       this.params.api.dispatchEvent(customEvent);
    }
    // for selectall checkbox header when filter input
    if(this.params.api?.appliedChecboxSetListByField){
      this.params.api?.appliedChecboxSetListByField?.gridRows?.forEach((item) => {
        if (item[this.params.api?.appliedChecboxSetListByField?.matchRowidFieldName] == this.params?.data[this.params.api?.appliedChecboxSetListByField?.matchRowidFieldName]) {
          item[this.params.api?.appliedChecboxSetListByField?.checkboxFieldName] = this.params?.data[this.params.api?.appliedChecboxSetListByField?.checkboxFieldName]; // Update the value
        }
      });
    }
  }

}
