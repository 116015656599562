
<div class="d-flex align-items-center justify-content-end position-relative">
  <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button cursorPointer" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<style>
 
</style>
<div class="modal-body ">
  <button class="btn btn-sm btn-pink text-nowrap cursorPointer bg-primary" (click)="printPdf()">Print </button>
  <iframe #pdfIframe
      *ngIf="src"
      [src]="src"
      style="width: 100%; height: 100vh; border: none;"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
></iframe>
<iframe #pdfIframe
*ngIf="data?.printHtml"
style="width: 100%; height: 100vh; border: none;"
webkitallowfullscreen
mozallowfullscreen
allowfullscreen
></iframe>
</div>