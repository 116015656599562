
import {AgColDefsI,AgGridI,} from "../../../shared/common-components/ag-grid/ag-grid.model";
import { CustomHeaderComponent } from "src/app/shared/common-components/ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { JwellaryImageComponent } from "src/app/shared/common-components/ag-grid/jwellary-image/jwellary-image.component";
import { CheckboxRendererComponent } from "src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { AmountColumn, ColorId, CutId, DiamondCaratColumn, FinalWtColumn, LessWtColumn, NetWtColumn, ProductColumn, PurityColumn, QuantityColumn, RateColumn, SizeId, WastagePerColumn, WastageWtColumn,WeightColumn,PurityWtColumn } from "src/app/shared/common-components/metal-section/metal-section.model";
import { PaginationCommonRequestModel } from "src/app/shared/models/pagination-base-model.model";
import { StorageName } from "src/app/shared/enum/common-enum";
import { NumericCellRendererComponent } from "src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { TextRendererComponent } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";

const ProductColumnStockJournal = { ...ProductColumn };
ProductColumnStockJournal.headerComponentParams = {
  ...ProductColumnStockJournal.headerComponentParams,
  enablePlusIconLight: false,
};
const WeightColumnStockJournal = {...WeightColumn};
const LessWtColumnStockJournal = {...LessWtColumn};
const NetWtColumnStockJournal  = {...NetWtColumn};
const PurityColumnStockJournal = {...PurityColumn};
PurityColumnStockJournal.headerComponentParams = {
  ...PurityColumnStockJournal.headerComponentParams,
  enablePlusIconLight: false,
};
const PurityWtColumnStockJournal = {...PurityWtColumn};
const FinalWtColumnStockJournal  = {...FinalWtColumn};
const QuantityColumnStockJournal = {...QuantityColumn};
const WastagePerColumnStockJournal = {...WastagePerColumn};
const WastageWtColumnStockJournal = {...WastageWtColumn};
const RateColumnStockJournal   = {...RateColumn};
const AmountColumnStockJournal = {...AmountColumn};

//for diamond 
const SizeIdStockJournal  = {...SizeId};
const ColorIdStockJournal = {...ColorId}
const CutIdStockJournal   = {...CutId}
const DiamondCaratColumnStockJournal = {...DiamondCaratColumn}

// const WastagePerColumnStockJournal = JSON.parse(JSON.stringify(WastagePerColumn));
//       WastagePerColumnStockJournal.headerName = "Waste %"

export class StockJournal {}

// movementstock for Raw Material Item Table
const ItemListcheckboxColumn: AgColDefsI = {
    headerName: "",
    field: "active",   
    resizable: false, 
    cellClass: "custom-column-group-cell",
    cellRendererParams: { showLabel: false }, 
    cellRenderer:CheckboxRendererComponent  ,
    headerClass:" justify-content-center px-0  border-0",
    minWidth: 50,
    maxWidth: 50,
    editable:false, 
  };

const ItemListImageColumn: AgColDefsI = {
    headerName: "",
    headerClass:
      "px-0   border-0 f-14 text-gray-color font-semibold",
    field: "image",
    cellRenderer: JwellaryImageComponent,
    cellClass: "px-0 custom-column-group-cell",
    minWidth: 36,
    maxWidth: 36,
    editable:false,
    resizable: false,
  };
  
  const ItemListActionColumn: AgColDefsI = {
    headerName: "",
    pinned: "right",
    suppressNavigable: true,
    editable: false,
    lockPinned: true,
    headerComponent: CustomHeaderComponent,
    headerClass: "  justify_content_start  custom-column-group-cell border-0 px-0",
    cellRenderer: EditDeleteIconComponent,
    cellRendererFramework: EditDeleteIconComponent,
    // Pass gridOptions to the cell renderer component
    headerComponentParams: { enableMenu: false, moreButton: true, selectAll : true },
    cellClass: "mx-0 text-end px-1",
    minWidth: 80,
    maxWidth: 80,
  };

    // for raw material item list

    const MovementStockColumnDefForRawMaterialItemList: AgColDefsI[] = [
        ItemListcheckboxColumn,
        ItemListImageColumn,
        ProductColumnStockJournal,
        WeightColumnStockJournal,
        LessWtColumnStockJournal,
        NetWtColumnStockJournal,
        PurityColumnStockJournal,
        PurityWtColumnStockJournal,
        WastagePerColumnStockJournal,
        WastageWtColumnStockJournal,
        FinalWtColumnStockJournal,
        RateColumnStockJournal ,
        AmountColumnStockJournal,
        ItemListActionColumn,
    ];

  export const AgGridDataModelSourceRawMaterialsConsumptionTable: AgGridI = {
    colDefs: MovementStockColumnDefForRawMaterialItemList,
    rowSelection: "multiple",
    rowHeight:28,
  };

  export const AgGridDataModelDestinationFinishedGoodsProductionTable: AgGridI = {
    colDefs: MovementStockColumnDefForRawMaterialItemList,
    rowSelection: "multiple",
    rowHeight:28,
  };


  //#region stock journal
      
  export enum ConsumptionOrProduction{
      Consumption = 1,
      Production =2
  }

  // stock journal inset update requestModel 

  // export class StockJournalInsertUpdateRequestModel {
  //   stockJournalMasterModel : StockJournalMasterModel = new StockJournalMasterModel();
  //   stockJournalDetailModel : StockJournalDetailModel[];
  // }
  
  export class StockJournalMasterModel {
    id: number;
    voucherNo      : number;
    voucherTypeId  : number;
    suffixPrefixId : number;
    invoiceNo      : string;
    accountLedgerId: number;
    narration      : string;
    additionalCost : number;
    date           : string;
    againstVoucherTypeId: number;
    againstVoucherNo : number;
    stockJournalTypeId : number;
  }
  
  // export class StockJournalDetailModel {
  //   deleted: boolean
  //   added: boolean
  //   edited: boolean
  //   none: boolean
  //   id: number
  //   stockJournalMasterId: number
  //   productDetailId: number
  //   metalDetailId: number
  //   wt: number
  //   rate: number
  //   unitId: number
  //   unitConversionId: number
  //   amount: number
  //   consumptionOrProductionId: number
  //   goldItemModel: GoldItemModel
  //   silverItemModel: SilverItemModel
  //   diamondItemModel: DiamondItemModel
  //   imitationItemModel: ImitationItemModel
  //   stoneGemsItemModel: StoneGemsItemModel
  // }
  
  export class GoldItemModel {
    deleted: boolean
    added: boolean
    edited: boolean
    none: boolean
    id: number
    voucherTypeId: number
    voucherNo: number
    caratId: number
    purityPer: number
    lessWt: number
    purityWt: number
    wastagePer: number
    wastageWt: number
    netWt: number
    finalWt: number
    makingRate: number
    makingDiscount: number
    amount: number
    huidNo: string
    packetWt: number
    packetLessWt: number
    barcodeNo: string
    grossWt: number
    productDetailId: number
    rate: number
    quantity: number
    hallmarkAmount: number
    hallmarkRate: number
    netAmount: number
    stoneAmount: number
    diamondAmount: number
    makingTypeId: number
    ratePer: number
    makingAmount: number
    itemTaxDetails: ItemTaxDetail[]
    netAmountWithTax: number
    hallmarkAmountWithTax: number
    reverseCalculationAmount: number
    bucketId: number
  }
  
  export class ItemTaxDetail {
    taxId: number
    taxName: string
    taxAmount: number
  }
  
  export class SilverItemModel {
    deleted: boolean
    added: boolean
    edited: boolean
    none: boolean
    id: number
    voucherTypeId: number
    voucherNo: number
    caratId: number
    purityPer: number
    lessWt: number
    purityWt: number
    wastagePer: number
    wastageWt: number
    netWt: number
    finalWt: number
    makingRate: number
    makingDiscount: number
    amount: number
    huidNo: string
    packetWt: number
    packetLessWt: number
    barcodeNo: string
    grossWt: number
    productDetailId: number
    rate: number
    quantity: number
    hallmarkAmount: number
    hallmarkRate: number
    netAmount: number
    stoneAmount: number
    diamondAmount: number
    makingTypeId: number
    ratePer: number
    makingAmount: number
    itemTaxDetails: ItemTaxDetail2[]
    netAmountWithTax: number
    hallmarkAmountWithTax: number
    reverseCalculationAmount: number
    bucketId: number
  }
  
  export class ItemTaxDetail2 {
    taxId: number
    taxName: string
    taxAmount: number
  }
  
  
  export class ItemTaxDetail3 {
    taxId: number
    taxName: string
    taxAmount: number
  }
  
  export class ImitationItemModel {
    deleted: boolean
    added: boolean
    edited: boolean
    none: boolean
    id: number
    purchaseAmount: number
    saleAmount: number
    voucherTypeId: number
    voucherNo: number
    productDetailId: number
    huidNo: string
    packetWt: number
    packetLessWt: number
    barcodeNo: string
    grossWt: number
    rate: number
    quantity: number
    insuranceId: number
    netAmountWithTax: number
    taxAmount: number
    netAmount: number
    discountPer: number
    discountAmount: number
    deliveryNoteDetailsId: number
    orderDetailsId: number
    quotationDetailsId: number
    unitId: number
    unitConversionId: number
    remarkId: number
    comment: string
    bucketId: number
    itemTaxDetails: ItemTaxDetail4[]
  }
  
  export class ItemTaxDetail4 {
    taxId: number
    taxName: string
    taxAmount: number
  }
  
  export class StoneGemsItemModel {
    deleted: boolean
    added: boolean
    edited: boolean
    none: boolean
    id: number
    carat: number
    weight: number
    stoneAmount: number
    certificateAmount: number
    otherAmount: number
    makingTypeId: number
    makingRate: number
    makingDiscount: number
    makingAmount: number
    amount: number
    qualityId: number
    cutId: number
    colorId: number
    seiveSizeId: number
    sizeId: number
    shapeId: number
    goldWeight: number
    goldAmount: number
    voucherTypeId: number
    voucherNo: number
    productDetailId: number
    huidNo: string
    packetWt: number
    packetLessWt: number
    barcodeNo: string
    grossWt: number
    rate: number
    quantity: number
    hallmarkRate: number
    hallmarkAmount: number
    insuranceId: number
    netAmountWithTax: number
    taxAmount: number
    netAmount: number
    discountPer: number
    discountAmount: number
    deliveryNoteDetailsId: number
    orderDetailsId: number
    quotationDetailsId: number
    unitId: number
    unitConversionId: number
    taxId: number
    remarkId: number
    comment: string
    bucketId: number
    itemTaxDetails: ItemTaxDetail5[]
  }
  
  export class ItemTaxDetail5 {
    taxId: number
    taxName: string
    taxAmount: number
  }
  const BarCodeNumberColumn: AgColDefsI = {
    headerName: "BarCodeNo.",
    field: "barCodeNo",   
    resizable: false, 
    cellClass: "custom-column-group-cell",
    headerClass:" justify-content-start px-0",
    cellRenderer:NumericCellRendererComponent,
    minWidth: 50,
    editable:false, 
  };
  const ProductsColumn: AgColDefsI = {
    headerName: "Product",
    field: "product",   
    resizable: false, 
    cellClass: "custom-column-group-cell",
    headerClass:" justify-content-start px-0",
    minWidth: 50,
    editable:false, 
    cellRenderer:TextRendererComponent,
  };
  const GrossWtColumn: AgColDefsI = {
    headerName: "Gross Wt.",
    field: "grossWt",   
    resizable: false, 
    cellClass: "custom-column-group-cell text-end",
    headerClass:"ag_header_end  px-0",
    minWidth: 50,
    editable:false, 
    cellRenderer:NumericCellRendererComponent,
    cellRendererParams:{isWeight :true},
  };


  const StockOutList: AgColDefsI[] = [
    BarCodeNumberColumn,
    ProductsColumn,
    GrossWtColumn

  ]
  export const AgGridDataModelStockOut: AgGridI = {
    colDefs: StockOutList,
    rowSelection: "multiple",
    storageName:StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_ITEM_GRID,
    rowHeight:28,
  };

  // stock journal type enum 

  export enum StockJournalType
  {
      MovementStock = 1,
      Manufacturing = 2,
      Transfer      = 3,
      StockOut      = 4
  }

  //end

 export class exportRequest {
  gridName : string;
  columnToInclude : any
}
  //#endregion stock journal