import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridI } from '../../../ag-grid/ag-grid.model';
import { AgGridDataModelSelectPrintFormat } from './select-print-format.model';
import { SettingsService } from 'src/app/components/Settings/settings.service';
import { IconHover } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AgGridService } from '../../../ag-grid/ag-grid.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-print-format-popup',
  templateUrl: './select-print-format-popup.component.html',
  styleUrls: ['./select-print-format-popup.component.scss']
})
export class SelectPrintFormatPopupComponent extends CommonService implements OnInit {
  // @Input() printFormatData: any;
  @Input() modalTitle: string;
  @Input() data : any ;
  private onRadioButtonSubscription: Subscription | undefined;
  agGridDataModel: AgGridI;
  gridApi: any;
  rdlcResourceName: any;
  currentHoverEffect = IconHover.Pink;

  constructor
    (public renderer: Renderer2,
      public activeModal: NgbActiveModal,
      public settingsService: SettingsService,
      public storageService: StorageService,
      public agGridService: AgGridService,
    ) {
    super(renderer)
    this.onRadioButtonSubscription = this.agGridService.onRadioButtonChange$.subscribe((params: any) => {
      this.rdlcResourceName = params?.data?.fileName;
    });
  }

  ngOnInit(): void {
    this.agGridDataModel = AgGridDataModelSelectPrintFormat;
  }

  onGridReady(event) {
    this.gridApi = event;
    this.rdlcResourceName = this.data?.printFormatData?.rdlcResourceList?.find((x) => x?.active == true).fileName;
    this.updateGridData(this.data?.printFormatData?.rdlcResourceList);
  }


  updateGridData(data: any) {
    if (this.gridApi) {
      setTimeout(() => {
        this.gridApi?.api?.setRowData(data);
      }, 100);
    } else {
      console.error('Grid API not initialized yet');
    }
  }

  continue() {
      if (this.rdlcResourceName){
      this.activeModal.close(this.rdlcResourceName);
    }
  }

  ngOnDestroy() {
    this.onRadioButtonSubscription?.unsubscribe();
  }
}