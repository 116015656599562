import { ResponseModel } from "./../../../shared/models/response-model.model";
import { DataService } from "./../../../shared/services/data.service";
import { Injectable } from "@angular/core";
import { CreateRoleModel} from "./usermanagment.model";
import { Observable, tap } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UsermanagmentService {
  public isDisabledDeleteIcon : boolean = false;
  constructor(public service: DataService) {}


  generatePassword(length: number, options: { letters: boolean; numbers: boolean; symbols: boolean; }): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*()_+-=[]{}|;:\'",.<>?/';

    let validChars = '';
    if (options.letters) {
      validChars += letters;
    }
    if (options.numbers) {
      validChars += numbers;
    }
    if (options.symbols) {
      validChars += symbols;
    }

    let generatedPassword = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * validChars.length);
      generatedPassword += validChars[randomIndex];
    }

    return generatedPassword;
  }
  
  //#region getAllUser
  getAllUser(data): Observable<ResponseModel> {
    let url = "api/app/users/getalluser";
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  //#endregion

  //#region getUserByID
  getUserById(id:number): Observable<ResponseModel> {
    let url = `api/app/users/getbyiduser?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  //#endregion

  //#region deleteUser
  deleteUser(id: number): Observable<ResponseModel> {
    let url = `api/app/users/deleteuser?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  //#endregion

  //#region Add user
  createUser(data) {
    let url = "api/app/users/createuser";
    const json =JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  //#endregion

  //#region Update user
  updateUser(data) {
    let url = "api/app/users/updateuser";
    const json = JSON.stringify(data);    
    return this.service
      .put(url, data, true)
      .pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
  }
  //#endregion
 

  // *************getAllRoles start service *************
  getAllRole(data:any):Observable<ResponseModel>{
    let url = "api/app/role/getallrole";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
 

  //  ************insert Role *************
  insertRole(createRoleModel:CreateRoleModel):Observable<ResponseModel>{
    let url = "api/app/role/createrole";
    const json = JSON.stringify(createRoleModel);
    return this.service.post(url,createRoleModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //  ************update Role *************
   updateRole(createRoleModel:CreateRoleModel):Observable<ResponseModel>{
    let url = "api/app/role/updaterole";
    const json = JSON.stringify(createRoleModel);
    return this.service.putWithId(url,createRoleModel,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************delete Role *************
  deleteRole(id:number):Observable<ResponseModel>{
    let url = `api/app/role/deleterole?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  //#endregion

   // *************getAllRoles end service *************


   

}
