import { NgForm } from '@angular/forms';
import { AccountGroupId, AddressTab, AddressVM, AgGridDataModelMetalTypeTax, AgGridDataModelshareHolder, BillingAddressModel, BilltoBill, CustomerDetails, LedgerItemTypeTaxModel, LedgerTypesEnum, OpeningBalanceModel, ResourceProperties, ShippingAddressModel, SundryType } from './customer-details.model';
import { Component, Input, Renderer2, ViewChild ,ElementRef} from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { WebcamComponent } from 'ngx-webcam';
import { Observable, Subject, Subscription, catchError } from 'rxjs';
import { CustomerDetailsService} from './customer-details.service'
import { StorageName, TransactionType } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { AgGridDataModelMetalWiseOpening } from '../customer-details/customer-details.model';
import { GridOptions, RowClickedEvent } from 'ag-grid-community';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadDropdownDataService } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { EditDeleteIconService } from 'src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.service';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { commonModalOptions } from 'src/app/shared/models/common.model';
import { OpenImagePopupComponent } from './popup/open-image-popup/open-image-popup.component';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { LayoutDetails } from 'src/app/auth/auth/auth.model';
import { ShippingAddressComponent } from './tabs/shipping-address/shipping-address.component';
import { BillingAddressComponent } from './tabs/billing-address/billing-address.component';
import { IconDatepickerComponent } from 'src/app/shared/common-components/icon-datepicker/icon-datepicker.component';
import { BlockUnblockStatus } from 'src/app/components/Settings/campaign-manager/campaign-manager.model';
import { CampaignManagerService } from 'src/app/components/Settings/campaign-manager/campaign-manager.service';
import { IconHover } from '../../../../enum/common-enum';
import { NotificationService } from '../../../../services/notification.service';
import { ModalPopupSize } from '../../../../models/common.model';
import { AgGridService } from '../../../ag-grid/ag-grid.service';
import { PlusIconService } from '../../../ag-grid/plus-icon/plus-icon.service';
import { PersonalDetailsComponent } from 'src/app/components/edit-profile/personal-details/personal-details.component';
@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent extends CommonService {
  gridApiWhatsAppList                 : any;
  @ViewChild("AddCustomerDetailsForm") AddCustomerDetailsForm!: NgForm;
  @ViewChild(WebcamComponent) webcamComponent: WebcamComponent;
  agGridDataModelMetalTypeTax: AgGridI;
  agGridDataModelshareHolder: AgGridI;
  // @Input()accountLedgerId :number=0;
  // @Input() SundryType;
  // @Input() title: string = 'Customer Details'; // Add this line
  // @Input() ledgerName
  @Input() hide_header:boolean = true;
  @Input() hide_section:boolean = false;
  @Input() formName:string;
  @Input()isExpanses:boolean;
  //@Input() customerDetails : CustomerDetails;
  currentHoverEffect = IconHover.Pink;
  sundryType       = SundryType;
  customerDetails  =  new CustomerDetails();
  resourceProperty = new ResourceProperties();
  transactionType  = TransactionType;
  accountGroupId   = AccountGroupId
  availableCamera  : string[];
  openingBalanceCopiedList : OpeningBalanceModel[]  = []
  resourcePropertyCopiedList : ResourceProperties[] = []
  LedgerItemTypeTaxModelCopiedList : LedgerItemTypeTaxModel[] = []
  previewImg=null
  storageName=StorageName;
  getAllDropdownModel  : any = {};
  LedgerId: any;
  selectedOption: string = '';
  agGridDataModelMetalWiseOpening :AgGridI;
  userData: any;
  getDistrictData : any[]=[];
  getTalukaData : any[]=[];
  isFromAccountLedger:boolean = false;
  resetRowSubscription    : Subscription | undefined;
  @ViewChild('video') videoElement: ElementRef;
  isCameraOn:boolean=false;
  video: any;
  capturedImage: string | null = null;
  stream: MediaStream | null = null;
  gridApiForMetalOpening:any;
  addressModel: AddressVM = new AddressVM();
  billingAddressModel :BillingAddressModel  = new BillingAddressModel();
  shippingAddressModel :ShippingAddressModel = new ShippingAddressModel();
  ledgerTypesEnum = LedgerTypesEnum;
  displayImg: any;
  activeTabId: any = AddressTab.BillingAddress;
  layoutDetails = new LayoutDetails();
  @ViewChild(ShippingAddressComponent) shippingAddressComponent: ShippingAddressComponent;
  @ViewChild(BillingAddressComponent) billingAddressComponent: BillingAddressComponent;
  gridApiMetalTypeTax: any;
  gridApiHolderReady: any;
  billtoBillenum : typeof BilltoBill = BilltoBill;
  // countryCode:any;
  @ViewChild('specialDayDatepicker') specialDayDatepicker: IconDatepickerComponent;
  @ViewChild('dobDatePicker') dobDatePicker: IconDatepickerComponent;
  @Input() data : any = {}
  isNewUser: boolean = true;  
  accountLedgerCanUpdate: boolean = false;  
  accountLedgerCanAdd: boolean = false;  
  campaignGroupIds: any[] = [];
  copySelectedGroup           : any;
  private plusIconSubscription        : Subscription;
  public selectedRowNode : any = {}
  @ViewChild(PersonalDetailsComponent) personalDetailsComponent : PersonalDetailsComponent;
  deletedSharedHolderList = [];

  constructor(public activeModal: NgbActiveModal, 
    public storageService:StorageService ,
    public renderer: Renderer2,
    public loadDropdownDataService : LoadDropdownDataService,
    private ngbDateParserFormatter: NgbDateParserFormatter ,
    public customerDetailsService:CustomerDetailsService,
    public editDeleteIconService    : EditDeleteIconService,
    private storageservice:StorageService,
    public toaster          : ToastrService,
    private activatedRoute  : ActivatedRoute,
    public compressImageService :CompressImageService,
    private notificationService: NotificationService,
    private sendDataService :SendDataService,
    private modalService: NgbModal,
    private agGridService : AgGridService,
    private plusIconService : PlusIconService,
    private router : Router,
    private dateService           : DateService,
    public campaignManagerService : CampaignManagerService,
  ){
      super(renderer);
      if(this.customerDetails?.billByBill == null)
        this.customerDetails.billByBill ='false';
      
      this.resetRowSubscription = this.storageService.onResetRowClicked.subscribe(params =>{
        if(params){
          this.removeMetalOpeningRowData(params);
        }
      })

      this.plusIconSubscription = this.plusIconService.plusIconClicked$.subscribe((context: string) => {
        this.addNewRow();
      });
     
      this.accountLedgerCanUpdate = this.sendDataService?.permissionVM?.accountLedgerPopUp ?? this.sendDataService?.permissionVM?.accountLedgerCanUpdate ;
      this.accountLedgerCanAdd  = this.sendDataService?.permissionVM?.accountLedgerPopUp ?? this.sendDataService?.permissionVM?.accountLedgerCanAdd;
    }

  ngOnInit() {
    if (!this.data.accountLedgerId) {
      this.data.accountLedgerId = 0;
    }

    this.isNewUser = this.data?.accountLedgerId === 0 || this.data?.accountLedgerId == null;
    this.dateReceived();
    this.agGridDataModelMetalWiseOpening = AgGridDataModelMetalWiseOpening;
    this.agGridDataModelMetalTypeTax = AgGridDataModelMetalTypeTax;
    this.agGridDataModelshareHolder =AgGridDataModelshareHolder;
    if (this.data?.accountLedgerId != 0 ) {
      this.getaccountledgerbyid();
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      //  this.isFromAccountLedger = true;
      this.LedgerId = history.state?.id;
      this.formName = history.state?.formName;
      if (Number(this.LedgerId)) {
        this.data.accountLedgerId = Number(this.LedgerId);
        this.isFromAccountLedger = true;
        this.getaccountledgerbyid();
      }

      this.layoutDetails = this.storageService.retrieve('layoutDetails');

    });
    this.customerDetails.ledgerName = this.data?.ledgerName;
    this.customerDetails.imageUrl = this.data?.imageUrl;
  }

  onGridMetalTypeReady(event){
    this.gridApiMetalTypeTax  = event; 
  }

  onGridShareHolderReady(event){
    this.gridApiHolderReady  = event; 
    setTimeout(() => {
      this.gridApiHolderReady?.api?.setRowData(this.agGridDataModelshareHolder?.rows ?? []);
      if(this.agGridDataModelshareHolder?.rows?.length) this.selectRowByIndex(0);
    }, 100);
  }

  dateReceived(){
    this.dateService.getSelectedDate().subscribe(date => {
    //  this.invoiceDate = date;
    });
  }
  
  // updateMobile(value: string) {
  //   const mobileNumber = value.replace('+' + this.countryCode, '').trim();
  //   this.customerDetails.mobile = mobileNumber;
  //   }
  
  ngAfterViewInit(): void {
      if(this.LedgerId != undefined && this.LedgerId != 0)
        this.getaccountledgerbyid();
  }

  stateList

  fillDropdownData(){
    this.getAllDropdownModel.registrationTypeDropdown = this.storageService.retrieve(StorageName.REGISTRATIONTYPE_DROPDOWN);
    this.getAllDropdownModel.countryDropdown          = this.storageService.retrieve(StorageName.COUNTRY_DROPDOWN);
    this.getAllDropdownModel.stateDropdown            = this.storageService.retrieve(StorageName.STATE_DROPDOWN);
    this.getAllDropdownModel.documentTypeDropdown     = this.storageService.retrieve(StorageName.DOCUMENT_TYPE_DROPDOWN);
    this.getAllDropdownModel.districtDropdown         = this.storageService.retrieve(StorageName.DISTRICT_DROPDOWN);
    this.getAllDropdownModel.talukaDropdown           = this.storageService.retrieve(StorageName.TALUKA_DROPDOWN);
    this.getAllDropdownModel.ledgerTypeDropdown       = this.storageService.retrieve(StorageName.LEDGERTYPE_DROPDOWN);
    this.getAllDropdownModel.campaignGroupDropdown    = this.storageService.retrieve(StorageName.CAMPAIGNGROUP_DROPDOWN);
    this.getAllDropdownModel.accountGroupDropdown     = this.storageService.retrieve(StorageName.ACCOUNTGROUP_DROPDOWN);
    this.getAllDropdownModel.taxDropdown              = this.storageService.retrieve(StorageName.TAX_DROPDOWN);
    if(this.data?.accountLedgerId || this.data?.accountLedgerId == 0){
      this.getaccountledgerbyid();
    }
    if(!this.isFromAccountLedger && this.LedgerId ==undefined){
      this.getAllDropdownModel.accountGroupDropdown = this.getAllDropdownModel.accountGroupDropdown.filter(x=>x.name==this.sundryType.SundryDebitor || x.name==this.sundryType.SundryCreditor)
     
    }
    // this.selectAccountGroup();
  }

  gridOptions: GridOptions = {
    singleClickEdit:true,
    getRowStyle: this.getRowStyle,
  };

  getRowStyle(params: any) {
    return {
      background: params.node.rowIndex % 2 === 0 ? '#EEF1F7' : '#FFFFFF',
    };
  }

  getBranchId(item){
    if (JSON.stringify(item) !== "{}") {
      this.customerDetails = item;
    }
    const campaignGroupIds = item?.campaignGroupIds ?? [];
    this.customerDetails.campaignGroupIds =item?.campaignGroupIds;
     this.campaignGroupIds = this.getAllDropdownModel?.campaignGroupDropdown.filter(branch => campaignGroupIds?.includes(Number(branch?.id))).map(branch => branch);
    }

  getaccountledgerbyid(){

    this.customerDetailsService.getaccountledgerbyid(Number(this.data?.accountLedgerId)).pipe(catchError((err)=>this.handleError((err))))
    .subscribe((res:ResponseModel)=>{
      if(this.data?.fromReader != true)
        this.customerDetails = res?.response;
        else
        {
        this.customerDetails.accountLedgerId  =  this.data.accountLedgerId;
        this.customerDetails.SundryType = this.data.SundryType;
        this.customerDetails.ledgerName = this.data.ledgerName;
        this.customerDetails.mobile = this.data.mobile;
        this.customerDetails.aadhaarCardNo = this.data.aadhaarCardNo;
        this.customerDetails.pan = this.data.pan;
        this.customerDetails.email = this.data.email;
        const splitDate = this.data.dateOfBirth; // 'dd/MM/yyyy'
        const dateParts = splitDate.split('/'); // Split by '/'
        
        // Reorder and join the date parts to form 'yyyy-MM-dd'
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        
        this.customerDetails.dateOfBirth = formattedDate;
        this.customerDetails.addressLine1 = this.data.addressLine1;
        this.customerDetails.imageUrl = this.data.imageUrl;
        this.customerDetails.fromReader = this.data.fromReader;
        this.customerDetails.id =  0;
        }
      if(this.customerDetails?.billByBill == null){
        this.customerDetails.billByBill ='false';
      }else{
        this.customerDetails.billByBill = res?.response?.billByBill ? "true" : "false";
      }
      if(this.isFromAccountLedger)
        if(!this.data?.accountLedgerId)
          this.customerDetails.crOrDr = this.transactionType.CR;

        this.customerDetails.openingBalance = this.customerDetails.openingBalance ?? '0'
      
      if(this.data?.ledgerName){
        if (isNaN(Number(this.data?.ledgerName)) ) {
          this.customerDetails.ledgerName  =  this.data?.ledgerName;
        }
        else {
        
          this.customerDetails.mobile = this.data?.ledgerName
        }
      }
     if(this.customerDetails.id==0 && this.data.fromReader != true){
      this.customerDetails.imageUrl=null;
      this.valueChangedTabwise();
     }
     this.customerDetails.billingAddressModel= this.customerDetails?.billingAddressModel?? new BillingAddressModel();
     this.customerDetails.shippingAddressModel= this.customerDetails?.shippingAddressModel?? new ShippingAddressModel();
     if(!this.data?.accountLedgerId){
      this.customerDetails.billingAddressModel.stateId = this.storageservice?.retrieve('layoutDetails')?.stateId ??  this.storageservice?.retrieve('layoutDetails')?.billingStateId;
      if(this.data?.SundryType){
        const accountGroupId:any = this.getAllDropdownModel?.accountGroupDropdown.find(x=>x?.name==this.data?.SundryType).id;
          this.customerDetails.accountGroupId = accountGroupId;
      }
      
    }

    if (this.isFromAccountLedger || this.data?.accountLedgerId) {
      this.customerDetails.billingAddressModel.countryId = this.getCountryId(this.customerDetails?.billingAddressModel?.stateId || this.customerDetails?.shippingAddressModel?.stateId);
     
     
    } else {
      this.customerDetails.billingAddressModel.countryId = this.getCountryId(this.layoutDetails?.stateId);
    }
    this.campaignGroupIds = this.customerDetails.campaignGroupIds;
    this.agGridDataModelshareHolder.rows = this.customerDetails.shareHolderModel;
    this.getBranchId(this.customerDetails);
    this.billingAddressComponent?.onChangeCountryName(this.customerDetails?.billingAddressModel?.countryId)
    this.shippingAddressComponent?.onChangeCountryName(this.customerDetails?.shippingAddressModel?.countryId)
    if(!this.data?.accountLedgerId){
      this.customerDetails.mobileNoDialCode=this.getDialcodeByCountryId(this.customerDetails?.billingAddressModel?.countryId);
    }
    this.customerDetails.mobileNoDialCode = this.customerDetails.mobileNoDialCode;
     this.customerDetails?.resourcePropertyModel?.map((item) => {
      item.isDeleteIcon = true;
      item.added = false;
      item.edited = false;
      item.deleted = false;
      item.none = false;
    });

    this.customerDetails?.openingBalanceModel?.forEach((item) => {
      item.rate = item.rate || 0;
  });
      this.openingBalanceCopiedList = JSON.parse(JSON.stringify(this.customerDetails?.openingBalanceModel));
      this.resourcePropertyCopiedList = JSON.parse(JSON.stringify(this.customerDetails?.resourcePropertyModel));
      this.LedgerItemTypeTaxModelCopiedList = JSON.parse(JSON.stringify(this.customerDetails?.ledgerItemTypeTaxModel));
      this.kycEnabled = this.customerDetails?.resourcePropertyModel?.length > 0;
      if (!this.kycEnabled) {
        this.customerDetails.isKYC = false;
      }
      this.customerDetails.isKYC = this.customerDetails.isKYC ?? false;
      this.customerDetails.isAML = this.customerDetails.isAML ?? false;
    });
  }

  getCountryId(stateId){
    const countryId = this.getAllDropdownModel?.stateDropdown?.find(item => item?.id == stateId)?.extra1;
    return countryId
  }

  getDialcodeByCountryId(countryId){
    var dialcodelist = this.storageService.retrieve(StorageName.DIALCODE_DROPDOWN);
    var dialcode = dialcodelist?.find(item => item?.extra1 == countryId)?.name;
    return dialcode;
  }

  handleError(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  // image upload start 
  openAddImagePopup() {
    this.notificationService.openAddImagePopup([{ base64Resource: this.customerDetails?.imageUrl }], false).then((res) => {
      if (res === false) return;
      this.customerDetails.imageUrl = res;
      this.customerDetails.userProfile = res;
      this.customerDetails.isProfileChanged = true;
      // }
    })
  }

// imageUpload(event) {
//   this.customerDetails.isProfileChanged = true;
//   const file = event.target.files[0];

//   if (file && file.type.startsWith('image/') && !file.type.endsWith('gif')) {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => {
//           this.customerDetails.photoUrl = reader?.result?.toString();
//           this.customerDetails.imageUrl = reader?.result?.toString();
//       }
//   } else {
//       this.toaster.error('Please select a valid image');
//   }
// }


  // image upload end

  // region start ngx web cam code
  status: any = null;
  trigger: Subject<void> = new Subject();
  previewImage: string = '';

  get $trigger(): Observable<void> {
    return this.trigger.asObservable();
  }

  async startCamera() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      if (videoDevices.length === 0) {
        console.error('No video devices found.');
        return;
      }

      const selectedDevice = videoDevices[0]; // Use the first camera
      const constraints = { video: { deviceId: { exact: selectedDevice.deviceId } } };

      this.stream = await navigator.mediaDevices.getUserMedia(constraints);

      if (!this.video) {
        this.video = document.getElementById('video');
      }
      this.video.srcObject = this.stream;
      this.video.play();
      this.isCameraOn=true;
    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  }

    stopCamera() {
      this.isCameraOn =false;
      if (this.stream) {
        const tracks = this.stream.getTracks();
        tracks.forEach(track => track.stop());
        this.video.srcObject = null; // Clear the video source
      }
      this.video.pause();
      this.video = null;
      this.stream = null;
    }

    capturePhoto() {
      // this.startCamera();
      if (this.stream) {
        const canvas = document.createElement('canvas');
        canvas.width = this.video.videoWidth;
        canvas.height = this.video.videoHeight;
        canvas.getContext('2d')?.drawImage(this.video, 0, 0, canvas.width, canvas.height);
        this.customerDetails.imageUrl = canvas.toDataURL('image/png');
      }
    }

  toggleAML(isChecked: boolean) {
    this.customerDetails.isAML = isChecked;
  }
  toggleKYC(isChecked: boolean) {
    this.customerDetails.isKYC = isChecked;
  }

  addOrEditCustomerDetails(){
     if(!(this.data?.accountLedgerId ? this.accountLedgerCanUpdate : this.accountLedgerCanAdd)) return;
        const openingModelList  = this.gridOptions?.api?.getRenderedNodes()?.map(node => node?.data);
        let openingModelFilterList = openingModelList?.filter(itemB => !this.openingBalanceCopiedList?.some(itemA => JSON.stringify(itemA)  ===  JSON.stringify(itemB)));
        openingModelFilterList?.map((item)=> {
          if(item?.deleted == false){
            if(item?.id ==0){
              item.added = true;
              item.edited = false
            } else{
              item.added = false;
              item.edited = true;
            }
          } else if(item?.deleted == true){
            if(item.id){
              item.rate = 0
            }
          }
        });
        // this.customerDetails.openingBalanceModel = openingModelFilterList;
        let resourceFileList =  this.customerDetails?.resourcePropertyModel?.filter(itemB => !this.resourcePropertyCopiedList?.some(itemA => JSON.stringify(itemA)  ===  JSON.stringify(itemB)));
        resourceFileList?.map((item)=> {
          if(item.deleted == false){
            if(item?.id){
              item.added = false;
              item.edited = true
            }else{
              item.added = true;
              item.edited = false;
            }
          }
        });

        const ledgerItemTypeTaxModelList  = this.gridApiMetalTypeTax?.api?.getRenderedNodes()?.map(node => node?.data);
        let ledgerItemTypeTaxModelFilterList = ledgerItemTypeTaxModelList?.filter(itemB => !this.LedgerItemTypeTaxModelCopiedList?.some(itemA => JSON.stringify(itemA)  ===  JSON.stringify(itemB)));
        ledgerItemTypeTaxModelFilterList?.map((item)=> {
          if(item.deleted == false){
            if(item.id ==0){
              item.added = true;
              item.edited = false
            } else{
              item.added = false;
              item.edited = true;
            }
          } 
        });

        if(this.data?.accountLedgerId && !(this.customerDetails?.imageUrl ) ){
          this.customerDetails.isProfileChanged = false;
        }

        
        let customerDetailsRequest = new CustomerDetails();
        customerDetailsRequest = JSON.parse(JSON.stringify(this.customerDetails));
        customerDetailsRequest.isKYC = this.customerDetails.isKYC;
        customerDetailsRequest.isAML = this.customerDetails.isAML; 
        customerDetailsRequest.resourcePropertyModel = resourceFileList;
        customerDetailsRequest.openingBalanceModel   = openingModelFilterList;
        customerDetailsRequest.ledgerItemTypeTaxModel =ledgerItemTypeTaxModelFilterList;
        customerDetailsRequest.billByBill = (this.customerDetails.billByBill == 'true') ? true : false;
        customerDetailsRequest.mobileNoDialCode = (this.customerDetails.mobile) ? this.customerDetails.mobileNoDialCode : null;

        if (!this.customerDetails?.shippingAddressModel?.active) {
          customerDetailsRequest.shippingAddressModel = null;
        }

        if (this.customerDetails.campaignGroupIds) {
        const campaignGroupIds = this.customerDetails.campaignGroupIds;
        if (Array.isArray(campaignGroupIds) && campaignGroupIds.length > 0) {
        if (typeof campaignGroupIds[0] === 'object' && 'id' in campaignGroupIds[0]) {
        this.customerDetails.campaignGroupIds = campaignGroupIds.map(campaignGroupId => Number(campaignGroupId.id));
        } else {
        this.customerDetails.campaignGroupIds = campaignGroupIds.map(campaignGroupId => Number(campaignGroupId));
        }
        } else {
        this.customerDetails.campaignGroupIds = Array.isArray(campaignGroupIds) 
          ? campaignGroupIds.map(campaignGroupId => Number(campaignGroupId)) 
          : [Number(campaignGroupIds)];
        }
        } else {
        this.customerDetails.campaignGroupIds = Array.isArray(this.userData?.campaignGroupIds) 
          ? this.userData.campaignGroupIds.map(campaignGroupId => Number(campaignGroupId)) 
          : [Number(this.userData?.campaignGroupIds)];
        }
        customerDetailsRequest.campaignGroupIds = this.customerDetails.campaignGroupIds;

        let shareHolderList = this.gridApiHolderReady?.api?.getModel()?.rowsToDisplay?.map(((item)=>item?.data))??[];
        shareHolderList = [...shareHolderList, ...this.deletedSharedHolderList];
        shareHolderList?.map((item)=> {
          if(!item.deleted){
            if(item?.id){
              item.added = false;
              item.edited = true
            }else{
              item.added = true;
              item.edited = false;
            }
          }
        });
        customerDetailsRequest.shareHolderModel = shareHolderList;
        
        if((this.data?.accountLedgerId ==0 || this.data?.accountLedgerId ==null)){
          this.addCustomerAccountLeger(customerDetailsRequest);
      } else {
        this.updateCustomerAccountLeger(customerDetailsRequest)
      }
  }

  handleonBlockUnblockIconClick(action: 'email' | 'mobile') {
    const isMobile = action === 'mobile';
    const isBlocked = isMobile ? this.customerDetails.isMobileBlock : this.customerDetails.isEmailBlock;

    const data = {
        id: this.data?.accountLedgerId,
        campaignTypeId: 1,
        blockOrUnblockContactDetails: !isBlocked
    };

    this.campaignManagerService.blockUnblockContactDetails(data).subscribe((res) => {
        if (res?.isSuccess) {
            // Update the UI based on the API response
            if (isMobile) {
                this.customerDetails.isMobileBlock = !isBlocked;
            } else {
                this.customerDetails.isEmailBlock = !isBlocked;
            }

            // Optionally update dropdown if necessary
            this.getAllDropdownModel.accountLedgerDropdown.forEach(x => {
                if (x?.accountLedgerId === res?.response?.id?.toString()) {
                    x.extra1 = res?.response?.blockOrUnblockContactDetails ? BlockUnblockStatus.Block : BlockUnblockStatus.Unblock;
                }
            });

            const list = structuredClone(this.getAllDropdownModel.accountLedgerDropdown);
            this.gridApiWhatsAppList?.api?.setRowData(list);
            this.toaster?.success(res?.message);
        } else {
            this.toaster?.error(res?.message);
        }
    });
}


  addCustomerAccountLeger(customerDetails:CustomerDetails){
    this.customerDetailsService?.insertAccountLedger(customerDetails)?.subscribe({
      next:(res)=>{

        if(res?.isSuccess){
          this.storageService.store(StorageName.ACCOUNTLEDGER_DROPDOWN,res.response.response.accountLedgerDropdown);
          this.previewImage = null;
          this.previewImg   = null
       
          this.customerDetails =  new CustomerDetails();
          this.clearDatePickers();
          this.data.ledgerName      = undefined;
          this.campaignGroupIds = undefined;
          this.resetShareHolderData();
          this.toaster?.success(res?.message);

          this.activeModal?.close(res?.response.response.id);
          this.getaccountledgerbyid();
          
          // if(this.isExpanses){
          //   var storageName = StorageName.ALL_ACCOUNTLEDGER_DROPDOWN
          //  }else{
          //    var storageName = StorageName.ACCOUNTLEDGER_DROPDOWN
          //  }
          // this.loadDropdownDataService?.getDropdownData(storageName)
          // .pipe(catchError((err)=>this.handleError((err))))
          // .subscribe((res:ResponseModel)=>{
          //   if(res?.isSuccess)
          //   {
          //     this.activeModal?.close(res?.response.response.id);
          //     this.getaccountledgerbyid();
          //   }
          //   });
        }else{
          this.toaster.error(res?.errors[0]);
        }
      },error:(err)=>{
        this.toaster.error(err?.error?.errors[0])
      }
    })
  }

  updateCustomerAccountLeger(customerDetails: CustomerDetails) {
    this.customerDetailsService.updateAccountLedger(customerDetails)?.subscribe({
      next: (res) => {
        if (res?.isSuccess) {
          this.storageService.store(StorageName.ACCOUNTLEDGER_DROPDOWN,res.response.response.accountLedgerDropdown);
          // this.activeModal?.dismiss();
          // this.getaccountledgerbyid();
          this.previewImage = null;
          this.previewImg = null
          this.customerDetails = new CustomerDetails();
          this.clearDatePickers();
          if (this.isFromAccountLedger && this.formName == StorageName.FORMNAME_PRODUCT_ACCOUNT_LEDGER) {
            this.router?.navigate(["Product/account-ledger"]);
          }
          this.resetShareHolderData();
          this.toaster.success(res?.message);
          if(this.isExpanses){
           var storageName = StorageName.ALL_ACCOUNTLEDGER_DROPDOWN
          }else{
            var storageName = StorageName.ACCOUNTLEDGER_DROPDOWN
          }
          this.loadDropdownDataService?.getDropdownData(storageName)
            .pipe(catchError((err) => this.handleError((err))))
            .subscribe((res: ResponseModel) => {
              if (res?.isSuccess) {
                this.activeModal?.close(res?.response.response.id);
                this.getaccountledgerbyid();
              }
            });
        }  else{
          this.toaster.error(res?.errors[0]);
        }
      }, error: (err) => {
        console.log(err);
        this.toaster.error(err?.error?.errors[0])

      }
    })
  }

  getSpecicalDayDates(event) {
    this.customerDetails.specialDay =this.ngbDateParserFormatter?.format(event?.startDate);
  }

  getDobDates(event) {
    this.customerDetails.dateOfBirth =this.ngbDateParserFormatter?.format(event?.startDate);
  }

  onRemove(event, index) {
    this.previewImg = null;
    if (event?.id) {
        event.deleted = true;
    } else {
        this.customerDetails?.resourcePropertyModel.splice(index, 1);
    }
    this.kycEnabled = this.customerDetails?.resourcePropertyModel?.filter(item => !item.deleted)?.length > 0;
    if (!this.kycEnabled) {
        this.customerDetails.isKYC = false;
    }
  }
  onRemoveShareHolder(event, index) {
    this.previewImg = null;
    if (event?.id) {
        event.deleted = true;
    } else {
      this.selectedRowNode?.data?.shareHolderResourcePropertyModel?.splice(index, 1);
    } 
  }


  remove(event){
    this.previewImg=null
    this.customerDetails.imageUrl=null;
    this.customerDetails.photoUrl=null;
    event.stopPropagation()  
  }

  assignDocumentTypeId(event, resource?){
    this.resourceProperty.documentTypeId = Number(event);
    if(resource){
      this.actionUpdate(resource)
    }
  }

  checkIsExpired(documentTypeId):boolean{
    return ((this.getAllDropdownModel.documentTypeDropdown?.length) && (this.getAllDropdownModel.documentTypeDropdown?.find((x) => x?.id == documentTypeId)?.extra1) == '1' ? true : false);
   }

   kycEnabled: boolean = false;

   onSelect(event: any, from: number) {
    const files = from === 1 ? event.target.files : event.addedFiles;
    if (files.length > 1) {
        this.resourceMultipleFile(files , this.customerDetails?.resourcePropertyModel);
    } else {
        this.resourceFile(files[0] , this.customerDetails?.resourcePropertyModel);
    }

    this.kycEnabled = true;
    this.customerDetails.isKYC = true; 
  }

  onSelectResourcesShareHolder(event: any, from: number) {
    const files = from === 1 ? event.target.files : event.addedFiles;
    if (files.length > 1) {
        this.resourceMultipleFile(files , this.selectedRowNode?.data?.shareHolderResourcePropertyModel);
    } else {
        this.resourceFile(files[0] , this.selectedRowNode?.data?.shareHolderResourcePropertyModel);
    }
  }


resourceFile(item:any , requestModel){
  // this.customerDetails.resourceProperties=[]
this.processFile(item ,requestModel);
  
}

resourceMultipleFile(items:any ,requestModel){
  // this.customerDetails.resourceProperties=[]
  for(const file of items)
  this.processFile(file ,requestModel)
}


  processFile(file: any , requestModel) {
    console.log(file);
    if((file?.type?.includes('video/')) || file?.type?.includes('image/gif')) {
      this.toaster?.error("Video and GIF files are not allowed for upload");
      return;
    }

    if (file?.type?.includes('image')) {
      // Process image files
      this.compressImageService?.compress(file).subscribe(
        compressedFile => {
          this.readThis(compressedFile)
            .then((base64String) => {
              if (base64String) {
                console.log(compressedFile);
                const fileSizeInKB = file?.size / 1024;
                const resourceProperty = new ResourceProperties();
                const fileNameWithExtension = file?.name.split('.');
                resourceProperty.resourceTitle = fileNameWithExtension[0];
                resourceProperty.mimeType = fileNameWithExtension[fileNameWithExtension.length - 1];
                console.log('imageFormat', resourceProperty?.mimeType);
                resourceProperty.mimeSize = `${(fileSizeInKB / 1024).toFixed(2)} MB`;
                resourceProperty.base64Resource = base64String;
                resourceProperty.isDeleteIcon = false;
                resourceProperty.documentTypeId = 0;
                // this.customerDetails?.resourcePropertyModel?.push(resourceProperty);
                resourceProperty.added = true;
                requestModel?.push(resourceProperty);
                console.log('metadata', this.customerDetails);
              } else {
                console.error("Failed to get base64 string.");
              }
            })
            .catch((error) => {
              console.error("Error processing file:", error);
            });
        },
        error => {
          console.error('Error compressing file:', error);
        }
      );
    } else {
      // Process PDF files
      this.readThis(file)
        .then((base64String) => {
          if (base64String) {
            console.log(file);
            const fileSizeInKB = file?.size / 1024;
            const resourceProperty = new ResourceProperties();
            const fileNameWithExtension = file?.name?.split('.');
            resourceProperty.resourceTitle = fileNameWithExtension[0];
            resourceProperty.mimeType = fileNameWithExtension[fileNameWithExtension.length - 1];
            resourceProperty.mimeSize = `${(fileSizeInKB / 1024).toFixed(2)} MB`;
            resourceProperty.base64Resource = base64String;
            resourceProperty.isDeleteIcon = false;
            resourceProperty.documentTypeId = 1; // Assuming 1 represents PDF document type
            resourceProperty.added = true;
            requestModel?.push(resourceProperty);
            // console.log('metadata', this.customerDetails);
          } else {
            console.error("Failed to get base64 string.");
          }
        })
        .catch((error) => {
          console.error("Error processing file:", error);
        });
    }
  }

  readThis(event: any): Promise<string | null> {
    return new Promise((resolve) => {
      const file: File = event;
      const myReader: FileReader = new FileReader();

      myReader.onloadend = (e) => {
        const base64String = myReader?.result as string;
        resolve(base64String);
      };

      myReader.onerror = (error) => {
        console.error("File reading failed:", error);
        resolve(null);
      };

      if (file) {
        myReader.readAsDataURL(file);
      } else {
        console.error("No file selected.");
        resolve(null);
      }
    });
  }

downloadOrPreviewImage(event){
  // (click)="downloadOrPreviewImage(f)"
const src = event?.base64Resource;
const link = document?.createElement("a")
link.href = src
link.download = 'fileName'
link.click()
link.remove()
}

previewImages(event){
  if(event.base64Resource?.includes('image')){
    this.previewImg= event?.base64Resource;
    this.displayImg = event;
    this.openImgPopUp()
  }else{
    // this.openOfficeFileFromBase64(event.base64Resource,event.resourceTitle,event.mimeType)
  }
}


openOfficeFileFromBase64(base64String: string, fileName: string, fileType: string) {
  // Decode the base64 string
  const base64Content = base64String.split(';base64,')[1];

  // Decode base64 to binary
  const byteCharacters = atob(base64Content);

  // Convert the byte string to an ArrayBuffer
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Open the URL in a new tab/window
  window.open(url, '_blank');

  // Optionally, revoke the URL after opening
  // URL.revokeObjectURL(url);
}


clear() {
  const id = this.customerDetails?.id ?? 0;
  const addressId = this.customerDetails?.addressId ?? 0;
  const newCustomerDetails = new CustomerDetails();
  newCustomerDetails.id = id;
  newCustomerDetails.addressId = addressId;

  this.kycEnabled = false;
  this.customerDetails.isKYC = false; 
  if (!newCustomerDetails.id) {
    this.data.accountLedgerId = 0;
  }
  newCustomerDetails.addressModel = new AddressVM();
  newCustomerDetails.openingBalanceModel = this.customerDetails?.openingBalanceModel?.map(item => ({
    ...item,
    rate: 0,
    weight: null
  })) ?? [];
  const ledgerItemTypeTaxModel = structuredClone(this.customerDetails?.ledgerItemTypeTaxModel) 
  this.customerDetails = newCustomerDetails;
  this.customerDetails.ledgerItemTypeTaxModel = ledgerItemTypeTaxModel?.map(row=>({
    ...row,
    defaultInputTypeId : null ,
    defaultOutputTypeId : null,
    accountLedgerId : null,
  }))
  this.campaignGroupIds = undefined;
  this.resetShareHolderData();
}

selectAccountGroup(){
  if(this.data?.SundryType){
    const accountGroupId:any = this.getAllDropdownModel?.accountGroupDropdown.find(x=>x?.name==this.data?.SundryType).id;
      this.customerDetails.accountGroupId = accountGroupId;
  }
} 


selectCrOrDr(value?){
  if (this.customerDetails?.accountGroupId == this.accountGroupId.SundryCreditor) {
    this.customerDetails.crOrDr = this.transactionType.CR;
    this.updateColumnValues('transactionTypeId',this.transactionType.CR )
  } else if (this.customerDetails?.accountGroupId ==this.accountGroupId.SundryDebitor) {
    this.customerDetails.crOrDr = this.transactionType.DR;
    this.updateColumnValues('transactionTypeId',this.transactionType.DR )
  }
}

updateColumnValues(columnFieldName,newValue) {
  this.gridApiForMetalOpening?.api?.forEachNode((node) => {
    node.setDataValue(columnFieldName, newValue);
  });
  this.gridApiForMetalOpening?.api?.refreshCells();
}

onCellValueChange(params){
  const data  = params.data;
  data.added  = false;
  data.edited = false;
  data.stockValue = parseInt(data?.rate ? data?.rate :0 ) * parseInt(data?.weight ? data?.weight :0 );
  params?.api?.refreshCells();
}

removeMetalOpeningRowData(params:any){
  if(params?.data?.id){
    params.data.deleted   = true;
  }
  params.data.rate        = null;
  params.data.stockValue  = 0;
  params.data.weight      = null;
  params.api.refreshCells();
}

valueChangedTabwise(){
  const url = this.router?.url;
  if(url?.includes('Transaction/manufacturing')){
    this.customerDetails.ledgerTypeId = LedgerTypesEnum.JobWorker;
  }
  else if(url?.includes('Transaction')){
    this.customerDetails.ledgerTypeId = LedgerTypesEnum.Customer;
  }else if(url.includes('Manufacturer')){
    this.customerDetails.ledgerTypeId = LedgerTypesEnum.JobWorker;
  }
}

  openImgPopUp() {
    let Images: any[] = [...this.customerDetails?.resourcePropertyModel];
    const allowedMimeTypes = ["jpg", "jpeg", "png", "image/png", "webp"];
    Images = Images?.filter(x => allowedMimeTypes?.includes(x?.mimeType));
    var data = {
      jewelryCatalogue: [...Images],
    }
    this.notificationService.openModalPopup(OpenImagePopupComponent, data, '', ModalPopupSize.SM, 'image_popup_fullscreen', true, true).then(
      (result) => {}
    )
  }


clearDatePickers() {
  if (this.specialDayDatepicker) {
    this.specialDayDatepicker.onClearPicker();
  }
  if (this.dobDatePicker) {
    this.dobDatePicker.onClearPicker();
  }
}
close(){
  this.activeModal?.close();
  this.storageService.customDropdownValue.next(this.customerDetails.ledgerName)
}


// --------------------------- share holder implementation start ------------


addNewRow(){
  const obj = {
    shareHolderResourcePropertyModel:[]
  }
    this.gridApiHolderReady?.api?.applyTransaction({add: [obj]});
    this.setSelectedRowNodeData();
}

setSelectedRowNodeData(){
  const list:any[]  = this.gridApiHolderReady?.api?.getModel()?.rowsToDisplay?.map(((item)=>item?.data));
  const index =  list?.length - 1;
  if(index >= 0)  {
    this.selectRowByIndex(index)
  }else{
    this.selectedRowNode = {};
  };
}

selectRowByIndex(index: number) {
  this.selectedRowNode = this.gridApiHolderReady?.api?.getDisplayedRowAtIndex(index);
  if (this.selectedRowNode) {
    this.selectedRowNode?.setSelected(true);
  }
}

onRowClicked(event: RowClickedEvent) {
  this.selectedRowNode = event?.node;
  this.selectedRowNode?.setSelected(true);  // Selects the clicked row
}

onGridDestroy(){
  this.agGridDataModelshareHolder.rows = this.gridApiHolderReady?.api?.getModel()?.rowsToDisplay?.map(((item)=>item?.data))??[];
}

resetShareHolderData(){
  this.gridApiHolderReady?.api?.setRowData([]);
  this.selectedRowNode = {};
  this.agGridDataModelshareHolder.rows =[];
  this.deletedSharedHolderList =[];
  this.previewImg = null;
}

onDeleteIconClick(params){
  this.onDeleteRowClickedResponse(params?.detail?.data);
}

onDeleteRowClickedResponse(event) {
  if(event?.data?.id){
    event.data.deleted = true;
    this.deletedSharedHolderList.push(event?.data);
    this.previewImg = null;
  }
  this.gridApiHolderReady?.api.refreshCells(true);
  this.setSelectedRowNodeData();
}

onCellValue(event) {
  let rowsToDisplay = this.gridApiHolderReady?.api?.getModel()?.rowsToDisplay?.map(((item)=>item?.data))??[];
  switch (event.column.colId) {
    case 'shareHolderName':
      if(rowsToDisplay?.some((x)=>x?.id==event?.data?.id)){
        event.data.edited= true;
      }else{
        event.data.added = true;
      }
    break;
    case 'nationalityId':
      if(rowsToDisplay?.some((x)=>x?.id==event?.data?.id)){
        event.data.edited= true;
      }else{
        event.data.added = true;
      }
    break;
    case 'sharePer':
      if(rowsToDisplay?.some((x)=>x?.id==event?.data?.id)){
        event.data.edited= true;
      }else{
        event.data.added = true;
      }
    break;
  }
  event.data.updateAdd = true;
  this.gridApiHolderReady?.api.refreshCells(true);
}

shareHolderResourcesExpirydateChanged(item){
  this.actionUpdate(item)
}

actionUpdate(item){
  if(item?.id){
    item.edited = true;
    item.added = false;
  } else {
    item.edited = false;
    item.added = true;
  }
}

previewImagesShareHolder(event){
  if(event.base64Resource?.includes('image')){
    this.previewImg= event?.base64Resource;
    this.displayImg = event;
    this.openShareHolderImgPopUp()
  }else{
  }
}

openShareHolderImgPopUp() {
  let Images: any[] = [...this.selectedRowNode?.data?.shareHolderResourcePropertyModel];
  const allowedMimeTypes = ["jpg", "jpeg", "png", "image/png", "webp"];
  Images = Images?.filter(x => allowedMimeTypes?.includes(x?.mimeType));
  var data = {
    jewelryCatalogue: [...Images],
  }
  this.notificationService.openModalPopup(OpenImagePopupComponent, data, '', ModalPopupSize.SM, 'image_popup_fullscreen', true, true).then(
    (result) => {}
  )
}
// --------------------------- share holder implementation end ------------

ngOnDestroy() {
  this.isFromAccountLedger = false;
  if (this.stream) {
    this.stream.getTracks().forEach(track => track.stop());
  };
  this.resetRowSubscription?.unsubscribe();
  this.plusIconSubscription?.unsubscribe();
}
  


}