import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SendDataService } from '../../../shared/services/send-data.service'
import { AccountledgerDetailInfoComponent } from './tabs/accountledger-detail-info/accountledger-detail-info.component';
import { AccountLedgerReportTabs } from './accountledger-report.model';
import { AccountLedgerReportAmountComponent } from './tabs/accountledger-report-amount/accountledger-report-amount.component';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { RangeDatepickerComponent } from 'src/app/shared/common-components/range-datepicker/range-datepicker.component';
import { ExportDropdownBindDynamicParameter, IconHover, StorageName } from 'src/app/shared/enum/common-enum';
@Component({
  selector: 'app-accountledger-report',
  templateUrl: './accountledger-report.component.html',
  styleUrls: ['./accountledger-report.component.scss']
})
export class AccountLedgerReportComponent implements OnInit , AfterViewInit {
  accountLedgerReportTabs = AccountLedgerReportTabs ;
  defaultFilterData;
  currentHoverEffect = IconHover.Pink;
  @ViewChild(AccountledgerDetailInfoComponent) accountledgerDetailInfoComponent : AccountledgerDetailInfoComponent;
  @ViewChild(AccountLedgerReportAmountComponent)accountLedgerReportAmountComponent : AccountLedgerReportAmountComponent;
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ViewChild(RangeDatepickerComponent) rangeDatepickerComponent : RangeDatepickerComponent;
  @Output() emitFilterData = new EventEmitter();
  viewfromDate:any;
  viewtoDate:any;
  activeTabId: number = 1;
  @Input() fromDate;
  @Input() toDate;
  @Input() data : any = {};
  tabChange: boolean = true;
  isnavigateFromAmount:boolean;
  exportDropdownBindDynamicParameter = ExportDropdownBindDynamicParameter;
  filterVal:any;
  constructor(public activeModal              : NgbActiveModal,
  )   { }
  ngAfterViewInit(): void {
    this.apply(true ,false);
  }
 
  ngOnInit(): void 
  {
  }

  onTabChange(nextId) {
    this.activeTabId = nextId?.nextId;
    // this.ledgerId = 0;
    setTimeout(() => {
      this.apply(true, false);
    }, 0)
  }

  isViewAllLedgerDisabled(){
    return this.activeTabId === this.accountLedgerReportTabs.BalanceAmount;
  }

  handleValueChange(value: any) {
    this.filterVal = value;
  }

  exportBindParameterBasedOnTab(value) {
    switch (this.activeTabId) {
      case 1:
        switch (value) {
          case ExportDropdownBindDynamicParameter.RequestModel:
            return this.accountLedgerReportAmountComponent?.apiRequestModel;
          case ExportDropdownBindDynamicParameter.GridApi:
            return [this.accountLedgerReportAmountComponent?.gridApi];
          case ExportDropdownBindDynamicParameter.GridName:
            return [StorageName.REPORT_ACCOUNTLEDGERREPORT_AMOUNT_GRID];
        }
        break;
      case 2:
        break;
      case 3:
        switch (value) {
          case ExportDropdownBindDynamicParameter.RequestModel:
            return this.accountledgerDetailInfoComponent?.apiRequestModel;
          case ExportDropdownBindDynamicParameter.GridApi:
            return [this.accountledgerDetailInfoComponent?.gridApiAccountLedgerDetailInfo];
          case ExportDropdownBindDynamicParameter.GridName:
            return ["REPORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID"];
        }
        break;
    }
  }


  callApiForExport() {
    
    switch (this.activeTabId) {
      case 1:
        this.accountLedgerReportAmountComponent.callApiForExportData();
        break;
      case 2:
        break;
      case 3:
        this.accountledgerDetailInfoComponent.callApiForExportData();
        break;
    }
  }

  emitedDate(val) {
    
  }

  apply(isOnit: boolean, isnavigateFromAmount: boolean = false) {
    switch (this.activeTabId) {
      case this.accountLedgerReportTabs.BalanceAmount:
        // this.accountLedgerReportAmountComponent.getReport();
        // // this.accountLedgerReportAmountComponent.apiRequestModel.fromDate = this.fromDate;
        // // this.accountLedgerReportAmountComponent.apiRequestModel.toDate = this.toDate;
        // if (!isOnit) {
          
        // }
        break;

      case this.accountLedgerReportTabs.ViewAllLedgerAmt:
        // this.accountledgerDetailInfoComponent.getAccountLedgerDetailAmtInfo();
        // if (isnavigateFromAmount) {
        //   // this.accountledgerDetailInfoComponent.apiRequestModel.fromDate = this.viewfromDate;
        //   // this.accountledgerDetailInfoComponent.apiRequestModel.toDate = this.viewtoDate;
        // } else {
        //   // this.accountledgerDetailInfoComponent.apiRequestModel.fromDate = this.fromDate;
        //   // this.accountledgerDetailInfoComponent.apiRequestModel.toDate = this.toDate;
        // }
        // if (!isOnit) {
        //   // this.accountledgerDetailInfoComponent.getDate(this.fromDate, this.toDate);
          
        // }
        // break;
    }
  }

  openAdvanceFilterPopUp() {
    switch (this.activeTabId) {
      case 1:
        this.accountLedgerReportAmountComponent.openAdvanceFilterPopUp();
        break;
      case 2:
        break;
      case 3:
        this.accountledgerDetailInfoComponent.openAdvanceFilterPopUp();
        break;
    }
  }

  resetFilter() {
    switch (this.activeTabId) {
      case 1:
        this.accountLedgerReportAmountComponent.resetFilter();
        break;
      case 2:
        break;
      case 3:
        this.accountledgerDetailInfoComponent.resetFilter();
        break;
    }

  }

  navigateToDetailInfoTab(params: any) {
    this.defaultFilterData = params;
    this.activeTabId = this.accountLedgerReportTabs.ViewAllLedgerAmt;
    // this.viewfromDate = fromDate;
    // this.viewtoDate = toDate;
    this.isnavigateFromAmount = true;
    setTimeout(() => {
      this.apply(true, this.isnavigateFromAmount);
    }, 0);
  }

}
