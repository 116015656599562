import { StorageService } from './../../services/storage.service';
import { Component, ViewEncapsulation, HostListener, Renderer2, ElementRef, Inject, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { Subscription, catchError, of, switchMap, throwError } from 'rxjs';
import {MaximizeToggleService} from 'src/app/shared/services/maximize-toggle-service.service'
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { ShowHeaderPageNameAndInvoieNum } from '../../models/common.model';
import { DOCUMENT } from '@angular/common';
import { IconHover, StorageName } from '../../enum/common-enum';
import { PermissionsService } from 'src/app/components/administration/usermanagment/tabs/permissions/permissions.service';
import { HttpStatusCode } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
  // public showSaleInvoiceInfo: boolean = false;
  @ViewChild('ContentComponent') ContentComponent;
  currentHoverEffect = IconHover.Pink;
  public elem: any;
  public showPurchaseInvoiceInfo: boolean = false;
  public showHeaderPageNameAndInvoieNum = new ShowHeaderPageNameAndInvoieNum();
  public showsaleBill :boolean = false; 
  public showpaymentVoucher :boolean = false; 
  public showsaleorderInfo:boolean =false;
  public productOpeaningInfo:boolean = false;
  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  SaleinvoicenNo:any;
  private navItemsSubscription: Subscription;
  // For Horizontal Menu
  public margin: any = 0;
  subscription: Subscription;
  widthVal:number;
  filteredOptions: any[] = [];
  dropdownShow:boolean = false
  constructor(
    private router: Router, 
    public navServices: NavService,
    public layout: LayoutService,
    private renderer: Renderer2 ,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: any ,
    public sendDataService: SendDataService,
    public storageService:StorageService,
    public maximizeToggleService:MaximizeToggleService,
    public userPermissionsService: PermissionsService,
    private toaster: ToastrService,
    public dashboardService : DashboardService
    ) {
      this.navServices?.items?.next(JSON.parse(JSON.stringify(this.navServices?.MENUITEMS)));         
      this.setPermissionAccessByUser();
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.widthVal=event.target.innerWidth ;
    if (event.target.innerWidth < 1450) {
      this.leftArrowNone = false;
      this.rightArrowNone = false;
    }
    else {
      this.leftArrowNone = true;
      this.rightArrowNone = true;
    }
  }
  isDropdownShow() {
    const permissions:any = this.storageService.retrieve(StorageName.USER_PERMISSIONS) as Permissions;
    let value = permissions?.dashboard
    if(value){
        this.dropdownShow  = value;
    }
  }
  ByUserId() {
    if (this.router?.url?.includes('/dashboard')) {
      this.getDashboardByUserId();
    }
  }
  ngOnInit() {
    this.isDropdownShow()
    const permissions = this.storageService?.retrieve(StorageName?.USER_PERMISSIONS) as Permissions;
    this.filteredOptions = this.options?.filter(option => permissions[this.toCamelCase(option?.name)]);
    this.setSelectedValueBasedOnRoute();
    this.routerSubscription = this.router?.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setSelectedValueBasedOnRoute();
      }
    });  this.router?.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      setTimeout(() => {
        this.setActiveClassForRoute(event?.url);
      });
    }
  });

  const currentRoute = this.router.url;
  this.setActiveClassForRoute(currentRoute);
    this.subscription = this.sendDataService.headerPageNameAndInvoiceNum.subscribe((res) => {
        this.showHeaderPageNameAndInvoieNum = res;
    });
    this.elem = document.documentElement;
    if (this.width < 1450) {
      this.leftArrowNone = false;
      this.rightArrowNone = false;
    }
    else {
      this.leftArrowNone = true;
      this.rightArrowNone = true;
    }
    console.log('menuItems',this.menuItems);
    this.ByUserId()
  }

  getDashboardByUserId(){
    const userId = this.storageService.retrieve("user");
    this.dashboardService.getDashboardByUserId(userId?.id).subscribe((res=>{
    if(res?.isSuccess){
     this.onSelectionChange(res?.response?.recentlyUsedDashboardId?.toString())
    }
    }))
  }
  
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.width = event.target.innerWidth - 500;
  // }

  //add dynamic and remove class start 
  onItemClick(event: Event,menuItem) {
    event.stopPropagation();
    const clickedElement = event.target as HTMLElement;
    const parentLi = clickedElement.closest('.sidebar-list');
    if (parentLi.tagName === 'LI') {
      const listItems = this.el.nativeElement.querySelectorAll('.sidebar-list');
      listItems.forEach(element => {
        this.renderer.removeClass(element, 'selected');
      });
      this.renderer.addClass(parentLi, 'selected');
    }
    if(menuItem?.icon === 'dashboard')
    this.ByUserId()
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event , onRoute?:boolean) {
    const clickedElement = event?.target as HTMLElement;
    if ((!clickedElement.closest('.sidebar-links')) || (onRoute)) {
      const listItems = document.querySelectorAll('.sidebar-links .sidebar-list');
      setTimeout(()=>{
        listItems.forEach(element => {
          this.renderer.removeClass(element, 'selected');
        });
      },50)
    }
  }
  //add dynamic and remove class start 

  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = true;


  @ViewChild("widgetsContent", { read: ElementRef })
  public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: "smooth",
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: "smooth",
    });
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  public setActiveClassForRoute(route: string) {
    let foundRoute = false;
    this.menuItems?.forEach(item => {
        const isActive = route === item.path || (item.children && item.children.some(subItem => route === subItem.path));
        if (isActive) {
            item.active = true;
            foundRoute = true;
        } else {
            item.active = false;
        }
    });

    if (!foundRoute) {
        this.menuItems?.forEach(item => {
            item.active = item.path === '/dashboard';
            if (item.children) {
                item.children.forEach(subItem => subItem.active = false);
            }
        });
    }
}

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }


  toggleFullScreen() {
    this.maximizeToggleService.setHeaderFlag(false);
    this.maximizeToggleService.toggleFullScreen();
  }
  options: any[] = [
    { id: '0', name: '' },
    { id: '1', name: 'Retailer' },
    { id: '2', name: 'Manufacturing' },
    { id: '3', name: 'Catalogue Tracking' },
    { id: '4', name: 'Stock' },
    { id: '5', name: 'Customer' },
    { id: '6', name: 'Sales Person' }
  ];

  selectedValue: string = '0';
  routerSubscription: Subscription;

  toCamelCase(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    ).replace(/\s+/g, '');
  }
  
  setSelectedValueBasedOnRoute() {
    const currentPath = this.router.url;
    switch (currentPath) {
      case '/dashboard/retailer-dashboard':
        this.selectedValue = '1';
        break;
      case '/dashboard/manufacturing-dashboard':
        this.selectedValue = '2';
        break;
      case '/Inventory/jewelry-catalogue-tracking':
        this.selectedValue = '3';
        break;
      case '/dashboard/stock-dashboard':
        this.selectedValue = '4';
        break;
      case '/dashboard/customer-dashboard':
        this.selectedValue = '5';
        break;
      case '/dashboard/salesperson-dashboard':
        this.selectedValue = '6';
        break;
      default:
        this.selectedValue = '0';
        break;
    }
  }
  updateRecentlyUsedDashboard(id:any){
    const userId = this.storageService.retrieve("user");
    const requestData ={id: userId?.id,recentUsedDashboardId: id}
   this.dashboardService.updateRecentlyUsedDashboard(requestData).subscribe((res=>{
    if(res?.isSuccess){
      this.onSelectionChange(id)
    }
   }))
  }
  onSelectionChange(event: any) {
    if (event) {
      switch (event) {
        case '1':
          this.router.navigate(['/dashboard/retailer-dashboard']);
          break;
        case '2':
          this.router.navigate(['/dashboard/manufacturing-dashboard']);
          break;
        case '3':
          this.router.navigate(['/Inventory/jewelry-catalogue-tracking']);
          break;
        case '4':
          this.router.navigate(['/dashboard/stock-dashboard']);
          break;
        case '5':
          this.router.navigate(['/dashboard/customer-dashboard']);
          break;
        case '6':
          this.router.navigate(['/dashboard/salesperson-dashboard']);
          break;
        default:
          this.router.navigate(['/dashboard']);
          break;
      }
      this.selectedValue = event;
    }
  }


  isDashboardActive(): boolean {
    const currentPath = this.router.url;
    const activePaths = [
      '/dashboard',
      '/dashboard/retailer-dashboard',
      '/dashboard/manufacturing-dashboard',
      '/dashboard/customer-dashboard',
      '/dashboard/salesperson-dashboard',
      '/dashboard/stock-dashboard',
      '/Inventory/jewelry-catalogue-tracking'
    ];
    return activePaths.includes(currentPath);
  }

  // setPermissionAccessByUser() {
  //   this.navItemsSubscription = this.navServices.items.subscribe(menuItems => {
  //     this.menuItems = menuItems;
  //     this.setupNavigationEventHandling(menuItems);
  //   });
  // }

  setPermissionAccessByUser() {
    this.navItemsSubscription = this.navServices.items.subscribe(menuItems => {
      const userPermissions = this.storageService.retrieve(StorageName.USER_PERMISSIONS);
      
      if (JSON.stringify(userPermissions) == "{}") {
        this.GetPermissionAccessByUser().subscribe(() => {
          this.menuItems = this.getMenuListByPermission(menuItems);
          this.setupNavigationEventHandling(this.menuItems);  
        }, (error) => {
          console.error('Failed to get permissions:', error);  
        });
      } else {
        this.sendDataService.permissionVM = userPermissions;
        this.menuItems = this.getMenuListByPermission(menuItems);
        this.setupNavigationEventHandling(this.menuItems);
      }
    });
  }

  
  private setupNavigationEventHandling(menuItems: any[]) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        menuItems.filter(items => {
          if (items.path === event.url) {
            this.setNavActive(items);
          }
          if (!items.children) { return false; }
          items.children.filter(subItems => {
            if (subItems.path === event.url) {
              this.setNavActive(subItems);
            }
            if (!subItems.children) { return false; }
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url) {
                this.setNavActive(subSubItems);
              }
            });
          });
        });
      }
    });
  }
  

  private GetPermissionAccessByUser() {
    return this.userPermissionsService.getPermissionAccessByUser()
      .pipe(
        catchError(err => this.handleError(err)),
        switchMap(res => {
          if (res.isSuccess === true) {
            this.sendDataService.permissionVM = res.response;
            this.storageService.store(StorageName.USER_PERMISSIONS, res?.response);
          }
          return of(null); // Emit a signal to continue the chain
        })
      );
  }
  
  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  getMenuListByPermission(menuItems: Menu[]): Menu[] {

    //#region Product

    if(!this.sendDataService?.permissionVM?.product){
      menuItems = menuItems.filter(s=> !['HEADER.MENUITEMS.PRODUCT'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems?.find(item => item?.title === "HEADER.MENUITEMS.PRODUCT");
      if(!this.sendDataService?.permissionVM?.productOpeningCanView){
        if (parentItem) {
            parentItem.children = parentItem?.children?.filter(child => child?.title !== "HEADER.MENUITEMS.PRODUCT.PRODUCTOPENING");
        }
      }
      if(!this.sendDataService?.permissionVM?.accountLedgerCanView){
        if (parentItem) {
            parentItem.children = parentItem?.children.filter(child => child?.title !== "HEADER.MENUITEMS.PRODUCT.ACCOUNTLEDGER");
        }
      }
      if(!this.sendDataService?.permissionVM?.accountLedgerCanView){
        if (parentItem) {
            parentItem.children = parentItem?.children.filter(child => child?.title !== "HEADER.MENUITEMS.PRODUCT.PARTIES");
        }
      }
      if(!this.sendDataService?.permissionVM?.appraisalCanView){
        if (parentItem) {
            parentItem.children = parentItem?.children.filter(child => child?.title !== "HEADER.MENUITEMS.PRODUCT.APPRAISAL");
        }
      }
    }
    //#endregion

    //#region Transaction
    
    if(!this.sendDataService?.permissionVM?.transaction){
      menuItems = menuItems?.filter(s=> !['HEADER.MENUITEMS.TRANSACTION'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems.find(item => item?.title === "HEADER.MENUITEMS.TRANSACTION");
      if(!this.sendDataService?.permissionVM?.salesInvoiceCanView){
        if (parentItem) {
            parentItem.children = parentItem?.children?.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.SALEINVOICE");
        }
      }
      if(!this.sendDataService?.permissionVM?.purchaseInvoiceCanView){
        if (parentItem) {
            parentItem.children = parentItem?.children?.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.PURCHASEINVOICE");
        }
      }
      if(!this.sendDataService?.permissionVM?.oldJewelryScrapInvoiceCanView){
        if (parentItem) {
            parentItem.children = parentItem?.children?.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.OLDJEWElRY_SCRAP_INVOICE");
        }
      }
      if(!this.sendDataService.permissionVM?.oldJewelryScrap){
        if (parentItem) {
            parentItem.children = parentItem?.children?.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.OLDJEWELLERY");
        }
      }

      // if(!this.sendDataService.permissionVM.oldJewelryScrap){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.OLDJEWElRY_SCRAP_INVOICE");
      //   }
      // }
      // if(!this.sendDataService.permissionVM.oldJewelryScrap){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.OLDJEWElRY_SCRAP_INVOICE");
      //   }
      // }
      // if(!this.sendDataService.permissionVM.oldJewelryScrap){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.OLDJEWElRY_SCRAP_INVOICE");
      //   }
      // }
    
      // if(!this.sendDataService.permissionVM.oldJewelryScrap){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.OLDJEWELLERY");
      //   }
      // }
      if(!this.sendDataService.permissionVM?.receiptVoucherCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.RECEIPTVOUCHER");
        }
      }
      if(!this.sendDataService.permissionVM?.paymentVoucherCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.PAYMENTVOUCHER");
        }
      }
      if(!this.sendDataService.permissionVM?.materialIssueCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.MATERIAL_ISSUE");
        }
      }
      if(!this.sendDataService.permissionVM?.materialReceiveCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.MATERIAL_RECEIVE");
        }
      }
      if(!this.sendDataService.permissionVM?.materialInCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.MATERIAL_IN");
        }
      }
      if(!this.sendDataService.permissionVM?.materialOutCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.MATERIAL_OUT");
        }
      }
      if(!this.sendDataService.permissionVM?.salesReturnCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.SALERETURN");
        }
      }
      if(!this.sendDataService.permissionVM?.purchaseReturnCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.PURCHASERETURN");
        }
      }
     if(!this.sendDataService.permissionVM?.creditNoteCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.CREDITNOTE");
        }
      }
      if(!this.sendDataService.permissionVM?.debitNoteCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.DEBITNOTE");
        }
      }
      if(!this.sendDataService.permissionVM?.stockJournalOption1CanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.STOCKJOURNAL");
        }
      }
      if(!this.sendDataService.permissionVM?.stockJournalOption2CanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.STOCK-JOURNAL-2");
        }
      }
      if(!this.sendDataService.permissionVM?.pdcEntryCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.PDCENTRY");
        }
      }
      if(!this.sendDataService.permissionVM?.contraVoucherCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.CONTRAVOUCHER");
        }
      }
      if(!this.sendDataService.permissionVM?.journalVoucherCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.JOURNALVOUCHER");
        }
      }
      // if(!this.sendDataService.permissionVM?.purchaseFixing){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.PURCHASEFIXING");
      //   }
      // }
      // if(!this.sendDataService.permissionVM?.saleFixing){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.SALEFIXING");
      //   }
      // }
      if(!this.sendDataService.permissionVM?.repairInvoiceCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.REPAIRINVOICE");
        }
      }
      if(!this.sendDataService.permissionVM?.posCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.POS");
        }
      }

      if(!this.sendDataService.permissionVM?.salesQuotationCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.SALESQUOTATION"); //|| "Estimate Quotation Invoice" comment this code added by someone 
        }
      }
      if(!this.sendDataService.permissionVM?.deliveryNoteCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.DELIVERYNOTE"); 
        }
      }
      if(!this.sendDataService.permissionVM?.expensesCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.EXPENSES");
        }
      }
      if(!this.sendDataService.permissionVM?.investmentFundCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.INVESTMENTFUND");
        }
      }
      if(!this.sendDataService.permissionVM?.loansCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.LOANS");
        }
      }
      if(!this.sendDataService.permissionVM?.bankReconciliationCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.BANKRECONCILIATION");
        }
      }
      // if(!this.sendDataService.permissionVM.stockJournalOption2){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.STOCK-JOURNAL-2");
      //   }
      // }
    }


    //#endregion

    //#region Orders
    
    if(!this.sendDataService?.permissionVM?.orders){
      menuItems = menuItems?.filter(s=> !['HEADER.MENUITEMS.ORDERS'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems?.find(item => item.title === "HEADER.MENUITEMS.ORDERS");
      if(!this.sendDataService?.permissionVM?.salesOrderCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.SALEORDER");
        }
      }
      if(!this.sendDataService.permissionVM.repairOrderCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.ORDERS.REPAIRORDER");
        }
      }
      if(!this.sendDataService.permissionVM.saleRepairOrderProcess){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.ORDERS.SALEREPAIRORDERPROCESS");
        }
      }
      if(!this.sendDataService.permissionVM.purchaseOrderCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.TRANSACTION.PURCHASEORDER");
        }
      }
    }
    //#endregion

    //#region Inventory
    
    if(!this.sendDataService?.permissionVM?.inventory){
      menuItems = menuItems.filter(s=> !['HEADER.MENUITEMS.INVENTORY'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems.find(item => item.title === "HEADER.MENUITEMS.INVENTORY");
      if(!this.sendDataService.permissionVM.jewelryCatalogueCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.JEWELRYCATALOGUE");
        }
      }
      if(!this.sendDataService.permissionVM.physicalStockCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.PHYSICAL_STOCK");
        }
      }
      if(!this.sendDataService.permissionVM.consignmentInCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_IN");
        }
      }
      if(!this.sendDataService.permissionVM.consignmentOutCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_OUT");
        }
      }
      if(!this.sendDataService.permissionVM.consignmentItemsCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_ITEMS");
        }
      }
      if(!this.sendDataService.permissionVM.stockHistory){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.STOCK-SUMMARY");
        }
      }
      if(!this.sendDataService.permissionVM.goldSilverAnalysis){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.GOLD_SILVER_ANALYSIS");
        }
      }
      if(!this.sendDataService.permissionVM.diamondStoneWeightReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.DIAMOND_STONE_WEIGHT_REPORT");
        }
      }

      if(!this.sendDataService.permissionVM.goldSilver){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.GOLD_AND_SILVER");
        }
      }
      if(!this.sendDataService.permissionVM.diamondStones){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.DIAMOND_AND_STONE");
        }
      }
      if(!this.sendDataService.permissionVM.imitationWatches){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.IMITATION_AND_WATCHES");
        }
      }

      if(!this.sendDataService.permissionVM.rfid){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.RFID");
        }
      }
      if(!this.sendDataService.permissionVM.barcode){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.BARCODE");
        }
      }
      if(!this.sendDataService.permissionVM.stockValuation){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.STOCK_VALUATION");
        }
      }
      if(!this.sendDataService.permissionVM.stockSummaryReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.STOCK_SUMMARY_REPORT");
        }
      }
      if(!this.sendDataService.permissionVM.stockTransferCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.STOCK_TRANSFER");
        }
      }
      if(!this.sendDataService.permissionVM.stockTransferHistory){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.INVENTORY.STOCK_TRANSFER_HISTORY");
        }
      }
    }
    //#endregion

    //#region Manufacture
    
    if(!this.sendDataService?.permissionVM?.manufacturer){
      menuItems = menuItems.filter(s=> !['HEADER.MENUITEMS.MANUFACTURER'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems.find(item => item.title === "HEADER.MENUITEMS.MANUFACTURER");
      if(!this.sendDataService.permissionVM.departmentCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.DEPARTMENT");
        }
      }
      if(!this.sendDataService.permissionVM.jobworkOrderCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.JOBWORKORDER");
        }
      }
      if(!this.sendDataService.permissionVM.jobworkQueueCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.JOBWORKQUEUE");
        }
      }
      if(!this.sendDataService.permissionVM.manufacturingProcess){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.MANUFACTURINGPROCESS");
        }
      }
      if(!this.sendDataService.permissionVM.jobworkQueueReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.JOBWORKQUEUEREPORT");
        }
      }
      if(!this.sendDataService.permissionVM.lossTracking){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.LOSSTRACKING");
        }
      }
      if(!this.sendDataService.permissionVM.closingReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.CLOSINGREPORT");
        }
      }
      if(!this.sendDataService.permissionVM.workLogReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.WORKLOGREPORT");
        }
      }
      if(!this.sendDataService.permissionVM.jobcardReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.JOBCARDREPORT");
        }
      }
      if(!this.sendDataService.permissionVM.departmentReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.MANUFACTURER.DEPARTMENTREPORT");
        }
      }
    }
    //#endregion

    //#region Financial Statement
    
    if(!this.sendDataService?.permissionVM?.financialStatement){
      menuItems = menuItems.filter(s=> !['HEADER.MENUITEMS.FINANCIALSTATEMENT'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems.find(item => item.title === "HEADER.MENUITEMS.FINANCIALSTATEMENT");
      if(!this.sendDataService.permissionVM.trialBalance){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.TRIALBALANCE");
        }
      }
      if(!this.sendDataService?.permissionVM?.balanceSheet){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.BALANCESHEET");
        }
      }
      if(!this.sendDataService?.permissionVM?.profitLoss){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.PROFITLOSS");
        }
      }
      if(!this.sendDataService.permissionVM.cashFlow){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.CASHFLOW");
        }
      }
      if(!this.sendDataService.permissionVM.fundFlow){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.FundFUlow");
        }
      }
      if(!this.sendDataService.permissionVM.chartOfAccount){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.CHARTOFACCOUNT");
        }
      }
      // if(!this.sendDataService.permissionVM.transactionReport){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.TRANSACTIONREPORT");
      //   }
      // }
      if(!this.sendDataService.permissionVM.taxReturn){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.TAXRETURN");
        }
      }
      if(!this.sendDataService.permissionVM.saleAnalysis){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.SALEANALYSIS");
        }
      }
      if(!this.sendDataService.permissionVM.purchaseAnalysis){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.PURCHASEANALYSIS");
        }
      }
      
    }
    //#endregion

    //#region Report
    
    if(!this.sendDataService?.permissionVM?.report){
      menuItems = menuItems.filter(s=> !['HEADER.MENUITEMS.REPORT'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems.find(item => item.title === "HEADER.MENUITEMS.REPORT");
      if(!this.sendDataService.permissionVM.transactionsReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.INVOICE");
        }
      }
      if(!this.sendDataService.permissionVM.accountLedgerReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.ACCOUNTLEDGERREPORT");
        }
      }
      if(!this.sendDataService.permissionVM.dayReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.DAYREPORT");
        }
      }
      if(!this.sendDataService.permissionVM.cashbankBook){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.CASHBANKBOOK");
        }
      }
      if(!this.sendDataService.permissionVM.ageingReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.AGEINGREPORT");
        }
      }
      if(this.sendDataService.permissionVM.kycReport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.KYCREPORT");
        }
      }
      // if(!this.sendDataService.permissionVM.stockReport){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.FINANCIALSTATEMENT.STOCKREPORT");
      //   }
      // }
      if(!this.sendDataService.permissionVM.dailySalesAndCollectionSummary){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.DAILY_SALES_AND_COLLECTION_SUMMARY");
        }
      }
      // if(!this.sendDataService.permissionVM.borrowersReport){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.REPORT.BORROWERSREPORT");
      //   }
      // }
    }
    //#endregion

    //#region Settings

    if(!this.sendDataService.permissionVM.settings){
      menuItems = menuItems.filter(s=> !['HEADER.MENUITEMS.SETTINGS'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems.find(item => item.title === "HEADER.MENUITEMS.SETTINGS");
      if(!this.sendDataService.permissionVM.setSoftwareCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.SETSOFTWARE");
        }
      }
      if(!this.sendDataService.permissionVM.voucherTypeCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.VOUCHERTYPE");
        }
      }
      if(!this.sendDataService.permissionVM.dataImportExport){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.DATAIMPORTEXPORT");
        }
      }
      if(!this.sendDataService.permissionVM.dataExportInTally){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.DATAEXPORTINTALLY");
        }
      }
      // if(!this.sendDataService.permissionVM.whatsAppMarketing){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.WHATSAPP_MARKETING");
      //   }
      // }
      if(!this.sendDataService.permissionVM.campaignManagerCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.CAMPAIGN_MANAGER");
        }
      }
    }
    
    //#endregion

    //#region Administration
    
    if(!this.sendDataService.permissionVM.administration){
      menuItems = menuItems.filter(s=> !['HEADER.MENUITEMS.ADMINISTRATION'].includes(s.title));
    }
    else
    {
      const parentItem = menuItems.find(item => item.title === "HEADER.MENUITEMS.ADMINISTRATION");
      if(!this.sendDataService.permissionVM.userManagementCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.USER MANAGMENT");
        }
      }
      if(!this.sendDataService.permissionVM.mastersCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.MASTERS");
        }
      }
      if(!this.sendDataService.permissionVM.activityLog){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.ACTIVITYLOG");
        }
      }
      if(!this.sendDataService.permissionVM.taskEventCanView){
        if (parentItem) {
            parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.TASKEVENT");
        }
      }
      // if(!this.sendDataService.permissionVM.taskevents){
      //   if (parentItem) {
      //       parentItem.children = parentItem.children.filter(child => child.title !== "HEADER.MENUITEMS.SETTINGS.TASK EVENTS");
      //   }
      // }
    }
    //#endregion
    
    return menuItems;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
    this.menuItems =[];
    if(!this.router.url.includes('add-update-profile')){
      this.storageService.removeItem(StorageName.USER_PERMISSIONS); 
    }
   
     if (this.navItemsSubscription) {
      this.navItemsSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  
}
