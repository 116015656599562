import { Component, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { agGridDataModelAdditionalAmount } from './additional-amount.model'
import { GridOptions } from 'ag-grid-community';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { AgGridComponent } from 'src/app/shared/common-components/ag-grid/ag-grid.component';
import { Subscription } from 'rxjs';
import { PlusIconService } from 'src/app/shared/common-components/ag-grid/plus-icon/plus-icon.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { IconHover } from '../../../../enum/common-enum';
@Component({
  selector: 'app-additional-amount',
  templateUrl: './additional-amount.component.html',
  styleUrls: ['./additional-amount.component.scss']
})
export class AdditionalAmountComponent extends CommonService implements OnInit {
  @ViewChild("AdditionalAmountForm") AdditionalAmountForm!: NgForm;
  @ViewChildren(AgGridComponent) children: QueryList<AgGridComponent>;
  @Input() data                       : any;
  @Input() modalTitle                 : string;
  @Input() additionalAmountList       : any;
  TotalAmount                         = 0;
  getAllDropdownModel                 : any = {};
  gridApiForAdditionalAmountTable     : any;
  formName                            : StorageName.FORMNAME_TRANSACTION_SALESINVOICE;
  private plusIconSubscription        : Subscription | undefined;
  params:any
  currentHoverEffect = IconHover.Pink;
  tabGroupList: any[] = [
    {tabId  :1, 
    dataModel: agGridDataModelAdditionalAmount,                  
    label: 'Additional Amount', 
    rows:[],     
    tabIndex: 0 },
  ];
  rowdata=[];
  constructor(
    public activeModal  : NgbActiveModal,
    public renderer : Renderer2,
    public storageService : StorageService,
    private agGridService : AgGridService,
    private plusIconService : PlusIconService,
  ) {
    super(renderer);
    this.plusIconSubscription = this.plusIconService.plusIconClicked$.subscribe((context: string) => {
      this.agGridService.appendNewRow(this.gridApiForAdditionalAmountTable)
    });
  }
  ngOnInit(): void {
  }
  gridOptionsAdditionalAmount: GridOptions = {
    singleClickEdit: true
  };
  onDropdownChange(value: string) {
  }
  fillDropdownData() {
    this.getAllDropdownModel.accountLedgerDropdown = this.storageService.retrieve(StorageName.ACCOUNTLEDGER_DROPDOWN);
  }

  onGridReady(gridApi) {
      this.gridApiForAdditionalAmountTable  = gridApi;
      if(this.additionalAmountList.length==0){
        this.agGridService.addDefaultRow(this.gridApiForAdditionalAmountTable);
      }else{
        this.tabGroupList[0].rows=this.additionalAmountList;
        this.gridApiForAdditionalAmountTable.api.setRowData(this.additionalAmountList)
      }
      this.calculateTotalAmount(gridApi);
  }
  ngOnDestroy() {
    this.plusIconSubscription?.unsubscribe();
  }
  cellValueChanged(event?: any) {
    if (event?.data?.amount !== undefined) {
      this.calculateTotalAmount(event);
    }
  }
  calculateTotalAmount(event?:any) {
    const rowsToDisplay = event?.api?.getRenderedNodes()?.map(node => node?.data)??[];
    this.TotalAmount = 0;
    if(rowsToDisplay.length>0){
      for (const row of rowsToDisplay) {
        const parsedAmount = parseFloat(row.amount);
        if (!isNaN(parsedAmount)) {
          this.TotalAmount += parsedAmount;
        }
      }
    }
  }
  OnSaveAdditionalAmount() {
    this.activeModal.close({response:this.gridApiForAdditionalAmountTable,isSuccess:true})
  }
}