<div class="d-flex commonInputContainer align-items-center w-100">
    <label *ngIf="inputLabel" for="exampleFormControlInput1" class="text-nowrap mb-0 w-50">{{inputLabel}} </label>
    <button *ngIf="showmobileIcon || showphoneIcon || labelName" [class]="buttonClass +' icon'"  [style.width.px]="labelName ? 90 : null">
        <ng-container *ngIf="showmobileIcon">
            <app-svg-icon name="mobile"></app-svg-icon>
           <span *ngIf="inputValue" class="text-color-danger w-auto">*</span>
        </ng-container>
        <ng-container *ngIf="showphoneIcon">
            <app-svg-icon name="phone"></app-svg-icon>
        </ng-container>
        <span *ngIf="labelName">{{InputLabel}}</span>
    </button> 
    <select  
        [id]="id" 
        [name]="name"     
        [disabled]="isDisabled"
        [class]="selectClass +' form-select text-truncate'" 
        [(ngModel)]="selectedDialCodeValue" 
        (ngModelChange)="dialCodeValueChanged.emit($event)"
        [required]="inputValue"
        >
        <option *ngFor="let option of options" [value]="option.id">
           {{ option.name}}
        </option>
    </select>
    <input 
      autocomplete="off"
      [type]="inputType" 
      [id]="inputId" 
      [name]="inputName" 
      [placeholder]="inputPlaceholder"
      [maxlength]="inputMaxlength"
      [(ngModel)]="inputValue" 
      [class]="inputClass +' form-control'"
      (ngModelChange)="inputValueEmit.emit(inputValue)"
      [required]="isRequired"
      [disabled]="disabled"
      [readOnly]="readOnly" 
      [allowDecimal]="false"
      onlyNumbers    >
      <!-- copy&paste not working because of onlynumbers -->
</div>