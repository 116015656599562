import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { InvoiceService } from 'src/app/components/transaction/invoice/invoice.service';
import { ResponseModel } from '../../models/response-model.model';
import { Subscription, catchError } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { CommonService } from "src/app/shared/services/common.service";
import { StorageName } from '../../enum/common-enum';
import { ProductService } from 'src/app/components/product/product.service';
import { NavTabsComponent } from '../nav-tabs/nav-tabs.component';
import { TransactionService } from 'src/app/components/transaction/transaction.service';
import { StockJournalService } from 'src/app/components/transaction/stock-journal/stock-journal.service';
import { MetalSectionReadonlyService } from '../metal-section-readonly/metal-section-readonly.service';
import { DiamondCategory, MetalType, VoucherTypeName } from '../../models/common.model';
import { Router } from '@angular/router';
import { MetalTabs } from '../metal-section/metal-section.model';
import { PaginationModel } from '../ag-grid/ag-grid.model';
import { CommonFilterService } from '../../services/common-filter.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadDropdownDataService } from '../load-dropdown-data/load-dropdown-data.service';

@Component({
  selector: 'app-metal-section-readonly',
  templateUrl: './metal-section-readonly.component.html',
  styleUrls: ['./metal-section-readonly.component.scss']
})
export class MetalSectionReadonlyComponent extends  CommonService  implements  AfterViewInit, OnDestroy  {
  @ViewChild(NavTabsComponent)navTabsComponent : NavTabsComponent;
  @Input() formName:string;
  @Input() formModel:any;
  @Input() metalSectionModel:any;
  @Input() gridDynamicHeightClass : string = 'h-150px';
  @Output() getTabId = new EventEmitter<any>();
  @Output() onEditIconClick = new EventEmitter<any>();
  @Input() data: any;
  isDisabled:boolean=false
  tabGroupList: any[];
  tabId: any = 1;
  tabItems:any[] = [];
  productTax : any[] = [];
  storage = StorageName;
  gridApi:any={};
  isLoading:boolean=false;
  voucherType: string;
  voucherWiseData:any;
  applicableOnProducts:any[] = [];
  barcodeNo : string;
  uploadImagesList = [];
  getSaleInvoiceItemsDetailsModel : any = {};
  isRowClicked:boolean
  pagination  = new PaginationModel();
  gridApiGoldTable:any;
  gridApiSilverTable:any;
  gridApiDiamondTable:any;
  gridApiStoneGEMSTable:any;
  gridApiImmitationAndWatchesTable:any;

  onTaxChangeSubscription           : Subscription;
  resetInvoiceSubscription          : Subscription;
  onDeleteRowClickedSubscription    : Subscription;
  onAddRowButtonSubscription        : Subscription | undefined;
  onNavTabKeyPressedSubscription    : Subscription;
  onplusIconClickSubscription       : Subscription;
  onImgCellClickedSubscription      : Subscription;
  
  constructor(
    public  metalSectionService     : MetalSectionReadonlyService,
    public  stockJournalService     : StockJournalService,
    private agGridService           : AgGridService,
    public  renderer                : Renderer2,
    public  invoiceService          : InvoiceService,
    public  transatcionService      : TransactionService,
    public  storageService          : StorageService,
    public  productService          : ProductService,
    public  router                  : Router,
    public commonFilterService:CommonFilterService,
    public activeModal: NgbActiveModal,
    public transactionService: TransactionService,
      public loadDropdownDataService: LoadDropdownDataService,
    ){
      super(renderer);
      this.onTaxChangeSubscription          = this.storageService.onTaxChange           .subscribe((result) => {
        if(result != undefined)
          this.onTaxChangeResponse(result);
       });
       this.resetInvoiceSubscription        = this.storageService.resetInvoice          .subscribe((result) => {
        if(result != undefined)
        {
          this.resetInvoiceResponse(result);
       
      }
       });
  }

  loadVoucherTypeWiseData() {
    if(this.formModel?.voucherType == "Stock Journal")
      this.loadDropdownDataService.getInvoiceNo(this.formModel?.voucherType).pipe(catchError((err) => this.handleError(err))).subscribe((res: ResponseModel) => {
          if (res.isSuccess === true) {
            this.onTaxChangeResponse(res?.response?.taxAndMetalDetailsByVoucherType);
          } 
        });
  }

  deleteIconClick(event)
  {
    this.onDeleteRowClickedResponse(event);
  }

  editIconClick(event)
  {
    this.deselectAllRows();
   this.setRowsSelected(this.gridApiGoldTable,event.detail.data.data.rowId,event.detail.data.data.barcodeNo);
   this.setRowsSelected(this.gridApiSilverTable,event.detail.data.data.rowId,event.detail.data.data.barcodeNo);
   this.setRowsSelected(this.gridApiDiamondTable,event.detail.data.data.rowId,event.detail.data.data.barcodeNo);
   this.setRowsSelected(this.gridApiStoneGEMSTable,event.detail.data.data.rowId,event.detail.data.data.barcodeNo);
   this.setRowsSelected(this.gridApiImmitationAndWatchesTable,event.detail.data.data.rowId,event.detail.data.data.barcodeNo);

   var params = event.detail.data.colDef.storageName;
   //var params = params
   var indexGold = params.indexOf("GOLD");
    var indexSilver = params.indexOf("SILVER");
    var indexDiamond = params.indexOf("DIAMOND");
    var indexStone = params.indexOf("STONE");
    var indexImitation = params.indexOf("IMITATION");

    var indexOfCurrentTabItem : any = [];
    if(indexGold != -1)
      indexOfCurrentTabItem = MetalType.Gold;
    if(indexSilver != -1)
      indexOfCurrentTabItem = MetalType.Silver;
    if(indexDiamond != -1)
      indexOfCurrentTabItem = MetalType.Diamond;
    if(indexStone != -1)
      indexOfCurrentTabItem = MetalType.StoneGEMS;
    if(indexImitation != -1)
      indexOfCurrentTabItem = MetalType.Imitation; 

     this.setSelectedTab(indexOfCurrentTabItem); 
   this.onEditIconClick.emit(event);
  }

  setRowsSelected(api,rowId,barcodeNo)
  {
    if(rowId !== undefined && rowId !== null)
    api?.api?.forEachNode((node) => {
      if (node.data.rowId === rowId) {
        node.setSelected(true);
      }
    });
    if(barcodeNo !== undefined && barcodeNo !== null)
    api?.api?.forEachNode((node) => {
      if (node.data.barcodeNo === barcodeNo) {
        node.setSelected(true);
      }
    });
  }
  

  onDeleteRowClickedResponse(event) {
    if(this.router.url == '/transaction/stock-journal'){
      var event = event?.detail?.data
    }else{
      var event = event.data.rowId;
    }
    var deletedRowId = event.data.rowId;
    var deletedBarcodeId = event.data.barcodeNo;
    var allMetals =  this.getAllMetals();
    if(deletedRowId != undefined)
    {
      allMetals.goldItem = allMetals.goldItem.filter(a=>a.rowId != deletedRowId);
      allMetals.silverItem = allMetals.silverItem.filter(a=>a.rowId != deletedRowId);
      allMetals.diamondItem = allMetals.diamondItem.filter(a=>a.rowId != deletedRowId);
      allMetals.stoneGemsItem = allMetals.stoneGemsItem.filter(a=>a.rowId != deletedRowId);
      allMetals.imitationItem = allMetals.imitationItem.filter(a=>a.rowId != deletedRowId);
    }
    else
    {
      allMetals.goldItem = allMetals.goldItem.filter(a=>a.barcodeNo != deletedBarcodeId);
      allMetals.silverItem = allMetals.silverItem.filter(a=>a.barcodeNo != deletedBarcodeId);
      allMetals.diamondItem = allMetals.diamondItem.filter(a=>a.barcodeNo != deletedBarcodeId);
      allMetals.stoneGemsItem = allMetals.stoneGemsItem.filter(a=>a.barcodeNo != deletedBarcodeId);
      allMetals.imitationItem = allMetals.imitationItem.filter(a=>a.barcodeNo != deletedBarcodeId);
    }
    this.setAllMetals(allMetals);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.initialCalls();
  }

  initialCalls(){
    this.processDataForPopup()
    this.loadVoucherTypeWiseData();
    this.isLoading = true;
    this.tabGroupList = [
      {tabId  :MetalTabs.Gold,                  dataModel:  this.metalSectionModel?.agGridDataModelGold,                     label: 'GoldItem',      tabIndex: 0 },
      {tabId  :MetalTabs.Silver,                dataModel:  this.metalSectionModel?.agGridDataModelSilver,                   label: 'SilverItem',    tabIndex: 1 },
      {tabId  :MetalTabs.Diamond,               dataModel:  this.metalSectionModel?.agGridDataModelDiamond,                  label: 'DiamondItem',   tabIndex: 2 },
      {tabId  :MetalTabs.StoneGems,             dataModel:  this.metalSectionModel?.agGridDataModelStoneGEMS,                label: 'StoneItem',     tabIndex: 3 },
      {tabId  :MetalTabs.ImmitationAndWatches,  dataModel:  this.metalSectionModel?.agGridDataModelImmitationAndWatches,     label: 'ImitationItem', tabIndex: 4 },
    ];
    this.selectTab(1);
  }
  processDataForPopup(){
    if(this.data){
      this.gridDynamicHeightClass =  'stock_journal_grid_height'
      this.metalSectionModel =  this.data?.metalSectionModel;
     let metalList = this.storageService.retrieve(StorageName.METAL_DROPDOWN)?.map(item => ({ ...item, id: parseInt(item.id) }))?.sort((a, b) => a.id - b.id) ?? [];
     this.tabItems = metalList.filter(item => item.id !== MetalType.Platinum && item.id !== MetalType.Other);
      this.getProductDataByBarcodeNo(this.data?.barcodeNo)
    }
  } 
  onGridReady(item, gridApi) {
    switch (item) {
      case 0: this.gridApiGoldTable                 = gridApi;  break;
      // this.agGridService.refreshColumnValues(this.gridApiGoldTable,this.metalSectionModel.agGridDataModelGold); break;
      case 1: this.gridApiSilverTable               = gridApi;  break;
      // this.agGridService.refreshColumnValues(this.gridApiSilverTable, this.metalSectionModel.agGridDataModelSilver);break;
      case 2: this.gridApiDiamondTable              = gridApi;  break;
      // this.agGridService.refreshColumnValues(this.gridApiDiamondTable,this.metalSectionModel.agGridDataModelDiamond);break;
      case 3: this.gridApiStoneGEMSTable            = gridApi;  break;
      // this.agGridService.refreshColumnValues(this.gridApiStoneGEMSTable,this.metalSectionModel.agGridDataModelStoneGEMS);break;
      case 4: this.gridApiImmitationAndWatchesTable = gridApi;  break;
      // this.agGridService.refreshColumnValues(this.gridApiImmitationAndWatchesTable,this.metalSectionModel.agGridDataModelImmitationAndWatches);break;
    }
  }

  selectTab(tabId: number) {
    this.tabId = tabId;
    this.getTabId.emit(tabId);
    this.invoiceService.TabId.next(this.tabId);
  }

  async onTaxChangeResponse(voucherWiseData)
  {
    this.voucherWiseData = voucherWiseData;
    if(voucherWiseData != undefined)
      this.tabItems = voucherWiseData?.allMetalList??[];
  }

  addMultipleItems(element) {
    element.forEach(event => {
      if(event != undefined)
      {
        const records=JSON.parse(event.detailedJson);
        records.goldItem?.forEach(item => {
          this.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a=>a.taxId==element.id);
            if(tax.length > 0)
            {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.silverItem?.forEach(item => {
          this.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a=>a.taxId==element.id);
            if(tax.length > 0)
            {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.diamondItem?.forEach(item => {
          this.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a=>a.taxId==element.id);
            if(tax.length > 0)
            {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.stoneGemsItem?.forEach(item => {
          this.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a=>a.taxId==element.id);
            if(tax.length > 0)
            {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.imitationItem?.forEach(item => {
          this.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a=>a.taxId==element.id);
            if(tax.length > 0)
            {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        this.agGridService.addNewRowsWithData(this.gridApiGoldTable,records.goldItem);
        this.agGridService.addNewRowsWithData(this.gridApiSilverTable,records.silverItem);
        this.agGridService.addNewRowsWithData(this.gridApiDiamondTable,records.diamondItem);
        this.agGridService.addNewRowsWithData(this.gridApiStoneGEMSTable,records.stoneGemsItem);
        this.agGridService.addNewRowsWithData(this.gridApiImmitationAndWatchesTable,records.imitationItem);
      }
    });
  }

  resetInvoiceResponse(formName){
    if(formName != undefined)
    {
      this.clearAllMetals();
      this.formModel = {};
      this.formModel.invoiceModel = {};
      this.formModel.invoiceModel.internalFix = false;
      this.formModel.invoiceModel.metalFix = false;
    }
  }

  selectTabAccordingBarcode(records){
    switch (true) {
      case records.goldItem       != undefined && records.goldItem       .length>0: 
        this.selectNavTab(1)
        break;
      case records.silverItem     != undefined && records.silverItem     .length>0:  
        this.selectNavTab(2)
        break;
      case records.diamondItem    != undefined && records.diamondItem    .length>0: 
        this.selectNavTab(4)
        break;
      case records.stoneGemsItem  != undefined && records.stoneGemsItem  .length>0: 
        this.selectNavTab(5)
        break;
      case records.imitationItem  != undefined && records.imitationItem  .length>0: 
        this.selectNavTab(6)
        break;
    }
    this.setTabItemColors(records);
  }

  selectNavTab(tab)
  {
    if(this.navTabsComponent == undefined)
      this.selectTab(tab);
    else
      this.navTabsComponent.selectTab(tab)
  }
  //#endregion

  getAllMetals() : any
  {
    this.getSaleInvoiceItemsDetailsModel.goldItem         = this.gridApiGoldTable                   ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.silverItem       = this.gridApiSilverTable                 ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.diamondItem      = this.gridApiDiamondTable                ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.stoneGemsItem    = this.gridApiStoneGEMSTable              ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.imitationItem    = this.gridApiImmitationAndWatchesTable   ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllSelectedMetals():any{
    this.getSaleInvoiceItemsDetailsModel.goldItem         = this.gridApiGoldTable                   ?.api?.getSelectedRows();
    this.getSaleInvoiceItemsDetailsModel.silverItem       = this.gridApiSilverTable                 ?.api?.getSelectedRows();
    this.getSaleInvoiceItemsDetailsModel.diamondItem      = this.gridApiDiamondTable                ?.api?.getSelectedRows();
    this.getSaleInvoiceItemsDetailsModel.stoneGemsItem    = this.gridApiStoneGEMSTable              ?.api?.getSelectedRows();
    this.getSaleInvoiceItemsDetailsModel.imitationItem    = this.gridApiImmitationAndWatchesTable   ?.api?.getSelectedRows();
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllActiveMetals():any{
    this.getSaleInvoiceItemsDetailsModel.goldItem         = this.gridApiGoldTable                   ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);
    this.getSaleInvoiceItemsDetailsModel.silverItem       = this.gridApiSilverTable                 ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);
    this.getSaleInvoiceItemsDetailsModel.diamondItem      = this.gridApiDiamondTable                ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);
    this.getSaleInvoiceItemsDetailsModel.stoneGemsItem    = this.gridApiStoneGEMSTable              ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);
    this.getSaleInvoiceItemsDetailsModel.imitationItem    = this.gridApiImmitationAndWatchesTable   ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllMetalWithRowId(rowId,barcodeNo):any{
    if(rowId != undefined)
    {
        this.getSaleInvoiceItemsDetailsModel.goldItem         = this.gridApiGoldTable                    ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);
        this.getSaleInvoiceItemsDetailsModel.silverItem       = this.gridApiSilverTable                  ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);
        this.getSaleInvoiceItemsDetailsModel.diamondItem      = this.gridApiDiamondTable                 ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);
        this.getSaleInvoiceItemsDetailsModel.stoneGemsItem    = this.gridApiStoneGEMSTable               ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);
        this.getSaleInvoiceItemsDetailsModel.imitationItem    = this.gridApiImmitationAndWatchesTable    ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);
    }
    else
    {
        this.getSaleInvoiceItemsDetailsModel.goldItem         = this.gridApiGoldTable                    ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.barcodeNo == barcodeNo);
        this.getSaleInvoiceItemsDetailsModel.silverItem       = this.gridApiSilverTable                  ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.barcodeNo == barcodeNo);
        this.getSaleInvoiceItemsDetailsModel.diamondItem      = this.gridApiDiamondTable                 ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.barcodeNo == barcodeNo);
        this.getSaleInvoiceItemsDetailsModel.stoneGemsItem    = this.gridApiStoneGEMSTable               ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.barcodeNo == barcodeNo);
        this.getSaleInvoiceItemsDetailsModel.imitationItem    = this.gridApiImmitationAndWatchesTable    ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.barcodeNo == barcodeNo);
    }
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllGrid():any{
    this.gridApi.gridApiGoldTable                   = this.gridApiGoldTable                 
    this.gridApi.gridApiSilverTable                 = this.gridApiSilverTable                 
    this.gridApi.gridApiDiamondTable                = this.gridApiDiamondTable              
    this.gridApi.gridApiStoneGEMSTable              = this.gridApiStoneGEMSTable           
    this.gridApi.gridApiImmitationAndWatchesTable   = this.gridApiImmitationAndWatchesTable 
    return this.gridApi;
  }

  setAllMetals(records)
  {
    this.gridApiGoldTable                   ?.api?.setRowData(records.goldItem);
    this.gridApiSilverTable                 ?.api?.setRowData(records.silverItem);
    this.gridApiDiamondTable                ?.api?.setRowData(records.diamondItem);
    this.gridApiStoneGEMSTable              ?.api?.setRowData(records.stoneGemsItem);
    this.gridApiImmitationAndWatchesTable   ?.api?.setRowData(records.imitationItem);
    this.selectTabAccordingBarcode(records)
    this.setFooterCalcAndDisplay();
  }

  addAllMetals(records,rowId,setSelected = false,removeAllRows = false ,invoiceItemImageResources)
  {
    const goldItem = records.goldItem?.map((item, index) => ({ ...item, rowId: rowId ,invoiceItemImageResources:invoiceItemImageResources, active : false }));
    const silverItem = records.silverItem?.map((item, index) => ({ ...item, rowId: rowId,invoiceItemImageResources:invoiceItemImageResources, active : false }));
    const diamondItem = records.diamondItem?.map((item, index) => ({ ...item, rowId: rowId ,invoiceItemImageResources:invoiceItemImageResources, active : false}));
    const imitationItem = records.imitationItem?.map((item, index) => ({ ...item, rowId: rowId, invoiceItemImageResources:invoiceItemImageResources,active : false }));

    if(removeAllRows == true)
    {
    // Get all nodes
    const selectedGoldRows = this.gridApiGoldTable?.api?.getSelectedRows();
    const selectedSilverRows = this.gridApiSilverTable?.api?.getSelectedRows();
    const selecteDiamondRows = this.gridApiDiamondTable?.api?.getSelectedRows();
    const selectedImmitationAndWatchesRows = this.gridApiImmitationAndWatchesTable?.api?.getSelectedRows();

    // Remove all selected rows
    this.gridApiGoldTable?.api?.applyTransaction({ remove: selectedGoldRows });
    this.gridApiSilverTable?.api?.applyTransaction({ remove: selectedSilverRows });
    this.gridApiDiamondTable?.api?.applyTransaction({ remove: selecteDiamondRows });
    this.gridApiImmitationAndWatchesTable?.api?.applyTransaction({ remove: selectedImmitationAndWatchesRows });
    }

    this.agGridService.addNewRowsWithData(this.gridApiGoldTable,goldItem,setSelected);
    this.agGridService.addNewRowsWithData(this.gridApiSilverTable,silverItem,setSelected);
    this.agGridService.addNewRowsWithData(this.gridApiDiamondTable,diamondItem,setSelected);
    this.agGridService.addNewRowsWithData(this.gridApiImmitationAndWatchesTable,imitationItem,setSelected);
    this.gridApiGoldTable?.api?.refreshCells();


    this.selectTabAccordingBarcode(records);
    this.setFooterCalcAndDisplay();
  }

  maxRowId() : any {
    var arrayOfIndividualMax = [];
    var data =  this.getAllMetals();
    var indexFromGold = data.goldItem.map(a=> {
      return a.rowId == undefined ? 0 :  a.rowId 
    });
    const maxRowIdGold = indexFromGold.length == 0 ? 0 : Math.max(... indexFromGold);
    arrayOfIndividualMax.push(maxRowIdGold);

    var indexFromSilver = data.silverItem.map(a=> {
      return a.rowId == undefined ? 0 :  a.rowId 
    });
    const maxRowIdSilver = indexFromSilver.length == 0 ? 0 :  Math.max(... indexFromSilver);
    arrayOfIndividualMax.push(maxRowIdSilver);

    var indexFromDiamond = data.diamondItem.map(a=> {
      return a.rowId == undefined ? 0 :  a.rowId 
    });
    const maxRowIdDiamond = indexFromDiamond.length == 0 ? 0 : Math.max(... indexFromDiamond);
    arrayOfIndividualMax.push(maxRowIdDiamond);

    var indexFromImitation = data.imitationItem.map(a=> {
      return a.rowId == undefined ? 0 :  a.rowId 
    });
    const maxRowIdImitation = indexFromImitation.length == 0 ? 0 :  Math.max(... indexFromImitation);
    arrayOfIndividualMax.push(maxRowIdImitation);
  
    const rowIndex = Math.max(... arrayOfIndividualMax);
    return (rowIndex ?? 0) + 1;
  }

  clearAllMetals()
  {
    this.gridApiGoldTable                   ?.api?.setRowData([]);
    this.gridApiSilverTable                 ?.api?.setRowData([]);
    this.gridApiDiamondTable                ?.api?.setRowData([]);
    this.gridApiStoneGEMSTable              ?.api?.setRowData([]);
    this.gridApiImmitationAndWatchesTable   ?.api?.setRowData([]);
    this.setFooterCalcAndDisplay();
  }

  setFooterCalcAndDisplay() {
    if(this.metalSectionModel.isGridFooter == true)
    {
      this.addFooterAndCalculation(this.gridApiGoldTable);
      this.addFooterAndCalculation(this.gridApiSilverTable);
      this.addFooterAndCalculation(this.gridApiDiamondTable);
      this.addFooterAndCalculation(this.gridApiStoneGEMSTable);
      this.addFooterAndCalculation(this.gridApiImmitationAndWatchesTable);
    }
  }
  
  addFooterAndCalculation(params){
    if(this.metalSectionModel?.isGridFooter){
      const rowsdata =  params?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
      const sumOfQuantity = (rowsdata?.map(a => Number(a?.quantity))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfGrossWt = (rowsdata?.map(a => Number(a?.grossWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
      const sumOfNetWt: string = (rowsdata?.map(a => Number(a?.netWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
      const sumOfFinalWt: string = (rowsdata?.map(a => Number(a?.finalWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
      const sumOfAmount: string = (rowsdata?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfNetAmount: string = (rowsdata?.map(a => Number(a?.netAmount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
  
      const totalsObject = {
        quantity    : sumOfQuantity,
        grossWt     : sumOfGrossWt,
        netWt       : sumOfNetWt,
        finalWt     : sumOfFinalWt,
        amount      : sumOfAmount,
        netAmount   : sumOfNetAmount,
        isFooterRow : true
        }
        params?.api?.setPinnedBottomRowData([totalsObject]);
        params?.api?.refreshCells();
    }
  }

  itemPresentInMetalList() : boolean
  {
    var getSaleInvoiceItemsDetailsModel : any = {};
    getSaleInvoiceItemsDetailsModel.goldItem         = this.gridApiGoldTable                   ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.silverItem       = this.gridApiSilverTable                 ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.diamondItem      = this.gridApiDiamondTable                ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.stoneGemsItem    = this.gridApiStoneGEMSTable              ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.imitationItem    = this.gridApiImmitationAndWatchesTable   ?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);

    if(   getSaleInvoiceItemsDetailsModel.goldItem      ?.length > 0
      ||  getSaleInvoiceItemsDetailsModel.silverItem    ?.length > 0
      ||  getSaleInvoiceItemsDetailsModel.diamondItem   ?.length > 0
      ||  getSaleInvoiceItemsDetailsModel.stoneGemsItem ?.length > 0
      ||  getSaleInvoiceItemsDetailsModel.imitationItem ?.length > 0
      )
        return true;
      else
        return false;
  }

  setTabItemColors(records)
  {
    this.tabItems = this.tabItems.map(item => {
      if ((item.id === 1 && records?.goldItem      ?.length > 0)  ||
          (item.id === 2 && records?.silverItem    ?.length > 0)  ||
          (item.id === 4 && records?.diamondItem   ?.length > 0)  ||
          (item.id === 5 && records?.stoneGemsItem ?.length > 0)  ||
          (item.id === 6 && records?.imitationItem ?.length > 0)
      ) 
            return { ...item, color: "lightgray" };
      else  return { ...item, color: "white" };
    });
  }

  getSelectedTab() : any
  {
    return this.tabId;
  }

  setSelectedTab(TabId) : any
  {
    this.selectNavTab(TabId);
  }

  deselectAllRows()
  {
    this.gridApiGoldTable                     ?.api?.deselectAll();
    this.gridApiSilverTable                   ?.api?.deselectAll();
    this.gridApiDiamondTable                  ?.api?.deselectAll();
    this.gridApiStoneGEMSTable                ?.api?.deselectAll();
    this.gridApiImmitationAndWatchesTable     ?.api?.deselectAll();
  }

  handleError(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
    this.onTaxChangeSubscription        .unsubscribe();
    this.resetInvoiceSubscription       .unsubscribe();
  }

  onPagination(pagination: PaginationModel) {
    this.isRowClicked=false;
    this.storeChangedPagination(pagination);
    //this.getLedgerTransactionsList();
  }

  storeChangedPagination(paginationModel: PaginationModel) {
   // this.pagination = this.commonFilterService.storeChangedPagination(paginationModel, this.pagination,this.paginationStorageName,this.apiRequestStorageName);
  } 
  getProductDataByBarcodeNo(barcodeNo) {
    setTimeout(() => {
      let records: any;
      if (barcodeNo) {
        this.transactionService.getPrductByBarcodeNo(barcodeNo,undefined).subscribe({
          next: (res) => {
            if (res?.response?.detailedJson != '{"goldItem":[],"silverItem":[],"diamondItem":[],"stoneGemsItem":[],"imitationItem":[]}') {
              records = JSON.parse(res?.response?.detailedJson);
              this.gridApiGoldTable?.api?.setRowData(records.goldItem);
              this.gridApiSilverTable?.api?.setRowData(records.silverItem);
              this.gridApiDiamondTable?.api?.setRowData(records.diamondItem);
              this.gridApiImmitationAndWatchesTable?.api?.setRowData(records.imitationItem);
              this.visibleColumn(["action", "active"])
            }
             records = JSON.parse(res?.response?.detailedJson);
            this.selectTabAccordingBarcode(records)
          }, error: (err) => { }
        })
      }
      const navTabElement = document.querySelector('.nav-tabs') as any;
      navTabElement?.firstElementChild?.firstElementChild?.focus();
    }, 1000);
  }
  visibleColumn(columnName: string[]) {
    const columnNames = columnName;
    const gridApis = [
      this.gridApiGoldTable,
      this.gridApiSilverTable,
      this.gridApiDiamondTable,
      this.gridApiImmitationAndWatchesTable
    ];
  
    gridApis.forEach(gridApi => {
      if (gridApi?.columnApi) {
        columnNames.forEach(element => {
          gridApi.columnApi.setColumnVisible(element, false);
        });
  
        const columnState = gridApi.columnApi.getColumnState();
        const hiddenColumnsDetails = columnState
          .filter(col => columnNames?.includes(col?.colId) && col?.hide)
          .map(col => ({ colId: col?.colId, headerName: col?.headerName }));
  
        this.agGridService.hiddenColumnsdisableSubject.next(hiddenColumnsDetails);
      }
    });
  }
  
}