<app-load-dropdown-data [formName]="storageName.FORMNAME_TRANSACTION_SALESINVOICE" (fillDropdownData)="fillDropdownData()"></app-load-dropdown-data>
<!-- <app-load-dropdown-data [formName]="storageName.FORMNAME_PRODUCT_LEDGEROPENING" (fillDropdownData)="fillDropdownData()"></app-load-dropdown-data> -->


<div class="modal-header d-flex align-items-center justify-content-center position-relative" *ngIf="hide_header">
  <div><span class="font-semibold text-primary">{{ data?.title??'Customer Details' }}</span></div>
  <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
  <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="close()"></app-svg-icon>
</div>
<form autocomplete="off" class="theme-form" ngNativeValidate #AddCustomerDetailsForm="ngForm">
  <div class="modal-body " [ngClass]="!hide_section?'mx-2':'mx-0 me-3'">
    <ng-container *ngIf="isCameraOn"> 
      <div class="d-flex justify-content-center position-absolute top-50 start-50 translate-middle Camera-div">
        <div *ngIf="!capturedImage" class="d-flex flex-column align-items-center">
          <video id="video" autoplay muted></video>
          <div (click)="stopCamera()" class="cross-div cursorPointer d-flex position-absolute end-0 top-0 bg-light p-2 rounded-circle m-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 17.999 18.002">
              <path id="Union_4" data-name="Union 4" d="M-1051-8106l-8,8,8-8-8-8,8,8,8-8-8,8,8,8Z" transform="translate(1060 8115)" fill="none" stroke="#7c89a4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </svg>
          </div>
          <div class="w-100 text-center py-2 bg-light" *ngIf="stream">
            <svg (click)="capturePhoto()" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 50.484 50.484">
              <g id="Group_40884" data-name="Group 40884" transform="translate(-934.758 -838.758)">
                <path id="Ellipse_522" data-name="Ellipse 522" d="M25.242,1.87a23.379,23.379,0,0,0-9.1,44.909A23.379,23.379,0,0,0,34.339,3.706a23.224,23.224,0,0,0-9.1-1.836m0-1.87A25.242,25.242,0,1,1,0,25.242,25.242,25.242,0,0,1,25.242,0Z" transform="translate(934.758 838.758)" fill="#7c89a4"/>
                <circle id="Ellipse_523" data-name="Ellipse 523" cx="18" cy="18" r="18" transform="translate(942 846)" fill="#7c89a4"/>
              </g>
            </svg>
          </div>
        </div>
        <div  class="d-flex flex-column align-items-center" *ngIf="capturedImage">
          <img width="auto" height="300px" [src]="capturedImage" alt="Captured Photo">
          <div (click)="stopCamera()" class="cross-div cursorPointer d-flex position-absolute end-0 top-0 bg-light p-2 rounded-circle m-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 17.999 18.002">
              <path id="Union_4" data-name="Union 4" d="M-1051-8106l-8,8,8-8-8-8,8,8,8-8-8,8,8,8Z" transform="translate(1060 8115)" fill="none" stroke="#7c89a4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class=" h-100" [ngClass]="!hide_section?'col':'col-8 center-border'">
            <div class="row" *ngIf="hide_section">
              <div class="col-12 py-2 ">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <h5 class="text-primary font-semibold f-16 mb-0">
                      Customer Details
                    </h5>
                    <div class="d-flex">
                      <app-button class="my-0 me-3" (buttonOutputEvent)="clear()" [buttonLabel]="'Clear'" [buttonClass]="'light_button'" 
                        [buttonType]="'button'">
                        <!-- activeModal.close(); -->
                      </app-button>
                      <app-button 
                      [ngbTooltip]="sendDataService?.noPermissionTooltip"
                      [disableTooltip]="(data?.accountLedgerId ? accountLedgerCanUpdate : accountLedgerCanAdd)"
                      [disabled]="!(data?.accountLedgerId ? accountLedgerCanUpdate : accountLedgerCanAdd)"
                      class="m-0" (buttonOutputEvent)="addOrEditCustomerDetails()" [buttonLabel]="'Save'" [buttonClass]="'blue_button'"
                      [buttonType]="'submit'">
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <div class="row">
                  <div class="col-4">
                    <div class="img-upload-section">
                      <div class="img-profile">
                        <ng-container *ngIf="!customerDetails?.imageUrl">
                          <input id="customer-img" name="customer-img"  class="d-none">
                          <label for="customer-img" class="mb-0">
                            <app-svg-icon name="userimg"  [src]="isBase64Image(customerDetails?.imageUrl)" ></app-svg-icon>
                          </label>
                        </ng-container>
                        <img draggable="false" *ngIf="customerDetails?.imageUrl" [src]="isBase64Image(customerDetails?.imageUrl)" alt="">
                      </div>
                      <div class="topright">
                        <app-svg-icon name="camera-circle" (click)="openAddImagePopup()"></app-svg-icon>
                        <!-- <webcam class="" [width]="170" [height]="170" *ngIf="stream" [trigger]="$trigger"
                          (imageCapture)="snapshot($event)" [imageQuality]="1"></webcam> -->
                      </div>
                      <!-- <div class="cross_icon" >
                        <app-svg-icon name="cross" (click)="remove($event)"
                        class="cursorPointer mt-1"></app-svg-icon>
                      </div> -->
                      <ng-container *ngIf="customerDetails?.imageUrl">
                        <div class="my-2" [ngClass]="hide_section?'':'me-2'">
                          <h6 class="f-14 text-red font-semibold cursorPointer w-165px text-center" (click)="remove($event)">Remove Profile</h6>
                        </div>
                      </ng-container>
                    </div>

                  </div>
                  <div class="col-8 ps-4 pe-0">
                    <div class="row">
                      <div class="col-12">
                        <app-icon-with-input 
                          [placeHolderRequired]="true"
                          [inputName]="'customerName'" [inputType]="'text'" [isRequired]="true"
                          [inputId]="'customerName'" [inputValue]="customerDetails?.ledgerName"
                          [inputPlaceholder]="'Name'"
                          [buttonClass]="'w-36px'"
                          (inputValueEmit)="customerDetails.ledgerName = $event"
                          (keydown.enter)="onKeydown($event, 'accountGroupId')"
                          showNameIcon="true"></app-icon-with-input>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="d-flex justify-content-between">
                        <app-input-with-select-dropdown
                          class="w-100"
                          [buttonClass]="'w-36px'"
                          [inputName]="'customerMobileNo'" 
                          [inputMaxlength]="15"
                          [inputType]="'text'" 
                          [name]="'customerDialCode'"
                          [id]="'customerDialCode'"
                          [inputId]="'customerMobileNo'"
                          [inputValue]="customerDetails?.mobile" 
                          (inputValueEmit)="customerDetails.mobile = $event"
                          [selectedDialCodeValue]="customerDetails?.mobileNoDialCode"
                          (dialCodeValueChanged)="customerDetails.mobileNoDialCode = $event"
                          [isRequired]="customerDetails.mobile" 
                          [inputPlaceholder]="'Mobile No'"
                          (keydown.enter)="onKeydown($event, 'customerEmail')"
                          [showmobileIcon]="true"
                          ></app-input-with-select-dropdown>

                          <img class="cursorPointer" 
                          *ngIf="!isNewUser" 
                          [class.disabled]="!customerDetails?.mobile"
                          [src]="customerDetails?.isMobileBlock ? './assets/images/blocked.svg' : './assets/images/unblocked.svg'" 
                          alt=""
                          [style.pointer-events]="!customerDetails?.mobile ? '' : 'auto'"
                          width="30" height="30"
                          (click)="customerDetails?.mobile ? handleonBlockUnblockIconClick('mobile') : null"
                          [ngbTooltip]="customerDetails?.isMobileBlock ? 'UnBlocked' : 'Blocked'" 
                          [tooltipPlacement]="'top'">
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                        <app-icon-with-input [inputName]="'customerPanNo'" [isOnlyAlphabetsAndNumbers]="true"
                          [inputMaxlength]="20" [inputType]="'text'" [inputId]="'customerPanNo'"
                          (keydown.enter)="onKeydown($event, 'customerPhoneNo')"
                          [inputValue]="customerDetails?.pan" (inputValueEmit)="customerDetails.pan = $event"
                          [isRequired]="false" [inputPlaceholder]="'Identity No'" showpanIcon="true"></app-icon-with-input>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12">
                        <app-icon-with-input [inputName]="'customerAadharNo'" [inputMaxlength]="20"
                          [inputType]="'text'" [inputId]="'customerAadharNo'"
                          [inputValue]="customerDetails?.aadhaarCardNo"
                          (keydown.enter)="onKeydown($event, 'specialDate');specialDayDatepicker?.dp?.toggle()"
                          (inputValueEmit)="customerDetails.aadhaarCardNo = $event" [isRequired]="false"
                          [inputPlaceholder]="'National Id'" showadharIcon="true"></app-icon-with-input>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <app-icon-with-input [inputName]="'customerGSTNo'" 
                          [isOnlyAlphabetsAndNumbers]="true" [inputType]="'text'" [inputId]="'customerGSTNo'"
                          (keydown.enter)="onKeydown($event, 'DateOfBirth');dobDatePicker?.dp?.toggle()"
                          [inputValue]="customerDetails?.gstNo" (inputValueEmit)="customerDetails.gstNo = $event"
                          [isRequired]="false" [inputPlaceholder]="'Trade No'" showgstIcon="true"></app-icon-with-input> 
                      </div>
                    </div>

                    <div class="row mt-2 px-0">
                      <div class="col-12">
                        <app-select-with-icon [options]="getAllDropdownModel?.registrationTypeDropdown"
                          [selectedValue]="customerDetails?.registrationTypeId"
                          (valueChanged)="customerDetails.registrationTypeId =$event" [id]="'registrationTypeId'"
                          (keydown.enter)="onKeydown($event, 'ledgerTypeId')"
                          [name]="'registrationTypeDropdown'"
                          [isDisabled]="false" [isRequired]="false"
                          [selectPlaceholder]="'Reg. Type'" 
                         showregistrationIcon="true">
                        </app-select-with-icon>
                      </div>
                    </div>

                    <div class="row mt-2 px-0">
                      <div class="col-12">
                        <app-select-with-icon [options]="getAllDropdownModel?.countryDropdown"
                          [selectedValue]="customerDetails?.regionOfResidentId"
                          (valueChanged)="customerDetails.regionOfResidentId =$event" [id]="'countryId'"
                          (keydown.enter)="onKeydown($event, 'nationalityId')"
                          [name]="'country'" [isDisabled]="false" [isRequired]="false"
                          [selectPlaceholder]="'Country/Region of Residence'" showCountryIcon="true" [className]="''">
                      </app-select-with-icon>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-5 ps-4">

                <div class="row">
                  <div class="col-12">
                    <app-select-with-icon [options]="getAllDropdownModel?.accountGroupDropdown"
                      [selectedValue]="customerDetails?.accountGroupId"
                      (valueChanged)="customerDetails.accountGroupId =$event; selectCrOrDr()" [id]="'accountGroupId'"
                      (keydown.enter)="onKeydown($event, 'customerMobileNo')"
                      [name]="'accountGroup'" [isDisabled]="false"
                       [isRequired]="true"
                      [selectPlaceholder]="''" showGroupIcon="true" [className]="'w-36px'">
                    </app-select-with-icon>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <div class="d-flex justify-content-between">
                      <app-icon-with-input [inputName]="'customerEmail'" [inputType]="'email'" [inputMaxlength]="50"
                      [inputId]="'customerEmail'" [inputValue]="customerDetails?.email"
                      [isEmailPattern]="true"
                      (keydown.enter)="onKeydown($event, 'customerPanNo')"
                      (inputValueEmit)="customerDetails.email = $event"
                      [inputPlaceholder]="'Mail ID'" showmailIcon="true"></app-icon-with-input>

                      <img class="cursorPointer" 
                          *ngIf="!isNewUser" 
                          [class.disabled]="!customerDetails?.email"
                          [src]="customerDetails?.isEmailBlock ? './assets/images/blocked.svg' : './assets/images/unblocked.svg'" 
                          alt=""
                          [style.pointer-events]="!customerDetails?.email ? '' : 'auto'"
                          width="30" height="30"
                          (click)="customerDetails?.email ? handleonBlockUnblockIconClick('email') : null"
                          [ngbTooltip]="customerDetails?.isEmailBlock ? 'Unblocked' : 'Blocked'" 
                          [tooltipPlacement]="'top'">

               
                    </div>
                    <div *ngIf="((AddCustomerDetailsForm?.form?.controls?.customerEmail?.touched || AddCustomerDetailsForm?.form?.controls?.customerEmail?.dirty) && AddCustomerDetailsForm?.form?.controls?.customerEmail?.invalid)" class="text-normal-red font-regular f-11 position-absolute">Please enter a valid email address</div>
                    </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <app-icon-with-input [inputName]="'customerPhoneNo'" [inputMaxlength]="15" [isOnlyNumbers]="true"
                      [inputType]="'text'" [inputId]="'customerPhoneNo'" [inputValue]="customerDetails?.phone"
                      [inputId]="'customerPhoneNo'" [inputValue]="customerDetails?.phone"
                      (keydown.enter)="onKeydown($event, 'customerAadharNo')" [isAllowDecimal]="false"
                      (inputValueEmit)="customerDetails.phone = $event" [isRequired]="false"
                      [inputPlaceholder]="'Phone No'" showphoneIcon="true"></app-icon-with-input>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <app-icon-datepicker #specialDayDatepicker [inputId]="'specialDate'" showdayIcon="true" [formatMMddyyyy]="true" [inputPlaceholder]="'Special Day'"
                      [fromDateFromParent]="customerDetails?.specialDay" (selectedDates)="getSpecicalDayDates($event)"
                      [fromEdit]="true"
                      (keydown.enter)="onKeydown($event, 'customerGSTNo')"
                       [isRequired]="false" [datepickername]="'customerSpecialDay'">
                    </app-icon-datepicker>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <app-icon-datepicker #dobDatePicker showdateIcon="true" [formatMMddyyyy]="true"
                      [inputId]="'DateOfBirth'"
                      (keydown.enter)="onKeydown($event, 'registrationTypeId')"
                      [inputPlaceholder]="'Date Of Birth'" [fromDateFromParent]="customerDetails?.dateOfBirth"
                      (selectedDates)="getDobDates($event)" [fromEdit]="true" [isRequired]="false"
                      [datepickername]="'customerDobDay'">
                    </app-icon-datepicker>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <app-select-with-icon [options]="getAllDropdownModel?.ledgerTypeDropdown"
                      [selectedValue]="customerDetails?.ledgerTypeId"
                      (valueChanged)="customerDetails.ledgerTypeId =$event" [id]="'ledgerTypeId'"
                      (keydown.enter)="onKeydown($event, 'countryId')"
                      [name]="'ledgerType'" [isDisabled]="false" [isRequired]="false"
                      [selectPlaceholder]="'Ledger Type'" showSalesPersonIcon="true" [className]="''">
                    </app-select-with-icon>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <app-select-with-icon [options]="getAllDropdownModel?.countryDropdown"
                      [selectedValue]="customerDetails?.nationalityId"
                      (valueChanged)="customerDetails.nationalityId =$event" [id]="'nationalityId'"
                      (keydown.enter)="onKeydown($event, 'campaignGroupIds')"
                      [name]="'Nationality'" [isDisabled]="false" [isRequired]="false"
                      [selectPlaceholder]="'Nationality'" showNationalityIcon="true" [className]="''">
                    </app-select-with-icon>
                  </div>
                </div>

              </div>
              <div class="row mt-2 multi-custom-class"> 
                <div class=""> 
                  <app-ng-multi-select-dropdown
                    [isShowlable]="false" 
                    [isPlusIcon]="false"
                    [showcampaignGroupIcon]="true"
                    [name]="'campaignGroupIds'"  
                    [getAllDropdownModel]="getAllDropdownModel" 
                    [selectedIds]="campaignGroupIds"
                    [data]="getAllDropdownModel?.campaignGroupDropdown" 
                    [placeholder]="'Select Group'"
                    [id]="'campaignGroupIds'" 
                    (onSelectItem)="customerDetails.campaignGroupIds=$event">
                  </app-ng-multi-select-dropdown>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <!-- KYC and AML Checkboxes -->
              <div class="col-12 col-md-6 d-flex justify-content-center align-items-center ps-5 gap-4 px-0">
                <div class="d-flex align-items-center gap-2 ms-5">
                 <!--  -->
                </div>
              </div>
              <!-- Bill to Bill Section -->
              <div class="col-12 col-md-6 d-flex align-items-center justify-content-end gap-4">
                <div class="d-flex align-items-center gap-4">
                  <div class="border_right">
                    <app-checkbox 
                  [disabled]="!kycEnabled" 
                  [status]="customerDetails.isKYC"    
                  (change)="toggleKYC($event.target.checked)"
                  [class.cursorNotAllowed]="!kycEnabled" 
                  [class.cursorPointer]="kycEnabled"                  
                  class="cursorPointer w-39 h-18"
                  [isRequired]="false"
                  [lableName]="'KYC'">
                </app-checkbox>
                  </div>
                 <div class="border_right">
                  <app-checkbox 
                  [disabled]="false"
                  [status]="customerDetails.isAML"                   
                  (change)="toggleAML($event.target.checked)"
                  class="cursorPointer "
                  [isRequired]="false"
                  [lableName]="'AML'">
                </app-checkbox>
                 </div>
                </div>
                <label for="billtobill" class="f-14 font-semibold text-gray-color m-r-12 text-nowrap mb-0">Bill to Bill</label>
                
                <div class="d-flex align-items-center gap-2">
                  <input class="radio_Button  cursorPointer " type="radio" id="yes" name="billtobill" 
                    [value]="billtoBillenum.Yes"
                    [(ngModel)]="customerDetails.billByBill" />
                  <label for="yes" class="f-14 text-gray-color font-regular mb-0 cursorPointer">Yes</label>
            
                  <input class="radio_Button  cursorPointer " type="radio" id="no" name="billtobill" 
                    [value]="billtoBillenum.No"
                    [(ngModel)]="customerDetails.billByBill" />
                  <label for="no" class="f-14 text-gray-color font-regular mb-0 cursorPointer">No</label>
                </div>
              </div>
            </div>
            <div class="row border-bottom my-2 mx-0"></div>
            <div class="row pt-1">
              <div class="pe-0 col">
                <div class="row py-2"  [ngClass]="{'border-right': activeTabId !== 3 && activeTabId !== 4}">
                  <div class="col d-flex align-items-center border-bottom px-0 mx-3">
                    <ul 
                        ngbNav #nav="ngbNav" 
                        [(activeId)]="activeTabId"
                        class="customer_nav-tabs customerdetails_nav_tabs gap-1">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink class="d-flex me-2">
                            <span class="d-flex justify-content-center f-14 font-semibold">Billing Address</span>
                            </a>
                            <ng-template ngbNavContent>
                               <app-billing-address [customerDetails]="customerDetails" [getAllDropdownModel]="getAllDropdownModel"></app-billing-address>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink class="d-flex">
                            <span class="d-flex justify-content-center f-14 font-semibold">Shipping Address</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-shipping-address [customerDetails]="customerDetails" [getAllDropdownModel]="getAllDropdownModel"></app-shipping-address>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                          <a ngbNavLink class="d-flex">
                            <span class="d-flex justify-content-center f-14 font-semibold">Item Type Tax</span>
                          </a>
                          <ng-template ngbNavContent> 
                            <div class="row pe-4 ps-3 mt-2">
                              <div class="col-12 h-100 position-relative px-0">
                                <div>
                                  <app-ag-grid 
                                  id="customerDetailsGrid"
                                  [dataModel]="agGridDataModelMetalTypeTax" 
                                  [rowData]="customerDetails?.ledgerItemTypeTaxModel" 
                                  (gridReady)="onGridMetalTypeReady($event)"
                                  >
                                </app-ag-grid>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                      </li>
                      <li [ngbNavItem]="4">
                        <a ngbNavLink class="d-flex">
                          <span class="d-flex justify-content-center f-14 font-semibold">Share Holders</span>
                        </a>
                        <ng-template ngbNavContent> 
                          <div class="row pe-4 ps-3 mt-2">
                            <div class="col-12 h-100 position-relative px-0">
                              <div>
                                <app-ag-grid 
                                id="shareHoldersGrid"
                                [dataModel]="agGridDataModelshareHolder" 
                                [addBlankRowOnEnter]="true"
                                [columnSettings]="true"
                                (gridReady)="onGridShareHolderReady($event)"
                                (rowClicked)="onRowClicked($event)"
                                (onGridDestroy)="onGridDestroy()"
                                (cellValueChanged)="onCellValue($event)" 
                                (onDeleteIconClick)="onDeleteIconClick($event)"
                                >
                              </app-ag-grid>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>
                    </ul>
                  </div>
                  <div [ngbNavOutlet]="nav" class="flex-fill py-2">
                  </div>
                </div>
              </div>
              <div  *ngIf="activeTabId !== 3 && activeTabId !== 4" class="w-315px ps-4">
                <div class="row">
                  <div class="col-12">
                    <div class="col-12 py-2">
                      <h5 class="text-primary font-semibold f-16 mb-0">Bank Details</h5>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <app-icon-with-input [inputName]="'customerBankAccNo'" [inputMaxlength]="17" [isOnlyNumbers]="true"
                      [inputType]="'text'" [inputId]="'customerBankAccNo'"
                      [inputValue]="customerDetails?.bankAccountNumber"
                      [isAllowDecimal]="false"
                      (keydown.enter)="onKeydown($event, 'CustomerBankAccountName')"
                      (inputValueEmit)="customerDetails.bankAccountNumber = $event" [isRequired]="false"
                      [inputPlaceholder]="'Account No'" [InputLabel]="'Acc.No.'"
                      labelName="true" [className]="'w-90px'"></app-icon-with-input>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <app-icon-with-input [inputName]="'CustomerBankAccountName'" [isOnlyAlphabets]="true"
                      [inputType]="'text'" [inputId]="'CustomerBankAccountName'"
                      (keydown.enter)="onKeydown($event, 'customerBankIFSCCode')"
                      [inputValue]="customerDetails?.bankName" (inputValueEmit)="customerDetails.bankName = $event"
                      [isRequired]="false" [inputPlaceholder]="'Bank Name'" [InputLabel]="'Name'"
                      labelName="true" [className]="'w-90px'"></app-icon-with-input>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <app-icon-with-input [inputName]="'customerBankIFSCCode'" [inputMaxlength]="12"
                      [isOnlyAlphabetsAndNumbers]="true" [inputType]="'text'" [inputId]="'customerBankIFSCCode'"
                      (keydown.enter)="onKeydown($event, 'customerBankBranchName')"
                      [inputValue]="customerDetails?.ifscCode" (inputValueEmit)="customerDetails.ifscCode = $event"
                      [isRequired]="false" [inputPlaceholder]="'IFSC Code'" [InputLabel]="'IFSC Code'"
                      labelName="true" [className]="'w-90px'"></app-icon-with-input>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <app-icon-with-input [inputName]="'customerBankBranchName'" [isOnlyAlphabets]="true"
                      [inputType]="'text'" [inputId]="'customerBankBranchName'"
                      (keydown.enter)="onKeydown($event, 'openingBalance')"
                      [inputValue]="customerDetails?.branchName" (inputValueEmit)="customerDetails.branchName = $event"
                      [isRequired]="false" [inputPlaceholder]="'Branch Name'" [InputLabel]="'Branch'"
                      labelName="true" [className]="'w-90px'"></app-icon-with-input>
                  </div>
                </div>
              </div>
            </div>

            <div  *ngIf="activeTabId !== 3 && activeTabId !== 4" class="row my-2 border-top mx-0">
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col pt-2 " *ngIf="customerDetails?.resourcePropertyModel?.length > 0">
                    <div class="w-63"></div>
                    <div class="col d-flex align-items-center gap-2 mb-1">
                      <label for="files" class="btn label-upload px-3 py-1 m-0"><span class="pe-2"><svg class="cursorPointer" xmlns="http://www.w3.org/2000/svg" width="14.526" height="13.479" viewBox="0 0 14.526 13.479">
                        <g id="Group_32" data-name="Group 32" transform="translate(-1783.63 -892.761)">
                          <path id="Union_33" data-name="Union 33" d="M-6769.729-2550.3a2.438,2.438,0,0,1-2.327-2.528v-2.884a.653.653,0,0,1,.632-.668.651.651,0,0,1,.631.668v2.884a1.134,1.134,0,0,0,1.064,1.192h9.872a1.136,1.136,0,0,0,1.064-1.192v-2.978a.652.652,0,0,1,.631-.668.653.653,0,0,1,.632.668v2.978a2.437,2.437,0,0,1-2.327,2.528Zm4.374-4.473v-6.442l-1.509,1.6a.611.611,0,0,1-.452.2.6.6,0,0,1-.452-.2.706.706,0,0,1,0-.957l3.025-3.2,2.929,3.1a.7.7,0,0,1,.186.478.692.692,0,0,1-.186.479.62.62,0,0,1-.453.2.621.621,0,0,1-.455-.2l-1.349-1.428v6.378a.661.661,0,0,1-.643.675A.661.661,0,0,1-6765.354-2554.776Z" transform="translate(8555.686 3456.542)" fill="#2F2F3B"/>
                        </g>
                      </svg> </span> Upload Document</label>
                      <input id="files" type="file" class="py-1 invisible" (change)="previewImg == null ? onSelect($event,1) : ''" multiple>
                    </div>
                </div>
                </div>
                <div class="row">
                    <div class="col-3 py-2" [ngClass]="{'d-none': customerDetails?.resourcePropertyModel?.length > 0}">
                      <h5 class="text-primary font-semibold f-16 mb-0">Upload Document</h5>
                    </div>
                    <div class="col-1 w-40 p-0 m-0"></div>
                    <div class="col-4 p-0 m-0" *ngIf="customerDetails?.resourcePropertyModel?.length > 0">
                      <h5 class="text-primary font-semibold f-16 mb-0">Document Type</h5>
                    </div>
                    <div class="col-4 py-2" *ngIf="customerDetails?.resourcePropertyModel?.length > 0">
                      <h5 class="text-primary font-semibold f-16 mb-0">File Name</h5>
                    </div>
                    <div class="col-3 ps-0 py-2"  *ngIf="customerDetails?.resourcePropertyModel?.length > 0">
                      <h5 class="text-primary font-semibold f-16 mb-0">Expiry Date</h5>
                    </div>
                </div>
                <div class="row overflow-auto" [ngClass]="{'h-150px':customerDetails?.resourcePropertyModel?.length}">
                  <div 
                    [ngClass]="{'d-none': customerDetails?.resourcePropertyModel?.length > 0, 'col-12': customerDetails?.resourcePropertyModel?.length === 0}">
                    <ngx-dropzone [disableClick]="previewImg !=null" class="file_upload_section p-0" (change)="previewImg == null ?onSelect($event,2):''">
                      <ngx-dropzone-label *ngIf=" previewImg ==null ">
                        <div class="dz-message needsclick">
                          <svg xmlns="http://www.w3.org/2000/svg" width="57.5" height="45.11" viewBox="0 0 57.5 45.11">
                            <g id="Group_3283" data-name="Group 3283" transform="translate(-2256.384 -6410.034)">
                              <path id="Path_1944" data-name="Path 1944"
                                d="M2313.884,6438.555c-.112.5-.208,1.012-.339,1.511a12.338,12.338,0,0,1-9.186,9.324,11.1,11.1,0,0,1-2.71.367c-2.356.051-4.714.021-7.071.021-.126,0-.252-.017-.405-.027v-3.562h.606c2.133,0,4.265.012,6.4,0a8.956,8.956,0,0,0,1.908-17.733,2.449,2.449,0,0,1-2.2-2.088,16.115,16.115,0,0,0-31.044-1.291,29.138,29.138,0,0,0-.751,4.113c-.046.275-.023.561-.056.839a1.794,1.794,0,0,1-1.95,1.8,7.18,7.18,0,0,0,.24,14.358c2.731.028,5.462,0,8.193.005h.662v3.559c-.164.011-.326.03-.487.03-2.825,0-5.649.011-8.474,0a10.766,10.766,0,0,1-2.358-21.281.689.689,0,0,0,.648-.725,19.711,19.711,0,0,1,32.072-13.293,19.285,19.285,0,0,1,6.529,9.893,1.049,1.049,0,0,0,.78.809,12.318,12.318,0,0,1,8.888,10.423,3.019,3.019,0,0,0,.109.372Z"
                                fill="#4f39cd" />
                              <path id="Path_1945" data-name="Path 1945"
                                d="M2411.934,6588.158H2408.3v-17.07l-3.891,4.018-2.673-2.649c2.33-2.335,4.709-4.743,7.117-7.121a1.759,1.759,0,0,1,2.6.105c1.189,1.164,2.357,2.351,3.535,3.527l3.488,3.486-2.56,2.562-3.986-4.014Z"
                                transform="translate(-124.916 -133.013)" fill="#4f39cd" />
                            </g>
                          </svg>

                          <h6 class="f-16 font-regular text-secondary text-nowrap font-weight-unset">Drop files here or click to upload.</h6>
                        </div>
                      </ngx-dropzone-label>
                      <div class="position-relative justify-content-center d-flex" *ngIf=" previewImg!=null">
                        <img  [src]="previewImg" class="h-14" width="300px" alt="">
                        <span class="position-absolute p-2 top-0 end-0 " >
                          <svg class="cursorPointer" id="Group_4453" data-name="Group 4453" (click)= "remove($event)" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 60.139 60.135">
                            <path id="Path_2405" data-name="Path 2405" d="M321.848-1258.059a30.1,30.1,0,0,1,30.056,29.953,30,30,0,0,1-30.312,30.182,29.972,29.972,0,0,1-29.827-29.968A30.047,30.047,0,0,1,321.848-1258.059Z" transform="translate(-291.764 1258.059)" fill="#e11330"/>
                            <path id="Path_2406" data-name="Path 2406" d="M398.632-1154.222c.223.044.324.217.453.346q4.522,4.515,9.03,9.045c.368.372.552.432.959.023,3.022-3.033,6.079-6.03,9.109-9.054.335-.334.529-.392.873-.012a23.372,23.372,0,0,0,1.95,1.946c.451.394.331.6-.032.956-3.041,2.984-6.056,5.993-9.1,8.975-.359.353-.405.53-.022.912,3.044,3.039,6.057,6.11,9.1,9.156.323.324.368.5.009.829-.679.618-1.327,1.272-1.95,1.948-.31.336-.484.281-.782-.016q-4.5-4.489-9.032-8.953c-.594-.588-.593-.588-1.161-.02q-4.474,4.474-8.94,8.957c-.322.325-.5.37-.832.02-.646-.681-1.328-1.331-2.026-1.959-.325-.293-.324-.465-.012-.778,3.038-3.046,6.051-6.117,9.1-9.155.392-.392.382-.577-.006-.96-3.015-2.98-5.994-6-9.014-8.971-.416-.41-.363-.608.021-.961.692-.634,1.337-1.321,2-1.984C398.426-1154.035,398.534-1154.13,398.632-1154.222Z" transform="translate(-378.535 1171.613)" fill="#fff"/>
                          </svg>
                        </span>
                        <span class="position-absolute p-2 bottom-0 end-0 fullScreenBg" >
                          <svg (click)="openImgPopUp()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize cursorPointer">
                         <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                     </svg>
                        </span>
                      </div>
                    </ngx-dropzone>
                  </div>
                  <div *ngIf="customerDetails?.resourcePropertyModel?.length > 0" class="col d-flex flex-column gap-2">
                    <ng-container *ngFor="let resourceProperty of customerDetails?.resourcePropertyModel;let i = index">
                      <div class="row" *ngIf="!resourceProperty.deleted">
                        <div class="col-1 w-40 p-0 d-flex justify-content-center align-items-center">
                          <!-- <app-svg-icon *ngIf="!resourceProperty.isDeleteIcon" (click)="onRemove(f,i)" name="cross"
                            class="cursorPointer "></app-svg-icon> -->
                          <app-svg-icon name="delete_red" (click)="onRemove(resourceProperty,i)"
                            class="cursorPointer text-center"></app-svg-icon>
                        </div>
                        <div class="col-4 p-0 d-flex">
                          <div class="col-1 px-1 d-flex flex-column justify-content-center align-items-center">
                            <app-svg-icon  name="{{ resourceProperty?.mimeType}}"></app-svg-icon>
                          </div>
                          <span class="text-color-danger">*</span>
                          <div class="col px-2">
                                <app-select 
                                  [options]="getAllDropdownModel?.documentTypeDropdown" 
                                  [selectedValue]="resourceProperty?.documentTypeId"
                                  [id]="'documentId_'+resourceProperty?.documentTypeId" 
                                  [name]="'documentName_'+resourceProperty?.documentTypeId"
                                  (valueChanged)="resourceProperty.documentTypeId= $event; assignDocumentTypeId($event)"
                                  [isRequired]="true"
                                  [isDisabled]="false" >
                                </app-select>
                          </div>
                        </div>
                        <div class="col-4 d-flex align-items-center overflow-hidden">
                          <label for="file"
                            class="f-14 font-regular text-secondary mb-0 cursorPointer text-truncate" (click)="previewImages(resourceProperty)">{{resourceProperty.resourceTitle}}</label>
                        </div>
                        <div class="w-100px p-0" *ngIf="checkIsExpired(resourceProperty?.documentTypeId)">
                          <app-input-datepicker-renderer [inputDate]="resourceProperty?.expiryDate" [inputDatepickerName]="'expiryDate_'+resourceProperty?.documentTypeId" [id]="'expiryDate_'+resourceProperty?.documentTypeId" (dateChanged)="resourceProperty.expiryDate = $event"></app-input-datepicker-renderer>
                        </div>
                      </div>
                    </ng-container>
                   
                  </div>
                </div>
              </div>
            </div>

            <!-- ----  for shareHolder Resources start -->

            <div  *ngIf="activeTabId == 4" class="row my-2 border-top mx-0">
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col pt-2 " *ngIf="selectedRowNode?.data?.shareHolderResourcePropertyModel?.length > 0">
                    <div class="w-63"></div>
                    <div class="col d-flex align-items-center gap-2 mb-1">
                      <label for="files" class="btn label-upload px-3 py-1 m-0"><span class="pe-2"><svg class="cursorPointer" xmlns="http://www.w3.org/2000/svg" width="14.526" height="13.479" viewBox="0 0 14.526 13.479">
                        <g id="Group_32" data-name="Group 32" transform="translate(-1783.63 -892.761)">
                          <path id="Union_33" data-name="Union 33" d="M-6769.729-2550.3a2.438,2.438,0,0,1-2.327-2.528v-2.884a.653.653,0,0,1,.632-.668.651.651,0,0,1,.631.668v2.884a1.134,1.134,0,0,0,1.064,1.192h9.872a1.136,1.136,0,0,0,1.064-1.192v-2.978a.652.652,0,0,1,.631-.668.653.653,0,0,1,.632.668v2.978a2.437,2.437,0,0,1-2.327,2.528Zm4.374-4.473v-6.442l-1.509,1.6a.611.611,0,0,1-.452.2.6.6,0,0,1-.452-.2.706.706,0,0,1,0-.957l3.025-3.2,2.929,3.1a.7.7,0,0,1,.186.478.692.692,0,0,1-.186.479.62.62,0,0,1-.453.2.621.621,0,0,1-.455-.2l-1.349-1.428v6.378a.661.661,0,0,1-.643.675A.661.661,0,0,1-6765.354-2554.776Z" transform="translate(8555.686 3456.542)" fill="#2F2F3B"/>
                        </g>
                      </svg> </span> Upload Document</label>
                      <input id="files" type="file" class="py-1 invisible" (change)="previewImg == null ? onSelectResourcesShareHolder($event,1) : ''" multiple>
                    </div>
                </div>
                </div>
                <div class="row">
                    <div class="col-3 py-2" [ngClass]="{'d-none': selectedRowNode?.data?.shareHolderResourcePropertyModel?.length > 0}">
                      <h5 class="text-primary font-semibold f-16 mb-0">Upload Document</h5>
                    </div>
                    <div class="col-1 w-40 p-0 m-0"></div>
                    <div class="col-4 p-0 m-0" *ngIf="selectedRowNode?.data?.shareHolderResourcePropertyModel?.length > 0">
                      <h5 class="text-primary font-semibold f-16 mb-0">Document Type</h5>
                    </div>
                    <div class="col-4 py-2" *ngIf="selectedRowNode?.data?.shareHolderResourcePropertyModel?.length > 0">
                      <h5 class="text-primary font-semibold f-16 mb-0">File Name</h5>
                    </div>
                    <div class="col-3 ps-0 py-2"  *ngIf="selectedRowNode?.data?.shareHolderResourcePropertyModel?.length > 0">
                      <h5 class="text-primary font-semibold f-16 mb-0">Expiry Date</h5>
                    </div>
                </div>
                <div class="row overflow-auto" [ngClass]="{'h-150px':selectedRowNode?.data?.shareHolderResourcePropertyModel?.length}">
                  <div 
                    [ngClass]="{'d-none': selectedRowNode?.data?.shareHolderResourcePropertyModel?.length > 0, 'col-12': selectedRowNode?.data?.shareHolderResourcePropertyModel?.length === 0}">
                    <ngx-dropzone [disableClick]="previewImg !=null" class="file_upload_section p-0" (change)="previewImg == null ?onSelectResourcesShareHolder($event,2):''">
                      <ngx-dropzone-label *ngIf=" previewImg ==null ">
                        <div class="dz-message needsclick">
                          <svg xmlns="http://www.w3.org/2000/svg" width="57.5" height="45.11" viewBox="0 0 57.5 45.11">
                            <g id="Group_3283" data-name="Group 3283" transform="translate(-2256.384 -6410.034)">
                              <path id="Path_1944" data-name="Path 1944"
                                d="M2313.884,6438.555c-.112.5-.208,1.012-.339,1.511a12.338,12.338,0,0,1-9.186,9.324,11.1,11.1,0,0,1-2.71.367c-2.356.051-4.714.021-7.071.021-.126,0-.252-.017-.405-.027v-3.562h.606c2.133,0,4.265.012,6.4,0a8.956,8.956,0,0,0,1.908-17.733,2.449,2.449,0,0,1-2.2-2.088,16.115,16.115,0,0,0-31.044-1.291,29.138,29.138,0,0,0-.751,4.113c-.046.275-.023.561-.056.839a1.794,1.794,0,0,1-1.95,1.8,7.18,7.18,0,0,0,.24,14.358c2.731.028,5.462,0,8.193.005h.662v3.559c-.164.011-.326.03-.487.03-2.825,0-5.649.011-8.474,0a10.766,10.766,0,0,1-2.358-21.281.689.689,0,0,0,.648-.725,19.711,19.711,0,0,1,32.072-13.293,19.285,19.285,0,0,1,6.529,9.893,1.049,1.049,0,0,0,.78.809,12.318,12.318,0,0,1,8.888,10.423,3.019,3.019,0,0,0,.109.372Z"
                                fill="#4f39cd" />
                              <path id="Path_1945" data-name="Path 1945"
                                d="M2411.934,6588.158H2408.3v-17.07l-3.891,4.018-2.673-2.649c2.33-2.335,4.709-4.743,7.117-7.121a1.759,1.759,0,0,1,2.6.105c1.189,1.164,2.357,2.351,3.535,3.527l3.488,3.486-2.56,2.562-3.986-4.014Z"
                                transform="translate(-124.916 -133.013)" fill="#4f39cd" />
                            </g>
                          </svg>

                          <h6 class="f-16 font-regular text-secondary text-nowrap font-weight-unset">Drop files here or click to upload.</h6>
                        </div>
                      </ngx-dropzone-label>
                      <div class="position-relative justify-content-center d-flex" *ngIf=" previewImg!=null">
                        <img  [src]="previewImg" class="h-14" width="300px" alt="">
                        <span class="position-absolute p-2 top-0 end-0 " >
                          <svg class="cursorPointer" id="Group_4453" data-name="Group 4453" (click)= "remove($event)" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 60.139 60.135">
                            <path id="Path_2405" data-name="Path 2405" d="M321.848-1258.059a30.1,30.1,0,0,1,30.056,29.953,30,30,0,0,1-30.312,30.182,29.972,29.972,0,0,1-29.827-29.968A30.047,30.047,0,0,1,321.848-1258.059Z" transform="translate(-291.764 1258.059)" fill="#e11330"/>
                            <path id="Path_2406" data-name="Path 2406" d="M398.632-1154.222c.223.044.324.217.453.346q4.522,4.515,9.03,9.045c.368.372.552.432.959.023,3.022-3.033,6.079-6.03,9.109-9.054.335-.334.529-.392.873-.012a23.372,23.372,0,0,0,1.95,1.946c.451.394.331.6-.032.956-3.041,2.984-6.056,5.993-9.1,8.975-.359.353-.405.53-.022.912,3.044,3.039,6.057,6.11,9.1,9.156.323.324.368.5.009.829-.679.618-1.327,1.272-1.95,1.948-.31.336-.484.281-.782-.016q-4.5-4.489-9.032-8.953c-.594-.588-.593-.588-1.161-.02q-4.474,4.474-8.94,8.957c-.322.325-.5.37-.832.02-.646-.681-1.328-1.331-2.026-1.959-.325-.293-.324-.465-.012-.778,3.038-3.046,6.051-6.117,9.1-9.155.392-.392.382-.577-.006-.96-3.015-2.98-5.994-6-9.014-8.971-.416-.41-.363-.608.021-.961.692-.634,1.337-1.321,2-1.984C398.426-1154.035,398.534-1154.13,398.632-1154.222Z" transform="translate(-378.535 1171.613)" fill="#fff"/>
                          </svg>
                        </span>
                        <span class="position-absolute p-2 bottom-0 end-0 fullScreenBg" >
                          <svg (click)="openImgPopUp()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize cursorPointer">
                         <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                     </svg>
                        </span>
                      </div>
                    </ngx-dropzone>
                  </div>
                  <div *ngIf="selectedRowNode?.data?.shareHolderResourcePropertyModel?.length > 0" class="col d-flex flex-column gap-2">
                    <ng-container *ngFor="let resourceProperty of selectedRowNode?.data?.shareHolderResourcePropertyModel;let i = index">
                      <div class="row" *ngIf="!resourceProperty?.deleted">
                        <div class="col-1 w-40 p-0 d-flex justify-content-center align-items-center">
                          <app-svg-icon name="delete_red" (click)="onRemoveShareHolder(resourceProperty,i)"
                            class="cursorPointer text-center"></app-svg-icon>
                        </div>
                        <div class="col-4 p-0 d-flex">
                          <div class="col-1 px-1 d-flex flex-column justify-content-center align-items-center">
                            <app-svg-icon  name="{{ resourceProperty?.mimeType}}"></app-svg-icon>
                          </div>
                          <span class="text-color-danger">*</span>
                          <div class="col px-2">
                                <app-select 
                                  [options]="getAllDropdownModel?.documentTypeDropdown" 
                                  [selectedValue]="resourceProperty?.documentTypeId"
                                  [id]="'documentId_'+resourceProperty?.documentTypeId" 
                                  [name]="'documentName_'+resourceProperty?.documentTypeId"
                                  (valueChanged)="resourceProperty.documentTypeId= $event; assignDocumentTypeId($event ,resourceProperty)"
                                  [isRequired]="true"
                                  [isDisabled]="false" >
                                </app-select>
                          </div>
                        </div>
                        <div class="col-4 d-flex align-items-center overflow-hidden">
                          <label for="file"
                            class="f-14 font-regular text-secondary mb-0 cursorPointer text-truncate" (click)="previewImagesShareHolder(resourceProperty)">{{resourceProperty.resourceTitle}}</label>
                        </div>
                        <div class="w-100px p-0" *ngIf="checkIsExpired(resourceProperty?.documentTypeId)">
                          <app-input-datepicker-renderer [inputDate]="resourceProperty?.expiryDate" [inputDatepickerName]="'expiryDate_'+resourceProperty?.documentTypeId" [id]="'expiryDate_'+resourceProperty?.documentTypeId" (dateChanged)="resourceProperty.expiryDate = $event;shareHolderResourcesExpirydateChanged(resourceProperty)"></app-input-datepicker-renderer>
                        </div>
                      </div>
                    </ng-container>
                   
                  </div>
                </div>
              </div>
            </div>

            <!-- ----  for shareHolder Resources end -->
          </div>

          <div class="col-4" *ngIf="hide_section">
            <div class="row">
              <div class="col-12 py-2 ">
                <div class="row">
                  <div class="col-12 ">
                    <h5 class="text-primary font-semibold f-16 mb-0">
                      Opening
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-7 d-flex gap-2 align-items-center pe-0">
                <div class="">
                  <label for="category" class="f-14 font-semibold text-gray-color text-nowrap mb-0">Opening
                    Balance</label>
                </div>
                <div class="w-100">
                  <app-input [inputClass]="' bg-transperent text-end'" [inputName]="'openingBalance'" [inputType]="'text'"
                    [inputId]="'openingBalance'" [decimalPlaces]="2"
                    [inputValue]="customerDetails?.openingBalance"
                    (inputValueEmit)="customerDetails.openingBalance = convertToNum($event)"></app-input>
                </div>
              </div>
              <div class="col d-flex gap-2 align-items-center">
                  <div class="d-flex gap-2 align-items-center">
                    
                    <input
                      type="radio"
                      class=" cursorPointer radio_Button "
                      id="1"
                      name="fav_language"
                      [value]="transactionType.CR"
                      [(ngModel)]="customerDetails.crOrDr"
                    />
                    <label
                      for="1"
                      class="f-14 mb-0 text-gray-color font-semibold mb-0 cursorPointer "
                      >Credit</label
                    >
                  </div>

                  <div class="d-flex gap-2 align-items-center">
                    <input
                      type="radio"
                      class="  cursorPointer radio_Button "
                      id="2"
                      name="fav_language"
                      [value]="transactionType.DR"
                      [(ngModel)]="customerDetails.crOrDr"
                    />
                    <label
                      for="2"
                      class="f-14 mb-0 text-gray-color font-semibold mb-0 cursorPointer"
                      >Debit</label
                    >
                  </div>
              </div>
            </div>
            <hr>
            <div [ngClass]="{'invisible': !((customerDetails?.accountGroupId == this.accountGroupId?.SundryCreditor)||(customerDetails?.accountGroupId == this.accountGroupId?.SundryDebitor))}">
              <div class="row">
                <div class="col-12 ">
                  <h5 class="text-primary font-semibold f-16 mb-0">
                    Metal Wise Opening
                  </h5>
                </div>
              </div>
              <div class="row pe-0 ps-2 mt-2">
                <div class="col-12 h-100 position-relative px-0">
                  <div class="Metal_Wise_Opening_table">
                    <app-ag-grid 
                      id="MetalWiseOpening"
                      [dataModel]="agGridDataModelMetalWiseOpening" 
                      [rowData]="customerDetails?.openingBalanceModel" 
                      [gridOptions]="gridOptions" 
                      (gridReady)="gridApiForMetalOpening = $event;"
                      [columnSettings]="true"
                      (cellValueChanged)="onCellValueChange($event)"
                      >
                      <!-- [rowData]="customerDetails.openingBalances"  -->
                    </app-ag-grid>
                    <!-- (cellValueChanged)="getParamsonCellValueChanged($event)" -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer py-2 mx-2" [ngClass]="{'top-border mt-3': hide_section}"  *ngIf="!hide_section">
    <app-button class="my-0 me-3" (buttonOutputEvent)="clear()" [buttonLabel]="'Clear'" [buttonClass]="'light_button'" 
      [buttonType]="'button'">
      <!-- activeModal.close(); -->
    </app-button>
    <app-button
      [ngbTooltip]="sendDataService?.noPermissionTooltip"
      [disableTooltip]="(data?.accountLedgerId ? accountLedgerCanUpdate : accountLedgerCanAdd)"
      [disabled]="!(data?.accountLedgerId ? accountLedgerCanUpdate : accountLedgerCanAdd)" class="m-0" (buttonOutputEvent)="addOrEditCustomerDetails()" [buttonLabel]="'Save'" [buttonClass]="'blue_button'"
      [buttonType]="'submit'">
    </app-button>
  </div>
</form>