import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { StorageService } from '../../services/storage.service';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
import { ItemListDesignNoColumn, ItemListGoldPurityWtColumn, ItemListGrossWtColumn, ItemListGroupNameColumn, ItemSectionModel, agGridDataModelItemList } from "./item-list-section.model"
import { StorageName } from '../../enum/common-enum';
import { Router } from '@angular/router';
import { Path, VoucherType } from '../../models/common.model';
import { AgColDefsI, AgGridI } from '../ag-grid/ag-grid.model';
import {ItemListCheckboxColumn ,ItemListImageColumn,ItemListBarcodeNoColumn,ItemListDescriptionColumn,ItemListFinalWtColumn,ItemListMakingColumn,ItemListTaxColumn,ItemListAmtColumn,ItemListNetAmtColumn,ItemListActionColumn} from './item-list-section.model'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ItemListSectionService} from './item-list-section.service'
import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-item-list-section',
  templateUrl: './item-list-section.component.html',
  styleUrls: ['./item-list-section.component.scss']
})
export class ItemListSectionComponent extends  CommonService implements OnDestroy {
  @Output() onItemListChange: EventEmitter<any> = new EventEmitter();
  @Output() onItemListRowClick: EventEmitter<any> = new EventEmitter();
  @Input() formName:string;
  @Input() formModel:any;
  @Input() ImportButtonVisibility:boolean = false
  @Input() gridDynamicHeightClass : string = 'h-250px';
  @Input() SampleFileData:any
  @Input() title ='Print';
  @Output() onPrint = new EventEmitter();
  agGridDataModelItemList : any
  gridApi:any;
  onAddProductsInItemListSubscription: Subscription;
  getInvoiceSubscription: Subscription;
  resetInvoiceSubscription: Subscription;
  tabId:number;
  @Input() itemSectionModel = new ItemSectionModel()
  @Output() grossWeightCalculated = new EventEmitter<number>();
  showImportAndDownloadFile:boolean = false;
  @Output() barcodeNo = new EventEmitter();
  storageName = StorageName
  @Output() onDeleteClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public notificationService : NotificationService,
    public agGridService : AgGridService,
    public renderer: Renderer2,
    public modalService: NgbModal,
    public storageService : StorageService,
    public router : Router,
    public itemListSectionService : ItemListSectionService,
    ){
     super(renderer);;
      this.onAddProductsInItemListSubscription = this.storageService?.onAddProductsInItemList?.subscribe((result) => {
        if(result != undefined)
        this.onAddProductsInItemListResponce(result);
       });
       this.getInvoiceSubscription = this.storageService?.getInvoice?.subscribe((result) => {
        if(result != undefined)
        this.getInvoiceResponse(result?.response);
       });
       this.resetInvoiceSubscription = this.storageService?.resetInvoice?.subscribe((result) => {
        if(result != undefined)
          this.resetInvoiceResponse(result);
          this.addFooterAndCalculation(this.gridApi);
       });
      //  this.onDeleteRowClickedSubscription = this.storageService.onDeleteRowClicked.subscribe((result) => {
      //   if(result != undefined && result.gridName != "paymentList" && result.gridName != "" && result.gridName != undefined)
      //   this.onDeleteRowClickedResponse(result);
      //  });
  }
  onDeleteIconClick(params) {
    // this.onDeleteClick.emit(params)
    const result = params?.detail?.data
    // if (result != undefined && result.gridName != "paymentList" && result.gridName != "" && result.gridName != undefined)
    this.onDeleteRowClickedResponse(result);
    this.formModel.invoiceDocumentResources = this.formModel.invoiceDocumentResources.filter((x) => {
    return x.designNo != result.data.designNo;
    });
    
  
  }

  onDeleteRowClickedResponse(event) {
    this.itemListChange()
  }
  ngOnInit() {
    this.agGridDataModelItemList = agGridDataModelItemList;
    // this.setStorageNames();
    const currentPath = this.router.url;
    this.itemSectionModel.setVisibility(); // Assuming formName is the StorageName
    this.agGridDataModelItemList = this.updateGridModel(this.itemSectionModel?.showCheckboxColumn,this.itemSectionModel?.hideColumnConditionally ,currentPath);
  }

  ngAfterViewInit(): void {
    this.setStorageNames();
  }

  updateGridModel(showCheckboxColumn: boolean,hideColumnConditionally: boolean ,currentPath: string): AgGridI {
    const includeDesignNoColumn =
    currentPath === Path.JobWorkOrder ||
    currentPath === Path.SaleOrder;
    const ItemListColumnDef: AgColDefsI[] = [
      ...(showCheckboxColumn ? [ItemListCheckboxColumn] : []), // Include checkbox column conditionally
      ...(hideColumnConditionally ? [ItemListBarcodeNoColumn,
                                    ItemListGroupNameColumn,
                                    ItemListGrossWtColumn,
                                    ItemListFinalWtColumn,
                                    ItemListMakingColumn,
                                    ItemListTaxColumn,
                                    ItemListNetAmtColumn,
                                  ] : []), // Include description and netAmt column conditionally
                                  ...(includeDesignNoColumn ? [ItemListDesignNoColumn] : []),
      ItemListImageColumn,
      // ItemListBarcodeNoColumn,
      // ItemListGroupNameColumn,
      ItemListDescriptionColumn,
      // ItemListGrossWtColumn,
      // ItemListFinalWtColumn,
      // ItemListMakingColumn,
      // ItemListTaxColumn,
      ItemListAmtColumn,
      ItemListGoldPurityWtColumn,
      // ItemListNetAmtColumn,
      ItemListActionColumn,
    ];

    return {
      colDefs: ItemListColumnDef,
      rowSelection: "multiple",
      storageName: StorageName.TRANSACTION_SALEINVOICE_PRODUCTLIST_GRID,
      addBlankRowOnEnter: true,
      columnSettings: true,
      singleClickEdit: true,
      rows: [],
      rowHeight: 28,
    };
  }

  setStorageNames()
  {
    this.agGridDataModelItemList.storageName =this.itemSectionModel.storageName;
  }

  resetInvoiceResponse(formName) {
    if(formName != undefined) {
      this.gridApi?.api?.setRowData([]);
      this.formModel = {};
      this.formModel.invoiceModel = {};
    }
  }

  getInvoiceResponse(result) {
    this.formModel.itemList = result?.itemList?.filter(a=>a?.consumptionOrProductionId != 1);
    this.gridApi.api.setRowData(this.formModel?.itemList);
    this.formModel.grossWt = this.calculateTotalGrossWt(this.formModel?.itemList);
    this.grossWeightCalculated.emit(this.formModel?.grossWt);
  }
  
  calculateTotalGrossWt(itemList) {
    const sumOfGrossWt = (itemList?.map(a => Number(a?.grossWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
    return sumOfGrossWt;
  }

  onAddProductsInItemListResponce(res){
    if (res?.isSuccess) {
      var data =  this.gridApi.api.getSelectedRows();
      var dataNodes =  this.gridApi.api.getSelectedNodes();
      if(this.formModel.invoiceModel.fromScrapInvoiceItem == true)
        res.response.againstVoucherTypeId = VoucherType.OldJewelryScrapInvoice;
      if(data != undefined && data.length > 0)
      {
        var response = res.response;
        this.updateColumnsInSelectedRows(response,data);
        if(this.formName == StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE || this.formName == StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE)
          this.storageService.onAddProductsInPaymentList.next({response : response, voucherTypeId : VoucherType.MaterialIssue , addNewRow : false , rowIndex : dataNodes[0].rowIndex });
      }
      else
      {
        this.agGridService.addNewRowWithData(this.gridApi,res?.response);
        if(this.formName == StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE || this.formName == StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE)
          this.storageService.onAddProductsInPaymentList.next({response : res?.response, voucherTypeId : VoucherType.MaterialIssue , addNewRow : true, rowIndex : undefined});
      }
      this.itemListChange();
    } else this.notificationService.errorPopup(res.errors[0]);
}

calculateTotal(property: string, data: any[]): number {
  return data?.map(item => Number(item[property])).filter(value => !isNaN(value)).reduce((a, b) => a + b, 0) || 0;
}

itemListChange()
{
  this.formModel.itemList =  this.gridApi.api.getModel().rowsToDisplay.map(a => a.data);
  this.onItemListChange.emit(this.formModel); 
  this.formModel.grossWt = this.calculateTotalGrossWt(this.formModel.itemList);
  this.grossWeightCalculated.emit(this.formModel.grossWt);
  this.addFooterAndCalculation(this.gridApi);
}

updateColumnsInSelectedRows(response,selectedRows) {
  // const selectedRows = this.gridApi.api.getSelectedRows();
  // const selectedRowNode = this.gridApi.api.getSelectedNodes();
  // Update specific columns in selected rows
  selectedRows.forEach(row => {
    row.detailedJson = response.detailedJson;
    row.netAmount = response.netAmount;
    row.amount = response.amount;
    row.designNo = response.designNo;
    row.barcodeNo = response.barcodeNo;
    row.metalValue = response.metalValue;
    row.taxAmount = response.taxAmount;
    row.makingAmount = response.makingAmount;
    row.netAmountWithTax = response.netAmountWithTax;
    row.goldPurityWt = response.goldPurityWt;
    row.grossWt = response.grossWt;
    row.finalWt = response.finalWt;
    row.alloyWt = response.alloyWt;
    row.quantity = response.quantity;
    row.edited = true;
    row.added = false;
    row.deleted = false;
    row.parentMetalId = response.parentMetalId;
    row.description = response.description;
    row.comment =response.comment;
    row.groupName =response.groupName;
  });
  // Refresh the grid to reflect the changes
 // this.gridApi.api.setRowData(selectedRows);

 try
 {
  setTimeout(() => {
    this.gridApi.api.redrawRows(); 
    this.gridApi.api.refreshCells(); 
    this.gridApi.api.deselectAll();
  }, 600);

 }
 catch(ex)
 {
debugger
 }

}

  
  onGridReady(gridApi) {
    this.gridApi = gridApi;
  }

  onEditIconClick(event){
    
    // const isBarcodeCellClick = this.hasClassInTarget(event?.event?.target, 'barcode');
    // const isImagesCellClick = this.hasImageRendererClass(event?.event?.target);
    // if (!isImagesCellClick && !isBarcodeCellClick){
    //   this.gridApi.api.deselectAll();
      // event.node.setSelected(true);
      this.onItemListRowClick.emit(event); 
      // this.storageService?.onItemListRowClicked?.next(event);
    // }
    // if(isBarcodeCellClick){
    //   this.barcodeCellClicked(event)
    // }
  }

  imgCellClicked(event){
    this.gridApi.api.deselectAll();
    event.node.setSelected(true);
    this.storageService.imgCellClicked.next(event);
  }



  confirmPrintPopUp(event) {
    const title = `${this.title} ${(this.formName === this.storageName.FORMNAME_TRANSACTION_STOCKJOURNAL || 
                    this.formName === this.storageName.FORMNAME_INVENTORY_BARCODE) ? 'barcode' : 'bill'}`;
    const message = `Do you want to ${this.title.toLowerCase()} ${(this.formName === this.storageName.FORMNAME_TRANSACTION_STOCKJOURNAL || 
                    this.formName === this.storageName.FORMNAME_INVENTORY_BARCODE) ? 'barcode' : 'invoice'}?`;
    this.notificationService.confirmationPopup(title, message, 'printBill').subscribe((result) => {
      if(result.isConfirmed) {
        this.onPrint.emit(true);
      }else{
        this.onPrint.emit(false);
      }
    });
  }
  // barcodeCellClicked1(event){
  //   if(event.data.barcodeNo)
  //     return;
  //   if(this.router.url == '/Transaction/sale-order'){
  //     const modalOption: NgbModalOptions = {
  //       ...commonModalOptions,
  //       size            : "sm",
  //     };
  //     const modalRef = this..open(PrintConfirmationComponent, modalOption);
  //     modalRef.componentInstance.formName = this.storageName.FORMNAME_INVENTORY_BARCODE;
  //     modalRef.componentInstance.title="Generate";
  //     modalRef.componentInstance.barcodeSvg = true
  //     modalRef.result.then((result) => {
  //       if(result){
  //         this.getBarcode(event)
  //       }
  //     });
  //     }
  // }

  getBarcode(event){
    const node  =  event?.node;
    this.itemListSectionService.GenerateBarcodeForSaleOrderItem().subscribe((res) => {
      if (res.isSuccess) {
        const value = res?.response;
        node.setDataValue('barcodeNo', value);
      }
    })

  }
 
 callApionRowCellClicked(event){
    if(this.formName == StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE){
      this.storageService.onItemListRowClicked.next(event);
    }
  }
  hasImageRendererClass(target: any): boolean {
    return target?.closest('.images_renderer') !== null;
  }
  hasClassInTarget(target: any, className: string): boolean {
    return target?.closest(`.${className}`) !== null;
  }
  onFileSelect(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      
    }
  }

  downloadExcel() {
    this.exportExcel(this.SampleFileData?.headerNames, [], `${this.SampleFileData.fileName}.xlsx`);
  }

  handleError(arg0: any): any {
    throw new Error('Method not implemented.');
  }


   //#region footer row calculation
  addFooterAndCalculation(params) {
    const rowsdata = params?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
    const sumOfMakingAmt = (rowsdata?.map(a => Number(a?.makingAmount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
    const sumOfGrossWt = (rowsdata?.map(a => Number(a?.grossWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
    const sumOfGoldPurityWt: string = (rowsdata?.map(a => Number(a?.goldPurityWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
    const sumOfFinalWt: string = (rowsdata?.map(a => Number(a?.finalWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
    const sumOfTaxAmount: string = (rowsdata?.map(a => Number(a?.taxAmount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
    const sumOfAmount: string = (rowsdata?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
    const sumOfNetAmount: string = (rowsdata?.map(a => Number(a?.netAmount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
    const sumOfNetAmountWithTax: string = (rowsdata?.map(a => Number(a?.netAmountWithTax))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);

    const totalsObject = {
      makingAmount: sumOfMakingAmt,
      grossWt: sumOfGrossWt,
      goldPurityWt: sumOfGoldPurityWt,
      finalWt: sumOfFinalWt,
      taxAmount: sumOfTaxAmount,
      amount: sumOfAmount,
      netAmount: sumOfNetAmount,
      netAmountWithTax: sumOfNetAmountWithTax,
      isFooterRow: true
    }
    params?.api?.setPinnedBottomRowData([totalsObject]);
    params?.api?.refreshCells();

  }
  //#endregion

  ngOnDestroy(): void {
    this.onAddProductsInItemListSubscription?.unsubscribe()
    this.getInvoiceSubscription?.unsubscribe()
    this.resetInvoiceSubscription?.unsubscribe()
  }
}
