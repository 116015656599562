import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { commonModalOptions, ModalPopupSize } from '../../models/common.model';
import { OpenImagePopupComponent } from '../common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { NotificationService } from '../../services/notification.service';


@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent extends CommonService implements OnInit {
  @Input() imageList = [];
  currentImageIndexMap: { [key: string]: number } = {};
  @Input() initialIndex: number = 0;
  @Output() imageClick = new EventEmitter<void>();
  @Input() images1: { imageUrl: string }[] = [];
  @Input() images : any[]
  currentImageIndex: number = this.initialIndex;
  @Input() isBase64Resource: boolean=false;
  baseUrl
  constructor(public renderer: Renderer2, private modalService: NgbModal,private notificationService: NotificationService,) {
    super(renderer);
  }


  ngOnInit(): void {
  this.baseUrl =   environment.baseUrl;
    this.currentImageIndex = this.initialIndex;
  }
  
  showPrevImage(event: Event): void {
    event.stopPropagation();
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    } else {
      this.currentImageIndex = this.images.length - 1;
    }
  }

  showNextImage(event: Event): void {
    event.stopPropagation();
    if (this.currentImageIndex < this.images.length - 1) {
      this.currentImageIndex++;
    } else {
      this.currentImageIndex = 0;
    }
  }

  onImageClick(): void {
    if (this.images.length > 0) {
      this.images = this.images.map(imageObj => {
        if (imageObj?.imageUrl?.startsWith(environment.baseUrl)) {
          imageObj.imageUrl = imageObj.imageUrl.replace(environment.baseUrl, '');
        }
        return imageObj;
      });
      var data = {
        jewelryCatalogue: [...this.images],
      }
      this.notificationService.openModalPopup(OpenImagePopupComponent, data, '', ModalPopupSize.SM, 'image_popup_fullscreen',true,true).then(
        (result) => {
          if (result)
            this.images.forEach(item => {
              if (item?.imageUrl) {
                if (!item.imageUrl.startsWith(environment.baseUrl)) {
                  item.imageUrl = environment.baseUrl + item.imageUrl;
                }
              }
            });
        }
      )
    }
  }
 
}
