import { Component } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { ManufacturingOutComponent } from 'src/app/components/manufacturer/popups/manufacturing-out/manufacturing-out.component';
import { commonModalOptionsXXl } from 'src/app/shared/models/common.model';

@Component({
  selector: 'app-custom-header-parent',
  templateUrl: './custom-header-parent.component.html',
  styleUrls: ['./custom-header-parent.component.scss']
})
export class CustomHeaderParentComponent implements IHeaderGroupAngularComp {

  params: any;
  HeaderName: string = '';
  id: any;
  firstColumnIndex: number = -1;
  totalWeightAvailable = 0;
  totalQtyAvailable = 0;
  totalWeightScanned = 0;
  totalQtyScanned = 0;
  constructor(private modalService: NgbModal) {


  }
  agInit(params: any): void {
    this.params = params;
    this.HeaderName = this.params.displayName;
    this.id = this.params?.columnGroup?.groupId;

    //// Find the index of the first column within the column group
    const childColumns = this.params?.columnGroup?.getLeafColumns();
    if (childColumns && childColumns.length > 0) {
      this.firstColumnIndex = this.params.columnApi.getAllGridColumns().indexOf(childColumns[0]);
    }
    if(this.params.displayName == "scannedStock" || this.params.displayName == "AvailableStock"){
      this.calculateTotalWeight(params)
    }
  
  }



  refresh(params: any): boolean {
    return false;
  }
  // openMaterialOutPopUp() {
  //   const modalOption: NgbModalOptions = {
  //     ...commonModalOptionsXXl,
  //   };
  //   const modalRef = this..open(ManufacturingOutComponent, modalOption);
  //   // modalRef.componentInstance.modalTitle = this.popupTitle.MaterialIssue;
  //   modalRef.result.then((result) => {
  //     if (result) {
  //     }
  //   })
  // }
  // openMaterialINPopUp() {
  //   const modalOption: NgbModalOptions = {
  //     ...commonModalOptionsXXl,
  //   };
  //   const modalRef = this.notificationService.openModalPopup( MaterialIssueComponent, modalOption);
  //   // modalRef.componentInstance.modalTitle = this.popupTitle.MaterialIssue;
  //   modalRef.result.then((result) => {
  //     if (result) {
  //     }
  //   })
  // }


  calculateTotalWeight(event?: any) {
    const rowsToDisplay = event?.api?.getRenderedNodes()?.map(node => node?.data);
    var sumWt = event.api.getModel().rowsToDisplay.reduce((total, row) => total + (row.data['weight'] || 0), 0);
    var sumQty = event.api.getModel().rowsToDisplay.reduce((total, row) => total + (row.data['quantity'] || 0), 0);
    if(event.displayName == "AvailableStock")
    {
      this.totalWeightAvailable =  parseFloat(sumWt.toFixed(3));;
      this.totalQtyAvailable = parseFloat(sumQty.toFixed(3));;
    }
    if(event.displayName == "scannedStock")
    {
      this.totalWeightScanned = parseFloat(sumWt.toFixed(3));;
      this.totalQtyScanned =  parseFloat(sumQty.toFixed(3));;
    }
   

  }

}
