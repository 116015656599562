import { Component, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { catchError } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { IconHover } from '../../../../../shared/enum/common-enum';
@Component({
  selector: 'app-add-edit-location',
  templateUrl: './add-edit-location.component.html',
  styleUrls: ['./add-edit-location.component.scss']
})
export class AddEditLocationComponent extends CommonService{

  @ViewChild("LocationForm") LocationForm!: NgForm;
  locationRequestModel: any = {};
  currentHoverEffect = IconHover.Pink;
  getAllDropdownModel: any = {};
  formName = StorageName.FORMNAME_PRODUCT_PRODUCTOPENING;
  storageName = StorageName;
  constructor(public activeModal: NgbActiveModal,
    public administrationService: AdministrationService,
    public renderer: Renderer2,
    public toaster: ToastrService,
    public storageService: StorageService,) {
    super(renderer)
    this.getAllDropdownModel.locationDropdown = this.storageService.retrieve(this.storageName.LOCATION_DROPDOWN)
  }

ngOnInit(){
  this.locationRequestModel.active = true;
}

InserLocationList() {
  const { id, ...rest } = this.locationRequestModel;
  this.administrationService.insertLocation(rest)
  .pipe(catchError((err) => this.handleError(err)))
  .subscribe((res) => {
    if (res.isSuccess) {
    this.toaster.success(res.message);
    this.activeModal.close(true);
    } else {
  this.toaster.error(res.errors[0]);
  }
});
}

}
