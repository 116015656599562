<div class="media profile-media align-items-center">
  <div class="media-body me-2" (click)="addListShow()"><div class="text-primary f-13 f-w-semibold text-nowrap lh-1">{{user?.fullName}}</div>
    <div class="mb-0 text-secondary lh-1 f-10">{{layoutDetails?.roleName}} <i class="fa fa-sort-desc ms-3"></i></div>
  </div>
  <img class="rounded-circle img-24" [src]="isBase64Image(user?.userProfileUrl)" alt="img">
</div>
<!-- <ul class="profile-dropdown onhover-show-div">
  <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul> -->
<div class="position-absolute wrap-list-container"
(mouseleave)="showDropdown = false">
  <div class="list-container" *ngIf="showDropdown">
    <div class="row">
      <div class="col-12">
        <div class="d-flex">
          <div class="d-flex justify-content-center align-items-center name-item-1 theme-text-bg-color me-2 mt-1">
            <span class="theme-text-primary-blue f-12  font-semibold">{{getprefix(user?.fullName)}}</span>
          </div>
          <div class="d-flex flex-column ">
            <label for="" class="theme-text-primary-blue f-12  font-semibold mb-0">{{user?.fullName || 'NA'}}</label>
            <label class="text-gray-color f-9">{{user?.emailId || 'NA'}}</label>
          </div>
        </div>
        <div class="py-1 edit-item ps-2 d-flex  align-items-center theme-text-bg-color" (click)="navigate();addListShow()">
          <span class="me-2"><img src="./assets/SVGicon/edit-profile.svg" alt=""></span>
          <label for="" class="theme-text-primary-blue f-12  font-semibold mb-0 ps-1 cursorPointer" >Edit Profile</label>
        </div>
      </div>
      <hr class="hr-item">
      <div class="col-12 branchList_div">
        <ul *ngFor="let branch of branchList">
          <li class="d-flex mb-1" [style.cursor]="branch?.id == user?.branchId ? 'pointer_none' : 'pointer'"
          (click)="branch?.id != user?.branchId ? getUseretails(branch?.id) : getUseretails(undefined);addListShow()">
            <div class="d-flex justify-content-center align-items-center name-item-1 theme-text-bg-gray me-2" [style.cursor]="branch?.id == user?.branchId ? 'pointer_none' : 'pointer'">
              <span class="theme-color-secondary f-12  font-semibold" [style.cursor]="branch?.id == user?.branchId ? 'not-allowed' : 'pointer'">{{getprefix(branch?.name)}}</span>
            </div>
            <span *ngIf="branch?.id == user?.branchId" class="checkmark"></span>
            <label for="" class="f-12 mt-1 font-semibold theme-color-secondary"  [style.cursor]="branch?.id == user?.branchId ? 'pointer_none' : 'pointer'">{{branch?.name}}</label>
          </li>
        </ul>
      </div>
      <div class="col-12 px-1 mb-1 gap-1 d-flex flex-column" *ngIf="layoutDetails?.roleName == 'Admin'">
        <div class="py-1 d-flex justify-content-center align-items-center theme-text-bg-color" [ngClass]="{'pointer-none': !mastersCanView, 'cursorPointer': mastersCanView}"
          (click)="mastersCanView && redirect(); addListShow()">
          <label for="" class="theme-text-primary-blue f-12 font-regular mb-0"  [ngClass]="{'pointer-none': !mastersCanView, 'cursorPointer': mastersCanView}">Manage Branches</label>
        </div>
      </div>
      <!-- *ngIf="layoutDetails.isUserSuperAdmin == true"  -->
      <div class="col-12 px-1 mt-1" *ngIf="layoutDetails?.roleName == 'Admin'">
        <div class="py-1 d-flex justify-content-center align-items-center theme-text-bg-color"
          (click)="navigateCompanylist();addListShow()">
        <label for="" class="theme-text-primary-blue f-12 font-regular mb-0 cursorPointer">Manage Company</label>
        </div>
      </div>
      <div class="col-12">
        <div class="py-1  d-flex justify-content-center align-items-center ">
          <label for="" class=" f-12 cursorPointer font-semibold mb-0 sign-out-item " (click)="logoutFunc()">Sign Out</label>
        </div>
      </div>
    </div>
  </div>
</div>
