import { Injectable } from '@angular/core';
import { DataService } from './../../shared/services/data.service';
import { ResponseModel } from './../../shared/models/response-model.model';
import { Observable, tap } from 'rxjs';
import { AnyNaptrRecord } from 'dns';
import { AccountGroupsPopupComponent } from './popup/account-groups-popup/account-groups-popup.component';
import { ModalPopupSize } from 'src/app/shared/models/common.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialStatementService {
  constructor(public service:DataService, public notificationService : NotificationService, public commonFilterService : CommonFilterService) { }

  // trial balance start
  getTraialBalance(data:any):Observable<ResponseModel>{
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = `api/app/financialstatement/trialbalance`;
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  // trial balance end
  // VTA Return start //
  getVatReturn(data:any):Observable<ResponseModel>{
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = `api/app/taxreturn/getTaxReturn`;
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  //Tax Return Input//
  getInputOutputTaxReturn(data:any): Observable<ResponseModel> {
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = "api/app/taxreturn/getInputOutputTaxReturn";
    const json = JSON.stringify(data);
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   //Tax Return Import//
  getAllImport(data:any): Observable<ResponseModel> {
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = "api/app/taxreturn/getallimporttaxreturn";
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
   //Tax Return Export//
  getAllExport(data:any): Observable<ResponseModel> {
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = "api/app/taxreturn/getallexporttaxreturn";
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
  // VTA Return end

  // balance sheet start 
  getBalanceSheet(data:any):Observable<ResponseModel>{
  data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
  data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = `api/app/financialstatement/balancesheet`;
    const json = JSON.stringify(data);
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  // balance sheet end

  // Profit And Loss start 
  getProfitLossData(data:any):Observable<ResponseModel>{
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = `api/app/financialstatement/profitandloss`;
    const json = JSON.stringify(data);
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  // Profit And Loss end

  // Cash flow start 
  getCashFlowData(data:any):Observable<ResponseModel>{
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = `api/app/financialstatement/cashflow`;
    const json = JSON.stringify(data);
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  //Cash flow start end

  // Fund flow start 
  getFundFlowData(data:any):Observable<ResponseModel>{
    data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
    data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
    let url = `api/app/financialstatement/fundflow`;
    const json = JSON.stringify(data);
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  //Fund flow start end

    // Chart of Account start  
    getChartOfAccount(data:any):Observable<ResponseModel>{
      data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
      data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
      // let url = `api/app/financialstatement/chartofaccount?accountGroupId=${accountGroupId}`;
      let url = `api/app/financialstatement/chartofaccount`;
      // const json = JSON.stringify(getFinancialDataRequestModel);
      return this.service.post(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      )
    }
    //Chart of Account end


    //Transaction Report START
    getTransactionreportSale(data):Observable<ResponseModel>{
      let url = `api/app/financialstatement/getsaletransitionreport`;
      const json = JSON.stringify(data);
      return this.service.post(url,data,true,false,false).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      )
    }

    getTransactionReportPurchase(data): Observable<ResponseModel> {
      let url = `api/app/financialstatement/getpurchasetransitionreport`;
      const json = JSON.stringify(data);
      return this.service.post(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      )
    }
    //Transaction Report END

    // Tax Report Input output start
    getTaxReportFinancialTax(data:any):Observable<ResponseModel>{
      let url = 'api/app/financialstatement/gettaxreport';
      const json = JSON.stringify(data);
      return this.service.post(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      )
    }
   // Tax Report start

    // account groups popup
    getallAccountGroups(data:any):Observable<ResponseModel>{
      data.fromDate = this.commonFilterService.formatNgbDateToString(data.fromDate);
      data.toDate =  this.commonFilterService.formatNgbDateToString(data.toDate);
      let url = 'api/app/financialstatement/accountgroupwisereport';
      const json = JSON.stringify(data);
      return this.service.post(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      )
    }
   // account groups popup end

   getallLedgerDetails(data : any):Observable<ResponseModel>{
    let url = "api/app/financialstatement/accountledgerwisereport";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  openAccountGroupPopup(amount, accountGroupName, accountGroupId, fromDate, toDate, source, closingStock = 0) {
    if (amount != 0) {
      var item: any = {};
      item.accountGroupId = (accountGroupId == null ? 0 : accountGroupId);
      item.accountGroupName = accountGroupName;
      item.fromDate = fromDate;
      item.toDate = toDate;
      item.source = source;
      item.closingStock = closingStock;
      this.notificationService.openModalPopup(AccountGroupsPopupComponent, item, '', ModalPopupSize.XL, '');
    }
  }
}
