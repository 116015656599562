import { Injectable } from '@angular/core';
import { DataService} from '../../../shared/services/data.service';
import { BehaviorSubject, tap } from 'rxjs';
import { ResponseModel } from '../../../shared/models/response-model.model';

@Injectable({
  providedIn: 'root'
})
export class JewelryService {
  public totalAddedCartService = new BehaviorSubject<any>(undefined);
  
  constructor(public service:DataService) { }

  VerifyOTP(data) {
    let url = `api/App/account/verifyotp`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getRegisterByCustomerDetailse(data){
    let url = `api/app/accountledger/createaccountledgerforanonymoususer`;
    
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  

  getCustomerByPhoneNo(data){
    let url = `api/app/customer/getcustomerbyphoneno?PhoneNumber=${data}`;
    return this.service.get(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  placeOrder(data){
    let url = `api/app/placeorder/placeorder`;
    const json =  JSON.stringify(data);
    
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

}
