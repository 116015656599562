import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCarousel, NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { FileCategory } from 'src/app/shared/enum/common-enum';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-open-image-popup',
  templateUrl: './open-image-popup.component.html',
  styleUrls: ['./open-image-popup.component.scss']
})
export class OpenImagePopupComponent extends CommonService implements OnInit , AfterViewInit , OnDestroy{
  @ViewChild('carousel') carousel: NgbCarousel | undefined;
  carouselWidth: number = 396;
  carouselHeight: number = 350;
  FileCategory = FileCategory;
  @Input() selectedIndex: number;
  @Input() data: any={};
  isBase64Resource: boolean=false;
  constructor(public activeModal: NgbActiveModal,public renderer : Renderer2,private cdr: ChangeDetectorRef){
    super(renderer);
  }

  jewelryCatalogue = [];
  ngOnInit(): void {
    const obj = this.data?.jewelryCatalogue[0]??this.data?.jewelryCatalogue[0];
    const isCheck = "base64Resource" in obj;
    if(isCheck){
      this.isBase64Resource = true
    }else{
      this.isBase64Resource = false
    }
   }

   ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.carousel?.ngOnDestroy();
  }

}