<div>
    <div *ngIf="isShowHeader" class="d-flex align-items-center"> 
        <div class="gap-2 d-flex align-items-center me-2">
            <h5 class="text-primary font-semibold f-14 mb-0 p-2" [ngClass]="{'w-200px': !formModel?.fundTransferAmountResult && !headerText}">
                {{headerText}} 
                <!-- <span class="text-pink">{{formModel?.balanceResult}}</span> -->
                <span *ngIf="fundTransferAmount" class="ms-1 text-underline cursorPointer text-primary font-semibold f-14" (click)="openFundTransferPopup()">{{formModel?.fundTransferAmountResult}}</span>
            </h5>
            <div class="form-group d-flex gap-2" *ngIf="metalSectionModel?.isBarcodeInput">
                <div class="input-group w-100" >
                    <input class="form-control f-12 h-100 align-bar" id="barcode" type="text" placeholder=""  [(ngModel)]="metalSectionModel.barcodeNo" (keydown.enter)="getProductDataByBarcodeNo(metalSectionModel.barcodeNo)" aria-label="Barcode">
                    <div class="input-group-append "(click)="clearBarcode()">
                        <span class="input-group-text px-2 cursorPointer  ">
                            <img src="./assets/images/svg-icon/barcode_icon.svg" alt="">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="enableAdding">
            <div class="mb-0">
                <span class="text-pink f-12 font-bold">{{formModel?.balanceDescription}}</span>
            </div>
        </div>
        <!-- Icons -->
         <div class="d-flex col justify-content-end">
        <div class="col recent-sliders d-flex justify-content-end align-items-center py-1 gap-2">
            <div *ngFor="let tab of paymentTypeDropdown">
                <div class="icon-container">
                <app-svg-icon
                    class="cursorPointer"
                    [ngbTooltip]="!isTouchDevice ? (tab?.name === 'OldJewellery' ? 'Scrap' : tab?.name === 'MetalExchange' ? 'Metal-Exchange' : tab?.name) : null"
                    [name]="tab?.name === 'OldJewellery' ? 'Scrap' : tab?.name === 'MetalExchange' ? 'Metal-Exchange' : tab?.name"
                    (click)="openPaymentTypePopup(tab?.name, tab?.id)"></app-svg-icon>
                </div>
            </div>
            <div class="icon-container">
            <app-svg-icon *ngIf="enableAdding" ngbTooltip="Add Diamond" name="metal_diamond" class="metal_diamond cursorPointer" (click)="openAddDiamondPopup('Add Diamond')"></app-svg-icon>
            </div>
            <div class="icon-container">
            <app-svg-icon *ngIf="enableAdding" ngbTooltip="Add Stone" name="stone" class="stone cursorPointer" (click)="openAddDiamondPopup('Add Stones')"></app-svg-icon>
            </div>
        </div>
    <!-- Icons -->
    </div>
    </div>
  
    <div class="position-relative"  >
        <div class=""*ngFor="let item of tabGroupList" >
        <!-- payment_list_table -->
        <app-ag-grid
        id="payment"
        [class]="'all_aggrid_tables position-relative d-block '+ gridDynamicHeightClass" 
                [dataModel]="item.dataModel"
                (cellValueChanged)="onCellValueChange($event)"
                [addBlankRowOnEnter]="true"
                [tableRowCellEditable]="false"
                [rowHeight]="rowHeight"
                [headerHeight]="headerHeight"
                (gridReady)="onGridReady($event)"
                [columnSettings]="true"
                (onDeleteIconClick)="onDeleteIconClick($event)"
                (onEditIconClick)="onEditIconClick($event)"
                >
        </app-ag-grid>       
        </div>        
    </div>
    <div class="row d-flex flex-fill" *ngIf="isShowFooterRow">
        <div class="col-12  transactiontable">
            <table class="table table-bordered">
                <tbody>
                    <tr class="d-flex align-items-center p-0 tabel-bg-color border-bottom">
                        <td width="75%" class="text-start {{bold?'font-bold':''}} color-dark-gray font-semibold border-bottom-0 border-right-0 border-top-0 tabel-bg-color"> Total</td>
                        <td width="25%" class="text-center {{bold?'font-bold':''}} border-left-0 color-dark-gray font-regular border-bottom-0 border-top-0 px-0 tabel-bg-color"> {{TotalAmount?.toFixed(2)}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>