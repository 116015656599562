//PRODUCT OPENING
export const API_URL_GET_ALL_PRODUCT = "api/app/product/getallproduct";
export const API_URL_GET_ALL_ACCOUNTlEDGER ="api/app/accountledger/getallaccountledger";
export const API_URL_GET_ALL_ACCOUNTlEDGER_PARTIES ="api/app/accountledger/getparty";
export const API_URL_GET_ALL_INVOICES ="api/app/invoice/getallinvoice";

// Appraisal

export const API_URL_GET_ALL_AACOUNTLEDGER_APPRAISAL ="api/app/appraisal/getappraisalledgerlist"

//PRODUCT OPENING

//TRANSACTION
export const API_URL_GET_ALL_PDCVOUCHER = "api/app/pdcvoucher/getallpdcvoucher";
export const API_URL_JOBWORK_ORDER_LIST = "api/app/jobworkorder/getall";
//TRANSACTION

//MANUFACTURER
export const API_URL_GET_ALL_MANUFACTURINGDEPARTMENT = "api/App/ManufacturingDepartment/getAllManufacturingDepartment";
export const API_URL_GET_ALL_MANUFACTURINGDEPARTMENT_BREAKTIME = "api/App/ManufacturingDepartment/getAllManufacturingBreakTimings";
export const API_URL_GET_ALL_MANUFACTURINGDEPARTMENT_BOTH_INWARDOUTWARD = "api/app/jobworkqueueitem/getSingleGridJobworkQueue";
export const API_URL_GET_ALL_MANUFACTURE_JOBWORK_QUEUE_REPORT = "api/app/jobworkqueueitem/getalljobworkqueuemasterreport";
export const API_URL_GET_MANUFACTURING_IN_OUT = "api/app/manufacturingprocess/getmanufacturingprocessinout";
//MANUFACTURER

//SETTINGS
export const API_URL_GET_ALL_VOUCHERTYPE = "api/app/vouchertype/getallvouchertype";
export const API_URL_GET_ALL_REFRESHDBHELPERFILES  ="api/app/setting/refreshdbhelperfiles";
export const API_URL_GET_ALL_WHATSAPP_CAMPAIGN  ="api/app/whatsappcampaign/getallwhatsappcampaign";
export const API_URL_GET_ALL_EMAIL_CAMPAIGN  ="api/app/emailcampaign/getallemailcampaign";

//SETTINGS
//REPORTS
export const API_URL_GET_ACCOUNTlEDGER_REPORT_AMOUNT = "api/app/accountLedgerReport/getAccountLedgerReport";
export const API_URL_GET_SCHEDULARTASKS = "api/app/accountLedgerReport/getAccountLedgerReport";
export const API_URL_GET_ACCOUNTLEDGERREPORT_GET_ACCOUNT_LEDGER_DETAIL_BY_LEDGERID = "api/app/accountledgerreport/getaccountledgerdetailbyledgerid";
export const API_URL_GET_ACCOUNTlEDGER_REPORT_METAL = "api/app/accountLedgerReport/getAccountLedgerReportMetal";
export const API_URL_GET_ALL_DAYBOOK = "api/app/DayBook/getAllDayBook";
export const API_URL_GET_ALL_STOCK = "api/app/stockreport/getallstock";
export const API_URL_GET_ALL_STOCK_SUMMARY_REPORT = "api/app/stocksummary/getstocksummary";
export const API_URL_GET_ALL_STOCKHISTORY = "api/app/stockreport/getallstockhistory";
export const API_URL_GET_ALL_STOCKDETAILS = "api/app/stockreport/getallstockdetails";
export const API_URL_GET_ALL_STOCKDETAILS_REPORT="api/app/goldstockanalysis/getgoldstockanalysisreportbyproduct"
export const API_URL_GET_ALL_AVAILABLESTOCKRFIDBARCODE = "api/app/stockreport/getallavailablestockrfidbarcode"
export const API_URL_GET_ALL_SCANNEDRFIDBARCODE = "api/app/stockreport/getallscannedrfidbarcode";
export const API_URL_SALEORDER_PROCESS_LIST = "api/app/saleorder/getlistofsaleorderitem";
export const API_URL_GET_ALL_SCRAP ="api/app/oldjewelleryscrap/getoldjewelleryscrap";
export const API_URL_GET_ALL_STOKED  = "api/app/oldjewelleryscrap/getoldjewelleryscrap"
export const API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT ="api/app/stockreport/getDiamondAndStoneImitationReport";
export const API_URL_GET_ALL_IMITATION_AND_WATCHES ="api/app/stockreport/getimitationwatchesreport";
export const API_URL_GET_ALL_GRID_GET_DIAMOND_CURRENT_STOCK = "api/app/stockreport/getDiamondCurrentStock"
export const API_URL_GET_ALL_STOCKHISTORY_GOLDSILVER ="api/app/stockreport/getallstockhistoryofgoldsilver";
export const API_URL_CONSIGNMENTLIST = "api/app/consignmentin/getlistofconsignmentinitem";
export const API_URL_GETALLBARCODESTOCK   ="api/app/stockreport/getallbarcodestock";
export const API_URL_GET_ALL_STOCKAVAILABILITY_OF_DIAMOND ="api/app/stockreport/getallstockhistoryofdiamond"
export const API_URL_SALEORDERLIST_GET_RFID_PRINT = "api/app/saleorder/GetPrintRFID";
export const API_URL_GET_ALL_STOCK_VALUATION = "api/app/stockvaluation/getallstockvaluation";
export const API_URL_GET_ALL_TRANSACTION_SALE_ANALYSIS = "api/app/financialstatement/getsaletransitionreport";
export const API_URL_GET_DIAMONDANDSTONE_REPORT ="api/app/stockreport/getDiamondAndStoneReport";
export const API_URL_GET_GOLDANDSILVER_REPORT ="api/app/stockreport/getgoldandsilverreport";
export const API_URL_GET_ALL_STOCK_TRANSFER_HISTORY ="api/app/stockvaluation/getallstocktransferhistory";

//REPORTS


// USER MANAGEMENT
export const API_URL_GET_ALL_USER = "api/app/users/getalluser"
//ROLE MANAGEMNET
export const  API_URL_GET_ALL_ROLE  = "api/app/role/getallrole"
//ADMINISTRATION
export const API_URL_GET_ALL_ACTIVITY_LOG = "api/app/applicationlog/getallapplicationlog"
//ADMINISTRATION

export const API_URL_GET_ALL_DIAMOND_STOCK ="api/app/stockreport/getdiamondstock"

//
export const  API_URL_GETALLRECEIPTVOUCHER ="api/app/receiptvoucher/getallreceiptvoucher"
export const  API_URL_GETALLPAYMENTVOUCHER ="api/app/paymentvoucher/getallpaymentvoucher"
export const  API_URL_GETALLMATERIAL_IN_VOUCHER ="api/app/materialin/getallmaterialin"
export const  API_URL_GETALLMATERIAL_OUT_VOUCHER ="api/app/materialout/getallmaterialout"
export const  API_URL_GET_ALL_GRID_CONFIGURATION ="api/app/gridproperty/getallgridproperties"

//Inventry
export const API_URL_GET_ALL_MEMOREPORT_LEDGERLIST ="api/app/memo/getmemoreportledgerlist";
export const API_URL_GET_ALL_MEMO_LEDGERLIST = "api/app/consignmentout/getconsignmentreportledgerlist"
//Financial statment
export const API_URL_GET_ALL_FINANCIAL_STATEMENT_INPUT_OUTPUT_TAX_REPORT="api/app/financialstatement/gettaxreport";
export const  API_URL_GET_ALL_CREATE_SHECME= "api/app/investmentfund/getallschemedetails";
export const API_URL_GET_ALL_INVESTMENT_FUND_INSTALL_ENTRY ="api/app/installmentfund/getallinstallmentfunddetails"; 
export const API_URL_GET_ALL_CLOSINGREPORT ="api/app/jobworkqueueitem/getallclosingreport";
export const  API_URL_GET_JOB_CARD ="api/app/saleorder/getsaleorderjobcardbyvouchernoandvouchertypeid";

export const API_URL_GET_ALLINSTALLMENTFUNDREPORTLEDGERLIST ="api/app/installmentfundreport/getallinstallmentfundreportledgerlist";