import { Component, Input, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { RangeDatepickerComponent } from 'src/app/shared/common-components/range-datepicker/range-datepicker.component';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-accountledger-detail-info-advanced-filter',
  templateUrl: './advanced-filte-popup.component.html',
  styleUrls: ['./advanced-filte-popup.component.scss']
})
export class AccountledgerDetailInfoAdvancedFilterComponent {
  @Input() data : any = {};
  @ViewChildren(RangeDatepickerComponent) dateRangeDatepicker: QueryList<RangeDatepickerComponent>
  selectedLedgerIds             = [];
  selectedVoucherTypeIds        = [];
  selectedagainstVoucherTypeId = [];
  selectedLegerTypesIds         = [];
  advanceFilterRequest :any     = {}
  filterAgainstDropdown: any;
  constructor(
    public activeModal         : NgbActiveModal,
    public renderer            : Renderer2,
    public agGridService       : AgGridService,
    public storageService      : StorageService,
    public commonFilterService : CommonFilterService,
    
  )
  {}

  ngAfterViewInit(): void {
    this.setAlreadyAppliedData();
  }
  setAlreadyAppliedData() {
    var previousAppliedFilters = this.storageService.retrieve(this.data.agGridDataModel.filterStorageName);
    if (previousAppliedFilters != undefined) {
      this.selectedLedgerIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "ledgerid", this.data.getAllDropdownModel.allAccountLedgerDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedAccountLedgers = this.selectedLedgerIds?.map((x) => x.id);
      
      this.selectedLegerTypesIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "ledgertype", this.data.getAllDropdownModel.ledgerTypeDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedLedgerTypes = this.selectedLegerTypesIds?.map((x) => x.id);

      this.selectedVoucherTypeIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "vouchertypeId", this.data.getAllDropdownModel.voucherTypeDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedVoucherTypes = this.selectedVoucherTypeIds?.map((x) => x.id);

      this.selectedagainstVoucherTypeId = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "againstVoucherTypeId", this.data.getAllDropdownModel.voucherTypeDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedagainstVoucherType = this.selectedagainstVoucherTypeId?.map((x) => x.id);
      
      this.advanceFilterRequest.branchId = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "branchId", undefined, "dropdown");
      this.advanceFilterRequest.invoiceNo = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "invoiceNo", undefined, "input");

      this.advanceFilterRequest.againstInvoiceNo = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "againstinvoiceno", undefined, "input");

      this.advanceFilterRequest.comment = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "comment", undefined, "input");

      this.advanceFilterRequest.fromDate = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "fromDate", undefined, "input");
      this.advanceFilterRequest.toDate = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "toDate", undefined, "input");
      this.dateRangeDatepicker?.get(0)?.onApply(false, this.advanceFilterRequest.fromDate, this.advanceFilterRequest.toDate);
    }
  }

  onClickAdvancedFilter() {
    this.storageService.removeItem(this.data.agGridDataModel.filterStorageName);

    this.commonFilterService.performFilterColumnwise('ledgerid', this.advanceFilterRequest.selectedAccountLedgers, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('ledgertype', this.advanceFilterRequest.selectedLedgerTypes, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('vouchertypeId', this.advanceFilterRequest.selectedVoucherTypes, undefined, this.data.agGridDataModel.filterStorageName, '');
    
    if (this.advanceFilterRequest.branchId != undefined)
      this.commonFilterService.performFilterColumnwise('branchId', undefined, this.advanceFilterRequest?.branchId, this.data.agGridDataModel.filterStorageName, 'textFilter');

    if(this.advanceFilterRequest?.invoiceNo != undefined)
      this.commonFilterService.performFilterColumnwise('invoiceNo', undefined, this.advanceFilterRequest?.invoiceNo, this.data.agGridDataModel.filterStorageName, 'textFilter');
    
    if(this.advanceFilterRequest?.againstInvoiceNo != undefined)
      this.commonFilterService.performFilterColumnwise('againstinvoiceno', undefined, this.advanceFilterRequest?.againstInvoiceNo, this.data.agGridDataModel.filterStorageName, 'textFilter');
  
     if(this.advanceFilterRequest?.comment != undefined)
      this.commonFilterService.performFilterColumnwise('comment', undefined, this.advanceFilterRequest?.comment, this.data.agGridDataModel?.filterStorageName, 'textFilter');


    this.commonFilterService.performFilterColumnwise('againstVoucherTypeId', this.advanceFilterRequest.selectedagainstVoucherType, undefined, this.data.agGridDataModel.filterStorageName, '');

    if (this.advanceFilterRequest?.fromDate != undefined)
      this.commonFilterService.performFilterColumnwise('fromDate', undefined, this.advanceFilterRequest?.fromDate, this.data.agGridDataModel.filterStorageName, 'dateFilter');

    if (this.advanceFilterRequest?.toDate != undefined)
      this.commonFilterService.performFilterColumnwise('toDate', undefined, this.advanceFilterRequest?.toDate, this.data.agGridDataModel.filterStorageName, 'dateFilter');

    var filterData = this.commonFilterService.processFilterData(this.data.agGridDataModel.filterStorageName)

    this.advanceFilterRequest.filters = filterData;
    this.activeModal.close(this.advanceFilterRequest)
  }

  clearFields() {
    this.advanceFilterRequest = {};
    this.selectedLedgerIds = [];
    this.selectedLegerTypesIds = [];
    this.selectedagainstVoucherTypeId = [];

    this.storageService.removeItem(this.data.agGridDataModel.filterStorageName);
    const parsedData = JSON.parse(this.data.defaultFilterData.filters);
    parsedData.rules.forEach(rule => {
      if (rule.field === 'fromDate' || rule.field === 'toDate')
        this.commonFilterService.performFilterColumnwise(rule.field, undefined, rule.data, this.data.agGridDataModel.filterStorageName, 'dateFilter');
      else
        this.commonFilterService.performFilterColumnwise(rule.field, undefined, rule.data, this.data.agGridDataModel.filterStorageName, '');
    });
    this.commonFilterService.performFilterColumnwise('ledgerid', [this.data.apiRequestModel.ledgerId], undefined, this.data.agGridDataModel.filterStorageName, '');
    this.storageService.store(this.data.apiRequestStorageName,this.data.apiRequestModel);

    this.setAlreadyAppliedData();
  }
}
