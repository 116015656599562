<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Advance Filter</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'"  class="cross-button" [ngClass]="currentHoverEffect" (click)="activeModal?.dismiss()"></app-svg-icon>
</div>
<div class="modal-body">
    <ng-content></ng-content>
</div>
<div class="modal-footer pt-3 pb-2 mx-2 d-flex align-items-center justify-content-center">
    <app-button class="m-0" [buttonLabel]="'Apply Filter'" (buttonOutputEvent)="onClickApplyFilter();"
        [buttonClass]="'blueText_white_bg_button'" [buttonType]="'submit'">
    </app-button>
    <app-button class="my-0 me-3" (buttonOutputEvent)="onClickClearFilter();" [buttonLabel]="'Clear Filter'"
        [buttonClass]="'common_button d-flex align-items-center justify-content-center pink_button text-nowrap f-14 font-semibold '"
        [buttonType]="'button'">
    </app-button>
</div>