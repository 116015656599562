import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WebcamComponent, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { IconHover } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-cam-view',
  templateUrl: './cam-view.component.html',
  styleUrls: ['./cam-view.component.scss']
})
export class CamViewComponent {
  @ViewChild(WebcamComponent) webcamComponent: WebcamComponent;
  @Output() emitImage = new EventEmitter();
  @Input() data:any = {};
 
  // Available video devices
  public availableVideoInputs: MediaDeviceInfo[] = [];
  private currentDeviceIndex: number = 0;
    // region start ngx web cam code
    stream: any = null;
    status: any = null;
    trigger: Subject<void> = new Subject();

  currentHoverEffect = IconHover.Pink;
  constructor(public activeModal : NgbActiveModal, public toaster : ToastrService)
    {

       // Get available video devices (cameras)
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.availableVideoInputs = mediaDevices;
      // If there are multiple cameras, show a switch button
      if (this.availableVideoInputs?.length > 1) {
        console.log("Multiple cameras found. Switch functionality enabled.");
      }
    });
    }

  get $trigger(): Observable<void> {
    return this.trigger.asObservable();
  }

  captureImage() {
    this.trigger.next();
  }

  closeWebCam(){
    if (this.webcamComponent) this.stopMediaTracks();
    this.activeModal.close(true)
  }

snapshot(event: WebcamImage) {
  this.emitImage.next(event?.imageAsDataUrl);
  const img = {
          id: 0,
          deleted: false,
          added: true,
          edit: false,
          base64Resource : event?.imageAsDataUrl
        }

        if(!this.data?.isMultipleUpload && this.data?.imagesList?.length) this.data?.imagesList?.shift();
        this.data?.imagesList?.push(img);
       this.toaster.success('Your snapshot has been added.');
       if(!this.data?.isMultipleUpload) this.closeWebCam();
}

  stopMediaTracks() {
    if (this.data?.stream && this.data?.stream?.getTracks) {
      this.webcamComponent?.nativeVideoElement?.pause();
      this.data?.stream?.getTracks()?.forEach(track => track?.stop());
      this.data.stream=null;
    }
  }

  switch(event){
    console.log(event)
  }

   // Subject to trigger camera switch
   // Stream to switch between front/back cameras
  private switchCameraSubject: Subject<boolean | string> = new Subject<boolean | string>();
  public switchCameraObservable: Observable<boolean | string> = this.switchCameraSubject.asObservable();



  // Switch camera method
  public switchCam(): void {
    if (this.availableVideoInputs.length > 1) {
      // Cycle through available cameras
      this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.availableVideoInputs.length;
      const nextDeviceId = this.availableVideoInputs[this.currentDeviceIndex].deviceId;

      // Use the switchCameraSubject to switch to the selected camera
      setTimeout(() => {
        this.switchCameraSubject.next(nextDeviceId); // Emit the new device ID to switch camera
      }, 1000);

      // Update the webcamComponent with the latest switchCameraSubject
      this.webcamComponent.switchCamera = this.switchCameraSubject.asObservable();
    } else {
      console.log('No multiple cameras available to switch.');
    }
  }


  // public facingMode: string = 'environment';
  // public get videoOptions(): MediaTrackConstraints {
  //   const result: MediaTrackConstraints = {};
  //   if (this.facingMode && this.facingMode !== '') {
  //     result.facingMode = { ideal: this.facingMode };
  //   }
  //   return result;
  // }
}