import { Component, Input, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/components/Transaction/invoice/invoice.service';
import { IconHover, StorageName } from 'src/app/shared/enum/common-enum';
import { MetalType } from 'src/app/shared/models/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrls: ['./exchange-rates.component.scss']
})
export class ExchangeRatesComponent extends CommonService {
  currentHoverEffect = IconHover.Pink;
  constructor(public activeModal: NgbActiveModal, public toaster: ToastrService,
    public renderer: Renderer2, public notificationService: NotificationService, public storageService: StorageService, public invoiceService: InvoiceService,) { 
    super(renderer);
  }
  insertUpdateCurrencyExchangeRate: any = {};
  filteredMetalDropdown
  @Input() data: any = {};

  ngOnInit(): void {
    this.filteredMetalDropdown = this.data?.getAllDropdownModel?.metalDropdown.filter(item => item?.id == MetalType?.Gold);
  }

  selectedRangeDates(event?: any) {
    this.insertUpdateCurrencyExchangeRate.date = event;
  }

  ngAfterViewInit(): void {
    this.insertUpdateCurrencyExchangeRate = this.data.insertUpdateCurrencyExchangeRate;
  }

  calculateRate()
  {
    this.insertUpdateCurrencyExchangeRate.metalRateInAED =  this.fixWeightDecimal((this.insertUpdateCurrencyExchangeRate.metalRate /  
      this.insertUpdateCurrencyExchangeRate.unitConversionRate) * this.insertUpdateCurrencyExchangeRate.currencyRate);
  }

  Clear() {
    this.insertUpdateCurrencyExchangeRate = {}
  }
  Save() {
    this.invoiceService.insertupdatecurrencyexchangerate(this.insertUpdateCurrencyExchangeRate).subscribe({
      next: (res) => {
        if (res?.isSuccess) {
          this.storageService.store(StorageName.CURRENCY_EXCHANGE,res.response);
          this.toaster.success(res.message);
          this.activeModal.close(res.isSuccess);
        } else {
          this.activeModal.close();
          this.notificationService.errorPopup(res?.errors[0]);
        }
      }, error: (err) => {
        
      }
    });
  }

  OnChangeCurrency(event){
    this.insertUpdateCurrencyExchangeRate.currencyRate = this.data?.getAllDropdownModel?.currencyExchangeDropdown?.find(item => item.id == event)?.extra1 || 0;
  }
  onChangeUnit(event){
    this.insertUpdateCurrencyExchangeRate.unitConversionRate = this.data?.getAllDropdownModel?.unitDropdown?.find(item => item.id == event)?.extra1 || 0;

  }

  

}
