import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpenImagePopupComponent } from 'src/app/shared/common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { commonModalOptions } from 'src/app/shared/models/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { FileCategory, FileFormats, IconHover } from '../../../enum/common-enum';
import { CamViewComponent } from '../cam-view/cam-view.component';
import { NotificationService } from '../../../services/notification.service';
import { ModalPopupSize } from '../../../models/common.model';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss']
})
export class AddImageComponent extends CommonService implements OnInit{
  @Input() displayImage;
  @Input() imagesList = [];
  @Input() alldata:any;
  @Input() data : any ;
  selectedImage : any;
  currentHoverEffect = IconHover.Pink;
  FileCategory = FileCategory;
  FileFormats=FileFormats;
  selectedIndex;
  @ViewChild('videoPlayer') videoPlayer: any;
  constructor(
    public activeModal        : NgbActiveModal,
    public renderer           : Renderer2,
    public toaster            : ToastrService,
    public compressImageService :CompressImageService,
    private modalService: NgbModal,
    public notificationService: NotificationService,
    private cdr: ChangeDetectorRef
    )
    {
      super(renderer)
    }
  ngOnInit(): void {
    const updatedArray = this.data?.imagesList.map(obj => {
      if(obj.imageUrl){
        let {imageUrl , ...rest} = obj
        return { base64Resource: obj.imageUrl , ...rest};
      }else {
        return obj;
      }
    });

    this.imagesList  =  updatedArray?.filter((item) => item?.base64Resource && !item?.base64Resource?.includes('/default.png'));
    this.displayImage = this.imagesList[this.imagesList?.length - 1]?.base64Resource;
    this.selectedIndex = this.imagesList?.length ? this.imagesList?.length - 1 : null;
  }

  showPreviewImage(img,i){
    this.displayImage = img?.base64Resource;
    this.selectedIndex = i;
    this.videoPlayer?.nativeElement?.load();
    this.videoPlayer?.nativeElement?.play();
    this.cdr?.detectChanges();
  }
  

  removeImage(index ,file) {
   if(! this.data?.isAction || !file.id)  this.imagesList?.splice(index,1);
    if(this.data?.isAction && file.id){
      file.deleted = true;
      file.imageUrl = null;
    }
    const list  = this.imagesList?.filter((item)=> item?.deleted != true);
    list.length ? this.displayImage = list[list?.length-1]?.base64Resource : this.displayImage = null;
    list.length ? this.selectedIndex = list[list?.length-1] : this.selectedIndex = null;
  }

  saveImages(){
    this.activeModal.close(this.imagesList ?? []);
  }

  onSelect(event) {
    if(event.addedFiles.length>1){
      this.resourceMultipleFile(event.addedFiles)
      }else{
        this.resourceFile(event.addedFiles[0])
      }
  }
  
  resourceFile(item:any){
    this.processFile(item);
  }
  
  resourceMultipleFile(items:any){
    for(const file of items)
    this.processFile(file)
  }

  convertMBToBytes(sizeInMB: number): number {
    return sizeInMB * 1024 * 1024;
  }
  
  processFile(file: any) {
    if( file?.size > this.convertMBToBytes(25) ) {
      this.toaster?.error('The file should not be greater than 25mb');
      return;
    }
    this.compressImageService.compress(file).subscribe(
      compressedFile => {
     this.readThis(compressedFile).then((base64String) => {
        if (base64String) {
          const img = {
            id: 0,
            deleted: false,
            added: true,
            edit: false,
            base64Resource : base64String
          }
          if(!this.data?.isMultipleUpload && this.imagesList?.length) this.imagesList?.shift();
          this.imagesList.push(img);
          if(!this.data?.isMultipleUpload && this.imagesList?.length){
            this.displayImage = this.imagesList[0]?.base64Resource ?? null;
            this.selectedIndex = this.displayImage ? 0 : null;
          } 
            

        } else {
          console.error("Failed to get base64 string.");
        }
      })
      .catch((error) => {
        console.error("Error processing file:", error);
      });
    },
    error => {
      console.error('Error compressing file:', error);
    }
  );
  }

  openImgPopUp(){
    if (!this.imagesList.length) return;
    var data = {
      jewelryCatalogue : [... this.imagesList],
      activeSlideId : this.selectedIndex
    }
    this.notificationService.openModalPopup(OpenImagePopupComponent , data , '' , ModalPopupSize.SM,'image_popup_fullscreen',true,true).then(
      (result)=>{

      }
    )
  }

  checkPermissions() {
    navigator.mediaDevices.getUserMedia({
      video: {
        width: 200,
        height: 200
      }
    }).then((res) => {
      setTimeout(() => {
        this.openCamViewPopUp(res );
      }, 100);
    }).catch(err => {
      if (err?.message === 'Permission denied') {
        this.toaster.error('Permission denied please try again by approving the access');
      } else {
        this.toaster.error('You may not having camera system, Please try again ...');
      }
    })
  }

  openCamViewPopUp(stream){
    var data ={ stream, imagesList : this.imagesList, isMultipleUpload:this.data?.isMultipleUpload }
    this.notificationService?.openModalPopup(CamViewComponent ,data ,"",ModalPopupSize.MD , "camview_popup").then((res)=>{
      if(!this.data?.isMultipleUpload && this.imagesList?.length) this.displayImage = this.imagesList[0]?.base64Resource ?? null;
    })
  } 

  // ----------------------------------------  camera view -------------

  //#region for upload different files 


  detectBase64Type(base64String) {
    const match = base64String.match(/^data:(.+?);base64,/);
    return match ? match[1] : null; // Returns the MIME type
}

isBase64Url(url) {
  const base64Pattern = /^data:(.+?);base64,[a-zA-Z0-9+/]+={0,2}$/;
  return base64Pattern.test(url);
}

   openFileInNewTab(Url: string, fileType: string) {
    if (Url.startsWith('Uploads/')) {
      const fullUrl = `${environment.baseUrl}${Url}`;
      window.open(fullUrl, '_blank');
    } else {
      const base64Content = Url?.split(';base64,')[1];
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters?.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: fileType });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
  }

//#endregion 

}
