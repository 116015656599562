import { Component, Input, Renderer2, ViewChild ,ElementRef, AfterViewInit, OnDestroy, HostListener} from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MetalSectionService } from './metal-section.service';
import { KeyCode, StorageName } from '../../enum/common-enum';
import { MetalSectionChildComponent } from '../metal-section-child/metal-section-child.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage.service';
import { CustomSelect2Component } from '../custom-select2/custom-select2.component';
import { Subscription } from 'rxjs';
import { ModalPopupSize, VoucherTypeName } from '../../models/common.model';
import { NotificationService } from '../../services/notification.service';
import { MetalSectionChildAppraisalComponent } from '../metal-section-child-appraisal/metal-section-child-appraisal.component';

@Component({
  selector: 'app-metal-section',
  templateUrl: './metal-section.component.html',
  styleUrls: ['./metal-section.component.scss']
})
export class MetalSectionComponent extends  CommonService  implements  AfterViewInit, OnDestroy  {
  @ViewChild(MetalSectionChildComponent) metalSectionChildComponent: MetalSectionChildComponent;
  @ViewChild(CustomSelect2Component) customSelect2Component: CustomSelect2Component;
  @Input() formName:string;
  @Input() getAllDropdownModel  : any = {};
  isPopupOpen: boolean = false;
  @Input() formModel:any;
  @Input() metalSectionModel:any;
  storage = StorageName;
  onclearDesignNoSubscription: Subscription;
  voucherTypeName : typeof VoucherTypeName = VoucherTypeName;
  constructor(
    public renderer: Renderer2,
    public modalService: NgbModal,
    public storageService: StorageService,
    public metalSectionService: MetalSectionService,
    public notificationService : NotificationService
  ) {
    super(renderer);
    this.onclearDesignNoSubscription = this.storageService.onclearDesignNo.subscribe((result) => {
      if (result) {
       this.resetdesignNoDropdown();
      }
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.keyCode === KeyCode.Q) {
      event.preventDefault();
      if (!this.isPopupOpen) {
        this.openMetalSectionPopup(undefined);
      }
    }
  }

  ngOnDestroy(): void {
    this.onclearDesignNoSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.initialCalls();
  }
  
  initialCalls(){
    setTimeout(() => {
    this.getAllDropdownModel.designNoDropdown = this.storageService.retrieve(StorageName.DESIGN_NO_DROPDOWN);
    this.customSelect2Component?.setDataSource(this.getAllDropdownModel?.designNoDropdown);
  }, 1000);
  }
  
  assignDesignNo(event){
    this.metalSectionModel.designNo =event;
    if(this.metalSectionModel.designNo !=='')
    this.metalSectionService?.openMetalSectionPopup(this.metalSectionModel,this.formModel,this.formName,'',this.metalSectionModel?.designNo);
  }

  resetdesignNoDropdown(){
    this.customSelect2Component?.setDataSource(this.getAllDropdownModel?.designNoDropdown ,'0');
  }

  openMetalSectionPopup(previousRow) {
    if(this.formModel.voucherType === VoucherTypeName.Appraisal){
      this.openMetalSectionPopupAppraisal(previousRow)
    }else{
      this.metalSectionModel.previousRow = previousRow;
      this.isPopupOpen = true; 
      this.metalSectionService.openMetalSectionPopup(this.metalSectionModel, this.formModel, this.formName)
        .then(() => { this.isPopupOpen = false; })
        .catch(() => { this.isPopupOpen = false; });
    }
   
  }

  getProductDataByBarcodeNo()
  {
    if(this.formModel.voucherType === VoucherTypeName.Appraisal){
      this.openMetalSectionPopupAppraisal()
    }else{
      this.metalSectionService.openMetalSectionPopup(this.metalSectionModel,this.formModel,this.formName,this.metalSectionModel.barcodeNo);
    }
  }

  clearBarcode(){
    this.metalSectionModel.barcodeNo='';
  }

  openModalBarcodePopup(): void {
    this.metalSectionChildComponent.openModalBarcodePopup();
  }

  setColumnsVisible(columnnames : any = undefined,visible : boolean = false)
  {
    this.metalSectionModel.columnForVisibility = columnnames;
    this.metalSectionModel.columnsVisible = visible;
    this.metalSectionChildComponent.setColumnsVisible();
  }

  checkAnyGridHasRowWithTabs() : any
  {
    return this.metalSectionChildComponent.checkAnyGridHasRowWithTabs();
  }

  internalFixChecked(value)
  {
      this.formModel.invoiceModel.internalFix = value;
  }
  metalFixChecked(value)
  {
    this.formModel.invoiceModel.metalFix = value;
  }
  vatChecked(value)
  {
    this.formModel.invoiceModel.vat = value;
  }

  KYCChecked(value)
  {
    this.formModel.invoiceModel.isKYC = value;
  }

  AMLChecked(value)
  {
    this.formModel.invoiceModel.isAML = value;
  }

// ************Appraisal**********
  openMetalSectionPopupAppraisal(previousRow? ,barcodeNo?){
    this.metalSectionModel.previousRow = previousRow;
    var data = { getAllDropdownModel:this.getAllDropdownModel ,formModel:this.formModel ,metalSectionModel:this.metalSectionModel}
    this.notificationService.openModalPopup(MetalSectionChildAppraisalComponent,data, 'Enter your item', ModalPopupSize.XL, 'print-popup' , false , true).then(
      (result) => {
        if (result) {
         
        }
      }
    );
  }

}

