import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit {
  @Input() data : any
  constructor(public activeModal:NgbActiveModal){
  }

  ngOnInit(): void {
    const element = document.querySelector('.custom_focus') as any;
    element?.focus();
  }

  onSave(){
    this.activeModal.close(true);
}

  onKeyDown(event: KeyboardEvent | any) {
    event?.srcElement?.parentElement?.click();
  }

}
