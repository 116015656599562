<div class="d-flex justify-content-center gap-1"
  *ngIf="!params.Loanssvg && !params.PrintSvg && !params?.data?.isFooterRow &&  !params?.data.isSumRow">
 <div>
  <div class="d-flex  justify-content-center">
    <app-svg-icon style="cursor: pointer;" (click)="navitageToOrderTracking(params)" name="track_order"
      class="track_order trackOrderRenderer" container="body" ngbTooltip="Order Tracking"
      placement="top"></app-svg-icon>
  </div></div>
  <div [ngClass]="{'pe-none opacity-50': isIconDisabled }" class="d-flex gap-1">
    <div *ngIf="params.Barcode" [ngClass]="{'pe-none opacity-50': !params?.data?.barcodeNo }"
      (click)="onClickBarcodeIcon(params)" container="body" ngbTooltip="Barcode Print" placement="top">
      <app-svg-icon style="cursor: pointer;" name="barcodeprint"></app-svg-icon>
    </div>
    <div *ngIf="params.isZebra" (click)="onClickZebraprintIcon(params)"
      [ngClass]="{'pe-none opacity-50': saleOrderProcessService?.isDisabledZebraIcon}" container="body"
      ngbTooltip="Zebra ZPL" placement="top">
      <app-svg-icon style="cursor: pointer;" name="zebraprint"></app-svg-icon>
    </div>
    <div *ngIf="params?.isDocumentFileIcon && !params?.data?.isFooterRow && !params?.data.isSumRow"  (click)="onClickDocumentFileIcon(params)"
      [ngClass]="{'pe-none opacity-50': !(params?.data?.documentCount)}" container="body"
      ngbTooltip="Download Files" placement="top" >
      <app-svg-icon class="cursorPointer " container="body"  ngbTooltipClass="custom-tooltip" name="kycDetails_icon"></app-svg-icon>
    </div>
  </div>
</div>

<div *ngIf="params.Loanssvg && !params.PrintSvg && !params?.data?.isFooterRow &&  !params?.data.isSumRow" class="d-flex gap-1">
  <div>
    <app-svg-icon style="cursor: pointer;" name="expandloans" (click)="onClickExpandLoans(params)"></app-svg-icon>
  </div>
  <div>
    <app-svg-icon style="cursor: pointer;" name="addonloans" (click)="AddOnLoans()"></app-svg-icon>
  </div>
  <div>
    <app-svg-icon style="cursor: pointer;" name="percentage" (click)="onClickPayInterest()"></app-svg-icon>
  </div>
  <div>
    <app-svg-icon style="cursor: pointer;" (click)="EditLoans(params)" name="editrow"></app-svg-icon>
  </div>
  <div>
    <app-svg-icon style="cursor: pointer;"  (click)="openDeleteConfirmationPopup(contents,params)" name="deleterows"></app-svg-icon>
  </div>
</div>

<div *ngIf="params.PrintSvg && params?.data?.diamondCategory === 'Loose'" class="cursorPointer images_renderer"  (click)="onClickPrintIcon(params)">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_45715" data-name="Group 45715" transform="translate(-1830.5 -402)">
        <g id="Layer_x0020_1" transform="translate(1836.051 405.886)">
          <path id="Path_35321" data-name="Path 35321" d="M10.668,15.427H2.231A1.358,1.358,0,0,1,.8,14V2.2A1.358,1.358,0,0,1,2.231.771H8.067V2.96a1.949,1.949,0,0,0,.42,1.388,1.786,1.786,0,0,0,.506.414,1.489,1.489,0,0,0,.723.2H12.1V13.84a1.58,1.58,0,0,1-.356,1.135,1.378,1.378,0,0,1-1.071.451Zm-7.423-1.9H9.685c.5,0,.5-.793.032-.793H3.182a.4.4,0,0,0,.063.793ZM6.037,10,4.46,8.344a.394.394,0,0,0-.707.231c0,.241.117.277.235.431l2.193,2.343c.441.33.669-.182,1.005-.514a3.669,3.669,0,0,0,.337-.361c.1-.127.233-.229.346-.352.071-.077.082-.113.158-.191L9.043,8.853a.4.4,0,1,0-.632-.505C8.1,8.725,7.275,9.53,7.079,9.776,7,9.871,6.96,9.968,6.83,10V6.291a5.451,5.451,0,0,0-.006-.628.4.4,0,0,0-.742-.056,12.761,12.761,0,0,0-.045,1.858V10Zm2.855-8.6,2.133,2.213c.068.078.08.112.158.191a2.6,2.6,0,0,1,.342.356,18.22,18.22,0,0,1-1.962-.037.88.88,0,0,1-.435-.263c-.27-.289-.236-.57-.236-1.1V1.406ZM.01,1.977V14.221A2.164,2.164,0,0,0,1.2,16.009a1.987,1.987,0,0,0,.8.211h8.819a2.148,2.148,0,0,0,2.062-2.157V4.578c0-.261-.442-.594-.707-.911l-.554-.556c-.063-.068-.067-.091-.127-.159l-.412-.413c-.112-.111-.165-.2-.269-.3-.376-.381-.719-.744-1.1-1.125L9.185.541C9.137.493,9.1.464,9.058.415c-.067-.072-.3-.4-.452-.4H1.818A2.146,2.146,0,0,0,.01,1.977Z" fill="#5843cf" fill-rule="evenodd"/>
        </g>
        <path id="Rectangle_17868" data-name="Rectangle 17868" d="M5,1A4,4,0,0,0,1,5V19a4,4,0,0,0,4,4H19a4,4,0,0,0,4-4V5a4,4,0,0,0-4-4H5M5,0H19a5,5,0,0,1,5,5V19a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(1830.5 426) rotate(-90)" fill="#5843cf"/>
      </g>
  </svg>      
</div>

<!-- Day Report Expand Start -->
<div *ngIf="params.expandSvg">
  <div class="d-flex px-2">
    <app-svg-icon *ngIf="!params?.data?.isRowExpandable" [name]="'expandOpen'" [ngClass]="{
        'cursorPointer': params?.data?.invoiceCount !== 0,
        'cursorNotAllowed': params?.data?.invoiceCount === 0
      }" class="d-flex justify-content-center" (click)="params?.data?.invoiceCount !== 0 && onClick(params)">
    </app-svg-icon>
    <app-svg-icon *ngIf="params?.data?.isRowExpandable" [name]="'expandClose'" [ngClass]="{
        'cursorPointer': params?.data?.invoiceCount !== 0,
        'cursorNotAllowed': params?.data?.invoiceCount === 0
      }" class="d-flex justify-content-center" (click)="params?.data?.invoiceCount !== 0 && onClick(params)">
    </app-svg-icon>
    <span class="mx-2">{{ params?.value }}</span>
  </div>
</div>
<!-- Day Report Expand End -->
<!-- Delete Cofirmation  modal -->
<ng-template #contents let-modal>
  <div class="modal-body p-2">
      <div class="row">
        <div class="col d-flex justify-content-end  my-1 mx-1"><app-svg-icon [name]="'cross'" tabindex="0" [ngClass]="currentHoverEffect" class="cross-button cursorPointer" (click)="modal.dismiss('Cross click')"></app-svg-icon></div>
          <div class="col-12 d-flex justify-content-center my-2">
              <img src="./assets/SVGicon/delete-confirm-icon.svg" alt="">
          </div>
          <div class="col-12 ">
              <div class="d-flex justify-content-center">
                  <h5 class="text-gray-color f-16 mb-0  font-semibold ">Deleting</h5>
              </div>
              <div class="d-flex justify-content-center">
                  <p class="theme-color-secondary f-13 w-p-200 mt-1 text-center font-regular mb-0">Are You Sure You Want To Delete Loan Entry From This List?</p>
              </div>
          </div>
      </div>
  </div>
  <div class="modal-footer border-0">
    <div tabindex="0" class="col d-flex align-items-center justify-content-center cursorPointer m-0"  (click)="deleterowdata(params,modal)"><span class="font-semibold px-2 f-16">DELETE</span></div>
    <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="modal.dismiss('Cross click')"><span tabindex="0" class=" px-2 font-semibold f-16">CANCEL</span></div>
 </div>
</ng-template>
<!-- end -->