import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { SendDataService } from '../../services/send-data.service';
import { CommonService } from '../../services/common.service';
import { GetAllBreakTypeResponseModel } from 'src/app/components/administration/administration.model';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-break-type',
  templateUrl: './break-type.component.html',
  styleUrls: ['./break-type.component.scss']
})
export class BreakTypeComponent extends CommonService implements OnInit {
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllBreakType             : any[]=[];
  copyGetAllBreakType          : any[]=[]
  editBreakType               = new GetAllBreakTypeResponseModel();
  isAdd                       : boolean = false;
  
constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllBreakTypeList();
}

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return; if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, name:'',active:true}
  this.getAllBreakType?.push(obj);
  this.copyGetAllBreakType?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`breaktypename${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllBreakType = this.getAllBreakType.filter((x)=>x.id != 0);
    this.copyGetAllBreakType = this.copyGetAllBreakType.filter((x)=>x.id != 0);
  }
  else if(this.editBreakType?.id && this.editBreakType?.isEdit){
    const index = this.copyGetAllBreakType.findIndex((x)=>x.id == this.editBreakType?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllBreakType))[index];
    this.getAllBreakType[index] = singleData;
    this.editBreakType = new GetAllBreakTypeResponseModel();
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
   this.editBreakType  = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

  // *********** Break Type API START *********
  // **********get all Break Type *********
  getAllBreakTypeList() {
    this.administrationService.getAllBreakType().subscribe({
      next:(res)=>{
        if(res.isSuccess){
            this.getAllBreakType = res.response??[];
            this.editBreakType = new GetAllBreakTypeResponseModel();
            this.copyGetAllBreakType = JSON.parse(JSON.stringify(this.getAllBreakType.filter((x)=>x.active == true)))??[];
          }else{           
          this.getAllBreakType = [];
          this.copyGetAllBreakType = [];
        }  
      },
      error:(err)=>{
        this.getAllBreakType = []; 
        this.copyGetAllBreakType = [];
      }
    })
  }

 // *************insert Break Type **************
  InserBreakTypeList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertBreakType(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllBreakTypeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update Break Type *********
    UpdateBreakType(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateBreakType(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            console.log(res?.message)
            this.isAdd = false;
            this.getAllBreakTypeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete Break Type *********
    DeleteBreakType(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.deleteBreakType(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllBreakTypeList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** BreakType API END *********
}
