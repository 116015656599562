import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconHover } from '../../../../enum/common-enum';

@Component({
  selector: 'app-sales-mode',
  templateUrl: './sales-mode.component.html',
  styleUrls: ['./sales-mode.component.scss']
})
export class SalesModeComponent implements OnInit{
  constructor(public activeModal: NgbActiveModal) {}
  currentHoverEffect = IconHover.Pink;
  selectedOption: string = '';
  voucherTypeDropdown:any[];

  dropdownOptions = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' }
  ];

ngOnInit(): void {
  this.voucherTypeDropdown;
}

  onDropdownChange(value: string) {
  }
}
