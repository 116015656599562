<div class="modal-header d-flex align-items-center justify-content-center position-relative">
  <div><span class="font-semibold text-primary">Enter your item</span></div>
  <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
  <app-svg-icon [name]="'cross'" class="cross-button" [ngClass]="currentHoverEffect"
    (click)="activeModal?.dismiss()"></app-svg-icon>
</div>
<div class="modal-body">
  <div id="metalsection_appraisaltabs" class="col-12 d-flex align-items-center justify-content-center">
    <ul ngbNav #nav="ngbNav" [(activeId)]="tabId"  (navChange)="onTabChange($event)" class="nav-tabs gap-0">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="d-flex">
          <span class="d-flex justify-content-center f-14 font-semibold">Jewellery</span>
        </a>  <ng-template ngbNavContent></ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="d-flex">
          <span class="d-flex justify-content-center f-14 font-semibold">Diamond & GemStone</span>
        </a>  <ng-template ngbNavContent></ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="d-flex">
          <span class="d-flex justify-content-center f-14 font-semibold">Summary</span>
        </a>  <ng-template ngbNavContent></ng-template>
      </li>
    </ul>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2 flex-grow-1"></div>
</div>

<div class="row" style="height: 430px;">
  <div class="col-10">
    <app-app-metal-appraisal #jewelryAppraisal [tabName]="'jewelry'" [data]="data"   [tabId]="tabId" (addRowToMetalGrid)="onAddRowToMetalGrid($event)"  (tabChange)="tabChange($event)" [ngClass]="{'d-none':tabId == appraisalTabEnum.DiamondAndGemstone || tabId == appraisalTabEnum.Summary}"></app-app-metal-appraisal>
    <app-app-metal-appraisal #diamondAppraisal [tabName]="'DiamondAndGemstone'" [data]="data"  [tabId]="tabId" (addRowToMetalGrid)="onAddRowToMetalGrid($event)"  (tabChange)="tabChange($event)"  [ngClass]="{'d-none':tabId == appraisalTabEnum.Jewellery || tabId == appraisalTabEnum.Summary}"></app-app-metal-appraisal>
    <app-app-metal-appraisal #summaryAppraisal [tabName]="'summary'" [data]="data" [tabId]="tabId" (addRowToMetalGrid)="onAddRowToMetalGrid($event)" [ngClass]="{'d-none': tabId == appraisalTabEnum.DiamondAndGemstone || tabId == appraisalTabEnum.Jewellery}"></app-app-metal-appraisal>
  </div>
 
 <div class="border-gary-left col-2">
    <div class="px-0 d-flex flex-column images border">
      <div class="d-flex px-3 py-2 justify-content-between align-items-center">
          <div class="col-4 text-primary ps-0  justify-content-start d-flex align-items-center mb-0 font-semibold f-14">  
              <h6 class="mb-0 img-text">
                  Images
              </h6>
          </div>
          <div class="col-6 d-flex justify-content-end">
              <span *ngIf="excludeDeletedResourcesLength()" (click)="openAddImagePopup()" >
                  <img
                    class="cursorPointer"
                    src="./assets/images/svg-icon/cam_icon.svg"
                    alt="camera icon"
                  />
                </span>
          </div>
      </div>
      <div class="theme-bg-lightergray-color d-flex h-100">
          <div class="custom-dropzone col d-flex flex-column">
              <div class="col flex-column" [ngClass]="{'d-flex':excludeDeletedResourcesLength()}" >
                  <div *ngIf="!excludeDeletedResourcesLength()" class="col d-flex cursorPointer h-260 tabel-bg-color border-0 mt-0 mx-3 mb-3 justify-content-center" (click)="openAddImagePopup()">
                  <app-svg-icon *ngIf="!excludeDeletedResourcesLength()" class="d-flex cursorPointer" [name]="'upload_Icon'"></app-svg-icon>
                  </div>
                  <div class="h-260 w-100" *ngIf="excludeDeletedResourcesLength()">
                      <img *ngIf="excludeDeletedResourcesLength() > 0" (click)="openImgPopUp()" [src]="isBase64Image(selectedImage)" alt="img" class="h-100 cursorPointer w-100">
                  </div>
                  <div class="row px-4 pb-2 overflow-auto flex-fill gap-1 child_item_img_container h_9rem" *ngIf="excludeDeletedResourcesLength() ">
                      <ng-container *ngFor="let img of imageResourceList;let i = index" >
                         
                              <div *ngIf="!img?.deleted"
                                  class="tabel-bg-color child_image  mt-2 px-0 rounded h-auto justify-content-center position-relative" >
                                  <img [src]="isBase64Image(img?.imageUrl)"  alt="img" (click)="previewImg(img)" class="w-100 h-100 cursorPointer yyy">

                                  <div (click)="onRemove(img , i)"
                                      class="position-absolute top-0 end-0 m-1 cursorPointer cross-icon">
                                      <app-svg-icon class="" [name]="'cross'"></app-svg-icon>
                                  </div>
                              </div>
                         
                      </ng-container>
                  </div>
              </div>
          </div>
      </div>
  </div>
 </div>
</div>
<div class="row px-2 mb-2 mt-2">
  <div class="col-12 d-flex align-items-center justify-content-between px-2 py-1 bg-light-voilet-color black-color font-semibold">
    Item Details
  </div>
</div>

<form >
  <div class="row mx-0 gap-2 d-flex flex-column h-100">
    <div class="col flex-fill p-0">
        <app-ag-grid 
        [id]="'AppraisalHeight'"
        [dataModel]="agGridDataModel"
        (gridReady)="onGridReady($event)"
        [columnSettings]="true"
        [rowData]=[]
        (onEditIconClick)="onEditIconClick($event)" 
        [rowHeight]="27">
        </app-ag-grid>
    </div>
  </div>
  <div class="row mx-0">
      <div class="col-12 overflow sale_bill_one px-0">
          <table class="table table-bordered table-scroll border-right">
              <tbody>
                  <tr class="">
                      <td width="7%">
                          <div class="table_text text-nowrap font-semibold f-14">Group Name</div>                                    </td>
                      <td width="20%">
                          <app-input 
                          [inputName]="'groupName'" 
                          [inputType]="'text'"
                          [inputId]="'groupName'" 
                          [inputValue]="data?.formModel?.groupName" 
                          (inputValueEmit)="data?.formModel.groupName = $event" 
                          [isRequired]="false"
                          (keydown.enter)="onKeydown($event, 'comment')"
                         >
                      </app-input>
                      </td>
                      <td width="5%">
                          <div class="table_text font-semibold f-14">Comment</div>
                      </td>
                      <td width="40%">
                          <app-input 
                          [inputName]="'comment'" 
                          [inputType]="'text'"
                          [inputId]="'comment'" 
                          [inputValue]="data?.formModel?.comment" 
                          (inputValueEmit)="data?.formModel.comment = $event" 
                          [isRequired]="false"
                         >
                      </app-input>
                      </td>
                      <td width="9%">
                        <app-button class="m-0" [buttonLabel]="'Cancel'" (buttonOutputEvent)="Cancel()" [buttonClass]="'blue_button'" [buttonType]="'submit'">
                        </app-button>
                       
                      </td>
                      <td width="9%">
                        <app-button class="m-0" [buttonLabel]="'Done'" (buttonOutputEvent)="addProductsInItemList()" [buttonClass]="'bluebutton'" [buttonType]="'submit'">
                        </app-button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</form>