import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrderTrackingComponent } from 'src/app/components/orders/order-tracking/order-tracking.component';
import { SaleOrderProcessService } from 'src/app/components/orders/sale-order-process/sale-order-process.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';
export interface Icons{
  Barcode : boolean;
  isZebra: boolean;
  Loanssvg: boolean;
  isDocumentFileIcon:boolean;
 }
@Component({
  selector: 'app-track-order-cellrender',
  templateUrl: './track-order-cellrender.component.html',
  styleUrls: ['./track-order-cellrender.component.scss']
})
export class TrackOrderCellrenderComponent {
  params:any;
  PrintSvg:boolean = false
  @Input() isIconDisabled:boolean=false;

  constructor( 
    private storageService    : StorageService,
    private router                : Router, 
    public notificationService: NotificationService,
    private modalService          : NgbModal,
    private saleOrderProcessService : SaleOrderProcessService,
    ){}


  agInit(params: any): void {
    this.params = params;
    this.isIconDisabled = this.params.isIconDisabled !== undefined ? this.params.isIconDisabled : false; 

  }
 
  refresh(params: any): boolean {
    return false;
  }

  navitageToOrderTracking(params)
  {;
     this.openOrderTrackingPopUp(params)
  }

  openOrderTrackingPopUp(params){
    var data = { hide_Header: true, paramsData: params?.data }
    this.notificationService.openModalPopup(OrderTrackingComponent, data, 'Advance Filter').then(
      (resultData) => {
        if (resultData) {
          
        }
      }
    );
  }
  onClickDocumentFileIcon(params){
    const fileUrl = params?.data?.documentUrl || params?.data?.documentPath;
    if (fileUrl) {
        const customEvent = new CustomEvent('downloadIconClick', {
            detail: { data: params }
        });
        this.params.api.dispatchEvent(customEvent);
    } 
  }

  onClickBarcodeIcon(params){
    const customEvent = new CustomEvent('barcodeIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
   // this.storageService.cellRendererIconClicked.next(this.params)
  }
  onClickZebraprintIcon(params){
    const customEvent = new CustomEvent('printButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }
  onClickExpandLoans(params){
    const customEvent = new CustomEvent('expandableIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  AddOnLoans(){
    this.storageService.AddOnLoansClick.next(this.params)
  }
  EditLoans(params){
    const customEvent = new CustomEvent('editIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }
  onClickPayInterest(){
    this.storageService.PayInterestClick.next(this.params)
  }
  onClickPrintIcon(params) {
    
    const customEvent = new CustomEvent('printButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  // openDeleteConfirmationPopup(content,params)
  // {
  //   let pay = params
  //    this.notificationService.openModalPopup(content, { windowClass: "delet-confirm-popup-w" , centered:true,  backdrop: "static",
  //   });
  // }


  openDeleteConfirmationPopup(content,params) {
    let pay = params?.data
    this.notificationService.confirmationPopup('Deleting', 'Are You Sure You Want To Delete Loan Entry From This List?', 'delete-confirm', false, 'Delete', 'Cancel', true).subscribe((result) => {
      if (result.isConfirmed) {
        this.deleterowdata(params)
      } else if (result.isDismissed) {
      }
    });
  }

  deleterowdata(params)
  {
    params.api.deselectAll();
    params.node.setSelected(true);
     const selectedNode = params.api.getSelectedRows();
   //  modal?.dismiss('Cross click');
     this.params.api.applyTransaction({ remove: selectedNode});
     params.api.deselectAll();

     const custoDeleteEvent = new CustomEvent('deleteButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(custoDeleteEvent);
  }
  

  onClickExpandOpen(params){
    const customEvent = new CustomEvent('expandableIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  onClickExpandClose(params){
    const customEvent = new CustomEvent('expandableIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  onClick(params){
    if( params.isCursor)
    {
      const customEvent = new CustomEvent('textCellClick', {
        detail : { data: this.params },
      });
      this.params.api.dispatchEvent(customEvent);
    }
  }

}
