import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbInputDatepicker, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage.service';
import { FinancialStatementService } from '../../../components/financial-statement/financial-statement.service';

export class SelectedDates {
	startDate: NgbDate;
	endDate: NgbDate;
}

@Component({
	selector: 'app-range-datepicker',
	templateUrl: './range-datepicker.component.html',
	styleUrls: ['./range-datepicker.component.scss'],
	viewProviders: [DatePipe],
	encapsulation: ViewEncapsulation.None,
})
export class RangeDatepickerComponent implements OnChanges {

	maxDate: NgbDateStruct;
	minDate: NgbDateStruct;

	hoveredDate: NgbDate | null = null;
	currentDate: string;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;

	inputDate: string = "";
	@ViewChild("datepicker") dp: NgbInputDatepicker;
	@Input() defaultFinancialYear: boolean = false;
	@Input() startDate;
	@Input() endDate;
	@Input() isResetButton = true;
	@Output() startDateEmit = new EventEmitter();
	@Output() endDateEmit = new EventEmitter();
	@Output() applyDate = new EventEmitter();

	constructor(public formatter: NgbDateParserFormatter, public datePipe: DatePipe, private storageService: StorageService, public financialStatementService: FinancialStatementService) {
		// this.onClear();
		// this.getAndAssignFinancialDate();
	}

	parseDateStringToNgbDate(dateString: string): NgbDate {
		const dateParts = dateString.split('-');
		if (dateParts.length === 0)
			return new NgbDate(+'yyyy', +'MM', +'dd');
		return new NgbDate(+dateParts[0], +dateParts[1], +dateParts[2]);
	}

	ngAfterViewInit(): void {
		const currentDate = new Date().toISOString().split('T')[0];
		this.startDate = this.parseDateStringToNgbDate(currentDate);
		this.endDate = this.parseDateStringToNgbDate(currentDate);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const dt1 = this.formatter?.parse(this.startDate) as any;
		const dt2 = this.formatter?.parse(this.endDate) as any;
		this.fromDate = dt1 ? dt1 : null;
		this.toDate = dt2 ? dt2 : null;

		if (!this.startDate || !this.endDate) {
			this.inputDate = null;
		} else {
			this.inputDate = this.startDate + ' - ' + this.endDate;
		}
	}

	// ngOnInit(): void {
	// 	if (this.defaultFinancialYear)
	// 		this.onApplyFinancialYear();
	// 	else
	// 		this.onToday();
	// }

	getFormattedDate(date: Date): string {
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		return `${year}-${month}-${day}`;
	}

	convertToNgbDateStruct(date: any): any {
		if (date.year)
			return date;
		if (this.isString(date))
			return date;
		return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
	}

	 isString(value: any): boolean {
		return typeof value === 'string';
	  }

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && (date.equals(this.fromDate) || date.after(this.fromDate))) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
		this.onApply(false, this.fromDate, this.toDate);
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	isStartDate(date: NgbDate) {
		return date.equals(this.fromDate);
	}

	isEndDate(date: NgbDate) {
		return date.equals(this.toDate);
	}

	onToday() {
		const today = new Date();
		this.onApply(true, today, today);
	}

	onApply(isClose = true, fromDate = undefined, toDate = undefined) {
		if (fromDate != undefined && toDate != undefined) {
			if (this.isString(fromDate) && this.isString(toDate)) {
				this.fromDate = this.parseDateStringToNgbDate(fromDate);
				this.toDate = this.parseDateStringToNgbDate(toDate);
			}
			else {
				this.fromDate = this.convertToNgbDateStruct(fromDate);
				this.toDate = this.convertToNgbDateStruct(toDate);
			}
		}
		
		this.inputDate = this.formatter.format(this.fromDate) + ' - ' + this.formatter.format(this.toDate);
		this.startDateEmit.emit(this.fromDate);
		this.endDateEmit.emit(this.toDate);

		if (isClose) {
		this.applyDate.emit();
			this.dp?.close();
		}
	};

	closed(e){
		if (this.fromDate && !this.toDate){
			this.onApply(false, this.fromDate, this.fromDate);
		}
	}

	onApplyFinancialYear() {
		let financialYearDates = this.storageService.retrieve('financialYear');
		this.onApply(true, new Date(financialYearDates?.financialYearStartDate), new Date(financialYearDates?.financialYearEndDate));
	}

	getAndAssignFinancialDate() {
		if (!this.defaultFinancialYear)
			this.onToday();
		else if (this.fromDate && this.toDate)
			this.onApply(false, this.fromDate, this.toDate);
		else
			this.onApplyFinancialYear();
	}
}
