<div class="modal-header d-flex align-items-center justify-content-center position-relative">
  <div><span class="font-semibold text-primary">Exchange Rate</span></div>
  <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
  <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button"
    (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form>
  <div class="modal-body mx-2">
    <div class="row px-2">
      <div class="w-140 col-12 mb-2 p-0 d-flex align-items-center">
        <div class="">
          <label for="date" class="f-14 w-90px font-semibold text-gray-color text-nowrap mb-0">Date</label>
        </div>
        <div class="w-100">
          <app-input-datepicker-renderer [id]="'date'"
            (dateChanged)="selectedRangeDates($event)"></app-input-datepicker-renderer>

        </div>
      </div>
      <div class="col-6 d-flex align-items-center  mb-2 px-0 pe-2">
        <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="metal">Metal</label>
        <div class="col">
          <app-select 
            [id]="'metalId'" 
            [name]="'metalId'" 
            [options]="filteredMetalDropdown"
            [selectedValue]="insertUpdateCurrencyExchangeRate?.metalId"
            (valueChanged)="insertUpdateCurrencyExchangeRate.metalId = $event" 
            [isRequired]="false">
          </app-select>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center  mb-2 px-0">
        <label class="w-100px f-14 font-semibold text-gray-color mb-0 " for="ounceRate">Ounce Rate</label>
        <div class="col">
          <app-input 
            [inputName]="'ounceRate'" 
            [inputType]="'text'" 
            [inputId]="'ounceRate'"
            [inputValue]="insertUpdateCurrencyExchangeRate?.ounceRate"
            (inputValueEmit)="insertUpdateCurrencyExchangeRate.ounceRate = $event; onChangeOunceRate($event)" 
            [applyDecimalOrPercent]="true"
            [decimalRestrictedDigit]="2"
            [isRequired]="false"
            >
          </app-input>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center  mb-2 px-0 pe-2">
        <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="unit">Unit</label>
        <div class="col">
          <app-select 
            [id]="'unitId'" 
            [name]="'unitId'" 
            [options]="data?.getAllDropdownModel?.unitDropdown"
            [selectedValue]="insertUpdateCurrencyExchangeRate?.unitId"
            (valueChanged)="insertUpdateCurrencyExchangeRate.unitId = $event;onChangeUnit($event)" 
            [isRequired]="false"
            >
          </app-select>
        </div>
      </div>
      <div class="col d-flex align-items-center mb-2 px-0">
       <label class="w-100px f-14 font-semibold text-gray-color mb-0 " for="unitConversionRate">Unit Conversion Rate</label>
        <div class="col">
          <app-input 
            [inputName]="'unitConversionRate'" 
            [inputType]="'text'" 
            [inputId]="'unitConversionRate'"
            [inputValue]="insertUpdateCurrencyExchangeRate?.unitConversionRate"
            (inputValueEmit)="insertUpdateCurrencyExchangeRate.unitConversionRate = $event" 
            [applyDecimalOrPercent]="true"
            [decimalRestrictedDigit]="5"
            [isRequired]="false"
            >
          </app-input>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center mb-2 px-0 pe-2">
        <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="currency">Currency</label>
        <div class="col">
          <app-select 
            [id]="'currencyId'" 
            [name]="'currencyId'" 
            [options]="data?.getAllDropdownModel?.currencyExchangeDropdown"
            [selectedValue]="insertUpdateCurrencyExchangeRate?.currencyId"
            (valueChanged)="insertUpdateCurrencyExchangeRate.currencyId = $event; OnChangeCurrency($event)" 
            [isRequired]="false"
            >
          </app-select>
        </div>
      </div>

      <div class="col-6 d-flex align-items-center mb-2 px-0">
        <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="currencyRate">Rate
          <span>&#64;</span></label>
        <div class="col">
          <app-input 
            [inputName]="'currencyRate'" 
            [inputType]="'text'" 
            [inputId]="'currencyRate'"
            [applyDecimalOrPercent]="true"
            [decimalRestrictedDigit]="3"
            [inputValue]="insertUpdateCurrencyExchangeRate?.currencyRate"
            (inputValueEmit)="insertUpdateCurrencyExchangeRate.currencyRate = $event" [isRequired]="false">
          </app-input>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center px-0 pe-2">
        <label class="w-90px f-14 font-semibold text-gray-color mb-0 " for="metalRateInAED">Metal Rate In AED Per
          Gram</label>
        <div class="col">
          <app-input [inputName]="'metalRateInAED'" [inputType]="'text'" [inputId]="'metalRateInAED'"
            [inputValue]="insertUpdateCurrencyExchangeRate?.metalRateInAED"
            [applyDecimalOrPercent]="true"
            [decimalRestrictedDigit]="3"
            (inputValueEmit)="insertUpdateCurrencyExchangeRate.metalRateInAED = $event" [isRequired]="false">
          </app-input>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer pt-3 pb-2 mx-2">
    <app-button class="my-0 me-3" (buttonOutputEvent)="Clear()" [buttonLabel]="'Clear'" [buttonClass]="'pink_button'"
      [buttonType]="'button'">
    </app-button>
    <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="Save()" [buttonClass]="'blue_button'"
      [buttonType]="'button'">
    </app-button>
  </div>
</form>