import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer } from 'rxjs';
import { commonModalOptions } from '../models/common.model';
import { CamViewComponent } from '../common-components/common-popup/cam-view/cam-view.component';

const fileSizeMax = 6 * 1024 * 1024; // Set the maximum file size to 6 MB
const widthHeightMax = 1024;
const defaultQualityRatio = 0.7;

@Injectable({
  providedIn: 'root'
})
export class CompressImageService {
  constructor(public toaster?:ToastrService , public modalService?:NgbModal){

  }
  compress(file: File): Observable<File> {
    return new Observable((observer: Observer<File>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (ev) => {
        const img = new Image();
        img.src = ev.target.result as string;

        img.onload = () => {
          const elem = document.createElement('canvas');
          const imgWH = Math.max(img.width, img.height);
          const withHeightRatio = imgWH > widthHeightMax ? widthHeightMax / imgWH : 1;

          elem.width = img.width * withHeightRatio;
          elem.height = img.height * withHeightRatio;

          const ctx = elem.getContext('2d');
          ctx?.drawImage(img, 0, 0, elem.width, elem.height);

          const compressedBlob = this.dataURLtoBlob(elem.toDataURL(this.getImageType(file), defaultQualityRatio));
          const compressedFile = new File([compressedBlob], file.name, {
            type: this.getImageType(file),
            lastModified: Date.now(),
          });

          observer.next(compressedFile);
          observer.complete();
        };
      };

      reader.onerror = error => observer.error(error);
    });
  }

  private getImageType(file: File): string {
    return file.type || (file.name.toLowerCase().endsWith('.png') ? 'image/png' : 'image/jpeg');
  }

  private dataURLtoBlob(dataURL: string): Blob {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }


  checkPermissions(imageList, imgObj?) {
    navigator.mediaDevices.getUserMedia({
      video: {
        width: 200,
        height: 200
      }
    }).then((res) => {
      setTimeout(() => {
        this.openCamViewPopUp(res, imageList, imgObj);
      }, 100);
    }).catch(err => {
      console.log(err);
      if (err?.message === 'Permission denied') {
        this.toaster.error('Permission denied please try again by approving the access');
      } else {
        this.toaster.error('You may not having camera system, Please try again ...');
      }
    })
  }

  openCamViewPopUp(stream , imagesList ,imgUploadKey = 'base64Resource'){
    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
      size: "md",
      centered:true,
      windowClass:"camview_popup"
    };
    const modalRef = this.modalService.open(CamViewComponent, modalOption);
      modalRef.componentInstance.stream = stream;
      modalRef.componentInstance.emitImage.subscribe((imgUrl)=>{
        const img = {
          id: 0,
          deleted: false,
          added: true,
          edit: false,
        }
        img[imgUploadKey] = imgUrl;
        imagesList?.push(img);
      })
  }
}
