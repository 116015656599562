import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { JewelryCatalogueRequestModel, CatalogueModel, ImageUrls } from 'src/app/components/inventory/jewelry-catalogue/jewelry-catalogue-details/jewelry-catalogue-details.model';
import { JewelryCatalogueDetailsService } from 'src/app/components/inventory/jewelry-catalogue/jewelry-catalogue-details/jewelry-catalogue-details.service';
import { JewelryService } from 'src/app/jewelry/jewelry.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { AgGridI } from '../../ag-grid/ag-grid.model';
import { CustomerOrderSummary, commonModalOptions } from 'src/app/shared/models/common.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OpenImagePopupComponent } from 'src/app/shared/common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import {AgGridDataModelBillOfMaterial} from './products-details-common.model'
import { NotificationService } from '../../../services/notification.service';
import { ModalPopupSize } from '../../../models/common.model';

@Component({
  selector: 'app-products-details-common',
  templateUrl: './products-details-common.component.html',
  styleUrls: ['./products-details-common.component.scss']
})
export class ProductsDetailsCommonComponent {
  agGridDataModelProductListTable: AgGridI;
  agGridDataModelProductDetails: AgGridI;
  taxGridApi: GridApi;
  quantity: number = 0;
  catelogueId: any;
  cartItems: any[] = [];
  selectedImg
  jewelryCatalougeResourceList;
  jewelryCatalogueRequestModel = new JewelryCatalogueRequestModel();
  catalogueModel = new CatalogueModel();
  imageUrls = new ImageUrls();
  removeCatalogueResourceList = [];
  //  Bill of Material List data
  ProductDetailsListRowData: any[] = [
    { description: "Unit P-0, Qty-1, Weight-1312.500, MC-10000.00, Amt-25000.00, Tax-1000.00, HM-100.00, Unit P-0, Qty-0, Weight-0.000, MC-0.00, Amt-0.00, Tax-0.00, HM-0.00", tax: "5000.00", amt: 6495.00, netAmt: 6495.00 },
    { description: "Unit P-0, Qty-1, Weight-1312.500, MC-10000.00, Amt-25000.00, Tax-1000.00, HM-100.00, Unit P-0, Qty-0, Weight-0.000, MC-0.00, Amt-0.00, Tax-0.00, HM-0.00", tax: "5000.00", amt: 6495.00, netAmt: 6495.00 },
    { description: "Unit P-0, Qty-1, Weight-1312.500, MC-10000.00, Amt-25000.00, Tax-1000.00, HM-100.00, Unit P-0, Qty-0, Weight-0.000, MC-0.00, Amt-0.00, Tax-0.00, HM-0.00", tax: "5000.00", amt: 6495.00, netAmt: 6495.00 },
    { description: "Unit P-0, Qty-1, Weight-1312.500, MC-10000.00, Amt-25000.00, Tax-1000.00, HM-100.00, Unit P-0, Qty-0, Weight-0.000, MC-0.00, Amt-0.00, Tax-0.00, HM-0.00", tax: "5000.00", amt: 6495.00, netAmt: 6495.00 },
  ];
  selectedImage: any;
  ID:any;
  totalCartItems:any;
  descBillOfMaterial = DescBillOfMaterial
  activeTabId: any = DescBillOfMaterial.Desc;
  formName = StorageName.FORMNAME_INVENTORY_JEWELRY_CATALOGUE_PRODUCT_DETAILS;
  @Input() customerId ;
  user
  itemList=[];
  constructor(public navServices: NavService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public jewelryCatalogueDetailsService: JewelryCatalogueDetailsService,
    public toaster: ToastrService,
    public jewelryService: JewelryService,
    public sendDataService: SendDataService,
    private storageservice: StorageService,
    private notificationService: NotificationService,
    private modalService: NgbModal,) {
  }

  ngOnInit(): void {
    this.agGridDataModelProductDetails = AgGridDataModelBillOfMaterial;

    this.user =this.storageservice.retrieve('user');
    this.sendDataService.displayPageName(this.formName)
    this.activatedRoute.queryParams.subscribe((params) => {
      this.catelogueId = params["id"];
      if (this.catelogueId) {
        this.getJewelryDetailById(this.catelogueId)
      }
    });
    // this.agGridDataModelProductListTable.rows = this.productListRowData;
    this.getCartItems();
  }
 

  gridOptionsProductDetails: GridOptions = {
    suppressClickEdit: true,
    getRowStyle: this.getRowStyle,
  };

  
  getRowStyle(params: any) {
    return {
      background: params.node.rowIndex % 2 === 0 ? '#EEF1F7' : '#FFFFFF',
    };
  }

  Checkout() {
    // this.router.navigate(["app-jewelry/billingDetails"], {
    //   queryParams: { id: this.catelogueId },
    // });
    if(this.user?.id){
      this.router.navigate(["Inventory/jewelry-Catalogue/product-Checkout"])
    }
    else{
    this.router.navigate(["/app-jewelry/orderSummaryCheckout"] , {
      queryParams: { id: this.catelogueId },
    })
    // this.router.navigateByUrl("/app-jewelry/billingDetails")
    }
    
    // , {
    //   queryParams: { id: this.catelogueId },
    // }
  
  }

  addOrRemoveQuantity(status) {
    if (status == "plus") {
      this.quantity++
    } else {
      if (this.quantity < 1) return;
      this.quantity--
    }
  }

  getJewelryDetailById(id) {
    this.jewelryCatalogueDetailsService.getjewelryCatalogueById(id).subscribe({
      next: (res) => {
        if (res?.isSuccess) {
          this.catalogueModel = new CatalogueModel();
          this.jewelryCatalougeResourceList = [];
          this.removeCatalogueResourceList = [];
          this.catalogueModel = res?.response?.catalogueModel;
          
          this.jewelryCatalougeResourceList = res?.response?.imageUrls;
          this.itemList = res?.response?.itemList;
          
          
          this.imgOnLoad(this.jewelryCatalougeResourceList);
        }

      }, error: (err) => {
        
      }
    })
  }

  imgOnLoad(files) {
    this.selectedImage = files[0]?.base64Resource;
  }

  retriveCheckOutList
  AddToCart(catalogue , resouceList) {debugger
    // if (this.catelogueId !== undefined && this.quantity !== undefined) {
    //     const existingCartItemsJSON = localStorage.getItem('cartItems');
    //     const existingCartItems = existingCartItemsJSON ? JSON.parse(existingCartItemsJSON) : [];
    //     // Check if the item with the same id already exists in the cart
    //     const existingItemIndex = existingCartItems.findIndex(item => item.catalogueId == this.catelogueId);
    //     if (existingItemIndex !== -1) {
    //         // If the item already exists, update its quantity
    //         existingCartItems[existingItemIndex].quantity = this.quantity;
    //     } else {
    //         // If the item doesn't exist, add it to the cart
    //         const newItem = { catalogueId: Number(this.catelogueId), quantity: this.quantity };
    //         existingCartItems.push(newItem);
    //     this.toaster.success("Item added Successful")
    //     }
    //     localStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    //     this.totalCartItems = existingCartItems;
    //     this.jewelryService.totalAddedCartService.next(this.totalCartItems)
    //     this.toaster.success("Quantity updated Successful")
    // } else {
    //     console.error('Invalid id or quantity');
    // }
    const data = this.storageservice.retrieve(CustomerOrderSummary)??[];
    this.retriveCheckOutList = data;

    const previousOrderSummary = this.retriveCheckOutList?.find(x=> x?.customerId == this.customerId);

    const orderSummary = {
      isCheck   :true,
      productId :catalogue?.id,
      metalId   : catalogue?.metalId,
      productDetailId: catalogue?.productDetailId,
      productUrls:resouceList,
      productTitle:catalogue?.title,
      shortDesc :catalogue.shortDescription,
      qty: this.quantity,
      amount:catalogue.amount,
    }

    const details =  {
      customerId: this.customerId,
      orderSummary : [orderSummary]
    }

    if(this.retriveCheckOutList){
      if(previousOrderSummary){
       let productDetails = previousOrderSummary?.orderSummary?.length && previousOrderSummary?.orderSummary?.find(x=>x?.productId == orderSummary?.productId);
       if(productDetails){
          productDetails.qty = ((productDetails?.qty??0) + (this.quantity??0));
          productDetails.productUrls=resouceList,
          productDetails.productTitle = catalogue?.title,
          productDetails.shortDesc = catalogue.shortDescription,
          productDetails.amount = catalogue.amount
       } else{
          previousOrderSummary?.orderSummary?.push(orderSummary)
       }

      } else {
          this.retriveCheckOutList?.push(details)
      }
    } else {
        this.retriveCheckOutList?.push(details);
    }
    
    this.storageservice.store(CustomerOrderSummary , this.retriveCheckOutList);
    this.toaster?.success("Item added Successful");
   this.sendDataService.updateCart.next(true);

}




getCartItems() {
  const existingCartItemsJSON = localStorage.getItem('cartItems');
  const cartItems = JSON.parse(existingCartItemsJSON);
  if (cartItems) {
      const item = cartItems.find(x => x.catalogueId === this.catelogueId);
      if (item) {
          this.quantity = item.quantity;
      } else {
          this.quantity = 0;
      }
  } else {
      this.quantity = 0;
  }
}


  // goBack(){
  //   this.router.navigateByUrl("/app-jewelry/jewelry");
  // }

  tabchanged(tabId){debugger
    this.activeTabId = tabId;
}
isItemListEmpty(): boolean {
  return this.itemList.length === 0;
}


openImgPopUp(selectedItem: any){
  const selectedIndex = this.jewelryCatalougeResourceList?.findIndex(item => item.base64Resource == selectedItem);
  var data = {
    jewelryCatalogue : [...this.jewelryCatalougeResourceList],
    selectedIndex : selectedIndex,
  }
  this.notificationService.openModalPopup(OpenImagePopupComponent , data ,'' , ModalPopupSize.SM,'image_popup_fullscreen', true, true).then(
    (result)=>{
      if(result){

      }
    }
  )
}

  displayPreview(img){
    this.selectedImage = img;
  }
}

enum DescBillOfMaterial  {
  Desc = 1,
  BillOfMaterial = 2
}

