<div class="d-flex commonSelectContainer align-items-center w-100 position-relative">
    <button class="select-label {{className}}" >
        <!-- [style.width.px]="labelName ? 90 : null" -->
        <ng-container *ngIf="showregistrationIcon">
            <app-svg-icon name="registration"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showGroupIcon">
            <app-svg-icon name="group"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showcampaignGroupIcon">
            <app-svg-icon name="campaignGroup"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showSalesPersonIcon">
            <app-svg-icon name="salesPerson"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showCountryIcon">
            <app-svg-icon name="country"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showNationalityIcon">
            <app-svg-icon name="nationality"></app-svg-icon>
        </ng-container>
        <span *ngIf="labelName" [style.width.px]="labelName && SelectLabel !== 'State' ? 90 : null">{{SelectLabel}}</span>
        <span *ngIf="isRequired == true" class="text-color-danger">*</span>
    </button>  

    <select [id]="id" [name]="name" [required]="isRequired" [disabled]="isDisabled" class="form-select" [ngModel]="selectedValue" (ngModelChange)="valueChanged.emit($event)">
        <!-- <option value="0" class="place-holder"></option>  -->
        <option *ngFor="let option of options" [value]="option.id">
            {{ option.name }}
        </option>
    </select>
</div>


