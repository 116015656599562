import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountLedgerReportComponent } from '../../../../../components/report/accountledger-report/accountledger-report.component';
import { IconHover } from '../../../../../shared/enum/common-enum';

@Component({
  selector: 'app-account-ledger-report-pop-up',
  templateUrl: './account-ledger-report-pop-up.component.html',
  styleUrls: ['./account-ledger-report-pop-up.component.scss']
})
export class AccountLedgerReportPopUpComponent {
  currentHoverEffect = IconHover.Pink;
  @Input() data : any = {};
  @ViewChild(AccountLedgerReportComponent) accountLedgerReportComponent : AccountLedgerReportComponent ;
  
  constructor(public activeModal : NgbActiveModal,){ }
  
  intializeAccountledgerId(){
    const requestModel = this.accountLedgerReportComponent?.accountLedgerReportAmountComponent?.storageService?.retrieve(this.accountLedgerReportComponent?.accountLedgerReportAmountComponent?.apiRequestStorageName);
    requestModel.filters  =  this.assignAccountGroupId(requestModel?.filters , this.data?.accountgroupId);
    this.accountLedgerReportComponent?.accountLedgerReportAmountComponent?.storageService?.store(this.accountLedgerReportComponent?.accountLedgerReportAmountComponent?.apiRequestStorageName, requestModel );
  }


  assignAccountGroupId(filtersJson: string, accountgroupId: string): string {
    const filters = JSON.parse(filtersJson || '{"rules": [], "groups": []}');
    const existingRule = filters.rules.find((rule: any) => rule.field === 'accountgroupId');
    if (existingRule) {
        existingRule.data = accountgroupId;
    } else {
        filters.rules.push({
            data: accountgroupId,
            op: 'eq', 
            field: 'accountgroupId' 
        });
    }
    return JSON.stringify(filters);
}
}
