import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export const commonModalOptions: NgbModalOptions = {
  centered        : true,
  backdrop        : "static",
  keyboard        : false,
  size            : "md",
};
export const commonModal: NgbModalOptions = {
  centered        : true,
  backdrop        : "static",
  keyboard        : false,
  size            : "md",
};

export const ModalPopupSize = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
}

export const commonModalOptionsLg: NgbModalOptions = {
  centered        : true,
  backdrop        : "static",
  keyboard        : false,
  size            : "lg",
};

export const commonModalOptionsXl: NgbModalOptions = {
  centered        : true,
  backdrop        : "static",
  keyboard        : false,
  size            : "xl",
};

export const commonModalOptionsXXl: NgbModalOptions = {
  centered        : true,
  backdrop        : "static",
  keyboard        : false,
  size            : "xxl",
  windowClass     : "ManufacturingPopUps",
};

export const commonModalsOptionsXXl: NgbModalOptions = {
  centered        : true,
  backdrop        : "static",
  keyboard        : false,
  size            : "xxl",
  windowClass     : "RetailerPopUps",
};

export class CommonOperationModel {
  deleted         : boolean;
  added           : boolean;
  edited          : boolean;
  none            : boolean;
  id              : number;
}


export class ShowHeaderPageNameAndInvoieNum{
  isShow : boolean = true;
  pageName: string;
  invoiceNumber : string;
}
export const Path ={
  SaleOrder : '/Transaction/sale-order' ,
  JobWorkOrder  : '/Transaction/jobwork-order' ,

}
export const VoucherTypeName = {
  SalesInvoice : "Sales Invoice",
  JobworkInvoice : "Jobwork Invoice",
  PurchaseInvoice : "Purchase Invoice",
  PurchaseOrder : "Purchase Order",
  PurchaseReturn : "Purchase Return",
  SalesReturn : "Sales Return",
  SalesOrder: "Sales Order",
  StockJournal : "Stock Journal",
  PaymentVoucher : "Payment Voucher",
  ReceiptVoucher : "Receipt Voucher",
  JournalVoucher : "Journal Voucher",
  PurchaseFixing : "PurchaseFixing",
  SaleFixing : "SaleFixing",
  JobworkOrder : "Jobwork Order",
  OldJewelryScrapInvoice : "Old Jewelry - Scrap Invoice",
  CreditNote : "Credit Note",
  DebitNote : "Debit Note",
  ConsignmentIn : "Consignment In",
  ConsignmentOut : "Consignment Out",
  JobworkQueue : "Jobwork Queue",
  JobworkQueueMaster : "Jobwork Queue Master",
  ContraVoucher:"Contra Voucher",
  MaterialIn:"Material In",
  MaterialOut:"Material Out",
  RepairOrder:"Repair Order",
  RepairInvoice:"Repair Invoice",
  MaterialIssue : "Material Issue",
  MaterialReceive : "Material Receive",
  PhysicalStock : "Physical Stock",
  BilOfMaterial : "Bill Of Material",
  MemoVoucher :"Memo Voucher",
  EstimateQuotationInvoice :"Estimate Quotation Invoice",
  InvestmentFund :"Investment Fund",
  ExpenseInvoice :"Expense Invoice",
  Loan :"Loan",
  LoanRelease :"LoanRelease",
  SalesQuotation :"Sales Quotation",
  DeliveryNote : "Delivery Note",
  PDCClearance :"PDCClearance",
  PDCPayable :"PDCPayable",
  PDCReceivable :"PDCReceivable",
  FundTransfer :"Fund Transfer",
  FundWithdraw :"Fund Withdraw",
  JewelryCatalogue:"Jewelry Catalogue",
  Appraisal:"Appraisal",
  PurchaseQuotation:"Purchase Quotation"
}
export enum VoucherType
{
  OpeningBalance = 1,
  OpeningStock  = 2,
  ContraVoucher = 3,
  PaymentVoucher = 4,
  ReceiptVoucher = 5,
  JournalVoucher = 6,
  PDCPayable = 7,
  PDCReceivable = 8,
  PDCClearance = 9,
  PurchaseOrder = 10,
  MaterialReceipt = 11,
  RejectionOut = 12, 
  PurchaseInvoice = 13,
  PurchaseReturn = 14,
  SalesQuotation = 15,
  SalesOrder = 16,
  DeliveryNote = 17,
  RejectionIn = 18,
  SalesInvoice = 19,
  SalesReturn = 20,
  ServiceVoucher = 21,
  CreditNote = 22,
  DebitNote = 23,
  StockJournal = 24,
  PhysicalStock = 25,
  DailySalaryVoucher = 26,
  MonthlySalaryVoucher = 27,
  AdvancePayment = 28,
  JobworkOrder = 29,
  JobworkInvoice = 30,
  Pos = 31,
  OldJewelryScrapInvoice = 32,
  JobworkQueue = 33,
  ConsignmentIn = 34,
  ConsignmentOut = 35,
  MaterialIn = 36,
  MaterialOut = 37,
  RepairOrder = 38,
  RepairInvoice = 39,
  MaterialIssue = 40,
  MaterialReceive = 41,
  JobworkQueueMaster = 42,
  BilOfMaterial = 43,
  InvestmentFund = 44,
  ExpenseInvoice = 45,
  Loan = 46,
  LoanRelease = 47,
  PurchaseFixing = 48,
  SaleFixing = 49,
  FundTransfer = 50,
  FundWithdraw = 51,
  JewelryCatalogue =52,
  Appraisal =53,
  PurchaseQuotation=54,
}


enum EventType {
  insert = 'Success',
  update = 'Error',
  delete = 'Warning',

}

export enum MetalType
{

    Gold  = 1,
    Silver = 2,
    Platinum = 3,
    Diamond = 4,
    StoneGEMS = 5,
    Imitation = 6,
    Other = 7
}


export enum DiamondCategory
{
  Diamonds = 10,
  GemStones = 40,
  Jewellery = 30
}

export enum LedgerItemTypeTaxes{
  Amount = 1,
  Gold = 2,
  GoldMaking = 3,
  Silver = 4,
  SilverMaking = 5,
  Diamond = 6,
  Watches = 7,
  LooseDiamond = 8,
  CertifiedDiamond = 9
}


export class DropdownModel {
  name: string;
  id: string | number;
  extra1:string;
  extra2:string;
  extra3?:string;
  extra4?:string;
}

export class PermissionVM {
  [key: string] : any | null; // Define index signature to allow any additional properties
  dashboardWidgets: boolean;
  productOpening: boolean;
  accountLedger: boolean;
  parties: boolean;
  product: boolean;
  salesInvoice: boolean;
  purchaseInvoice: boolean;
  oldJewelryScrapInvoice: boolean;
  oldJewelryScrap: boolean;
  receiptVoucher: boolean;
  paymentVoucher: boolean;
  materialIssue: boolean;
  materialIn: boolean;
  materialOut: boolean;
  materialReceive: boolean;
  salesReturn: boolean;
  purchaseReturn: boolean;
  stockJournalOption1: boolean;
  stockJournalOption2: boolean;
  pdcEntry: boolean;
  contraVoucher: boolean;
  journalVoucher: boolean;
  purchaseFixing: boolean;
  saleFixing: boolean;
  repairInvoice: boolean;
  pos: boolean;
  estimate:boolean;
  deliverynote:boolean;
  transaction: boolean;
  salesOrder: boolean;
  repairOrder: boolean;
  purchaseOrder: boolean;
  saleRepairOrderProcess: boolean;
  orders: boolean;
  jewelryCatalogue: boolean;
  jewelryCatalogueTracking: boolean;
  consignmentIn: boolean;
  consignmentOut: boolean;
  consignmentItems: boolean;
  physicalStock: boolean;
  inventory: boolean;
  department: boolean;
  jobworkOrder: boolean;
  jobworkQueue: boolean;
  manufacturingProcess: boolean;
  manufacturer: boolean;
  trialBalance: boolean;
  balanceSheet: boolean;
  profitLoss: boolean;
  cashFlow: boolean;
  fundFlow: boolean;
  chartOfAccount: boolean;
  financialStatement: boolean;
  transactionsReport: boolean;
  accountLedgerReport: boolean;
  dayReport: boolean;
  cashbankBook: boolean;
  ageingReport: boolean;
  stockReport: boolean;
  taxReport: boolean;
  saleAnalysis: boolean;
  purchaseAnalysis: boolean;
  dailySalesAndCollectionSummary: boolean;
  borrowersReport: boolean;
  report: boolean;
  setSoftware: boolean;
  voucherType: boolean;
  dataImportExport: boolean;
  dataExportInTally: boolean;
  whatsAppMarketing: boolean;
  settings: boolean;
  userManagement: boolean;
  masters: boolean;
  activityLog: boolean;
  taskevents: boolean;
  administration: boolean;
  goldSilverAnalysis: boolean;
  diamondCaratwise: boolean;
  // diamondStoneImitation: boolean;
  diamondStones: boolean;
  imitationWatches: boolean;
  memoVoucher: boolean;
  rfid: boolean;
  barcode: boolean;
  stockValuation: boolean;
  jobworkQueueReport: boolean;
  transactionReport: boolean;
  taxReturn: boolean;
  lossTracking: boolean;
  stockHistory: boolean;
  departmentReport: boolean;
  retailer: boolean;
  manufacturing: boolean;
  catalogTracking: boolean;
  stock: boolean;
  customer: boolean;
  salesPerson: boolean;
  dashboard: boolean;
  diamondStoneWeightReport : boolean;
  closingReport: boolean;
  creditNote:boolean;
  debitNote:boolean;
  expenses:boolean;
  investmentFund:boolean;
  loans:boolean;
  bankReconciliation:boolean;
  stockSummaryReport:boolean;
  workLogReport:boolean;
  jobcardReport:boolean;
  stockTransferHistory:boolean;
}

export class StorageModel{

}
 export class uploadRequestModel
 {
  
  invoiceItemImageResources: InvoiceItemImageResource[]
  voucherNo: number
  voucherTypeId: number
  invoiceItemId: number
  
 }
 export class InvoiceItemImageResource {
  base64Resource: string
}

export const CustomerOrderSummary = 'CustomerOrderSummary'