import { Component, Input, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from 'ag-grid-community';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { ToastrService } from 'ngx-toastr';
import { VoucherType } from 'src/app/shared/models/common.model';
import { AgGridAgainstOfList } from 'src/app/components/Transaction/invoice/invoice.model';
import { InvoiceService } from 'src/app/components/Transaction/invoice/invoice.service';
import { IconHover } from '../../../../enum/common-enum';

@Component({
  selector: 'app-against-of-popup',
  templateUrl: './against-of-popup.component.html',
  styleUrls: ['./against-of-popup.component.scss']
})
export class AgainstOfPopupComponent extends CommonService{
  @Input()data:any;
  agGridAgainstOfList             : AgGridI;
  gridOptions                     : GridOptions;
  gridApiAgainstOf                :any;
  agGridAgainstOfListRowData      :any[]=[];
  saleOrdeList                    : any;
  requestIds=[];
  currentHoverEffect = IconHover.Pink;
  itemList = [];
  voucherdata: any;

  constructor(
    public activeModal  : NgbActiveModal,
    public renderer : Renderer2,
    public invoiceService : InvoiceService,
    public agGridService:AgGridService ,
    public toaster: ToastrService,
  ) {
    super(renderer);
    this.gridOptions = {
      singleClickEdit : true, // Add other grid options as needed
    };
  }

  ngOnInit() : void {
    this.agGridAgainstOfList = AgGridAgainstOfList;
    const element = document.querySelector('.custom_focus') as any;
    element?.focus();
     
    
  }

 onKeyDown(event: KeyboardEvent | any) {
    event?.srcElement?.parentElement?.click();
  }

  onGridReady(event) {
    this.gridApiAgainstOf = event;
    switch (this.data.modalTitle) {
        case "Sale Order":
            this.agGridAgainstOfListRowData = this.data?.saleOrderNoDropdownFilterData;
            break;
        case "Estimate/Quotation":
            this.agGridAgainstOfListRowData = this.data?.saleQuotationNoDropdownFilterData;
            break;
        case "Delivery Note":
            this.agGridAgainstOfListRowData = this.data?.deliveryNoteNoDropdownFilterData;
            break;
        case "Sales Invoice":
            this.agGridAgainstOfListRowData = this.data?.salesInvoiceNoDropdownFilterData;
            break;
        case "Purchase Invoice":
            this.agGridAgainstOfListRowData = this.data?.purchaseInvoiceNoDropdownFilterData;
            break;
        case "Repair Order":
        this.agGridAgainstOfListRowData = this.data?.repairOrderNoDropdownFilterData;
        break;
        case "Purchase Order":
             this.agGridAgainstOfListRowData = this.data?.purchaseOrderNoDropdownFilterData;
        break;
        case "Purchase Quotation":
              this.agGridAgainstOfListRowData = this.data?.purchaseQuotationNoDropdownFilterData;
          break;
        default:
            this.agGridAgainstOfListRowData = []; 
            break;
    }
  }

  onApply() {
    this.requestIds = this.gridApiAgainstOf?.api?.getModel()?.rowsToDisplay
      ?.map(a => a?.data)
      ?.filter(x => x?.active === true)
      ?.map(y => y?.id);
    if (!this.requestIds || this.requestIds.length === 0) {
      this.activeModal.close(false);
      return;
    }
    switch (this.data.modalTitle) {
      case "Sale Order":
        this.voucherdata = VoucherType.SalesOrder;
        break;
      case "Estimate/Quotation":
        this.voucherdata = VoucherType.SalesQuotation;
        break;
      case "Delivery Note":
        this.voucherdata = VoucherType.DeliveryNote;
        break;
      case "Sales Invoice":
        this.voucherdata = VoucherType.SalesInvoice;
        break;
      case "Purchase Invoice":
        this.voucherdata = VoucherType.PurchaseInvoice;
        break;
      case "Repair Order":
        this.voucherdata = VoucherType.RepairOrder;
        break;
      case "Purchase Quotation":
        this.voucherdata = VoucherType.PurchaseQuotation;
        break;
      case "Purchase Order":
        this.voucherdata = VoucherType.PurchaseOrder;
        break;
      default:
        this.voucherdata = null;
        break;
    }

    const requestData = { ids: this.requestIds, voucherType: this.voucherdata };

    this.invoiceService.getBySaleOrderId(requestData).subscribe({
      next: (res) => {
        if (res?.isSuccess) {
          this.itemList = res?.response?.itemList;
          // Save active checkbox
          this.gridApiAgainstOf?.api?.forEachNode((node) => {
            node.data.active = this.requestIds.includes(node.data.id);
            this.gridApiAgainstOf?.api?.refreshCells({ rowNodes: [node] });
          });
          this.activeModal.close(this.itemList ?? []);
        } else {
          this.toaster.error(res.errors[0]);
          this.activeModal.close();
        }
      },
      error: (err) => {
        
      }
    });
  }
 

  cleanupCheckboxes() {
    this.gridApiAgainstOf?.api?.forEachNode((node) => {
      if (node?.data?.active) {
        node.data.active = false;
        this.gridApiAgainstOf?.api?.refreshCells({ rowNodes: [node] });
      }
    });
  }

  closeModal() {
    this.cleanupCheckboxes();
    this.activeModal.dismiss();
  }

}