import { Component, OnInit, Inject, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { StorageService } from "../../services/storage.service";
import { LayoutDetails, User } from "src/app/auth/auth/auth.model";
import { Subscription } from "rxjs";
import { SendDataService } from "../../services/send-data.service";
import { CommonService } from "../../services/common.service";
import { Router } from "@angular/router";
import { EditProfileService } from "src/app/components/edit-profile/edit-profile.service";
import { CustomerOrderSummary } from "../../models/common.model";
import { LayoutDetailsStoreKeyName } from "../../utils/common-utils";
import { IconHover, StorageName } from "../../enum/common-enum";
import { MaximizeToggleService } from "../../services/maximize-toggle-service.service";
import { SettingTab } from "src/app/components/Settings/set-software/set-software.model";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends CommonService implements OnInit {

  isWhatsappConnected: boolean = false;
  currentHoverEffect = IconHover.Pink
  public elem: any;
  user:User;
  isRateVisible: boolean = false;
  layoutDetails:LayoutDetails;
  private branchSubscription:Subscription;
  private cartDetailsSubscription:Subscription;
  private refreshCompanyDetailsSubscription:Subscription;
  private whatsappConnectionSubscription:Subscription;
  cartDetails

  constructor(public layout: LayoutService, 
    public navServices: NavService, @Inject(DOCUMENT) private document: any , 
    public storageservice: StorageService,
    public layoutService: LayoutService,
    public renderer : Renderer2,
    public router : Router,
    public editProfileService : EditProfileService,
    private sendDataService: SendDataService,
    public maximizeToggleService:MaximizeToggleService,) {
    super(renderer);
    
  }

  ngOnInit() {
    this.branchSubscription = this.sendDataService.ShowUserDetails.subscribe((res => {
      if (res) {
        this.setDetails();
      }
    }));
    this.elem = document.documentElement;
    this.setDetails();

    this.refreshCompanyDetailsSubscription = this.editProfileService.refreshCompanyDetails.subscribe((res => {
      if (res) this.layoutDetails = this.storageservice.retrieve(LayoutDetailsStoreKeyName);
    }));

    this.getCartDetails();
    this.cartDetailsSubscription = this.sendDataService.updateCart.subscribe((res => {
      if (res) {
        this.getCartDetails();
      }
    }));
    this.whatsappConnectionSubscription = this.sendDataService.whatsappConnectionStatus.subscribe((res => {
      if (res) {
        this.isWhatsappConnected = res == 'CONNECTED';
      }
    }));
  }

  handleImageError(event: any) {
    event.target.src = this.layoutService?.displayDefaultCompanyIcon;
  }
  
  getCartDetails(){
        const customersOrderSummary =  this.storageservice.retrieve(CustomerOrderSummary);
        const customerId = this.user?.accountLedgerId ? this.user?.id :undefined;
        this.cartDetails =  customersOrderSummary?.find(x=>x?.customerId == customerId);  
  }

  navigateProductCheckout(){
    this.router.navigate(["Inventory/jewelry-Catalogue/product-Checkout"])
  }

  setDetails(){
    this.user =this.storageservice.retrieve('user');
    this.layoutDetails =this.storageservice.retrieve('layoutDetails');
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    document.body.remove;
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    this.maximizeToggleService.setHeaderFlag(true);
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  navgateToDashboard(){
    this.router.navigate(['/dashboard']);
  }

  openUpdateRateToggler(){
    this.isRateVisible = !this.isRateVisible; 
  }

  clearCache() {
    window.location.reload();
  }

  ngOnDestroy(){
    this.branchSubscription?.unsubscribe();
    this.cartDetailsSubscription?.unsubscribe();
    this.refreshCompanyDetailsSubscription?.unsubscribe();
    this.whatsappConnectionSubscription?.unsubscribe();
  }

  openEmptyCart(){
    
  }

  navigateToCredentials(){
    localStorage.setItem(StorageName.SET_SOFTWARE_ACTIVE_TAB_ID, SettingTab.NotificationConfiguration.toString() );
    this.router.navigate(['/Settings/set-software']);

    // this.storageService.sendWhatsappImg.next(undefined);
    this.sendDataService.navigateToCrdentialWhatsapp.next(true);
  }
}
