import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';
import { StorageService } from '../../services/storage.service';
import { StorageName } from '../../enum/common-enum';
import { MetalSectionReadonlyModel } from './metal-section-readonly.model';
import { MetalSectionReadonlyComponent } from './metal-section-readonly.component';
import { ModalPopupSize } from '../../models/common.model';
import { NotificationService } from '../../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class MetalSectionReadonlyService {
  constructor(public service:DataService,public storageService:StorageService,public notificationService : NotificationService) { }

  openMetalSectionReadOnlyPopup(metalSectionModel, formModel, formName, barcodeNo = undefined ,designNo?) {
    var metalSectionModelSource =   this.setVisibility(StorageName.INVENTORY_BARCODE_DETAILS_POPUP_GOLD_ITEM_GRID); 
    var data = { formName: formName, formModel: formModel, metalSectionModel: metalSectionModelSource, barcodeNo: barcodeNo ,designNo:designNo}
    const modalRefResult = this.notificationService.openModalPopup(MetalSectionReadonlyComponent, data, 'Enter your item', ModalPopupSize.XL, 'print-popup' , false , true);
    return modalRefResult;
  }
 
  setVisibility(storageName : StorageName | StorageName) : any
  {
    var metalSectionModel = new MetalSectionReadonlyModel();
    switch(storageName)
    {
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter  = true;
        metalSectionModel.showCommentAndAddField = false;
        metalSectionModel.agGridDataModelGold.moreButton = false;
        metalSectionModel.agGridDataModelSilver.moreButton = false;
        metalSectionModel.agGridDataModelDiamond.moreButton = false;
        metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
        metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
        metalSectionModel.agGridDataModelGold.selectAll=true;
        metalSectionModel.agGridDataModelSilver.selectAll=true;
        metalSectionModel.agGridDataModelDiamond.selectAll=true;
        metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
        metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_IMITATION_ITEM_GRID);

        metalSectionModel.agGridDataModelGold.editable = false;
        // metalSectionModel.storageNamePlatinum=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_PLATINUM_ITEM_GRID;
        // metalSectionModel.storageNameOther=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_OTHER_ITEM_GRID;
        metalSectionModel.tableIndex = 0;
        metalSectionModel.selectAll = true;
        metalSectionModel.showMetalSelectionTabs = false;
        break;

        case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter  = true;
        metalSectionModel.showCommentAndAddField = false;
        metalSectionModel.agGridDataModelGold.moreButton = false;
        metalSectionModel.agGridDataModelSilver.moreButton = false;
        metalSectionModel.agGridDataModelDiamond.moreButton = false;
        metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
        metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
        metalSectionModel.agGridDataModelGold.selectAll=true;
        metalSectionModel.agGridDataModelSilver.selectAll=true;
        metalSectionModel.agGridDataModelDiamond.selectAll=true;
        metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
        metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_IMITATION_ITEM_GRID);


        metalSectionModel.agGridDataModelImmitationAndWatches.editable = false;

        metalSectionModel.tableIndex = 1;
        metalSectionModel.selectAll = true;
        metalSectionModel.showMetalSelectionTabs = false;

        break;

        case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE:
          metalSectionModel.isInternal_Fix = false;
          metalSectionModel.isBarcodeInput = false;
          metalSectionModel.isGridFooter  = true;
          metalSectionModel.showCommentAndAddField = false;
          metalSectionModel.agGridDataModelGold.moreButton = false;
          metalSectionModel.agGridDataModelSilver.moreButton = false;
          metalSectionModel.agGridDataModelDiamond.moreButton = false;
          metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
          metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
          metalSectionModel.agGridDataModelGold.selectAll=true;
          metalSectionModel.agGridDataModelSilver.selectAll=true;
          metalSectionModel.agGridDataModelDiamond.selectAll=true;
          metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
          metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;

          metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID);
          metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID);
          metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID);
          metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID);
          metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID);
  
          metalSectionModel.agGridDataModelGold.editable = false;
          // metalSectionModel.storageNamePlatinum=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_PLATINUM_ITEM_GRID;
          // metalSectionModel.storageNameOther=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_OTHER_ITEM_GRID;
          metalSectionModel.tableIndex = 0;
          metalSectionModel.selectAll = true;
          metalSectionModel.showMetalSelectionTabs = true;

          metalSectionModel.agGridDataModelGold.isServerSidepagination=true;
          metalSectionModel.agGridDataModelGold.showPagination=true;
          metalSectionModel.agGridDataModelSilver.isServerSidepagination=true;
          metalSectionModel.agGridDataModelSilver.showPagination=true;
          metalSectionModel.agGridDataModelDiamond.isServerSidepagination=true;
          metalSectionModel.agGridDataModelDiamond.showPagination=true;
          metalSectionModel.agGridDataModelStoneGEMS.isServerSidepagination=true;
          metalSectionModel.agGridDataModelStoneGEMS.showPagination=true;
          metalSectionModel.agGridDataModelImmitationAndWatches.isServerSidepagination=true;
          metalSectionModel.agGridDataModelImmitationAndWatches.showPagination=true;

          break;
  
          case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION:
          metalSectionModel.isInternal_Fix = false;
          metalSectionModel.isBarcodeInput = false;
          metalSectionModel.isGridFooter  = true;
          metalSectionModel.showCommentAndAddField = false;
          metalSectionModel.agGridDataModelGold.moreButton = false;
          metalSectionModel.agGridDataModelSilver.moreButton = false;
          metalSectionModel.agGridDataModelDiamond.moreButton = false;
          metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
          metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
          metalSectionModel.agGridDataModelGold.selectAll=true;
          metalSectionModel.agGridDataModelSilver.selectAll=true;
          metalSectionModel.agGridDataModelDiamond.selectAll=true;
          metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
          metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;

          metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID);
          metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID);
          metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID);
          metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID);
          metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID);
  
          metalSectionModel.tableIndex = 1;
          metalSectionModel.selectAll = true;
          metalSectionModel.showMetalSelectionTabs = true;
  
          break;
          case StorageName.INVENTORY_BARCODE_DETAILS_POPUP_GOLD_ITEM_GRID:
            metalSectionModel.metalSectionModelAsPopup = true;
            metalSectionModel.showMetalSelectionPopupHeader = true;
            metalSectionModel.agGridDataModelGold.moreButton = false;
            metalSectionModel.agGridDataModelSilver.moreButton = false;
            metalSectionModel.agGridDataModelDiamond.moreButton = false;
            metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
            metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
            metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.INVENTORY_BARCODE_DETAILS_POPUP_GOLD_ITEM_GRID);
            metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.INVENTORY_BARCODE_DETAILS_POPUP_SILVER_ITEM_GRID);
            metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.INVENTORY_BARCODE_DETAILS_POPUP_DIAMOND_ITEM_GRID);
            metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.INVENTORY_BARCODE_DETAILS_POPUP_STONE_GEMS_ITEM_GRID);
            metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.INVENTORY_BARCODE_DETAILS_POPUP_IMITATION_ITEM_GRID);
          break;
    }

    return metalSectionModel;
  }

  setNewColDef(grirDataModel, storageName, 
    filterStorageName = "", 
    sortStorageName = "", 
    paginationStorageName ="",
    moreButton : boolean = false,
    selectAll : boolean = false,
    isFilter : boolean = false,
    isServerSidepagination : boolean = false) : any
  {
    grirDataModel.colDefs = grirDataModel.colDefs.map((item, index) => ({
      ...item,
      headerComponentParams: {
        ...item.headerComponentParams,
        storageName: storageName,
        filterStorageName : filterStorageName,
        sortStorageName : sortStorageName,
        paginationStorageName : paginationStorageName,
        moreButton : (item.extraField == "Action" || item.field=='action') ? moreButton : false,
        selectAll : (item.field=='active') ? selectAll : false,
        isFilter : isFilter,
        isServerSidepagination : isServerSidepagination
      },
      storageName: storageName,
    }));
    grirDataModel.storageName = storageName;
    return grirDataModel;
  }
}