<app-load-dropdown-data [formName]="formName"></app-load-dropdown-data>
<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Add Location</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect"  class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
  </div>
  <form ngNativeValidate #LocationForm="ngForm">
    <div class="modal-body mx-2">
      <div class="row mb-1">
        <div class="col-12 d-flex mb-2 gap-0">
        <div class="col-1 font-semibold text-gray-color d-flex align-items-center">Name<span class="text-color-danger">*</span></div>
        <div class="col-9 ps-3 w-175">
            <app-input 
            [inputName]="'locationName'" 
            [inputId]="'locationName'" 
            [inputType]="'text'" 
            [inputValue]="locationRequestModel?.name"
            (inputValueEmit)="locationRequestModel.name=$event"
            [isRequired]="true"
            >
          </app-input>
        </div>
        <div class="col-2 d-flex align-items-center ps-0">
            <div (click)="this.locationRequestModel.active = !this.locationRequestModel.active;" class="col-9 f-14 font-semibold text-gray-color cursorPointer text-center text-nowrap ps-2 mb-0">
                Active
            </div>
            <div class="col d-flex justify-content-end align-items-center">
                <app-checkbox  
                [dyanamicId]="'active'" 
                [isRequired]="false" 
                [status]="locationRequestModel?.active" 
                (check)="locationRequestModel.active = $event"></app-checkbox>
            </div>
        </div>
      </div>
      </div>
    </div>
    <div class="modal-footer pt-3 pb-2 mx-2">
      <app-button 
      class="my-0 me-3" 
      (buttonOutputEvent)="activeModal.close()" 
      [buttonLabel]="'Close'" 
      [buttonClass]="'pink_button'" 
      [buttonType]="'button'">
      </app-button>
      <app-button class="m-0" 
      [buttonLabel]="'Save'" 
      (buttonOutputEvent)="InserLocationList()" 
      [buttonClass]="'blue_button'" 
      [buttonType]="'submit'">
      </app-button>
    </div>
  </form>
