<div class="container-fluid px-0 mt-3">
    <div class="row">
        <div class="col-9">
            <form action="" ngNativeValidate #personalDetailsForm="ngForm">
            <div class="row">
                <div class="col-5 mb-2">
                    <label class="f-13 font-semibold text-gray-color mb-1" for="">Full Name <span class="text-color-danger">*</span></label>
                    <app-input 
                        onlyAlphabets
                        [inputName]="'fullName'" 
                        [inputType]="'text'"
                        [inputId]="'fullName'" 
                        [inputValue]="personalDetails?.fullName" 
                        (inputValueEmit)="personalDetails.fullName = $event" 
                        [isRequired]="true"
                        (keydown.enter)="onKeydown($event, 'pcontactId')"
                        [ngClass]="{'input-border-red' : ((personalDetailsForm?.form?.controls?.fullName?.touched || personalDetailsForm?.form?.controls?.fullName?.dirty) && personalDetailsForm?.form?.controls?.fullName?.invalid)}"
                        >
                    </app-input>
                </div>
                <!-- <div class="col-5 mb-2">
                    <label class="f-13 font-semibold text-gray-color mb-1" for="">Last Name</label>
                    <app-input 
                    onlyAlphabets
                    [inputName]="'plname'" 
                    [inputType]="'text'"
                    [inputId]="'plnameId'" 
                    [inputValue]="editProfileModel.personalDetails.lastName" 
                    (inputValueEmit)="editProfileModel.personalDetails.lastName = $event" 
                    (keydown.enter)="onKeydown($event, 'pcontactId')"
                        >
                    </app-input>
                </div> -->
                <div class="col-5 mb-2">
                    <label class="f-13 font-semibold text-gray-color mb-1" for="">Contact</label>
                    <app-input-with-select-dropdown
                        [inputName]="'pcontact'" 
                        [inputMaxlength]="15"
                        [inputType]="'text'" 
                        [inputId]="'pcontactId'"
                        [inputValue]="personalDetails?.contactNo" 
                        (inputValueEmit)="personalDetails.contactNo = $event"
                        [selectedDialCodeValue]="personalDetails?.dialCode"
                        (dialCodeValueChanged)="personalDetails.dialCode = $event"
                        (keydown.enter)="onKeydown($event, 'emailId')"
                    ></app-input-with-select-dropdown>
                </div>
               
                <div class="col-5">
                    <label class="f-13 font-semibold text-gray-color mb-1" for="">Email <span class="text-color-danger">*</span></label>
                    <app-input 
                        [inputName]="'emailId'" 
                        [inputType]="'email'"
                        [inputId]="'emailId'" 
                        [isEmailPattern]="true"
                        [inputValue]="personalDetails?.emailId" 
                        (inputValueEmit)="personalDetails.emailId = $event" 
                        [isRequired]="true"
                        [ngClass]="{'input-border-red' : ((personalDetailsForm?.form?.controls?.emailId?.touched || personalDetailsForm?.form?.controls?.emailId?.dirty) && personalDetailsForm?.form?.controls?.emailId?.invalid)}"
                        >
                    </app-input>
                    <div *ngIf="((personalDetailsForm?.form?.controls?.emailId?.touched || personalDetailsForm?.form?.controls?.emailId?.dirty) && personalDetailsForm?.form?.controls?.emailId?.invalid)" class="text-normal-red font-regular f-11 position-absolute">Please enter a valid email address</div>
                </div>
            </div>
           <button class="d-none" type="submit" (click)="UpdatepersonalDetails()" #submitButton>Save</button>
        </form>
        </div>
        <div class="col-3">
        </div>
    </div>
</div>

