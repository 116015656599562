import {
  AgColDefsI,
  AgGridI,
} from "./../../../shared/common-components/ag-grid/ag-grid.model";
import { CheckboxRendererComponent } from "./../../../shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { EditDeleteIconComponent } from "./../../../shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { TextRendererComponent } from "../../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { ContactColWidth, MailColWidth, NameColWidth,  StorageName } from "../../../shared/enum/common-enum";
import { paginationModel } from "../../../shared/components/common-model/common-model";
import { API_URL_GET_ALL_USER } from "../../../shared/url-helper/url-helper";
import { right } from "@popperjs/core";
import { StorageService } from "../../../shared/services/storage.service";

export class Usermanagment {}
// ******** User List start**********
const NameColumn: AgColDefsI = {
  headerName: "Name",
  field: "fullName",
  headerClass: "px-0 custom-column-group-cell ",
  sortable: true,
  lockPosition:true,
  suppressMovable: false,
  resizable: true,
  editable: false,
  cellClass: "px-0 custom-column-group-cell text-primary",
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:true},
  minWidth: NameColWidth.MinWidth,
  maxWidth:NameColWidth.MaxWidth,
};


const BranchColumn: AgColDefsI = {
  headerName: "Branch",
  field: "branchIds",
  resizable: true,
  sortable: false,
  editable: false,
  minWidth: NameColWidth.MinWidth,
  maxWidth:NameColWidth.MaxWidth,
  headerClass: "px-0 custom-column-group-cell",
  cellClass: "custom-column-group-cell customCellclass",
  cellRenderer: function (params) {
    const branchIdsArray = params.value;
    // Assuming you want to display id and name for each item in the array
    const displayText = branchIdsArray?.map(item => item.name).join(', ');
    return displayText;
  },
};

const MailIDColumn: AgColDefsI = {
  headerName: "Mail ID",
  field: "emailId",
  resizable: true,
  sortable: true,
  editable: false,
  minWidth: MailColWidth.MinWidth,
  maxWidth:MailColWidth.MaxWidth,
  cellRenderer: TextRendererComponent,
  headerClass: "px-0 custom-column-group-cell",
  cellClass: "px-2 custom-column-group-cell ",
};

const ContactColumn: AgColDefsI = {
  headerName: "Contact",
  field: "contactNo",
  resizable: true,
  sortable: true,
  editable: false,
  minWidth: ContactColWidth.MinWidth,
  maxWidth:ContactColWidth.MaxWidth,
  cellRenderer: TextRendererComponent,
  headerClass: "px-0 ag_header_end custom-column-group-cell",
  cellClass: "px-2 custom-column-group-cell  text-end",
};

const UserRoleColumn: AgColDefsI = {
  headerName: "User Role",
  field: "roleName",
  resizable: true,
  sortable: true,
  editable: false,
  minWidth: NameColWidth.MinWidth,
  maxWidth:NameColWidth.MaxWidth,
  headerClass: "px-0 custom-column-group-cell",
  cellClass: "px-2 custom-column-group-cell ",
   cellRenderer: TextRendererComponent,
};

const StatusColumn: AgColDefsI = {
  headerName: "Status",
  field: "active",
  resizable: false,
  sortable: false,
  editable: false,
  lockPosition:right,
  suppressMovable: false,
  headerClass: "px-0 custom-column-group-cell",
  cellClass: "px-1 custom-column-group-cell d-flex justify-content-center ",
  cellRenderer: CheckboxRendererComponent,
  cellRendererParams: {
    showLabel: false,
  },
  cellClassRules: {
    'non-editable-cell': params => !params.data.editable
  },
  minWidth: 80,
  maxWidth: 80,
};

const ActionColumn: AgColDefsI = {
  headerName: "",
  field: "",
  editable: false,
  lockPosition:right,
  suppressMovable: false,
  resizable: false,
  sortable:false,
  cellRenderer: EditDeleteIconComponent,
  cellRendererParams: (params: any) => {
    const storageService = new StorageService();
    const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
    return {
      hasDeletePermission: userPermissions?.userManagementCanDelete,
    };
  },
  headerClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
  cellClass: "custom-column-group-cell px-0 mt-1",
  minWidth: 50,
  maxWidth: 50,
};

export const ColDefsUsersList: AgColDefsI[] = [
  NameColumn,
  BranchColumn,
  MailIDColumn,
  ContactColumn,
  UserRoleColumn,
  StatusColumn,
  ActionColumn,
];

export const AgGridDataModelUsers: AgGridI = {
    colDefs: ColDefsUsersList,
    rowSelection: "multiple",
    storageName : StorageName.ADMINISTRATION_USER_MANAGEMENT_USER_GRID,
    filterStorageName: StorageName.FILTER_ADMINISTRATION_USER_MANAGEMENT_USER_GRID,
    sortStorageName: StorageName.SORT_ADMINISTRATION_USER_MANAGEMENT_USER_GRID,
    paginationStorageName : StorageName.PAGINATION_ADMINISTRATION_USER_MANAGEMENT_USER_GRID,
    rows:[],
    rowHeight:28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_USER,
    isServerSidepagination : true,
    showPagination: true,
  };
// ******** User List end**********

  export enum UserModalTitle {
    AddUser = "Add User",
    EditUser = "Edit User",
    AddRole = "Add Role",
    EditRole = "Edit Role",
  }
  
  
export class CreateUpdateUserModel {
  id                : string| null;
  fullName          : string| null;
  username          : string| null;
  password          : string| null;
  confirmPassword  ?: string| null;
  contactNo         : number| null;
  emailId           : string| null;
  roleId            : number| null;
  accountLedgerId   : number| null;
  dialCode          : string| null;
  branchIds         : any = [];
  isPasswordUpdate  : boolean = false;
  active            : boolean = true;
  isSendMail        : boolean;
}

export class GetallUserRequestModel  extends paginationModel{
  page          : number = 1;
  pageSize      : number = 25;
  exportAs:number;
  fromDate: string;
  toDate: string;
  filters:any;
}
// user region end 
  
// role region start

const RolesListColumnDef: AgColDefsI[] = [
    { headerName: "Role", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'name',
    cellClass:'px-1 custom-column-group-cell text-primary', minWidth: 200, flex:2,
    editable:false,
    lockPosition:true,
    suppressMovable: false,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  },
  { headerName: "Active", 
    headerCheckboxSelection: false, 
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'active',
    lockPosition:true,
    editable:false,
    sortable:true,
    suppressMovable: false,
    cellRenderer: CheckboxRendererComponent,
    cellRendererParams: { showLabel: false }, 
    cellClass:'custom-column-group-cell font-regular px-1', 
    minWidth: 250, flex:1 ,
    resizable:false,
    cellClassRules: {
      'non-editable-cell': params => !params.data.editable
    }
  }
  ,
  { 
  headerName: "", 
  editable:false,
  field:'editdeleteicon',
  cellRenderer: EditDeleteIconComponent,
  cellClass:' custom-column-group-cell d-flex align-items-center justify-content-center',
  // cellRendererParams :{focusOnColumn:'branchName' ,isEditRowOnEdit:true},
  sortable:true, 
  lockPosition:true,
  suppressMovable: false,
  resizable: false, 
  minWidth: 50, 
  maxWidth:50,
  cellRendererParams: (params: any) => {
    const storageService = new StorageService();
    const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
    return {
      hasDeletePermission: userPermissions?.userManagementCanDelete,focusOnColumn:'branchName' ,isEditRowOnEdit:true
    };
  },
}
  ,
  ];

  
export const AgGridDataModelRolesList: AgGridI = {
    colDefs: RolesListColumnDef,
    rowSelection: "multiple",
    storageName : StorageName.ADMINISTRATION_USER_MANAGEMENT_ROLES_GRID,
    sortStorageName: StorageName.SORT_ADMINISTRATION_USER_MANAGEMENT_ROLES_GRID,
    rowHeight:28,
  }


export class CreateRoleModel{
  id          : number;
  name        : string;
  active?     : boolean;
  isSystemGenerated : boolean;
}


// role region end


