import { Component, OnInit, Input } from '@angular/core';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-feather-icons',
  templateUrl: './feather-icons.component.html',
  styleUrls: ['./feather-icons.component.scss']
})
export class FeatherIconsComponent implements OnInit {

  @Input('icon') public icon;

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  visiblefeatherIcon(): boolean {
    if (this.icon == 'downArrow'
      || this.icon == 'dashboard'
      || this.icon == 'productMenu'
      || this.icon == 'transactionMenu'
      || this.icon == 'orderMenu'
      || this.icon == 'inventoryMenu'
      || this.icon == 'manufacturerMenu'
      || this.icon == 'finantialStatementMenu'
      || this.icon == 'reportMenu'
      || this.icon == 'settingMenu'
      || this.icon == 'administrationMenu'
      || this.icon == 'productOpening'
      || this.icon == 'accountLedger'
      || this.icon == 'parties'
      || this.icon == 'appraisal'
      || this.icon == 'saleInvoice'
      || this.icon == 'saleFixingDirect'
      || this.icon == 'salesQuotation'
      || this.icon == 'salesReturn'
      || this.icon == 'purchaseInvoice'
      || this.icon == 'purchaseFixingDirect'
      || this.icon == 'purchaseQuotation'
      || this.icon == 'paymentVoucher'
      || this.icon == 'purchaseReturn'
      || this.icon == 'receiptVoucher'
      || this.icon == 'materialIn'
      || this.icon == 'materialOut'
      || this.icon == 'deliveryNote'
      || this.icon == 'oldJewelryScrapInvoice'
      || this.icon == 'oldJewelryScrap'
      || this.icon == 'materialIssue'
      || this.icon == 'materialReceive'
      || this.icon == 'creditNote'
      || this.icon == 'debitNote'
      || this.icon == 'stockJournalOption1'
      || this.icon == 'stockJournalOption2'
      || this.icon == 'pdcEntry'
      || this.icon == 'countraVoucher'
      || this.icon == 'journalVoucher'
      || this.icon == 'repairInvoice'
      || this.icon == 'investmentLayawaysFund'
      || this.icon == 'expenses'
      || this.icon == 'loans'
      || this.icon == 'bankReconciliation'
      || this.icon == 'salesOrder'
      || this.icon == 'repairOrder'
      || this.icon == 'saleRepairOrderProcess'
      || this.icon == 'purchaseOrder'
      || this.icon == 'jobworkOrderManufacturing'
      || this.icon == 'jobworkOrderOutsource'
      || this.icon == 'catalogQuotation'
      || this.icon == 'jewelleryCatalog'
      || this.icon == 'physicalStock'
      || this.icon == 'memoConsignmentIn'
      || this.icon == 'memoconsignmentOut' 
      || this.icon == 'stockHistory'
      || this.icon == 'memoConsignmentItems'
      || this.icon == 'goldSilverAnalysis'
      || this.icon == 'diamondStoneWeightReport'
      || this.icon == 'goldSilver'
      || this.icon == 'diamondStone'
      || this.icon == 'imitationWatches'
      || this.icon == 'rfid'
      || this.icon == 'barcode'
      || this.icon == 'stockValuation'
      || this.icon == 'stockSummeryReport'
      || this.icon == 'stockTransfer'
      || this.icon == 'stockTransferHistory'
      || this.icon == 'department'
      || this.icon == 'departmentReport'
      || this.icon == 'manufacturingProcess'
      || this.icon == 'jobworkQueueReport'
      || this.icon == 'lossTracking'
      || this.icon == 'closingReport'
      || this.icon == 'workLogReport'
      || this.icon == 'jobCardReport'
      || this.icon == 'jobCardPrint'
      || this.icon == 'trialBalance'
      || this.icon == 'balanceSheet'
      || this.icon == 'profitLoss'
      || this.icon == 'cashFlow'
      || this.icon == 'fundFlow'
      || this.icon == 'taxReturn'
      || this.icon == 'salesAnalysis'
      || this.icon == 'transactionReport'
      || this.icon == 'accountLedgerReport'
      || this.icon == 'dayReport'
      || this.icon == 'cashBankBook'
      || this.icon == 'ageingReport'
      || this.icon == 'dailySalesCollectionSummery'
      || this.icon == 'borrowersReport'
      || this.icon == 'kycReport'
      || this.icon == 'ledgerBalanceReport'
      || this.icon == 'fixingPosition'
      || this.icon == 'setSoftware'
      || this.icon == 'voucherType'
      || this.icon == 'dataImportExport'
      || this.icon == 'dataExportInTally'
      || this.icon == 'userManagement'
      || this.icon == 'masters'
      || this.icon == 'activityLog'
      || this.icon == 'crm'
      || this.icon == 'addAppraisal'
      || this.icon == 'jobworkInvoice'
      || this.icon == 'jobworkOrder'
      || this.icon == 'purchaseFixing'
      || this.icon == 'salesFixing'
      || this.icon == 'jewelleryCatalogDetails'
      || this.icon == 'chartofAccount'
      || this.icon == 'jobworkQueue'

    ) {
      return false
    } else return true
  }
}
