import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { Select2Data, Select2SearchEvent, Select2UpdateEvent } from 'ng-select2-component';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';


@Component({
  selector: 'app-ag-grid-custom-select2',
  templateUrl: './ag-grid-custom-select2.component.html',
  styleUrls: ['./ag-grid-custom-select2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AgGridCustomSelect2Component implements ICellRendererAngularComp, ICellEditorAngularComp {
  @Output() keyEnter: EventEmitter<any> = new EventEmitter<any>();
  params: any;
  dropdown: any[] = [];
  data3: Select2Data = [];
  @Input() whichDropdown = StorageName.ACCOUNTLEDGER_DROPDOWN;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  private debounceTimer: any;
  overlay = false;
  id: any;
  @ViewChild('select2Ref') select2Ref: any; 
  // Implement the ICellRendererAngularComp methods
  agInit(params: any): void {
    // This is called by AG-Grid to initialize the cell renderer
    this.params = params;
    // Initialize other properties as needed
  }

  refresh(params: any): boolean {
    // This is called by AG-Grid when the cell needs to be refreshed
    // Implement if necessary, return true if refresh successful
    return true;
  }

  isPopup?(): boolean {
    return true;
  }
  getPopupPosition?(): any {
    return 'over'
  }
  isCancelBeforeStart?(): boolean {
    return false;
  }
  isCancelAfterEnd?(): boolean {
    return false
  }
  focusIn?(): void {
  
  }
  focusOut?(): void {
  
  }
  afterGuiAttached?(): void {
    this.getValue()
  }
  
  


  getValue(): any {
    return this.params.value;
  }


  constructor(public storageService: StorageService,private renderer: Renderer2, private el: ElementRef
    ) {
      
    }

  ngAfterViewInit() {
    const targetDiv = this.el.nativeElement.querySelector('.select2-results');

    if (targetDiv) {
      const newDiv = this.renderer.createElement('div');
      if(this.params.colDef.headerComponentParams.filterParams == StorageName.ACCOUNTLEDGER_DROPDOWN){
      newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-8 ps-1 text-truncate">Name</div>
            <div class="col-4 pe-0 ps-0 text-truncate">Contact</div>
          </div>
        </div>
      `;
      }else if(this.params.colDef.headerComponentParams.filterParams == StorageName.PRODUCT_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-8 pe-0 ps-0 text-truncate">Product</div>
            <div class="col-4 pe-3 text-truncate text-end">Weight</div>
          </div>
        </div>
      `;
      }
      else if(this.params.colDef.headerComponentParams.filterParams == StorageName.SALEPERSON_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-12 px-1 text-truncate">Sale Person</div>
          </div>
        </div>
      `;
      }
      else if(this.params.colDef.headerComponentParams.filterParams == StorageName.INVOICE_NO_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-12 px-1 text-truncate">Invoice No.</div>
          </div>
        </div>
      `;
      }
  
      this.renderer.insertBefore(targetDiv, newDiv, targetDiv.firstChild);
    } 
    // this.keydownListener = this.renderer.listen('document', 'keydown', (event) => {    //need to improve
    //   this.onKeydown(event);
    // });

    this.addEnterKeydownListener('select2-search__field', (event) => {
      this.onKeydown(event);
    });
  }


  setDataSource(datasorce ,newCreatedOptionId?): void {
    this.dropdown = datasorce;
    

    this.data3 = this.dropdown as Select2Data;

    if(this.params.colDef.headerComponentParams.filterParams === StorageName.ACCOUNTLEDGER_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-8 pe-0 text-truncate">' + item.name + '</div>' +
          '<div class="col-4 pe-0">' + item.extra2 + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.params.colDef.headerComponentParams.filterParams === StorageName.PRODUCT_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-8 pe-0 text-truncate">' + item.name + '</div>' +
          '<div class="col-4 pe-0 text-truncate text-end">' + item.extra2 + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.params.colDef.headerComponentParams.filterParams === StorageName.SALEPERSON_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-12 pe-0 text-truncate">' + item.name + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.params.colDef.headerComponentParams.filterParams === StorageName.INVOICE_NO_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-12 pe-0 text-truncate">' + item.name + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
   
    this.data3 = [
      {
        value: '',
        label: ''
      },

      ...this.data3, // Rest of the options
    ];
    

    if(newCreatedOptionId){
      this.setDropdownText(newCreatedOptionId)
    }
  }

  ngOnInit() {

    //  this.setAccountLedgerDropdownText();
  }

  getInvoiceResponse(result) {
    //this.selectedValue = result.invoiceModel.accountLedgerId;
    //  this.displayText = result.invoiceModel.accountLedgerName;
    this.setDropdownText(result);
  }


  update(key: string, event: Select2UpdateEvent<any>) {
    this[key] = event.value;
    this.setDropdownText(event.value);
    this.valueChanged.emit(event.value);
  }

  setDropdownText(value) {
    this.id = value;
  }

  search(key: string, event: Select2SearchEvent) {
    event.filteredData(
      event.search
          ? event.data.filter(option => option.label.toLowerCase().includes(event.search.toLowerCase()))
          : event.data,
        );
}

close(event){
  
}

onKeydown(event) {
  if(event.key == 'Enter'){
    this.select2Ref?.toggleOpenAndClose(false, false);
    this.focusOnMatchingOption(event.target.value);
    
}
}

ngOnDestroy() {
}


focusOnMatchingOption(searchKey: string) {
  if (this.select2Ref) {
    const matchingOption:any = this.select2Ref?.filteredData.find(option => {
      return option.label.toString()?.toLowerCase()?.includes(searchKey?.toLowerCase());
    });
      if(matchingOption){
        if(this.select2Ref?.filteredData?.length == 1){
          this.setDropdownText(matchingOption?.value)
      }}else{
          this.keyEnter.emit(searchKey);
      }
    }
  }

  addEnterKeydownListener(className: string, callback: (event: KeyboardEvent) => void) {
    const elements = document.querySelectorAll('.' + className);
    elements.forEach((element: HTMLElement) => {
      const listener = this.renderer.listen(element, 'keydown', (event) => {
        if (event.key === 'Enter') {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(() => {
            callback(event);
          }, 300); // Adjust the debounce delay as needed
        }
      });
    });
  }
 


}
