import { TotalSectionService } from './total-section.service';
import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { Subscription, catchError } from 'rxjs';
import { ResponseModel } from '../../models/response-model.model';
import { ModalPopupSize } from "src/app/shared/models/common.model";
import { AdditionalAmountComponent } from '../../../shared/common-components/common-voucher/popup/additional-amount/additional-amount.component';
import { CommonService } from '../../services/common.service';
import { StorageService } from '../../services/storage.service';
import { StorageName } from '../../enum/common-enum';
import { NotificationService } from '../../services/notification.service';
import { FundTransferComponent } from '../common-voucher/popup/fund-transfer/fund-transfer.component';
@Component({
  selector: 'app-total-section',
  templateUrl: './total-section.component.html',
  styleUrls: ['./total-section.component.scss']
})
export class TotalSectionComponent extends CommonService implements OnDestroy {
  isRoundOff: boolean = false;
  currencyExchangeDropdown: any;
  @Input() formName: string;
  @Input() formModel: any;
  @Input() totalSectionModel: any;
  @Input() hideSection: boolean = false;
  @Output() onFundTransfer: EventEmitter<any> = new EventEmitter<any>();
  isCollapsed: boolean = true;
  count = 0
  grandTotal = 0
  callInvoiceCalculationSubscription: Subscription;
  resetInvoiceSubscription: Subscription;
  getInvoiceSubscription: Subscription;
  constructor(public totalSectionService: TotalSectionService,
    public renderer: Renderer2,
    public storageService: StorageService,
    public notificationService: NotificationService,
  ) {
    super(renderer);
    this.callInvoiceCalculationSubscription = this.storageService.callInvoiceCalculation.subscribe((result) => {
      if (result != undefined)
        this.getCalculatedInvoiceDetails(result);
    });
    this.resetInvoiceSubscription = this.storageService.resetInvoice.subscribe((result) => {
      if (result != undefined)
        this.resetInvoiceResponse(result);
    });
    this.getInvoiceSubscription = this.storageService.getInvoice.subscribe((result) => {
      if (result != undefined)
        if(result?.response?.invoiceModel != undefined)
        this.getInvoiceResponse(result?.response);
    });
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  ngOnInit() {
    this.currencyExchangeDropdown = this.storageService.retrieve(StorageName.CURRENCY_EXCHANGE);
  }

  getInvoiceResponse(result) {
    this.assignTotalSectionValues(result?.invoiceModel);
    this.formModel.additionalAmountList = result?.additionalAmountList;
  }

  getCalculatedInvoiceDetails(model, fieldName = "") {
    

    if (model == undefined)
      model = this.formModel;
    var currencyexhangeRate = this.storageService.retrieve(StorageName.CURRENCY_EXCHANGE)?.find(a => a.id?.toString() == this.formModel?.invoiceModel?.exchangeRateId)?.extra1 ?? undefined;
    if (currencyexhangeRate) {
      this.formModel.invoiceModel.currencyExchangeRate = (currencyexhangeRate == null || currencyexhangeRate === '') ? 0 : currencyexhangeRate;
    }
    this.totalSectionService.getCalculatedInvoiceDetails(model)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess) {
          this.assignTotalSectionValues(res.response.invoiceModel, fieldName);
          this.formModel.invoiceTaxDetail = res.response.invoiceTaxDetail;
          this.formModel.paymentList = res.response.paymentList;
          this.storageService.onInvoiceCalculation.next(this.formModel);
        }
      });
  }
  openFundTransferPopup() {

    this.notificationService.openModalPopup(FundTransferComponent,this.formModel.fundTransfer, "Fund Transfer",ModalPopupSize.LG , "", false, true).then(
      (resultData) => {
        if (resultData) {
          this.formModel.invoiceModel.fundTransferAmount = resultData?.data.totalAmount;
          this.formModel.fundTransferIds = resultData?.data?.fundTransferIds;
          this.getCalculatedInvoiceDetails(this.formModel)
          // this.onFundTransfer.emit(this.formModel);
        }
      }
    );
  }


  assignTotalSectionValues(res, fieldName = "") {
    this.formModel.invoiceModel.amount = this.fixAmountDecimal(res?.amount);
    this.formModel.invoiceModel.totalAmount = this.fixAmountDecimal(res?.totalAmount);
    this.formModel.invoiceModel.additionalAmount = this.fixAmountDecimal(res?.additionalAmount);
    this.formModel.invoiceModel.netAmount = this.fixAmountDecimal(res?.netAmount);
    this.formModel.invoiceModel.genRewardPt = this.fixAmountDecimal(res?.genRewardPt);
    this.formModel.invoiceModel.genRewardAmount = this.fixAmountDecimal(res?.genRewardAmount);
    if (fieldName != 'invoiceDiscount')
      this.formModel.invoiceModel.invoiceDiscount = this.fixAmountDecimal(res?.invoiceDiscount);
    if (fieldName != 'redeemPt')
      this.formModel.invoiceModel.redeemPt = this.fixAmountDecimal(res?.redeemPt);
    this.formModel.invoiceModel.redeemAmount = this.fixAmountDecimal(res?.redeemAmount);
    this.formModel.invoiceModel.redeemBalancePoint = this.fixAmountDecimal(res?.redeemBalancePoint);
    this.formModel.invoiceModel.advancePayment = this.fixAmountDecimal(res?.advancePayment);
    this.formModel.invoiceModel.grandTotal = this.fixAmountDecimal(res?.grandTotal);
    this.formModel.invoiceModel.metalAmount = this.fixAmountDecimal(res?.metalAmount);
    if (fieldName != 'roundOFFValue')
      this.formModel.invoiceModel.roundOFFValue = this.fixAmountDecimal(res?.roundOFFValue);
    this.formModel.invoiceModel.currencyExchangeAmount = this.fixAmountDecimal(res?.currencyExchangeAmount);
    this.formModel.invoiceModel.returnAmount = this.fixAmountDecimal(res?.returnAmount);
    this.formModel.invoiceModel.creditNoteAmount = this.fixAmountDecimal(res?.creditNoteAmount);
    this.formModel.invoiceModel.balanceAmount = this.fixAmountDecimal(res?.balanceAmount);
    this.formModel.invoiceModel.balanceGrossGold = this.fixWeightDecimal(res?.balanceGrossGold);
    this.formModel.invoiceModel.balanceGrossSilver = this.fixWeightDecimal(res?.balanceGrossSilver);
    this.formModel.invoiceModel.balancePureGold = this.fixWeightDecimal(res?.balancePureGold);
    this.formModel.invoiceModel.balancePureSilver = this.fixWeightDecimal(res?.balancePureSilver);
    this.formModel.invoiceModel.totalGrossGold = this.fixWeightDecimal(res?.totalGrossGold);
    this.formModel.invoiceModel.totalGrossSilver = this.fixWeightDecimal(res?.totalGrossSilver);
    this.formModel.invoiceModel.totalPureGold = this.fixWeightDecimal(res?.totalPureGold);
    this.formModel.invoiceModel.totalPureSilver = this.fixWeightDecimal(res?.totalPureSilver);
  }
  
  openAdditionalAmount() {
    const data = { modalTitle: "Additional Amount",additionalAmountList: this.formModel.additionalAmountList};
    this.notificationService.openModalPopup(AdditionalAmountComponent, data,undefined, ModalPopupSize.MD, "", false, true)
      .then((resultData: any) => {
        if (resultData) {
          this.formModel.additionalAmountList = resultData.response?.api.getModel().rowsToDisplay.map(a => a.data) ?? [];
          this.getCalculatedInvoiceDetails(this.formModel);
        }
      });
  }
  

  resetInvoiceResponse(formName) {
    if (formName != undefined) {
      // this.storageService.store("SaleInvoiceId",0);
      this.formModel = {};
      this.formModel.invoiceModel = {};
    }
  }

  handleError(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  ngOnDestroy() {
    this.resetInvoiceSubscription.unsubscribe();
    this.getInvoiceSubscription.unsubscribe();
    this.callInvoiceCalculationSubscription.unsubscribe();
  }

  onRoundOffCheck(number, checked) {
    if (checked == true) {
      var number = this.formModel.invoiceModel.grandTotal;
      // Extract the decimal part
      const decimalPart: number = number % 1;
      // Round up the decimal part to two decimal places
      const roundUpAmount: number = decimalPart * 100 / 100;
      // If the original number is positive, subtract the round-up amount
      // If the original number is negative, add the round-up amount
      const result: number = number >= 0 ? number - roundUpAmount : number + roundUpAmount;
      this.formModel.invoiceModel.roundOFFValue = this.fixAmountDecimal(roundUpAmount);
      this.formModel.invoiceModel.grandTotal = (result * 100) / 100;
    }
    else {
      this.formModel.invoiceModel.grandTotal = this.formModel.invoiceModel.grandTotal + Number(this.formModel.invoiceModel.roundOFFValue);
      this.formModel.invoiceModel.roundOFFValue = 0;
    }
    this.formModel.invoiceModel.balanceAmount = this.fixAmountDecimal((this.formModel.invoiceModel.grandTotal) + Number(this.formModel.invoiceModel.metalAmount) - Number(this.formModel.invoiceModel.amountPaid ?? 0));
    this.formModel.invoiceModel.currencyExchangeAmount = this.fixAmountDecimal(Number(this.formModel.invoiceModel.grandTotal) * Number(this.formModel.invoiceModel.CurrencyExchangeRate ?? 0));
    // this.getCalculatedInvoiceDetails(this.formModel);
  }

}