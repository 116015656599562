<!-- <svg class="stroke-icon" *ngIf="getSvgType(); else fill">
  <use attr.xlink:href="assets/svg/icon-sprite.svg#stroke-{{icon}}"></use>
</svg>
<ng-template  #fill>
  <svg class="fill-icon">
    <use attr.xlink:href="assets/svg/icon-sprite.svg#fill-{{icon}}"></use>
  </svg>
</ng-template> -->
<ng-container *ngIf="svgIcon">
  <span 
  [innerHTML]="svgIcon" 
  class="d-flex align-items-center justify-content-center"
  ></span>
</ng-container>