import { Component, Input, OnInit, Renderer2, ViewChild } from "@angular/core";
import {
  RegisterUserRequestModel,
  RegisteredUserVM,
} from "../business-registration.model";
import { NgForm } from "@angular/forms";
import { CommonService } from "src/app/shared/services/common.service";
import { UsermanagmentService } from "src/app/components/administration/usermanagment/usermanagment.service";
import { StorageName } from "src/app/shared/enum/common-enum";
import { SendDataService } from "src/app/shared/services/send-data.service";
import { StorageService } from "src/app/shared/services/storage.service";

@Component({
  selector: "app-user-details-registration",
  templateUrl: "./user-details-registration.component.html",
  styleUrls: ["./user-details-registration.component.scss"],
})
export class UserDetailsRegistrationComponent extends CommonService implements OnInit{
  @Input("user") usermodel: RegisteredUserVM = new RegisteredUserVM();
  @ViewChild("UserForm") UserForm!: NgForm;

  submitUser      : boolean = false;
  confirmPassWord : any;
  formName = StorageName.FORMNAME_BUSINESSREGISTRATION;
  storageName = StorageName;
  getAllDropdownModel : any = {}; 
  constructor( public storageService:StorageService ,public sendDataService  : SendDataService,public renderer: Renderer2,public usermanagmentService: UsermanagmentService,)
   {
    super(renderer);
   }
   ngOnInit(): void 
   {
    this.sendDataService.displayPageName(this.formName)
   }
   generateRandumPassword() {
    const passwordOptions = {
      letters: false,
      numbers: true,
      symbols: false
    };
    const passwordLength = 6;
    const newPassword = this.usermanagmentService.generatePassword(passwordLength, passwordOptions);
    this.usermodel.password = newPassword;
    this.confirmPassWord = newPassword;
  }
  fillDropdownData(event){
    this.getAllDropdownModel.dialCodeDropdown = event?.dialCodeDropdown;
   }
   
  selectedDates(event?: any) {
    if (event) {
    const date = new Date(event);
    this.usermodel.dob = date.toLocaleDateString('en-US');  
    }
  }
  
}
