
<app-advanced-filter-pop-up  (applyFilter)="onClickAdvancedFilter()" (clearFilter)="clearFields()">
  <form class="theme-form">
      <div class="modal-body mx-2 py-0">
          <div class="row mx-0">
              <div class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                 <div  class="col d-flex align-items-center">
                      <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                          Date Range
                      </div>
                      <div class="col">
                          <app-range-datepicker 
                          #rangePicker [startDate]="advanceFilterRequest.fromDate"  
                          [endDate]="advanceFilterRequest.toDate" 
                          (startDateEmit)="advanceFilterRequest.fromDate = $event;" 
                           (endDateEmit)="advanceFilterRequest.toDate = $event"  >
                          </app-range-datepicker>
                      </div>
                  </div>
                  <div class="col d-flex align-items-center">
                    <div class="col d-flex align-items-center">
                        <div class="w-135px f-14 font-semibold text-gray-color text-nowrap mb-0">
                          Invoice No.
                       </div>
                       <div class="col">
                        <app-input 
                        [inputName]="'invoiceNo'" 
                        [inputType]="'text'" 
                        [inputId]="'invoiceNo'"
                        [isRequired]="false"
                        [inputValue]="advanceFilterRequest?.invoiceNo" 
                        (inputValueEmit)="advanceFilterRequest.invoiceNo = $event"
                        [disabled]="data?.modalFilterData?.elementsToHide"
                        >
                        </app-input>
                       </div>
                      </div>
                  </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                  Branch
                </div>
               <div class="col">
                  <app-ng-multi-select-dropdown
                  [isShowlable]="false" 
                  [isPlusIcon]="false" 
                  [name]="'branchId'" 
                  [getAllDropdownModel]="data.getAllDropdownModel?.branchDropdown" 
                  [selectedIds]="selectedBranchIds"
                  [data]="data.getAllDropdownModel?.branchDropdown" 
                  [placeholder]="'Select Branch'"
                  (onSelectItem)="advanceFilterRequest.selectedBranches= $event" 
                  [id]="'branchId'" 
                  >
               </app-ng-multi-select-dropdown>
              </div>
              </div>
              <div class="col-12 px-0 d-flex align-items-center mb-2">
                  <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Ledger
                  </div>
                  <div class="col">
                      <app-ng-multi-select-dropdown
                      [isShowlable]="false" 
                      [isPlusIcon]="false" 
                      [name]="'accountLedger'" 
                      [selectedIds]="selectedLedgerIds"
                      [data]="filteredLedgerDropdown" 
                      [placeholder]="'Select Ledger'"
                      (onSelectItem)="advanceFilterRequest.selectedAccountLedgers= $event" 
                      [id]="'accountLedger'" 
                      >
                  </app-ng-multi-select-dropdown>
                  </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 d-flex align-items-center mb-2">
                  <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                   Group
                  </div>
                  <div class="col">
                    <app-ng-multi-select-dropdown
                    [isShowlable]="false" 
                    [isPlusIcon]="false" 
                    [name]="'groupId'" 
                    [getAllDropdownModel]="" 
                    [selectedIds]="selectedGroupIds"
                    [data]="data.getAllDropdownModel?.accountGroupDropdown" 
                    [placeholder]="'Select Group'"
                    [id]="'groupId'" 
                    (onSelectItem)="advanceFilterRequest.selectedGroups= $event" 
                    >
                    </app-ng-multi-select-dropdown>
                  </div>
              </div>
              <div class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                  <div class="col d-flex align-items-center">
                       <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                       Ledger Type
                       </div>
                       <div class="col">
                        <app-ng-multi-select-dropdown
                        [isShowlable]="false" 
                        [isPlusIcon]="false" 
                        [name]="'ledgerType'" 
                        [selectedIds]="selectedLegerTypesIds"
                        [getAllDropdownModel]="" 
                        [data]="data.getAllDropdownModel?.ledgerTypeDropdown" 
                        [placeholder]="'Select Ledger Type'"
                        (onSelectItem)="advanceFilterRequest.selectedLedgerIds= $event" 
                        [disabled]="data?.modalFilterData?.elementsToHide"
                        [id]="'ledgerTypeId'" 
                        >
                    </app-ng-multi-select-dropdown>
                       </div>
                  </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                <div class="col d-flex align-items-center">
                     <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Type of Voucher
                     </div>
                     <div class="col">
                      <app-ng-multi-select-dropdown
                      [isShowlable]="false" 
                      [isPlusIcon]="false" 
                      [name]="'VoucherType'" 
                      [getAllDropdownModel]="" 
                      [selectedIds]="selectedVoucherTypeIds"
                      [data]="data.getAllDropdownModel?.voucherTypeDropdown" 
                      [placeholder]="'Select Type of Voucher'"
                      (onSelectItem)="advanceFilterRequest.selectedVoucherTypes= $event" 
                      [id]="'VoucherTypeId'" 
                      >
                      </app-ng-multi-select-dropdown>
                     </div>
                </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                <div class="col d-flex align-items-center">
                     <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Type of Against Voucher
                     </div>
                    <div class="col">
                    <app-ng-multi-select-dropdown
                    [isShowlable]="false" 
                    [isPlusIcon]="false" 
                    [name]="'againstVoucherTypeId'" 
                    [selectedIds]="selectedagainstVoucherTypeId"
                    [data]="data.getAllDropdownModel?.voucherTypeDropdown" 
                    [placeholder]="'Select Type Of Against Voucher'"
                    (onSelectItem)="advanceFilterRequest.selectedagainstVoucherType= $event ;onChangeAgainstVoucherType($event)" 
                    [id]="'againstVoucherTypeId'" 
                    >
                  </app-ng-multi-select-dropdown>
                  </div>
                 </div>
              </div>     
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                <div class="col d-flex align-items-center">
                     <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                       Against Inv.No
                     </div>
                     <div class="col">
                      <app-ng-multi-select-dropdown
                        [isShowlable]="false" 
                        [isPlusIcon]="false" 
                        [name]="'againstInvNo'" 
                        [getAllDropdownModel]="" 
                        [selectedIds]="selectedAgainstIds"
                        [data]="filterAgainstDropdown" 
                        [placeholder]="'Select Against Inv No'"
                        (onSelectItem)="advanceFilterRequest.selectedAgainstNos= $event" 
                        [id]="'againstInvNoId'" 
                        >
                      </app-ng-multi-select-dropdown>
                     </div>
                </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 ps-5 gap-2 checkbox-align d-flex align-items-center mb-2" >
                <app-checkbox
                id="onlyBalance"
                [lableName]="'Only Balance'"
                [dyanamicId]="'onlyBalance'"
                [status]="advanceFilterRequest?.onlyBalance === 'true'"
                (check)="advanceFilterRequest.onlyBalance = $event ? 'true' : 'false'">
              </app-checkbox>
              </div>
          </div>  
      </div>
  </form>
  </app-advanced-filter-pop-up>
  

