import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { AgGridService } from '../ag-grid.service';
import {PreviewIconService} from './preview-icon.service'

@Component({
  selector: 'app-preview-icon',
  templateUrl: './preview-icon.component.html',
  styleUrls: ['./preview-icon.component.scss']
})
export class PreviewIconComponent {
  public params: ICellRendererParams;
  constructor(
    public toastr                   : ToastrService,
    public agGridService            : AgGridService ,
    public previewiconService       : PreviewIconService)
    {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onPreviewClick(params): void {
    this.previewiconService.triggerpreviewClick(params);
    const selectedData = [this.params.node.data];
    this.agGridService.preview.next(true);
    
    
  }

}
