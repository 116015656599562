import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { AgGridService } from '../ag-grid.service';
import { PlusIconService } from '../plus-icon/plus-icon.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { MakingChargeType, MetalGridMoreButtonId, StorageName } from 'src/app/shared/enum/common-enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { ColumnFilterComponent } from '../column-filter/column-filter.component';
import { Subscription } from 'rxjs';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { BlockUnblockStatus } from 'src/app/components/administration/crm/crm.model';
import { MetalType } from 'src/app/shared/models/common.model';
import { KeyCode } from '../../../enum/common-enum';
import { InvoiceService } from '../../../../components/transaction/invoice/invoice.service'
import { MetalSectionChildComponent } from '../../metal-section-child/metal-section-child.component';
import { TextProcessingService } from '../../../services/text-processing.service';
export interface ICustomHeaderParams {
  menuIcon: string;
  enablePlusIcon : boolean | undefined;
  isRequired:boolean;
  moreButton:boolean;
  moreaddButton:boolean;
  selectAll:boolean;
  isCommonHeaderCheckbox:boolean;
  isContactGroupCheckbox:boolean;
  MaterialIssue:boolean;
  PartiesFilterIcon:boolean;
  uploadButton:boolean;
  enablePlusIconLight:boolean;
  enableSorting:boolean;
  displayName: string;
  columnName: string;
  rows: any[];
  oldrows: any[];
  eventOccurs?: any;
  isSort: boolean;
  isFilter:boolean;
  floatingFilter:boolean;
  popUpfloatingFilter:boolean;
  floatingDropDown:boolean;
  floatingMultiselectDropDown:boolean;
  gridName:any;
  filterValueColumnName?:string;
  filterIdColumnName?:string;
  initialRows?:string;
  filterStorageName?:string;
  cellDisplayType?:string;
  isDateFilterShow?:boolean;
  isServerSidepagination?:boolean;
  tableIndex?:number;
  controllerName : string;
  isSelected : boolean;
  onCustomHeaderEvent : any;
  arrowUpDownIcon: boolean;
  downloadIcon:boolean;
  storageName?:string;
  moreButtonId?:string;
  hideStoneButton:boolean;
}

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})

export class CustomHeaderComponent extends CommonService  implements IHeaderAngularComp {
  public params!: IHeaderParams & ICustomHeaderParams;
  public ascSort = 'inactive';
  public descSort = 'inactive';
  public noSort = 'inactive';
  public headerText : string;
  private subscription: Subscription;
  @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;
  @ViewChild(ColumnFilterComponent) columnFilter: ColumnFilterComponent;
  arrowUpDown: boolean = true;
  @ViewChild(MetalSectionChildComponent, { static: true })
  metalSectionChildComponent!: MetalSectionChildComponent;

  constructor(public sendDataService : SendDataService, private agGridService : AgGridService,private plusIconService: PlusIconService,private storageService: StorageService,
    public renderer: Renderer2, public invoiceService: InvoiceService,public textProcessingService : TextProcessingService
  ){
    super(renderer);
    this.subscription = this.sendDataService?.resetColumnFilter$.subscribe(() => {
      this.resetColumnFilterData();
    });
  }


  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    // var colId = this.params.column.getColId();
    // var systemFields = this.storageService.retrieve(StorageName.STORAGE_SYSTEM_FIELDS);
    // this.headerText = systemFields.filter(a=> this.firstLetterSmallCaps(a.systemName) == this.params.column.getColId())[0].name;
    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  createSortArray(col, sortId,gridName) {
    var maxSortIndex = 0;
    var listOfSortModel = this.storageService.retrieve(gridName);
    if (listOfSortModel == undefined)
      listOfSortModel = [];
    if (listOfSortModel.length > 0)
      maxSortIndex = Math.max(...listOfSortModel.map(o => o.sortIndex));
    var data = listOfSortModel.filter(a => a.colId == col);
    if (data.length != 0) {
      data[0].sort = sortId;
      data[0].sortIndex = data[0].sortIndex != maxSortIndex ? maxSortIndex + 1 : maxSortIndex;
    }
    else listOfSortModel.push({ colId: col, sort: sortId, sortIndex: maxSortIndex + 1 })
    this.storageService.store(gridName, listOfSortModel);
    const customEvent = new CustomEvent('sortIconClick', {
      detail: { data: listOfSortModel },
    });
    this.params.api.dispatchEvent(customEvent);
  }


  goToGoldTab()
  {
    if (this.params.onCustomHeaderEvent) 
      this.params.onCustomHeaderEvent(this);
    
  }

  goToStoneTab()
  {
    if (this.params.onCustomHeaderEvent) 
      this.params.onCustomHeaderEvent(this);
    
  }
  onMenuClicked(event) {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  } 

 
  addRow(params) {
    const storeData =  this.storageService.retrieve(StorageName.MAKINGCHARGESTYPE_DROPDOWN);
    var makingTypeIdDefault =  storeData?.find(a => a.extra1 === 'true')?.id || MakingChargeType.FIX;
   this.params = params;
    var data : any = {
      quantity : 1,
      added : true,
      edited : false,
      deleted : false,
      purityPer : '1.0',
      makingTypeId : makingTypeIdDefault
    }
    var metals = this.storageService.retrieve(StorageName.METAL_DROPDOWN).filter(a=>a.extra2 != null );
    switch(params.moreButtonId)
    {
      case MetalGridMoreButtonId.Gold:
        data.discountPer = this.textProcessingService.fixAmountDecimal(metals?.filter(a=> Number(a.id) == MetalType.Gold)[0]?.extra2);
        break;
      case MetalGridMoreButtonId.Silver:
        data.discountPer = this.textProcessingService.fixAmountDecimal(metals?.filter(a=>Number(a.id) == MetalType.Silver)[0]?.extra2);
        break;
      case MetalGridMoreButtonId.Diamond:
        data.discountPer = this.textProcessingService.fixAmountDecimal(metals?.filter(a=>Number(a.id) == MetalType.Diamond)[0]?.extra2);
        break;
      case MetalGridMoreButtonId.ImmitationOrWatches:
        data.discountPer = this.textProcessingService.fixAmountDecimal(metals?.filter(a=>Number(a.id) == MetalType.Imitation)[0]?.extra2);
        break;
    }
    this.agGridService.appendNewRow(params,data);
    this.agGridService.nextRowAdded.next(params);
  }

  onSortChanged(order = undefined) {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column?.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column?.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
   var storageName = this.params.column.getColDef().headerComponentParams;
   if(order != undefined)
    this.createSortArray(this.params.column.getColId(),order,storageName.sortStorageName)
  }

  // onSortRequested(order: 'asc' | 'desc' | null, event: any) {
  //   this.params.setSort(order, event.shiftKey);
  // }
  
onSortRequested(order: 'asc' | 'desc' | null, event: any) {
  if(this.params.isServerSidepagination == false)
    this.sortData(order);
  this.onSortChanged(order);
}

  sortData(order: 'asc' | 'desc' | null) {
    const allRows = [];
    const columnField = this.params?.column.getColId();
    this.params.api.forEachNode(node => allRows.push(node?.data));
    
    const isStringColumn = typeof allRows[0][columnField] === 'string';
    if (order === 'asc') {
      if (isStringColumn) {
        allRows.sort((a, b) => a[columnField]?.localeCompare(b[columnField], 'en', { sensitivity: 'base' }));
      } else {
        allRows.sort((a, b) => a[columnField] - b[columnField]);
      }
    } else if (order === 'desc') {
      if (isStringColumn) {
        allRows.sort((a, b) => b[columnField]?.localeCompare(a[columnField], 'en', { sensitivity: 'base' }));
      } else {
        allRows.sort((a, b) => b[columnField] - a[columnField]);
      }
    }
    this.params.api.setRowData(allRows);
  }

  onIconClick() {
    // withoutCustomeEvent
    this.plusIconService.emitPlusIconClick(this.params); 
    // withCustomeEvent
    this.onClickPlusIcon();
  }

  onClickPartiesFilterIcon(){
    this.sendDataService.emitpartiesFilterIconClick(this.params); 
  }

  refresh(params: IHeaderParams) {
    return false;
  }
  selectAllItems(value,params)
  {
    if(value == true)
    {
      params.api.forEachNode((node) => {
        node.data.active = true; // Assuming 'active' is the field indicating active/inactive
        if(node.data.hasOwnProperty('isSelected')) node.data.isSelected = true;
      });
      params.api.refreshCells();
      params.api.selectAll();
    }
    else
    {
      params.api.forEachNode((node) => {
        node.data.active = false; // Assuming 'active' is the field indicating active/inactive
        if(node.data.hasOwnProperty('isSelected')) node.data.isSelected = false;
      });
      params.api.refreshCells();
      params.api.deselectAll();
    }

    const customEvent = new CustomEvent('headerCheckboxClick', {
      detail: { data: this.params },
    });
    this.params?.api?.dispatchEvent(customEvent);
  }

  selectAll(value,params,fieldName='active')
  {
    if(value == true)
    {
      params.api.forEachNode((node) => {
        node.data[fieldName] = node.data.isContactBlock == BlockUnblockStatus.Block ? false : true;
        node.setSelected(node.data.isContactBlock == BlockUnblockStatus.Unblock);
       // node.data[fieldName] = true; // Assuming 'active' is the field indicating active/inactive
      });

      params.api.refreshCells();
   //   params.api.selectAll();
    }
    else
    {
      params.api.forEachNode((node) => {
        node.data[fieldName] = false; // Assuming 'active' is the field indicating active/inactive
      });
      params.api.refreshCells();
      params.api.deselectAll();
    }
    // for selectall checkbox header when filter input
    if(params.api?.appliedChecboxSetListByField){
      const list = params?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
      params.api?.appliedChecboxSetListByField?.gridRows?.forEach((item) => {
        const correspondingItem = list?.find(x => x[params.api?.appliedChecboxSetListByField?.matchRowidFieldName] == item[params.api?.appliedChecboxSetListByField?.matchRowidFieldName]);
        if (correspondingItem) {
          item[params.api?.appliedChecboxSetListByField?.checkboxFieldName] = correspondingItem[params.api?.appliedChecboxSetListByField?.checkboxFieldName]; // Update the value
        }
      });
    }
  }
  contactGroupselectAll(value: boolean, params: any, fieldName: string = 'active') {
    if (value) {
      // Select all rows
      params.api.forEachNode((node) => {
        if (node.data.isMobileBlock === BlockUnblockStatus.Unblock) {
          node.data[fieldName] = true; // Mark as active
          node.setSelected(true); // Select the row
        }
      });
    } else {
      // Deselect all rows
      params.api.forEachNode((node) => {
        node.data[fieldName] = false; // Mark as inactive
        node.setSelected(false); // Deselect the row
      });
    }
  
    // Refresh cells to reflect changes
    params.api.refreshCells();
  
    // Update the applied checkbox list (if filter applied)
    if (params.api?.appliedChecboxSetListByField) {
      const list = params?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
      params.api?.appliedChecboxSetListByField?.gridRows?.forEach((item) => {
        const correspondingItem = list?.find(x => x[params.api?.appliedChecboxSetListByField?.matchRowidFieldName] === item[params.api?.appliedChecboxSetListByField?.matchRowidFieldName]);
        if (correspondingItem) {
          item[params.api?.appliedChecboxSetListByField?.checkboxFieldName] = correspondingItem[params.api?.appliedChecboxSetListByField?.checkboxFieldName];
        }
      });
    }
  }
  
  // Check if all checkboxes are selected
  areAllCheckeds(): boolean {
    let allChecked = true;
    this.params.api.forEachNode((node) => {
      if (!node.data.active && node.data.isMobileBlock === BlockUnblockStatus.Unblock) {
        allChecked = false;
      }
    });
    return allChecked;
  }
  
  // Check if any checkbox is selected
  isAnyChecked(): boolean {
    let anyChecked = false;
    this.params.api.forEachNode((node) => {
      if (node.data.active && node.data.isMobileBlock === BlockUnblockStatus.Unblock) {
        anyChecked = true;
      }
    });
    return anyChecked;
  }
  

  open(v){
    
  }
  
  resetColumnFilterData() {
    if (this.columnFilter && this.columnFilter.params) {
      this.columnFilter.params.appliedData = [];
    }
    this.columnFilter.resetFilter();
    this.columnFilter.clearFilterFromHeader();
  }

  areAllChecked(): boolean {
    const allRows = [];
    this.params.api.forEachNode(node => allRows.push(node?.data));

    if(allRows?.length){
      let unblockedRows = allRows?.filter(item => item?.isContactBlock == BlockUnblockStatus.Unblock);
      let selectedItems = allRows?.filter(item => item?.active && item?.isContactBlock == BlockUnblockStatus.Unblock);
      return unblockedRows?.length === selectedItems?.length && unblockedRows?.length !== 0;
   // return allRows?.every(item => item?.extra4);
    } else {
      return false;
    }
  }
  allChecked(): boolean {
    const allRows = [];
    this.params.api.forEachNode(node => allRows.push(node?.data));

    if(allRows?.length){
      let activeRows = allRows?.filter(item => item?.active === true);
      return activeRows?.length === allRows?.length;
    } else {
      return false;
    }
  }

  ondownloadClick(params){
    
    const customEvent = new CustomEvent('downloadIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }
  onClickArrowUpDown(){
    this.arrowUpDown = !this.arrowUpDown;
    const custoDeleteEvent = new CustomEvent('arrowUpDownIconClick', {
      detail: { data: this.params },
    });
    this.params?.api?.dispatchEvent(custoDeleteEvent);
  }

  onClickPlusIcon(){
    const customOpenPopupEvent = new CustomEvent('plusIconClickEventTriggered', {
      detail: { data: this.params },
    });
    this.params?.api?.dispatchEvent(customOpenPopupEvent);
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
  }

}

const i = ['_GOLD_' , '_SILVER_' ,'_DIAMOND_' ,]