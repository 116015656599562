import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonOperationModel, DropdownModel } from '../../models/common.model';
import { CreateUpdateUserModel } from 'src/app/components/administration/usermanagment/usermanagment.model';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { StorageService } from '../../services/storage.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ControlContainer, NgForm } from '@angular/forms';
import { ReportService } from 'src/app/components/Report/report.service';
import { GetallInvoiceRequestModel , GetInvoiceReportResponse } from 'src/app/components/Report/report.model';
import { ProductOpeningService } from 'src/app/components/Product/product-opening/product-opening.service';
import { Subscription } from 'rxjs';

export interface ngMultiselect{
  plusIcon:boolean;
  agLabelName:boolean;
}

@Component({
  selector: 'app-ng-multi-select-dropdown',
  templateUrl: './ng-multi-select-dropdown.component.html',
  styleUrls: ['./ng-multi-select-dropdown.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  
})
export class NgMultiSelectDropdownComponent implements ICellEditorAngularComp, OnInit{

  public params: ICellEditorParams & ngMultiselect;
  @Input() name: string =''; 
  @Input() placeholder: string =''; 
  @Input() id: string =''; 
  @Input() settings: any; 
  @Input() data:  DropdownModel[];
  @Input() isRequired: boolean = false; 
  @Input() branchIds: any[] = []; 
  @Input() selectedIds: any[] = []; 
  @Input() editUser: any;
  @Input() labelName: boolean = true;
  @Output() onSelectItem: EventEmitter<any[]> = new EventEmitter();
  @Output() openModelPopup: EventEmitter<boolean> = new EventEmitter();
  @Input() createUpdateUserModel = new CreateUpdateUserModel();
  @Input() getAllDropdownModel : any = {};
  @Input() isShowlable:boolean=true;
  @Input() isPlusIcon:boolean=true;
  @Input() isclearIcon:boolean = false;
  @Input()isFilterIcon:boolean=false;
  @Input()isResetIcon:boolean=false;
  @Input() showcampaignGroupIcon:boolean =false;
  @Input() className: string = ''; 
  @Input() disabled :boolean;
  @Output() filterIconClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetIconClicked: EventEmitter<any> = new EventEmitter<any>();

  getallInvoiceRequestModel  =new GetallInvoiceRequestModel();
  getInvoiceReportResponseList :GetInvoiceReportResponse[] = []
  branchDetailsFromProductOpeaning:Subscription;

  value: any;
  values: any;

  @Input() dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true
  };



  constructor(public storageService : StorageService , private reportService :ReportService , private productOpeningService:ProductOpeningService){
    // this.selectedIds = this.data.filter(a => this.selectedIds.includes(a.id));
  }

  ngOnChanges(){
    this.selectedIds;
  }

  ngOnInit(): void {
    // this.selectedIds = this.data.filter(a => this.selectedIds.includes(a.id));
  }

  agInit(params:  ICellEditorParams & ngMultiselect): void {
    this.params = params;
    const valuesOrFunction = params.colDef.cellEditorParams?.values;
    this.values = typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction??[];
    this.values = params?.colDef?.cellEditorParams?.values() ?? [];
    this.value = this.params?.value;
    
    this.isShowlable = params.agLabelName;
    this.isPlusIcon = params.plusIcon;
  }

  refresh(): boolean {
    return true;
  }

  private getValuesFromParams(params: ICellEditorParams ): string[] {
    return params?.colDef?.cellEditorParams?.values ?? [];
  }
  getValue() {
    return this.value;
  }
  isPopup?(): boolean {
    return true;
  }
  getPopupPosition?(): any {
    return 'over'
  }

  onItemSelect(item: any) {
    this.onSelectItem.emit(this.selectedIds?.map((x) => x.id));
  }

  onItemDeSelect(item: any) {
    this.onSelectItem.emit(this.selectedIds?.map((x) => x.id))
  }

  onSelectAll(items: any[]) {
    this.selectedIds = items;
    this.onSelectItem.emit(this.selectedIds?.map((x) => x.id))
  }
  onItemDeSelectAll(items: any[]) {
    this.selectedIds = []
    this.onSelectItem.emit(this.selectedIds)
  }
  
  
  clearSelectedItems() {
    this.selectedIds = []; 
  }

onFilterIconClick(data) {
  this.filterIconClicked.emit(data);
}
onResetIconClick(){
  this.selectedIds = []; 
  this.resetIconClicked.emit()
}

ngOnDestroy() {
  // this.branchDetailsFromProductOpeaning.unsubscribe();
}

}
