import { EditDeleteIconComponent } from 'src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component';
import { AgColDefsI, AgGridI, paginationModel } from './../../shared/common-components/ag-grid/ag-grid.model';
import { TextRendererComponent } from 'src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component';
import { AgGridCustomDropdownComponent } from 'src/app/shared/common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component';
import { CustomHeaderComponent } from 'src/app/shared/common-components/ag-grid/custom-header/custom-header.component';
import { DatepickerRendererComponent } from 'src/app/shared/common-components/ag-grid/datepicker-renderer/datepicker-renderer.component';
import { AgGridButtonCellrendererComponent } from 'src/app/shared/common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.component';
import { AgGridCustomStatusDropdownComponent } from 'src/app/shared/common-components/ag-grid/ag-grid-custom-status-dropdown/ag-grid-custom-status-dropdown.component';
import { AgGridCustomInputComponent } from 'src/app/shared/common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component';
import { ResetRenderComponent } from 'src/app/shared/common-components/ag-grid/reset-render/reset-render.component';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AmountColWidth, MetalColWidth, NameColWidth, ProductColWidth, StorageName, TransactionType, VoucherColWidth, WeightColWidth } from 'src/app/shared/enum/common-enum';
import { InputDatepickerRendererComponent } from 'src/app/shared/common-components/ag-grid/input-datepicker-renderer/input-datepicker-renderer.component';
import { API_URL_GET_ALL_PDCVOUCHER, API_URL_JOBWORK_ORDER_LIST } from 'src/app/shared/url-helper/url-helper';
import { StatusCellRendererComponent } from 'src/app/shared/common-components/ag-grid/status-cell-renderer/status-cell-renderer.component';
import { ReceiveButtonCellComponent } from 'src/app/shared/common-components/ag-grid/receive-button-cell/receive-button-cell.component';
import { DatePipe } from '@angular/common';
import { DateCellRendererComponent } from 'src/app/shared/common-components/ag-grid/date-cell-renderer/date-cell-renderer.component';
import { TrackOrderCellrenderComponent } from 'src/app/shared/common-components/ag-grid/track-order-cellrender/track-order-cellrender.component';
import { AgGridInnerHTMLDisplayComponent } from 'src/app/shared/common-components/ag-grid/ag-grid-inner-htmldisplay/ag-grid-inner-htmldisplay.component';
import { DateCellEditor } from 'ag-grid-community';
import { right } from '@popperjs/core';
import { NumericCellRendererComponent } from 'src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component';
import { JwellaryImageComponent } from 'src/app/shared/common-components/ag-grid/jwellary-image/jwellary-image.component';

export class Transaction {
}

// FOR PDC Entry payment list
  const SrNo: AgColDefsI = {
    headerName: "Sr.No",
    field: "srNo",
    lockPosition: "left",
    resizable: true,
    sortable: true,
    minWidth: 70,
    maxWidth: 70,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isReadOnly: false},
    cellClass: 'mx-0 custom-column-group-cell align-items-center',
    headerClass: "px-0 custom-column-group-cell",
  };
// const VoucherNo: AgColDefsI =     {
//     headerName: "Voucher No.",
//     field: 'voucherNo',
//     headerClass: "px-0",
//     cellClass: "px-0 custom-column-group-cell text-primary ",
//     minWidth: 150, 
//     flex:3,
//     sortable: true,
//     suppressMovable: false,
//     editable:false,
//     lockPosition:true,
//     headerComponent: CustomHeaderComponent,
//     cellRenderer: TextRendererComponent,
//     headerComponentParams:{
//       filterValueColumnName: "voucherNo",
//       filterIdColumnName: "voucherNo",
//       isInputFilter: true,
//       isAllowNumber:true 
//     },
//     cellRendererParams :{isCursor:true},

// }
const InvoiceNo: AgColDefsI =     {
  headerName: "Invoice No.",
  field: 'invoiceNo',
  headerClass: "px-0 custom-column-group-cell",
  cellClass: "px-0 custom-column-group-cell text-primary ",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth, 
  sortable: true,
  suppressMovable: false,
  editable:false,
  // headerComponent: CustomHeaderComponent,
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:true},
}
const BankName: AgColDefsI =     {
    headerName: "Bank Name.",
    field: 'bankName',
    headerClass: "px-0 custom-column-group-cell",
    cellClass: "px-0 custom-column-group-cell ",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth, 
    sortable: true,
    suppressMovable: false,
    editable:false,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "bankId",
      filterIdColumnName: "bankName",
      filterParams: StorageName.BANK_ACCOUNT_DROPDOWN,
    },
    cellRendererParams :{isCursor:true},
}

const PDCVoucherType: AgColDefsI =     {
  headerName: "PDC VoucherType",
  field: 'voucherTypeName',
  headerClass: "px-0 custom-column-group-cell",
  cellClass: "px-0 custom-column-group-cell ",
  minWidth: VoucherColWidth.MinWidth,
  maxWidth: VoucherColWidth.MaxWidth, 
  sortable: false,
  suppressMovable: false,
  editable:false,
  cellRenderer: TextRendererComponent,
}


const ChequeNo: AgColDefsI =     {
    headerName: "Cheque No.",
    field: 'chequeNo', 
    headerClass: "px-0 custom-column-group-cell ag_header_end",
    cellClass: "px-2 custom-column-group-cell text-end",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth, 
    sortable: false,
    suppressMovable: false,
    editable:false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
}
const ChequeDate: AgColDefsI =     {
  headerName: "Cheque Date",
  field: 'chequeDate', 
  headerClass: "px-0 custom-column-group-cell ag_header_end",
  cellClass: "custom-column-group-cell text-end",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth, 
  sortable: false,
  suppressMovable: false,
  editable:false,
  cellRenderer:DateCellRendererComponent, 
}

const PayDt: AgColDefsI =     {
    headerName: "Pay Dt.",
    field: 'date', 
    headerClass: "px-0 custom-column-group-cell ag_header_end",
    cellClass: "px-2 custom-column-group-cell text-end",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth, 
    sortable: false,
    suppressMovable: false,
    editable:false,
    cellRenderer:DateCellRendererComponent, 
}
const Amount: AgColDefsI =     {
    headerName: "Amount",
    field: 'amount', 
    headerClass: "px-0 custom-column-group-cell ag_header_end",
    cellClass: "px-2 custom-column-group-cell text-end",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth, 
    sortable: false,
    suppressMovable: false,
    editable:false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams :{isAmt:true},
}

const BranchName: AgColDefsI = { 
  headerName: "Branch Name", 
  headerComponentParams: {enableMenu:false},
  sortable:true,
  headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: 'branchName',
  cellClass:'px-0 custom-column-group-cell',
  suppressMovable: false,
  resizable: true, 
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:false},
  editable:false, 
  minWidth: 180, flex:2,
}

const Status: AgColDefsI = {
  headerName: "Status",
  field: 'statusName',
  headerClass: " text-center custom-column-group-cell d-flex align-items-center justify-content-center",
  cellClass: " text-center custom-column-group-cell d-flex  justify-content-center ",
  sortable: false,
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth, 
  editable: false,
  cellRenderer: StatusCellRendererComponent,

}
const ActionColumn: AgColDefsI = {
    headerName: "",
    field: "",
    minWidth: 60,
    maxWidth: 60,
    editable: false,
    cellRenderer: EditDeleteIconComponent,
    cellRendererParams :{focusOnColumn:'name' ,isEditRowOnEdit:false},
    headerClass: "custom-column-group-cell",
    cellClass: "custom-column-group-cell",
    suppressMovable:false,
}

export const ColDefspdcEntryPayemntList: AgColDefsI[] = [
    SrNo,
    PDCVoucherType,
    InvoiceNo,
    BankName,
    ChequeNo,
    ChequeDate,
    PayDt,
    Amount,
    BranchName,
    Status,
    ActionColumn
];

const PayDate: AgColDefsI = {
    headerName  : "Pay Dt.",
    field       : "date",
    sortable    : true,
    editable    :  true,
    isRequired  : false,
    cellClass   : " px-0 text-center",
    headerClass : "custom-column-group-cell px-0",
    cellRendererParams :{isCursor:true, isTodayDateDefault:false},
    headerComponentParams:{enableSorting: false, },
    valueFormatter : dateFormatter ,
    cellRenderer: InputDatepickerRendererComponent,
    cellEditor: InputDatepickerRendererComponent,
    cellEditorParams:{isTodayDateDefault:false},
    flex:1,
    minWidth:AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
  };
  
  const ChequeDates: AgColDefsI = {
    headerName: "Cheque Date",
    field: "chequeDate",
    sortable    : true,
    editable    :  true,
    isRequired  : false,
    cellClass   : " px-0 text-center",
    headerClass : "custom-column-group-cell px-0",
    cellRendererParams :{isCursor:true, isTodayDateDefault:false},
    headerComponentParams:{enableSorting: false, },
    valueFormatter : dateFormatter ,
    cellRenderer: InputDatepickerRendererComponent,
    cellEditor: InputDatepickerRendererComponent,
    cellEditorParams:{isTodayDateDefault:false},
    flex:1,
    minWidth:AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
  };
  
  const ChequeNoo: AgColDefsI = {
    headerName: "Cheque No.",
    field: "chequeNo",
    flex:2,
    sortable: true,
    editable: true,
    minWidth:AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    cellRenderer: TextRendererComponent,
    cellEditor :AgGridCustomInputComponent,
    cellClass: "custom-column-group-cell px-0 text-end",
    cellEditorParams:{
      decimalPlaces:0,
      isOnlyNumbers:true,
    },
    headerComponentParams: 
    { isRequired: false, 
      enablePlusIconLight: false,
      enableSorting: false,
    },
    headerClass: "custom-column-group-cell px-0 ag_header_end",
  };

  const Amt: AgColDefsI = {
    headerName: "Amount",
    field: "amount",
    cellClass: "custom-column-group-cell  text-end",
    headerClass: "custom-column-group-cell wrap-header-cell",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    sortable: true,
    editable: true,
    headerComponentParams: { 
      isRequired: false,
      enablePlusIconLight: false ,
      enableSorting: false,
    },
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:3,
      isOnlyNumbers:true,
    },

  }
  
  const EditStaus: AgColDefsI = { 
    headerName: "Status", 
    sortable:true,
    headerClass:"px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_fontSize",
  field: "statusId",
  headerComponentParams: { isRequired: false ,enableSorting: false },
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.PDC_STATUS_DROPDOWN);
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 120,
  flex: 2,
  editable: true,
  isRequired:false,
}
  
  const AccountLedger: AgColDefsI = {
    headerName: "Account Ledger",
    headerComponentParams: { isRequired: false, enablePlusIconLight: false,enableSorting: false, },
    field: "accountLedgerId",
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.ACCOUNTLEDGER_DROPDOWN);
      }
    },
  };

  const AgainstInvoice: AgColDefsI = {
    headerName: "Against Invoice",
    headerComponentParams: { isRequired: false, enablePlusIconLight: false , enableSorting: false, },
    field: "againstVoucherNo",
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        return [{name:'',id:0}];
      }
    },
  };

  const BankNames: AgColDefsI = {
    headerName: "Bank Name",
    field: "bankId",
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
    headerComponentParams: { isRequired: false, enablePlusIconLight: false , enableSorting: false, },
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.BANK_ACCOUNT_DROPDOWN);
      }
    },

  };
  

  



export const ColDefspdcEntry: AgColDefsI[]=[
    PayDate,
    ChequeDates,
    ChequeNoo,    
    AccountLedger,
    AgainstInvoice,
    BankNames,
    EditStaus,
    Amt,
  
]


export const AgGridDataModelpdcEntryPayemntList: AgGridI = {
  colDefs: ColDefspdcEntryPayemntList,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  columnSettings: true,
  singleClickEdit: true,
  rows: [],
  isServerSidepagination: true,
  showPagination: true,
  rowHeight:28,
  storageName : StorageName.TRANSACTION_PDCENTRY_PAYMENTLIST_GRID,
  filterStorageName: StorageName.FILTER_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID,
  sortStorageName: StorageName.SORT_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID,
  paginationStorageName : StorageName.PAGINATION_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID,
  apiUrl : API_URL_GET_ALL_PDCVOUCHER, 

}

export const AgGridDataModelpdcEntryList: AgGridI = {
    colDefs: ColDefspdcEntry,
    rowSelection: "multiple",
    storageName : StorageName.TRANSACTION_PDCENTRY_GRID,
    rowHeight:28,
};


export class UpdateModel {
  id: number
  pdcVoucherType: number
  voucherNo: string
  invoiceNo: string
  suffixPrefixId: number
  date: string
  accountLedgerId: number
  amount: number
  chequeNo: string
  chequeDate: string
  comment: string
  bankId: number
  bank: string
  voucherTypeId: number
  againstVoucherNo: number
  againstVoucherType: string
  againstVoucherTypeId: number
}


// contraVoucher start 
const ContraVoucherColumnDef: AgColDefsI[] = [
  // { headerName: "Transaction", 
  //   headerComponentParams: {enableMenu:false},
  //   sortable:true,
  //   headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  //   field: 'transactionType',
  //   cellClass:'px-0 custom-column-group-cell', minWidth: 200, flex:2,
  //   lockPosition:true,
  //   suppressMovable: false,
  //   resizable: true,
  //   cellRenderer: TextRendererComponent,
  //   cellRendererParams :{isCursor:true},
  //   cellEditor: AgGridCustomDropdownComponent,
  //   cellEditorParams: {
  //     values: () => {
  //       const storageService = new StorageService();
  //       const storage = StorageName;
  //       return storageService.retrieve(storage.TRANSACTION_TYPE_DROPDOWN);
  //     }
  //   },
  // },
  // { headerName: "From", 
  //   headerComponentParams: {enableMenu:false},
  //   sortable:true,
  //   headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  //   field: 'fromAccountLedgerId',
  //   cellClass:'px-2 custom-column-group-cell text-gray-color', minWidth: 200, flex:2,
  //   lockPosition:true,
  //   suppressMovable: false,
  //   resizable: true,
  //   cellRenderer: TextRendererComponent,
  //   cellRendererParams :{isCursor:true},
  //   cellEditor: AgGridCustomDropdownComponent,
  //   cellEditorParams: {
  //     values: () => {
  //       const storageService = new StorageService();
  //       const storage = StorageName;
  //       return storageService.retrieve(storage.BANK_ACCOUNT_DROPDOWN);
  //     }
  //   },
  // },
  { headerName: "Bank/Cash a/c", 
    headerComponentParams: {enableMenu:false , enableSorting: false,},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'accountLedgerId',
    cellClass:'px-0 custom-column-group-cell text-gray-color', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth, 
    flex:2,
    lockPosition:true,
    suppressMovable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.BANK_ACCOUNT_DROPDOWN);
      }
    },
  },
  { headerName: "Ref. No.", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'referenceNo',
    cellClass:'px-0 custom-column-group-cell text-gray-color text-end', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    editable:true,
    lockPosition:true,
    suppressMovable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    headerComponentParams: { enableMenu: false, enableSorting: false,},
    cellEditorParams:{
      isOnlyNumbers:true,
    }
  },
  { headerName: "Ref. Date", 
    headerComponentParams: {enableMenu:false , enableSorting: false,},
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'referenceDate',
    cellClass:'custom-column-group-cell text-gray-color text-end',
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth,
    editable:true,
    lockPosition:true,
    cellRendererParams :{isCursor:true},
    suppressMovable: false,
    resizable: false,
    cellRenderer: InputDatepickerRendererComponent,
    valueFormatter : dateFormatter ,
    cellEditor: InputDatepickerRendererComponent,
    // cellEditorParams: {
    //   values: () => {
    //   },
    // },
  },
  { headerName: "Amount", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'amount',
    cellClass:'px-0 custom-column-group-cell text-gray-color text-end',
     minWidth: NameColWidth.MinWidth,
     maxWidth: NameColWidth.MaxWidth,
    editable:true,
    lockPosition:true,
    suppressMovable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    headerComponentParams: { enableMenu: false, enableSorting: false,},
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    }
  },
  { headerName: "Comment", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'comment',
    cellClass:'px-2 custom-column-group-cell text-gray-color', 
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    editable:true,
    cellRendererParams :{isCursor:true},
    lockPosition:true,
    suppressMovable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    headerComponentParams: { enableMenu: false, enableSorting: false,},
  }
  ,
  {
    headerName: "",
    pinned: "right",
    field:'editdeleteicon',
    suppressNavigable: true,
    editable: false,
    lockPinned: true,
    resizable: false,
    headerComponent: CustomHeaderComponent,
    headerClass: "justify_content_start custom-column-group-cell border-0 px-0",
    cellRenderer: EditDeleteIconComponent,
    cellRendererFramework: EditDeleteIconComponent,
    cellRendererParams: {
      editIcon: true,
      isFirstDeleteIcon:true,
      resetIcon:false,
      isCursor:true
    },
    headerComponentParams: { enableMenu: false, moreaddButton: true ,enableSorting: false, selectAll : false },
    cellClass: "mx-0 text-end px-1",
    minWidth: 80,
    maxWidth: 80,
  }
  ,
];

export const AgGridDataModelContraVoucher: AgGridI = {
  colDefs: ContraVoucherColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_CONTRAVOUCHER_GRID,
  rowHeight: 28,
};
// contra voucher end 


// Journal Voucher Start
const JournalVoucherColumnDef: AgColDefsI[] = [
  { headerName: "Account Ledger",
    headerComponentParams: {enableSorting:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'accountLedgerId',
    cellClass:'px-0 custom-column-group-cell',
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth, 
    flex:2,
    editable:true,
    resizable: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.ALL_ACCOUNTLEDGER_DROPDOWN);
      },
    },
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor: AgGridCustomDropdownComponent,
  },
  { headerName: "Cr/Dr", 
    headerComponentParams: {enableSorting:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'crOrDr',
    cellClass:'px-0 custom-column-group-cell', 
    minWidth: 80, flex:1,
    editable:true,
    resizable: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.TRANSACTION_TYPE_DROPDOWN);
      },
    }, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor: AgGridCustomDropdownComponent,
  },
  { headerName: "Against", 
    headerComponentParams: {enableSorting:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'againstVoucherNo',
    cellClass:'px-0 custom-column-group-cell', 
    minWidth: 80, flex:2,
    editable:true,
    resizable: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.AGAINST_DROPDOWN);
      },
    },
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor: AgGridCustomDropdownComponent,
  },
  { headerName: "Ref. No.", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'referenceNo',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end',
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    editable:true,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    headerComponentParams: { enableMenu: true,enableSorting:false},
    cellEditorParams:{
      isOnlyNumbers:true,
    }
  },
  { headerName: "Ref. Date", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'referenceDate',
    cellClass:'mx-0 custom-column-group-cell text-gray-color text-end padding-right-10', 
    minWidth: 80,
    editable:true,
    resizable: true,
    valueFormatter : dateFormatter ,
    cellRendererParams :{isCursor:true},
    headerComponentParams: { enableSorting: false},
    cellEditor: InputDatepickerRendererComponent,
    // cellEditorParams: {
    //   values: () => {
    //   },
    // },
  },
  { headerName: "Amount", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'amount',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    editable:true,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    headerComponentParams: { enableSorting: false},
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    }
  },
  {
    headerName: "Metal",
    headerComponentParams: { enableSorting: false },
    sortable: true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'metalId',
    cellClass: 'px-0 custom-column-group-cell', 
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth, 
    flex: 1,
    editable: true,
    resizable: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.METAL_DROPDOWN);
      },
    },
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isCursor: true },
    cellEditor: AgGridCustomDropdownComponent,
  },
  {
    headerName: "Purity Wt",
    sortable: true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'purityWt',
    cellClass: 'px-2 custom-column-group-cell text-gray-color text-end',
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    editable: (params) => {
      const storageService = new StorageService();
      const storage = StorageName;
      const storeData = storageService.retrieve(storage.METAL_DROPDOWN);
      const selectedMetal = storeData?.find(a => a.id == params?.data?.metalId)?.name;
      return selectedMetal ? true  : false;
    },
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isCursor: true },
    cellEditor: AgGridCustomInputComponent,
    headerComponentParams: { enableSorting: false },
    cellEditorParams: {
      decimalPlaces: 2,
      isOnlyNumbers: true,
    }
  },
  {
    headerName: "",
    pinned: "right",
    field:'editdeleteicon',
    suppressNavigable: true,
    editable: false,
    lockPinned: true,
    headerComponent: CustomHeaderComponent,
    headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
    cellRenderer: EditDeleteIconComponent,
    cellRendererFramework: EditDeleteIconComponent,
    resizable: false,
    cellRendererParams: {
      editIcon: true,
      isFirstDeleteIcon:true,
      resetIcon:false,
      isCursor:true
    },
    headerComponentParams: { enableMenu: false, moreaddButton: true ,enableSorting: false, selectAll : false },
    cellClass: "mx-0 text-end px-1",
    minWidth: 80,
    maxWidth: 80,
    // extraField:"Action"
  }
  ,
];

const PurchaseSaleFixingColumnDef: AgColDefsI[] = [
  { headerName: "Account Ledger",
    headerComponentParams: {enableSorting:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'accountLedgerId',
    cellClass:'px-0 custom-column-group-cell',
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth, 
    flex:2,
    editable:true,
    resizable: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.ALL_ACCOUNTLEDGER_DROPDOWN);
      },
    },
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor: AgGridCustomDropdownComponent,
  },
  // { headerName: "Cr/Dr", 
  //   headerComponentParams: {enableSorting:false},
  //   sortable:true,
  //   headerClass: "px-0 custom-column-group-cell",
  //   field: 'crOrDr',
  //   cellClass:'px-0 custom-column-group-cell', 
  //   minWidth: 80, flex:1,
  //   editable:true,
  //   resizable: true,
  //   cellEditorParams: {
  //     values: () => {
  //       const storageService = new StorageService();
  //       const storage = StorageName;
  //       return storageService.retrieve(storage.TRANSACTION_TYPE_DROPDOWN);
  //     },
  //   }, 
  //   cellRenderer: TextRendererComponent,
  //   cellRendererParams :{isCursor:true},
  //   cellEditor: AgGridCustomDropdownComponent,
  // },
  
  { headerName: "Against", 
    headerComponentParams: {enableSorting:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'againstVoucherNo',
    cellClass:'px-0 custom-column-group-cell', 
    minWidth: 80, flex:2,
    editable:true,
    resizable: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.AGAINST_DROPDOWN);
      },
    },
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor: AgGridCustomDropdownComponent,
  },
  { headerName: "Rate", 
    headerClass: "custom-column-group-cell",
    field: 'rate',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end', minWidth: 80,flex:2,
    editable:true,
    resizable: true,
    cellRenderer:TextRendererComponent,
  },
  { headerName: "Ref. No.", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'referenceNo',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end',
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    editable:true,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    headerComponentParams: { enableMenu: true,},
    cellEditorParams:{
      isOnlyNumbers:true,
    }
  },
  { headerName: "Ref. Date", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'referenceDate',
    cellClass:'mx-0 custom-column-group-cell text-gray-color text-end padding-right-10', 
    minWidth: 80,
    editable:true,
    resizable: true,
    valueFormatter : dateFormatter ,
    cellRendererParams :{isCursor:true},
    cellEditor: InputDatepickerRendererComponent,
    // cellEditorParams: {
    //   values: () => {
    //   },
    // },
  },
  { headerName: "Amount", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'amount',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    editable:true,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    headerComponentParams: { enableSorting: false},
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    }
  },
  {
    headerName: "Metal",
    headerComponentParams: { enableSorting: false },
    sortable: true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'metalId',
    cellClass: 'px-0 custom-column-group-cell', 
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth, 
    flex: 1,
    editable: true,
    resizable: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.METAL_DROPDOWN);
      },
    },
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isCursor: true },
    cellEditor: AgGridCustomDropdownComponent,
  },
  {
    headerName: "Purity Wt",
    sortable: true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'purityWt',
    cellClass: 'px-2 custom-column-group-cell text-gray-color text-end',
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    editable: (params) => {
      const storageService = new StorageService();
      const storage = StorageName;
      const storeData = storageService.retrieve(storage.METAL_DROPDOWN);
      const selectedMetal = storeData?.find(a => a.id == params?.data?.metalId)?.name;
      return selectedMetal ? true  : false;
    },
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isCursor: true },
    cellEditor: AgGridCustomInputComponent,
    headerComponentParams: { enableSorting: false },
    cellEditorParams: {
      decimalPlaces: 2,
      isOnlyNumbers: true,
    }
  },
  {
    headerName: "",
    pinned: "right",
    field:'editdeleteicon',
    suppressNavigable: true,
    editable: false,
    lockPinned: true,
    headerComponent: CustomHeaderComponent,
    headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
    cellRenderer: EditDeleteIconComponent,
    cellRendererFramework: EditDeleteIconComponent,
    resizable: false,
    cellRendererParams: {
      editIcon: true,
      isFirstDeleteIcon:true,
      resetIcon:false,
      isCursor:true
    },
    headerComponentParams: { enableMenu: false, moreaddButton: true ,enableSorting: false, selectAll : false },
    cellClass: "mx-0 text-end px-1",
    minWidth: 80,
    maxWidth: 80,
    // extraField:"Action"
  }
  ,
];

export const AgGridDataModelJournalVoucher: AgGridI = {
  colDefs: JournalVoucherColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_JOURNALVOUCHER_GRID,
  rowHeight: 28,
};

export const AgGridDataModelPurchaseFixing: AgGridI = {
  colDefs: PurchaseSaleFixingColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_PURCHASEFIXING_GRID,
  rowHeight: 28,
};

export const AgGridDataModelSaleFixing: AgGridI = {
  colDefs: PurchaseSaleFixingColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEFIXING_GRID,
  rowHeight: 28,
};

export class JournalMaster {
  id: number;
  suffixPrefixId: number;
  voucherNo: number;
  voucherTypeId: number;
  invoiceNo: string;
  totalDebitAmount: number;
  totalCreditAmount: number;
  creditWt: number;
  debitWt: number;
  date: string;
  comment: string;
  isprint:boolean;
}

export class JournalDetailList {
  id: number
  journalMasterId: number
  accountLedgerId: number
  crOrDr: string
  againstVoucherType: string
  againstVoucherTypeId: number
  againstInvoiceNo: string
  referenceNo: string
  metalId: number
  referenceDate: string
  amount: number
  purityWt: number
  
}
// Journal Voucher End

// Job Order List Starts
const JobOrderListColumnDef: AgColDefsI[] = [
  { headerName: "Jobwork Order No", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'invoiceNo',
    cellClass:'px-0 custom-column-group-cell text-primary', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
     flex:1,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  },
  { headerName: "Jobwork Invoice No.", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'jobWorkInvoiceVoucherNo',
    cellClass:'px-0 custom-column-group-cell text-primary', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth, 
    flex:1,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    // cellRendererParams :{isCursor:true},
    cellRendererParams: (params: any) => {
      const storageService = new StorageService();
      const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
      return {
        hasViewPermission: userPermissions?.jobworkInvoiceCanView,
        isCursor: userPermissions?.jobworkInvoiceCanView === false ? false : true
      };
    },
  },
  { headerName: "Sale Order No.", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'saleOrderVoucherNo',
    cellClass:'px-0 custom-column-group-cell text-primary', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
     flex:1,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  },


  { headerName: "Ref. No.", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'refNo',
    cellClass:'px-0 custom-column-group-cell',
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    flex:1,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},  
  },
  {
    headerName: "RFID Code.",
    headerComponent: CustomHeaderComponent,
    headerClass:
      "px-0 custom-column-group-cell f-14 text-gray-color font-semibold justify_content_end",
    field: "rfid",
    sortable: true,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell text-primary text-underline font-regular f-12",
    minWidth: 80,
    resizable: true,
    editable: false,
    cellRendererParams: { isCursor: true ,widthFull:true },
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "invoiceNo",
    //   filterIdColumnName: "invoiceNo",
    //   isInputFilter: true,
    // },
  },
  { headerName: "Customer Name", 
  sortable:true,
  headerClass: "px-0 custom-column-group-cell",
  field: 'customerName',
  cellClass:'px-0 custom-column-group-cell',
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  flex:1,
  editable:false,
  // autoHeight:true,
  //  wrapText:true,
  resizable: true,
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:true},  
},
{
  headerName: "Group Name",
  headerClass:
    "px-0 custom-column-group-cell f-14 text-gray-color font-semibold justify_content_end",
  field: "groupName",
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellClass: "custom-column-group-cell text-gray-color font-regular f-12",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  resizable: true,
  headerComponentParams:{
    enableSorting: false,
    // filterValueColumnName: "groupName",
    // filterIdColumnName: "groupName",
    // isInputFilter: true,
  },
},
  { headerName: "Assign To", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'accountLedgerName',
    cellClass:'px-0 custom-column-group-cell', 
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
     flex:1,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true}, 
  },

  {
    headerName: "Short Description", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ",
    field: 'description',
    cellClass:'px-2 custom-column-group-cell text-gray-color',
    minWidth: ProductColWidth.MinWidth,
    maxWidth: ProductColWidth.MaxWidth,
    resizable: true,
    editable:false,
    autoHeight:true,
    wrapText:true,
    cellRenderer: AgGridInnerHTMLDisplayComponent
   
  },
  { headerName: "Order Dt.", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'invoiceDate',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end', minWidth: 70, flex:2,
    editable:false,
    resizable: true,
    cellRenderer: DateCellRendererComponent,
    cellRendererParams :{isCursor:true},
  },
  { headerName: "Due Date", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'dueDate',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end', minWidth: 70, flex:2,
    editable:false,
    resizable: true,
    cellRenderer: DateCellRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    }
  },
  {
    headerName: "Branch Name", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'branchName',
    cellClass:'px-0 custom-column-group-cell',
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:false},
    editable:false, 
    minWidth: 180, flex:2,
  },
  
  {
    headerName: "Current Dept.",
    headerComponent: CustomHeaderComponent,
    cellRenderer: TextRendererComponent,
    headerClass:
      "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold justify_content_end",
    field: "currentDept",
    sortable: true,
    flex: 1,
    cellClass: "custom-column-group-cell pink-text",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable: true,
    cellRendererParams: { isCursor: false },
    headerComponentParams: {
      enableSorting: false,
      filterValueColumnName: "currentDept",
      filterIdColumnName: "currentDept",
    },
    cellStyle: { color: '#C71AA4' }
  },
  {
    headerName: "Tag No.",
    headerClass:"px-0 custom-column-group-cell f-14 text-gray-color font-semibold justify_content_end",
    field: "barcodeNo",
    sortable: true,
    headerComponent: CustomHeaderComponent,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell text-gray-color font-regular f-12",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable: true,
    editable: false,
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "invoiceNo",
    //   filterIdColumnName: "invoiceNo",
    //   isInputFilter: true,
    // },
  },
  {
    headerName: "Design No.",
    headerClass:"px-0 custom-column-group-cell f-14 text-gray-color font-semibold",
    field: "designNo",
    sortable: true,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell text-gray-color font-regular f-12",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable: true,
    editable: false,
    cellRendererParams: { isCursor: false },
  },
  { headerName: "Status", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color ag_header_center font-semibold ag_header_end",
    field: 'statusName',
    cellClass:'text-center custom-column-group-cell d-flex align-items-center justify-content-center', minWidth: 100, 
    editable:false,
    pinned: "right",
    resizable: false,
    lockPinned: true,
    cellRenderer: StatusCellRendererComponent,  
    cellRendererParams :{isCursor:true},
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    }
  },
  { headerName: "Priority", 
  sortable:true,
  headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color ag_header_center font-semibold ag_header_end",
  field: 'priorityName',
  cellClass:'text-center custom-column-group-cell d-flex align-items-center justify-content-center', 
  editable:false,
  resizable: false,
  pinned: "right",
  lockPinned: true,
  minWidth:100,
  cellRenderer: StatusCellRendererComponent,  
  cellRendererParams :{isCursor:true},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
},
  {
    headerName: "",
    pinned: "right",
    suppressNavigable: true,
    editable: false,
    lockPinned: true,
    field:'Invoice',
    headerComponent: CustomHeaderComponent,
    headerClass: "text-center custom-column-group-cell d-flex align-items-center justify-content-center",
    cellRenderer: ReceiveButtonCellComponent,
    cellRendererParams: (params: any) => {
      const storageService = new StorageService();
      const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
      return {
        hasAddPermission: userPermissions?.jobworkInvoiceCanAdd
      };
    },
    headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false, selectAll : false },
    cellClass: "text-center custom-column-group-cell d-flex align-items-center justify-content-center",
    minWidth: 110,
    maxWidth: 110,
    resizable: false,
    valueFormatter: params => {
      return 'Invoice'
   }
 },
 {
    headerName: "",
    pinned: "right",
    suppressNavigable: true,
    editable: false,
    lockPinned: true,
    field:'Print',
    headerComponent: CustomHeaderComponent,
    headerClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
    cellRenderer: EditDeleteIconComponent,
    headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false, selectAll : false },
    cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
    cellRendererParams: {
      editIcon: false,
      isDeleteIcon : false,
      printIcon: true,
    },
      // cellRenderer: ReceiveButtonCellComponent,
    //   valueFormatter: params => {
    //     return 'Print'
    //  },
    minWidth: 45,
    maxWidth: 45,
    resizable: false,
  },
  {
    headerName: "",
    headerClass:"px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: "imageUrls",
    cellRenderer: JwellaryImageComponent,
    cellClass: "custom-column-group-cell",
    minWidth: 36,
    maxWidth: 36,
    editable:false,
    cellRendererParams:{fromDetailJson: true},
    headerComponentParams: { enableSorting: false},
    resizable: true,
    extraField:'JewelleryImg',
    cellStyle: { cursor: 'pointer' },
    suppressMovable:true,
    suppressColumnMove:true,
    lockPosition:'left',
  
  
  },
  
 {
  headerName: "",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  lockPosition: "right",
  field:'',
  headerComponent: CustomHeaderComponent,
  headerClass: "text-center custom-column-group-cell d-flex align-items-center justify-content-center",
  cellRenderer: TrackOrderCellrenderComponent,
  headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false, selectAll : false },
  cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
  minWidth: 60,
  maxWidth: 60,
  resizable: false,
  cellRendererParams: (params: any) => {
    const storageService = new StorageService();
    const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
    return {
      isIconDisabled:!userPermissions?.jobworkInvoiceCanAdd,
      isCursor: userPermissions?.jobworkInvoiceCanAdd,
      Barcode: false, 
      isZebra: false, 
      isDocumentFileIcon: true,
      isDisabledZebraIcon: false,
    }
  },
},
];

export const AgGridDataModelJobOrderList: AgGridI = {
  colDefs: JobOrderListColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_JOBORDERLIST_GRID,
  paginationStorageName : StorageName.PAGINATION_TRANSACTION_JOBORDERLIST_GRID,
  filterStorageName: StorageName.FILTER_TRANSACTION_JOBORDERLIST_GRID,
  sortStorageName: StorageName.SORT_TRANSACTION_JOBORDERLIST_GRID,
  rows:[],
  apiUrl :  API_URL_JOBWORK_ORDER_LIST,
  isServerSidepagination : true,
  showPagination: true
};

// Job Order List Ends

export class GetPDCVoucherRequestModel extends paginationModel{
  // page          : number = 1;
  pageSize      : number = 25;
  branchId      : number;
  voucherTypeId : number;
  ledgerId      : number;
  productId     : number;
  exportAs       :number;
  exportRequest : exportRequest[];
  filters:string;
}

export class exportRequest {
  gridName : string;
  columnToInclude : any
}

function dateFormatter(params:any){
  if(params){
    const startDate = params.value
    const datePipe = new DatePipe('en-us')
    const dtStart = datePipe.transform(startDate, 'dd/MM/yyyy');
    return dtStart
    }
    return ''
}
// Contra voucher end

export class GetallSalesOrderRequestModel extends paginationModel{
  pageSize      : number = 25;
  branchId      : number;
  voucherTypeId : number;
  ledgerId      : number;
  productId     : number;
  columnToInclude:any;
  exportRequest : exportRequest[];
}


export enum TransactionManufacturingTabs {
  JobworkOrderList = 2
}
export enum transactionType {
    cr = 1,
}
export enum TransactionStockJournalTabs {
  movement = 1,
  manufacturing = 2,
  transfer = 3,
  stockout = 4,
}