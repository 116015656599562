import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { ColDef, ColGroupDef, IHeaderParams } from 'ag-grid-community';
import { AgGridI } from '../ag-grid.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Subscription } from 'rxjs';
import { StorageName } from 'src/app/shared/enum/common-enum';



@Component({
  selector: 'app-columns-settings',
  templateUrl: './columns-settings.component.html',
  styleUrls: ['./columns-settings.component.scss'],
})
export class ColumnsSettingsComponent implements OnInit , OnDestroy {
  public params: IHeaderParams;
  @Input() dataModel: any;
  columnArray:any[];
  settingColumns:any[];
  searchTerm: string = '';
  filteredColumns: any[];
  hideColumnsPermanentSubscription: Subscription;
  @Output() onColumnVisibility = new EventEmitter<any>();
  childFields: any=[];
  constructor(private offcanvasService: NgbOffcanvas, public storageService : StorageService){
    this.hideColumnsPermanentSubscription = this.storageService.hideColumnsPermanent.subscribe((result) => {
      if(result != undefined)
        this.hideColumnsPermanentResponse(result)
    });

    
  }
  hideColumnsPermanentResponse(result)
  {
    if(result != undefined &&  this.dataModel != undefined && this.dataModel.columnApi.columnModel.columnDefs[0].headerComponentParams.storageName == result.storageName )
    {
      this.dataModel.hideColumns =  result.columnnames;
      this.storageService.store(result.storageName+"_HIDE",result.columnnames);
    //  this.dataModel.storageName = result.storageName;
      //this.onColumnVisibility.emit(this.dataModel);
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.getAllColumns()
    }, 1000);
  }

  getAllColumns() {
    const displayedColumns =  this.dataModel?.columnApi?.getAllDisplayedColumns();
    const displayedColDefs: ColDef[] = displayedColumns?.map(column => column?.getColDef());
    console.log('Displayed Column Definitions:', displayedColDefs);   
 
 var coldefnew = JSON.parse(JSON.stringify(this.dataModel?.columnApi?.columnModel?.columnDefs));
 console.log(coldefnew,'p')
     this.columnArray = [];
     for (let item of coldefnew) {
       var headername = item?.headerName == "" ? item?.field : item?.headerName;
       if(item.children?.length> 0)
         headername = headername +' (group)';
 
       // if(item?.colDef?.headerName !== ''){
         let Obj = {
           headerName:headername,
           keyName:item.field,
           isChecked:true,
           isVisibleOnColumnSetting :item?.isVisibleOnColumnSetting,
         }
          this.columnArray.push(Obj);
 
          item.children?.forEach(element => {
           let Obj = {
             headerName: element.headerName == "" ? headername+ ' - '+ element.field : headername+ ' - '+ element.headerName,
             keyName:element.field,
             isChecked:true
           }
            this.columnArray.push(Obj);
          });
       // }
       // this.columnArray.filter((x)=>x.colDef?.headerName !='')
      
     }
     this.filterColumns();
   }
  showColumn:boolean=false;

  hideSettingButton() {
    this.showColumn = !this.showColumn;
    this.dataModel.hideColumns = this.storageService.retrieve(this.dataModel.columnApi.columnModel.columnDefs[0].headerComponentParams.storageName+"_HIDE");
    var displayedColDefs = [];
    var displayedColumns = this.dataModel?.columnApi?.columnModel?.columnDefs.filter(a=>a.hide != true && !this.dataModel?.hideColumns?.includes(a.field));

    this.settingColumns = this.columnArray;

    displayedColumns.forEach(element => {
      var colState = this.storageService.retrieve(element.storageName != undefined ?element?.storageName.toString() : "");
      var hideColumn = colState?.filter(a=>a.colId == element.field)[0];
      if(hideColumn?.hide != true)
        displayedColDefs.push(element);
      
      var childrenLength = element.children?.length;
      var hiddenChildrenLength = 0;
      element.children?.forEach(item => {
        var hideColumn = colState?.filter(a=>a.colId == item.field)[0];
        item.hide = hideColumn?.hide;
          if(hideColumn?.hide != true)
            displayedColDefs.push(item);
          else
          hiddenChildrenLength++;
        
      });
      if(childrenLength == hiddenChildrenLength)
        displayedColDefs.pop();

    });

    let nonMatchingColumns = this.settingColumns.filter((settingCol) => {
      return !displayedColDefs.some((displayedCol) => displayedCol.field === settingCol.keyName);
    });

    if (nonMatchingColumns.length == 0) {
      this.setColumnsChacked();
    }

    if (nonMatchingColumns) {
      nonMatchingColumns.forEach((col => {
        if (col.isChecked) {
          col.isChecked = false;
        }
      }))
    }
    this.searchTerm="";
    this.filterColumns();
  }

  setColumnsChacked() {
    this.settingColumns.forEach((col => {
      col.isChecked = true;
    }))
  }
  

  hideOnCheck(keyName,isChecked) {
    var displayedColumns = this.dataModel.columnApi.columnModel.columnDefs.filter(a=>a.headerName?.toLowerCase() == keyName)[0];
    if (displayedColumns != undefined && displayedColumns?.children?.length > 0) {
      const childColumns = displayedColumns?.children;
      childColumns.forEach(element => {
        element.hide = isChecked;
       var settingColumn =   this.settingColumns.filter(a=>a.keyName == element?.field)[0];
       settingColumn.isChecked = isChecked;
        this.dataModel.columnApi.setColumnVisible(element?.field, isChecked?true:false);
      });
    }
    this.dataModel.columnApi.setColumnVisible(keyName, isChecked?true:false);
    this.onColumnVisibility.emit(this.dataModel);
  }

  reset(){
    this.searchTerm="";
    this.dataModel?.columnApi.columnModel.columnDefs.forEach((columnDef) => {
      columnDef.hide = false;
      if(columnDef.children){
        columnDef.children.forEach(child=>{
          child.hide=false;
          this.childFields.push(child?.field)
        })
        this.dataModel.columnApi.setColumnsVisible(this.childFields, true);
      }
    });  
    // this.dataModel =  this.dataModel.columnApi.columnModel.columnDefs.filter(column=>column.headerName !=='');
    this.dataModel.columnApi.setColumnsVisible(this.dataModel?.columnApi?.columnModel?.columnDefs?.map(columnDef => columnDef?.field), true);
    this.onColumnVisibility.emit(this.dataModel);
    // this.dataModel.columnApi.resetColumnState();
    this.getAllColumns();
  }

  filterColumns() {
    if(this.searchTerm !== ' ' ){
      this.filteredColumns = this.columnArray.filter((col) =>
      col.headerName?.toLowerCase()?.includes(this.searchTerm.toLowerCase())
    );
    }else{
      this.filteredColumns =[];
    }
    this.filteredColumns = this.filteredColumns?.filter(column => !this.dataModel?.hideColumns?.includes(column.keyName));
    console.log(this.filteredColumns,'f')
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    console.log('onDrop',event);
    // moveItemInArray(this.filteredColumns, event.previousIndex, event.currentIndex);
  }

  onDragStart(event: DragEvent, item: any): void {
    console.log('onDragStart',event);
    // Implement any additional logic for drag start if needed
  }

  onMouseLeave() {
    this.showColumn = false;
  }

  ngOnDestroy(): void {
    this.hideColumnsPermanentSubscription.unsubscribe();
  }

}