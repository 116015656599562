import { Component, Renderer2 } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { OpenImagePopupComponent } from 'src/app/shared/common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { uploadRequestModel } from 'src/app/shared/models/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { AgGridService } from '../ag-grid.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { ErrorPopupComponent } from '../../common-popup/error-popup/error-popup.component';
import { NotificationService } from '../../../services/notification.service';
import { ModalPopupSize } from '../../../models/common.model';
import { SingleOrMultipleUpload ,ImgUploadAttachKey } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-jwellary-image',
  templateUrl: './jwellary-image.component.html',
  styleUrls: ['./jwellary-image.component.scss']
})
export class JwellaryImageComponent extends CommonService implements ICellRendererAngularComp{
  params: any;
  displayImage:any;
  isDisplayImageByUrl:any
  public baseUrl = environment.baseUrl;
  fromDetailJson : boolean = false;
  fromImageUrl: boolean = false;
  forDirectImgValue: boolean = false;
  isAddImage: boolean = false;
  imagesListData:any= [];
  showDropzone: boolean = false;
  requestModel = new uploadRequestModel()
  constructor( 
    private toster :ToastrService,
    private storageservice  : StorageService,
    private agGridServic : AgGridService ,
    private modalService  : NgbModal , 
    public renderer : Renderer2, 
    public compressImageService     : CompressImageService,
    private notificationService: NotificationService,
   private sendDataService :SendDataService)
    {
    super(renderer);
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.fromDetailJson = this.params.fromDetailJson??false;
    this.fromImageUrl = this.params.fromImageUrl??false;
    this.forDirectImgValue = this.params?.forDirectImgValue??false;
    this.imageShow();

  }

  imageShow(){
    this.displayImage = null;
    if(this.fromDetailJson){
      const parseDataJson = JSON?.parse(this.params?.data?.detailedJson??'[]')??[];
      this.imagesListData = parseDataJson?.invoiceItemImageResources??this.params?.data?.invoiceItemImageResources??[];
      if(this.imagesListData.length){
        this.displayImage = this.imagesListData[0]?.base64Resource;
      }
    }else if(this.fromImageUrl){
      this.imagesListData = this.params?.data?.imageUrls??[]
      if(this.params?.data?.imageUrls){
        this.displayImage = this.params?.data?.imageUrls[0]?.imageUrl;
      }
    } else if(this.forDirectImgValue){
      this.displayImage = this.params?.value;
    }
  }
 
  isMp4File(url: string): boolean {
    return (url?.includes('.mp4') || url?.includes('.mp4'));
  }

  refresh(): boolean {
    this.imageShow();
    return true;
  }

  onIconClick() {
    if ((this.imagesListData?.length && this.displayImage) || (this.displayImage && this.forDirectImgValue)) {
      let imagesList = [];
      if (this.fromDetailJson) {
        const parseDetailedData = this.params?.data?.detailedJson ? JSON?.parse(this.params?.data?.detailedJson ?? '[]') ?? [] : this.params?.data?.invoiceItemImageResources ? this.params?.data : [];
        if (parseDetailedData?.invoiceItemImageResources?.length) {
          const list = parseDetailedData.invoiceItemImageResources;
          imagesList = list
        }
      }
      else if (this.fromImageUrl) {
        const imageList = this.params?.data?.imageUrls ?? [];
        imagesList = imageList
      }
      else if (this.forDirectImgValue) {
        imagesList = [{ imageUrl: this.params?.value }]
      }
      var data = {
        jewelryCatalogue: [...imagesList],

      }
      this.notificationService.openModalPopup(OpenImagePopupComponent, data, '', ModalPopupSize.SM, 'image_popup_fullscreen',true,true).then(
        (result) => {
          if (result) {

          }
        }
      )

    }
  }


  datamodel:any = {}
  onOpenAddImage() {
    // this.showDropzone = true; // Set to true to show Dropzone
    // const fileInput = document.getElementById('fileInput');
    // this.params;
    const data = this.params.data;
    this.storageservice.store("uploadeImageData",  data);
    if(!data.voucherItemId && !data.imageUrls)
    {
      this.errorpopup()
    }
    else if(!data.voucherItemId && data.imageUrls)
    {
      this.openAddImagePopup(this.imagesListData);
    }
    // else if(data.voucherItemId &&!data.imageUrls){
    //   fileInput.click();
    // }
    else{
      this.openAddImagePopup(this.imagesListData);
    }
  
  }

  //#region upload items images start
// async onSelect(event) {
//   const imagesList = []
//  if (event.addedFiles.length > 1) {
//      await this.resourceMultipleFile(event.addedFiles , imagesList);
//  } else {
//      await this.resourceFile(event.addedFiles[0] , imagesList);
//  }
// }

async resourceFile(item: any , list) {
 await this.processFile(item, true ,list);
}

async resourceMultipleFile(items: any ,list) {
 for (let index = 0; index < items.length; index++) {
     const isLastFile = index === items.length - 1;
     await this.processFile(items[index], isLastFile ,list);
 }
}

async processFile(file: any, isLastFile: boolean , imagesList) {
 
 try {
     const compressedFile = await this.compressImageService.compress(file).toPromise();
     const base64String = await this.readThis(compressedFile);
     if (base64String) {
         
         const img = {
             base64Resource: base64String
         };
            imagesList?.push(img)
         if (isLastFile) {
             this.openAddImagePopup(imagesList);
         }
     } else {
         console.error("Failed to get base64 string.");
     }
 } catch (error) {
     console.error("Error processing file:", error);
 }
}
//#endregion

openAddImagePopup(imgList: any[]) {
  const res = this.storageservice.retrieve('uploadeImageData');
  this.notificationService.openAddImagePopup(imgList , true , ImgUploadAttachKey?.Base64Resource ,res ).then((result)=>{
    if(res === false) return;
    if (result) {
          this.requestModel.voucherTypeId = res.voucherTypeId;
          this.requestModel.invoiceItemId = res.voucherItemId;
          this.requestModel.voucherNo = res.voucherNo;
          this.requestModel.invoiceItemImageResources = [...result];
    
          this.agGridServic.uploadInvoiceImages(this.requestModel).subscribe((res) => {
            if (res.isSuccess) {
              this.toster.success(res.message);
              this.sendDataService.sendIsUploadImg(true);
            }
          });
        }
    })
  }

errorpopup(){
var data ={ title : "Error", message : "You cannot upload images for this item."}
this.notificationService.openModalPopup(ErrorPopupComponent,data,undefined,ModalPopupSize.SM,'',false,true).then(
  (result)=>{
// 
  }
)}

}

