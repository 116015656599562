

<div *ngIf="isPopup" class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Enter your item</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>

    <div class="modal-body h_22rem px-1 pb-0">
        <div class="row py-1">
            <div class="col-12 d-flex justify-content-between">
                <div class="col gap-2 d-flex align-items-center me-2"  *ngIf="data.metalSectionModel?.isBarcodeInput">
                        <div class="form-group d-flex gap-2" [ngClass]="'col-12'">
                            <label
                                class="d-flex align-items-center f-14 font-semibold text-gray-color text-nowrap mb-0">Barcode</label>
                            <div class="input-group w-100" >
                                <input class="form-control f-12 h-100 align-bar" id="barcode" type="text" placeholder=""  [(ngModel)]="data.metalSectionModel.barcodeNo" (keydown.enter)="getProductDataByBarcodeNo(data.metalSectionModel.barcodeNo,data.formName)" aria-label="Barcode">
                                <div class="input-group-append ">
                                    <span class="input-group-text px-2 cursorPointer  ">
                                        <img src="./assets/images/svg-icon/barcode_icon.svg" alt="">
                                    </span>
                                </div>
                            </div>
                        </div>
                </div>
                
                <div class="" [ngClass]="{'col-12':!data.metalSectionModel?.isBarcodeInput ,'col-7':data.metalSectionModel?.isBarcodeInput}">
                    <app-nav-tabs [tabItems]="tabItems" (tabId)="selectTab($event)" ></app-nav-tabs>
                </div>
                
                <div class="w-220px ms-2 d-flex align-items-center justify-content-end">
                    <div class="d-flex gap-3">
                        <div class="d-flex align-items-center" *ngIf="data.metalSectionModel?.isInternal_Fix">
                            <app-checkbox  (check)="vatChecked($event)" [status]="data.formModel?.invoiceModel?.vat" [disabled]="false"></app-checkbox>
                            <span class="text-primary f-12 font-semibold ps-2 cursorPointer"  (click)="data.formModel.invoiceModel.vat = !data.formModel.invoiceModel.vat; vatChecked(data.formModel.invoiceModel.vat)">Vat</span>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="data.metalSectionModel?.isInternal_Fix">
                            <app-checkbox  (check)="metalFixChecked($event)" [status]="data.formModel?.invoiceModel?.metalFix" [disabled]="false"></app-checkbox>
                            <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="data.formModel.invoiceModel.metalFix = !data.formModel.invoiceModel.metalFix; metalFixChecked(data.formModel.invoiceModel.metalFix)">Metal Unfix</span>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="data.metalSectionModel?.isInternal_Fix">
                            <app-checkbox (check)="internalFixChecked($event)" [status]="data.formModel?.invoiceModel?.internalFix" [disabled]="false"></app-checkbox>
                            <span class="text-primary f-12 font-semibold ps-2 cursorPointer"(click)="data.formModel.invoiceModel.internalFix = !data.formModel.invoiceModel.internalFix; internalFixChecked(data.formModel.invoiceModel.internalFix)">UnFix</span>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="data.metalSectionModel?.isAML">
                            <app-checkbox (check)="AMLChecked($event)" [status]="data.formModel?.invoiceModel?.isAML" [disabled]="false"></app-checkbox>
                            <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="data.formModel.invoiceModel.isAML = !data.formModel.invoiceModel.isAML; AMLChecked(data.formModel.invoiceModel.isAML)">AML</span>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="data.metalSectionModel?.isKYC">
                            <app-checkbox (check)="KYCChecked($event)" [status]="data.formModel?.invoiceModel?.isKYC" [disabled]="false"></app-checkbox>
                            <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="data.formModel.invoiceModel.isKYC = !data.formModel.invoiceModel.isKYC; KYCChecked(data.formModel.invoiceModel.isKYC)">KYC</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-fill content_tab">
            <div  class="d-flex flex-column h-100" container="body">
                <div class="" id="Sales_Invoice position-relative"  *ngIf="tabItems.length > 0 else noTabsMessage">
                    <div class="" *ngFor="let item of tabGroupList">
                        <app-ag-grid 
                                [ngClass]="tabId == item.tabId ? 'd-block': 'd-none'"
                                [tableId]="item.dataModel.storageName"
                                [class]="'all_aggrid_tables position-relative d-block '+ gridDynamicHeightClass" 
                                [dataModel]="item.dataModel"
                                [gridOptions]="gridOptions"
                                (lastCellTriggered)="lastCellTriggered($event)"
                                (cellValueChanged)="onCellValueChangedDetect($event)"
                                [navigateGridOption]="gridOptionsPayment"
                                [addBlankRowOnEnter]="item.dataModel.addBlankRowOnEnter"
                                [columnSettings]="item.dataModel.columnSettings"
                                (gridReady)="onGridReady(item.tabIndex, $event)"
                                (onHeaderAddClick)="headerAddClick($event)"
                                (addNewBlankRow)="onAddNewRow($event)"
                                (onDeleteIconClick)="onDeleteIconClick($event)"
                                (onRapnetIconClick)="onRapnetIconClick($event)"
                                >
                        </app-ag-grid>
                    </div>
                  
                    <form  *ngIf="data.metalSectionModel.showCommentAndAddField">
                        <div class="row mx-0">
                            <div class="col-12 overflow sale_bill_one px-0">
                                <table class="table table-bordered table-scroll border-right">
                                    <tbody>
                                        <tr class="">
                                            <td width="7%">
                                                <div class="table_text text-nowrap font-semibold f-14">Group Name</div>                                    </td>
                                            <td width="22%">
                                                <app-input 
                                                [inputName]="'groupName'" 
                                                [inputType]="'text'"
                                                [inputId]="'groupName'" 
                                                [inputValue]="data.metalSectionModel.groupName" 
                                                (inputValueEmit)="data.metalSectionModel.groupName = $event" 
                                                [isRequired]="false"
                                                [readOnly]="data.metalSectionModel.isCreateDemo ?true:false"
                                                (keydown.enter)="onAddButtonPress($event,'comment')">
                                            </app-input>
                                            </td>
                                            <td width="6%">
                                                <div class="table_text font-semibold f-14">Comment</div>
                                            </td>
                                            <td width="65%">
                                                <app-input 
                                                [inputName]="'comment'" 
                                                [inputType]="'text'"
                                                [inputId]="'comment'" 
                                                [inputValue]="data.metalSectionModel.comment" 
                                                (inputValueEmit)="data.metalSectionModel.comment = $event" 
                                                [isRequired]="false"
                                                [readOnly]="data.metalSectionModel.isCreateDemo ?true:false"
                                                (keydown.enter)="onAddButtonPress($event,'addButton')">
                                            </app-input>
                                            </td>
                                            <!-- <td width="75%" class="text-end pe-2 font-semibold f-14">
                                                <div class="table_text w-100">Return</div>
                                            </td>
                                            <td width="10%">
                                                <app-input 
                                                    [applyDecimalOrPercent]="true"
                                                    [inputClass]="'bordernone bg-transperent text-end'"
                                                    [inputName]="''" 
                                                    [inputType]="'text'" 
                                                    [inputId]="''" 
                                                    [inputValue]="''"
                                                    >
                                                </app-input>
                                            </td> -->
                                            <td width="5%" >
                                                <div class="d-flex align-items-center gap-3 mx-3 my-1 border-0">
                                                    <div *ngIf="data.metalSectionModel.addButton" class="d-flex align-items-center width-max-content" [ngClass]="{'cursor-not-allowed':!agGridService?.cameraButtonDisabled}">
                                                        <app-button 
                                                            [ngClass]="{'pointer_none':!agGridService?.cameraButtonDisabled}"
                                                            class="" 
                                                            [buttonLabel]="'Add (Shift + A)'"
                                                            [buttonClass]="'add_button px-2'" 
                                                            [buttonType]="'button'"
                                                            [showPlusIcon]="true"
                                                            [buttonId]="'addButton'"
                                                            [disabled]="!checkedValue()"
                                                            (buttonOutputEvent)="addProductsInItemList()"
                                                            (keydown.enter)="onAddButtonPress($event,'payment')"
                                                            >
                                                        </app-button>
                                                    </div>
                                                    <ng-container *ngTemplateOutlet="imgUpload"></ng-container>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
                <ng-template #noTabsMessage>
                    <div class="d-flex align-items-center justify-content-center">Please configure metals.</div>
                  </ng-template>
            </div>
        </div>
        
        <ng-template #imgUpload>
            <div>
                <ng-container *ngIf="!uploadImagesList?.length">
                   <div  [ngClass]="{'cursor-not-allowed':!agGridService?.cameraButtonDisabled}">
                    <!-- [style.pointer-events]="!agGridService.cameraButtonDisabled ? 'none' : 'auto'" -->
                        <div id="uploadItemImages" [ngClass]="{'pointer_none':!agGridService?.cameraButtonDisabled}" class="rounded child_image justify-content-center border-0 h-auto cursorPointer ">
                            <label class="m-0" (click)="openAddImagePopup()">
                                <img class="cursorPointer" src="./assets/images/svg-icon/camera_icon.svg" alt="">
                            </label>  
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="uploadImagesList?.length">
                    <div [ngClass]="{'cursor-not-allowed':!agGridService?.cameraButtonDisabled}">
                        <label id="uploadItemImages" [style.pointer-events]="!agGridService.cameraButtonDisabled ? 'none' : 'auto'" class="m-0" (click)="openAddImagePopup()" for="uploadImg">
                            <img  class="cursorPointer"
                            src="./assets/images/svg-icon/camera_icon.svg"
                            alt=""
                            >
                        </label>   
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </div>
