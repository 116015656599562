export function convertUtcToLocalDate(utcDate: string): string {
    try {
        if (utcDate) {
            // const localDate = new Date(utcDate + "Z");
            const localDate = new Date(utcDate);
            return localDate?.toLocaleDateString();
        } else {
            return "";
        }
    } catch (error) {
        console.error("Error converting UTC to local date:", error);
        return "";
    }
}

export function formatUtcToTimeString(utcDate: string): string {
    try {
        if (utcDate) {
            const time = new Date(utcDate);
            return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        } else {
            return "";
        }
    } catch (error) {
        console.error("Error formatting UTC to time string:", error);
        return "";
    }
}

// Add this function in a utility file or a common service
export function convertUtcToLocalDateTime(utcDate: string): string {
    const utcDateTime = new Date(utcDate);
    // const localDateTime = new Date(utcDateTime.toLocaleString("en-US", { timeZone: "UTC" }));
    // return localDateTime.toLocaleString(); // You can customize the format as needed
    return utcDateTime?.toLocaleString(); // You can customize the format as needed
  }

  export function convertUtcToLocalDateTimeCustom(utcDate: string): string {
    // Replace space with 'T' to make it ISO 8601 compliant
    const isoDate = utcDate.replace(' ', 'T').split('.')[0];
    const utcDateTime = new Date(isoDate).toString();

       // Return the Date object for use with DatePipe
       return utcDateTime;
}


export function convertUtcToLocalDateCustom(utcDate: string): string {
    // Replace space with 'T' to make it ISO 8601 compliant
    const isoDate = utcDate.replace(' ', 'T').split('.')[0];
    const utcDateTime = new Date(isoDate);

    // Format the date as 'Aug 30 2024'
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    return utcDateTime.toLocaleString("en-US", options);
}

export function convertTimeToCustomFormat(time: string): string {
    const [hours, minutes, seconds] = time.split(':').map(Number);

    // Convert the total time to minutes and hours
    const totalMinutes = (hours * 60) + minutes + Math.floor(seconds / 60);
    const formattedHours = Math.floor(totalMinutes / 60);
    const formattedMinutes = totalMinutes % 60;
    const formattedSeconds = seconds % 60; 
    return `${formattedHours}H ${formattedMinutes}M ${formattedSeconds}S`;
}

//#region stroage key name constant
  export const LayoutDetailsStoreKeyName = 'layoutDetails';
//#endregion 

//  export const cjAppMainUrl = 'app.canadianjewellerstech.ca'
 export const cjAppMainUrl = 'https://app.canadianjewellerstech.ca'
   // svg logo url path 
  export const cjLoginLogoPath  = 'assets/SVGicon/canadian-jewellers-logo/cj-logo-login.svg';
  export const cjLightLogoPath  = 'assets/SVGicon/canadian-jewellers-logo/cj-logo-light.svg';
  export const cjHeaderLogoPath = "assets/SVGicon/canadian-jewellers-logo/cj-logo-header.svg";
  export const cjDarkLogoPath   = "assets/SVGicon/canadian-jewellers-logo/cj-logo-main.svg";
  export const cjBusinessRegistrationLogoPath = "assets/SVGicon/canadian-jewellers-logo/cj-bussiness-registraton-imglogo.svg";

  export const jsLoginLogoPath  = 'assets/SVGicon/jewelsteps-logo/js-logo-login.svg';
  export const jsLightLogoPath  = 'assets/SVGicon/jewelsteps-logo/js-logo-light.svg';
  export const jsHeaderLogoPath = "assets/SVGicon/jewelsteps-logo/js-logo-header.svg";
  export const jsDarkLogoPath   = "assets/SVGicon/jewelsteps-logo/js-logo-main.svg";
  export const jsBusinessRegistrationLogoPath   = "assets/SVGicon/jewelsteps-logo/js-bussiness-registraton-imglogo.svg";

  export function convertTo12HourFormat(time24: string): string {
    const [hour, minute] = time24.split(':').map(Number);
    const suffix = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; 
    return `${hour12}:${minute.toString().padStart(2, '0')} ${suffix}`;
  }
