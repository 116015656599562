import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { IconHover } from '../../../enum/common-enum';


@Component({
  selector: 'app-print-confirmation',
  templateUrl: './print-confirmation.component.html',
  styleUrls: ['./print-confirmation.component.scss']
})
export class PrintConfirmationComponent implements OnInit , AfterViewInit {
  currentHoverEffect = IconHover.Pink;
  formName;
  storageName = StorageName
  @Input() title ='Print';
  @Input() barcodeSvg:boolean = false
constructor(public activeModal : NgbActiveModal){

}
  ngAfterViewInit(): void {
    const element = document.querySelectorAll('.custom_focus') as any;
    element[1]?.focus();
  }
  ngOnInit(): void {
   
  }
  
 

  onKeyDown(event: KeyboardEvent | any) {
    event?.srcElement?.click();
  }

}
