import { NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { flatMap } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @ViewChild('myButton') myButton: ElementRef;
  @Input() buttonType:string;
  @Input() buttonClass:string;
  @Output("buttonOutputEvent") buttonOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() inputReadonly : boolean  = false;
  @Input() buttonLabel:string;
  @Input() showIcon: boolean = false;
  @Input() showIconCount: boolean = false;
  @Input() isFilterCount :string;
  @Input() showFilterIcon:boolean =false;
  @Input() showPlusIcon: boolean = false;
  @Input() showUploadIcon: boolean = false;
  @Input() showUploadIconWhite: boolean = false;
  @Input() showPlusIconwhite : boolean = false;
  @Input() showRefreshIcon : boolean = false;
  @Input() showUpload1Icon : boolean = false;
  @Input() showBackIcon : boolean = false;
  @Input() importIcon : boolean = false;
  @Input() buttonId:string;
  @Input() ResetBlueIcon :boolean =false;
  @Input() ResetdarkBlueIcon :boolean=false;
  @Input() copywhiteIcon :boolean=false;
  @Input() backupDownloadIcon :boolean=false;
  @Input() disabled :boolean=false;
  @Input() showImportWhite:boolean=false;
  @Input() badgeCount:any;
  @Input() position:boolean=false;
  @Input() saveButton:boolean=false;

  constructor(private renderer: Renderer2) { }
  @Input() showSearchIcon : boolean = false;

  ngOnInit(): void {
  }

  blurOnEnter() {
    this.myButton.nativeElement.blur();
    // let element = document.getElementById(this.buttonId) as HTMLInputElement;
    // element?.click();
  }
}