import { Component, Input, OnInit } from '@angular/core';
import { ProductTax } from './product-checkout.model';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CustomerOrderSummary } from 'src/app/shared/models/common.model';
import { ActivatedRoute, Router } from '@angular/router';
import { JewelryService } from 'src/app/jewelry/jewelry.service';

@Component({
  selector: 'app-product-checkout',
  templateUrl: './product-checkout.component.html',
  styleUrls: ['./product-checkout.component.scss']
})
export class ProductCheckoutComponent implements OnInit {
  @Input() hide_Header:boolean=false;
  formName = StorageName.FORMNAME_PRODUCT_PRODUCTCKECKOUT;
  catelogueId: any;
  quantityDropdownList = [1,2,3,4,5,6,7,8,9,10]
  //  proudctOrderCheckout = new ProudctOrderCheckout();
   proudctOrderCheckout:any = new Object();
   orderSummaryList =[];
   productTax = new ProductTax()
   tax:any;
   quantity = 1;
   options = [1, 2, 3, 4]; 
   dropdownStates: boolean[] = [];
   action = Action
   anonymoususerLedgerId: any;
  constructor(public sendDataService        : SendDataService,
    private storageservice: StorageService,
    public router: Router,
    public jewelryService: JewelryService,
    private activatedRoute: ActivatedRoute,
  ){
    
    this.orderSummaryList = this.proudctOrderCheckout.orderSummary;
    this.tax =  this.proudctOrderCheckout.tax
  }

  
  ngOnInit() {
    !this.hide_Header ? this.sendDataService.displayPageName(this.formName):null;
    const user= this.storageservice.retrieve('user');
    this.activatedRoute.queryParams.subscribe((params) => {
      this.catelogueId = params["id"];
    });
    const customerId = user?.accountLedgerId ? user?.id :undefined;
    const customersOrderSummary =  this.storageservice.retrieve('CustomerOrderSummary');
    this.proudctOrderCheckout =  customersOrderSummary?.find(x=>x?.customerId == customerId);
    const anonymoususerDrtails= this.storageservice.retrieve("moususerDrtails");
    this.anonymoususerLedgerId = anonymoususerDrtails.LedgerId;
  } 
  placeOrder(){
    if(!this.anonymoususerLedgerId){
      // this.router.navigateByUrl("/app-jewelry/billingDetails")
      this.router.navigate(["/app-jewelry/billingDetails"] , {
          queryParams: { id: this.catelogueId },
        })
    }else{
      this.router.navigateByUrl("/app-jewelry/orderSuccsesfull")
    }
  }


  
  toggleDropdown(index: number) {
   // this.dropdownStates[index] = !this.dropdownStates[index];
  }
  
  // updateQuantity(option: number, index: number) {
  //   this.quantity = option;
  //   this.dropdownStates[index] = false; 
  // };

  calculateTotal(amount: number, quantity: number): number {
    return amount * quantity;
  }

calculateSubTotal(){
if(this.proudctOrderCheckout?.orderSummary?.length){
  return (this.proudctOrderCheckout?.orderSummary?.filter(x=>x?.isCheck == true)?.map((x)=>x.total = this.calculateTotal(x?.amount??0 , x.qty??0))?.reduce((a ,b)=>(a+b),0))??0;
}else{
  return 0
}
}

  finalAmt(){
  return (this.calculateSubTotal() + this.productTax.CGST + this.productTax.IGST + this.productTax.SGST)??0;
  }

  removeProductFromCart(productId , index){
    this.updateProductSummary(productId , false ,'',undefined, Action.Remove)
  }

  checkUncheckCartProduct(isActive , prouductId){
    this.updateProductSummary(prouductId , isActive ,'',undefined, Action.CheckUnchek);
  }


  updateProductSummary(productId ,isActive ,index,qty,action){
    const user= this.storageservice.retrieve('user');
    const customerId = user?.accountLedgerId ? user?.id :undefined;
    const customersOrderSummary =  this.storageservice.retrieve(CustomerOrderSummary)??[];
    this.proudctOrderCheckout =  customersOrderSummary?.find(x=>x?.customerId == customerId);
    switch(action){
      case Action.CheckUnchek:
        const product =  this.proudctOrderCheckout?.orderSummary?.find((x)=>x.productId == productId);
        product.isCheck = isActive;
        break;
        case Action.Remove:
          this.proudctOrderCheckout?.orderSummary?.splice(index , 1);
        break;
        case Action.UpdateQty:
          const productDetail=  this.proudctOrderCheckout?.orderSummary?.find((x)=>x.productId == productId);
          productDetail.qty = qty;

        break;
    }
    this.storageservice.store(CustomerOrderSummary , customersOrderSummary) ;
    this.sendDataService.updateCart.next(true);
  }

  updateQty(qty , prouductId){
    this.updateProductSummary(prouductId , undefined ,'',qty, Action.UpdateQty);
  }

}

export enum Action {
  Remove = 1,
  UpdateQty = 2,
  CheckUnchek = 3
}