<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Add Image</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" [ngClass]="currentHoverEffect" (click)="activeModal.close('close')"></app-svg-icon>
</div>
<form ngNativeValidate #ImageForm="ngForm">
    <div class="modal-body AddImagePopUp mx-2">
        <div class="row pt-1 pb-3" >
            <div class="col-4 pe-0">  
                <div class="main_image">
                    <img *ngIf="!displayImage || getCategoryFromBase64Url(displayImage) == FileCategory.EXCEL || getCategoryFromBase64Url(displayImage) == FileCategory.PDF || getCategoryFromBase64Url(displayImage) == FileCategory.OTHER || getCategoryFromBase64Url(displayImage) == FileCategory.DOC || getCategoryFromBase64Url(displayImage) == FileCategory.TXT" class="img-fluid h-100 w-100 cursorPointer" src="./assets/svg/nopreviewImage.svg" alt="previewImage" (click)="openImgPopUp()" onerror="this.src = './assets/svg/nopreviewImage.svg'">
                    <img *ngIf="getCategoryFromBase64Url(displayImage) == FileCategory.IMAGE" class="img-fluid h-100 w-100 cursorPointer" [src]="isBase64Image(displayImage)" alt="previewImage" (click)="openImgPopUp()" onerror="this.src = './assets/svg/nopreviewImage.svg'">
                    <video #videoPlayer *ngIf="getCategoryFromBase64Url(displayImage) == FileCategory.VIDEO" (click)="openImgPopUp()" loop autoplay  [muted]="true" class="w-100 h-100 cursorPointer">
                        <source [src]="isBase64Image(displayImage)">
                        Does not support.
                    </video>                     
                </div>
            </div>
            <div class="col-8">
                <div class="d-flex flex-wrap gap-3 ms-1">
                    <div class="row m-0 p-0">
                        <div class="p-0 m-0 d-flex gap-3 overflow-auto flex-wrap child_item_img_container">
                            <!-- [accept]="'.png,.jpg,.jpeg,.pdf,.xls,.xlsx,.mp4,.avi,.mov'" -->
                             <!-- 'image/jpeg,.png,.jpg,.pdf,.xls,.xlsx,.mp4,.avi,.mov,.doc,.docx,.txt' -->
                            
                            <div 
                            class="tabel-bg-color disabled border rounded child_image justify-content-center {{ !data?.alldata ? 'cursorPointer' : (!data?.alldata?.voucherItemId ? 'cursor-not-allowed' : 'cursorPointer') }}" 
                            ngx-dropzone 
                            [accept]="data?.isAcceptAllDoc ? '*' : 'image/jpeg,.png,.jpg'"
                            (change)="onSelect($event)"
                            [multiple]="data?.isMultipleUpload ? true : false"
                            [disabled]="!data?.alldata ? false : (!data?.alldata?.voucherItemId ? true : false)">
                                <app-svg-icon class="w-50" [name]="'upload_Icon'"></app-svg-icon>
                            </div>
                            <ng-container *ngFor="let img of imagesList ;let i = index">
                                <div *ngIf="!this.data?.isAction || !img?.deleted"  class="child_image tabel-bg-color border rounded justify-content-center position-relative">
                                    <img *ngIf="getCategoryFromBase64Url(img?.base64Resource) == FileCategory.IMAGE" [src]="isBase64Image(img?.base64Resource)" alt="img" (click)="showPreviewImage(img,i)" class="w-100 h-100 cursorPointer" loading="lazy">
                                    
                                    <video *ngIf="getCategoryFromBase64Url(img?.base64Resource) == FileCategory.VIDEO" (click)="showPreviewImage(img,i)" [muted]="true" loop autoplay class="w-100 h-100 cursorPointer">
                                        <source [src]="isBase64Image(img?.base64Resource)">
                                        Does not support.
                                    </video>
                                    <div *ngIf="getCategoryFromBase64Url(img?.base64Resource) == FileCategory.EXCEL"(click)="openFileInNewTab(img?.base64Resource,FileFormats.XLSX)" class="w-100 h-100 d-flex align-items-center justify-content-center cursorPointer">
                                        <app-svg-icon  [name]="FileCategory.EXCEL"></app-svg-icon>
                                    </div>
                                    <div *ngIf="getCategoryFromBase64Url(img?.base64Resource) == FileCategory.PDF"(click)="openFileInNewTab(img?.base64Resource,FileFormats.PDF)" class="w-100 h-100 d-flex align-items-center justify-content-center cursorPointer">
                                        <app-svg-icon  [name]="FileCategory.PDF"></app-svg-icon>
                                    </div>
                                    <div *ngIf="getCategoryFromBase64Url(img?.base64Resource) == FileCategory.OTHER"(click)="openFileInNewTab(img?.base64Resource,'')" class="w-100 h-100 d-flex align-items-center justify-content-center cursorPointer">
                                        <app-svg-icon  [name]="FileCategory.OTHER"></app-svg-icon>
                                    </div>
                                    <div *ngIf="getCategoryFromBase64Url(img?.base64Resource) == FileCategory.DOC"(click)="openFileInNewTab(img?.base64Resource,FileFormats.DOC)" class="w-100 h-100 d-flex align-items-center justify-content-center cursorPointer">
                                        <app-svg-icon [name]="FileCategory.DOC"></app-svg-icon>
                                    </div>
                                    <div *ngIf="getCategoryFromBase64Url(img?.base64Resource) == FileCategory.TXT"(click)="openFileInNewTab(img?.base64Resource,FileFormats.TXT)" class="w-100 h-100 d-flex align-items-center justify-content-center cursorPointer">
                                        <app-svg-icon  [name]="FileCategory.TXT"></app-svg-icon>
                                    </div>
                                      
                                    <div (click)="removeImage(i ,img)" class="position-absolute top-0 end-0 m-1 cursorPointer cross-icon">
                                    <app-svg-icon class="" [name]="'cross'"></app-svg-icon>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-2 pb-1 mx-2">
        <div class="col justify-content-start">
            <span (click)="checkPermissions()">
              <img
                class="cursorPointer"
                src="./assets/images/svg-icon/cam_icon.svg"
                alt="camera icon"
              />
            </span>
        </div>

          
        <div class="justify-content-end">
            <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="saveImages()" [buttonClass]="'blue_button'"
                [buttonType]="'submit'">
            </app-button>
        </div>
        
    </div>
</form>