import { Component, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { InsertTaskTypeRequestModel } from 'src/app/components/administration/administration.model';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { IconHover } from '../../../enum/common-enum';

@Component({
  selector: 'app-expenses-category-popup',
  templateUrl: './expenses-category-popup.component.html',
  styleUrls: ['./expenses-category-popup.component.scss']
})
export class ExpensesCategoryPopupComponent extends CommonService{
  currentHoverEffect = IconHover.Pink;
  insertTaskTypeRequestModel = new InsertTaskTypeRequestModel();
  @ViewChild("ExpensesForm") ExpensesForm!: NgForm;
  formName = StorageName.FORMNAME_TRANSACTION_EXPENSES;
  getAllDropdownModel : any = {};
  storageName = StorageName;

  constructor(public activeModal: NgbActiveModal,
    public administrationService    : AdministrationService,
    public renderer                 : Renderer2,
    public toaster                  : ToastrService,
    public storageService           :StorageService,) {
      super(renderer);
      // this.getAllDropdownModel.expensesCategoryDropdown = this.storageService.retrieve(StorageName.EXPENSES_CATEGORY_DROPDOWN)
    }

    InserExpensesList() {
      if (this.ExpensesForm.valid) {
        this.administrationService.InsertExpensesCategoryList(this.insertTaskTypeRequestModel)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if (res.isSuccess) {
              this.toaster.success(res.message);
              this.activeModal.close(true);
            } else {
              this.toaster.error(res.message);
            }
          });
      }
    }
}
