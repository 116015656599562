import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noMultipleDecimals]'
})
export class NoMultipleDecimalsDirective {

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const input = event.target.value;
    if ((input.match(/\./g) || []).length > 1) {
      event.target.value = input.substring(0, input.lastIndexOf('.'));
    }
  }
}
