<div> 
      <svg *ngIf="timelineStatusId == jobWorkStatus?.Completed" xmlns="http://www.w3.org/2000/svg" width="15.868" height="10.474" viewBox="0 0 15.868 10.474">
        <path id="Path_3433" data-name="Path 3433" d="M654.936,654.436l5.082,5.082,3.509-3.508,5.155-5.155" transform="translate(-653.875 -649.794)" fill="none" stroke="#4f39cd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      </svg>
      <svg *ngIf="timelineStatusId == jobWorkStatus?.Processing" xmlns="http://www.w3.org/2000/svg" width="19.413" height="17.469" viewBox="0 0 19.413 17.469">
        <g id="Group_42117" data-name="Group 42117" transform="translate(2983.419 3137.878)">
          <path id="Path_3431" data-name="Path 3431" d="M-2964.282-3132.536c-.374-.247-.7-.071-.94.271s-.487.681-.812,1.134c-.165-.432-.273-.7-.372-.973a8.658,8.658,0,0,0-9.219-5.741,8.53,8.53,0,0,0-7.613,6.923,8.684,8.684,0,0,0,7.266,10.419,8.532,8.532,0,0,0,8.045-3.055,3.468,3.468,0,0,0,.5-.747.52.52,0,0,0-.26-.758.957.957,0,0,0-.7.134c-.188.11-.3.354-.442.536a7.483,7.483,0,0,1-8.137,2.4,7.445,7.445,0,0,1-5.178-6.612,7.474,7.474,0,0,1,5.954-7.922,7.5,7.5,0,0,1,8.858,5.87c-.6-.387-1.042-.669-1.48-.962a.585.585,0,0,0-.88.119.559.559,0,0,0,.153.871c.875.615,1.764,1.211,2.672,1.777a.658.658,0,0,0,.954-.252c.57-.826,1.15-1.644,1.721-2.469C-2963.964-3131.911-2963.893-3132.281-2964.282-3132.536Z" fill="#4f39cd"/>
          <path id="Path_3432" data-name="Path 3432" d="M-2954.377-3114.933v4.229l-3.725,2.24a.5.5,0,0,0-.172.69.5.5,0,0,0,.431.244.5.5,0,0,0,.258-.072l3.969-2.386a.5.5,0,0,0,.244-.431v-4.513a.5.5,0,0,0-.5-.5A.5.5,0,0,0-2954.377-3114.933Z" transform="translate(-20.032 -17.93)" fill="#4f39cd"/>
        </g>
      </svg>
      
      <svg *ngIf="timelineStatusId == jobWorkStatus?.Hold" xmlns="http://www.w3.org/2000/svg" width="13.499" height="13.502" viewBox="0 0 13.499 13.502">
        <path id="Union_158" data-name="Union 158" d="M6,6,0,12,6,6,0,0,6,6l6-6L6,6l6,6Z" transform="translate(0.75 0.75)" fill="none" stroke="#7c89a4" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      </svg>
      
      <svg *ngIf="timelineStatusId == jobWorkStatus?.NotInitiate" xmlns="http://www.w3.org/2000/svg" width="13.499" height="13.502" viewBox="0 0 13.499 13.502">
        <path id="Union_158" data-name="Union 158" d="M6,6,0,12,6,6,0,0,6,6l6-6L6,6l6,6Z" transform="translate(0.75 0.75)" fill="none" stroke="#ef6a65" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      </svg>
</div>
  