import { AgColDefsI, AgGridI } from "../../../../shared/common-components/ag-grid/ag-grid.model";
import { StorageName, WeightColWidth } from "../../../../shared/enum/common-enum";
import { CheckboxColumn } from "../../../../shared/common-components/metal-section/metal-section.model";
import { TextRendererComponent } from "../../../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { CheckboxRendererComponent } from "src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { AgGridCustomDropdownComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { StorageService } from "src/app/shared/services/storage.service";
import { AgGridCustomInputComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { CustomHeaderComponent } from "src/app/shared/common-components/ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { MetalType } from "src/app/shared/models/common.model";
import { API_URL_GET_ALL_DIAMOND_STOCK } from "src/app/shared/url-helper/url-helper";

const ItemCodeColumn: AgColDefsI = {
    headerName: "Item Code",
    field: "itemCode",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "itemCode",
      filterIdColumnName: "itemCode",    
      isInputFilter: true,
    },
   
   
  };

  const ItemCode: AgColDefsI = {
    headerName: "Item Code",
    field: "itemCode",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: true,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "itemCode",
      filterIdColumnName: "itemCode",    
      isInputFilter: true,
    },
   
  };
  const BarcodeNoColumn: AgColDefsI = {
    headerName: "Barcode No",
    field: "barcodeNo",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "barcodeNo",
      filterIdColumnName: "barcodeNo",    
      isInputFilter: true,
    },
  };

const RateColumn: AgColDefsI = {
    headerName: "Rate",
    field: "rate",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isRate: true },
    headerComponentParams:{
      filterValueColumnName: "rate",
      filterIdColumnName: "rate",    
      isInputFilter: true,
      isAllowNumber:true
    },
  };

const StyleColumn: AgColDefsI = {
    headerName: "Style",
    field: "style",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "styleId",
      filterIdColumnName: "styleId",
      filterParams: StorageName.STYLE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    
  };
  
const DiamondCategoryColumn: AgColDefsI = {
    headerName: "Diamond Category",
    field: "diamondCategoryName",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "diamondCategory",
      filterIdColumnName: "diamondCategory",
      filterParams: StorageName.DIAMOND_CATEGORY_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
   
  };

const CalculationTypeColumn: AgColDefsI = {
    headerName: "Calculation Type",
    field: "calculationTypeName",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "calculationType",
      filterIdColumnName: "calculationType",
      filterParams: StorageName.CALCULATION_TYPE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
   
  };

const ProductColumn: AgColDefsI = {
    headerName: "Product",
    field: "product",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "productDetailId",
      filterIdColumnName: "productDetailId",
      filterParams: StorageName.DIAMOND_STONE_PRODUCT_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
  };

const GoldCaratColumn: AgColDefsI = {
    headerName: "Gold Carat",
    field: "goldCarat",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "goldCarat",
      filterIdColumnName: "goldCarat",
      isInputFilter: true,
    },
    
  };

const GrossWtColumn: AgColDefsI = {
    headerName: "Gross Wt",
    field: "grossWt",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "grossWt",
      filterIdColumnName: "grossWt",
      isInputFilter: true,
      isAllowNumber:true
    },
  }; 

const WeightColumn: AgColDefsI = {
    headerName: "Weight",
    field: "weight",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "weight",
      filterIdColumnName: "weight",
      isInputFilter: true,
      isAllowNumber:true
    },
  }; 

const QuantityColumn: AgColDefsI = {
    headerName: "Quantity",
    field: "quantity",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "quantity",
      filterIdColumnName: "quantity",
      isInputFilter: true,
      isAllowNumber:true
    },
  }; 

const CertificateNumberColumn: AgColDefsI = {
    headerName: "Certificate Number",
    field: "certificateNumber",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "certificateNumber",
      filterIdColumnName: "certificateNumber",
      isInputFilter: true,
    },
  };   
  
const CutColumn: AgColDefsI = {
    headerName: "Cut",
    field: "cut",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "cutId",
      filterIdColumnName: "cutId",
      filterParams: StorageName.CUT_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent,
  };  

const DiamondCaratColumn: AgColDefsI = {
    headerName: "Diamond Carat",
    field: "purityWt",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "purityWt",
      filterIdColumnName: "purityWt",
      isInputFilter: true,
      isAllowNumber:true
    },
  }; 

const ColorColumn: AgColDefsI = {
    headerName: "Color",
    field: "color",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "colorId",
      filterIdColumnName: "colorId",
      filterParams: StorageName.COLOR_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent,
  };  

const SeiveSizeColumn: AgColDefsI = {
    headerName: "SeiveSize",
    field: "seiveSize",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "seiveSizeId",
      filterIdColumnName: "seiveSizeId",
      filterParams: StorageName.SEIVE_SIZE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent,
  };  


const SizeColumn: AgColDefsI = {
    headerName: "Size",
    field: "size",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "sizeId",
      filterIdColumnName: "sizeId",
      filterParams: StorageName.SIZE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent, 
  };  

const ShapeColumn: AgColDefsI = {
    headerName: "Shape",
    field: "shape",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "shapeId",
      filterIdColumnName: "shapeId",
      filterParams: StorageName.SHAPE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent,
  };  

const QualityColumn: AgColDefsI = {
    headerName: "Quality",
    field: "clarity",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "clarityId",
      filterIdColumnName: "clarityId",
      filterParams: StorageName.CLARITY_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
  };  

export const ColumnDefForAddDiamondTable :AgColDefsI[] =[
    CheckboxColumn,
    ItemCodeColumn,
    BarcodeNoColumn,
    StyleColumn,
    DiamondCategoryColumn,
    CalculationTypeColumn,
    ProductColumn,
    GoldCaratColumn,
    GrossWtColumn,
    WeightColumn,
    DiamondCaratColumn,
    QuantityColumn,
    RateColumn,
    CertificateNumberColumn,
    CutColumn,
    ColorColumn,
    SeiveSizeColumn,
    SizeColumn,
    ShapeColumn,
    QualityColumn,
  ]

  const ColumnDefForAddStoneGemsTable :AgColDefsI[] =[
    CheckboxColumn,
    ItemCodeColumn,
    BarcodeNoColumn,
    StyleColumn,
    CalculationTypeColumn,
    ProductColumn,
    GoldCaratColumn,
    GrossWtColumn,
    WeightColumn,
    DiamondCaratColumn,
    QuantityColumn,
    RateColumn,
    CertificateNumberColumn,
    CutColumn,
    ColorColumn,
    SeiveSizeColumn,
    SizeColumn,
    ShapeColumn,
    QualityColumn
  ]
//#region add diamond popup


export const agGridDataModelAddDiamondTable: AgGridI = {
    colDefs: ColumnDefForAddDiamondTable,
    rowSelection: "multiple",
    storageName : StorageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID,
    filterStorageName: StorageName.FILTER_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID,
    sortStorageName: StorageName.SORT_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID,
    initialRows: StorageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID_INITIAL_ROWS,
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    rowHeight:28,
    headerHeight : 70,
    rows:[],
    isFilter: true, 
    isServerSidepagination : false, 
    apiUrl :  API_URL_GET_ALL_DIAMOND_STOCK,
  };

  //#endregion 

  const BarcodeNo: AgColDefsI = {
    headerName: "Barcode No.",
    field: "barcodeNo",
    sortable: true,
    minWidth: 110,
    editable: false,
    isRequired: false,
    cellClass: "custom-column-group-cell px-0 text-center",
    headerClass: "custom-column-group-cell px-0",
    cellRenderer: TextRendererComponent,
     headerComponentParams:{
      filterValueColumnName: "barcodeNo",
      filterIdColumnName: "barcodeNo",    
      isInputFilter: true,
      isRequired: false ,enableSorting: false
    },
  }

  export const Style: AgColDefsI = {
    headerName: "Style",
    field: "styleId",
    minWidth: 120,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.STYLE_DROPDOWN);
      },
    },
    headerComponentParams:{
      filterValueColumnName: "styleId",
      filterIdColumnName: "styleId",
      filterParams: StorageName.STYLE_DROPDOWN,
      floatingMultiselectDropDown: true,
      enableSorting: false,isRequired: false
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  }
  const DiamondCategory: AgColDefsI = {
    headerName: "Diamond Category",
    field: "diamondCategoryId",
    minWidth: 100,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent, 
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
      },
    },
    headerComponentParams:{
      filterValueColumnName: "diamondCategoryId",
      filterIdColumnName: "diamondCategoryId",
      filterParams: StorageName.DIAMOND_CATEGORY_DROPDOWN,
      floatingMultiselectDropDown: true,
      enableSorting: false
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  };

  const CalculationType: AgColDefsI = {
    headerName: "Calculation Type",
    field: "calculationType",
    minWidth: 100,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.CALCULATION_TYPE_DROPDOWN);
      },
    },
    headerComponentParams:{
      filterValueColumnName: "calculationType",
      filterIdColumnName: "calculationType",
      filterParams: StorageName.CALCULATION_TYPE_DROPDOWN,
      floatingMultiselectDropDown: true,
      enableSorting: false
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  };
  export const ProductDiamond: AgColDefsI = {
  headerName: "Product",
  field: "productDetailId",
  minWidth: 110,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
     var dropdownValues =  storageService.retrieve(storage.PRODUCT_DROPDOWN)
      return dropdownValues.filter(a=>a.extra1 == MetalType.Diamond.toString());
    },
  },
  headerComponentParams:{
    filterValueColumnName: "productId",
    filterIdColumnName: "productId",
    filterParams: StorageName.PRODUCT_DROPDOWN,
    floatingMultiselectDropDown: true,
    isRequired: true, enablePlusIconLight: true , enableSorting: false
},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const ProductStone: AgColDefsI = {
  headerName: "Product",
  headerComponentParams:{
    filterValueColumnName: "productId",
    filterIdColumnName: "productId",
    filterParams: StorageName.PRODUCT_DROPDOWN,
    floatingMultiselectDropDown: true,
    isRequired: true, enablePlusIconLight: true , enableSorting: false
},
field: "productDetailId",
minWidth: 110,
sortable: true,
editable: true,
cellRenderer: TextRendererComponent,
cellEditor: AgGridCustomDropdownComponent,
cellEditorParams: {
  values: () => {
    const storageService = new StorageService();
    const storage = StorageName;
    return storageService.retrieve(storage.PRODUCT_DROPDOWN).filter(a=>a.extra1 == MetalType.StoneGEMS.toString());
  },
},
cellClass: "custom-column-group-cell px-0",
headerClass: "custom-column-group-cell px-0",
};

const GoldCarat: AgColDefsI = {
  headerName: "Gold Carat",
  field: "goldCaratId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  headerComponentParams:{
    filterValueColumnName: "goldCaratId",
    filterIdColumnName: "goldCaratId",
    filterParams: StorageName.CARAT_DROPDOWN,
    floatingMultiselectDropDown: true,
    isRequired: false, enablePlusIconLight: true , enableSorting: false
 },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const GrossWt: AgColDefsI = {
  headerName: "Gross Wt",
  field: "grossWt",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams:{
      filterValueColumnName: "grossWt",
      filterIdColumnName: "grossWt",    
      isInputFilter: true,
      isRequired: false, enableSorting: false
    },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  }
}

export const Weight: AgColDefsI = {
  headerName: "Weight",
  field: "weight",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams:{
    filterValueColumnName: "weight",
    filterIdColumnName: "weight",    
    isInputFilter: true,
    isRequired: false, enableSorting: false
  },
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

export const DiamondCarat: AgColDefsI = {
  headerName: "Diamond Carat",
  field: "carat",
  sortable: true,
  minWidth: 110,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  },
  headerComponentParams:{
    filterValueColumnName: "carat",
    filterIdColumnName: "carat",    
    isInputFilter: true,
    isRequired: false, enableSorting: false
  },
};

export const Quantity: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    isOnlyNumbers:true,
  },
  headerComponentParams:{
    filterValueColumnName: "quantity",
    filterIdColumnName: "quantity",    
    isInputFilter: true,
    isRequired: false, enableSorting: false
  },
  valueGetter: (param) => {
    return param.data.quantity !== undefined && param.data.quantity !== null? param.data.quantity: 1;
  }
};

export const Rate: AgColDefsI = {
  headerName: "Rate",
  field: "rate",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellRendererParams: { isRate: true },
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  },
  headerComponentParams:{
    filterValueColumnName: "rate",
    filterIdColumnName: "rate",    
    isInputFilter: true,
    isRequired: false, enableSorting: false
  },
};
const CertificateNumber: AgColDefsI = {
  headerName: "Certificate No.",
  field: "certificateNumber",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams:{
    filterValueColumnName: "certificateNumber",
    filterIdColumnName: "certificateNumber",    
    isInputFilter: true,
    isRequired: false, enableSorting: false
  },
};
export const Cut: AgColDefsI = {
  headerName: "Cut",
  field: "cutId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CUT_DROPDOWN);
    },
  },
  headerComponentParams:{
    filterValueColumnName: "cutId",
    filterIdColumnName: "cutId",
    filterParams: StorageName.CUT_DROPDOWN,
    floatingMultiselectDropDown: true,
    enableSorting: false
 },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const Color: AgColDefsI = {
  headerName: "Color",
  field: "colorId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.COLOR_DROPDOWN);
    },
  },
  headerComponentParams:{
    filterValueColumnName: "colorId",
    filterIdColumnName: "colorId",
    filterParams: StorageName.COLOR_DROPDOWN,
    floatingMultiselectDropDown: true,
    enableSorting: false
 },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const SeiveSize: AgColDefsI = {
  headerName: "SeiveSize",
  field: "seiveSizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SEIVE_SIZE_DROPDOWN);
    },
  },
  headerComponentParams:{
    filterValueColumnName: "seiveSizeId",
    filterIdColumnName: "seiveSizeId",
    filterParams: StorageName.SEIVE_SIZE_DROPDOWN,
    floatingMultiselectDropDown: true,
    enableSorting: false
 },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
export const Size: AgColDefsI = {
  headerName: "Size",
  field: "sizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SIZE_DROPDOWN);
    },
  },
  headerComponentParams:{
    filterValueColumnName: "sizeId",
    filterIdColumnName: "sizeId",
    filterParams: StorageName.SIZE_DROPDOWN,
    floatingMultiselectDropDown: true,
    enableSorting: false
 },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const Shape: AgColDefsI = {
  headerName: "Shape",
  field: "shapeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SHAPE_DROPDOWN);
    },
  },
  headerComponentParams:{
    filterValueColumnName: "shapeId",
    filterIdColumnName: "shapeId",
    filterParams: StorageName.SHAPE_DROPDOWN,
    floatingMultiselectDropDown: true,
    enableSorting: false
 },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const Quality: AgColDefsI = {
  headerName: "Clarity",
  field: "clarityId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CLARITY_DROPDOWN);
    },
  },
  headerComponentParams:{
    filterValueColumnName: "clarityId",
    filterIdColumnName: "clarityId",
    filterParams: StorageName.CLARITY_DROPDOWN,
    floatingMultiselectDropDown: true,
    enableSorting: false
 },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const Action: AgColDefsI = {
  headerName: "",
  field: "action",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  cellRendererParams: {
    editIcon: false,
    isFirstDeleteIcon:true,
    resetIcon:true
  },
  headerComponentParams: {  moreaddButton: true  },
  cellClass: "mx-0 text-end px-1",
  minWidth: 80,
  maxWidth: 80,
  extraField:"Action"
};
  export const ColumnDefForAddDiamondModelTable :AgColDefsI[]=[
    ItemCode,
    BarcodeNo,
    Style,
    DiamondCategory,
    CalculationType,
    ProductDiamond,
    GoldCarat,
    GrossWt,
    Weight,
    DiamondCarat,
    Quantity,
    Rate,
    CertificateNumber,
    Cut,
    Color,
    SeiveSize,
    Size,
    Shape,
    Quality,
    Action
  ]
  
  export const agGridDataModelAddDiamondModelTable: AgGridI = {
    colDefs: ColumnDefForAddDiamondModelTable,
    rowSelection: "multiple",
    storageName : StorageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_BYAPI_GRID,
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    rowHeight:28,
    rows:[],
  };


