<app-load-dropdown-data [formName]="formName"></app-load-dropdown-data>
<div class="container-fluid d-flex flex-column px-0 pt-3 h-100">
    <div class="main-container col-12 d-flex flex-fill">
      <div class="item-1 w-350px border">
        <div class="pt-3 d-flex justify-content-center align-items-center user-image">
            <div class="avatar">
                <img alt="" [src]="isBase64Image(parentCompany?.companyLogo)" (error)="handleImageError($event)"/>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column mt-3">
              <label for="" class="theme-text-primary-blue text-center f-16 font-semibold mb-0">{{parentCompany?.companyName ||'NA'}}</label>
              <label class="theme-color-secondary text-center f-13">{{parentCompany?.branchName || 'NA'}}</label>
            </div>
        </div>
        <hr class="mt-2 mb-1 border-gray-1">
        <div class="d-flex col-12 gap-2 p-3">
            <div class="col">
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">CRID</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{parentCompany?.crid || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Email</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12 text-break">{{parentCompany?.email || 'NA'}}</p>
                </div>
            </div>
            <div class="col">
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Registration Date</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{parentCompany?.registrationDate || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Business License No.</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12 text-break">{{parentCompany?.businessLicenceNo || 'NA'}}</p>
                </div>
            </div>
        </div>
      </div>

      <div class="item-2 col border">
        <div class="row px-3 pt-3 mt-1 position-relative cursorPointer" *ngFor="let item of getAllCompanyQueryResponse ; ;let i = index" (click)="assignToSubCompany(item,i)">
            <div class="col d-flex gap-2">
                <div class="col d-flex gap-3">
                    <div class="">
                        <img alt="" width="150px" [src]="isBase64Image(item?.companyLogo)" class="h-90px" (error)="handleImageError($event)"/>
                    </div>
                    <div class="col text-break d-flex item-2-content gap-2">
                        <div class="col">
                            <div class="text-gray-color font-semibold f-16">{{item?.companyName || 'NA'}}</div>
                            <div class="text-gray-color font-regular f-12 mb-1">{{item?.branchName || 'NA'}}</div>
                            <div *ngIf="item?.isCurrentCompany == true">
                                <button class="d-flex align-items-center current_com_button border-0"><span
                                        class="f-11 font-regular text-gray-color text-nowrap">Current Company</span></button>
                            </div>
                        </div>
                        <div class="col d-flex gap-2 ">
                            <div class="mt-1">
                                <svg id="Group_42968" data-name="Group 42968" xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 10.958 15.585">
                                    <path id="Path_1346" data-name="Path 1346" d="M4712.374,1895.495c.374.069.752.117,1.116.212a5.222,5.222,0,0,1,2.43,1.434,5.333,5.333,0,0,1,1.443,2.677,5.405,5.405,0,0,1-.671,4q-2.081,3.483-4.168,6.96a.469.469,0,0,1-.867.012q-2.145-3.453-4.285-6.907a5.489,5.489,0,0,1,3.54-8.281c.243-.051.489-.075.734-.111Zm-.3,14.236c.026-.04.042-.061.054-.082l3.784-6.321a4.561,4.561,0,1,0-8.438-2.875,4.519,4.519,0,0,0,.7,3q1.649,2.651,3.293,5.3C4711.67,1909.077,4711.869,1909.394,4712.078,1909.731Z" transform="translate(-4706.532 -1895.495)" fill="#f92b35"/>
                                    <path id="Path_1347" data-name="Path 1347" d="M4781.2,1967.372a2.738,2.738,0,1,1-2.746,2.724A2.737,2.737,0,0,1,4781.2,1967.372Zm-.007,4.565a1.827,1.827,0,1,0-1.829-1.824A1.829,1.829,0,0,0,4781.195,1971.937Z" transform="translate(-4775.716 -1964.632)" fill="#f92b35"/>
                                </svg>                           
                            </div>
                            <div class="text-gray-color font-regular f-13">
                                {{item?.address || 'NA'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 d-flex flex-column justify-content-between">
                   <div class="d-flex justify-content-end position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="154" height="32" viewBox="0 0 154 32">
                            <defs>
                            <linearGradient id="linear-gradient" x1="0.055" y1="0.5" x2="0.956" y2="0.5" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#e6a600"/>
                                <stop offset="0.464" stop-color="#ffb900"/>
                                <stop offset="1" stop-color="#e6a700"/>
                            </linearGradient>
                            </defs>
                            <path id="Path_3665" data-name="Path 3665" d="M0,0H154l-7.452,16.4L154,32H0L8.609,15.336Z" fill="url(#linear-gradient)"/>
                        </svg>  
                        <div class="f-14 font-semibold position-absolute premium_name text-white">Premium Plan</div>   
                   </div> 
                   <div class="d-flex justify-content-end align-items-center">
                        <div class="d-flex align-items-center border-right gap-2" (click)="navigateToEditProfile(item)">
                            <div class="d-flex align-items-center">
                                <svg class="cursorPointer" xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 8.145 7.992">
                                    <path id="Path_3664" data-name="Path 3664" d="M-1994.126,5977.17a.432.432,0,0,1-.138.3l-.425.42q-2.391,2.369-4.78,4.737a.739.739,0,0,1-.355.2q-.948.239-1.893.483a.422.422,0,0,1-.447-.093.41.41,0,0,1-.076-.445c.194-.612.383-1.225.571-1.838a.661.661,0,0,1,.175-.283q2.591-2.563,5.179-5.127a.417.417,0,0,1,.686-.005q.67.663,1.339,1.326A.469.469,0,0,1-1994.126,5977.17Zm-7.074,5.2.009,0,1.11-.285a.228.228,0,0,0,.108-.066q1.782-1.765,3.566-3.528c.067-.066.047-.1-.008-.152-.245-.239-.491-.479-.728-.725-.073-.075-.109-.058-.173.006q-1.766,1.754-3.535,3.5a.292.292,0,0,0-.08.1c-.117.364-.23.731-.344,1.1C-2001.284,5982.356-2001.29,5982.393-2001.2,5982.371Zm6.1-5.212c-.01-.011-.022-.028-.037-.043-.261-.259-.523-.517-.783-.777-.04-.041-.065-.04-.1,0-.2.2-.391.392-.591.584-.052.05-.042.08,0,.125q.385.376.765.758c.047.048.077.049.125,0,.191-.194.386-.384.58-.577C-1995.12,5977.211-1995.1,5977.2-1995.1,5977.158Z" transform="translate(2002.271 -5975.342)" fill="#1492e6"/>
                                </svg>                                  
                            </div>
                            <div class="text-color-sky-blue f-12 font-regular pe-3 cursorPointer">Edit</div>
                        </div>
                        <div class="d-flex align-items-center p-2 gap-2" (click)="deleteCompany(item.id)">
                            <div class="ps-1 d-flex align-items-center">
                                <svg class="cursorPointer" xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 6.572 8.308">
                                    <g id="Group_42952" data-name="Group 42952" transform="translate(0)">
                                      <path id="Path_1245" data-name="Path 1245" d="M-1558.372,5984.4c0-.743,0-1.486,0-2.23,0-.1-.012-.151-.134-.156a.3.3,0,0,1-.3-.335c0-.169,0-.339,0-.508a.812.812,0,0,1,.8-.8q.487-.007.973,0c.09,0,.128-.02.123-.118-.008-.143,0-.287,0-.431a.312.312,0,0,1,.341-.344q1.049,0,2.1,0a.313.313,0,0,1,.344.34c0,.143.006.288,0,.43,0,.094.024.125.119.122.314,0,.628,0,.942,0a.816.816,0,0,1,.832.847c0,.159,0,.317,0,.476a.29.29,0,0,1-.294.323c-.139,0-.137.068-.137.169q0,2.184,0,4.367a1.179,1.179,0,0,1-.835,1.185,1.241,1.241,0,0,1-.36.049h-3.314a1.166,1.166,0,0,1-1.2-1.2C-1558.375,5985.853-1558.372,5985.124-1558.372,5984.4Zm5.1-.033c0-.727,0-1.456,0-2.184,0-.124-.026-.167-.157-.166q-2.09.007-4.181,0c-.129,0-.158.039-.158.165,0,1.45,0,2.9,0,4.352a.569.569,0,0,0,.632.633h3.208a.576.576,0,0,0,.65-.662Q-1553.272,5985.433-1553.273,5984.363Zm-2.244-2.964c.846,0,1.692,0,2.538,0,.1,0,.144-.018.14-.132-.007-.221-.068-.291-.294-.291h-4.772a.59.59,0,0,0-.121.005c-.129.026-.186.147-.169.333.013.146.123.079.185.08C-1557.179,5981.4-1556.348,5981.4-1555.517,5981.4Zm0-1.036c.222,0,.445-.007.668,0,.117,0,.116-.058.116-.141s0-.143-.117-.141q-.668.01-1.336,0c-.116,0-.117.056-.117.141s0,.146.117.141C-1555.963,5980.356-1555.74,5980.363-1555.518,5980.363Z" transform="translate(1558.804 -5979.472)" fill="#f92b35"/>
                                      <path id="Path_1246" data-name="Path 1246" d="M-1516.207,6056.928q0,.822,0,1.645c0,.234-.119.377-.309.372s-.3-.146-.3-.37q0-1.652,0-3.3c0-.226.115-.36.3-.36s.3.129.3.358C-1516.206,6055.821-1516.207,6056.375-1516.207,6056.928Z" transform="translate(1518.523 -6051.808)" fill="#f92b35"/>
                                      <path id="Path_1247" data-name="Path 1247" d="M-1485.436,6056.93q0-.831,0-1.661c0-.228.111-.36.3-.361s.307.129.307.356q0,1.658,0,3.32c0,.22-.123.362-.308.358s-.3-.145-.3-.368Q-1485.437,6057.752-1485.436,6056.93Z" transform="translate(1488.418 -6051.806)" fill="#f92b35"/>
                                      <path id="Path_1248" data-name="Path 1248" d="M-1454.07,6056.932q0-.832,0-1.661c0-.23.11-.361.3-.362s.308.131.308.357q0,1.658,0,3.319a.317.317,0,0,1-.31.359c-.18,0-.3-.14-.3-.354C-1454.071,6058.038-1454.07,6057.485-1454.07,6056.932Z" transform="translate(1458.327 -6051.808)" fill="#f92b35"/>
                                    </g>
                                </svg>                                  
                            </div>
                            <div class="d-flex align-items-center text-color-danger f-12 font-regular cursorPointer">Delete</div>  
                        </div>
                   </div>                
                </div>
            </div>
            <hr class="mt-3 mb-0 border-gray-1"> 
                <div *ngIf="i == selectedIndex" class="arrow-right position-absolute"></div>
        </div>
      </div>

      <div class="item-3 w-350px border">
        <div class="pt-3 d-flex justify-content-center align-items-center user-image">
            <div class="avatar">
                <img alt="" [src]="isBase64Image(subCompany?.companyLogo)" (error)="handleImageError($event)"/>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column mt-3">
              <label for="" class="theme-text-primary-blue text-center f-16 font-semibold mb-0">{{subCompany?.companyName ||'NA'}}</label>
              <label class="theme-color-secondary text-center f-13">{{subCompany?.branchName || 'NA'}}</label>
            </div>
        </div>
        <hr class="mt-2 mb-1 border-gray-1">
        <div class="d-flex col-12 gap-2 p-3">
            <div class="col-6">
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Name</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{subCompany?.fullName || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">CRID</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{subCompany?.crid || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Email</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12 break-word">{{subCompany?.email || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Country</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{subCompany?.country || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">City</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{subCompany?.city || 'NA'}}</p>
                </div>
            </div>
            <div class="col-6">
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Contact</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{subCompany?.contactNo || 'NA'}}</p>
                    <!-- +{{layoutDetails?.dialCode}} -  -->
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Registration Date</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12">{{subCompany?.registrationDate || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">Business License No.</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12 text-break">{{subCompany?.businessLicenceNo || 'NA'}}</p>
                </div>
                <div>
                    <div class="theme-color-secondary font-regular mb-0 f-11">State</div>
                    <p class="p-0 mb-2 text-gray-color font-semibold f-12 ">{{subCompany?.state || 'NA'}}</p>
                </div> 
            </div>
        </div>
      </div>
    </div>
    <hr class="mb-3 border-gray-1">
    <div class="d-flex align-items-center gap-2 justify-content-end pb-3">
        <button class="common_button pink_button" (click)="close()">Close</button>
        <button class="common_button blue_button" (click)="navigateToNewCompany()">New Company</button>
    </div>
</div>
  