import { Component, HostListener } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AgGridService } from '../ag-grid.service';
import { ICellRendererParams } from 'ag-grid-community';
import { DropdownModel, MetalType } from 'src/app/shared/models/common.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ColumnCellType, DepositInto, PaymentMethod, StorageName } from 'src/app/shared/enum/common-enum';
import { Router } from '@angular/router';
import { SendDataService } from 'src/app/shared/services/send-data.service';

@Component({
  selector: 'app-text-renderer',
  templateUrl: './text-renderer.component.html',
  styleUrls: ['./text-renderer.component.scss']
})
export class TextRendererComponent implements ICellRendererAngularComp {
  // private handled: boolean = false;
  // private lastRightClickTime: number = 0;
  // private readonly doubleClickThreshold: number = 300;
  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.ctrlKey && event.key === 'c') {
  //     if (!this.handled) {
  //       this.handled = true;
  //       this.triggerContextMenu(event);
  //       setTimeout(() => this.handled = false, 100); // Reset after a short delay
  //     }
  //     event.preventDefault(); // Prevent default action
  //     event.stopImmediatePropagation(); // Stop event propagation
  //   }
  // }

  // @HostListener('contextmenu', ['$event'])
  // onContextMenu(event: MouseEvent) {
  //   event.preventDefault(); // Prevent the default context menu

  //   const currentTime = new Date().getTime();
  //   const isDoubleClick = currentTime - this.lastRightClickTime <= this.doubleClickThreshold;

  //   if (isDoubleClick) {
  //     this.triggerContextMenu(event);
  //   }

  //   this.lastRightClickTime = currentTime;
  // }
  params;
  name: string;
  isCursor: boolean = false;
  constructor(public agGridService: AgGridService, public storageService : StorageService,public sendDataService : SendDataService,
    public route : Router
  ) {}
  ngOnInit(): void {}
  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    // // for tax dyanamic columns
    // if(params?.colDef?.cellRendererParams != undefined){
    //   var index = params?.colDef?.cellRendererParams?.findIndex(tax=>tax.name == params?.colDef?.field);
    //   if(index != -1){
    //     this.params.value = params?.colDef?.cellRendererParams[index].extra1??'0.00';
    //   }
    // }
    // if(params?.colDef?.field == 'quantity'){
    //   this.params.value = this.params.value??1;
    //   this.name = params.value;
    // }
    const valuesOrFunction = params.colDef.cellEditorParams?.values;
    var values = typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction;
    if(params?.colDef?.field == 'diamondCategoryId'){
      if(this.params.data.diamondCategoryId){
        params.value = this.params.data.diamondCategoryId
      }
    }


    switch (params.column.getColId()) {
      case "depositeIntoId":
      case "transferFromId":
        switch (Number(params.data.paymentMethodId)) {
          case PaymentMethod.Card:
            values = this.storageService.retrieve(StorageName.CREDITCARD_DROPDOWN);
            break;
          case PaymentMethod.Cash:
            values = this.storageService.retrieve(StorageName.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Cash_in_Hand) ?? [];
            break;
          case PaymentMethod.Bank:
          case PaymentMethod.Cheque:
          case PaymentMethod.UPI:
            values = this.storageService.retrieve(StorageName.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Bank_Account || a.extra2 == DepositInto.Bank_OD_AC) ?? [];
            break;
        }
        break;
      case "caratId":
        let caratMetalId = params.colDef.headerComponentParams.storageName.indexOf('GOLD') != -1 ? MetalType.Gold :
          (params.colDef.headerComponentParams.storageName.indexOf('SILVER') != -1 ? MetalType.Silver : (
            (params.colDef.headerComponentParams.storageName.indexOf('DIAMOND') != -1 ? MetalType.Diamond : params?.data?.metalId)
          ))

        caratMetalId = (caratMetalId == MetalType.Diamond) ? MetalType.Gold : caratMetalId;
        if (!caratMetalId)
          values = this.storageService.retrieve(StorageName.CARAT_DROPDOWN) ?? [];
        else
          values = this.storageService.retrieve(StorageName.CARAT_DROPDOWN)?.filter(a => a.extra1 == caratMetalId.toString()) ?? [];
        break;

      case "goldCaratId":
        let goldCaratMetalId = params?.colDef?.headerComponentParams?.storageName.indexOf('DIAMOND') != -1 ? MetalType.Gold : params?.data?.metalId;

        if (!goldCaratMetalId)
          values = this.storageService.retrieve(StorageName.CARAT_DROPDOWN) ?? [];
        else
          values = this.storageService.retrieve(StorageName.CARAT_DROPDOWN)?.filter(a => a.extra1 == goldCaratMetalId.toString()) ?? [];
        break;

      case "paymentMethodId":
      
        const paymentTypeDropdownList = this.storageService.retrieve(StorageName.PAYMENTTYPE_DROPDOWN);
    
        const paymentTypeOptionsList2 = ["MetalExchange", "OldJewellery", "M2A", "A2M"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
        const paymentTypeOptionsList3 = ["MetalExchange", "OldJewellery"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
        const paymentTypeOptionsList4 = ["Cash", "Bank", "Cheque", "UPI", "Card", "A2M", "MetalExchange"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
        const paymentTypeOptionsList5 = ["Cash", "Bank", "Cheque", "UPI", "Card"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
    
        switch (params.column.getColDef().headerComponentParams.storageName) {
          case StorageName.TRANSACTION_SALEINVOICE_PAYMENT_GRID:
          case StorageName.TRANSACTION_PURCHASEINVOICE_PAYMENT_GRID:
          case StorageName.TRANSACTION_PURCHASEQUOTATION_PAYMENT_GRID:
          case StorageName.TRANSACTION_SALESQUOTATION_PAYMENT_GRID:
          case StorageName.TRANSACTION_RECEIPTVOUCHER_PAYMENT_GRID:
          case StorageName.TRANSACTION_SALESORDER_PAYMENT_GRID:
          case StorageName.ORDERS_REPAIRORDER_PAYMENT_GRID:
          case StorageName.TRANSACTION_SALESRETURN_PAYMENT_GRID:
          case StorageName.TRANSACTION_PAYMENTVOUCHER_PAYMENT_GRID:
          case StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PAYMENT_GRID:
          case StorageName.TRANSACTION_PURCHASEORDER_PAYMENT_GRID:
          case StorageName.MANUFACTURER_JOBWORK_ORDER_PAYMENT_LIST_GRID:
          case StorageName.TRANSACTION_JOBWORKINVOICE_PAYMENT_GRID:
          case StorageName.TRANSACTION_DELIVERYNOTE_PAYMENT_GRID:
          case StorageName.TRANSACTION_REPAIRINVOICE_PAYMENT_GRID:
          case StorageName.TRANSACTION_JOBWORKORDER_PAYMENT_GRID:
          case "Receipt Voucher":
          case "Payment Voucher":
            values =  paymentTypeDropdownList; 
            break;
          case StorageName.TRANSACTION_PURCHASERETURN_PAYMENT_GRID:
            values =  paymentTypeOptionsList4;
            break;
          case StorageName.TRANSACTION_EXPENSES_PAYMENT_GRID:
          case StorageName.TRANSACTION_CREDITNOTE_PAYMENT_GRID:
          case StorageName.TRANSACTION_DEBITNOTE_PAYMENT_GRID:
          case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_WITHDRAW_GRID:
          case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_RECEIPT_GRID:
          case StorageName.TRANSACTION_EXPENSES_PAYMENT_LIST_GRID:
          case StorageName.TRANSACTION_NEW_LOAN_PAYMENT_GRID:
    
          values =  paymentTypeOptionsList5; 
            break;
    
    
          case StorageName.TRANSACTION_MATERIALIN_PAYMENT_GRID:
          case StorageName.TRANSACTION_MATERIALOUT_PAYMENT_GRID:
          case StorageName.MANUFACTURER_JOBWORK_QUEUE_PAYMENT_LIST_GRID:
            values =  paymentTypeOptionsList3;
            break;
    
    
    
          case StorageName.TRANSACTION_MATERIAL_ISSUE_PAYMENT_GRID:
          case StorageName.TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID:
            values =  paymentTypeOptionsList2;
            break;
    
          case StorageName.TRANSACTION_NEW_LOAN_PAYMENT_GRID:
            
            break;

        }
    }

    if (values == undefined) {
      this.name = params.value;
      this.isCursor = this.params?.isCursor ?? false;
    }
    else {
      const selectedOption = values.find((option: DropdownModel) => option.id === params.value?.toString());
      this.name = selectedOption ? selectedOption.name : params.value;
    }
    this.updateName(params.value, values);
  }

  onContextMenu(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default context menu

    // Detect double right-click
    const currentTime = new Date().getTime();
    const isDoubleClick = currentTime - this.lastRightClickTime <= this.doubleClickThreshold;
    this.lastRightClickTime = currentTime;

    if (isDoubleClick) {
      this.triggerContextMenu(event);
    }
  }

  triggerContextMenu(event: MouseEvent): void {
    // Access current cell details
    const cellValue = this.params.value; // Get cell value
    const rowIndex = this.params.rowIndex; // Get row index
    const colId = this.params.column.getId(); // Get column ID

    
    
    
    

      const customEvent = new CustomEvent('cellContextMenu', {
    detail : { data: this.params },
  });
  this.params.api.dispatchEvent(customEvent);
  }

  private lastRightClickTime: number = 0;
  private readonly doubleClickThreshold: number = 300; 


  refresh(params: ICellRendererParams<any, any>): boolean {
    this.params = params;
    this.updateName(params.value);

    switch (this.params.colDef.field) {
      case "caratId":

        let metalId = params.colDef.headerComponentParams.storageName.indexOf('GOLD') != -1 ? MetalType.Gold :
          (params.colDef.headerComponentParams.storageName.indexOf('SILVER') != -1 ? MetalType.Silver : (
            (params.colDef.headerComponentParams.storageName.indexOf('DIAMOND') != -1 ? MetalType.Diamond : params?.data?.metalId)
          ))

        var dropdown: any = [];
        metalId = (metalId == MetalType.Diamond) ? MetalType.Gold : metalId;

        if (!metalId)
          dropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        else {
          dropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN).filter(a => a.id == params?.value?.toString() && a.extra1 == metalId.toString())[0];
        }

        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          
        this.name = "";
        break;
      case "goldCaratId":
        var dropdown: any = 
        [];
        let goldCaratMetalId = params?.colDef?.headerComponentParams?.storageName.indexOf('DIAMOND') != -1 ? MetalType.Gold : params?.data?.metalId;
        if (!goldCaratMetalId)
          dropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        else {
          dropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN).filter(a => a.id == params?.value?.toString() && a.extra1 == goldCaratMetalId.toString())[0];
        }
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "makingTypeId":
        var dropdown = this.storageService.retrieve(StorageName.MAKINGCHARGESTYPE_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "diamondCategoryId":
        var dropdown = this.storageService.retrieve(StorageName.DIAMOND_CATEGORY_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "calculationType":
        var dropdown = this.storageService.retrieve(StorageName.CALCULATION_TYPE_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "calculationType":
        var dropdown = this.storageService.retrieve(StorageName.CALCULATION_TYPE_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "productDetailId":
        var dropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "paymentMethodId":
        var dropdown = this.storageService.retrieve(StorageName.PAYMENTTYPE_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "transferFromId":
        case "depositeIntoId":
        var dropdown = this.storageService.retrieve(StorageName.DEPOSIT_INTO_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "metalId":
        var dropdown = this.storageService.retrieve(StorageName.METAL_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "categoryId":
        var dropdown = this.storageService.retrieve(StorageName.CATEGORY_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "defaultOutputTypeId":
      case "defaultInputTypeId":
        var dropdown = this.storageService.retrieve(StorageName.TAX_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "locationId":
        var dropdown = this.storageService.retrieve(StorageName.LOCATION_DROPDOWN).filter(a => a.id == params.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
        case "colorId":
        var dropdown = this.storageService.retrieve(StorageName.COLOR_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "shapeId":
        var dropdown = this.storageService.retrieve(StorageName.SHAPE_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "seiveSizeId":
        var dropdown = this.storageService.retrieve(StorageName.SEIVE_SIZE_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "sizeId":
        var dropdown = this.storageService.retrieve(StorageName.SIZE_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
      case "clarityId":
        var dropdown = this.storageService.retrieve(StorageName.CLARITY_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
        if (dropdown != undefined)
          this.name = dropdown.name;
        else
          this.name = "";
        break;
        case "cutId":
          var dropdown = this.storageService.retrieve(StorageName.CUT_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
          if (dropdown != undefined)
            this.name = dropdown.name;
          else
            this.name = "";
          break;
          case "shapeCategoryId":
            var dropdown = this.storageService.retrieve(StorageName.SHAPE_CATEGORY_DROPDOWN).filter(a => a.id == params?.value?.toString())[0];
            if (dropdown != undefined)
              this.name = dropdown.name;
            else
              this.name = "";
            break;
      default:
        this.name = params.value;
        break;
        
    }
    this.isCursor = this.params?.isCursor ?? false;
    return true;
  }

  updateName(value: any,defaultvalues : any= undefined): void {
    const valuesOrFunction = this.params?.colDef?.cellEditorParams?.values;
    const values = (defaultvalues != undefined) ? defaultvalues : typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction;

    if (values === undefined) {
      this.name = value;
    } else {
     // const selectedOption = values.find((option: DropdownModel) => option.id === value?.toString());
     const selectedOption = values?.find((option: DropdownModel) => option?.id?.toString() === value?.toString());
     this.name = selectedOption ? selectedOption.name : value;
    }
  }
//  // Function to trigger context menu on double click
//  triggerContextMenu(any: KeyboardEvent): void {
//   event.preventDefault(); // Prevent default context menu if needed

//   // // Simulate a right-click (context menu) action at the current mouse position
//   // const simulatedEvent = new MouseEvent('contextmenu', {
//   //   bubbles: true,
//   //   cancelable: true,
//   //   view: window,
//   //   clientX: event.clientX, // X position of the double-click
//   //   clientY: event.clientY, // Y position of the double-click
//   //   button: 2 // Button 2 represents right-click
//   // });

//   const customEvent = new CustomEvent('cellContextMenu', {
//     detail : { data: this.params },
//   });
//   this.params.api.dispatchEvent(customEvent);

// }

  onClick(event){
    if( this.isCursor)
    {
      const customEvent = new CustomEvent('textCellClick', {
        detail : { data: this.params },
      });
      this.params.api.dispatchEvent(customEvent);
    }
  }
}



export function  getCellStyleForColor(columnCellType) {
  // params.value contains the current cell value
  // You can add any condition based on the cell value to determine the style
  switch(columnCellType)
  {
    case ColumnCellType.DefaultColumn :
      return null;
    case ColumnCellType.OpeningAmountColumn :
      return { 'text-align': 'right', backgroundColor: '#e7cee4' };
    case ColumnCellType.OpeningWtColumn :
      return { 'text-align': 'right', backgroundColor: '#e7cee4' };
    case ColumnCellType.ClosingAmountColumn :
      return { 'text-align': 'right', backgroundColor: '#add6d6'};
    case ColumnCellType.ClosingWtColumn :
      return { 'text-align': 'right', backgroundColor: '#add6d6' };
    case ColumnCellType.AmountColumn :
      return { 'text-align': 'right', backgroundColor: '#cce3f9' };
    case ColumnCellType.WtColumn :
      return { 'text-align': 'right', backgroundColor: '#d5f0f5' };
  }
}
