import { AgColDefsI, AgGridI } from "src/app/shared/common-components/ag-grid/ag-grid.model";
import { CheckboxRendererComponent } from "src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { TextRendererComponent } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { StorageName } from "src/app/shared/enum/common-enum";

const ActionColumn: AgColDefsI = {
  headerName: "",
  headerComponentParams: { selectAll : false },
  field:'isSelected',    
  lockPosition:true,
  cellClass: "custom-column-group-cell",
  cellRenderer:CheckboxRendererComponent  ,
  headerClass:" mx-0 px-2 d-flex justify-content-center custom-column-group-cell border-0",
  cellRendererParams: { showLabel: false ,require: false },
  minWidth: 35,
  maxWidth: 35,
  editable:false, 
};

const Name: AgColDefsI = {
  headerName: "Name",
  resizable: false,
  sortable: false,
  lockPosition:true,
  headerClass:"px-0 custom-column-group-cell",
  field:'name',
  minWidth:150,
  flex: 1,
  cellClass:'mx-0 px-2 custom-column-group-cell',
  editable:false,
  cellRenderer:TextRendererComponent,
};

export const ColDefsAgainstOfList: AgColDefsI[] = [
  // RadioField, 
  ActionColumn,
  Name
];

export const AgGridAgainstOfList: AgGridI = {
  colDefs: ColDefsAgainstOfList,
  rowSelection: "single",
  storageName: StorageName.TRANSACTION_INVOICES_AGAINSTOFPOPUP_GRID,
};

export enum AgainstOf {
  SaleOrder = 1,
  DeliveryNote = 2,
  SaleQuotation = 3,
  SalesInvoice = 4,
  PurchaseInvoice = 5,
  RepairOrder = 6,
  PurchaseQuotation = 7,
  PurchaseOrder = 8,
}

// sale order dropdown popup end