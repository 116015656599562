import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SecurityService } from './security.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from "./notification.service"
//service
import { Router } from '@angular/router';
import {environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    isLoading:boolean=false;
    constructor(
        private http: HttpClient,
        private securityService: SecurityService,
        private router: Router,
        private toaster: ToastrService,
        private notificationService : NotificationService
    ) { 

    }

    get(url: string, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader?: boolean): Observable<Response> {
        return this.doGet(environment.baseUrl + url, isAuthTokenRequired, httpParams, isEnableLoader);
    }
    put(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPut(environment.baseUrl + url, data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    post(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPost(environment.baseUrl + url, data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    getForWhatsApp(url: string, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader?: boolean): Observable<Response> {
        return this.doGetWhatsApp(url, isAuthTokenRequired, httpParams, isEnableLoader);
    }
    postForWhatsApp(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true,): Observable<Response> {
        return this.doPostWhatsApp(url, data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    postWithFile(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPostWithFile(environment.baseUrl + url, data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    postWithId(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPost(environment.baseUrl + url, data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    putWithId(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPut(environment.baseUrl + url, data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    upload(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = false): Observable<any> {
        return this.doUpload(environment.baseUrl + url, data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    delete(url: string,data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = false): Observable<Response> {
        return this.doDelete(environment.baseUrl + url,data, isAuthTokenRequired, httpParams, isEnableLoader);
    }

    
  private doUpload(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = false): Observable<Response> {
    if (isEnableLoader) {
        // this.spinnerService.show();
        this.isLoading=true;
    }
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = this.securityService.setHeaders(isAuthTokenRequired, isEnableLoader,httpParams);

    return this.http.post(url, data, httpOptions)
      .pipe(
        tap((res: any) => {
            this.isLoading=false;
                    if(res.isSuccess)
                        return res;
                    else
                        this.handleError(res.errors,true,url);
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }


    private doGet(url: string, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
      if(isEnableLoader == undefined)
        isEnableLoader = false
        if (isEnableLoader) {
            // this.spinnerService.show();
            this.isLoading=true;
        }
        let httpOptions = {
            headers: new HttpHeaders(),
        };

        httpOptions = this.securityService.setHeaders(isAuthTokenRequired,false,httpParams);

        return this.http.get(url, httpOptions)
            .pipe(
                tap((res: any) => {
                        this.isLoading = false;
                      if(res.isSuccess)
                        return res;
                    else
                    {

                        this.handleError(res.errors,true,url);
                    }
                }),
                catchError(err => this.handleError(err))
            );
    }

    private doPost(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        if(isEnableLoader == undefined)
            isEnableLoader = false
        if (isEnableLoader) {
            // this.spinnerService.show();
            this.isLoading=true;
        }
        let httpOptions = {
            headers: new HttpHeaders()
        };
        if(url.indexOf("/account/signin") == -1)
            isAuthTokenRequired = true
        else
            isAuthTokenRequired = false
        httpOptions = this.securityService.setHeaders(isAuthTokenRequired,false,httpParams);

        return this.http.post(url, data, httpOptions)
            .pipe(
                tap((res: any) => {
                   
                        this.isLoading = false;
                
                    if(res.isSuccess){
                        return res;
                    }else if(res.status){
                        return res;
                    }
                    else
                        this.handleError(res.errors ,true,url);
                }),
                catchError(err => this.handleError(err))
            );
    }

    private doGetWhatsApp(url: string, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        if(isEnableLoader == undefined)
          isEnableLoader = false
          if (isEnableLoader) {
              // this.spinnerService.show();
              this.isLoading=true;
          }
          let httpOptions = {
              headers: new HttpHeaders(),
          };
  
          httpOptions = this.securityService.setWhatsAppHeaders(isAuthTokenRequired,false,httpParams);
  
          return this.http.get(url, httpOptions)
              .pipe(
                  tap((res: any) => {
                    this.isLoading = false;
                    if (res.isSuccess) {
                        return res;
                    } else if (res.status) {
                        return res;
                    }
                  }),
                  catchError(error => {
                    this.isLoading = false;
                    if (error.error instanceof ErrorEvent) {
                     }
                     else if (error.status === 401) {
                        this.securityService.RemoveWhatsAppToken();     
                     }
                    return throwError(error || 'server error');
                })
              );
      }


    private doPostWhatsApp(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        if (isEnableLoader) {
            this.isLoading=true;
        }
        let httpOptions = {
            headers: new HttpHeaders()
        };
     
        httpOptions = this.securityService.setWhatsAppHeaders(isAuthTokenRequired,false,httpParams);

        return this.http.post(url, data, httpOptions)
            .pipe(
                tap((res: any) => {
                    this.isLoading = false;
                    if (res.isSuccess) {
                        return res;
                    } else if (res.status) {
                        return res;
                    }
                   
                }),
                catchError(error => {
                    this.isLoading = false;
                    if (error.error instanceof ErrorEvent) {
                     }
                     else if (error.status === 401) {
                        this.securityService.RemoveWhatsAppToken();     
                     }
                    return throwError(error || 'server error');
                })
            );
    }


    // private doPostWithFile(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
    //     if (isEnableLoader) {
    //         // this.spinnerService.show();
    //         this.isLoading=true;
    //     }
    //     let httpOptions = {
    //         headers: new HttpHeaders()
    //     };
    //     if(url.indexOf("/account/signin") == -1)
    //         isAuthTokenRequired = true
    //     else
    //         isAuthTokenRequired = false
    //     httpOptions = this.securityService.setHeaders(isAuthTokenRequired,false,httpParams);

    //     return this.http.post(url, data, httpOptions)
    //         .pipe(
    //             tap((res: any) => {
    //                 setTimeout(() => {
    //                     this.isLoading = false;
    //                   }, 2000);
    //                 if(res.isSuccess)
    //                     return res;
    //                 else
    //                     this.handleError(res.errors[0]);
    //             }),
    //             catchError(err => this.handleError(err))
    //         );
    // }

    private doPostWithFile(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        if (isEnableLoader) {
            // this.spinnerService.show();
            this.isLoading=true;
        }
        let httpOptions = {
            headers: new HttpHeaders()
        };
        httpOptions = this.securityService.setHeadersMultipart(isAuthTokenRequired,false,httpParams);
        return  this.http.post(url, data,httpOptions)
               .pipe(
                    tap((res: any) => {
                     
                            this.isLoading = false;
                     
                        if(res.isSuccess)
                            return res;
                        else
                            this.handleError(res.errors, true,url);
                    }),
                    catchError(err => this.handleError(err))
                );
        }
    private doDelete(url: string,data, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        if (isEnableLoader) {
            // this.spinnerService.show();
            this.isLoading=true;
        }
        let httpOptions = {
            headers: new HttpHeaders(),
            body:null
            
        };

        httpOptions = this.securityService.setHeaders(isAuthTokenRequired,false,httpParams);
        httpOptions.body=data

        return this.http.delete(url,httpOptions)
            .pipe(
                tap((res: Response) => {
                  
                        this.isLoading = false;
                
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }

    private doPut(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
        if (isEnableLoader) {
            // this.spinnerService.show();
            this.isLoading=true;
        }
        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = this.securityService.setHeaders(isAuthTokenRequired,false,httpParams);

        return this.http.put(url, data, httpOptions)
            .pipe(
                tap((res: Response) => {
                       
                        this.isLoading = false;
                    
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }

     private handleError(error: any, customErrors : boolean = false,url : string = "") {
        this.isLoading = false;
       if (error.error instanceof ErrorEvent) {
        }
        else if (error.status === 401) {
            this.router.navigate(['/login']);
        }
        
        if(error?.error?.message != "Input validation failed!" && (error?.error?.errors != undefined && error?.error?.errors[0] != "No record found."))
        {
            var errorMsg = error.error.errors.join('\r\n');
            errorMsg = errorMsg +"\r\n"+ error.error.message
            this.notificationService.errorPopup(errorMsg);
        }

        if(customErrors)
            if(error[0] != 'No record found.' && error.length != 0)
                this.notificationService.errorPopup(error[0]);

        // if(error != undefined && error != 'No record found.')
        // {
        //     if(error.message != undefined)
        //     this.notificationService.errorPopup(error.message);
        //     else
        //     this.notificationService.errorPopup(error);
        // }
        return throwError(error || 'server error');
    }
}