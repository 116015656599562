import { AgGridService } from './../ag-grid/ag-grid.service';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { StorageService } from '../../services/storage.service';
import { PaymentMethod, StorageName, TouchEvent } from '../../enum/common-enum';
import { TotalSectionService } from '../total-section/total-section.service';
import { PaymentSectionModel } from '../payment-section/payment-section.model';
import { ToastrService } from 'ngx-toastr';
import { commonModalOptionsLg, MetalType, ModalPopupSize, VoucherType, VoucherTypeName } from '../../models/common.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddDiamondComponent } from 'src/app/components/Transaction/popups/add-diamond/add-diamond.component';
import { FundTransferComponent } from '../common-voucher/popup/fund-transfer/fund-transfer.component';
import { NotificationService } from '../../services/notification.service';
import { PaymentTypePopupsComponent } from '../pop-ups/payment-type-popups/payment-type-popups.component';
import { MetalSectionModel } from '../metal-section/metal-section.model';
import { TransactionService } from 'src/app/components/Transaction/transaction.service';

@Component({
  selector: 'app-payment-section',
  templateUrl: './payment-section.component.html',
  styleUrls: ['./payment-section.component.scss']
})

export class PaymentSectionComponent extends CommonService implements AfterViewInit, OnDestroy {
  tabGroupList: any[];
  isRoundOff:boolean=false;
  currencyExchangeDropdown : any;
  paymentTypeDropdown: any;
  headerText : string;
  enableAdding : boolean = true;
  jobworkqueuepaymentTabs : boolean = false;
  fundTransferAmount:boolean=false;
  transferFromColumn:boolean=false;
  depositeIntoColumn:boolean=false;
  isnonInvoice:boolean=false;
  @Input() isHideMoreButton : boolean = false;
  @Input() formModel : any ;
  @Input()formName :any;
  @Input() gridApi : any;
  @Input() rowHeight = 28;
  @Input()headerHeight=28;
  @Input() gridDynamicHeightClass : string = 'h-300px';
  @Input() voucherType:any;
  @Input() paymentSectionModel =new PaymentSectionModel();
  @Input() bold : boolean = false;
  @Input() getAllDropdownModel  : any = {};
  isTouchDevice: boolean;

  @Input() tabId: number;
  // @Input() commentPaymentSection: boolean;
  @Input() isShowHeader: boolean = true;
  isShowFooterRow: boolean = false;
  storageName: StorageName
  resetInvoiceSubscription: Subscription;
  getInvoiceSubscription: Subscription;
  onInvoiceCalculationtSubscription: Subscription;
  onAddButtonKeyPressedSubscription: Subscription;
  onAddProductsInPaymentListSubscription: Subscription;
  TotalAmount: number;
  @Output() cellValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteClick: EventEmitter<string> = new EventEmitter<string>();
  selectedTabId: string | null = null;
  fundtransferData: any;

  metalSectionModel = new MetalSectionModel();
  @Input() data: any;
  constructor(
    public totalSectionService: TotalSectionService,
    public renderer: Renderer2,
    public storageService: StorageService,
    private agGridService: AgGridService,
    public toaster: ToastrService,
    private modalService: NgbModal,
    public notificationService: NotificationService,
    private transactionService :TransactionService,
  ) {
    
    super(renderer);
    this.resetInvoiceSubscription = this.storageService.resetInvoice.subscribe((result) => {
      if (result != undefined)
        this.resetInvoiceResponse(result);
    });
    this.getInvoiceSubscription = this.storageService.getInvoice.subscribe((result) => {
      if (result != undefined)
        if(result?.response?.paymentList != undefined)
          this.getInvoiceResponse(result?.response);
    });
    this.onInvoiceCalculationtSubscription = this.storageService.onInvoiceCalculation.subscribe((result) => {
      if (result != undefined)
        this.onInvoiceCalculationResponse(result);
    });
    this.onAddButtonKeyPressedSubscription = this.storageService.onAddButtonKeyPressed.subscribe((event) => {
      if (event != undefined)
        this.focusFirstRowFirstCell(event);
    });

    // this.onDeleteRowClickedSubscription = this.storageService.onDeleteRowClicked.subscribe((result) => {
    //   if (result != undefined)
    //     this.onDeleteRowClickedResponse(result);
    // });

    this.onAddProductsInPaymentListSubscription = this.storageService.onAddProductsInPaymentList.subscribe((result) => {
      if (result != undefined)
        this.onAddProductsInPaymentResponse(result);
    });

  }
  ngOnInit() {
    this.initialCalls();
    this.detectTouchDevice()
  }

  detectTouchDevice() {
    this.isTouchDevice = TouchEvent.TouchDevice in window || navigator.maxTouchPoints > 0;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.detectTouchDevice();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: Event) {
    this.detectTouchDevice();
  }

  initialCalls() {
    this.fillDropdownData();
    this.tabGroupList = [
      { tabId: "PaymentSection", dataModel: this.paymentSectionModel.agGridDataModel, label: 'PaymentSection', tabIndex: 0 }
    ];
    // switch(this.paymentSectionModel.agGridDataModel.storageName)
    // {
    //   case StorageName.TRANSACTION_PURCHASEINVOICE_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_PURCHASERETURN_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_PAYMENTVOUCHER_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_MATERIALOUT_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_MATERIAL_ISSUE_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_EXPENSES_PAYMENT_LIST_GRID:
    //   case StorageName.TRANSACTION_PURCHASEORDER_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_CREDITNOTE_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_JOBWORKINVOICE_PAYMENT_GRID:
    //     case StorageName.MANUFACTURER_JOBWORK_QUEUE_ITEM_GRID:
    //     this.headerText ="Payment"
    //     break;
    //   case StorageName.TRANSACTION_SALEINVOICE_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_SALESQUOTATION_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_SALESRETURN_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_SALESORDER_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_RECEIPTVOUCHER_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_MATERIALIN_PAYMENT_GRID:
    //   case StorageName.TRANSACTION_DEBITNOTE_PAYMENT_GRID:
    //     case StorageName.TRANSACTION_REPAIR_INVOICE_PAYMENT_LIST_GRID:
    //       case StorageName.ORDERS_REPAIRORDER_PAYMENT_GRID:
    //         case StorageName.MANUFACTURER_JOBWORK_ORDER_PAYMENT_LIST_GRID:
    //     this.headerText ="Receipt"
    //     break;

    //     case StorageName.TRANSACTION_INVESTMENTFUND_INVESTMENTFUND_PAYMENT_GRID:
    //     this.headerText =""
    //     break;
    // }
  }

  // #for payments type tab
  fillDropdownData() {
    this.currencyExchangeDropdown = this.storageService.retrieve(StorageName.CURRENCY_EXCHANGE);
    this.getAllDropdownModel.depositIntoDropdown = this.storageService.retrieve(StorageName.DEPOSIT_INTO_DROPDOWN);
    this.getAllDropdownModel.metalDropdown = this.storageService.retrieve(StorageName.METAL_DROPDOWN);
    this.getAllDropdownModel.productDropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
    this.getAllDropdownModel.creditCardDropdown = this.storageService.retrieve(StorageName.CREDITCARD_DROPDOWN);
    this.getAllDropdownModel.diamondCategoryDropdown = this.storageService.retrieve(StorageName.DIAMOND_CATEGORY_DROPDOWN);

    const paymentTypeDropdownList = this.storageService.retrieve(StorageName.PAYMENTTYPE_DROPDOWN);
    const paymentTypeOptionsList1 = ["Cash","Bank","Cheque","UPI","Card","MetalExchange", "OldJewellery"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
    const paymentTypeOptionsList2 = ["MetalExchange","OldJewellery"]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
    const paymentTypeOptionsList3 = ["Cash","Bank","Cheque","UPI","Card","MetalExchange" ]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);
    const paymentTypeOptionsList4 = ["Cash","Bank","Cheque","UPI","Card" ]?.map(name => paymentTypeDropdownList.find(item => item.name === name)).filter(Boolean);

    switch (this.paymentSectionModel.agGridDataModel.storageName) {
      case StorageName.TRANSACTION_SALEINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEQUOTATION_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESQUOTATION_PAYMENT_GRID:
      case StorageName.TRANSACTION_RECEIPTVOUCHER_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESORDER_PAYMENT_GRID:
      case StorageName.ORDERS_REPAIRORDER_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESRETURN_PAYMENT_GRID:
      case StorageName.TRANSACTION_PAYMENTVOUCHER_PAYMENT_GRID:
      case StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEORDER_PAYMENT_GRID:
      case StorageName.MANUFACTURER_JOBWORK_ORDER_PAYMENT_LIST_GRID:
      case StorageName.TRANSACTION_JOBWORKINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_DELIVERYNOTE_PAYMENT_GRID:
      case StorageName.TRANSACTION_REPAIRINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_JOBWORKORDER_PAYMENT_GRID:
      case "Receipt Voucher":
      case "Payment Voucher":
        this.paymentTypeDropdown = paymentTypeOptionsList1;
        break;
      case StorageName.TRANSACTION_PURCHASERETURN_PAYMENT_GRID:
        this.paymentTypeDropdown = paymentTypeOptionsList3;
        break;
      case StorageName.TRANSACTION_MATERIALIN_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIALOUT_PAYMENT_GRID:
      case StorageName.MANUFACTURER_JOBWORK_QUEUE_PAYMENT_LIST_GRID:
      case StorageName.TRANSACTION_MATERIAL_ISSUE_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID:
        this.paymentTypeDropdown = paymentTypeOptionsList2;
        break;
      case StorageName.TRANSACTION_CREDITNOTE_PAYMENT_GRID:
      case StorageName.TRANSACTION_DEBITNOTE_PAYMENT_GRID:
      case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_WITHDRAW_GRID:
      case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_RECEIPT_GRID:
      case StorageName.TRANSACTION_EXPENSES_PAYMENT_GRID:
      case StorageName.TRANSACTION_EXPENSES_PAYMENT_LIST_GRID:
      case StorageName.TRANSACTION_NEW_LOAN_PAYMENT_GRID:
        this.paymentTypeDropdown = paymentTypeOptionsList4;
        break;
      default:
        this.paymentTypeDropdown = paymentTypeDropdownList; // Default assignment
    }
  }
  // #for payments type tab

  ngAfterViewInit(): void {
    this.setStorageNames();
  }
  onDeleteIconClick(params){
    this.onDeleteClick.emit(params)
    this.onDeleteRowClickedResponse(params?.detail?.data);
  }

  onDeleteRowClickedResponse1(event) {
    this.formModel.paymentList = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.calculateTotalAmount(this.gridApi);
    this.gridApi?.api?.forEachNode((node) => {
      this.callCalulationApi(node?.data, node, node?.column?.colId); 
  });
  }

  onDeleteRowClickedResponse(event) {
    this.formModel.paymentList = this.gridApi?.api?.getModel()?.rowsToDisplay?.map((a) => a.data) ?? [];
    this.calculateTotalAmount(this.gridApi);
    
    if (this.formModel.paymentList.length === 0) {
      this.clearBalanceDetails();
    } else {
      this.gridApi?.api?.forEachNode((node) => {
        this.callCalulationApi(node?.data, node, node?.column?.colId);
      });
    }
  }

  clearBalanceDetails() {
    this.formModel.invoiceModel.amountPaid = 0;
    this.formModel.invoiceModel.metalAmount = 0;
  
    this.formModel.invoiceModel.balanceGrossGold = 0;
    this.formModel.invoiceModel.balanceGrossSilver = 0;
    this.formModel.invoiceModel.balancePureGold = 0;
    this.formModel.invoiceModel.balancePureSilver = 0;
  
    this.formModel.balanceDescription = "(G: 0/0) (S: 0/0)";
  }
  
  

  // case VoucherTypes.JobworkInvoice:
  //   case VoucherTypes.PurchaseInvoice:
  //   case VoucherTypes.OldJewelryScrapInvoice:
  //   case VoucherTypes.PurchaseOrder:
  //   case VoucherTypes.JobworkOrder:
  //   case VoucherTypes.CreditNote:

  setStorageNames() {
    switch (this.formModel.voucherType) {
      // case VoucherTypeName.SalesInvoice:
      case VoucherTypeName.SalesQuotation:
        case VoucherTypeName.DeliveryNote:
      // case VoucherTypeName.SalesOrder:
      case VoucherTypeName.DebitNote:
      case VoucherTypeName.PurchaseReturn:
      case VoucherTypeName.ReceiptVoucher:
      case VoucherTypeName.ConsignmentOut:
      case VoucherTypeName.MaterialIn:
      case VoucherTypeName.RepairInvoice:
      case VoucherTypeName.RepairOrder:
          this.formModel.paymentVoucherType = "Receipt";
        break;
      case VoucherTypeName.PurchaseInvoice:
      case VoucherTypeName.PurchaseQuotation:
      case VoucherTypeName.OldJewelryScrapInvoice:
      case VoucherTypeName.PurchaseOrder:
      case VoucherTypeName.JobworkOrder:
      case VoucherTypeName.JobworkInvoice:
      case VoucherTypeName.CreditNote:
      case VoucherTypeName.SalesReturn:
      case VoucherTypeName.PaymentVoucher:
      case VoucherTypeName.MaterialOut:
      case VoucherTypeName.ConsignmentIn:
        this.formModel.paymentVoucherType = "Payment";
        break;
      case VoucherTypeName.JobworkQueue:
        this.formModel.paymentVoucherType = VoucherTypeName.JobworkQueue;
        break;
      case VoucherTypeName.MaterialIssue:
        this.formModel.paymentVoucherType = VoucherTypeName.MaterialIssue;
        break;
      case VoucherTypeName.MaterialReceive:
        this.formModel.paymentVoucherType = VoucherTypeName.MaterialReceive;
        break;
      case VoucherTypeName.ExpenseInvoice:
        this.formModel.paymentVoucherType = VoucherTypeName.ExpenseInvoice;
        break;
      case VoucherTypeName.InvestmentFund:
        this.formModel.paymentVoucherType = VoucherTypeName.InvestmentFund;
        break;
      case VoucherTypeName.FundWithdraw:
        this.formModel.paymentVoucherType = VoucherTypeName.FundWithdraw;
        break;
      case VoucherTypeName.FundTransfer:
        this.formModel.paymentVoucherType = VoucherTypeName.FundTransfer;
        break;
        case VoucherTypeName.SalesInvoice:
          this.formModel.paymentVoucherType = VoucherTypeName.SalesInvoice;
          break;
          case VoucherTypeName.SalesOrder:
            this.formModel.paymentVoucherType = VoucherTypeName.SalesOrder;
            break;
            case VoucherTypeName.Loan:
              this.formModel.paymentVoucherType = VoucherTypeName.Loan;
              break;
    }
    setTimeout(() => {
      this.setColumnsVisible();
    }, 100);
  }

  focusFirstRowFirstCell(event) {
    if (event.key == "Tab" || event.key == "Enter")
      this.agGridService.setFocusToLastRow(this.gridApi);
  }

  getInvoiceResponse(result) {
    this.formModel.paymentList = result?.paymentList;
    this.gridApi.api.setRowData(this.formModel?.paymentList);
  }

  onInvoiceCalculationResponse(result) {
    this.formModel.balanceDescription = "(G: " + result.invoiceModel.balancePureGold + ") "
    "(S: " + result.invoiceModel.balancePureSilver + ") ";
    this.formModel.paymentList = result.paymentList;
    this.gridApi.api.setRowData(this.formModel.paymentList);
  }

  getMaxRowIndexColumn(): number {
    let maxRowIndex = -1;

    // Iterate through the displayed rows
    this.gridApi.api.forEachNodeAfterFilterAndSort((node, index) => {
      // Get the value of the 'rowIndex' column (replace 'rowIndex' with your actual column field)
      const rowIndexValue = node.data.rowIndex == undefined ? 0 : node.data.rowIndex; // Replace 'rowIndex' with your actual column field

      // Check if the value is a number and update the maxRowIndex
      if (typeof rowIndexValue === 'number' && rowIndexValue > maxRowIndex) {
        maxRowIndex = rowIndexValue;
      }
    });

    return maxRowIndex;
  }

  getNodeByRowIndex(rowIndexToFind: number): any {
    let foundNode = null;

    // Iterate through all nodes
    this.gridApi.api.forEachNode(node => {
      // Get the value of the 'rowIndex' column (replace 'rowIndex' with your actual column field)
      const rowIndexValue = node.data.rowIndex; // Replace 'rowIndex' with your actual column field

      // Check if the value matches the specified rowIndexToFind
      if (rowIndexValue === rowIndexToFind) {
        foundNode = node;
      }
    });

    return foundNode;
  }

  onAddProductsInPaymentResponse(records) {
    var rowIndex = records.rowIndex;
    if (rowIndex == undefined)
      rowIndex = this.getMaxRowIndexColumn() + 1;
    const result = JSON.parse(records.response.detailedJson);
    var paymentMethod = (records.voucherTypeId == VoucherType.OldJewelryScrapInvoice) ? PaymentMethod.MetalExchange : PaymentMethod.MetalExchange;
    this.assignValuesToPayment(records.response, result.goldItem, rowIndex, records.addNewRow, MetalType.Gold, paymentMethod);
    this.assignValuesToPayment(records.response, result.silverItem, rowIndex, records.addNewRow, MetalType.Silver, paymentMethod);
    this.assignValuesToPayment(records.response, result.diamondItem, rowIndex, records.addNewRow, MetalType.Diamond, paymentMethod);
    this.assignValuesToPayment(records.response, result.stoneGemsItem, rowIndex, records.addNewRow, MetalType.StoneGEMS, paymentMethod);
    this.assignValuesToPayment(records.response, result.imitationItem, rowIndex, records.addNewRow, MetalType.Imitation, paymentMethod);
    this.formModel.paymentList = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
  }

  assignValuesToPayment(itemmodel, item, rowIndex, addNewRow, metalId, paymentMethod) {
    if (item != undefined && item != null)
      item.forEach(element => {
        var paymentItem: any = {};
        paymentItem.purityPer = this.fixAmountDecimal(element.purityPer);
        paymentItem.purityWt = this.fixWeightDecimal(element.purityWt);
        paymentItem.grossWt = this.fixWeightDecimal(element.grossWt);
        paymentItem.quantity = element.quantity;
        paymentItem.description = element.description;
        paymentItem.paymentMethodId = paymentMethod.toString();
        paymentItem.metalId = metalId.toString();
        paymentItem.productDetailId = element.productDetailId;
        paymentItem.scrapInvoiceItemId = itemmodel.againstVoucherNo;
        if (metalId == MetalType.Diamond || metalId == MetalType.StoneGEMS)
          paymentItem.diamondStoneAgainstId = element.id;
        paymentItem.description = element.description;
        paymentItem.rowIndex = rowIndex;
        if (Number(paymentItem.grossWt) > 0) {
          if (addNewRow == true)
            this.agGridService.addNewRowWithData(this.gridApi, paymentItem);
          else
            this.updatePaymentRow(paymentItem, rowIndex);
        }
      });
  }

  updatePaymentRow(dataToUpdate, rowIndex) {
    const node = this.getNodeByRowIndex(rowIndex);
    if (node == null)
      this.agGridService.addNewRowWithData(this.gridApi, dataToUpdate);
    else {
      node.data.purityPer = dataToUpdate.purityPer;
      node.data.purityWt = dataToUpdate.purityWt;
      node.data.grossWt = dataToUpdate.grossWt;
      node.data.rowIndex = dataToUpdate.rowIndex;
      node.data.paymentMethodId = dataToUpdate.paymentMethodId
      node.data.metalId = dataToUpdate.metalId;
      node.data.description = dataToUpdate.description;
      this.gridApi.api.refreshCells();
    }
  }

  onCellValueChange(params) {
    
    this.cellValueChanged.emit(params)
    switch (params.column.colId) {
      case "productDetailId":
        this.assignGridDataProductWise(params);
      case "grossWt":
      case "purityPer":
      case "requestedPurityPer":
      case "rate":
      // case "amount":
      //   this.calculateTotalAmount(this.gridApi);
      //   break;
      case "purityWt":
      case "paymentMethodId":
        case "amount":
        this.callCalulationApi(params.data, params.node, params.column.colId);
        this.hideshoweditIcon(params?.data?.paymentMethodId)
        break;
      case "metalId":
        this.metalValueChange(params)
        break;

    }
  }


  metalValueChange(params) {
    if (params?.data?.metalId !== '4' && params?.data?.metalId !== '5') {
      params.data.itemCode = undefined;
      params?.api?.refreshCells();
    }
  }


  assignGridDataProductWise(params) {
    var productDropdown = this.
      storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
    var particularProductData = productDropdown?.find(a => a.id == parseInt(params.node.data.productDetailId));
    params.data.purityPer = params.data.purityPer == undefined || Number(params.data.purityPer ?? 0) == 0 ? '1.0' : params.data.purityPer;

    if (particularProductData != undefined) {
      const obj = JSON.parse(particularProductData?.extra2);

      if (params.colDef.headerComponentParams.storageName.indexOf('PAYMENT') != -1)
        params.data.purityPer = obj[0].purityPurchase;
      else if (params.colDef.headerComponentParams.storageName.indexOf('RECEIPT') != -1)
        params.data.purityPer = obj[0].puritySale;
    }
    params.data.purityPer = Number(params.data.purityPer) == 0 ? "1.0" : this.fixWeightDecimal(params.data.purityPer);
  }

  callCalulationApi(data, node, colId) {
    this.formModel.paymentList = this.gridApi.api.getModel().rowsToDisplay.map(a => a.data) ?? [];
    this.calculationForPaymentDetails(data, node, colId);
  }

  calculationForPaymentDetails(model, node, colId) {

    if (colId == "purityPer" && model.metalId == MetalType.Diamond) {
      model.grossWt = Number(model.purityPer ?? 0) / 5;
      model.purityWt = model.grossWt;
      //  model.purityPer = "1";
    }
    if (colId == "rate")
      model.amount = ((model.rate ?? 0) * (model.purityWt ?? 0));
    else
      model.amount = model.amount ?? 0;

    const currentPaymentMethod = Number(model.paymentMethodId);
    if (
      (currentPaymentMethod === PaymentMethod.Cash ||
        currentPaymentMethod === PaymentMethod.Bank ||
        currentPaymentMethod === PaymentMethod.Card ||
        currentPaymentMethod === PaymentMethod.Cheque ||
        currentPaymentMethod === PaymentMethod.UPI)
    ) {
      // Get the ag-Grid row nodes
      const rowNodes = this.gridApi.api.getModel().rowsToDisplay;

      // Calculate the sum excluding the specified row
      const totalAmount: number = rowNodes.reduce((sum, rowNode, index) => {
        if (index !== node.rowIndex) {
          const cellValue = rowNode.data['amount'];
          if (cellValue !== undefined) {
            return Number(sum) + Number(cellValue);
          }
        }
        return sum;
      }, 0);
      // const totalAmount: number = this.formModel.paymentList.reduce((sum, payment) => sum + payment.amount, 0);
      if ((model.amount === 0 || model.amount === null || model.amount == undefined || Number.isNaN(Number(model.amount))))
        model.amount = Number(this.formModel.invoiceModel.grandTotal) - Number(this.formModel.invoiceModel.advancePayment)  + Number(this.formModel.invoiceModel.metalAmount) - Number(totalAmount ?? 0);
      model.grossWt = undefined;
      model.purityPer = undefined;
      model.purityWt = undefined;
      model.grossWt = undefined;
      model.metalId = 0;
      model.productDetailId = undefined;
      model.rate = undefined;
    }
    else {
      if (Number(model.paymentMethodId) === PaymentMethod.A2M) {
        if (Number(model.amount) == 0)
          model.amount = this.formModel.amountBalance;
        model.grossWt = Number(model.rate ?? 0) == 0 ? 0 : (Number(model.amount ?? 0) / Number(model.rate ?? 0));
      }
      if (Number(model.paymentMethodId) === PaymentMethod.OldJewellery) {
        if (Number(model.amount) == 0)
          model.amount = this.formModel.amountBalance;
        model.rate = Number(model.grossWt ?? 0) == 0 ? 0 : (Number(model.amount ?? 0) / Number(model.purityWt ?? 0));
      }
      if (model.purityPer) {
        model.purityPer = model.purityPer ?? '1.0';
        if (model.metalId != MetalType.Diamond) {
          model.purityWt = (Number(model.grossWt) * Number(model.purityPer || 1));
        }

        if (Number(model.paymentMethodId) !== PaymentMethod.A2M)
          model.amount = model.paymentMethodId === PaymentMethod.MetalExchange ? 0 : Number(model.rate) * Number(model.purityWt);
      }
    }
    this.formModel.paymentList = this.gridApi.api.getModel().rowsToDisplay.map(a => a.data) ?? [];
    this.formModel.invoiceModel.amountPaid = this.formModel.paymentList
      .filter(a => Number(a.paymentMethodId) !== PaymentMethod.M2A && Number(a.paymentMethodId) !== PaymentMethod.MetalExchange && Number(a.paymentMethodId) !== PaymentMethod.A2M)
      .reduce((acc, curr) => acc + Number(curr.amount || 0), 0);

    this.formModel.invoiceModel.metalAmount = this.formModel.paymentList
      .filter(a => Number(a.paymentMethodId) === PaymentMethod.M2A)
      .reduce((acc, curr) => acc + Number(curr.amount || 0), 0);

    this.formModel.invoiceModel.balanceAmount = this.fixAmountDecimal( Number(this.formModel.invoiceModel.grandTotal ?? 0) - Number(this.formModel.invoiceModel.advancePayment ?? 0) + Number(this.formModel.invoiceModel.metalAmount ?? 0) - Number(this.formModel.invoiceModel.amountPaid ?? 0));

    var paidGrossGold = this.formModel.paymentList
      .filter(a => Number(a.metalId) === MetalType.Gold)
      .reduce((acc, curr) => acc + Number(curr.grossWt || 0), 0);

    var paidGrossSilver = this.formModel.paymentList
      .filter(a => Number(a.metalId) === MetalType.Silver)
      .reduce((acc, curr) => acc + Number(curr.grossWt || 0), 0);

    var paidPureGold = this.formModel.paymentList
      .filter(a => Number(a.metalId) === MetalType.Gold)
      .reduce((acc, curr) => acc + Number(curr.purityWt || 0), 0);

    var paidPureSilver = this.formModel.paymentList
      .filter(a => Number(a.metalId) === MetalType.Silver)
      .reduce((acc, curr) => acc + Number(curr.purityWt || 0), 0);

    this.formModel.invoiceModel.amountPaid = this.fixAmountDecimal(this.formModel.invoiceModel.amountPaid ?? 0);
    this.formModel.invoiceModel.balanceGrossGold = this.fixWeightDecimal(Number(this.formModel.invoiceModel.totalGrossGold ?? 0) - paidGrossGold);
    this.formModel.invoiceModel.balanceGrossSilver = this.fixWeightDecimal(Number(this.formModel.invoiceModel.totalGrossSilver ?? 0) - paidGrossSilver);
    this.formModel.invoiceModel.balancePureGold = this.fixWeightDecimal(Number(this.formModel.invoiceModel.totalPureGold ?? 0) - paidPureGold);
    this.formModel.invoiceModel.balancePureSilver = this.fixWeightDecimal(Number(this.formModel.invoiceModel.totalPureSilver ?? 0) - paidPureSilver);
    this.formModel.balanceDescription = "(G: " + this.formModel.invoiceModel.balanceGrossGold + "/" + this.formModel.invoiceModel.balancePureGold + ") " +
      "(S: " + this.formModel.invoiceModel.balanceGrossSilver + "/" + this.formModel.invoiceModel.balancePureSilver + ") "

    model.purityWt = this.fixWeightDecimal(model.purityWt ?? 0);
    model.grossWt = this.fixWeightDecimal(model.grossWt);
    model.purityPer = this.fixWeightDecimal(model.purityPer ?? 0);
    model.amount = (model.amount == "NaN") ? 0 : this.fixAmountDecimal(model.amount);

    this.gridApi?.api?.refreshCells();
    this.storageService.onPaymentListChanged.next(this.formModel.paymentList);
  }

  onGridReady(gridApi) {
    this.gridApi = gridApi;
    this.setPageWiseColumns();
  }

  setPageWiseColumns() {
    switch (this.paymentSectionModel.agGridDataModel.storageName) {
      case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
      case StorageName.FORMNAME_TRANSACTION_PURCHASEQUOTATION:
      case StorageName.TRANSACTION_SALEINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESQUOTATION_PAYMENT_GRID:
      case StorageName.TRANSACTION_DELIVERYNOTE_PAYMENT_GRID:
      case StorageName.TRANSACTION_RECEIPTVOUCHER_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESORDER_PAYMENT_GRID:
      case StorageName.ORDERS_REPAIRORDER_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASERETURN_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIALOUT_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID:
      case StorageName.TRANSACTION_DEBITNOTE_PAYMENT_GRID:
      case StorageName.TRANSACTION_REPAIRINVOICE_PAYMENT_GRID:
      case StorageName.MANUFACTURER_JOBWORK_ORDER_PAYMENT_LIST_GRID:
      case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_RECEIPT_GRID:
        this.headerText = "Receipt"
        break;
      case StorageName.TRANSACTION_PURCHASEINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEQUOTATION_PAYMENT_GRID:
      case StorageName.TRANSACTION_EXPENSES_PAYMENT_GRID:
      case StorageName.TRANSACTION_SALESRETURN_PAYMENT_GRID:
      case StorageName.TRANSACTION_PAYMENTVOUCHER_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIALIN_PAYMENT_GRID:
      case StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_PURCHASEORDER_PAYMENT_GRID:
      case StorageName.TRANSACTION_MATERIAL_ISSUE_PAYMENT_GRID:
      case StorageName.TRANSACTION_JOBWORKINVOICE_PAYMENT_GRID:
      case StorageName.TRANSACTION_CREDITNOTE_PAYMENT_GRID:
      case StorageName.TRANSACTION_EXPENSES_PAYMENT_LIST_GRID:
      case StorageName.INVESTMENT_FUND_INSTALLMENT_ENTRY_WITHDRAW_GRID:
        this.headerText = "Payment";
        break;
      case StorageName.TRANSACTION_NEW_LOAN_PAYMENT_GRID:
        this.headerText = "Transaction";
        break;
      case StorageName.MANUFACTURER_JOBWORK_QUEUE_PAYMENT_LIST_GRID:
        this.headerText = "";
        break;
    }
  }

  resetInvoiceResponse(formName) {
    if (formName != undefined) {
      this.gridApi?.api?.setRowData([]);
      this.formModel = {};
      this.formModel.invoiceModel = {};
    }
  }

  openAddDiamondPopup(modalTitle: string) {
    var data = {adddiaomodForm: this.paymentSectionModel.enableAddStoneDiamond,};
    this.notificationService.openModalPopup(AddDiamondComponent, data, modalTitle,ModalPopupSize.XL, "", false, true)
      .then((result) => {
        if (result.isSuccess) {
        
        result.response.forEach((item) => {
        item.paymentMethodId = result.paymentType === "Metal Exchange" ? PaymentMethod.MetalExchange : PaymentMethod.OldJewellery;
        item.metalId = MetalType.Diamond;
        item.purityPer = item.purityWt;
        item.purityWt = item.weight;
        });
        this.agGridService.addNewRowsWithData(this.gridApi, result.response);
        this.formModel.paymentList = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
      }
   });
  }

  setColumnsVisible() {
    var columnnames: any = [];
    switch (this.formModel.paymentVoucherType) {
      case "Payment":
        columnnames = ["depositeIntoId"];
        this.transferFromColumn=true;
        break;
      case VoucherTypeName.OldJewelryScrapInvoice:
        columnnames = ["depositeIntoId"];
        this.depositeIntoColumn =false;
        this.transferFromColumn=true;
      break;
      case "Receipt":
        columnnames = ["transferFromId"]
        this.depositeIntoColumn =true;
        break;
      case VoucherTypeName.SalesInvoice:
        columnnames = ["transferFromId"];
        this.depositeIntoColumn =true;
        this.fundTransferAmount = true;
        break;
      case VoucherTypeName.SalesOrder:
        columnnames = ["transferFromId"];
        this.depositeIntoColumn =true;
        this.fundTransferAmount = true;
        break;
      case VoucherTypeName.JobworkQueue:
        columnnames = ["transferFromId", "depositeIntoId", "paymentMethodId", "transactionNo", "chequeDate", "rate", "amount",]
       this.jobworkqueuepaymentTabs =true;
        break;
      case VoucherTypeName.MaterialIssue:
        columnnames = ["transferFromId", "depositeIntoId", "paymentMethodId", "transactionNo", "chequeDate", "amount", "action",];
        this.enableAdding = false;
        break;
      case VoucherTypeName.ExpenseInvoice:
        columnnames = ['depositeIntoId', 'quantity', 'productDetailId', 'grossWt', 'purityPer', 'purityWt', 'rate', 'itemCode', 'metalId', 'balance'];
        this.enableAdding = false;
        this.transferFromColumn=true;
        break;
      case VoucherTypeName.MaterialReceive:
        columnnames = ["transferFromId", "depositeIntoId", "paymentMethodId", "transactionNo", "chequeDate", "amount", "action",];
        this.enableAdding = false;
        break;
      case VoucherTypeName.FundWithdraw:
        columnnames = ["depositeIntoId", "productDetailId", "metalId", "grossWt", "quantity", "purityPer", "purityWt", "rate", "itemCode"];
        this.enableAdding = false;
        this.transferFromColumn=true;
        break;
      case VoucherTypeName.FundTransfer:
        columnnames = ["transferFromId", "productDetailId", "metalId", "grossWt", "quantity", "purityPer", "purityWt", "rate", "itemCode"];
        this.enableAdding = false;
        this.depositeIntoColumn =true
        break;
      case VoucherTypeName.InvestmentFund:
        columnnames = ["transferFromId", "productDetailId", "metalId", "grossWt", "quantity", "purityPer", "purityWt", "rate", "itemCode"];
        this.enableAdding = false;
        this.isShowFooterRow = true;
        this.depositeIntoColumn =true
        this.isnonInvoice=true;
        if (this.isHideMoreButton) {
          const columnApi = this.gridApi?.columnApi;
          if (columnApi) {
            const allColumns = columnApi.getAllColumns();
            const actionColumn = allColumns.find(col => col.getColId() === 'action');
            if (actionColumn) {
              actionColumn.colDef.headerComponentParams = {
                ...actionColumn.colDef.headerComponentParams,
                moreButton: false
              };
            }
          }
        }
        break;
    }
    if (this.gridApi != undefined) {
      columnnames.forEach(element => {
        this.gridApi.columnApi.setColumnVisible(element, false);
      });
      this.gridApi.api.refreshHeader();
    }
  }

  getAllData(): any {
    var paymentList = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    return paymentList;
  }

  AddDatainPaymentList(response) {
    this.agGridService.addNewRowsWithData(this.gridApi, response);
    this.formModel.paymentList = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data) || [];
    const columnApi = this.gridApi?.columnApi;
    if (columnApi) {
      const allColumns = columnApi.getAllColumns();
      const actionColumn = allColumns.find(col => col.getColId() === 'action');
      if (actionColumn) {
        actionColumn.colDef.headerComponentParams = {
          ...actionColumn.colDef.headerComponentParams,
          moreButton: false
        };
        columnApi.applyColumnState({
          state: columnApi.getColumnState(),
          applyOrder: true
        });
      }
    }
    this.gridApi.api.refreshHeader();
    this.calculateTotalAmount(this.gridApi);
  }

  calculateTotalAmount(event?: any, fromReceipt?: boolean): void {
    if (fromReceipt) {
      this.TotalAmount = (event?.reduce((sum, item) => { const amount = parseFloat(item.amount || '0.000'); return !isNaN(amount) ? sum + amount : sum; }, 0)) || 0;
    } else {
      this.TotalAmount = (event?.api?.getRenderedNodes()?.reduce((sum, node) => { const amount = parseFloat(node?.data?.amount || '0.000'); return !isNaN(amount) ? sum + amount : sum; }, 0)) || 0;
    }
  }

  openPaymentTypePopup(paymetType ,paymentMethodId ,apiRequestModel?:any){
    this.selectedTabId = paymentMethodId;
    var data = {paymetType: paymetType ,getAllDropdownModel:this.getAllDropdownModel ,paymentMethodId:paymentMethodId ,apiRequestModel:apiRequestModel ,depositeIntoColumn:this.depositeIntoColumn ,transferFromColumn:this.transferFromColumn ,amountBalance: this.isnonInvoice ? null : (this.formModel?.invoiceModel?.balanceAmount < 0 ? null : this.formModel?.invoiceModel?.balanceAmount)};
    this.notificationService.openModalPopup(PaymentTypePopupsComponent, data,'',ModalPopupSize.MD , "", false, true,)
      .then((result) => {
        if (result.isSuccess) {
          result.response[0].paymentMethodId = Number(result.response[0].paymentMethodId);
          this.agGridService?.addUpdateRowsWithData(this.gridApi, result?.response);
          this.formModel.paymentList = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
          this.gridApi?.api?.forEachNode((node) => {
            this.callCalulationApi(node?.data, node, node?.column?.colId); 
        });
      }
   });
  }
  
  onEditIconClick(params?:any) {
    const data= params?.detail?.data?.data
    const paymentType = this.storageService.retrieve(StorageName.PAYMENTTYPE_DROPDOWN)?.find(x => x?.id == data?.paymentMethodId).name;
     this.openPaymentTypePopup(paymentType ,String(data?.paymentMethodId) ,data)
  }

  hideshoweditIcon(paymentMethodId) {
    if(paymentMethodId){
      const actionColumn = this.gridApi?.columnApi?.getAllColumns().find(col => col.getColId() === 'action');
      if (actionColumn) {
        actionColumn.colDef = {
          ...actionColumn.colDef,
          cellRendererParams: { ...actionColumn.colDef.cellRendererParams, editIconChecker: (p: any) => ['10', '20', '30', '40', '50', '80', '90'].includes(String(p.data?.paymentMethodId)) },
          cellClass: p => ['10', '20', '30', '40', '50', '80', '90'].includes(String(p.data?.paymentMethodId)) ? "custom-column-group-cell" : "custom-column-group-cell editIconHide"
        };
        this.gridApi.columnApi.applyColumnState({ state: this.gridApi.columnApi.getColumnState(), applyOrder: true });
        this.gridApi.api.refreshCells({ force: true });
      }
    }
  }

  clearBarcode(){
    this.metalSectionModel.barcodeNo='';
  }

  getProductDataByBarcodeNo(barcodeNo) {
    if (barcodeNo) {
      this.transactionService.getPrductByBarcodeNo(this.metalSectionModel.barcodeNo, undefined).subscribe({
        next: (res) => {
          
        }, error: (err) => { }
      })
    }
  }
  
  ngOnDestroy() {
    this.resetInvoiceSubscription?.unsubscribe();
    this.getInvoiceSubscription?.unsubscribe();
    this.onAddButtonKeyPressedSubscription?.unsubscribe();
    this.onInvoiceCalculationtSubscription?.unsubscribe();
    this.onAddProductsInPaymentListSubscription?.unsubscribe();
  }
}