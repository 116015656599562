import { AgColDefsI, AgGridI } from "src/app/shared/common-components/ag-grid/ag-grid.model";
import { DateCellRendererComponent } from "src/app/shared/common-components/ag-grid/date-cell-renderer/date-cell-renderer.component";
import { TextRendererComponent } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { AmountColWidth, BarcodeColWidth, NameColWidth, StorageName, VoucherColWidth, WeightColWidth } from "src/app/shared/enum/common-enum";
import { NumericCellRendererComponent } from "src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { API_URL_GET_ALL_FINANCIAL_STATEMENT_INPUT_OUTPUT_TAX_REPORT, API_URL_GET_ALL_TRANSACTION_SALE_ANALYSIS } from "src/app/shared/url-helper/url-helper";
import { CustomHeaderComponent } from "src/app/shared/common-components/ag-grid/custom-header/custom-header.component";

const CashpartyColumn: AgColDefsI = {
    headerName: "Cash Party",
    field: "customerName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0",
 
  };
  const NameColumn: AgColDefsI = {
    headerName: "Name",
    field: "accountLedgerName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  }
  const SalesPersonColumn: AgColDefsI = {
    headerName: "Sales Person",
    field: "salePerson",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: TextRendererComponent,
    // headerComponentParams:{
    //   filterValueColumnName: "salePersonId",
    //   filterIdColumnName: "salePerson",
    //   filterParams: StorageName.SALEPERSON_DROPDOWN,
    //   floatingMultiselectDropDown: true,
    //   enableSorting: false,
    // },
  }
  const InvoiceNoColumn: AgColDefsI = {
    headerName: "Invoice No.",
    field: "invoiceNo",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: TextRendererComponent,
  }
  const SalesModeColumn: AgColDefsI = {
    headerName: "SalesMode",
    field: "saleMode",
    minWidth: 80,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: TextRendererComponent,
  }
  const BranchColumn: AgColDefsI = {
    headerName: "Branch",
    field: "branchName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable:true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  }
  const DateColumn: AgColDefsI = {
    headerName: "Date",
    field: "invoiceDate",
    minWidth: 80,
    resizable:true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    cellRenderer:DateCellRendererComponent
  }
  const ContactColumn: AgColDefsI = {
    headerName: "Contact",
    field: "mobile",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable:true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  }
  const AddressColumn: AgColDefsI = {
    headerName: "Address",
    field: "address",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  }
  const BarcodeColumn: AgColDefsI = {
    headerName: "Barcode",
    field: "barcodeNo",
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: TextRendererComponent,
  }
  const AmountColumn: AgColDefsI = {
    headerName: "Amount",
    field: "amount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }
  const GrandTotalColumn: AgColDefsI = {
    headerName: "Grand Total",
    field: "grandTotal",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
  }
  const BalanceAmtColumn: AgColDefsI = {
    headerName: "Balance Amount",
    field: "balanceAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
  }
  const ProductColumn: AgColDefsI = {
    headerName: "Product",
    field: "product",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:false},
  }
  const CategoryColumn: AgColDefsI = {
    headerName: "Category",
    field: "category",
    minWidth: 80,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const Locationcolumn: AgColDefsI = {
    headerName: "Location",
    field: 'locationName',
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0 ",
    minWidth: 170,
    sortable: true,
    editable: false,
    cellRenderer:TextRendererComponent,
}
  const GrossWt: AgColDefsI = {
    headerName: "Gross Wt",
    field: "grossWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
  }
  const FinalWt: AgColDefsI = {
    headerName: "Final Wt",
    field: "finalWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
  }
  const PCS: AgColDefsI = {
    headerName: "Pcs",
    field: "pcs",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
  }
  const StoneWt: AgColDefsI = {
    headerName: "Stone Wt",
    field: "stoneWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
  }
  const MetalAmount: AgColDefsI = {
    headerName: "Metal Amt.",
    field: "metalAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }
  const MakingAmount: AgColDefsI = {
    headerName: "Making Amt.",
    field: "makingAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }
  const StoneAmount: AgColDefsI = {
    headerName: "Stone Amt.",
    field: "stoneAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }

  const SaleAmount: AgColDefsI = {
    headerName: "Sales Amt.",
    field: "saleAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }
  const TaxAmount: AgColDefsI = {
    headerName: "Tax Amount",
    field: "taxAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }
  const PurchaseAmount: AgColDefsI = {
    headerName: "Purchase Amt.",
    field: "purchaseAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }
  const MakingCost: AgColDefsI = {
    headerName: "Making Cost",
    field: "makingCost",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
  }
  const CostPrice: AgColDefsI = {
    headerName: "Cost Price",
    field: "costPrice",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
  }
  

  const Profit: AgColDefsI = {
    headerName: "Profit",
    field: "profit",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end ",
    editable: false,
    sortable: true,
    resizable:true,
    cellRenderer: NumericCellRendererComponent,
  }

  // start inputouput Tax
  const SrNoColumn: AgColDefsI = {
    headerName: "Sr No.",
    field: "srNo",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: TextRendererComponent,
  }
  const DateShowColumn: AgColDefsI = {
    headerName: "Date",
    field: "date",
    minWidth: 70,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer:DateCellRendererComponent
  }
  const VoucherTypeColumn: AgColDefsI = {
      headerName: "Voucher Type",
      headerClass: "px-0 custom-column-group-cell", 
      field: 'voucherTypeName',
      cellClass:'px-0 text-primary custom-column-group-cell', 
      minWidth: VoucherColWidth.MinWidth,
      maxWidth: VoucherColWidth.MaxWidth, 
      sortable: true,
      resizable:true,
      editable:false,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
          filterValueColumnName: "voucherType",
          filterIdColumnName: "voucherTypeName",
          filterParams: StorageName.VOUCHER_TYPE_DROPDOWN,
          floatingMultiselectDropDown: true,
          enableSorting: false,
        },
      cellRendererParams :{isCursor:true},
  }
  const VoucherNoColumn: AgColDefsI = {
    headerName: "Voucher No.",
    field: "voucherNo",
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,

    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0 text-primary text-underline",
    editable: false,
    sortable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "voucherNo",
      filterIdColumnName: "voucherNo",
      isInputFilter: true,
  },
    cellRendererParams: { isReadOnly: false ,isCursor: true },
  }
  const LedgerNameColumn: AgColDefsI = {
    headerName: "Ledger Name",
    field: 'ledgerName',
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 ",    
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable:true, 
    sortable: true,
    editable:false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  }
  const PartyColumn: AgColDefsI = {
    headerName: "Party",
    field: 'ledgerName',
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 ",    
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable:true, 
    sortable: true,
    editable:false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  }
  
  export const PurchaseAnalysisActionColumn: AgColDefsI = {
    headerName: "",
    field: "action",
    headerComponent: CustomHeaderComponent,
    headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
    cellRendererParams: {
      editIcon: false,
      hasViewPermission : true
    },
    headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false},
    cellClass: "mx-0 text-end px-1",
    minWidth: 40,
    maxWidth: 40,
  };

  const BillAmtColumn: AgColDefsI = {
    headerName: "Bill Amt",
    field: "billAmount",
    minWidth: AmountColWidth.MaxWidth,
    maxWidth: AmountColWidth.MinWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: false,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isCursor: true ,isAmt:true},
    headerComponentParams:{
    filterValueColumnName: "billAmount",
    filterIdColumnName: "billAmount",
    isInputFilter: true,
    isAllowNumber:true
    },
  }
 
  const TaxAmtColumn: AgColDefsI = {
    headerName: "Tax Amt",
    field: "taxAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: false,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isCursor: true ,isAmt:true},
    headerComponentParams:{
    filterValueColumnName: "taxAmount",
    filterIdColumnName: "taxAmount",
    isInputFilter: true,
    isAllowNumber:true
    },
  }
 // end inputouput TaxReport

const FinancialStatementSaleColumn  :AgColDefsI[]
=[
  LedgerNameColumn,
  PartyColumn,
  SalesPersonColumn,
  InvoiceNoColumn,
  BranchColumn,
  DateColumn,
  BarcodeColumn,
  PCS,
  CategoryColumn,
  ProductColumn,
  GrossWt,
  FinalWt,
  StoneWt,
  MetalAmount,
  MakingAmount,
  StoneAmount,
  SaleAmount,
  TaxAmount,
  MakingCost,
  CostPrice,
  Profit,

]
const TransationReportPurchaseTable: AgColDefsI[] = [
  // CashpartyColumn,
  SalesPersonColumn,
  InvoiceNoColumn,
  BranchColumn,
  DateColumn,
  BarcodeColumn,
  ProductColumn,
   Locationcolumn,
  GrossWt,
  FinalWt,
  PCS,
  StoneWt,
  MetalAmount,
  MakingAmount,
  StoneAmount,
  PurchaseAmount,
  LedgerNameColumn,
  // PurchaseAnalysisActionColumn
]

const TaxReportInputOutputTaxTable: AgColDefsI[] = [
  SrNoColumn,
  DateShowColumn,
  VoucherTypeColumn,
  VoucherNoColumn,
  LedgerNameColumn,
  BillAmtColumn,
  TaxAmtColumn,
]

export const AgGridDataModelOutputTax: AgGridI = {
  colDefs: TaxReportInputOutputTaxTable,
  rowSelection: "multiple",
  storageName: StorageName.FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID,
  filterStorageName: StorageName.FILTER_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID,
  sortStorageName: StorageName.SORT_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID,
  paginationStorageName : StorageName.PAGINATION_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID,
  initialRows: StorageName.FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID_INITIAL_ROWS,
  columnSettings:true,
  rows:[],
  rowHeight:28,
  headerHeight : 70,
  isFilter: true,  
  isServerSidepagination : false,
  apiUrl : API_URL_GET_ALL_FINANCIAL_STATEMENT_INPUT_OUTPUT_TAX_REPORT,
  showPagination:false
};
export const AgGridDataModelInputTax: AgGridI = {
  colDefs: TaxReportInputOutputTaxTable,
  rowSelection: "multiple",
  storageName: StorageName.FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID,
  filterStorageName: StorageName.FILTER_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID,
  sortStorageName: StorageName.SORT_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID,
  paginationStorageName : StorageName.PAGINATION_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID,
  initialRows: StorageName.FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID_INITIAL_ROWS,
  columnSettings:true,
  rows:[],
  rowHeight:28,
  headerHeight : 70,
  isFilter: true,  
  isServerSidepagination : false,
  apiUrl : API_URL_GET_ALL_FINANCIAL_STATEMENT_INPUT_OUTPUT_TAX_REPORT,
  showPagination:false
};

export const AgGridDataModelPurchase: AgGridI = {
  colDefs: TransationReportPurchaseTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  columnSettings: true,
  singleClickEdit: true,
  filterStorageName: StorageName.FILTER_FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID,
  storageName: StorageName.FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID,
  paginationStorageName: StorageName.PAGINATION_FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID,
  rows: [],
  rowHeight: 28,
  headerHeight : 28,
  isFilter: true,  
  isServerSidepagination: true,
  showPagination: true
};
export function createAgGridModel(colDefs: AgColDefsI[]): AgGridI {
  return {
    colDefs: colDefs,
    rowSelection: "multiple",
    addBlankRowOnEnter: true,
    columnSettings: true,
    singleClickEdit: true,
    storageName: StorageName.FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID,
    paginationStorageName: StorageName.PAGINATION_FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID,
    filterStorageName: StorageName.FILTER_STATEMENT_TRANSACTION_REPORT_SALE_GRID,
    sortStorageName: StorageName.SORT_STATEMENT_TRANSACTION_REPORT_SALE_GRID,
    rows: [],
    rowHeight: 28,
    headerHeight: 28,
    isFilter: true,
    apiUrl: API_URL_GET_ALL_TRANSACTION_SALE_ANALYSIS,
    isServerSidepagination: true,
    showPagination: true
  };
}

const TableWithoutPartyColumn: AgColDefsI[] = FinancialStatementSaleColumn.filter(
  (col) => col !== PartyColumn && col !== CostPrice
);
const TableWithoutLedgerNameColumn: AgColDefsI[] = FinancialStatementSaleColumn.filter(
  (col) => col !== LedgerNameColumn
);

export const AgGridDataModelSaleGoldSilverDiamond = createAgGridModel(FinancialStatementSaleColumn);
export const AgGridDataModelSaleGoldSilver = createAgGridModel(TableWithoutPartyColumn);
export const AgGridDataModelSaleDiamond = createAgGridModel(TableWithoutLedgerNameColumn);

  const BarcodeNoColumn: AgColDefsI = {
    headerName: "Barcode No.",
    field: "barcodeno",
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const RFIDCodeColumn: AgColDefsI = {
    headerName: "RFID Code",
    field: "rfidcode",
    minWidth: 70,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const HUIDNoColumn: AgColDefsI = {
    headerName: "HUID No.",
    field: "huidno",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const Product: AgColDefsI = {
    headerName: "Product",
    field: "product",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };

  const CaretColumn: AgColDefsI = {
    headerName: "Carat",
    field: "carat",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const PurityColumn: AgColDefsI = {
    headerName: "Purity",
    field: "purity",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const PurityWtColumn: AgColDefsI = {
    headerName: "Purity Wt",
    field: "puritywt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const WastageWtColumn: AgColDefsI = {
    headerName: "Wastage Wt",
    field: "WastageWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const MakingDiscColumn: AgColDefsI = {
    headerName: "Making Disc. %",
    field: "MakingDisc",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const PcsColumn: AgColDefsI = {
    headerName: "Pcs",
    field: "pcs",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const PktWtColumn: AgColDefsI = {
    headerName: "Pkt.Wt.",
    field: "PktWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    sortable: true,
    resizable:true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const TransationReportsalePopupTable :AgColDefsI[] =[
    BarcodeNoColumn,
    RFIDCodeColumn,
    HUIDNoColumn,
    Product,
    CategoryColumn,
    CaretColumn,
    PurityColumn,
    PurityWtColumn,
    WastageWtColumn,
    MakingDiscColumn,
    PcsColumn,
    PktWtColumn
  ]

  export const agGridDataModelTable: AgGridI = {
    colDefs: TransationReportsalePopupTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    storageName : StorageName.FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID,
    paginationStorageName : StorageName.PAGINATION_FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID,
    rows:[],
    rowHeight:28,
  };



  // account groups popup model

  const SrNo: AgColDefsI = 
    {
      headerName: "Sr No.",
      field: 'srNo',
      cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
      headerClass: "custom-column-group-cell px-0",
      minWidth: 70,
      maxWidth: 70,
      resizable: true,
      sortable: true,
      editable: false,
      lockPosition:"left",
      cellRenderer:TextRendererComponent,
    };
  const PerticularColumn: AgColDefsI = 
    {
      headerName: "Perticular",
      field: 'particulars',
      headerClass: "custom-column-group-cell px-0",
      cellClass: "custom-column-group-cell  px-2",
      minWidth: 180, flex:2,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
    };
  const Opening: AgColDefsI = 
    {
      headerName: "Opening",
      field: 'openingCr',
      headerClass: "custom-column-group-cell px-0 ag_header_end",
      cellClass: "text-primary custom-column-group-cell text-end px-2",
      minWidth: AmountColWidth.MinWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRendererParams :{isCursor:true},
      cellRenderer:TextRendererComponent,
    };
  const Debit: AgColDefsI = 
    {
      headerName: "Debit",
      field: 'debitCr',
      headerClass: "custom-column-group-cell px-0 ag_header_end",
      cellClass: "custom-column-group-cell text-end px-2",
      minWidth: AmountColWidth.MinWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams:{isAmt:true},
    };
  const Credit: AgColDefsI = 
    {
      headerName: "Credit",
      field: 'creditCr',
      headerClass: "custom-column-group-cell px-0 ag_header_end",
      cellClass: "custom-column-group-cell text-end px-2",
      minWidth: AmountColWidth.MinWidth, 
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams:{isAmt:true},
    };
  const Closing: AgColDefsI = 
    {
      headerName: "Closing",
      field: 'closingCr',
      headerClass: "custom-column-group-cell px-0 ag_header_end",
      cellClass: "custom-column-group-cell text-end px-2",
      minWidth: AmountColWidth.MinWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
    };

  const AccountGroupsTable:AgColDefsI[] = [
    SrNo,
    PerticularColumn,
    Opening,
    Debit,
    Credit,
    Closing,
  ]

  export const AgGridAccountGroupsGrid: AgGridI = {
    colDefs: AccountGroupsTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    storageName:StorageName.FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID,
    filterStorageName: StorageName.FILTER_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID,
    sortStorageName: StorageName.SORT_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID,
    singleClickEdit:true,
    rowHeight:28,
    isFilter: false,  
    // apiUrl : API_URL_GETALLBARCODESTOCK,
    isServerSidepagination : false,
    showPagination: false
  };
  

  const VoucherType: AgColDefsI = 
    {
      headerName: "Voucher Type",
    field: "voucherTypeName",
    minWidth: VoucherColWidth.MinWidth,
    resizable:true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    };

    const LedgerName: AgColDefsI = 
    {
      headerName: "Ledger Name",
      field: 'ledgerName',
      headerClass: "custom-column-group-cell px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: WeightColWidth.MinWidth,
      maxWidth: WeightColWidth.MaxWidth, 
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
    };

    const DtColumn: AgColDefsI = {
      headerName: "Date",
      field: "date",
      minWidth:50,
      sortable: true,
      editable: false,
      resizable:true,
      cellClass: "custom-column-group-cell px-0 text-end",
      headerClass: "custom-column-group-cell px-0 ag_header_end ",
      cellRenderer: DateCellRendererComponent,
    };

    const Balance: AgColDefsI = 
    {
      headerName: "Balance",
      field: 'balance',
      headerClass: "custom-column-group-cell px-0 ag_header_end",
      cellClass: "custom-column-group-cell text-end px-2",
      minWidth: WeightColWidth.MinWidth,
      maxWidth: WeightColWidth.MaxWidth, 
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
    };

  const LedgerDetailsTable:AgColDefsI[] = [
    SrNo,
    VoucherType,
    LedgerName,
    DtColumn,
    Debit,
    Credit,
   Balance
  ]

  export const AgGridLedgerDetailsGrid: AgGridI = {
    colDefs: LedgerDetailsTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    storageName:StorageName.FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID,
    filterStorageName: StorageName.FILTER_FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID,
    sortStorageName: StorageName.SORT_FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID,
    singleClickEdit:true,
    rowHeight:28,
    isFilter: false,  
    isServerSidepagination : false, 
    showPagination: false
  };