import { Injectable } from '@angular/core';
import { DataService } from './../../shared/services/data.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ResponseModel } from './../../shared/models/response-model.model';
import {BankDetailsModel, CompanyAddressModel, CompanyDetailsModel, LoginDetailsModel, PersonalDetailsModel, PlanDetailsModel} from './edit-profile.model';

@Injectable({
  providedIn: 'root'
})
export class EditProfileService {
  constructor(public service: DataService,) { }
  public updateUser = new BehaviorSubject<any>(undefined);
  public refreshCompanyDetails = new BehaviorSubject<any>(undefined);
  public refreshCompanyAddress = new BehaviorSubject<any>(undefined);
  // PersonalDetails service
    updatePersonalDetails(personalDetailsModel:PersonalDetailsModel):Observable<ResponseModel>{
      let url = "api/app/company/updatecompanyuser";
      const json = JSON.stringify(personalDetailsModel);
      return this.service.put(url,personalDetailsModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // CompanyDetails service
    updateCompanyDetails(companyDetailsModel:CompanyDetailsModel):Observable<ResponseModel>{
      let url = "api/app/company/updatecompanydetails";
      const json = JSON.stringify(companyDetailsModel);
      return this.service.put(url,companyDetailsModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // CompanyDetails service
    updateCompanyAddress(companyAddressModel:CompanyAddressModel):Observable<ResponseModel>{
    let url = "api/app/company/updatecompanyaddress";
    const json = JSON.stringify(companyAddressModel);
    return this.service.put(url,companyAddressModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // BankDetails service
    updateBankDetails(bankDetailsModel:BankDetailsModel):Observable<ResponseModel>{
      let url = "api/app/company/updatecompanyuserbankdetails";
      const json = JSON.stringify(bankDetailsModel);
      return this.service.put(url,bankDetailsModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // LoginDetails service
    updateLoginDetails(loginDetailsModel:LoginDetailsModel):Observable<ResponseModel>{
      let url = "api/app/company/updatecompanyuserpassword";
      const json = JSON.stringify(loginDetailsModel);
      return this.service.put(url,loginDetailsModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // PlanDetails service
    updatePlanDetails(planDetailsModel:PlanDetailsModel):Observable<ResponseModel>{
      let url = "";
      const json = JSON.stringify(planDetailsModel);
      return this.service.put(url,planDetailsModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    
    // *************get by CompanyDetailsByCurrentUser **************
    getByIdCompanyDetailsByCurrentUser():Observable<ResponseModel>{
      let url = "api/app/company/getcompanydetailsbycurrentuser";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get company by id  **************
    getCompanyById(companyId):Observable<ResponseModel>{
      let url = `api/app/company/getbyidcompany?Id=${companyId}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
}
