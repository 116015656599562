import { Component, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RangeDatepickerComponent } from '../../range-datepicker/range-datepicker.component';
import { AgGridService } from '../../ag-grid/ag-grid.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';
import { DatePipe } from '@angular/common';
import { DateRangeType } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-advanced-filter-popup',
  templateUrl: './advanced-filter-popup.component.html',
  styleUrls: ['./advanced-filter-popup.component.scss'],
  providers :[DatePipe]
})
export class AdvancedFilterPopupComponent implements OnInit  {
  @Input() data : any = {};
  @ViewChildren(RangeDatepickerComponent) dateRangeDatepicker: QueryList<RangeDatepickerComponent>
  selectedAccountLedgerIds      = [];
  filteredProductDropdown:any
  advanceFilterRequest :any = {}
  selectedBranchIds = [];
  selectedMetalIds =[];
  selectedProductIds = [];
  constructor
    (
      public activeModal: NgbActiveModal,
      public renderer: Renderer2,
      public agGridService: AgGridService,
      public storageService: StorageService,
      public commonFilterService: CommonFilterService,
    ) {
  }
  ngOnInit(): void {
   this.data;
   
   
  }

  ngAfterViewInit(): void {
    this.setAlreadyAppliedData();
  }

  setAlreadyAppliedData() {
    var previousAppliedFilters = this.storageService.retrieve(this.data.filterStorageName);
    if (previousAppliedFilters != undefined) {

      //Branch 
      this.selectedBranchIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "branchId", this.data.getAllDropdownModel.branchDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectBranchs = this.selectedBranchIds?.map((x) => x.id);

      //Metals
      this.selectedMetalIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "metalId", this.data.getAllDropdownModel.metalDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedMetals = this.selectedMetalIds?.map((x) => x.id);

      if( this.advanceFilterRequest?.selectedMetals){
        this.onChangeDepartment(this.advanceFilterRequest?.selectedMetals)
      }
      //Products
      this.selectedProductIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "productId", this.data.getAllDropdownModel.productDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedProducts = this.selectedProductIds?.map((x) => x.id);

      //Title
      this.advanceFilterRequest.title = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "title", undefined, "input");
      
      //Design
      this.advanceFilterRequest.design = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "designNo", undefined, "input");

      //Barcode
      this.advanceFilterRequest.barcode = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "barcodeNo", undefined, "input");

      this.advanceFilterRequest.jcno = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "catelogueNo", undefined, "input");

      this.advanceFilterRequest.bfm = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "billOfMaterialNo", undefined, "input");

      this.advanceFilterRequest.sku = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "sku", undefined, "input");

      this.advanceFilterRequest.fromDate = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "fromDate", undefined, "input");

      this.advanceFilterRequest.toDate = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "toDate", undefined, "input");

    }
  }

  onClickAdvancedFilter() {
    this.storageService.removeItem(this.data.filterStorageName);

    this.commonFilterService.performFilterColumnwise('branchId', this.advanceFilterRequest.selectBranchs, undefined, this.data.filterStorageName, '');
 
    this.commonFilterService.performFilterColumnwise('metalId', this.advanceFilterRequest.selectedMetals, undefined, this.data.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('productId', this.advanceFilterRequest.selectedProducts, undefined, this.data.filterStorageName, '');

    if (this.advanceFilterRequest.title != undefined)
      this.commonFilterService.performFilterColumnwise('title', undefined, this.advanceFilterRequest?.title, this.data.filterStorageName, 'textFilter');

    if (this.advanceFilterRequest.design != undefined)
      this.commonFilterService.performFilterColumnwise('designNo', undefined, this.advanceFilterRequest?.design, this.data.filterStorageName, 'textFilter');

    if (this.advanceFilterRequest.barcode != undefined)
      this.commonFilterService.performFilterColumnwise('barcodeNo', undefined, this.advanceFilterRequest?.barcode, this.data.filterStorageName, 'textFilter');

    if (this.advanceFilterRequest.jcno != undefined)
      this.commonFilterService.performFilterColumnwise('catelogueNo', undefined, this.advanceFilterRequest?.jcno, this.data.filterStorageName, 'textFilter');

    if (this.advanceFilterRequest.bfm != undefined)
      this.commonFilterService.performFilterColumnwise('billOfMaterialNo', undefined, this.advanceFilterRequest?.bfm, this.data.filterStorageName, 'textFilter');

    if (this.advanceFilterRequest.sku != undefined)
      this.commonFilterService.performFilterColumnwise('sku', undefined, this.advanceFilterRequest?.sku, this.data.filterStorageName, 'textFilter');

    if (this.advanceFilterRequest?.fromDate != undefined)
      this.commonFilterService.performFilterColumnwise('fromDate', undefined, this.advanceFilterRequest?.fromDate, this.data.filterStorageName, 'dateFilter');

    if (this.advanceFilterRequest?.toDate != undefined)
      this.commonFilterService.performFilterColumnwise('toDate', undefined, this.advanceFilterRequest?.toDate, this.data.filterStorageName, 'dateFilter');

    var filterData = this.commonFilterService.processFilterData(this.data.filterStorageName)

    this.advanceFilterRequest.filters = filterData;
    this.activeModal.close(this.advanceFilterRequest)
  }

  onChangeDepartment(event) {
    const selectedDeptIds = event.map(e => +e);
    this.filteredProductDropdown = this.data?.getAllDropdownModel?.productDropdown?.filter(i => selectedDeptIds?.some(d => i.extra1.includes(d))
    );
    if (!this.filteredProductDropdown?.length || !this.filteredProductDropdown?.some(i => this.advanceFilterRequest?.selectedUserNames?.includes(i.id))) this.selectedProductIds = [];
  }

  clearFields() {
    this.advanceFilterRequest = {}
    this.selectedMetalIds = [];
    this.selectedProductIds = [];
    this.selectedBranchIds = [];

    this.commonFilterService.setDefaultFilter(this.data.apiRequestStorageName, this.data.apiRequestModel, this.data.filterStorageName, { setDefaultDateRangePickerData: { dateRangeType: DateRangeType.Today } });
    this.setAlreadyAppliedData();
  }
}