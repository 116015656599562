import { LoadDropdownDataService } from './load-dropdown-data.service';
import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subscription, catchError } from 'rxjs';
import { ResponseModel } from '../../models/response-model.model';
import { ShowHeaderPageNameAndInvoieNum, commonModalOptions } from '../../models/common.model';
import { SendDataService } from '../../services/send-data.service';
import { StorageService } from '../../services/storage.service';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { StorageName } from '../../enum/common-enum';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PrintConfirmationComponent } from '../common-popup/print-confirmation/print-confirmation.component';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-load-dropdown-data',
  templateUrl: './load-dropdown-data.component.html',
  styleUrls: ['./load-dropdown-data.component.scss']
})
export class LoadDropdownDataComponent implements OnDestroy {
  resetInvoiceSubscription: Subscription;
  @Input() formName:string;
  @Input() PageName : string;
  @Input() Name : string;
  @Input() title ='Print';
  @Input() barcodeSvg:boolean = false
  @Output() fillDropdownData = new EventEmitter();
  @Output() onPrint = new EventEmitter();
  @Input() isInvoiceNo:boolean = false;
  storageName = StorageName

  constructor(public loadDropdownDataService:LoadDropdownDataService,
    public sendDataService : SendDataService,
    public storageService : StorageService,
    public notificationService: NotificationService,
    private modalService          : NgbModal,
    public administrationService : AdministrationService
    ){
      this.resetInvoiceSubscription = this.storageService.resetInvoice.subscribe((result) => {
        if(result != undefined)
          this.getInvoiceNo();
       });
  }

  ngOnInit()
  {
    this.getInvoiceNo();
    
    if((this.formName == StorageName.FORMNAME_TRANSACTION_SALESINVOICE) || (this.formName == StorageName.FORMNAME_TRANSACTION_PAYMENTVOUCHER) || (this.formName == StorageName.FORMNAME_TRANSACTION_PDCENTRY) || (this.formName == StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE) || (this.formName == StorageName.FORMNAME_TRANSACTION_PURCHASEORDER) || (this.formName == StorageName.FORMNAME_TRANSACTION_PURCHASERETURN) || (this.formName == StorageName.FORMNAME_TRANSACTION_RECEIPTVOUCHER) || (this.formName == StorageName.FORMNAME_TRANSACTION_SALESORDER) || (this.formName == StorageName.FORMNAME_TRANSACTION_SALESRETURN) || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL)  || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK) || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION) || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE) || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_STOCKOUT) || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER) || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION) || (this.formName == StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE)){
      this.getAllTax();
    }
  }
  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Assuming the barcode scanner inputs data as keyboard events
    // You might need to adjust this depending on how your barcode scanner works
   
      this.handleBarcodeScan(event);
    
  }

  barcodeValue = '';
  lastKeypressTime = 0;
  handleBarcodeScan(event: KeyboardEvent) {
    const currentTime = new Date().getTime();

    // Check the time difference between this and the last keypress
    // If it's less than a certain threshold (e.g., 50ms), consider it part of a barcode scan
    if (currentTime - this.lastKeypressTime < 50) {
      this.barcodeValue += event.key;
    } else {
      // If the time difference is too long, reset the barcode value
      this.barcodeValue = event.key;
    }
    this.lastKeypressTime = currentTime;

    // Check for the Enter key (assuming your scanner is configured to send this at the end of a scan)
    if (event.key === 'Enter' && this.barcodeValue) {
      // Prevent it from doing anything default (e.g., submitting a form)
      event.preventDefault();

      // Remove the Enter key itself from the barcode value if included
      // const finalBarcode = this.barcodeValue.replace(/Enter$/, '');
      const barcodeNO = this.barcodeValue.replace(/Enter|Shift/g, '');

      const element = document.getElementById('barcode') as any;

      if(barcodeNO){
        this.storageService.barcodeScanValueSource?.next({barcodeNO ,formName : this.formName});
      }

      console.log('Barcode Value:', barcodeNO);
      //this.processBarcode(finalBarcode);
      // Reset for the next scan
      // this.barcodeValue = '';
    }
  }




  ngOnChanges(){
    if (this.formName || this.formName?.includes("Diamond &") || this.formName?.includes("Gold &")) {
      this.formName = this.formName?.replace(/&/g, "/");
    }
    if(this.formName){
      this.loadDropdownDataService?.getAllDropdownDataFormWise(this.formName)
      .pipe(catchError((err)=>this.handleError((err))))
      .subscribe((res:ResponseModel)=>{
        if(res.isSuccess)
        this.fillDropdownData.emit(res?.response);
      })
    }
  }

  getInvoiceNo(){
    if (this.formName && this.isInvoiceNo) {
      this.formName = this.formName?.indexOf("Stock Journal") !== -1 ? "Stock Journal" : (this.formName === "Memo / Consignment In" ? "Consignment In" : (this.formName === "Memo / Consignment Out" ? "Consignment Out" : this.formName));     
       this.loadDropdownDataService?.getInvoiceNo(this.formName)
      .pipe(catchError((err)=>this.handleError((err))))
      .subscribe((res:ResponseModel)=>{
        if(res.isSuccess)
        {
          var invoiceName = res?.response?.invoiceNo;
          if(invoiceName){
           const showHeaderPageNameAndInvoieNum = new ShowHeaderPageNameAndInvoieNum();
           showHeaderPageNameAndInvoieNum.pageName = this.formName;
           showHeaderPageNameAndInvoieNum.invoiceNumber = invoiceName;
           this.storageService.invoiceNoCalled.next(res.response);
           this.sendDataService.headerPageNameAndInvoiceNum.next(showHeaderPageNameAndInvoieNum);
          }
        }
      })
    }
  }

  setInvoiceNo(invoiceName){

           const showHeaderPageNameAndInvoieNum = new ShowHeaderPageNameAndInvoieNum();
           showHeaderPageNameAndInvoieNum.pageName = this.formName;
           showHeaderPageNameAndInvoieNum.invoiceNumber = invoiceName;
          
           this.sendDataService.headerPageNameAndInvoiceNum.next(showHeaderPageNameAndInvoieNum);
  }

  getAllTax()
  {
    this.administrationService.getAllTaxSetting().subscribe({
      next:(res)=>{
        if(res.isSuccess)
          this.storageService.store("TaxSetting",res.response??[]);
      },
      error:(err)=>{
      }
    })
  }

  // confirmPrintPopUp(){
  //   const modalOption: NgbModalOptions = {
  //     ...commonModalOptions,
  //     size            : "sm",
  //   };
  //   const modalRef = this.notificationService.openModalPopup(PrintConfirmationComponent, modalOption);
  //   modalRef.componentInstance.formName = this.formName;
  //   modalRef.result.then((result) => {
  //     if(result) 
  //         this.onPrint.emit(true);
  //       else
  //       this.onPrint.emit(false);
  //   });
  // }

  confirmPrintPopUp() {
    const title = `${this.title} ${(this.formName === this.storageName.FORMNAME_TRANSACTION_STOCKJOURNAL || 
                    this.formName === this.storageName.FORMNAME_INVENTORY_BARCODE) ? 'barcode' : 'bill'}`;
    const message = `Do you want to ${this.title.toLowerCase()} ${(this.formName === this.storageName.FORMNAME_TRANSACTION_STOCKJOURNAL || 
                    this.formName === this.storageName.FORMNAME_INVENTORY_BARCODE) ? 'barcode' : 'invoice'}?`;
    this.notificationService.confirmationPopup(title, message, 'printBill').subscribe((result) => {
      if(result.isConfirmed) {
        this.onPrint.emit(true);
      }else{
        this.onPrint.emit(false);
      }
    });
  }
  


  handleError(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  ngOnDestroy() {
    this.resetInvoiceSubscription?.unsubscribe();
  }

}
