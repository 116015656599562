import { Component, Input, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { RangeDatepickerComponent } from 'src/app/shared/common-components/range-datepicker/range-datepicker.component';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-accountledger-report-amount-advanced-filter',
  templateUrl: './advanced-filter-popup.component.html',
  styleUrls: ['./advanced-filter-popup.component.scss']
})
export class AccountledgerReportAmountAdvancedFilterComponent {
  @Input() data : any = {};
  @ViewChildren(RangeDatepickerComponent) dateRangeDatepicker: QueryList<RangeDatepickerComponent>
  selectedBranchIds             = [];
  selectedGroupIds              = [];
  selectedLedgerIds             = [];
  selectedLegerTypesIds         = [] ;
  selectedAgainstIds            = [];
  selectedVoucherTypeIds        = [];
  selectedagainstVoucherTypeId  = [];
  filterAgainstDropdown         : any;
  filteredLedgerDropdown        : any;
  advanceFilterRequest          :any = {}
  constructor(
    public activeModal       : NgbActiveModal,
    public renderer          : Renderer2,
    public agGridService     : AgGridService,
    public storageService    : StorageService,
    public commonFilterService : CommonFilterService,
    
  )
  {}

  ngAfterViewInit(): void {
    this.setAlreadyAppliedData();
  }

  onChangeAgainstVoucherType(event) {
    const eventIds = Array.isArray(event) ? event : [event];
        this.filterAgainstDropdown = this.data?.getAllDropdownModel?.againstDropdown?.filter(item =>
      eventIds.includes(item?.extra1)
    );
      const selectedAgainstVoucherTypeNoExist = this.filterAgainstDropdown?.some(item => 
      this.advanceFilterRequest?.selectedAgainstNos?.includes(item?.id)
    );
      if (this.filterAgainstDropdown?.length === 0 || !selectedAgainstVoucherTypeNoExist) {
      this.selectedAgainstIds = [];
    }
  }
  

  setAlreadyAppliedData() {
    var previousAppliedFilters = this.storageService.retrieve(this.data.agGridDataModel.filterStorageName);
    if (previousAppliedFilters != undefined) {
      this.selectedBranchIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "branchId", this.data.getAllDropdownModel.branchDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedBranches = this.selectedBranchIds?.map((x) => x.id);

      this.selectedGroupIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "accountgroupid", this.data.getAllDropdownModel.accountGroupDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedGroups = this.selectedGroupIds?.map((x) => x.id);

      this.filteredLedgerDropdown = this.data?.modalFilterData?.elementsToHide && this.data?.modalFilterData?.accountgroupId ? this.data.getAllDropdownModel.allAccountLedgerDropdown.filter((ledger) => ledger.extra1 === this.data.modalFilterData.accountgroupId) : this.data.getAllDropdownModel.allAccountLedgerDropdown;
      this.selectedLedgerIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "ledgerid", this?.filteredLedgerDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedAccountLedgers = this.selectedLedgerIds?.map((x) => x.id);

      this.selectedLegerTypesIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "ledgerTypeId", this.data.getAllDropdownModel.ledgerTypeDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedLedgerIds = this.selectedLegerTypesIds?.map((x) => x.id);

      this.selectedVoucherTypeIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "vouchertypeId", this.data.getAllDropdownModel.voucherTypeDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedVoucherTypes = this.selectedVoucherTypeIds?.map((x) => x.id);

      this.selectedagainstVoucherTypeId = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "againstVoucherTypeId", this.data.getAllDropdownModel.voucherTypeDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedagainstVoucherType = this.selectedagainstVoucherTypeId?.map((x) => x.id);

      if( this.selectedagainstVoucherTypeId?.length >0){
        this.onChangeAgainstVoucherType(this.advanceFilterRequest.selectedagainstVoucherType)
      }
      this.selectedAgainstIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "againstinvoiceno", this.filterAgainstDropdown, "multiselectDropdown");
      this.advanceFilterRequest.selectedAgainstNos = this.selectedAgainstIds?.map((x) => x.id);

      this.advanceFilterRequest.invoiceNo = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "invoiceNo", undefined, "input");

      this.advanceFilterRequest.fromDate = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "fromDate", undefined, "input");

      this.advanceFilterRequest.toDate = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters, "toDate", undefined, "input");

      this.dateRangeDatepicker?.get(0)?.onApply(false, this.advanceFilterRequest.fromDate, this.advanceFilterRequest.toDate);
      
      this.advanceFilterRequest.onlyBalance = previousAppliedFilters.find(filter => filter.name === "onlyBalance")?.FilterData[0] === "true" ? "true" : "false";

    }
  }

  onClickAdvancedFilter() {
    this.storageService.removeItem(this.data.agGridDataModel.filterStorageName);
    this.commonFilterService.performFilterColumnwise('accountgroupid', this.advanceFilterRequest.selectedGroups, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('branchId', this.advanceFilterRequest.selectedBranches, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('ledgerid', this.advanceFilterRequest.selectedAccountLedgers, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('ledgerTypeId', this.advanceFilterRequest.selectedLedgerIds, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('againstinvoiceno', this.advanceFilterRequest.selectedAgainstNos, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('vouchertypeId', this.advanceFilterRequest.selectedVoucherTypes, undefined, this.data.agGridDataModel.filterStorageName, '');

    this.commonFilterService.performFilterColumnwise('againstVoucherTypeId', this.advanceFilterRequest.selectedagainstVoucherType, undefined, this.data.agGridDataModel.filterStorageName, '');

    if(this.advanceFilterRequest?.invoiceNo != undefined)
    this.commonFilterService.performFilterColumnwise('invoiceNo', undefined, this.advanceFilterRequest?.invoiceNo, this.data.agGridDataModel.filterStorageName, 'textFilter');

    if (this.advanceFilterRequest?.fromDate != undefined)
      this.commonFilterService.performFilterColumnwise('fromDate', undefined, this.advanceFilterRequest?.fromDate, this.data.agGridDataModel.filterStorageName, 'dateFilter');

    if (this.advanceFilterRequest?.toDate != undefined)
      this.commonFilterService.performFilterColumnwise('toDate', undefined, this.advanceFilterRequest?.toDate, this.data.agGridDataModel.filterStorageName, 'dateFilter');

    this.commonFilterService.performFilterColumnwise('onlyBalance', [this.advanceFilterRequest?.onlyBalance], undefined, this.data.agGridDataModel.filterStorageName, '');

    var filterData = this.commonFilterService.processFilterData(this.data.agGridDataModel.filterStorageName);

    this.advanceFilterRequest.filters = filterData;
    this.activeModal.close(this.advanceFilterRequest);
  }

  clearFields() {
    this.selectedBranchIds = [];
    this.selectedGroupIds = [];
    this.selectedLedgerIds = [];
    this.selectedLegerTypesIds = [];
    this.selectedAgainstIds = [];
    this.selectedLegerTypesIds = [];
    this.selectedagainstVoucherTypeId = [];
    this.commonFilterService.setDefaultFilter(this.data.apiRequestStorageName, this.data.apiRequestModel, this.data.agGridDataModel.filterStorageName);
    this.setAlreadyAppliedData();
  }

}
