import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service'
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ResponseModel } from '../../../../app/shared/models/response-model.model';
import { ProductListModel, ProductModel, ProductOpening, TaxModel } from './product-opening.model'

@Injectable({
  providedIn: 'root'
})
export class ProductOpeningService {
  public previouscellClickedId: number = null;
  constructor(public service:DataService) { }
  public ProductOpeaningNameNavigate = new BehaviorSubject<any>(undefined);
  public branchDetails = new BehaviorSubject<any>(undefined);
  
  insertProduct(data){
    let url = 'api/app/product/addproduct';
    
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }

  updateProduct(data){
    let url = 'api/app/product/updateproduct';
    
    return this.service.put(url,data,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
      })
    );
  }

  getAllProduct(productListModel:ProductListModel){
    let url = 'api/app/product/getallproduct';
    const a = JSON.stringify(productListModel)
    return this.service.post(url,productListModel,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
      })
    );
  }

  deleteProduct(Id:number){
    let url = `api/app/product/deleteproduct?Id=${Id}`;
    return this.service.delete(url,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  getAllTaxSettings(){
    let url = 'api/app/taxsetting/getalltaxsetting';
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
      })
    );
  }

  insertTax(taxModel:TaxModel){
    let url = 'api/app/taxsetting/inserttaxsetting';
    
    return this.service.post(url,taxModel,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }

  getProductById(productId:number){
    let url = `api/app/product/getproductbyid?Id=${productId}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
      })
    );
  }

  // *************upload file product opening  **************
  uploadFile(data:any):Observable<ResponseModel>{
    let url = "api/app/product/UploadProductFile";
    const json = JSON.stringify(data);
    const formData = new FormData();
    formData.append('file', data);
    
    return this.service.postWithFile(url,formData, true,null,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
}
