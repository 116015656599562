import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shimmering-effect',
  templateUrl: './shimmering-effect.component.html',
  styleUrls: ['./shimmering-effect.component.scss']
})
export class ShimmeringEffectComponent {
  @Input() height: string = '100'; 
  @Input() width: string = '100';
  @Input() background: string = '#fff';
  @Input() animationSpeed: string = '1.5s';
  @Input() CardView: string ;
  CardCount: number[] = new Array(26)
  
  ngOnInit(){
    
  }
}
