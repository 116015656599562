import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { catchError } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-unit-conversion',
  templateUrl: './unit-conversion.component.html',
  styleUrls: ['./unit-conversion.component.scss']
})
export class UnitConversionComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllUnitConversionData          : any[]=[];
  isAdd                       : boolean = false;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllUnitConversionList();
}

add(){
  this.isAdd = true;
  const obj = {id:0, name:'',active:true,productId:0,unitId:0,conversionRate:0,quantities:0}
  this.getAllUnitConversionData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`unitConversionName${obj.id}`);
    element?.focus();
  },0)
}

cancel(){
  this.isAdd = false;
  this.getAllUnitConversionData = this.getAllUnitConversionData.filter((x)=>x.id != 0)
}

edit(item,formData){
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
}

  // *********** UnitConversion API START *********
  // **********get all UnitConversion *********
  getAllUnitConversionList() {
    this.administrationService.getAllUnitConversion().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.getAllUnitConversionData = res.response??[];
        }else{           
          this.getAllUnitConversionData = [];
        }  
      },
      error:(err)=>{
        this.getAllUnitConversionData = [];
      }
    })
  }
  
 // *************insert UnitConversion **************
  InserUnitConversionList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertUnitConversion(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllUnitConversionList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update UnitConversion *********
    UpdateUnitConversionList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateUnitConversion(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            
            this.isAdd = false;
            this.getAllUnitConversionList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

  // **********delete UnitConversion *********
    DeleteUnitConversionList(id) {
      this.administrationService.deleteUnitConversion(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllUnitConversionList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** UnitConversion API END *********

}

