import { CommonModel } from "src/app/components/Settings/set-software/set-software.model";
import { paginationModel } from "src/app/shared/common-components/ag-grid/ag-grid.model";
import { PaginationCommonRequestModel } from "src/app/shared/models/pagination-base-model.model";

// export class JewelryCatalogueRequestModel {
//       catalogueModel: CatalogueModel
//       catalogueResourceModel: CatalogueResourceModel[];
//   }

// export class CatalogueModel {
//   id: number = 0;
//   barcodeNo: string;
//   metalId: number;
//   productDetailId: number;
//   title: string;
//   category: number;
//   designNo: number;
//   sku: string;
//   amount: number;
//   shortDescription: string;
//   fullDescription: string;
//   weight:any;
//   productName:string;
// }

// export class CatalogueResourceModel {
//   deleted : boolean = false;
//   added   : boolean = false;
//   edited  : boolean = false;
//   none    : boolean = false;
//   id      : number ;
//   base64Resource : string;
// }

export type JewelryCatalogue = JewelryCatalogueRequestModel[]
  
  export class JewelryCatalogueRequestModel {
    catalogueModel: CatalogueModel
    imageUrls: ImageUrls[]
    eCommerceIntegrationModel: ECommerceIntegrationModel
    invoiceModel: InvoiceModel
    itemList: ItemList[]
    invoiceDocumentResources: InvoiceDocumentResource[] = [];
    billOfMaterial = new BillOfMaterial();
  }

  export class InvoiceDocumentResource {
    imageUrl: string;
    base64Resource: string;
  }
  
  export class BillOfMaterial {
    invoiceDocumentResources: InvoiceDocumentResource[] = [];
  }
  
  export class CatalogueModel {
    id: number
    barcodeNo: string
    metalId: number
    productDetailId: number
    title: string
    category: number
    designNo: string
    sku: string
    amount: number
    shortDescription: string
    fullDescription: string
    weight: number
    productName: string;
    images:any
    stockPostingId :number;
    invoiceNo:any
  }
  
  export class ImageUrls {
    deleted : boolean = false;
    added   : boolean = false;
    edited  : boolean = false;
    none    : boolean = false;
    id      : number ;
    imageUrl : string;
  }
  
  export class InvoiceModel {
    suffixPrefixId: number
    voucherNo: number
    voucherTypeId: number
    invoiceNo: string
    id: number
    customerName: string
    cashParty: boolean
    invoiceDate: string
    accountLedgerId: number
    exchangeRateId: number
    amount: number
    taxAmount: number
    invoiceDiscount: number
    grandTotal: number
    totalAmount: number
    amountPaid: number
    balanceAmount: number
    currencyExchangeAmount: number
    isMetalExchangeExist: boolean
    returnAmount: number
    exchangeAmount: number
    additionalAmount: number
    salePersonId: number
    creditPeriod: number
    isInTally: boolean
    dueDateInterest: number
    againstOfId: number
    saleOrderId: number
    deliveryNoteId: number
    saleQuotationId: number
    redeemPt: number
    redeemAmount: number
    genRewardPt: number
    genRewardAmount: number
    isKYC: boolean
    isAML: boolean
    makingAmount: number
    roundOFF: boolean
    roundOFFValue: number
    netAmount: number
    advancePayment: number
    internalFix: boolean
    metalFix: boolean
    metalAmount: number
    redeemBalancePoint: number
    totalGrossGold: number
    totalGrossSilver: number
    totalPureGold: number
    totalPureSilver: number
    balanceGrossGold: number
    balanceGrossSilver: number
    balancePureGold: number
    balancePureSilver: number
    comment: string
    dueDate: string
    currencyExchangeRate: number
    branchId: number
    catelogueId: any;
  }
  
  export class ItemList {
    invoiceNo: string
    againstVoucherNo: number
    againstVoucherTypeId: number
    grossWt: number
    finalWt: number
    barcodeNo: string
    description: string
    netAmount: number
    taxAmount: number
    netAmountWithTax: number
    invoiceDate: string
    dueDate: string
    detailedJson: string
    makingAmount: number
    statusId: number
    parentMetalId: number
    comment: string
    groupName: string
    isGroup: boolean
    setName: string
    isSet: boolean
    quantity: number
  }

  export class ECommerceIntegrationModel {
    publishToShopify: boolean;
    publishToWooCommerce: boolean;
  }
 
  export class GetAllJewelryCatalogueResponce {
    id          : number;
    productName?: string;
    weight?     : number;
    imageName?  : string;
    total_rows  : number;
    total_pages : number;
    images      : Image[];
  }
  
  export class Image {
    imageUrl: string
  }

  export class GetAllJewelryCatalogueRequestModel extends paginationModel{
    pageSize = 25;
    page=1;
    getAll= false;
    filters: any;
  }

  export enum JewelaryTab{
    Info           = 1,
    BillOfMaterial = 2,
  }


  export class CatalogueBillOfMaterialModel {
    id: number = 0;
    barcodeNo: string;
    metalId: number;
    productDetailId: number;
    title: string;
    category: number;
    designNo: number;
    sku: string;
    amount: number;
    shortDescription: string;
    fullDescription: string;
    weight:any;
    productName:string;
  }
  