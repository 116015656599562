import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExportAs } from '../enum/common-enum';
import { ExcelConverterServiceService } from './excel-converter-service.service';
import { ReportService } from '../../components/Report/report.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class FileReaderService {

  constructor(
    private http: HttpClient,
    public toaster : ToastrService,
    public excelConverterServiceService? : ExcelConverterServiceService,
    public reportService? : ReportService , ) 
    { }

  getBaseUrlFromFile(filePath: string): Promise<any> {
    return this.http.get(filePath, { responseType: 'json' })
      .toPromise()
      .then((data: any) => data)
      .catch(error => {
        console.error('Error reading file:', error);
        throw error;
      });
  }

  // Common Export Function for Exporting Excel And PDF File **********
  
  exportFile(res , exportAs){
    if(res?.isSuccess){
      if(exportAs == ExportAs.PDF ){
      this.reportService?.handleResponcePrint(res);
      }else{
      this.excelConverterServiceService?.downloadXlsx(res?.response);
      }
      this.toaster.success(res?.message);
    }else{
      this.toaster.error(res?.errors[0]);
    }
  }
  
}
