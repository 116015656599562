<div class="invoice_total h-100 d-flex flex-column px-0">
   
    <div class="col-12 d-flex justify-content-between my-1" *ngIf="totalSectionModel?.is_Estimate_Percent">
        <div class="d-flex col-4 align-items-center">
            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Estimate %</label>
        </div>
        <div class="d-flex col-8 justify-content-end">
            <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'estimate_percent'" [decimalPlaces]="2"
                [inputType]="'text'" (keydown.enter)="onKeydown($event, 'paid_amt1')" [inputId]="'estimate_percent'"
                [inputValue]="formModel?.invoiceModel?.estimatePercent || '0'"
                (inputValueEmit)="formModel.invoiceModel.estimatePercent = $event">
            </app-input>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-between my-1" *ngIf="totalSectionModel?.isFinal_Amount">
        <div class="d-flex col-4 align-items-center">
            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Final Amount</label>
        </div>
        <div class="d-flex col-8 justify-content-end">
            <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'Final_amount'" [decimalPlaces]="2"
                [inputType]="'text'" (keydown.enter)="onKeydown($event, 'paid_amt1')" [inputId]="'Final_amount'"
                [inputValue]="formModel?.invoiceModel?.finalAmount || '0'"
                (inputValueEmit)="formModel.invoiceModel.finalAmount = $event" [readOnly]="true">
            </app-input>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-between my-1" *ngIf="totalSectionModel?.isEstimate_Amount">
        <div class="d-flex col-4 align-items-center">
            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Estimate Amount</label>
        </div>
        <div class="d-flex col-8 justify-content-end">
            <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'Estimate_amount'" [decimalPlaces]="2"
                [inputType]="'text'" (keydown.enter)="onKeydown($event, 'paid_amt1')" [inputId]="'Estimate_amount'"
                [inputValue]="formModel?.invoiceModel?.estimateAmount || '0'"
                (inputValueEmit)="formModel.invoiceModel.estimateAmount = $event" [readOnly]="true">
            </app-input>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-between my-1" *ngIf="totalSectionModel?.isLoan_Amount">
        <div class="d-flex col-4 align-items-center">
            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Loan Amount</label>
        </div>
        <div class="d-flex col-8 justify-content-end">
            <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'Loan_amount'" [decimalPlaces]="2"
                [inputType]="'text'" (keydown.enter)="onKeydown($event, 'paid_amt1')" [inputId]="'Loan_amount'"
                [inputValue]="formModel?.invoiceModel?.loanAmount || '0'"
                (inputValueEmit)="formModel.invoiceModel.loanAmount = $event">
            </app-input>
        </div>
    </div>
    <div class="invoice_previous_balance_section py-1 px-2 flex-fill" *ngIf="totalSectionModel.showPreviousBalance">
        <div class="col-12 d-flex justify-content-between">
            <div class="text-gray-color font-bold f-12">
                Previous Balance
            </div>
        </div>
        <hr class="my-1" *ngIf="totalSectionModel.isLineVisible">
           <div class="row">
            <div class="col-4">
                <div class="text-gray-color font-bold f-12">
                    Amount
                </div>
            </div>
            <div class="col-4">
                <div class="text-gray-color font-bold f-12">
                    Gold
                </div>
            </div>
            <div class="col-4">
                <div class="text-gray-color font-bold f-12">
                    Silver
                </div>
            </div>
           </div>
           <div class="row">
            <div class="col-4">
                <div class="text-primary font-bold f-12">
                    {{formModel.amountBalance}}
                </div>
            </div>
            <div class="col-4">
                <div class="text-primary font-bold f-12">
                    {{formModel.goldBalance}}
                </div>
            </div>
            <div class="col-4">
                <div class="text-primary font-bold f-12">
                    {{formModel.silverBalance}}
                </div>
            </div>
           </div>
    </div>
    <div class="invoice_total_section py-1 px-2 flex-fill" *ngIf="!hideSection">
        <form class="h-100">
            <div class="col-12 d-flex justify-content-between" *ngIf="!totalSectionModel?.isHideTotal">
                <div class="text-gray-color font-bold f-14">
                    Total
                </div>
                <div class="d-flex text-red font-bold f-14  p-1 pe-2">
                    <span>{{formModel?.invoiceModel?.totalAmount || '0' }}</span>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between align-items-baseline"
                *ngIf="totalSectionModel?.isAdditional_Amt">
                <div class="d-flex col-4">
                    <label for="" (click)="openAdditionalAmount()"
                        class="text-underline text-primary cursorPointer font-semibold f-12">Additional Amt</label>
                </div>
                <div class="d-flex font-bold f-14 p-1 pe-2">
                    <span>{{formModel?.invoiceModel?.additionalAmount || '0' }}</span>
                </div>
                <!-- <div class="d-flex col-8 justify-content-end">
                    <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'additional_amt'" [inputType]="'text'"
                        [decimalPlaces]="2" (keydown.enter)="onKeydown($event, 'reward')" [inputId]="'additional_amt'"
                        [inputValue]="formModel?.invoiceModel?.additionalAmount || '0'"
                        (inputValueEmit)="formModel.invoiceModel.additionalAmount = $event" [readOnly]="true">
                    </app-input>
                </div> -->
            </div>
         
            <div class="col-12 d-flex justify-content-between align-items-baseline"
                *ngIf="totalSectionModel?.isNetTotal">
                <div class="d-flex col-4 align-items-center">
                    <label for="" class="font-semibold mb-0 f-12 pb-0">Net Total</label>
                </div>
                <div class="d-flex font-bold f-14 p-1 pe-2">
                    <span>{{formModel?.invoiceModel?.netAmount || '0'}}</span>
                </div>
            </div>
            <hr class="my-1" *ngIf="totalSectionModel.isLineVisible">

            <div class="col-12 d-flex justify-content-between" *ngIf="totalSectionModel?.isGen_Reward_Pt_Amt">
                <div class="d-flex col-4 align-items-center">
                    <label for="" class="text-gray-color font-regular mb-0 f-12">Reward Pt.</label>
                </div>
                <div class="d-flex mb-1 gap-1">
                    <div class="d-flex f-12  p-1 pe-2">
                        <span>{{formModel?.invoiceModel?.genRewardPt || '0'}} = {{formModel?.invoiceModel?.genRewardPt || '0'}}</span>
                    </div>
                    <!-- <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'reward'" [decimalPlaces]="2"
                        [inputType]="'text'" [inputId]="'reward'"
                        [inputValue]="formModel?.invoiceModel?.genRewardPt || '0'"
                        (inputValueEmit)="formModel.invoiceModel.genRewardPt = $event"
                        (keydown.enter)="onKeydown($event, 'reward2')" [readOnly]="true">
                    </app-input>
                    <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'reward2'" [inputType]="'text'"
                        [inputId]="'reward2'" [decimalPlaces]="2" (keydown.enter)="onKeydown($event, 'Discount')"
                        [inputValue]="formModel?.invoiceModel?.genRewardAmount || '0'"
                        (inputValueEmit)="formModel.invoiceModel.genRewardAmount = $event" [readOnly]="true">
                    </app-input> -->
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between" *ngIf="totalSectionModel?.isDiscount">
                <div class="d-flex col-4 align-items-center">
                    <label for="" class="text-gray-color font-regular mb-0 f-12 pb-0">Discount</label>
                </div>
                <div class="d-flex mb-1 gap-1">
                    <div class="d-flex mb-0 gap-1">
                        <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'Discount'" [inputType]="'text'"
                            [inputId]="'Discount'" [decimalPlaces]="2" [inputValue]=" '0'"
                            (keydown.enter)="onKeydown($event, 'Discount1')" [readOnly]="true">
                        </app-input>
                        <app-input [applyDecimalOrPercent]="true" [inputClass]="'text-end'" [inputName]="'Discount1'"
                            [inputType]="'text'" (keydown.enter)="onKeydown($event, 'Redeem')" [inputId]="'Discount1'"
                            [inputValue]="formModel?.invoiceModel?.invoiceDiscount || '0'"
                            (inputValueEmit)="formModel.invoiceModel.invoiceDiscount = $event ? $event :'0';getCalculatedInvoiceDetails(undefined,'invoiceDiscount')">
                        </app-input>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between" *ngIf="totalSectionModel?.isRedeem_Pt">
                <div class="d-flex col-4 align-items-center">
                    <label for="" class="font-semibold mb-0 f-12 pb-0">Redeem Pt.</label>
                </div>
                <div class="d-flex mb-1 gap-1">
                    <div class="d-flex mb-0 gap-1">
                        <app-input [applyDecimalOrPercent]="true" [inputClass]="'text-end'" [inputName]="'Redeem'"
                            [inputType]="'text'" [inputId]="'Redeem'" (keydown.enter)="onKeydown($event, 'Redeem1')"
                            [inputValue]="formModel?.invoiceModel?.redeemPt || '0'"
                            (inputValueEmit)="formModel.invoiceModel.redeemPt = $event;getCalculatedInvoiceDetails(undefined,'redeemPt');">
                        </app-input>
                        <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'Redeem1'" [inputType]="'text'"
                            [decimalPlaces]="2" (keydown.enter)="onKeydown($event, 'isRoundOFF')" [inputId]="'Redeem1'"
                            [inputValue]="formModel?.invoiceModel?.redeemAmount || '0'"
                            (inputValueEmit)="formModel.invoiceModel.redeemAmount = $event" [readOnly]="true">
                        </app-input>
                    </div>
                </div>
            </div>
            <div class="d-flex p-1 f-12 justify-content-end" *ngIf="totalSectionModel?.isRedeem_Pt_Balance">
                <span class="text-gray-color font-regular">Reedem Point Bal:</span>
                <span class="text-red font-regular ">{{formModel?.invoiceModel?.redeemBalancePoint}}</span>
            </div>
            <hr class="my-1" *ngIf="totalSectionModel?.isLineVisible">
            <!-- <div class="col-12 d-flex justify-content-between mb-1" *ngIf="totalSectionModel?.isCurrency_Exchange">
                <div class="d-flex col-4 align-items-center">
                    <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Currency Exchange</label>
                </div>
                <div class="d-flex col-8 justify-content-end gap-1">
                    <div class="col-6 ps-1">
                        <app-select [options]="currencyExchangeDropdown"
                            [selectedValue]="formModel?.invoiceModel?.exchangeRateId"
                            (valueChanged)="formModel.invoiceModel.exchangeRateId=$event;getCalculatedInvoiceDetails(undefined,'exchangeRateId')"
                            [id]="'currencyExchange'" [name]="'currencyExchange'" [isDisabled]="false"
                            [isRequired]="false" (keydown.enter)="onKeydown($event, 'currencyExchange1')"
                            currencyExchange1>
                        </app-select>
                    </div>
                    <div class="col-6">
                        <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'currencyExchange1'"
                            [decimalPlaces]="2" [inputType]="'text'" (keydown.enter)="onKeydown($event, 'R_invoice')"
                            [inputId]="'currencyExchange1'"
                            (inputValueEmit)="formModel.invoiceModel.currencyExchangeAmount=$event"
                            [inputValue]="formModel?.invoiceModel?.currencyExchangeAmount || '0'" [readOnly]="true">
                        </app-input>
                    </div>
                </div>
            </div> -->
            <div class="col-12 d-flex justify-content-between" *ngIf="totalSectionModel?.isGrandTotal">
                <div class="text-gray-color font-bold f-14">
                    Grand Total
                </div>
                <div class="d-flex text-red font-bold f-14  p-1 pe-2">
                    <span>{{formModel?.invoiceModel?.grandTotal || '0' }}</span>
                </div>
            </div>

            <div class="panel">
                <div class="panel-header">
                    <div class="col-12 d-flex justify-content-between" *ngIf="totalSectionModel?.isAdvancePayment">
                        <div class="text-gray-color font-bold f-12">
                            Advance Payment <label for="" (click)="toggleCollapse()"
                                class="text-underline text-primary cursorPointer font-semibold f-12">(show
                                details)</label>
                        </div>
                        <div class="d-flex  font-bold f-14 pe-2 p-1">
                            <span>{{formModel?.invoiceModel?.advancePayment || '0'}}</span>
                        </div>
                    </div>
                </div>
                <div class="panel-content" [ngClass]="{ 'collapsed': isCollapsed }">
                    <div ngClass="border-gray-color" *ngIf="formModel?.invoiceModel?.advancePayment != 0">
                        <div class="col-12 d-flex justify-content-between align-items-baseline"
                            *ngIf="totalSectionModel?.isAdvancePayment">
                            <div class="d-flex col-4">
                                <label for="" class="text-primary cursorPointer font-semibold f-12">From Sale
                                    Order</label>
                            </div>
                            <div class="d-flex f-12 pe-2 p-1">
                                <span>{{formModel?.invoiceModel?.saleOrderAdvancePayment || '0'}}</span>
                            </div>
                        </div>

                        <div class="col-12 d-flex justify-content-between align-items-baseline"
                            *ngIf="formModel?.invoiceModel?.totalFundTransferAmount != 0">
                            <div class="d-flex">
                                <label for="" (click)="openFundTransferPopup()"
                                    class="text-underline text-primary cursorPointer font-semibold f-12">From Fund
                                    ({{formModel?.invoiceModel?.totalFundTransferAmount}})</label>
                            </div>
                            <div class="d-flex text-primary font-bold f-12 pe-2 p-1">
                                <span>{{formModel?.invoiceModel?.fundTransferAmount || '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-12 d-flex justify-content-between" *ngIf="totalSectionModel?.isMetalAmmount">
                <div class="d-flex col-4 align-items-center">
                    <label for="" class="font-semibold mb-0 f-12 pb-0">Metal Amt</label>
                </div>
                <div class="d-flex font-bold f-14 pe-2 p-1">
                    <span>{{formModel?.invoiceModel?.metalAmount || '0'}}</span>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between align-items-center mb-1"
                *ngIf="totalSectionModel?.isRound_Off_For_GrandTotal">
                <div class="d-flex col-4 align-items-center">
                    <label for="" class="text-gray-color font-regular mb-0 f-12">Round Off</label>
                </div>
                <div class="col-8 d-flex align-items-center gap-1">
                    <div class="col-1 d-flex">
                        <app-checkbox (check)="roundOFF=$event;onRoundOffCheck(roundOFF,$event)" [status]=""
                            [disabled]="false"></app-checkbox>
                    </div>
                    
                    <div class="col-11 pe-1">
                        <app-input [readOnly]="!roundOFF" [applyDecimalOrPercent]="true" [inputClass]="'text-end'"
                            [inputName]="'isRoundOFF'" [inputType]="'text'" [inputId]="'isRoundOFF'"
                            [inputValue]="formModel?.invoiceModel?.roundOFFValue || '0'"
                            (inputValueEmit)="formModel.invoiceModel.roundOFFValue = $event;getCalculatedInvoiceDetails(undefined,'roundOFFValue');"
                            (keydown.enter)="onKeydown($event, 'currencyExchange')">
                        </app-input>
                    </div>
                </div>
            </div>


            <div class="col-12 d-flex justify-content-between align-items-baseline"
            *ngIf="totalSectionModel?.isReturn_Invoice">
            <div class="d-flex col-4 align-items-center">
                <label for="" class="font-semibold mb-0 f-12 pb-0">Return Invoice</label>
            </div>
            <div class="d-flex text-primary font-bold f-14 pe-2  p-1">
                <span>{{formModel?.invoiceModel?.returnAmount || '0'}}</span>
            </div>
        </div>
            <!-- <div class="col-12 d-flex justify-content-between mb-1">
                <div class="d-flex col-4">
                    <label for="" class="text-underline text-primary font-semibold f-12">Previous</label>
                </div>
                <div class="d-flex col-8 justify-content-end">
                    <app-input 
                        onlyNumbers 
                        [inputClass]="'text-end'" 
                        [inputName]="''"
                        [inputType]="'text'" 
                        [decimalPlaces]="2"
                        (keydown.enter)="onKeydown($event, 'paid_amt')" 
                        [inputId]="'R_invoice2'"
                        [inputValue]="'0.00'" 
                        [disabled]="true"
                        >
                    </app-input>
                </div>
            </div> -->

            <div class="col-12 d-flex justify-content-between mb-1" *ngIf="totalSectionModel.isCrediNote_Amt">
                <div class="d-flex col-4">
                    <label for="" class="text-primary font-semibold f-12">Credit Note</label>
                </div>
                <div class="d-flex col-8 justify-content-end">
                    <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'paid_amt'" [inputType]="'text'"
                        [decimalPlaces]="2" (keydown.enter)="onKeydown($event, 'paid_amt')" [inputId]="'paid_amt'"
                        [inputValue]="formModel?.invoiceModel?.creditNoteAmount || '0'"
                        (inputValueEmit)="formModel.invoiceModel.creditNoteAmount = $event">
                    </app-input>
                </div>
            </div>

            <!-- <div class="col-12 d-flex justify-content-between align-items-baseline mb-1"
                *ngIf="totalSectionModel.isPaid_Amt">
                <div class="d-flex col-4">
                    <label for="" class="text-underline text-primary font-semibold f-12 mb-0 pb-0">Paid Amt</label>
                </div>
                <div class="d-flex col-8 justify-content-end">
                    <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'paid_amt'" [decimalPlaces]="2"
                        (keydown.enter)="onKeydown($event, 'paid_amt1')" [inputType]="'text'" [inputId]="'paid_amt'"
                        [inputValue]="formModel?.invoiceModel?.amountPaid || '0'"
                        (inputValueEmit)="formModel.invoiceModel.amountPaid = $event" [readOnly]="true">
                    </app-input>
                </div>
            </div> -->
            <div class="col-12 d-flex justify-content-between align-items-baseline"
            *ngIf="totalSectionModel?.isPaid_Amt">
            <div class="d-flex col-4 align-items-center">
                <label for="" class="font-semibold mb-0 f-12 pb-0">Paid Amt</label>
            </div>
            <div class="d-flex text-primary font-bold f-14 pe-2 p-1">
                <span>{{formModel?.invoiceModel?.amountPaid || '0'}}</span>
            </div>
        </div>

        <div class="col-12 d-flex justify-content-between align-items-baseline"
        *ngIf="totalSectionModel?.isBalance_Amt">
        <div class="d-flex col-4 align-items-center">
            <label for="" class="font-semibold mb-0 f-12 pb-0">Balance Amt</label>
        </div>
        <div class="d-flex font-bold f-14 pe-2 p-1">
            <span>{{formModel?.invoiceModel?.balanceAmount || '0'}}</span>
        </div>
    </div>
            <!-- <div class="col-12 d-flex justify-content-between align-items-baseline mb-0"
                *ngIf="totalSectionModel.isBalance_Amt">
                <div class="d-flex col-4">
                    <label for="" class="text-underline text-primary font-semibold f-12 mb-0 pb-0">Balance Amt</label>
                </div>
                <div class="d-flex col-8 justify-content-end">
                    <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'paid_amt1'" [inputType]="'text'"
                        [inputId]="'paid_amt1'" [decimalPlaces]="2"
                        [inputValue]="formModel?.invoiceModel?.balanceAmount || '0'"
                        (inputValueEmit)="formModel.invoiceModel.balanceAmount = $event;" [readOnly]="true">
                    </app-input>
                </div>
            </div> -->

            <div class="col-12 d-flex  justify-content-between align-items-baseline mb-0"
                *ngIf="totalSectionModel.isDiscount_Amt">
                <div class="d-flex col-4">
                    <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Discount</label>
                </div>
                <div class="d-flex col-8 justify-content-end">
                    <app-input onlyNumbers [inputClass]="'text-end'" [inputName]="'discount_amt1'" [inputType]="'text'"
                        [inputId]="'discount_amt1'" [decimalPlaces]="2"
                        [inputValue]="formModel?.invoiceModel?.discountReturmAmount || '0'"
                        (inputValueEmit)="formModel.invoiceModel.discountReturmAmount = $event?$event:'0';"
                        [readOnly]="true">
                    </app-input>
                </div>
            </div>
        </form>
    </div>
</div>