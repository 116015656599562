import { AmountColWidth, BarcodeColWidth, MetalGridMoreButtonId, NameColWidth, ProductColWidth, QuantityColWidth, StorageName, VoucherColWidth, WeightColWidth } from "../../enum/common-enum";
import { DiamondCategory, MetalType } from "../../models/common.model";
import { StorageService } from "../../services/storage.service";
import { AgGridCustomDropdownComponent } from "../ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { AgGridCustomInputComponent } from "../ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { AgColDefsI, AgGridI } from "../ag-grid/ag-grid.model";
import { CheckboxRendererComponent } from "../ag-grid/checkbox-renderer/checkbox-renderer.component";
import { CustomHeaderComponent } from "../ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "../ag-grid/edit-delete-icon/edit-delete-icon.component";
import { TextRendererComponent } from "../ag-grid/text-renderer/text-renderer.component";

export enum MetalTabs {
  Gold    = 1,
  Silver  = 2,
  Diamond = 4,
  StoneGems = 5,
  ImmitationAndWatches = 6, 
} 

//#region set dynamic column
const UDF1DynamicColumn = (metalId) => {
  return {
    ...UDF1,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF1_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId);
        return  dropdownValues ??[]
      },
    },

    // isVisibleOnColumnSetting : visibleOncolumnSetting(metalId , "UDF1")
  };
};

const  visibleOncolumnSetting = (metalId , fieldName)=> {
  var storageUDFs =  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN);
  const metalIdWiseUdf = storageUDFs?.filter((element)=>element?.extra2 == metalId );
  const udf1 = metalIdWiseUdf?.find(a=>a.id == fieldName);
  const isVisible = udf1?.extra1  =='0'? true : false;
  return true
}

const UDF2DynamicColumn = (metalId) => {
  return {
    ...UDF2,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF2_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId);

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF3DynamicColumn = (metalId) => {
  return {
    ...UDF3,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF3_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF4DynamicColumn = (metalId) => {
  return {
    ...UDF4,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF4_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF5DynamicColumn = (metalId) => {
  return {
    ...UDF5,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF5_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF11DynamicColumn = (metalId) => {
  return {
    ...UDF11,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF11_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF12DynamicColumn = (metalId) => {
  return {
    ...UDF12,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF12_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF13DynamicColumn = (metalId) => {
  return {
    ...UDF13,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF13_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF14DynamicColumn = (metalId) => {
  return {
    ...UDF14,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF14_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF15DynamicColumn = (metalId) => {
  return {
    ...UDF15,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF15_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

const UDF21DynamicColumn = (metalId) => {
  return {
    ...UDF21,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF21_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF22DynamicColumn = (metalId) => {
  return {
    ...UDF22,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF22_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF23DynamicColumn = (metalId) => {
  return {
    ...UDF23,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF23_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF24DynamicColumn = (metalId) => {
  return {
    ...UDF24,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF24_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF25DynamicColumn = (metalId) => {
  return {
    ...UDF25,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF25_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF26DynamicColumn = (metalId) => {
  return {
    ...UDF26,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF26_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF27DynamicColumn = (metalId) => {
  return {
    ...UDF27,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF27_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF28DynamicColumn = (metalId) => {
  return {
    ...UDF28,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF28_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF29DynamicColumn = (metalId) => {
  return {
    ...UDF29,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF29_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};
const UDF30DynamicColumn = (metalId) => {
  return {
    ...UDF30,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const udfDropAllData = storageService.retrieve(storage.UDF30_DROPDOWN);
         const dropdownValues =  udfDropAllData?.filter((x)=>x?.extra1 == metalId)

        return  dropdownValues ??[]
      },
    },
  };
};

//#endregion set dynamic column

const IdColumn: AgColDefsI = {
  headerName: "Id",
  field: "id",
  sortable: false,
  hide : true,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  minWidth: AmountColWidth.MinWidth,
  maxWidth:AmountColWidth.MaxWidth,
  flex: 1,
};

const PurchaseAmountColumn: AgColDefsI = {
  headerName: "Purchase Amount",
  field: "purchaseAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:8
  },
}

const SaleAmountColumn: AgColDefsI = {
  headerName: "Sale Amount",
  field: "saleAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:8
  },
}

const VoucherTypeIdColumn: AgColDefsI = {
  headerName: "voucherTypeId",
  field: "voucherTypeId",
  sortable: false,
  hide : true,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  minWidth: VoucherColWidth.MinWidth,
  maxWidth: VoucherColWidth.MaxWidth,
  flex: 1,
};

export const CheckboxColumn: AgColDefsI = {
  headerName: "",
  headerComponentParams: { selectAll : true },
  rowDrag: false,
  field: "active",   
  resizable: false, 
  pinned: "left",
  suppressNavigable: true,
  //lockPinned: true,
  lockPosition: true,
  cellClass: "custom-column-group-cell",
  cellRendererParams: { showLabel: false}, 
  cellRenderer:CheckboxRendererComponent  ,
  headerClass:" justify-content-center custom-column-group-cell px-0  border-0",
  minWidth: 80,
  maxWidth: 80,
  editable:false, 
};


//#region  Interfaces Gold and Silver
const ReverseColumn: AgColDefsI = {
  headerName: "Reverse",
  headerComponentParams: { isRequired: false , enableSorting: false },
  field: "reverseCalculationAmount",
  sortable: true,
  minWidth: 100,
  editable: true,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
};

const BarcodeColumn: AgColDefsI = {
  headerName: "Barcode No.",
  headerComponentParams: { isRequired: false , enableSorting: false },
  field: "barcodeNo",
  sortable: true,
  minWidth: BarcodeColWidth.MinWidth, 
  maxWidth: BarcodeColWidth.MaxWidth,
  editable: true,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
};

const HUIDColumn: AgColDefsI = {
  headerName: "HUID No.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "huidNo",
  sortable: true,
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  editable: true,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent
};

export const ProductColumn: AgColDefsI = {
  headerName: "Product",
  field: "productDetailId",
  minWidth: ProductColWidth.MinWidth,
  maxWidth: ProductColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.PRODUCT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  headerComponentParams:{
    filterValueColumnName: "productId",
    filterIdColumnName: "productId",
    filterParams: StorageName.PRODUCT_DROPDOWN,
    floatingMultiselectDropDown: true,
    isRequired: true, enablePlusIconLight: true , enableSorting: false
},
};

const SubCategoryColumn: AgColDefsI = {
  headerName: "Category",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "subCategoryId",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      var data = storageService.retrieve(storage.CATEGORY_DROPDOWN);
      return data;
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const LocationColumn: AgColDefsI = {
  headerName: "Location",
  field: "locationId",
  headerComponentParams:{enablePlusIconLight: true },
  minWidth: 110,
  maxWidth: 110,
  sortable: true,
  editable: true,
  cellClass:"custom-column-group-cell px-0 f-12 text-gray-color font-regular",
  headerClass:"custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.LOCATION_DROPDOWN);
    },
  },
};

const CaretColumn: AgColDefsI = {
  headerName: "Carat",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "caratId",
  colId:'caratId', // this is for custom changes..
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const RequestedWtColumn: AgColDefsI = {
  headerName: "Requested Wt.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "requestedWt",
  sortable: true,
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const RequestedPurityColumn: AgColDefsI = {
  headerName: "Requested Purity",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "requestedPurityPer",
  sortable: true,
  minWidth: 110,
  maxWidth:120,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const AlloyWtColumn: AgColDefsI = {
  headerName: "Alloy Wt.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "alloyWt",
  sortable: true,
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};


export const PurityColumn: AgColDefsI = {
  headerName: "Purity",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "purityPer",
  sortable: true,
  minWidth: 110,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const PurityWtColumn: AgColDefsI = {
  headerName: "Purity Wt.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "purityWt",
  sortable: true,
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const DiamondCaratColumn: AgColDefsI = {
  headerName: "Carat",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "carat",
  sortable: true,
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const WastagePerColumn: AgColDefsI = {
  headerName: "Wastage Per.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "wastagePer",
  sortable: true,
  minWidth: 120,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:5
  }
};

export const WastageWtColumn: AgColDefsI = {
  headerName: "Wastage Wt.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "wastageWt",
  sortable: true,
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const QuantityColumn: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  headerComponentParams: { isRequired: true , enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:0,
    isOnlyNumbers:true,
  },
  valueGetter: (param) => {
    return param.data.quantity !== undefined && param.data.quantity !== null? param.data.quantity: 1;
  }
};

const PktWtColumn: AgColDefsI = {
  headerName: "Pkt. Wt.",
  field: "packetWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerComponentParams: { enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const PktLessWtColumn: AgColDefsI = {
  headerName: "PKt. Less Wt.",
  field: "packetLessWt",
  headerComponentParams: { isRequired: false, enableSorting: false },
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const GrossWtColumn: AgColDefsI = {
  headerName: "Gross Wt.",
  field: "grossWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

const GoldLossWtColumn: AgColDefsI = {
  headerName: "Gold Loss Wt.",
  field: "goldLossWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false },
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  }
};

const GoldLossValueColumn: AgColDefsI = {
  headerName: "Gold Loss Value",
  field: "goldLossValue",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false },
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  }
};

const SettingChargeColumn: AgColDefsI = {
  headerName: "Setting Charge",
  field: "settingCharge",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false },
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  }
};

const SettingChargeAmountColumn: AgColDefsI = {
  headerName: "Setting Charge Amount",
  field: "settingChargeAmount",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false },
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  }
};

const MarkUpAmountColumn: AgColDefsI = {
  headerName: "Mark Up Amount",
  field: "markUpAmount",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false },
  cellEditorParams: {
    decimalPlaces: 3,
    isOnlyNumbers: true,
    inputMaxlength: 8
  }
};


export const WeightColumn: AgColDefsI = {
  headerName: "Weight",
  field: "weight",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

export const DiamondWeightColumn: AgColDefsI = {
  headerName: "D.Weight",
  field: "weight",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

export const StoneWeightColumn: AgColDefsI = {
  headerName: "S.Weight",
  field: "weight",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

const GoldWtColumn: AgColDefsI = {
  headerName: "Gold Wt",
  field: "goldWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

const MinimumPrice: AgColDefsI = {
  headerName: "Minimum Price",
  field: "minimumPrice",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const GoldPurityPerColumn: AgColDefsI = {
  headerName: "Gold Purity %",
  field: "goldPurityPer",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

const GoldPurityWtColumn: AgColDefsI = {
  headerName: "Gold Purity Wt",
  field: "goldPurityWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

const GoldRateColumn: AgColDefsI = {
  headerName: "Gold Rate",
  field: "goldRate",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

// const StoneWtColumn: AgColDefsI = {
//   headerName: "Stone Wt",
//   field: "stoneWt",
//   minWidth: WeightColWidth.MinWidth,
//   maxWidth:WeightColWidth.MaxWidth,
//   headerClass: "custom-column-group-cell px-0",
//   cellClass: "custom-column-group-cell",
//   editable: true,
//   sortable: true,
//   cellRenderer: TextRendererComponent,
//   cellEditor:AgGridCustomInputComponent,
//   headerComponentParams: { enableSorting: false},
//   cellEditorParams:{
//     decimalPlaces:3,
//     isOnlyNumbers:true,
//     inputMaxlength:8
//   }
// }


const DiamondWtColumn: AgColDefsI = {
  headerName: "Diamond Wt",
  field: "diamondWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
export const LessWtColumn: AgColDefsI = {
  headerName: "Less Wt.",
  field: "lessWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  headerComponentParams: { enableSorting: false},
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const NetWtColumn: AgColDefsI = {
  headerName: "Net Wt.",
  field: "netWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  headerComponentParams: { enableSorting: false},
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const FinalWtColumn: AgColDefsI = {
  headerName: "Final Wt.",
  field: "finalWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  headerComponentParams: { enableSorting: false},
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const RateColumn: AgColDefsI = {
  headerName: "Rate",
  field: "rate",
  minWidth: 100,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

const RatePerColumn: AgColDefsI = {
  headerName: "Rate %.",
  field: "ratePer",
  minWidth: 100,
  headerComponentParams: { isRequired: true  ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

export const AmountColumn: AgColDefsI = {
  headerName: "Amount",
  field: "amount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

export const MetalValueColumn: AgColDefsI = {
  headerName: "Metal Value",
  field: "metalValue",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};


export const GoldAmountColumn: AgColDefsI = {
  headerName: "Gold Amount",
  field: "goldAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentFramework : 
    'app-custom-header'
  ,
  headerComponentParams: { enableSorting: false},
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  },
  editable: (params) => {
    if(params.data.diamondCategoryId == DiamondCategory.Diamonds || params.data.diamondCategoryId == DiamondCategory.GemStones){
      return false;
    }else{
      return true;
    }
},
};

// const GoldWeightColumn: AgColDefsI = {
//   headerName: "Gold Weight",
//   field: "goldWt",
//   minWidth: 100,
//   headerClass: "custom-column-group-cell px-0",
//   cellClass: "custom-column-group-cell",
//   headerComponentParams: { enableSorting: false},
//   sortable: true,
//   cellRenderer: TextRendererComponent,
//   cellEditor:AgGridCustomInputComponent,
//   cellEditorParams:{
//     decimalPlaces:2,
//     isOnlyNumbers:true,
//   },
//   editable: (params) => {
//     if(params.data.diamondCategoryId == DiamondCategory.LooseDiamond || params.data.diamondCategoryId == DiamondCategory.CeritifiedDiamond){
//       return false;
//     }else{
//       return true;
//     }
// },
// };

const MakingColumn: AgColDefsI = {
  headerName: "Making",
  field: "making",
  marryChildren: true,
  sortable: true,
  // isRequired: true,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      headerName: "Type",
      field: "makingTypeId",
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell px-0",  
      minWidth: 80,
      editable: true,
      sortable: true,
      cellRenderer: TextRendererComponent,
      cellEditor: AgGridCustomDropdownComponent,
      headerComponentParams: {enableSorting: false},
      cellEditorParams: {
        values: () => {
          const storageService = new StorageService();
          const storage = StorageName;
          return storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
        },
      },
     valueGetter: (param) => 
        {
          const storageService = new StorageService();
          const storage = StorageName;
          const storeData =  storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
          const selcetedId = storeData?.find(a => a.id == param.data.makingTypeId);
          return selcetedId != undefined && selcetedId.id !== undefined && selcetedId.id !== null? param.data.makingTypeId : 2;
       }
    },
    {
      field: "makingRate",
      cellClass: "customcolumn-group-cell px-0",
      headerName: "Rate",
      minWidth: 100,
      // editable: true,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:3,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        if(params.data.makingTypeId == '1' || params.data.makingTypeId == '6' ){
          return false;
        }else{
          return true;
        }
        // return params.data.makingTypeId != '1'
    },
    },
    {
      field: "makingDiscount",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Discount",
      minWidth: 120,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      },
      editable: (params) => 
      {
        if(params.data.makingTypeId == '6' ){
          return false;
        }else{
          return true;
        }
    },
    },
    {
      field: "makingAmount",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Amount",
      minWidth: 120,
      // editable: false,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        return params.data.makingTypeId == '1'
    },
    },
  ],
};


const DiamondMakingColumn: AgColDefsI = {
  headerName: "Making",
  field: "making",
  marryChildren: true,
  sortable: true,
  // isRequired: true,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      headerName: "Type",
      field: "makingTypeId",
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell px-0",  
      minWidth: 80,
      editable: true,
      sortable: true,
      cellRenderer: TextRendererComponent,
      cellEditor: AgGridCustomDropdownComponent,
      headerComponentParams: {enableSorting: false},
      cellEditorParams: {
        values: () => {
          const storageService = new StorageService();
          const storage = StorageName;
          return storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
        },
      },
     valueGetter: (param) => 
        {
          const storageService = new StorageService();
          const storage = StorageName;
          const storeData =  storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
          const selcetedId = storeData?.find(a => a.id == param.data.makingTypeId);
          return selcetedId != undefined && selcetedId.id !== undefined && selcetedId.id !== null? param.data.makingTypeId : 2;
       }
    },
    {
      field: "makingRate",
      cellClass: "customcolumn-group-cell px-0",
      headerName: "Rate",
      minWidth: 100,
      // editable: true,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:3,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        return params.data.makingTypeId != '1'
    },
    },
    {
      field: "makingAmount",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Amount",
      minWidth: 120,
      // editable: false,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        return params.data.makingTypeId == '1'
    },
    },
  ],
};



const DiamondAmount: AgColDefsI = {
  headerName: "Diamond Amount",
  field: "diamondAmount",
  minWidth: 140,
  headerComponentParams: { isRequired: false ,enableSorting: false },
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const NetAmountColumn: AgColDefsI = {
  headerName: "Net Amt",
  field: "netAmount",
  minWidth: 100, 
  headerComponentParams: { isRequired: false ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

const NetAmountWithTaxColumn: AgColDefsI = {
  headerName: "Net Amt+Tax",
  field: "netAmountWithTax",
  minWidth: 100,
  headerComponentParams: { isRequired: false  ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};

const HMColumn: AgColDefsI = {
  headerName: "Hall Mark",
  field: "hallMark",
  marryChildren: true,
  sortable: true,
  // isRequired: true,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      field: "hallmarkAmount",
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Amount",
      minWidth: 90,
      // editable: true,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:3,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        return params.data.hallmarkRate == 0;
    },
    },
    {
      field: "hallmarkRate",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Rate",
      minWidth: 100,
      editable: true,
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      headerComponentParams: { enableSorting: false},
      cellEditorParams:{
        decimalPlaces:3,
        isOnlyNumbers:true,
      }
    },
  ],
};

// const ActionColumn: AgColDefsI = {
//   headerName: "",
//   pinned: "right",
//   suppressNavigable: true,
//   editable: false,
//   lockPinned: true,
//   headerComponent: CustomHeaderComponent,
//   headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
//   cellRenderer: EditDeleteIconComponent,
//   cellRendererFramework: EditDeleteIconComponent,
//   // Pass gridOptions to the cell renderer component
//   cellRendererParams: {
//     editIcon: true,
//     // tableId: StorageName.FORMNAME_TRANSACTION_SALESINVOICE_GOLD_TOP_TABLE,
//     isFirstDeleteIcon:true,
//     resetIcon:true
//   },
//   headerComponentParams: { enableMenu: false, moreButton: true ,enableSorting: false, selectAll : false },
//   cellClass: "mx-0 text-end px-1",
//   minWidth: 80,
//   maxWidth: 80,
//   extraField:"Action"
// };

const ActionColumn: AgColDefsI = {
  headerName: "",
  field: "action",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  // Pass gridOptions to the cell renderer component
  cellRendererParams: {
    editIcon: false,
    // tableId: StorageName.TRANSACTION_SALEINVOICE_IMMITATION_ITEM_GRID,
    isFirstDeleteIcon:true,
    resetIcon:true
  },
  headerComponentParams: { enableMenu: false,  moreButton: true , enableSorting: false, selectAll : false },
  cellClass: "mx-0 text-end px-1",
  minWidth: 80,
  maxWidth: 80,
  extraField:"Action"
};






const RFIDCode: AgColDefsI = {
  headerName: "RFIDCode",
  field: "rfid",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
};
const CertificateNumber: AgColDefsI = {
  headerName: "Certificate No.",
  field: "certificateNumber",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
};
const ItemCode: AgColDefsI = {
  headerName: "ItemCode",
  field: "itemCode",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
};
//#endregion



//#region Interfaces ********** Diamond Table start*******

const Clarity: AgColDefsI = {
  headerName: "Clarity",
  field: "clarityId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CLARITY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerComponentParams: { enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
};
export const CutId: AgColDefsI = {
  headerName: "Cut",
  field: "cutId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CUT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const UDF1: AgColDefsI = {
  headerName: "UDF1",
  field: "udF1",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF1_DROPDOWN);
    },
  },
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1" && a.extra1 == "1")?.length > 0,
  hide :true,
  isVisibleOnColumnSetting : true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1" && a.extra1 == "1")?.length > 0,

  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
export const UDF2: AgColDefsI = {
  headerName: "UDF2",
  field: "udF2",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF2_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2" && a.extra1 == "1")?.length > 0,

};
export const UDF3: AgColDefsI = {
  headerName: "UDF3",
  field: "udF3",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF3_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3" && a.extra1 == "1")?.length > 0,

};
export const UDF4: AgColDefsI = {
  headerName: "UDF4",
  field: "udF4",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF4_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4" && a.extra1 == "1")?.length > 0,

};
export const UDF5: AgColDefsI = {
  headerName: "UDF5",
  field: "udF5",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF5_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF6: AgColDefsI = {
  headerName: "UDF6",
  field: "udF6",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF7: AgColDefsI = {
  headerName: "UDF7",
  field: "udF7",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7" && a.extra1 == "1")?.length > 0,

};
export const UDF8: AgColDefsI = {
  headerName: "udF8",
  field: "udF8",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8" && a.extra1 == "1")?.length > 0,
};
export const UDF9: AgColDefsI = {
  headerName: "udF9",
  field: "udF9",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,

  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9" && a.extra1 == "1")?.length > 0,
};
export const UDF10: AgColDefsI = {
  headerName: "udF10",
  field: "udF10",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  },
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10" && a.extra1 == "1")?.length > 0,

};

export const UDF11: AgColDefsI = {
  headerName: "UDF11",
  field: "udF11",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF11_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF12: AgColDefsI = {
  headerName: "UDF12",
  field: "udF12",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF12_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF13: AgColDefsI = {
  headerName: "UDF13",
  field: "udF13",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF13_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF14: AgColDefsI = {
  headerName: "UDF14",
  field: "udF14",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF14_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF15: AgColDefsI = {
  headerName: "UDF15",
  field: "udF15",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF15_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};
export const UDF16: AgColDefsI = {
  headerName: "UDF16",
  field: "udF16",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF17: AgColDefsI = {
  headerName: "UDF17",
  field: "udF17",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF18: AgColDefsI = {
  headerName: "UDF18",
  field: "udF18",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF19: AgColDefsI = {
  headerName: "UDF19",
  field: "udF19",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF20: AgColDefsI = {
  headerName: "UDF20",
  field: "udF20",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF21: AgColDefsI = {
  headerName: "UDF21",
  field: "udF21",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF21_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};
export const UDF22: AgColDefsI = {
  headerName: "UDF22",
  field: "udF22",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF22_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF23: AgColDefsI = {
  headerName: "UDF23",
  field: "udF23",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF23_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF24: AgColDefsI = {
  headerName: "UDF24",
  field: "udF24",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF24_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF25: AgColDefsI = {
  headerName: "UDF25",
  field: "udF25",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF25_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF26: AgColDefsI = {
  headerName: "UDF26",
  field: "udF26",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF26_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF27: AgColDefsI = {
  headerName: "UDF27",
  field: "udF27",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF27_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF28: AgColDefsI = {
  headerName: "UDF28",
  field: "udF28",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF28_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF29: AgColDefsI = {
  headerName: "UDF29",
  field: "udF29",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF29_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF30: AgColDefsI = {
  headerName: "UDF30",
  field: "udF30",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF30_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

const CalculationTypeColumn: AgColDefsI = {
  headerName: "Calculation Type",
  field: "calculationType",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CALCULATION_TYPE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const DiamondStoneCategoryColumn: AgColDefsI = {
  headerName: "Category",
  field: "diamondCategoryId",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false , isRequired: true},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const ColorId: AgColDefsI = {
  headerName: "Color",
  field: "colorId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.COLOR_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const SeiveSizeId: AgColDefsI = {
  headerName: "SeiveSize",
  field: "seiveSizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SEIVE_SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
export const SizeId: AgColDefsI = {
  headerName: "Size",
  field: "sizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const ShapeId: AgColDefsI = {
  headerName: "Shape",
  field: "shapeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SHAPE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
// const StoneAmount: AgColDefsI = {
//   headerName: "Stone Amount",
//   field: "stoneAmount",
//   minWidth: 140,
//   headerComponentParams: { isRequired: false ,enableSorting: false},
//   headerClass: "custom-column-group-cell px-0",
//   cellClass: "custom-column-group-cell",
//   editable: true,
//   sortable: true,
//   cellRenderer: TextRendererComponent,
//   cellEditor:AgGridCustomInputComponent,
//   cellEditorParams:{
//     decimalPlaces:2,
//     isOnlyNumbers:true,
//   }
// };

const CertificateAmount: AgColDefsI = {
  headerName: "Certificate Amount",
  field: "certificateAmount",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const OtherAmount: AgColDefsI = {
  headerName: "Other Amount",
  field: "otherAmount",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const NetAmount: AgColDefsI = {
  headerName: "Net Amt",
  field: "netAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
};
const DiscountPer: AgColDefsI = {
  headerName: "Discount Per.",
  field: "discountPer",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};
const DiscountAmount: AgColDefsI = {
  headerName: "Discount Amount",
  field: "discountAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const TaxAmount: AgColDefsI = {
  headerName: "Tax",
  field: "taxAmount",
  minWidth: 140,
  headerComponentParams: { isRequired: false ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};


const TaxId: AgColDefsI = {
  headerName: "Tax",
  field: "taxId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.TAX_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const RemarkId: AgColDefsI = {
  headerName: "Remark",
  field: "remarkId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.REMARK_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
// const CommentColumn: AgColDefsI = {
//   headerName: "Comment",
//   field: "comment",
//   sortable: true,
//   headerClass:"custom-column-group-cell px-0",
//   minWidth: 100,
//   flex: 3,
//   cellClass: "custom-column-group-cell px-0",
//   editable:true,
//   resizable: false,
//   cellRenderer: TextRendererComponent,
//   headerComponentParams: { enableSorting: false},
// };


//#endregion

//#region  Definition for Diamond 
const GoldCaratColumn: AgColDefsI = {
  headerName: "Gold Carat",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "goldCaratId",
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}



//#endregion ********** Diamond Table end*******

// **********Column Defination StoneGEMST Start*************

const ItemDetailColumn: AgColDefsI = {
  headerName: "Sale Invoice ItemDetail",
  field: "ItemDetailId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const Carat: AgColDefsI = {
  headerName: "Carat",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "carat",
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}



const clarityColumn: AgColDefsI = {
  headerName: "Clarity",
  field: "clarityId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent, 
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CLARITY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const Cut: AgColDefsI = {
  headerName: "Cut",
  field: "cut",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CUT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const ColorColumn: AgColDefsI = {
  headerName: "Color",
  field: "color",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.COLOR_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const SettingType: AgColDefsI = {
  headerName: "Setting Type",
  field: "settingType",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const SeiveSize: AgColDefsI = {
  headerName: "SeiveSize",
  field: "seiveSize",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SEIVE_SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const Size: AgColDefsI = {
  headerName: "Size",
  field: "size",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const CertificateCharges: AgColDefsI = {
  headerName: "Certificate Charges",
  field: "certificateCharges",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
// const VoucherTypeIdColumn: AgColDefsI = {
//   headerName: "Voucher Type",
//   field: "voucherTypeId",
//   minWidth: 120,
//   sortable: true,
//   editable: true,
//   cellRenderer: TextRendererComponent,
//   cellEditor:AgGridCustomInputComponent,
//   headerComponentParams: { enableSorting: false},
//   cellClass: "custom-column-group-cell px-0",
//   headerClass: "custom-column-group-cell px-0",
// }
const ProductDetailIdColumn: AgColDefsI = {
  headerName: "Product",
  field: "productDetailId",
  minWidth: ProductColWidth.MinWidth,
  maxWidth: ProductColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const LessWt: AgColDefsI = {
  headerName: "Less Wt.",
  field: "lessWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const PurityWt: AgColDefsI = {
  headerName: "Purity Wt.",
  headerComponentParams: { isRequired: false ,enableSorting: false},
  field: "purityWt",
  sortable: true,
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const WastagePer: AgColDefsI = {
  headerName: "Wastage Per.",
  headerComponentParams: { isRequired: false  ,enableSorting: false },
  field: "wastagePer",
  sortable: true,
  minWidth: 120,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:5
  }
}
const WastageWt: AgColDefsI = {
  headerName: "Wastage Wt.",
  headerComponentParams: { isRequired: false  ,enableSorting: false},
  field: "wastageWt",
  sortable: true,
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const NetWt: AgColDefsI = {
  headerName: "Net Wt.",
  field: "netWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const FinalWt: AgColDefsI = {
  headerName: "Final Wt.",
  field: "finalWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const ActualPurityColumn: AgColDefsI = {
  headerName: "ActualPurity",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "actualPurity",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const NotionalMeltingProfitAndLossColumn: AgColDefsI = {
  headerName: "National Melting Profit And Loss",
  field: "notionalMeltingProfitAndLoss",
  minWidth: 180,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: { enableSorting: false},
  cellEditor:AgGridCustomInputComponent,
}
const MakingCol: AgColDefsI = {
  headerName: "Making",
  field: "making",
  marryChildren: true,
  sortable: true,
  // isRequired: true,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      field: "makingTypeId",
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Type",
      minWidth: 80,
      editable: true,
      sortable: true,
      cellRenderer: TextRendererComponent,
      cellEditor: AgGridCustomDropdownComponent,
      cellEditorParams: {
        values: () => {
          const storageService = new StorageService();
          const storage = StorageName;
          return storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
        },
      },
    },
    {
      field: "makingRate",
      cellClass: "customcolumn-group-cell px-0",
      headerName: "Rate",
      minWidth: 100,
      editable: true,
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      headerComponentParams: { enableSorting: false},
      cellEditorParams:{
        decimalPlaces:3,
        isOnlyNumbers:true,
      }
    },
    {
      field: "makingDiscount ",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Discount",
      minWidth: 120,
      editable: true,
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      headerComponentParams: { enableSorting: false},
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      }
    },
    {
      field: "makingAmount",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Amount",
      minWidth: 120,
      editable: false,
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      headerComponentParams: { enableSorting: false},
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      }
    },
  ],
};
const Amount: AgColDefsI = {
  headerName: "Amt",
  field: "amount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
}
const HuidNo: AgColDefsI = {
  headerName: "HUID No.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "huidNo",
  sortable: true,
  minWidth: 110,
  editable: true,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent
}
const PacketWt: AgColDefsI = {
  headerName: "Pkt. Wt.",
  field: "packetWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const PacketLessWt: AgColDefsI = {
  headerName: "PKt. Less Wt.",
  field: "packetLessWt",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true ,enableSorting: false},
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
const BarcodeNo: AgColDefsI = {
  headerName: "Barcode No.",
  headerComponentParams: { isRequired: false ,enableSorting: false},
  field: "barcodeNo",
  sortable: true,
  minWidth: BarcodeColWidth.MinWidth,
  maxWidth: BarcodeColWidth.MaxWidth,
  editable: true,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
}

const Rate: AgColDefsI = {
  headerName: "Rate",
  field: "rate",
  minWidth: 100,
  headerComponentParams: { isRequired: true ,enableSorting: false },
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
}
const Quantity: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
    cellEditorParams:{
    decimalPlaces:0,
    isOnlyNumbers:true,
  }
}

const InsuranceIdColumn: AgColDefsI = {
  headerName: "Insurance",
  field: "insuranceId",
  minWidth: 110,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PRODUCT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const TaxAmountColumn: AgColDefsI = {
  headerName: "Tax Amount",
  field: "taxAmount",
  minWidth: 180,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}
const NetAmountCol: AgColDefsI = {
  headerName: "Net Amt",
  field: "netAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
}
const DiscountPerColumn: AgColDefsI = {
  headerName: "Discount Per.",
  field: "discountPer",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}
const DiscountAmountColumn: AgColDefsI = {
  headerName: "Discount Amount",
  field: "discountAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}
const DeliveryNoteDetailsIdColumn: AgColDefsI = {
  headerName: "Delivery Note Details",
  field: "deliveryNoteDetailsId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const OrderDetailsIdColumn: AgColDefsI = {
  headerName: "Order Details",
  field: "orderDetailsId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: { enableSorting: false},
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const QuotationDetailsIdColumn: AgColDefsI = {
  headerName: "Quotation Details",
  field: "quotationDetailsId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const UnitIdColumn: AgColDefsI = {
  headerName: "Unit",
  field: "unitId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UNIT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const UnitConversionIdColumn: AgColDefsI = {
  headerName: "Unit Conversion",
  field: "unitConversionId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UNIT_CONVERSION_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const TaxIdColumn: AgColDefsI = {
  headerName: "Tax",
  field: "taxId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.TAX_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const RemarkIdColumn: AgColDefsI = {
  headerName: "Remark",
  field: "remarkId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.REMARK_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
const CommentCol: AgColDefsI = {
  headerName: "Comment",
  field: "comment",
  sortable: true,
  headerClass:"custom-column-group-cell px-0",
  minWidth: 100,
  flex: 3,
  cellClass: "custom-column-group-cell px-0",
  editable:true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: { enableSorting: false},
}
const BucketColumn: AgColDefsI = {
  headerName: "Bucket",
  field: "bucketId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.BUCKET_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}




// **********Column Defination StoneGEMST end*************




// ******************** Column Defination Immmitation start *************************

const ImmitationWeight: AgColDefsI = {
  headerName: "Weight",
  field: "weight",
  minWidth: 100,
  sortable: true,
  editable: true,
  resizable: false,
  headerComponentParams: {isRequired: false , enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  },
}

const ImmitationBarcodeNoPcsWiseId: AgColDefsI = {
  headerName: "Barcode No Pcs ",
  field: "barcodeNoPcsWiseId",
  minWidth: 130,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  }, 
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationVoucherTypeId: AgColDefsI = {
  headerName: "Voucher Type",
  field: "voucherTypeId",
  minWidth: VoucherColWidth.MinWidth,
  maxWidth: VoucherColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationProductDetailId: AgColDefsI = {
  headerName: "Product",
  field: "productDetailId",
  minWidth: ProductColWidth.MinWidth,
  maxWidth: ProductColWidth.MaxWidth,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.PRODUCT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationHuidNo: AgColDefsI = {
  headerName: "Huid No",
  field: "huidNo",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationPacketWt: AgColDefsI = {
  headerName: "Packet Wt",
  field: "packetWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth:WeightColWidth.MaxWidth,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationPacketLessWt: AgColDefsI = {
  headerName: "Packet Less Wt",
  field: "packetLessWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationBarcodeNo: AgColDefsI = {
  headerName: "Barcode No",
  field: "barcodeNo",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationGrossWt: AgColDefsI = {
  headerName: "Gross Wt.",
  field: "grossWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  }
}

const ImmitationRate: AgColDefsI = {
  headerName: "Rate",
  field: "rate",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  }
}

const ImmitationQuantity: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:1,
    isOnlyNumbers:true,
    inputMaxlength:15
  },

}

const ImmitationInsuranceId: AgColDefsI = {
  headerName: "Insurance",
  field: "insuranceId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationNetAmountWithTax: AgColDefsI = {
  headerName: "Net Amt+Tax",
  field: "netAmountWithTax",
  minWidth: 100,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
}

const ImmitationTaxAmount: AgColDefsI = {
  headerName: "Tax Amount",
  field: "taxAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}

const ImmitationNetAmount: AgColDefsI = {
  headerName: "Net Amt",
  field: "netAmount",
  minWidth: 100,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  }
}

const ImmitationDiscountPer: AgColDefsI = {
  headerName: "Discount Per",
  field: "discountPer",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}

const ImmitationDiscountAmount: AgColDefsI = {
  headerName: "Discount Amount",
  field: "discountAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}

const ImmitationDeliveryNoteDetailsId: AgColDefsI = {
  headerName: "Delivery  Note Details",
  field: "deliveryNoteDetailsId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationOrderDetailsId: AgColDefsI = {
  headerName: "Order Details",
  field: "orderDetailsId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationQuotationDetailsId: AgColDefsI = {
  headerName: "Quotation Details",
  field: "quotationDetailsId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      // const storageService = new StorageService();
      // const storage = StorageName;
      // return storageService.retrieve(storage.PURITYGROUP_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationUnitId: AgColDefsI = {
  headerName: "Unit",
  field: "unitId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UNIT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationUnitConversionId: AgColDefsI = {
  headerName: "Unit Conversion",
  field: "unitConversionId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UNIT_CONVERSION_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationTaxId: AgColDefsI = {
  headerName: "Tax",
  field: "taxId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.TAX_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationRemarkId: AgColDefsI = {
  headerName: "Remark",
  field: "remarkId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.REMARK_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationComment: AgColDefsI = {
  headerName: "Comment",
  field: "comment",
  minWidth: 120,
  resizable: false,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationBucketId: AgColDefsI = {
  headerName: "Bucket",
  field: "bucketId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.BUCKET_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationItemTaxDetails: AgColDefsI = {
  headerName: "Item Tax Details",
  field: "itemTaxDetails",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  resizable: false,
}

const ImmitationActionColumn: AgColDefsI = {
  headerName: "",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  // Pass gridOptions to the cell renderer component
  cellRendererParams: {
    editIcon: true,
    // tableId: StorageName.TRANSACTION_SALEINVOICE_IMMITATION_ITEM_GRID,
    isFirstDeleteIcon:true,
    resetIcon:true
  },
  headerComponentParams: { enableMenu: false, moreButton: true ,enableSorting: false, selectAll : false },
  cellClass: "mx-0 text-end px-1",
  minWidth: 80,
  maxWidth: 80,
};

//# region for AddDiamond col defination 
export const StyleColumn: AgColDefsI = {
  headerName: "Style",
  field: "style",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.STYLE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
export const ItemCodeColumn: AgColDefsI = {
  headerName: "Item Code",
  field: "itemCode",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}
export const RapNetColumn: AgColDefsI = {
  headerName: "RapNet Valuation",
  field: "rapNetValuation",
  minWidth: 100,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
  },
  hide :true,
};

//#endregion


//#region  Definition for Gold and Silver Table
export const ColumnDefForGoldTable: AgColDefsI[] = [
  CheckboxColumn,  
  IdColumn,
  RFIDCode, 
  VoucherTypeIdColumn, 
  BarcodeColumn,
  HUIDColumn,
  SubCategoryColumn,
  ProductColumn,
  LocationColumn,
  CaretColumn,
  PktWtColumn,
  PktLessWtColumn,
  QuantityColumn,
  RequestedPurityColumn,
  RequestedWtColumn,
  GrossWtColumn,
  LessWtColumn, 
  GoldLossWtColumn,
  GoldLossValueColumn,
  SettingChargeColumn,
  NetWtColumn,
  PurityColumn,
  PurityWtColumn, 
  WastagePerColumn,
  WastageWtColumn,
  AlloyWtColumn,
  FinalWtColumn,
  RateColumn,
  MetalValueColumn,
  RatePerColumn,
  AmountColumn,
  DiscountPerColumn,
  DiscountAmountColumn,
  MakingColumn,
  MinimumPrice,
  // StoneAmount,
  DiamondAmount,
  PurchaseAmountColumn,
  SaleAmountColumn,
  NetAmountColumn,
  TaxAmount,
  NetAmountWithTaxColumn,
  UDF1DynamicColumn(MetalType.Gold),
  UDF2DynamicColumn(MetalType.Gold),
  UDF3DynamicColumn(MetalType.Gold),
  UDF4DynamicColumn(MetalType.Gold),
  UDF5DynamicColumn(MetalType.Gold),
  UDF6,
  UDF7,
  UDF8,
  UDF9,
  UDF10, 
  UDF11DynamicColumn(MetalType.Gold),
  UDF12DynamicColumn(MetalType.Gold),
  UDF13DynamicColumn(MetalType.Gold),
  UDF14DynamicColumn(MetalType.Gold),
  UDF15DynamicColumn(MetalType.Gold),
  UDF16,
  UDF17,
  UDF18,
  UDF19,
  UDF20, 
  HMColumn,
  ReverseColumn,
  ActionColumn,
];

export const ColumnDefForSilverTable: AgColDefsI[] = [
  CheckboxColumn,  
  IdColumn,
  RFIDCode, 
  VoucherTypeIdColumn, 
  BarcodeColumn,
  HUIDColumn,
  SubCategoryColumn,
  ProductColumn,
  LocationColumn,
  CaretColumn,
  PktWtColumn,
  PktLessWtColumn,
  QuantityColumn,
  RequestedPurityColumn,
  RequestedWtColumn,
  GrossWtColumn,
  LessWtColumn, 
  NetWtColumn,
  PurityColumn,
  PurityWtColumn, 
  WastagePerColumn,
  WastageWtColumn,
  AlloyWtColumn,
  FinalWtColumn,
  RateColumn,
  MetalValueColumn,
  RatePerColumn,
  AmountColumn,
  DiscountPerColumn,
  DiscountAmountColumn,
  MakingColumn,
  // StoneAmount,
  MinimumPrice,
  DiamondAmount,
  PurchaseAmountColumn,
  SaleAmountColumn,
  NetAmountColumn,
  TaxAmount,
  NetAmountWithTaxColumn,
  DiscountPer,
  DiscountAmount,
  UDF1DynamicColumn(MetalType.Silver),
  UDF2DynamicColumn(MetalType.Silver),
  UDF3DynamicColumn(MetalType.Silver),
  UDF4DynamicColumn(MetalType.Silver),
  UDF5DynamicColumn(MetalType.Silver),
  UDF6,
  UDF7,
  UDF8,
  UDF9,
  UDF10,
  UDF11DynamicColumn(MetalType.Silver),
  UDF12DynamicColumn(MetalType.Silver),
  UDF13DynamicColumn(MetalType.Silver),
  UDF14DynamicColumn(MetalType.Silver),
  UDF15DynamicColumn(MetalType.Silver),
  UDF16,
  UDF17,
  UDF18,
  UDF19,
  UDF20,  
  HMColumn,
  ReverseColumn,
  ActionColumn,
  
];
//#endregion

const ColumnDefForDiamondTable: AgColDefsI[] = [

  CheckboxColumn,
  IdColumn,
  RFIDCode,
  ItemCodeColumn,
  StyleColumn,
  HUIDColumn,
  BarcodeColumn,
  LocationColumn,
  DiamondStoneCategoryColumn,
  CalculationTypeColumn,
  ProductColumn, 
  PktWtColumn,
  PktLessWtColumn,
  GrossWtColumn,
  DiamondCaratColumn,
  DiamondWeightColumn,  
  QuantityColumn,
  RateColumn,
  RapNetColumn,
  GoldLossWtColumn,
  GoldLossValueColumn,
  SettingChargeColumn,
  SettingChargeAmountColumn,
  MarkUpAmountColumn,
  AmountColumn,
  GoldCaratColumn,
  GoldWtColumn,
  GoldPurityPerColumn,
  MinimumPrice,
  GoldPurityWtColumn,
  GoldAmountColumn,
  GoldRateColumn,
  // StoneWtColumn,
  // StoneAmount,
  DiamondMakingColumn,  
  CertificateAmount,
  PurchaseAmountColumn,
  SaleAmountColumn,
  OtherAmount,  
  CertificateNumber,
  CutId,
  ColorId,
  SeiveSizeId,
  SizeId,
  ShapeId,
  Clarity,
  UDF1DynamicColumn(MetalType.Diamond),
  UDF2DynamicColumn(MetalType.Diamond),
  UDF3DynamicColumn(MetalType.Diamond),
  UDF4DynamicColumn(MetalType.Diamond),
  UDF5DynamicColumn(MetalType.Diamond),
  UDF6,
  UDF7,
  UDF8,
  UDF9,
  UDF10,
  UDF11DynamicColumn(MetalType.Diamond),
  UDF12DynamicColumn(MetalType.Diamond),
  UDF13DynamicColumn(MetalType.Diamond),
  UDF14DynamicColumn(MetalType.Diamond),
  UDF15DynamicColumn(MetalType.Diamond),
  UDF16,
  UDF17,
  UDF18,
  UDF19,
  UDF20, 
  UDF21DynamicColumn(MetalType.Diamond),
  UDF22DynamicColumn(MetalType.Diamond),
  UDF23DynamicColumn(MetalType.Diamond),
  UDF24DynamicColumn(MetalType.Diamond),
  UDF25DynamicColumn(MetalType.Diamond),
  UDF26DynamicColumn(MetalType.Diamond),
  UDF27DynamicColumn(MetalType.Diamond),
  UDF28DynamicColumn(MetalType.Diamond),
  UDF29DynamicColumn(MetalType.Diamond),
  UDF30DynamicColumn(MetalType.Diamond), 
  DiscountPer,
  DiscountAmount,
  HMColumn,
  NetAmount,
  TaxAmount,
  NetAmountWithTaxColumn,
  ReverseColumn,
  ActionColumn,
  
]

export const ColumnDefForStoneGEMSTable: AgColDefsI[] = [

  CheckboxColumn,
  IdColumn,
  RFIDCode,
  ItemCodeColumn,
  StyleColumn,
  HUIDColumn,
  BarcodeColumn,
  VoucherTypeIdColumn,  
  DiamondStoneCategoryColumn,
  CalculationTypeColumn,
  ProductColumn, 
  PktWtColumn,
  PktLessWtColumn,
  GrossWtColumn,
  StoneWeightColumn,
  DiamondCaratColumn,
  QuantityColumn,
  RateColumn,
  AmountColumn,
  MinimumPrice,
  GoldCaratColumn,
  GoldWtColumn,
  GoldAmountColumn,
  DiamondWtColumn,
  DiamondAmount,
  DiamondMakingColumn,  
  CertificateAmount,
  LocationColumn,
  PurchaseAmountColumn,
  SaleAmountColumn,
  OtherAmount,  
  CertificateNumber,
  CutId,
  ColorId,
  SeiveSizeId,
  SizeId,
  ShapeId,
  Clarity,
  // UDF1,
  // UDF2,
  // UDF3,
  // UDF4,
  // UDF5,
  // UDF6,
  // UDF7,
  // UDF8,
  // UDF9,
  // UDF10, 
  DiscountPer,
  DiscountAmount,
  HMColumn,
  NetAmount,
  NetAmountWithTaxColumn,
  ReverseColumn,
  ActionColumn,
]

const ColumnDefForImmitationAndWatchesTable: AgColDefsI[] = [
  
  CheckboxColumn,
  IdColumn,
  VoucherTypeIdColumn,  
  ImmitationBarcodeNo,
  ImmitationProductDetailId,
  ImmitationHuidNo,
  ImmitationPacketWt,
  ImmitationPacketLessWt,
  ImmitationGrossWt,
  ImmitationQuantity,
  ImmitationNetAmount,  
  PurchaseAmountColumn,
  SaleAmountColumn,  
  LocationColumn,
  ImmitationDiscountPer,
  ImmitationDiscountAmount,
  TaxAmount,
  MinimumPrice,
  ImmitationNetAmountWithTax,
  UDF1DynamicColumn(MetalType.Imitation),
  UDF2DynamicColumn(MetalType.Imitation),
  UDF3DynamicColumn(MetalType.Imitation),
  UDF4DynamicColumn(MetalType.Imitation),
  UDF5DynamicColumn(MetalType.Imitation),
  UDF6,
  UDF7,
  UDF8,
  UDF9,
  UDF10, 
  ActionColumn
]
//#region Gold

export const agGridDataModelGoldTopTable: AgGridI = {
  colDefs: ColumnDefForGoldTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_GOLD_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};

//#endregion

//#region silver
export const agGridDataModelSilverTopTable: AgGridI = {
  colDefs: ColumnDefForSilverTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_SILVER_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion

//#region diamond
export const agGridDataModelDiamondTopTable: AgGridI = {
  colDefs: ColumnDefForDiamondTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_DIAMOND_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion 
//#region Common


//#region StoneGEMS
export const agGridDataModelStoneGEMSTopTable: AgGridI = {
  colDefs: ColumnDefForStoneGEMSTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_STONE_GEMS_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion

//#region ImmitationAndWatches
export const agGridDataModelImmitationAndWatchesTopTable: AgGridI = {
  colDefs: ColumnDefForImmitationAndWatchesTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_IMITATION_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion



export class MetalSectionModel{

  agGridDataModelGold : AgGridI = {
    colDefs: agGridDataModelGoldTopTable.colDefs,
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    rowHeight:28,
  }  ;
  agGridDataModelSilver  : AgGridI = {
    colDefs: agGridDataModelSilverTopTable.colDefs,
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    rowHeight:28,
  } ;
  agGridDataModelDiamond  : AgGridI = {
    colDefs:agGridDataModelDiamondTopTable.colDefs,
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    rowHeight:28,
  } ;
  agGridDataModelStoneGEMS  :  AgGridI = {
    colDefs:agGridDataModelStoneGEMSTopTable.colDefs,
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    rowHeight:28,
  } ;
  agGridDataModelImmitationAndWatches   : AgGridI = {
    colDefs: agGridDataModelImmitationAndWatchesTopTable.colDefs,
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    rowHeight:28,
  } ;

  purchaseInvoiceNoDropdown:any;
  showCunsumptionAndProductionHeaders:boolean = false;
  isInternal_Fix: boolean=false;
  isAML: boolean=false;
  isKYC: boolean=false;
  previousRow : any;
  isBarcodeInput:boolean = true;
  isShowPurchaseInvoiceDropdown:boolean = false;
  isGridFooter :boolean = false;
  showCommentAndAddField : boolean =true;
  addButton : boolean =true;
  isCreateDemo : boolean = false;
  selectAll : boolean =false;
  editable : boolean =true;
  isFilter : boolean = false;
  apiUrl : string = "";
  isServerSidepagination : boolean = false;
  comment : string = "";
  groupName : string = "";
  barcodeNo : string = "";
  showMetalSelectionTabs : boolean = true;
  columnForVisibility : any = undefined; 
  columnsVisible : boolean = false; 
  tableIndex : number = 0;
  parentMetalId : number = 0;
  gridDynamicHeightClass : string;
  voucherWiseData : any;

  defaultActiveMetalTab : any;
  setVisibility(storageName : any) : any
  {
    switch(storageName)
    {
      case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
        this.isAML =false;
        this.isKYC =false;
        this.isInternal_Fix = true;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_SALEINVOICE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_SALEINVOICE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_SALEINVOICE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_SALEINVOICE_STONE_GEMS_ITEM_GRID,MetalGridMoreButtonId.StoneGEMS);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_SALEINVOICE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTOUT:
        this.isAML =false;
        this.isKYC =false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_CONSIGNMENTOUT_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_CONSIGNMENTOUT_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_CONSIGNMENTOUT_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_CONSIGNMENTOUT_STONE_GEMS_ITEM_GRID,MetalGridMoreButtonId.StoneGEMS);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_CONSIGNMENTOUT_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
        this.isAML =false;
        this.isKYC =false;
        this.isInternal_Fix = true;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_PURCHASEINVOICE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_PURCHASEINVOICE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_PURCHASEINVOICE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_PURCHASEINVOICE_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_PURCHASEINVOICE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTIN:
        this.isAML =false;
        this.isKYC =false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_CONSIGNMENTIN_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_CONSIGNMENTIN_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_CONSIGNMENTIN_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_CONSIGNMENTIN_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_CONSIGNMENTIN_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
        this.isAML =false;
        this.isKYC =false;        
        this.isInternal_Fix = true;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_JOBWORKINVOICE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_JOBWORKINVOICE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_JOBWORKINVOICE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_JOBWORKINVOICE_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_JOBWORKINVOICE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
        case StorageName.FORMNAME_TRANSACTION_SALESORDER:
          this.isAML =false;
          this.isKYC =false;
          // this.isInternal_Fix =false;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_SALESORDER_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_SALESORDER_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_SALESORDER_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_SALESORDER_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_SALESORDER_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
          break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
        this.isAML =false;
        this.isKYC =false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_PURCHASERETURN_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_PURCHASERETURN_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_PURCHASERETURN_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_PURCHASERETURN_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_PURCHASERETURN_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
        
      case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        this.isAML =false;
        this.isKYC =false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_SALESRETURN_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_SALESRETURN_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_SALESRETURN_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_SALESRETURN_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_SALESRETURN_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;

      case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
        this.isAML =false;
        this.isKYC =false;
        this.isInternal_Fix = true;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_JOBWORKORDER_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_JOBWORKORDER_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_JOBWORKORDER_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_JOBWORKORDER_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_JOBWORKORDER_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;

    
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE:
        this.isAML =false;
        this.isKYC =false;
        this.isInternal_Fix = false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_MATERIAL_ISSUE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_MATERIAL_ISSUE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_MATERIAL_ISSUE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_MATERIAL_ISSUE_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_MATERIAL_ISSUE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;

      case StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE:
        this.isAML =false;
        this.isKYC =false;
        this.isInternal_Fix = false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_MATERIAL_RECEIVE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_MATERIAL_RECEIVE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_MATERIAL_RECEIVE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_MATERIAL_RECEIVE_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_MATERIAL_RECEIVE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
      break;
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_STOCKOUT:
            this.gridDynamicHeightClass = 'stock_journal_grid_height';
            this.isInternal_Fix = false;
            this.isAML =false;
            this.isKYC =false;
            this.isBarcodeInput = false;
            this.isGridFooter  = true;
            this.showCommentAndAddField = false;
            this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
            this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
            this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
            this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_STONE_GEMS_ITEM_GRID);
            this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
      break;
      case StorageName.FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE:
        this.isAML =false;
        this.isKYC =false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_STONE_GEMS_ITEM_GRID);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
      case StorageName.FORMNAME_ORDERS_REPAIRORDER:
          this.isAML =false;
          this.isKYC =false;
          // this.isInternal_Fix =false;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.ORDERS_REPAIRORDER_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.ORDERS_REPAIRORDER_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.ORDERS_REPAIRORDER_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.ORDERS_REPAIRORDER_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.ORDERS_REPAIRORDER_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
          break;

        case StorageName.FORMNAME_TRANSACTION_REPAIRINVOICE:
          this.isAML =false;
          this.isKYC =false;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_REPAIRINVOICE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_REPAIRINVOICE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_REPAIRINVOICE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_REPAIRINVOICE_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_REPAIRINVOICE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
          break;
        case StorageName.FORMNAME_INVENTORY_PHYSICALSTOCK:
          this.isInternal_Fix = false;
          this.isAML =false;
          this.isKYC =false;
          this.isBarcodeInput = false;
          this.isGridFooter  = false;
          this.showCommentAndAddField = true;
          this.isShowPurchaseInvoiceDropdown = true;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.INVENTORY_PHYSICALSTOCK_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.INVENTORY_PHYSICALSTOCK_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.INVENTORY_PHYSICALSTOCK_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.INVENTORY_PHYSICALSTOCK_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.INVENTORY_PHYSICALSTOCK_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
        case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
          this.isAML =false;
          this.isKYC =false;
          // this.isInternal_Fix =false;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_PURCHASEORDER_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_PURCHASEORDER_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_PURCHASEORDER_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_PURCHASEORDER_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_PURCHASEORDER_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
          break;
        case StorageName.FORMNAME_INVENTORY_JEWELRY_CATALOGUE_BILL_OF_MATERIAL:
          this.isInternal_Fix = false;
          this.isAML =false;
          this.isKYC =false;
          this.isBarcodeInput = false;
          this.isGridFooter  = false;
          this.showCommentAndAddField = true;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
        case StorageName.FORMNAME_INVENTORY_CREATEMEMO:
          this.isAML =false;
          this.isKYC =false;
          this.isInternal_Fix = false;
          this.isBarcodeInput = false;
          this.addButton =false;
          this.isCreateDemo =true;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.INVENTORY_CREATEMEMO_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.INVENTORY_CREATEMEMO_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.INVENTORY_CREATEMEMO_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.INVENTORY_CREATEMEMO_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.INVENTORY_CREATEMEMO_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
        case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL2:
          this.isAML =false;
          this.isKYC =false;
          this.isInternal_Fix = false;
          this.isBarcodeInput = false;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL2_POPUP_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL2_POPUP_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL2_POPUP_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL2_POPUP_STONE_GEMS_ITEM_GRID);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL2_POPUP_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
        break;
        case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
          this.isAML =false;
          this.isKYC =false;
          this.isInternal_Fix=true;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_SALESQUOTATION_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_SALESQUOTATION_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_SALESQUOTATION_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_SALESQUOTATION_STONE_GEMS_ITEM_GRID,MetalGridMoreButtonId.StoneGEMS);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_SALESQUOTATION_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
          break;
          case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
            this.isAML =false;
            this.isKYC =false;
            this.isInternal_Fix=true;
            this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_DELIVERYNOTE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
            this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_DELIVERYNOTE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
            this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_DELIVERYNOTE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
            this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_DELIVERYNOTE_STONE_GEMS_ITEM_GRID,MetalGridMoreButtonId.StoneGEMS);
            this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_DELIVERYNOTE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
            break;
        case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
            this.isAML =false;
            this.isKYC =false;
            this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_CREDITNOTE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
            this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_CREDITNOTE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
            this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_CREDITNOTE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
            this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_CREDITNOTE_STONE_GEMS_ITEM_GRID,MetalGridMoreButtonId.StoneGEMS);
            this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_CREDITNOTE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
            break;
        case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
          this.isAML =false;
          this.isKYC =false;
          this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold,StorageName.TRANSACTION_DEBITNOTE_GOLD_ITEM_GRID,MetalGridMoreButtonId.Gold);
          this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver,StorageName.TRANSACTION_DEBITNOTE_SILVER_ITEM_GRID,MetalGridMoreButtonId.Silver);
          this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond,StorageName.TRANSACTION_DEBITNOTE_DIAMOND_ITEM_GRID,MetalGridMoreButtonId.Diamond);
          this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS,StorageName.TRANSACTION_DEBITNOTE_STONE_GEMS_ITEM_GRID,MetalGridMoreButtonId.StoneGEMS);
          this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_DEBITNOTE_IMITATION_ITEM_GRID,MetalGridMoreButtonId.ImmitationOrWatches);
          break;
      case StorageName.FORMNAME_PRODUCT_ADD_APPRAISAL:
        this.isAML = false;
        this.isKYC = false;
        this.agGridDataModelGold = this.setNewColDef(this.agGridDataModelGold, StorageName.PRODUCT_ADD_APPRAISAL_GOLD_ITEM_GRID, MetalGridMoreButtonId.Gold);
        this.agGridDataModelSilver = this.setNewColDef(this.agGridDataModelSilver, StorageName.PRODUCT_ADD_APPRAISAL_SILVER_ITEM_GRID, MetalGridMoreButtonId.Silver);
        this.agGridDataModelDiamond = this.setNewColDef(this.agGridDataModelDiamond, StorageName.PRODUCT_ADD_APPRAISAL_DIAMOND_ITEM_GRID, MetalGridMoreButtonId.Diamond);
        this.agGridDataModelStoneGEMS = this.setNewColDef(this.agGridDataModelStoneGEMS, StorageName.PRODUCT_ADD_APPRAISAL_STONE_GEMS_ITEM_GRID, MetalGridMoreButtonId.StoneGEMS);
        this.agGridDataModelImmitationAndWatches = this.setNewColDef(this.agGridDataModelImmitationAndWatches, StorageName.PRODUCT_ADD_APPRAISAL_IMITATION_ITEM_GRID, MetalGridMoreButtonId.ImmitationOrWatches);
        break;

    }
  }

  setNewColDef(grirDataModel, storageName,addMoreItemBtnId?) : any
  {
    grirDataModel.colDefs = grirDataModel.colDefs.map((item, index) => ({
      ...item, 
      headerComponentParams: {
        ...item.headerComponentParams,
        storageName: storageName,
        moreButtonId : addMoreItemBtnId
      },
      storageName: storageName
    }));
    grirDataModel.storageName = storageName;
    return grirDataModel;
  }


  //setting dynamic column defination 
}


