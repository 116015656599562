<div class="col editor-container h-100">
    <textarea data-character-count
        id="markdown-editor"
        debounce="500"
        [(ngModel)]="campaignMasterModel.caption"
        (paste)="onPaste($event)"
        (drop)="onDrop($event)"
        (ngModelChange)="onNgModelChange($event)"
        placeholder="Type your Markdown here..."
        style="font-family: monospace; resize: none;">
    </textarea>
    <div class="col px-2 d-flex justify-content-between">
       <strong> <p class="mb-0">
            <span class="character-counter">Characters: {{ characterCount }}</span>
            /{{messageNumber}}
        </p>
    </strong>
        <p class="mb-0">WhatsApp Credit Balance: <span class="text-danger">{{creditBalance}}</span></p>
    </div>
</div>