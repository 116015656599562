<div class="container-fluid d-flex flex-column h-100 px-0 gap-2">
    <div class="row">
        <div class="col-12">
            <div class="row mt-2">
                <div class="col-xl-7 col-lg-7 col-md-7">
                    <app-search-input></app-search-input>
                </div>
                <div *ngIf="catelogueForm == catelogue?.InventoryCatelogue" class="col-xl-5 col-lg-5 col-md-5 d-flex justify-content-end gap-2 align-items-center">
                    <app-button class="" [buttonLabel]="'Sync Jewellry Catalogue'" [buttonClass]="'dark_pink_button text-nowrap'"
                        [buttonId]="'sync_Catalogue'" [buttonType]="'submit'"
                        (buttonOutputEvent)="syncJewellryCatalogueButton()">
                    </app-button>
                    <app-button class="" [buttonLabel]="'Add'" [buttonClass]="'blue_button'" [buttonType]="'button'"
                        [showPlusIconwhite]="true" (buttonOutputEvent)="navigateToDetails(0)">
                    </app-button>
                    <!-- [ngbTooltip]="sendDataService?.noPermissionTooltip"
                    [disableTooltip]="(this.formModel?.invoiceModel?.id ? sendDataService?.permissionVM?.jewelryCatalogueCanUpdate : sendDataService?.permissionVM?.jewelryCatalogueCanAdd)"
                    [disabled]="!(this.formModel?.invoiceModel?.id ? sendDataService?.permissionVM?.jewelryCatalogueCanUpdate : sendDataService?.permissionVM?.jewelryCatalogueCanAdd)" -->
                </div>
            </div>
        </div>
    </div>
    <div class="row product_images m-0 flex-fill h-0 overflow-auto px-1">
        <div class="product_box col-xl-2 col-lg-2 col-2 px-2 pb-3" *ngFor="let item of jewelryCatalogueList">
            <div class="card m-0" >
                <div class="d-flex justify-content-end " *ngIf="item?.label; let i=index">
                   <div class="text-gray-color out_stock f-14 font-semibold">{{item?.label}}</div>
                </div>
                <div class="product_img cursorPointer">
                    <!-- [ngClass]="{'pe-none':isCheckDefault(item?.images[i]?.imageUrl)}" -->
                    <img class="img-fluid" (click)="openImgPopUp(item)" [src]="isBase64Image(item?.images[0]?.imageUrl)" alt="jewelry image" />
                </div>
                <div class="card-body d-flex flex-column px-2 py-2">
                    <div class="d-flex justify-content-between">
                        <div class="text-gray-color title f-12 font-semibold cursorPointer" (click)="navigateToDetails(item?.id)">  {{item?.title ?? 'NA'}}</div>
                    </div>
                    <div class="text-primary font-semibold f-15 lh-1"> {{item?.weight || 'NA'}} <span *ngIf="item?.weight">gm</span> </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row py-1 mx-0 px-0">
        <div class="col-12 d-flex justify-content-between align-items-center pagination_div px-2 border">
            <div class="text-nowrap f-11 text-gray-color"> Showing <span class="font-semibold">{{totalRecords === 0 ? '0' : getStartRange()}}</span>
                to <span class="font-semibold">{{getEndRange()}}</span> of <span class="font-semibold">{{totalRecords}}</span> entries</div>
            <div class="d-flex">
                <div class="page-size-control px-2 d-flex align-items-center">
                    <select [(ngModel)]="getAllJewelryCatalogueRequestModel.pageSize" (ngModelChange)="onPageSizeChange()" class="page-size-dropdown f-11 cursorPointer pe-2">
                        <option *ngFor="let option of paginationPageSizes?.limitOptions" [ngValue]="option?.value">
                            Show {{option?.key}} Items
                        </option>
                    </select>
                    
                </div>
                <ngb-pagination 
                    #ngbPagination 
                    [collectionSize]="getAllJewelryCatalogueRequestModel?.getAll ? 1 : totalRecords" 
                    [pageSize]="getAllJewelryCatalogueRequestModel?.getAll ? 1 : getAllJewelryCatalogueRequestModel?.pageSize" 
                    [page]="getAllJewelryCatalogueRequestModel?.getAll ? 1 : getAllJewelryCatalogueRequestModel?.page" 
                    [maxSize]="5" 
                    (pageChange)="onPageChanged($event)"
                    [boundaryLinks]="true"
                    class="d-flex justify-content-end grid-pagination">
                    <ng-template ngbPaginationPrevious>
                        <img src="./assets/svg/left-arrow.svg" alt="" />
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <img src="./assets/svg/right-arrow.svg" alt="" />
                    </ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div> 
</div>