<div class="container-fluid d-flex flex-column h-100 px-0"> 
    <div class="row py-1">
        <div class="col-12 d-flex">
            <div class="col">
                <div class="d-flex flex-wrap gap-2 px-0 my-1">
                    <ul 
                        ngbNav #nav="ngbNav" 
                        [(activeId)]="activeTabId"
                        (navChange)="onTabChange($event)"
                        class="nav-tabs report_invoice_tabs gap-1">
                        <li [ngbNavItem]="accountLedgerReportTabs.BalanceAmount">
                            <a ngbNavLink class="d-flex">
                            <span class="d-flex justify-content-center f-14 font-semibold">Balance Amounts</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-accountledger-report-amount 
                                [data]="data"
                                (changeValue)="handleValueChange($event)"
                                (rowClickEventForNavigation)="navigateToDetailInfoTab($event)" 
                                [fromDate]="fromDate"[toDate]="toDate"
                                (emitFilterData)="emitFilterData.emit()">
                            </app-accountledger-report-amount>
                            </ng-template>
                    
                        </li>
                        
                        <li [ngbNavItem]="accountLedgerReportTabs.ViewAllLedgerAmt" [disabled]="isViewAllLedgerDisabled()">
                            <a ngbNavLink class="d-flex">
                                <span class="d-flex justify-content-center f-14 font-semibold">View All Ledger</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-accountledger-detail-info 
                                [data]="data"
                                (changeFilterval)="handleValueChange($event)"
                                [defaultFilterData]="defaultFilterData" 
                                [isnavigateFromAmount]="isnavigateFromAmount"></app-accountledger-detail-info>
                            </ng-template>
                        </li>
                    </ul> 
                </div>
            </div>
            <div  class="col-3 d-flex justify-content-end align-items-center gap-2">

                <div class="d-flex align-items-center justify-content-end f-13 gap-2">
                    <div class="d-flex align-items-center justify-content-end">
                        <span class="f-10 font-semibold  position-absolute filter-count" style="top: -2px;">
                            <span class="d-flex justify-content-center">{{filterVal}}</span>
                        </span>
                        <app-button class="" (click)="openAdvanceFilterPopUp()" showFilterIcon="true"
                            [buttonClass]="'white_button common-filter-size gap-0'" [buttonType]="'button'" showIconCount="true">
                        </app-button>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                        <app-button class="" showRefreshIcon="true" [buttonClass]="'white_button common-refresh-size gap-0'"
                                    [buttonType]="'button'" (buttonOutputEvent)="resetFilter()">
                        </app-button>
                    </div>
                    <app-export-dropdown [requestModel]="exportBindParameterBasedOnTab(exportDropdownBindDynamicParameter?.RequestModel)" [gridApi]="exportBindParameterBasedOnTab(exportDropdownBindDynamicParameter?.GridApi)" [gridName]="exportBindParameterBasedOnTab(exportDropdownBindDynamicParameter?.GridName)" (callApiForExport)="callApiForExport()"></app-export-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div [ngbNavOutlet]="nav" class="flex-fill my-2">
    </div>
</div>