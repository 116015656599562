import { Injector, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import { AuthGuard } from "../../src/app/shared/guard/auth.guard"
const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/dashboard",
  },
  {
    path: "account",
    loadChildren: () => import("../app/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content,
  },
  {
    path: "app-jewelry",
    loadChildren: () => import("../app/auth/onboarding/jewelry/jewelry.module").then((m) => m.JewelryModule),
  },
  {
    path: 'invalid-link',
    loadChildren: () => import("../app/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "**",
    redirectTo: "/dashboard",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

  constructor(private injector: Injector) {
  }
}
