import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { convertTo12HourFormat, convertUtcToLocalDate, convertUtcToLocalDateTime, formatUtcToTimeString } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  styleUrls: ['./date-cell-renderer.component.scss']
})
export class DateCellRendererComponent implements ICellRendererAngularComp {

  params:ICellRendererParams|any;
  value:any;
  dateTime:boolean=false;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.dateTime = this.params.colDef.headerComponentParams?.cellRendererParams?.dateTime;
    this.valueFormat();
  }

  valueFormat = () => {
    if (this.params.dateTime || this.dateTime == true) {
      this.value = this.params ? convertUtcToLocalDateTime(this.params?.value) : '';
    } 
    else if (this.params.TimeString) {
      this.value = this.params ? formatUtcToTimeString(this.params?.value) : '';
    } 
    
    else if (this.params.breakTime) {
      this.value = convertTo12HourFormat(this.params?.value)?? '';
    } 
    else {
      this.value = this.params ? convertUtcToLocalDate(this.params?.value) : '';
    }
  };
  
  

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.valueFormat();
    return true
  }

}