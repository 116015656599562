import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss']
})
export class StatusCellRendererComponent implements  ICellRendererAngularComp{

  params:any;
  colorAdded:string;
  defaultStatusName: string = 'NA';
  agInit(params: any): void {
    this.params = params;

    // if(this.params?.value ==0){
    //   this.params.value = ''
    // }
    if (!this.params?.value || this.params?.value.trim() === '') {
      this.params.value = this.defaultStatusName; // Assign default status name
    }
    
    if (this.params.data.priorityId) {
      switch (this.params.data.priorityId) {
        case 10:
          this.colorAdded = '#008000';
          break;
        case 20:
          this.colorAdded = '#4F39CD';
          break;
        case 30:
          this.colorAdded = '#F92B35';
          break;
      }
    }

    if (this.params?.value === this.defaultStatusName) {
      this.colorAdded = '#7C89A4'; // Gray color for 'NA'
    }
  }
 
      
  refresh(params: any): boolean {
    return true;
  }
}
