import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appMinTimeValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinTimeValidatorDirective,
      multi: true
    }
  ]
})
export class MinTimeValidatorDirective implements Validator {
  @Input('minTime') minTime: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      // Convert control value and minTime to Date objects
      const controlTime = new Date(`1970-01-01T${control.value}:00`);
      const minTimeWithBuffer = new Date(`1970-01-01T${this.minTime}:00`);
      
      // Add 5 minutes buffer to the minTime
      minTimeWithBuffer.setMinutes(minTimeWithBuffer.getMinutes() + 5);

      // Compare times
      if (controlTime < minTimeWithBuffer) {
        return { minTime: true };
      }
    }

    return null;
  }
}
