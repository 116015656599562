import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgGridButtonCellrendererService {
  public viewHistoryClickedSource = new Subject<any>();
  viewHistoryClicked$ = this.viewHistoryClickedSource.asObservable();


  triggerViewButtonClick(params: any) {
    this.viewHistoryClickedSource.next(params);
  }
  

  constructor() { }
}
