
import { Component, Renderer2, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { SendDataService } from '../../services/send-data.service';
import { CommonService } from '../../services/common.service';
import { catchError } from 'rxjs';
import { GetAllCampaignGroupResponseModel } from 'src/app/components/administration/administration.model';

@Component({
  selector: 'app-campaign-group',
  templateUrl: './campaign-group.component.html',
  styleUrls: ['./campaign-group.component.scss']
})
export class CampaignGroupComponent extends CommonService implements OnInit {
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllCampaignGroup         : any[]=[];
  copyGetAllCampaignGroup     : any[]=[];
  editCampaignGroup           = new GetAllCampaignGroupResponseModel();
  isAdd                       : boolean = false;
  
constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
    super(renderer)
}

ngOnInit(): void {
  this.getAllCampaignGroupList();
}
add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return; if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, name:'',active:true}
  this.getAllCampaignGroup?.push(obj);
  this.copyGetAllCampaignGroup?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`campaigngroupname${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllCampaignGroup = this.getAllCampaignGroup.filter((x)=>x.id != 0);
    this.copyGetAllCampaignGroup= this.copyGetAllCampaignGroup.filter((x)=>x.id != 0);
  }
  else if(this.editCampaignGroup?.id && this.editCampaignGroup?.isEdit){
    const index = this.copyGetAllCampaignGroup.findIndex((x)=>x.id == this.editCampaignGroup?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllCampaignGroup))[index];
    this.getAllCampaignGroup[index] = singleData;
     this.editCampaignGroup = new GetAllCampaignGroupResponseModel ();
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
   this.editCampaignGroup = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

// *********** Campaign Group API START *********
  // **********get all Campaign Group *********
  getAllCampaignGroupList() {
    this.administrationService.getAllCampaignGroup().subscribe({
      next:(res)=>{
        if(res.isSuccess){
            this.getAllCampaignGroup= res.response??[];
             this.editCampaignGroup = new GetAllCampaignGroupResponseModel();
            this.copyGetAllCampaignGroup = JSON.parse(JSON.stringify(this.getAllCampaignGroup.filter((x)=>x.active == true)))??[];
          }else{           
          this.getAllCampaignGroup = [];
          this.copyGetAllCampaignGroup = [];
        }  
      },
      error:(err)=>{
        this.getAllCampaignGroup = []; 
        this.copyGetAllCampaignGroup = [];
      }
    })
  }

 // *************insert Campaign Group **************
  InserCampaignGroupList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertCampaignGroup(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllCampaignGroupList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update Campaign Group *********
    UpdateCampaignGroup(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateCampaignGroup(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            console.log(res?.message)
            this.isAdd = false;
            this.getAllCampaignGroupList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete Campaign Group *********
    DeleteCampaignGroup(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.deleteCampaignGroup(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllCampaignGroupList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  
  // *********** CampaignGroup API END *********

}
