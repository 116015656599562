import { Component } from '@angular/core';

@Component({
  selector: 'app-two-headerline-span',
  templateUrl: './two-headerline-span.component.html',
  styleUrls: ['./two-headerline-span.component.scss']
})
export class TwoHeaderlineSpanComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}
