<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper header-wrap d-flex m-0">
    <app-search></app-search>
    <div class="left-header header-logo-wrapper col-auto p-0 d-flex align-center justify-content-center">
      <div class="logo-wrapper d-flex justify-content-center">
        <!-- <a routerLink="/"> -->
          <div class="ps-1" *ngIf="!layoutDetails?.companyLogo">
            <app-svg-icon  name="header_logo" ></app-svg-icon>
          </div>
          <div *ngIf="layoutDetails?.companyLogo" >
            <img [src]="isBase64Image(layoutDetails?.companyLogo)" (click)="navgateToDashboard()" class="cursorPointer" width="22" height="22"  name="header_logo" alt="image-f" (error)="handleImageError($event)">
          </div>     
        <!-- </a> -->
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="col-5 p-0 d-flex">
      <div class="ms-2">        
        <div class="mb-0 f-13 text-secondary font-semibold">{{layoutDetails?.companyName || 'NA'}}</div>
        <p class="mb-0 f-11 text-grey font-regular lh-1">{{layoutDetails?.financialYearName || 'NA'}}</p>
      </div>
      <!-- <div class="col-6 d-flex justify-content-end">
        <img src="assets/SVGicon/header-logo2.svg"/>
      </div> -->
    </div>
    <div class="col-1 logo2 d-flex justify-content-center align-items-center">
      <img [src]="layoutService?.logoHeader" alt="" (error)="handleImageError($event)">
    </div>  
    <div class="nav-right pull-right right-header p-0">
      <ul class="nav-menus d-flex">
        <li *ngIf="user?.accountLedgerId"  class="position-relative">
          <app-svg-icon class="cursorPointer"  name="addCart" (click)="navigateProductCheckout()"></app-svg-icon>
              <span *ngIf="cartDetails?.orderSummary?.length" class="cart-count d-flex align-items-center justify-content-center font-regular f-11">{{cartDetails?.orderSummary?.length}}</span>
        </li>
        <li>
          <div class="position-relative">
            <app-svg-icon name="rating" [ngClass]="currentHoverEffect" class="cursorPointer " (click)="openUpdateRateToggler()"></app-svg-icon>
              <div class="position-absolute top-0 end-0 m-t-40 updateratebox" *ngIf="isRateVisible">
                <app-update-current-rate (toggler)="openUpdateRateToggler()"></app-update-current-rate>
              </div>
          </div>
        </li>
        <li class="maximize" (click)="toggleFullScreen()">
          <div class="cursorPointer  " [ngClass]="currentHoverEffect"><i data-feather=maximize></i></div>
        </li>
        <!-- <li>
          <div class="cursorPointer " [ngClass]="currentHoverEffect"><app-svg-icon name="tally" ></app-svg-icon></div>
        </li> -->
        <!-- <li>
          <div class="cursorPointer " [ngClass]="currentHoverEffect"><app-svg-icon name="document" ></app-svg-icon></div>
        </li> -->
        <li>
          <div class="cursorPointer " [ngClass]="currentHoverEffect"><app-svg-icon name="headerWp" class="show-icon" ></app-svg-icon></div>
        </li>
       
        <li class="language-nav d-none" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li>
          <div class="cursorPointer  " [ngClass]="currentHoverEffect"><app-svg-icon name="headermenu" ></app-svg-icon></div>
        </li>
        <!-- <li> -->
          <!-- <span class="header-search" (click)="searchToggle()"> -->
            <!-- <span class="header-search"> -->
            <!-- <img src="assets/SVGicon/search-icon.svg" /> -->
            <!-- <div class="cursorPointer " [ngClass]="currentHoverEffect"><app-svg-icon name="searchheader" ></app-svg-icon></div>
          </span>
        </li> -->
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="profile-nav onhover-dropdown p-0 pe-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
