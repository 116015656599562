
<div class="col-12 download_icon-hover align-items-center d-flex">
    <div *ngIf="isShowlable" class="col-4 f-14 font-semibold text-gray-color text-nowrap mb-0">
        Branch<span class="text-color-danger">*</span>
    </div>
    <button *ngIf="showcampaignGroupIcon" class="select-label {{className}} " >
        <ng-container >
            <app-svg-icon  name="campaignGroup" ngbTooltip="Campaign Group"></app-svg-icon>
        </ng-container>
    </button> 
    <div class="col position-relative">
        <ng-multiselect-dropdown 
            class="custom_dropdown" 
            [ngClass]="{'dropdown-with-icon': showcampaignGroupIcon ,'single-select-no-checkbox': dropdownSettings?.singleSelection, 'non-editable-cell': disabled}" 
            [placeholder]="placeholder" 
            [id]="id" 
            [name]="id"
            [required]="isRequired" 
            [settings]="dropdownSettings" 
            [data]="data??values" 
            [(ngModel)]="selectedIds"
            [disabled]="disabled"
            (onSelect)="onItemSelect($event)" 
            (onSelectAll)="onSelectAll($event)" 
            (onDeSelect)="onItemDeSelect($event)"
            (onDeSelectAll)="onItemDeSelectAll($event)">
        </ng-multiselect-dropdown>
        <app-input *ngIf="isRequired ==true" class="ng-multiselect-requried" [inputName]="id" [inputId]="id"
            [isRequired]="!selectedIds?.length" [inputValue]="selectedIds"></app-input>
    </div>
    <div *ngIf="isPlusIcon" class="d-flex justify-content-start ps-2 align-items-center" >
        <svg (click)="openModelPopup.emit(true)" class="cursorPointer plusIcon-hover" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Group_3496" data-name="Group 3496" transform="translate(-0.023)">
              <circle id="Ellipse_59" data-name="Ellipse 59" cx="11" cy="11" r="11" transform="translate(0.023)" fill="#e8ecf3"/>
              <path id="Union_4" data-name="Union 4" d="M5.187-1a.687.687,0,0,1,.687.687V4.5h4.812a.687.687,0,1,1,0,1.375H5.875v4.812a.687.687,0,1,1-1.375,0V5.875H-.313A.687.687,0,0,1-1,5.187.687.687,0,0,1-.313,4.5H4.5V-.313A.687.687,0,0,1,5.187-1Z" transform="translate(6.023 6)" fill="#a3abb8"/>
            </g>
        </svg>
    </div>
    <div class="d-flex">
        <div *ngIf="isFilterIcon" (click)="onFilterIconClick(branchIds)">
            <app-button
                class=""
                showFilterIcon="true"
                [buttonClass]="'gap-0 h-1_8rem bordernone light_voiletButton px-1'"
                [buttonType]="'button'"
            ></app-button>
        </div>
        <div *ngIf="isResetIcon" (click)="onResetIconClick(branchIds)">
            <app-button class="p-0" 
            ResetdarkBlueIcon="true" 
            [buttonClass]="'gap-0 h-1_8rem bordernone light_voiletButton px-1'"
            [buttonType]="'button'">
        </app-button>
        </div>
    </div>
    
  
</div>

