import { EventEmitter, Injectable } from '@angular/core';
import { IpcRenderService } from './ipc-render.service';
import { StorageService } from './storage.service';
import { ImplementedOn, StorageName } from '../enum/common-enum';
import { SettingsService } from '../../components/settings/settings.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class WeightMachineService {
  public portsList: any[] = [];
  public getAllDeviceList: any[] = [];
  public serialData: string = '';
  storageName = StorageName;
  isPortOpen = false;
    dataLogged = new EventEmitter<any>();
  constructor(
    public ipcService: IpcRenderService,
    private settingsService: SettingsService,
    private toaster: ToastrService,
    private storageService: StorageService
  ) { }

  initializeWeightMachine(): void {
    this.settingsService.getDeviceByImplementedOnid(ImplementedOn.WeightMachine).subscribe({
      next: (res) => {
        if (!res?.isSuccess) {
          // this.handleError('Failed to retrieve devices', res?.message);
          return;
        }
  
        const weightMachineData = res?.response;
  
        if (!weightMachineData) {
          // this.toaster.error('Weight Machine Not Configured');
          return;
        }
  
        this.setupWeightMachinePorts(weightMachineData);
      },
      error: (err) => {
        // this.handleError('Device API call failed', err?.message);
      }
    });
  }
  
  private setupWeightMachinePorts(weightMachineData: any): void {
    this.ipcService.send('getPorts');

    this.ipcService.on('portsList', (event, ports) => {
      this.portsList = ports;
      console.log('Ports list:', this.portsList);
      this.storageService.store(this.storageName.PORT_DROPDOWN, ports);

      const portToOpen = this.getSavedOrDefaultPort(ports, weightMachineData);
      if (portToOpen) {
        this.openSelectedPort(portToOpen);
      }
    });

    this.ipcService.on('portOpened', () => {
      this.isPortOpen = true;
      console.log('Port successfully opened');
    });

    this.ipcService.on('portClosed', () => {
      this.isPortOpen = false;
      console.log('Port successfully closed');
    });
  }

  private handleError(defaultMessage: string, customMessage?: string): void {
    this.getAllDeviceList = [];
    this.toaster.error(customMessage || defaultMessage);
  }

  // Function to stop listening to events when destroyed
  stopListening() {
    // this.dataLogged?.unsubscribe();
    this.ipcService.removeAllListener("serialData");
    this.ipcService.removeAllListener("portOpened");
    this.ipcService.removeAllListener("openPort");
    // this.disconnectPort();
    this.ipcService.removelListener();
  }

  getSavedOrDefaultPort(portsList: any[], weightMachineData: any): any {
    console.log('savedPort name:', weightMachineData);
    let portToOpen = portsList.find(p => p.path === weightMachineData?.comPort) || portsList[0];

    // Safely assign baudRate with a default if undefined or invalid
    const baudRate = Number(weightMachineData?.baudRateName);
    portToOpen.baudRate = !isNaN(baudRate) ? baudRate : 9600;  // Default to 9600 if not a valid number

    console.log('portToOpen:', portToOpen);
    return JSON.stringify(portToOpen);
  }

  openSelectedPort(port: any) {
    if (port) {
      this.ipcService.send('openPort', port);
    }
  }
  private readonly delimiter: string = '\n';
  handleSerialData(data: string) {
    let delimiterIndex;
    var completeMessage;
    this.serialData += data;
    // Process complete messages
    while ((delimiterIndex = this.serialData.indexOf(this.delimiter)) !== -1) {
      completeMessage = this.serialData.slice(0, delimiterIndex).trim();
      this.serialData = this.serialData.slice(delimiterIndex + this.delimiter.length); // Remove processed data from buffer

      if (completeMessage) {
        const cleanedData = completeMessage?.trim(); // Remove leading/trailing whitespace
        const sanitized = cleanedData?.replace(/[^0-9.]/g, '')?.replace(/(\..*?)\..*/g, '$1');;
        this.dataLogged.emit(sanitized)
        console.log('Serial Data Completed:', sanitized);
        return sanitized;
      }
    }
  }

  disconnectPort(): void {
    console.log('disconnectPort')
    this.ipcService.send('disconnectPort');
  }

}
