import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutDetails, User } from 'src/app/auth/auth/auth.model';
import { StorageService } from '../../services/storage.service';
import { SendDataService } from '../../services/send-data.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ReferAndEarnComponent } from '../../common-components/pop-ups/refer-and-earn/refer-and-earn.component';
import { commonModalOptionsLg, ModalPopupSize } from '../../models/common.model';
import { KeyboardShortcutsComponent } from '../../common-components/pop-ups/keyboard-shortcuts/keyboard-shortcuts.component';
import { KeyboardShortcutsTitle } from '../../common-components/pop-ups/keyboard-shortcuts/keyboard-shortcuts.model';
import { DatePipe } from '@angular/common';
import { InputDatepickerRendererComponent } from '../../common-components/ag-grid/input-datepicker-renderer/input-datepicker-renderer.component';
import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from '../../services/layout.service';
import { StorageName } from '../../enum/common-enum';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers:[DatePipe]
})
export class FooterComponent implements OnInit {
  public showYoutubeicon :boolean = false;
  public today: number = Date.now();
  public menuItems: Menu[];
  user:User;
  layoutDetails:LayoutDetails;
  public branchSubscription:Subscription
  keyboardShortcutsTitle = KeyboardShortcutsTitle;
  invoiceDate         : any;
  ipAddress : string = environment.baseUrl;
  crid: any;
  @ViewChild(InputDatepickerRendererComponent) inputDatepickerRendererComponent: InputDatepickerRendererComponent;

  constructor( public navServices: NavService, 
    private router: Router, 
    private storageservice:StorageService,
    private sendDataService: SendDataService,
    private modalService  : NgbModal,private datePipe: DatePipe,
    public dataService:  DataService,
    public layoutService  : LayoutService,
    public notificationService: NotificationService,
    private dateService: DateService) { 

    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {

        if (event instanceof NavigationEnd) {
          if (event.url === '/Transaction/saleinvoice' || event.url === '/Transaction/sale-order' || event.url === '/Orders/ordertracking' || event.url === '/Orders/orderlist' || event.url === '/Orders/cart' || event.url === '/Inventory/jewelry-Catalogue' || event.url === '/Inventory/jewelry-Catalogue-Details' ) {
            this.showYoutubeicon = true;
          } else {
            this.showYoutubeicon = false;
          }
        } 
      });
    });

    this.crid = this.storageservice.retrieve(StorageName.COMPANY_REGISTRATION_ID);
  }

  ngOnInit(): void {
    this.branchSubscription = this.sendDataService.ShowUserDetails.subscribe((res=>{
      if(res){
        this.setDetails()
      }
     }))

    this.setDetails();
  }

  setDetails(){
    this.user =this.storageservice.retrieve('user');
    this.layoutDetails =this.storageservice.retrieve('layoutDetails');
  }

  openReferAndEarnPopup(){
    this.notificationService.openModalPopup(KeyboardShortcutsComponent  ,undefined,'' ,ModalPopupSize.LG,"refer_And_scan-popup" , false , true).then(
      (result)=>{
        if(result){

        }
      }
    )
  }
  // openReferAndEarnPopup(){
  //   const modalOption: NgbModalOptions = {
  //     centered: true,
  //     backdrop: "static",
  //     keyboard: false,
  //     size: "md",
  //     windowClass:"refer_And_scan-popup"
  //   } 
  
  //   const madalRef = this.notificationService.open(ReferAndEarnComponent, modalOption);
      
  // }

  openKeybordShortcutkeyPopup(modalTitle?:string){
    var data = {
      modalTitle : modalTitle,
    }
    this.notificationService.openModalPopup(KeyboardShortcutsComponent , data ,undefined ,ModalPopupSize.LG,"KeybordShortcut-popup").then(
      (result)=>{
        if(result){

        }
      }
    )
  }
  // openKeybordShortcutkeyPopup1(modalTitle?:string){
  //   const modalOption : NgbModalOptions = {
  //     ...commonModalOptionsLg,
  //     windowClass     : "KeybordShortcut-popup"
  //   };
  //   const modalRef = this.notificationService.open(KeyboardShortcutsComponent ,modalOption);
  //   modalRef.componentInstance.modalTitle = modalTitle;
  //   modalRef.result.then((result)=>{
  //     if(result){
        
  //     }
  //   });
  // }

  selectedRangeDates(event?: any) {
    this.invoiceDate = event;
    const date = this.datePipe.transform(event, 'yyyy-MM-dd');
    localStorage.setItem('selectedCommonDate', date);
    this.dateService.setSelectedDate(event);
    // this.inputDatepickerRendererComponent.getStoreddate();
  } 
  onYoutube(){
    const url = "https://www.youtube.com/@JewelSteps";
    window.open(url, "_blank");
  }

  onWhatsAppSupportClick() {
    const phoneNumber = '971509542169';
    const message = 'Hello! I would like to request more information about the JewelSteps Jewellery ERP Software.';
    const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  }


  ngOnDestroy(){
    this.branchSubscription?.unsubscribe();
  }

}
