import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';


@Component({
  selector: 'app-custom-expandable',
  templateUrl: './custom-expandable.component.html',
  styleUrls: ['./custom-expandable.component.scss']
})
export class CustomExpandableComponent implements OnInit, ICellRendererAngularComp{

  
  registerRowDragger: (rowDraggerElement: HTMLElement, dragStartPixels?: number, value?: string, suppressVisibilityChange?: boolean) => void;
  show: boolean = false;
  showSics: boolean = false;
  message: any;
  params: any;
  currentTab = ''
  listLength
  
  receiptDetails: any[] = [];
  manufacturingProcessDetails: any[] = [];
  loanDetails: any[] = [];
  dayReports: any[] = [];
  constructor( public storageService:StorageService){}

  agInit(params: any): void 
  {
    this.params = params;
    this.receiptDetails = 
    this.params?.data?.receiptDetailsModels ?? this.params?.data?.paymentDetailsModel  ?? this.params?.data?.materialInDetailsModels ?? this.params?.data?.materialOutDetailsModel ?? null;
    this.manufacturingProcessDetails = this.params?.data?.expandedRows;
    this.loanDetails = this.params?.data?.loansItemModel;
    
    if (params?.data?.isRowExpandable) {
      let rows = this.storageService.retrieve(StorageName.IS_ROW_SUM);
      this.dayReports = rows.filter(row => !row.isRowSum && row.voucherTypeId === params.data.voucherTypeId);
    }
    // loanGridHere
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void
  {
  }

  getResponse($event) 
  {
    this.message = $event;
  }

  clickIcon() 
  {
    this.show = !this.show;
  }
  clickSicsIcon()
  {
    this.showSics = !this.showSics;
  }
}
