import { FilterList, SortList } from "../../models/pagination-base-model.model";

export class JewelryCatalogueCommon {
}
export class GetAllJewelryCatalogueRequestModel {
    pageSize = 25;
    page=1;
    getAll= true;
    sortColumn      : string="";
    sortDirection   : string="";
    searchText      : string="";
    orderBy         : string="";
    filters         : string="";
    total_rows      : number=0;
    total_pages     : number=0;
    sortList        : SortList;
    filterList      : FilterList;
  }

  export enum Catelogue{
    InventoryCatelogue = 1,
    CustomerCatelogue = 2,
    LoggedCustomerCatelogue = 3,
    BillOfMaterialCatelogue = 4,
  }