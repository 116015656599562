import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllCategoryResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllCategoryData          : any[]=[];
  
  copyGetAllCategoryData           : any[]=[]
  editCategoryData                = new GetAllCategoryResponseModel();
  isAdd                       : boolean = false;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllCategoryList();
}

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, name:'',active:true}
  this.getAllCategoryData?.push(obj);
  this.copyGetAllCategoryData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`categoryname${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllCategoryData = this.getAllCategoryData.filter((x)=>x.id != 0);
    this.copyGetAllCategoryData = this.copyGetAllCategoryData.filter((x)=>x.id != 0);
  }
  else if(this.editCategoryData?.id && this.editCategoryData?.isEdit){
    const index = this.copyGetAllCategoryData.findIndex((x)=>x.id == this.editCategoryData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllCategoryData))[index];
    this.getAllCategoryData[index] = singleData;
    this.editCategoryData = new GetAllCategoryResponseModel();
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
   this.editCategoryData = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

  // *********** CATEGORY API START *********
  // **********get all category *********
  getAllCategoryList() {
    this.administrationService.getAllCategory().subscribe({
      next:(res)=>{
        if(res.isSuccess){
            this.getAllCategoryData = res.response??[];
            this.editCategoryData = new GetAllCategoryResponseModel();
            this.copyGetAllCategoryData = JSON.parse(JSON.stringify(this.getAllCategoryData))??[];
          }else{           
          this.getAllCategoryData = [];
          this.copyGetAllCategoryData = [];
        }  
      },
      error:(err)=>{
        this.getAllCategoryData = []; 
        this.copyGetAllCategoryData = [];
      }
    })
  }

 // *************insert category **************
  InserCategoryList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertCategory(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllCategoryList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update category *********
    UpdateCategoryList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateCategory(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            
            this.isAdd = false;
            this.getAllCategoryList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete category *********
    DeleteCategoryList(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.deleteCategory(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllCategoryList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** CATEGORY API END *********
}