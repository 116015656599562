import {
  AgColDefsI,
  AgGridI,
} from "../../shared/common-components/ag-grid/ag-grid.model";
import { EditDeleteIconComponent } from "../../shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { BarcodeColWidth, MetalColWidth, NameColWidth, ProductColWidth, QuantityColWidth, StorageName, VoucherColWidth, WeightColWidth } from "../../shared/enum/common-enum";
import { paginationModel } from "../../shared/components/common-model/common-model";
import {
  TextRendererComponent,
} from "../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { CheckboxRendererComponent } from "../../shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import {
  API_URL_GET_ALL_AVAILABLESTOCKRFIDBARCODE,
  API_URL_GET_ALL_SCANNEDRFIDBARCODE,
  API_URL_GET_ALL_STOCKAVAILABILITY_OF_DIAMOND,
  API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
} from "../../shared/url-helper/url-helper";
import { NumericCellRendererComponent } from "../../shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { DateCellRendererComponent } from "../../shared/common-components/ag-grid/date-cell-renderer/date-cell-renderer.component";
import { AgGridButtonCellrendererComponent } from "../../shared/common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.component";
import { JwellaryImageComponent } from "../../shared/common-components/ag-grid/jwellary-image/jwellary-image.component";
import { RfidIconComponent } from "../../shared/common-components/ag-grid/rfid-icon/rfid-icon.component";

export class Report {}

// invoice metalFixing popup
const ItemListColumnDef: AgColDefsI[] = [
  {
    headerName: "Fixing Weight",
    field: "fixingWt",
    suppressMovable: false,
    resizable: false,
    lockPosition: true,
    cellClass: "custom-column-group-cell",
    headerClass:
      "justify-content-center px-0 custom-column-group-cell border-0",
    minWidth: 180,
    maxWidth: 180,
    editable: true,
    sortable: false,
  },
  {
    headerName: "Fixing Rate",
    field: "fixingRate",
    suppressMovable: false,
    resizable: false,
    lockPosition: true,
    cellClass: "custom-column-group-cell",
    headerClass:
      "justify-content-center px-0 custom-column-group-cell border-0",
    minWidth: 180,
    maxWidth: 180,
    editable: true,
    sortable: false,
  },
  {
    headerName: "Trans. Fixing Value",
    field: "transFixingValue",
    lockPosition: true,
    suppressMovable: false,
    resizable: true,
    headerClass:
      "px-0 custom-column-group-cell border-0  justify_content_end",
    minWidth: 180,
    maxWidth: 180,
    cellClass: " px-2 custom-column-group-cell",
    editable: false,
    sortable: false,
  },
  {
    headerName: "Fixing Value",
    field: "fixingValue",
    lockPosition: true,
    suppressMovable: false,
    resizable: true,
    headerClass:
      "px-0 custom-column-group-cell border-0  justify_content_start",
    cellClass: "px-2 custom-column-group-cell sale-bill-class",
    minWidth: 180,
    maxWidth: 180,
    editable: false,
    sortable: false,
  },
  {
    headerName: "Difference",
    field: "difference",
    lockPosition: true,
    suppressMovable: false,
    resizable: true,
    headerClass:
      "px-0 custom-column-group-cell border-0   justify_content_start",
    cellClass: "px-2 custom-column-group-cell sale-bill-class",
    minWidth: 100,
    maxWidth: 100,
    editable: false,
    sortable: false,
  },
  {
    headerName: "",
    field: "",
    minWidth: 100,
    maxWidth: 100,
    editable: false,
    cellRenderer: EditDeleteIconComponent,
    cellRendererParams: {
      focusOnColumn: "name",
      isEditRowOnEdit: false,
      resetIcon: true,
    },
    headerClass: "custom-column-group-cell",
    headerComponentParams: {
      enableMenu: false,
      moreaddButton: true,
      enableSorting: false,
    },
    cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
    lockPosition: true,
    suppressMovable: false,
  },
];

export const AgGridDataModelMetalFixingList: AgGridI = {
  colDefs: ItemListColumnDef,
  rowSelection: "multiple",
  storageName: StorageName.REPORT_INVOICES_METAL_FIXING_GRID,
};

// sale order list starts

// sale order list ends

// invoices model
export class GetInvoiceReportResponse {
  label: string = "Sale";
  id: number;
  invoiceNo: string;
  amount: number;
  mobileNoDialCode: number;
  balanceAmount: number;
  invoiceDate: any;
  ledgerName: string;
  phone: string;
  mobile: string;
  addressLine1: string;
  dddressLine2: string;
  voucherTypeId: number;
  branchName: string;
}

export class GetallInvoiceRequestModel extends paginationModel {
  pageSize: number = 25;
  branchId: number;
  voucherTypeId: any[] = [];
  filters: any;
  exportRequest: exportRequest[];
  exportAs: number;
  fromDate: string;
  toDate: string;
}

// draft model
export class GetDraftsReportResponse {
  id: number;
  voucherTypeId: number;
  voucherTypeName: string;
  accountLedgerId: number;
  detailedJson: string;
  mobileNoDialCode: number;
  draftNo: string;
  accountLedgerName: string;
  mobile: number;
  fullAddress: string;
  salePersonName: string;
  amount: number;
  date: string;
}

export class GetallDraftsRequestModel extends paginationModel {
  pageSize: number = 25;
  voucherTypeId: any[] = [];
  filters: any;
  exportRequest: exportRequest[];
  exportAs: number;
}

export class GetallDayReportRequestModel extends paginationModel {
  pageSize: number = 25;
  branchId: number;
  voucherTypeId: number; 
  ledgerId: number;
  productId: number;
  exportAs: number;
  exportRequest: exportRequest[];
  fromDate: string;
  toDate: string;
  filters:any;
}

export class GetallKycReportRequestModel extends paginationModel {
  pageSize: number = 25;
  branchId: number;
  voucherTypeId: number;
  ledgerId: number;
  productId: number;
  exportAs: number;
  exportRequest: exportRequest[];
  fromDate: string;
  toDate: string;
  filters:any;
}

export class GetallSalesOrderRequestModel extends paginationModel {
  pageSize: number = 25;
  branchId: number;
  voucherTypeId: number;
  ledgerId: number;
  productId: number;
  columnToInclude: any;
}

export class GetallstockRequestModel extends paginationModel {
  pageSize: number = 25;
  filters: any;
  sortList: any[];
  exportAs: number;
  branchId: number;
  exportRequest: exportRequest[];
}
export class GetallstockhistoryRequestModel extends paginationModel {
  pageSize: number = 25;
  branchId: number;
  voucherTypeId: number;
  ledgerId: number;
  productId: number;
  invoiceId: number;
  filters: string;
  exportRequest: exportRequest[];
}

export class GetallstockDetailRequestModel extends paginationModel {
  pageSize: number = 25;
  branchId: number;
  voucherTypeId: number;
  ledgerId: number;
  productId: number;
  exportRequest: exportRequest[];
  exportAs: number;
  filters: any;
  fromDate: string;
  toDate: string;
}

export class GetallstockDetailReportRequestModel {
  exportRequest: exportRequest[];
  exportAs: number;
  filters: any;
  fromDate: string;
  toDate: string;
  getAll: boolean = true;
}
export class GetallBarcodestockRequestModel extends paginationModel {
  pageSize: number = 25;
  filters: string;
  sortList: any;
  exportRequest: exportRequest[];
  getAll = false;
  exportAs: number;
}

export class BarcodeTrackingRequestModel {
  barcodeTrackingModels: any[];
}

export class GetRequestModel extends paginationModel {
  pageSize: number = 25;
  branchId: number;
  voucherTypeId: number;
  ledgerId: number;
  productId: number;
  exportRequest: exportRequest[];
  filters: string;
  exportAs: number;
}

export class exportRequest {
  gridName: string;
  columnToInclude: any;
  gridData: any;
}
// *****************Stock Report end*********************

export enum InternalFixing {
  Normal = 1,
  UnFix = 2,
  Fix = 3,
}

export class MetalFixInsertUpdateRequestModel {
  metalFixingModels: MetalFixingModel[] = [];
}

export class MetalFixingModel {
  deleted: boolean = false;
  added: boolean = false;
  edited: boolean = false;
  none: boolean = false;
  id: number = 0;
  invoiceId: number = 0;
  voucherTypeId: number = 0;
  fixingWt: number = 0;
  fixingRate: number = 0;
  transFixingValue: number = 0;
  fixingValue: number = 0;
  difference: number = 0;
}

export class MetalFixingData {
  accountLedgerName: string;
  invoiceNo: string;
  invoiceDate: string;
  weight: number;
  unfixRate: number;
  metalValue: string;
}

export class PrintSaleInvoice {
  id: number;
  isPrint: boolean;
}

export class PrintDiamondCertificate {
  id: number; 
  voucherTypeId: number;
}

export enum RfidGrid {
  AvailableStock = 1,
  ScannedStock = 2,
  UnknownStock = 3,
}

export class RfidRequest {
  rfidNumber: string[]; 
  userId: number;
}

const ImageColumn: AgColDefsI = {
  headerName: "",
  headerClass:
    "px-0 custom-column-group-cell border-0 ",
  field: "imageUrls",
  cellRenderer: JwellaryImageComponent,
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 36,
  maxWidth: 36,
  editable: false,
  lockPosition: "left",
  lockPinned: true,
  pinned: "left",
  suppressMovable: false,
  cellRendererParams: { fromImageUrl: true },
  headerComponentParams: { enableSorting: false },
  resizable: false,
};

const ActionHistoryColumn: AgColDefsI = {
  headerName: "",
  field: "action",
  maxWidth: 70,
  minWidth: 70,
  sortable: true,
  editable: false,
  resizable: false,
  headerComponentParams: { enableSorting: false },
  cellRenderer: AgGridButtonCellrendererComponent,
  cellRendererParams: { view: true },
  cellClass:
    "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
  headerClass: "custom-column-group-cell px-0",
};
const DateColumn: AgColDefsI = {
  headerName: "Date",
  field: "date",
  maxWidth: 80,
  minWidth: 80,
  cellClass: 'custom-column-group-cell align-items-center d-flex justify-content-center',
  headerClass: "px-1 f-14 text-gray-color font-semibold custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: DateCellRendererComponent,
  }


const ProductNameColumn: AgColDefsI = {
  headerName: "Product Name",
  field: "productName",
  minWidth: ProductColWidth.MinWidth,
  maxWidth:ProductColWidth.MaxWidth,
  resizable: true,
  // headerClass: "custom-column-group-cell px-0",
  // cellClass: "custom-column-group-cell",
  cellClass: 'custom-column-group-cell align-items-center d-flex justify-content-center',
  headerClass: "px-1 f-14 text-gray-color font-semibold custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
};


const VoucherTypeColumn: AgColDefsI = {
  headerName: "Voucher Type",
  field: "voucherTypeName",
  flex:1,
  resizable: true,
  cellClass: 'custom-column-group-cell align-items-center d-flex justify-content-center',
  headerClass: "px-1 f-14 text-gray-color font-semibold custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
};
const InvoiceNoColumn: AgColDefsI = {
  headerName: "Invoice No.",
  field: "invoiceNo",
  minWidth: 70,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 ",
  cellClass: "custom-column-group-cell px-0 text-primary text-underline",
  editable: false,
  sortable: false,
  cellRenderer: TextRendererComponent,
  cellRendererParams: { isReadOnly: false ,isCursor: true },
};
const BranchColumn: AgColDefsI = {
  headerName: "Branch",
  field: "branch",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
};

const GrossWtColumn: AgColDefsI = {
  headerName: "Gross Wt",
  field: "grossWt",
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  resizable: true,
  cellStyle: { "text-align": "right" },
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: false,
  isRequired: true,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams: { isWeight: true },
};

const CustomerNameColumn: AgColDefsI = {
  headerName: "Customer Name",
  field: "customerName",
  minWidth: NameColWidth.MinWidth,
  maxWidth: NameColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {
    filterValueColumnName: "customerId",
    filterIdColumnName: "customerName",
    filterParams: StorageName.ACCOUNTLEDGER_DROPDOWN,
    floatingMultiselectDropDown: true,
  },
};
const CaratColumn: AgColDefsI = {
  headerName: "Carat",
  field: "inwardQty",
  // lockPinned: true,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell px-2",
  sortable: true,
  editable: false,
  resizable: true,
  suppressMoveIntoParents: true,
  minWidth: WeightColWidth.MinWidth,
  maxWidth: WeightColWidth.MaxWidth,
  flex: 1,
  cellRenderer: TextRendererComponent,
};
const BranchName: AgColDefsI = { 
  headerName: "Branch Name", 
  // headerComponentParams: {enableMenu:false},
  sortable:true,
  headerClass: "px-0 custom-column-group-cell border-0 ",
  field: 'branchName',
  cellClass:'px-0 custom-column-group-cell ',
  suppressMovable: false,
  resizable: true, 
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:false},
  editable:false, 
  minWidth: 180, flex:2,
};

const QTYColumn: AgColDefsI = {
  headerName: "Qty.",
  field: "qty",
  minWidth: QuantityColWidth.MinWidth,
  maxWidth: QuantityColWidth.MaxWidth,
  resizable: true,
  cellStyle: { "text-align": "right" },
  headerClass: "custom-column-group-cell ag_header_end px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: false,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams : {isWeight:true},
};
const BarcodeColumn: AgColDefsI ={
  headerName: "Barcode",
  field: "barcodeNo",
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell px-2",
  sortable: false,
  editable: false,
  resizable: true,
  suppressMoveIntoParents: true,
  minWidth: BarcodeColWidth.MinWidth,
  maxWidth: BarcodeColWidth.MaxWidth,
  flex: 1,
  cellRenderer: TextRendererComponent,
}

const StockReportColumnDefForStockhistoryDiamondTable: AgColDefsI[] = [
  ImageColumn,
  ActionHistoryColumn,
  DateColumn,
  ProductNameColumn,
  VoucherTypeColumn,
  InvoiceNoColumn,
  GrossWtColumn,
  CaratColumn,
  BranchName,
  QTYColumn,
  BarcodeColumn
];
const StockReportColumnOutWardDefForStockhistoryDiamondTable:AgColDefsI[] =[
  ImageColumn,
  ActionHistoryColumn,
  DateColumn,
  ProductNameColumn,
  VoucherTypeColumn,
  InvoiceNoColumn,
  GrossWtColumn,
  CaratColumn,
  BranchName,
  QTYColumn,
  BarcodeColumn
];

export const AgGridStockhistoryDiamondTable: AgGridI = {
  colDefs: StockReportColumnDefForStockhistoryDiamondTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  storageName: StorageName.REPORT_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID,
  filterStorageName: StorageName.FILTER_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID,
  sortStorageName: StorageName.SORT_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID,
  paginationStorageName: StorageName.PAGINATION_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID,
  columnSettings: true,
  singleClickEdit: true,
  rows: [],
  rowHeight: 28,
  isFilter: true,
  apiUrl: API_URL_GET_ALL_STOCKAVAILABILITY_OF_DIAMOND,
  isServerSidepagination: true,
  showPagination: true,
};
export const AgGridStockhistoryDiamondoutwardTable: AgGridI = {
  colDefs: StockReportColumnOutWardDefForStockhistoryDiamondTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  storageName: StorageName.REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID,
  filterStorageName: StorageName.FILTER_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID,
  sortStorageName: StorageName.SORT_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID,
  paginationStorageName: StorageName.PAGINATION_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID,
  columnSettings: true,
  singleClickEdit: true,
  rows: [],
  rowHeight: 28,
  isFilter: true,
  apiUrl: API_URL_GET_ALL_STOCKAVAILABILITY_OF_DIAMOND,
  isServerSidepagination: true,
  showPagination: true,
};
const CheckboxColumn: AgColDefsI = {
  headerName: "",
  field: "active",
  lockPosition: "left",
  cellClass: "custom-column-group-cell",
  cellRenderer: CheckboxRendererComponent,
  headerClass: " justify-content-center px-0  border-0",
  minWidth: 50,
  maxWidth: 50,
  editable: false,
};
const StockReportColumnDefForStockhistoryStoneGemsTable: AgColDefsI[] = [
  CheckboxColumn,
  ImageColumn,
  ActionHistoryColumn,
  DateColumn,
  ProductNameColumn,
  VoucherTypeColumn,
  InvoiceNoColumn,
  BranchColumn,
  GrossWtColumn,
  CustomerNameColumn,
];

export const AgGridStockhistoryStoneGemsTable: AgGridI = {
  colDefs: StockReportColumnDefForStockhistoryStoneGemsTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  storageName: StorageName.REPORT_DIAMOND_STONE_STONEGEMS_GRID,
  filterStorageName: StorageName.FILTER_REPORT_DIAMOND_STONE_STONEGEMS_GRID,
  sortStorageName: StorageName.SORT_REPORT_DIAMOND_STONE_STONEGEMS_GRID,
  paginationStorageName: StorageName.PAGINATION_DIAMOND_STONE_STONEGEMS_GRID,
  columnSettings: true,
  singleClickEdit: true,
  rows: [],
  headerHeight: 70,
  rowHeight: 28,
  isFilter: true,
  apiUrl: API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
  isServerSidepagination: true,
  showPagination: true,
};

const StockReportColumnDefForStockhistoryStoneGemsPopupTable: AgColDefsI[] = [
  DateColumn,
  ProductNameColumn,
  VoucherTypeColumn,
  InvoiceNoColumn,
  BranchColumn,
  GrossWtColumn,
  CustomerNameColumn,
];

export const AgGridStockhistoryStoneGemsPopupTable: AgGridI = {
  colDefs: StockReportColumnDefForStockhistoryStoneGemsPopupTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  storageName: StorageName.REPORT_DIAMOND_STONE_STONEGEMS_GRID,
  filterStorageName: StorageName.FILTER_REPORT_DIAMOND_STONE_STONEGEMS_GRID,
  sortStorageName: StorageName.SORT_REPORT_DIAMOND_STONE_STONEGEMS_GRID,
  paginationStorageName: StorageName.PAGINATION_DIAMOND_STONE_STONEGEMS_GRID,
  columnSettings: true,
  singleClickEdit: true,
  rows: [],
  headerHeight: 70,
  rowHeight: 28,
  apiUrl: API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
};

const StockReportColumnDefForAvailableStockTable: AgColDefsI[] = [
  // AvailableStockColumn
  {
    headerName: "",
    field: "isScanned",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2 d-flex justify-content-center",
    cellRendererParams: {
      showLabel: false,
      isReadOnly: true,
      isCommonCheckbox: true,
    },
    cellRenderer: CheckboxRendererComponent,
    sortable: false,
    editable: false,
    suppressMoveIntoParents: false,
    minWidth: 50,
    maxWidth: 50,
    resizable: false,
    lockPosition: "left",
    suppressMovable: false,
    lockPinned: true,
    pinned: "left",
  },
  {
    headerName: "Branch",
    field: "branchName",
    // lockPinned: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable: true,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  // {
  //   headerName: "Group Name",
  //   headerClass:
  //     "px-0 custom-column-group-cell f-14 text-gray-color font-semibold justify_content_end",
  //   field: "groupName",
  //   sortable: false,
  //   editable: false,
  //   cellRenderer: TextRendererComponent,
  //   cellRendererParams: { isCursor: true },
  //   cellClass: "custom-column-group-cell text-primary font-regular f-12",
  //   minWidth: NameColWidth.MinWidth,
  //   maxWidth: NameColWidth.MaxWidth,
  //   resizable: true,
  // },
  {
    headerName: "Carat",
    field: "carat",
    // lockPinned: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "Action",
    field: "action",
    headerClass:
      "custom-column-group-cell ag_header_center px-0 customHeaderClass",
    cellClass: "custom-column-group-cell text-center px-2",
    sortable: true,
    editable: false,
    resizable: false,
    suppressMoveIntoParents: true,
    minWidth: 70,
    maxWidth: 70,
    cellRenderer: RfidIconComponent,
    cellRendererParams: {RFID: true },
    lockPosition: "left",
  },
  {
    headerName: "Metal",
    field: "metalName",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "Product Code",
    field: "productName",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: 70,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "RFID Code",
    field: "rfid",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: 70,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "Barcode",
    field: "barcodeNo",
    // lockPinned: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "Qty",
    field: "quantity",
    // lockPinned: true,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellStyle: { "text-align": "right" },
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: QuantityColWidth.MinWidth,
    maxWidth: QuantityColWidth.MaxWidth,    
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
  },
   {
    
    headerName: "Location",
    field: 'locationName',
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    minWidth: 170,
    sortable: true,
    editable: false,
    cellRenderer:TextRendererComponent,
},



  {
    headerName: "Wt.",
    field: "weight",
    // lockPinned: true,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellStyle: { "text-align": "right" },
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: { isWeight: true },
  },
  {
    headerName: "Net Wt.",
    field: "netWt",
    // lockPinned: true,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellStyle: { "text-align": "right" },
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: { isWeight: true },
  },
  {
    headerName: "Final Wt.",
    field: "finalWt",
    // lockPinned: true,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellStyle: { "text-align": "right" },
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable: true,
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: { isWeight: true },
  },
  {
    headerName: "Voucher Type",
    field: "voucherTypeName",
    // lockPinned: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "invoice No",
    field: "invoiceNo",
    // lockPinned: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: 70,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
];

// export const GroupNameColumn: AgColDefsI = {
//   headerName: "Group Name",
//   headerClass:
//     "px-0 custom-column-group-cell f-14 text-gray-color font-semibold justify_content_end",
//   field: "groupName",
//   sortable: false,
//   editable: false,
//   cellRenderer: TextRendererComponent,
//   cellClass: "custom-column-group-cell",
//   minWidth: NameColWidth.MinWidth,
//   maxWidth: NameColWidth.MaxWidth,
//   resizable: true,
// };

export const AgGridAvailableStockTable: AgGridI = {
  colDefs: StockReportColumnDefForAvailableStockTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  columnSettings: true,
  storageName:
    StorageName.REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  filterStorageName:
    StorageName.FILTER_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  sortStorageName:
    StorageName.SORT_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  paginationStorageName:
    StorageName.PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  singleClickEdit: true,
  rows: [],
  headerHeight: 28,
  rowHeight: 28,
  apiUrl: API_URL_GET_ALL_AVAILABLESTOCKRFIDBARCODE,
  isFilter: true,
  isServerSidepagination: false,
};

const filteredColumns = StockReportColumnDefForAvailableStockTable.filter(
  (column) => {
    return (
      column.field !== "isScanned" &&
      column.field !== "action" &&
      column.field !== "groupName"
    );
  }
);

const columnsWithExtra = [...filteredColumns,
                          // GroupNameColumn
                        ];

export const AgGridAvailableStockPopup: AgGridI = {
  colDefs: columnsWithExtra,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  columnSettings: true,
  storageName:
    StorageName.REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  filterStorageName:
    StorageName.FILTER_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  sortStorageName:
    StorageName.SORT_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  paginationStorageName:
    StorageName.PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
  singleClickEdit: true,
  rows: [],
  // headerHeight: 70,
  headerHeight: 28,
  rowHeight: 28,
  apiUrl: API_URL_GET_ALL_AVAILABLESTOCKRFIDBARCODE,
  isFilter: true,
  isServerSidepagination: false,
};

const StockReportColumnDefForScannedStockTable: AgColDefsI[] = [
  {
    headerName: "",
    field: "active",
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell px-2",
    cellRendererParams: { showLabel: false },
    cellRenderer: CheckboxRendererComponent,
    sortable: false,
    resizable: false,
    editable: false,
    suppressMoveIntoParents: false,
    minWidth: 40,
    maxWidth: 40,
    lockPosition: "left",
    suppressMovable: false,
    lockPinned: true,
    pinned: "left",
  },
  {
    headerName: "Branch",
    field: "branchName",
    // lockPinned: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },

  {
    headerName: "Product Code",
    field: "productName",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: 150,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    
    headerName: "Location",
    field: 'locationName',
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    minWidth: 170,
    sortable: true,
    editable: false,
    cellRenderer:TextRendererComponent,
},
  // {
  //   headerName: "Group Name",
  //   headerClass:
  //     "px-0 custom-column-group-cell f-14 text-gray-color font-semibold justify_content_end",
  //   field: "groupName",
  //   sortable: false,
  //   cellRenderer: TextRendererComponent,
  //   cellClass: "custom-column-group-cell text-primary font-regular f-12",
  //   minWidth: NameColWidth.MinWidth,
  //   maxWidth: NameColWidth.MaxWidth,
  //   editable: false,
  //   cellRendererParams: { isCursor: true },
  // },

  {
    headerName: "RFID Code",
    field: "rfid",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: 150,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "Barcode",
    field: "barcodeNo",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: false,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "Qty",
    field: "quantity",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    cellStyle: { "text-align": "right" },
    sortable: false,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: QuantityColWidth.MinWidth,
    maxWidth: QuantityColWidth.MaxWidth,
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
  },
  {
    headerName: "Metal",
    field: "metalName",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
  },
  {
    headerName: "Wt.",
    field: "weight",
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell px-2",
    cellStyle: { "text-align": "right" },
    sortable: false,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: { isWeight: true },
  },
  {
    headerName: "Net Wt.",
    field: "netWt",
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell px-2",
    cellStyle: { "text-align": "right" },
    sortable: false,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: { isWeight: true },
  },
  {
    headerName: "Final Wt.",
    field: "finalWt",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    cellStyle: { "text-align": "right" },
    sortable: false,
    editable: false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    flex: 1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: { isWeight: true },
  },
  {
    headerName: "Voucher Type",
    field: "voucherTypeName",
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    resizable: true,
    editable: false,
    suppressMoveIntoParents: true,
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    flex: 1,
  },
 
];
export const AgGridScannedStockTable: AgGridI = {
  colDefs: StockReportColumnDefForScannedStockTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  columnSettings: true,
  singleClickEdit: true,
  storageName: StorageName.REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
  filterStorageName:
    StorageName.FILTER_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
  sortStorageName:
    StorageName.SORT_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
  paginationStorageName:
    StorageName.PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
  rows: [],
  headerHeight: 28,
  rowHeight: 28,
  apiUrl: API_URL_GET_ALL_SCANNEDRFIDBARCODE,
  isServerSidepagination: true,
  isFilter: true,
};

export class GetAllDepartmentReportRequestModel {
  exportRequest: ExportRequest[];
  date: string;
  exportAs: number;
  fromDate: string;
  toDate: string;
}
export class ExportRequest {
  columnToInclude: any;
  gridName: string;
}

export class GetallAgeingReportRequestModel extends paginationModel {
  fromDate: string;
  toDate: string;
  pageSize: number = 25;
  filters: any;
  exportRequest: exportRequest[];
  exportAs: number;

  accountLedger: string;
  date: string;
}

export enum CheckPermissionFor{
  CanView = 1,
  CanDelete = 2
}

