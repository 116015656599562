import { Component, Input, NgZone, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from 'ag-grid-community';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { ToastrService } from 'ngx-toastr';
import { VoucherType } from 'src/app/shared/models/common.model';
import { AgGridSaleOrderList } from 'src/app/components/Transaction/invoice/invoice.model';
import { InvoiceService } from 'src/app/components/Transaction/invoice/invoice.service';
import { IconHover } from '../../../../enum/common-enum';

@Component({
  selector: 'app-sale-order-popup',
  templateUrl: './sale-order-popup.component.html',
  styleUrls: ['./sale-order-popup.component.scss']
})
export class SaleOrderPopupComponent extends CommonService{
  @Input()data:any;
  agGridSaleOrderList            : AgGridI;
  gridOptions                    : GridOptions;
  gridApiSaleOrder               :any;
  agGridSaleOrderRowData         :any[]=[];
  saleOrdeList: any;
  requestIds=[];
  currentHoverEffect = IconHover.Pink;
  itemList = [];
  voucherdata: any;

  constructor(
    public activeModal  : NgbActiveModal,
    public renderer : Renderer2,
    public invoiceService : InvoiceService,
    public agGridService:AgGridService ,
    public toaster: ToastrService,
  ) {
    super(renderer);
    this.gridOptions = {
      singleClickEdit : true, // Add other grid options as needed
    };
  }

  ngOnInit() : void {
    this.agGridSaleOrderList = AgGridSaleOrderList;
    const element = document.querySelector('.custom_focus') as any;
    element?.focus();
     console.log(this.data);
    
  }

 onKeyDown(event: KeyboardEvent | any) {
    event?.srcElement?.parentElement?.click();
  }

  onGridReady(event) {
    this.gridApiSaleOrder = event; 
    if (this.data.modalTitle == "Sale Order") {
    this.agGridSaleOrderRowData = this.data.saleOrderNoDropdownFilterData;
    } else if (this.data.modalTitle == "Estimate/Quotation") {
    this.agGridSaleOrderRowData = this.data.saleQuotationNoDropdownFilterData;
    } else if (this.data.modalTitle == "Delivery Note") {
    this.agGridSaleOrderRowData = this.data.deliveryNoteNoDropdownFilterData; 
    } else if (this.data.modalTitle == "Sales Invoice"){
    this.agGridSaleOrderRowData = this.data.salesInvoiceNoDropdownFilterData;
    }
    
}

  onApply(){
   this.requestIds  =  this.gridApiSaleOrder?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x)=>x?.active == true)?.map((y)=>y?.id);
  //  Not call Api if checkbox is not selected
   if (!this.requestIds || this.requestIds.length === 0) {
    this.activeModal.close(false);
    return;
   }
   if (this.data.modalTitle == "Sale Order") {
    this.voucherdata = VoucherType.SalesOrder;
    } else if (this.data.modalTitle == "Estimate/Quotation") {
        this.voucherdata = VoucherType.SalesQuotation;
    } else if (this.data.modalTitle == "Delivery Note") {
        this.voucherdata = VoucherType.DeliveryNote;
    } else if (this.data.modalTitle == "Sales Invoice"){
      this.voucherdata = VoucherType.SalesInvoice;
    }
   const requestData ={ids:this.requestIds ,voucherType:this.voucherdata}
    this.invoiceService.getBySaleOrderId(requestData).subscribe({
        next:(res)=>{
          if(res?.isSuccess){
            this.itemList = res?.response?.itemList;
            // save active checkbox
            this.gridApiSaleOrder?.api?.forEachNode((node) => {
              node.data.active = this.requestIds.includes(node.data.id);
              this.gridApiSaleOrder?.api?.refreshCells({ rowNodes: [node] });
            })
            this.activeModal.close(this.itemList??[]);
          }else{
            this.toaster.error(res.errors[0]);
            this.activeModal.close();
          }
        },error:(err)=>{
          console.log(err)
        }
    });
  }

  cleanupCheckboxes() {
    this.gridApiSaleOrder?.api?.forEachNode((node) => {
      if (node?.data?.active) {
        node.data.active = false;
        this.gridApiSaleOrder?.api?.refreshCells({ rowNodes: [node] });
      }
    });
  }

  closeModal() {
    this.cleanupCheckboxes();
    this.activeModal.dismiss();
  }

}