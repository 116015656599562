import { Component, Input, Renderer2 } from '@angular/core';
import { GetAllJewelryCatalogueRequestModel } from 'src/app/components/inventory/jewelry-catalogue/jewelry-catalogue-details/jewelry-catalogue-details.model';
import { JewelryCatalogueCommonService } from './jewelry-catalogue-common.service';
import { Router } from '@angular/router';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupSize } from '../../models/common.model';
import { ActivatedRoute } from '@angular/router';
import { Catelogue } from './jewelry-catalogue-common.model';
import { StorageService } from '../../services/storage.service';
import { CommonService } from '../../services/common.service';
import { PaginationPageSizes } from '../../components/common-model/common-model';
import { OpenImagePopupComponent } from 'src/app/shared/common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { LayoutDetails, User } from 'src/app/auth/auth/auth.model';
import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-jewelry-catalogue-common',
  templateUrl: './jewelry-catalogue-common.component.html',
  styleUrls: ['./jewelry-catalogue-common.component.scss']
})
export class JewelryCatalogueCommonComponent extends CommonService {
  paginationPageSizes            = new PaginationPageSizes();  
  getAllJewelryCatalogueRequestModel =new GetAllJewelryCatalogueRequestModel();
  jewelryCatalogueList: GetAllJewelryCatalogueRequestModel[];
  pagination:any ={};
  totalRecords
  @Input() catelogueForm=Catelogue.CustomerCatelogue;
  @Input()anonymousUrl;
  catelogue = Catelogue;
 
  public readonly pageLimitOptions = [
   { value: 25 },
   { value: 50 },
   { value: 75 },
 ];
 user:User;
 layoutDetails:LayoutDetails;

  constructor(
   private jewelryCatalogueCommonService: JewelryCatalogueCommonService,
   private router: Router,
   private activatedRoute: ActivatedRoute,
   private storageService: StorageService,
   public renderer: Renderer2,
   private storageservice:StorageService,
   private notificationService: NotificationService,
  ){
    super(renderer);
  };

  ngOnInit(){
    this.getAlljewelryCatalogueList();
    this.getLoginUserDetails()

    console.log(this.anonymousUrl ,'url');
    
  }

  getLoginUserDetails(){
    this.user =this.storageservice.retrieve('user');
    this.layoutDetails =this.storageservice.retrieve('layoutDetails');
  }

  getAlljewelryCatalogueList(){
    this.jewelryCatalogueCommonService.getAlljewelryCatalogue(this.getAllJewelryCatalogueRequestModel ,this.anonymousUrl).subscribe({
       next:(res)=>{
        if(res?.isSuccess){
           this.jewelryCatalogueList = res?.response??[];
           this.totalRecords = res?.totalRecords;
         }
        },error:(err)=>{
          console.log(err);
        }
    })
  }


onPageChanged(value) {
  this.getAllJewelryCatalogueRequestModel.page = value;
  this.getAllJewelryCatalogueRequestModel.getAll = false; 
  this.getAlljewelryCatalogueList()
}

onPageSizeChange() {
  if (this.getAllJewelryCatalogueRequestModel.pageSize == 0) {
      this.getAllJewelryCatalogueRequestModel.getAll = true;
      this.getAllJewelryCatalogueRequestModel.page = 0;
  } else {
      this.getAllJewelryCatalogueRequestModel.getAll = false;
      this.getAllJewelryCatalogueRequestModel.page =1;
  }
  this.getAlljewelryCatalogueList()
  
}

getStartRange(): number {
if (this.getAllJewelryCatalogueRequestModel.getAll) {
    return 1;
} else {
    return (this.getAllJewelryCatalogueRequestModel.page - 1) * this.getAllJewelryCatalogueRequestModel.pageSize + 1;
}
}

getEndRange(): number {
if (this.getAllJewelryCatalogueRequestModel.getAll) {
    return this.totalRecords;
} else {
    const endRange = this.getAllJewelryCatalogueRequestModel.page * this.getAllJewelryCatalogueRequestModel.pageSize;
    return endRange > this.totalRecords ? this.totalRecords : endRange;
}
}

navigateToDetails(id){
  switch (this.catelogueForm) {
    case Catelogue.CustomerCatelogue:
      if(id){
        this.storageService.store("JewelleryCatalogueId",id);
        }
      this.router.navigate(["app-jewelry/jeweleryDetailsPage"], {
        queryParams: { id: id },
      });
      break;
    case Catelogue.LoggedCustomerCatelogue:
      this.router.navigate(["Inventory/jewelry-Catalogue/product-details"], {
        queryParams: { id: id },
      });
      break;
    case Catelogue.InventoryCatelogue:
      if(id){
          this.storageService.store("JewelleryCatalogueId",id);
          }
      this.router.navigate(['/Inventory/jewelry-Catalogue/jewelry-Catalogue-detail'],
      {relativeTo: this.activatedRoute });
      break;
    case Catelogue.BillOfMaterialCatelogue:
      if(id){
          this.storageService.store("jewelaryBillOfMaterialId",id);
          }
      this.router.navigate(['/Inventory/jewelry-Catalogue/jewelry-Catalogue-detail'],
      {
        queryParams: { id: id },
      });
      break;
  }
}

openImgPopUp(selectedItem: any){
  const selectedIndex = this.jewelryCatalogueList.findIndex(item => item === selectedItem);
  var data = {
    jewelryCatalogue : [...selectedItem?.images],
    selectedIndex : selectedIndex,
  }
  this.notificationService.openModalPopup(OpenImagePopupComponent , data ,'' , ModalPopupSize.LG ,'image_popup_fullscreen',true,true).then(
    (result)=>{
      if(result){

      }
    }
  )
}

isCheckDefault(img){
 const isDefault =  img?.includes('default.png')
 return isDefault
}

syncJewellryCatalogueButton(){
  this.jewelryCatalogueCommonService.getSyncJewellryCatalogue().subscribe({
    next:(res)=>{
      if(res?.isSuccess){
        this.toaster.success(res?.message);
        this.getAlljewelryCatalogueList();
      }else{  
        this.toaster.error(res?.errors[0]);            
      }  
    },
    error:(err)=>{
    }
  })
}


}
