import { AfterViewInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconHover } from '../../../enum/common-enum';

@Component({
  selector: 'app-confirmation-pop-up',
  templateUrl: './confirmation-pop-up.component.html',
  styleUrls: ['./confirmation-pop-up.component.scss']
})
export class ConfirmationPopUPComponent implements AfterViewInit{
  @Input() titleShow :boolean = false
  currentHoverEffect = IconHover.Pink;
  @Input()data
  constructor(public activeModal:NgbActiveModal){
  }
  ngAfterViewInit(): void {
    const element = document.querySelectorAll('.custom_focus') as any;
    element[1]?.focus();
  }

  onSave(istrue){
    this.activeModal.close(istrue);
}

onKeyDown(event: KeyboardEvent | any) {
  event?.srcElement?.click();
}


}
