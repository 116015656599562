
<form>
    <div class="update_rate_box modal-body p-0">
        <div class="row pt-3 mx-0">
        <div class="col-12 d-flex justify-content-between gap-2">
            <app-select 
            [id]="'websiteURLId'"
            [name]="'websiteURL'"
            class="w-100"
            [options]="websiteURLDropdown"
            [selectedValue]="websiteURLId"
            (valueChanged)="updaterecentlyUsedRateWebsiteId($event)"
            >
            </app-select>
            <!-- <app-button 
                (buttonOutputEvent)="refresh()"            
                showRefreshIcon="true"  
                [buttonClass]="'white_button border-primary common-refresh-size gap-0'"
                [buttonType]="'button'">
            </app-button> -->
        </div>
        </div>
        <div class="row mx-0 pt-2 mx-1 px-2">
            <div class="col-12 height_table overflow-auto border px-0 m-0">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr class="border font-semibold f-14">
                            <th width="30%">Carat</th>
                            <th width="30%" class="text-end">Old Rate</th>
                            <th  width="30%" class="text-end">New Rate</th>
                            <th  width="10%"></th>
                        </tr>
                    </thead>
                    <tbody class="font-regular f-12">
                        <tr *ngFor="let item of websiteRateList" class="border">
                            <td class="bg_color">
                                <app-input 
                                [readOnly]="item?.caratName"
                                [inputClass]="'bordernone bg-transperent text-truncate'"
                                [inputName]="'caratName'+item?.id" 
                                [inputType]="'text'" 
                                [inputId]="'caratName'+item?.id" 
                                [inputValue]="item?.caratName" 
                                (inputValueEmit)="item.caratName = convertToNum($event) "
                                ></app-input></td>
                            <td class="bg_color">
                                <app-input 
                                [readOnly]="item.currentRate"
                                [inputClass]="'text-end bordernone bg-transperent text-truncate'"
                                [inputName]="'currentRate'+item?.id" 
                                [inputType]="'text'" 
                                [inputId]="'currentRate'+item?.id" 
                                [inputValue]="item?.currentRate?.toFixed(2)" 
                                (inputValueEmit)="item.currentRate = convertToNum($event) "
                                ></app-input>
                            </td>
                            <td>
                                <app-input 
                                [applyDecimalOrPercent]="true"
                                [decimalRestrictedDigit]="2"
                                [isBlureApplicable]="true"
                                [inputClass]="'text-end bordernone bg-transperent text-truncate'"
                                [inputName]="'newRate'+item?.id" 
                                [inputType]="'text'" 
                                [inputId]="'newRate'+item?.id" 
                                [inputValue]="item?.newRate" 
                                (inputValueEmit)="item.newRate = convertToNum($event) "
                                ></app-input>
                            </td>
                            <td class="text-center">
                                <svg class="cursorPointer mt-1" (click)="updateSingleNewRate(item)" id="Component_25_2" data-name="Component 25 – 2" xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 18.076 18.076">
                                    <path id="Path_1240" data-name="Path 1240" d="M-1816.65,2401.785v12.639a.374.374,0,0,0-.034.077,1.937,1.937,0,0,1-1.915,1.581q-7.093,0-14.187,0a1.933,1.933,0,0,1-1.939-1.939q0-7.094,0-14.187a1.934,1.934,0,0,1,1.461-1.887c.066-.018.131-.041.2-.062h12.639c.142.119.293.229.424.359q1.5,1.493,2.995,2.994C-1816.878,2401.491-1816.769,2401.643-1816.65,2401.785Zm-4.53-2.7v.218c0,1.318,0,2.636,0,3.954a1.965,1.965,0,0,1-2.052,2.054c-2.018,0-4.036,0-6.054,0a2.324,2.324,0,0,1-.692-.1,1.936,1.936,0,0,1-1.35-1.9c0-1.341,0-2.683,0-4.024v-.21h-1.352a.906.906,0,0,0-.985.989q0,1.571,0,3.142,0,5.427,0,10.855a.862.862,0,0,0,.773.953c.512.042,1.031.009,1.564.009v-.215c0-1.712,0-3.424,0-5.136a1.956,1.956,0,0,1,2.017-2.015q3.627,0,7.254,0a2.185,2.185,0,0,1,.506.055,1.949,1.949,0,0,1,1.5,1.964q0,2.568,0,5.136v.2a.26.26,0,0,0,.054.019c.435,0,.871,0,1.306,0a.9.9,0,0,0,.977-.979q0-5.86,0-11.72a.426.426,0,0,0-.112-.271c-.927-.937-1.866-1.862-2.788-2.8A.548.548,0,0,0-1821.18,2399.085Zm.062,15.933v-.175q0-2.577,0-5.154a.9.9,0,0,0-.988-.981h-7.166a.894.894,0,0,0-.984.985q0,2.568,0,5.136c0,.062.006.124.009.188Zm-9.139-15.942v.182q0,2,0,4.006a.892.892,0,0,0,.986.981c2.012,0,4.024-.006,6.036,0a.934.934,0,0,0,.987-.989c-.02-1.335-.006-2.671-.007-4.006,0-.057-.005-.114-.008-.178Z" transform="translate(1834.725 -2398.007)" fill="#5843cf"/>
                                </svg>                                                 
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-3 mx-3 mb-0 border-gray-1">
        <div class="modal-footer pt-3 pb-2 mx-1 px-2">
            <app-button class="my-0 me-3" (buttonOutputEvent)="close()" [buttonLabel]="'Close'"
                [buttonClass]="'pink_button'" [buttonType]="'button'">
            </app-button>
            <app-button class="m-0" [buttonLabel]="'Save'" [buttonClass]="'blue_button'" [buttonType]="'button'" (buttonOutputEvent)="save()">
            </app-button>  
        </div>
    </div>
</form>
