import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import {UpdateCurrentRateService} from './update-current-rate.service';
import {InsertUpdateNewRateModel } from './update-current-rate.model';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { SendDataService } from 'src/app/shared/services/send-data.service';
@Component({
  selector: 'app-update-current-rate',
  templateUrl: './update-current-rate.component.html',
  styleUrls: ['./update-current-rate.component.scss']
})
export class UpdateCurrentRateComponent extends CommonService implements OnInit {
  @Input() getAllDropdownModel      : any = {};
  websiteURLDropdown: any[]=[];
  websiteURLId:any;
  websiteRateList:any[];
  @Output() toggler = new EventEmitter();

  constructor(public sendDataService:SendDataService ,public toaster: ToastrService, public renderer:Renderer2, public storageService : StorageService, public updateCurrentRateService:UpdateCurrentRateService) 
  {super(renderer)}
  
  ngOnInit(): void {
    this.getAllNewRateList(true);
  }

 
  updateSingleNewRate(item){
    const insertUpdateNewRateModel = new InsertUpdateNewRateModel()
    insertUpdateNewRateModel.data =[{...item}];
    this.updateRate(insertUpdateNewRateModel)
  }

  save(){
    const insertUpdateNewRateModel = new InsertUpdateNewRateModel()
    var updatedList = this.websiteRateList.filter(item => item.newRate && item.newRate !== item.currentRate && item.newRate > 0);
    insertUpdateNewRateModel.data = JSON.parse(JSON.stringify(updatedList)) ;
    if(updatedList.length > 0)
    {
    this.updateRate(insertUpdateNewRateModel);
    this.toggler.emit();
    }
    else
      this.toaster.error("There is no change in Rates");
  }

  updateRate(insertUpdateNewRateModel){
    this.updateCurrentRateService.insertUpdateNewRate(insertUpdateNewRateModel).subscribe(res=>{
      if (res?.isSuccess) {
        this.getAllNewRateList(true);
        this.toaster.success(res?.message);
        this.sendDataService.notifyRateUpdate();
      }
    else {
      this.toaster.error(res?.errors[0]);
    }
    })
  }

  getAllNewRateList(isRefresh?: boolean): void {
    this.updateCurrentRateService.getAllNewRate().subscribe({
      next: (res) => {
        if (res?.isSuccess) {
          this.websiteURLDropdown = res?.response || [];
          const selectedWebsite = this.websiteURLDropdown?.find((x) => x?.extra2 === "true");
          if (selectedWebsite) {
            this.websiteURLId = selectedWebsite?.id;
            if (isRefresh) {
              this.getDataByWebsiteUrlId(this.websiteURLId);
            }
          }
        } else {
          this.websiteURLDropdown = [];
        }
      },
      error: (err) => {
        this.websiteURLDropdown = [];
      }
    });
  }
  
  getDataByWebsiteUrlId(id: string): void {
    const selectedWebsite = this.websiteURLDropdown?.find((x) => x?.id === id);
    
    if (selectedWebsite && selectedWebsite?.extra1) {
      this.websiteRateList = JSON.parse(selectedWebsite?.extra1);
    } else {
      this.websiteRateList = [];
    }
  }

  close(){
    this.toggler.emit();
  }

  refresh(){
    this.getAllNewRateList(true);
  }
} 
