import { Injectable } from '@angular/core';
import { IpcRenderService } from './ipc-render.service';
import { StorageService } from './storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageName } from '../enum/common-enum';

@Injectable({
  providedIn: 'root'
})
export class WeightMachineService {
  public destroy$ = new Subject<void>();
  public portsList: any[] = [];
  public serialData: string = '';
  storageName                                 = StorageName;
  constructor(
    public ipcService: IpcRenderService,
    
    private storageService: StorageService
  ) { }

  initializeWeightMachine(): void {
    this.ipcService.send('getPorts');

    // Listen for available ports
    this.ipcService.on('portsList', (event, ports) => {
      this.portsList = ports;
      
      this.storageService.store(this.storageName.PORT_DROPDOWN, this.portsList);

      // Automatically select the port to open based on saved preference
      const portToOpen = this.getSavedOrDefaultPort(ports);
      if (portToOpen) {
        this.openSelectedPort(portToOpen);
      }
    });

    // Listen for serial data
    this.ipcService.on('serialData', (event, data) => {
      this.handleSerialData(data);
    });

    // Listen for port opened event
    this.ipcService.on('portOpened', (event) => {
      
    });
  }

  // Function to stop listening to events when destroyed
  stopListening() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSavedOrDefaultPort(portsList: any[]): any {
    const savedPort = this.storageService.retrieve('Port');

    // let portToOpen =  JSON.stringify(portsList[1]);
    
    let portToOpen = portsList.find(p => p.path === savedPort) || portsList[0];
    

    return JSON.stringify(portToOpen);
  }

  openSelectedPort(port: any) {
    if (port) {
      this.ipcService.send('openPort', port);
    }
  }

  handleSerialData(data: string): string {
    this.serialData += data;
    const reading = this.serialData.replace(/[^0-9.]/g, '');
    if (/^\d+\.\d{2}$/.test(reading)) {
      
      this.serialData = ''; // Clear serial data buffer
      return reading; // Return reading for use elsewhere
    }
    return '';
  }
}
