export enum BlockUnblockStatus {
    Unblock = '0',
    Block = '1',
}

export class GetAllContactGroupList {
    serialNo?: number;
    groupName?: string;
    createdDate?: string;
    userCount?: number;
}