import { Component, Input, Renderer2, ViewChild ,ElementRef, AfterViewInit, OnDestroy, HostListener} from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MetalSectionService } from './metal-section.service';
import { KeyCode, StorageName } from '../../enum/common-enum';
import { MetalSectionChildComponent } from '../metal-section-child/metal-section-child.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-metal-section',
  templateUrl: './metal-section.component.html',
  styleUrls: ['./metal-section.component.scss']
})
export class MetalSectionComponent extends  CommonService  implements  AfterViewInit, OnDestroy  {
  @ViewChild(MetalSectionChildComponent) metalSectionChildComponent: MetalSectionChildComponent;
  @Input() formName:string;
  isPopupOpen: boolean = false;
  @Input() formModel:any;
  @Input() metalSectionModel:any;
  storage = StorageName;
  constructor(
     public renderer              :Renderer2,
 public modalService            :NgbModal,

    
    public metalSectionService: MetalSectionService,
    ){
     super(renderer);;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.keyCode === KeyCode.Q) {
      event.preventDefault();
      if (!this.isPopupOpen) {
        this.openMetalSectionPopup(undefined);
      }
    }
  }
  ngOnDestroy(): void {

   
  }
  ngAfterViewInit(): void {
  }

  openMetalSectionPopup(previousRow) {
    this.metalSectionModel.previousRow = previousRow;
    this.isPopupOpen = true; 
    this.metalSectionService.openMetalSectionPopup(this.metalSectionModel, this.formModel, this.formName)
      .then(() => { this.isPopupOpen = false; })
      .catch(() => { this.isPopupOpen = false; });
  }

  getProductDataByBarcodeNo()
  {
    this.metalSectionService.openMetalSectionPopup(this.metalSectionModel,this.formModel,this.formName,this.metalSectionModel.barcodeNo);
  }

  openModalBarcodePopup(): void {
    this.metalSectionChildComponent.openModalBarcodePopup();
  }

  setColumnsVisible(columnnames : any = undefined,visible : boolean = false)
  {
    this.metalSectionModel.columnForVisibility = columnnames;
    this.metalSectionModel.columnsVisible = visible;
    this.metalSectionChildComponent.setColumnsVisible();
  }

  checkAnyGridHasRowWithTabs() : any
  {
    return this.metalSectionChildComponent.checkAnyGridHasRowWithTabs();
  }

  internalFixChecked(value)
  {
      this.formModel.invoiceModel.internalFix = value;
  }
  metalFixChecked(value)
  {
    this.formModel.invoiceModel.metalFix = value;
  }
  vatChecked(value)
  {
    this.formModel.invoiceModel.vat = value;
  }

  KYCChecked(value)
  {
    this.formModel.invoiceModel.isKYC = value;
  }

  AMLChecked(value)
  {
    this.formModel.invoiceModel.isAML = value;
  }

}

