<div class="col d-flex align-items-center justify-content-center my-2 ngbNav-tabs">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs justify-content-between flow-hidden">
      <li *ngFor="let item of currentActiveTabItems" 
        [id]="metalSectionNavTab + item?.id" 
        [ngbNavItem]="item?.id" 
        [class.disabled]="disableOtherTab && activeTab !== item.id"
        (keydown)="handleTabKeyDown($event, item)">
        <a ngbNavLink class="d-flex" (click)="disableOtherTab && activeTab !== item.id ? $event.preventDefault() : selectTab(item.id,disableOtherTab)">
          <span class="d-flex justify-content-center f-16 font-semibold">{{ item?.name }}</span>
        </a>
      </li>
    </ul>
  </div>