export class Auth {}
export class LoginRequestModel 
{
  
  username        : string;
  password        : string;
  rememberMe : boolean;
  crid       : string;
}

export class OTPResponse 
{
  otpToken  : string;
}

export class VerifyOTPModel
 {
  phoneNumber : string;
  otpToken    : string;
  otp         : string;
}
export class ResendOTPModel 
{
  phoneNumber : string;
  otpToken    : string;
  countryCode    : string;
}

//Regestration form Model
export class registerBillingAddressModel {
  ledgerName    :string;
  email         :string;
  country       :string;
  addressLine1  :string | null;
  addressLine2  :string | null;
  stateId       :string | number | null;
  pinCode       : number | null;
}

export class LicenseKeyGenerateResponse 
{
  productKey      : string | null;
  licenseKeyToken : string | null;
}
export class LicenseKeyGenerateRequestModel
 {
  productKey  : string | null;
  OTPToken    : string | null;
}
export class LicenseKeyVerifyModelRequest 
{
  productKey      : string;
  licenseKey      : string;
  licenseKeyToken : string;
}



export class LoginResponseModel {
  token         : Token
  branchList    : BranchList[]
  financialYearList: FinancialYearList[]
}

export class Token 
{
  accessToken: string
  scope: string
  tokenType: string
  refreshToken: string
  expiresIn: number
}

export class BranchList 
{
  id: number
  name: string
  extra1: any
  extra2: any
  extra3: any
}

export class CompanyList 
{
  id: any
  name: string
  extra1: any
  extra2: any
}

export class FinancialYearList 
{
  id: number
  name: string
  extra1: string
  extra2: string
  extra3: string
}

export class InternalSigninRequestModel{
    branchId:any;
    financialYearId:any;
    companyId:any;
}


export class InternalSigninResponseModel {
  token: Token
  user: User
  layoutDetails: LayoutDetails
}


export class User {
  id: number;
  fullName: string;
  username: string;
  dob: any;
  contactNo: number;
  emailId: string;
  addressId: any;
  address: string;
  roleId: number;
  branchId: number;
  active: boolean;
  userProfileUrl:string;
  accountLedgerId:any;
  quickAccessData:string;
}

export class LayoutDetails {
  gstNo             : string;
  countryCode       : string;
  financialYearId   : number;
  financialYearName : string;
  branchId: number;
  branchName: string;
  companyName: any;
  companyLogo:any;
  userName: string;
  roleId: number;
  roleName: string;
  stateId :number;
  stateName :string;
  dialCode: string;
  isUserSuperAdmin : boolean;
  companyId : number;
}
 export  class UserSendOTPModel
  {
    phoneNumber :string ;
    countryCode :string ;
  }

  export class GetCountryDetailsResponse 
  {
    countryName : string | null;
    countryCode : string | null;
    dialCode    : string | null;
    flag        : string | null;
}

export class LicenseKeyVerifyResponse {
  verified: boolean;
  licenseKeySecurityToken: string | null;
  countryCode: string | null;
}

export class StatusResponseModel
{
  onboardingRequired : boolean;
}
export class ProductKeyResponseModel
{
  productKey : string;
}