<div class="modal-header d-flex align-items-center justify-content-center position-relative">
<div><span class="font-semibold text-primary">RFID Tag</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect"  class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form ngNativeValidate #rfidForm="ngForm">
<div class="modal-body mx-2">
    <div class="row mb-1">
    <div class="col-2 font-semibold text-gray-color d-flex align-items-center">RFID No.</div>
    <div class="col ps-0">
        <app-input 
        [inputName]="'RFID No.'" 
        [inputId]="'RFIDNo'" 
        [inputType]="'text'" 
        [inputValue]="data?.rfid"
        (inputValueEmit)="data.rfid=$event"
        >
        </app-input>
    </div>
    </div>
</div>
<div class="modal-footer pt-3 pb-2 mx-2">
    <app-button class="my-0 me-3" (buttonOutputEvent)="dismiss()" [buttonLabel]="'Close'" [buttonClass]="'white_button'" [buttonType]="'button'">
    </app-button>
    <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="updateRFID()" [buttonClass]="'blue_button'" [buttonType]="'submit'">
    </app-button>
</div>
</form>
