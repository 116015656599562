import { Component, Input, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { TransactionService } from '../../transaction.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { catchError } from 'rxjs';
import { agGridDataModelAddDiamondModelTable, agGridDataModelAddDiamondTable } from '../add-diamond/add-diamond.model';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { MetalSectionService } from 'src/app/shared/common-components/metal-section/metal-section.service';
import { IconHover } from '../../../../shared/enum/common-enum';
import { ProductOpeningComponent } from 'src/app/components/Product/product-opening/product-opening.component';
import { CaratPopupComponent } from 'src/app/shared/common-components/common-popup/carat-popup/carat-popup.component';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { DiamondCategory } from 'src/app/shared/models/common.model';
@Component({
  selector: 'app-add-diamond',
  templateUrl: './add-diamond.component.html',
  styleUrls: ['./add-diamond.component.scss']
})
export class AddDiamondComponent  extends CommonService{
  @Input() modalTitle                 : string;
  @Input() data                       : any ;
  gridApi                             : any;
  agGridDataModel                     : AgGridI;
  currentHoverEffect                  = IconHover.Pink
  @Input() adddiaomodForm: boolean = false;
  storage = StorageName;
  constructor(
    private agGridService: AgGridService,
    public activeModal  : NgbActiveModal,
    private transactionService :TransactionService,
    private metalSectionService :MetalSectionService,
    private storageservice: StorageService,
    public renderer                   : Renderer2
  ) {super(renderer);}

  ngOnInit(): void {
    if(this.data?.adddiaomodForm ===false)
      this.agGridDataModel  = agGridDataModelAddDiamondTable;
    else
      this.agGridDataModel = agGridDataModelAddDiamondModelTable
  }

  OnSaveAdditionalAmount(paymentType){
    var rowData = [];
    if(this.data?.adddiaomodForm === false)
      rowData = this.gridApi.api.getSelectedRows();
    else
      this.gridApi.api.forEachNode(node => {
        rowData.push({
          ...node.data,
          detailedJson: JSON.stringify(node.data),
          purityWt : node.data.carat,
          grossWt : node.data.weight,
          diamondCategoryId: (paymentType === 'Metal Exchange' && this.modalTitle === 'Add Diamond') ? DiamondCategory.Diamonds.toString() :
                             (paymentType === 'Metal Exchange' && this.modalTitle === 'Add Stones') ? DiamondCategory.GemStones.toString() : null,

        });
      });
    this.activeModal.close({ response: rowData, isSuccess: true , from : this.modalTitle , paymentType : paymentType });
  }

  onGridReady(event){
      this.gridApi  = event; 
      if(this.data?.adddiaomodForm ===false)
        this.getAllDiamondList();
  }


  onCellValueChangedDetect(params) {
    if(params.column.colId != "purchaseAmount")
    {
      params.data = this.metalSectionService.calculateDiamondItem(params.data,params.column.colId,); 
      params.data.weight = this.fixWeightDecimal(params.data.weight );
      params.data.grossWt  = this.fixWeightDecimal(params.data.grossWt );
      params.data.carat  = this.fixWeightDecimal(params.data.carat);
      this.gridApi?.api?.refreshCells();
    }
  }

  openPopup(params){
    
    switch (params?.detail?.data?.displayName) {
      case 'Product': this.agGridService.openModal(ProductOpeningComponent, params, this.storage.PRODUCT_DROPDOWN, 'productDetailId'); break;
      case 'Gold Carat': this.agGridService.openModal(CaratPopupComponent, params, this.storage.CARAT_DROPDOWN, 'caratId'); break;
    }
  }

  getAllDiamondList() {
    this.showLoading(this.gridApi);
    this.transactionService.getdiamondstock().pipe(catchError((err) => this.handleError(err))).subscribe((res) => {
      if (res.isSuccess) {
          this.hideLoading(this.gridApi);
          this.gridApi.api.setRowData(res.response.data);
          this.storageservice.store(StorageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID_INITIAL_ROWS, res.response.data);
      } else this.hideLoading(this.gridApi);
    });
  }
}