<div class="d-flex flex-column" (click)="onClick($event)">
    <span class="mx-2  text"  [ngClass]="{'cursorPointer': isCursor ,'d-block h-100':params?.widthFull}">{{name}}</span>
    <span *ngIf="!params?.invoiceNumber" class="mx-2 text2" [ngClass]="{'cursorPointer': isCursor ,'d-block h-100':params?.widthFull}">{{value}}</span>
    <span *ngIf="params?.invoiceNumber" class="mx-2 text2" [ngClass]="{'cursorPointer': isCursor ,'d-block h-100':params?.widthFull}">{{value}}  
        <ng-container *ngIf="params?.data?.againstInvoiceNo">
        (Against Of {{params?.data?.againstInvoiceNo}})
        </ng-container>
    </span>
</div>
<span *ngIf="worklogReport==true" class="mx-2 d-block h-100">{{params?.data?.date}}</span>

