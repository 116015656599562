import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { MetalSectionNavTab, StorageName } from '../../enum/common-enum';
import { VoucherTypeName } from '../../models/common.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent {
  metalSectionNavTab = MetalSectionNavTab;
  @Input() tabItems: any[];
  currentActiveTabItems: any[]=[];
  @Output() tabId: EventEmitter<number> = new EventEmitter();
  activeTab: number = 1;
  disableOtherTab : boolean = false;
  voucherTypeName : typeof VoucherTypeName = VoucherTypeName;
  @Input() voucherType
  constructor(private storageService:StorageService,public router: Router){
  }

  selectTab(tabId: any,disableOtherTab = false): void {
    this.activeTab = tabId;
   // this.disableOtherTab = disableOtherTab;
    this.tabId.emit(tabId)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabItems) {
      this.currentActiveTabItems = this.tabItems;
      this.ensureActiveTabIsValid();
    }
  }

  ensureActiveTabIsValid(): void {
    const activeTabExists = this.currentActiveTabItems.some(item => item.id === this.activeTab);
    if (!activeTabExists && this.currentActiveTabItems.length > 0) {
      this.activeTab = this.currentActiveTabItems[0].id;
      this.tabId.emit(this.activeTab);
    }
  }


  @ViewChild('nav') nav: any; // Use ViewChild on ngbNav


  handleTabKeyDown(event: KeyboardEvent,item:any): void {
    // Handle keydown event here

    
    const lastIndexTab = this.currentActiveTabItems[this.currentActiveTabItems.length-1];
    
    // const lastIndexTab = this.tabItems[this.tabItems.length-1];
    if (event.key === 'Enter') {
      this.activeTab = item.id;
      this.storageService.onNavTabKeyPressed.next(this.activeTab);
    }
    else if(event.key  == 'Tab' && item.id == lastIndexTab?.id ){
      event.preventDefault();
      this.storageService.onNavTabKeyPressed.next(this.activeTab);
    } 
    else if (event.key === 'Backspace' && event.shiftKey) {
    }
  }

  focusNextTab(): void {
    // const currentIndex = this.tabItems.findIndex((item) => item.id === this.activeTab);
    // const nextIndex = (currentIndex + 1) % this.tabItems.length;
    // this.nav.nativeElement.blur(); // Remove focus from the current tab
    // this.nav = this.getTabLinkElement(nextIndex);
    // this.nav.nativeElement.focus(); // Focus on the next tab
  }

  focusPrevTab(): void {
    // const currentIndex = this.tabItems.findIndex((item) => item.id === this.activeTab);
    // const prevIndex = (currentIndex - 1 + this.tabItems.length) % this.tabItems.length;
    // this.nav.nativeElement.blur(); // Remove focus from the current tab
    // this.nav = this.getTabLinkElement(prevIndex);
    // this.nav.nativeElement.focus(); // Focus on the previous tab
  }

  getTabLinkElement(index: number): ElementRef {
    // Retrieve the ElementRef of the tab link at the specified index
    // This method assumes that your tabItems array has a corresponding order with the tab links
    return this.tabItems[index].id;
  }

 resetTabColor(): void {
    this.currentActiveTabItems.forEach(item => item.color = null);
    this.ensureActiveTabIsValid(); 
  }


}

