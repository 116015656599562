import { TwoDigitDecimaNumberDirective } from './directives/two_digit_decimal_number.directive';
import { LoadDropdownDataComponent } from '../shared/common-components/load-dropdown-data/load-dropdown-data.component';
import { MetalSectionChildComponent } from '../shared/common-components/metal-section-child/metal-section-child.component';
import { MetalSectionComponent } from '../shared/common-components/metal-section/metal-section.component';

import { MetalSectionReadonlyComponent } from '../shared/common-components/metal-section-readonly/metal-section-readonly.component';
import { TotalSectionComponent } from '../shared/common-components/total-section/total-section.component';
import { PaymentSectionComponent } from '../shared/common-components/payment-section/payment-section.component';
import { ItemListSectionComponent } from '../shared/common-components/item-list-section/item-list-section.component';
import { TaxSectionComponent } from '../shared/common-components/tax-section/tax-section.component';

import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDropzoneModule } from 'ngx-dropzone';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditorModule } from 'ng2-ckeditor';

// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";

// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";

// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { AgGridModule } from "ag-grid-angular";

//Common-Components
import { RangeDatepickerComponent } from './common-components/range-datepicker/range-datepicker.component';
import { InputComponent } from './common-components/input/input.component';
import { ButtonComponent } from './common-components/button/button.component';
import { CheckboxComponent } from './common-components/checkbox/checkbox.component';
import { OnlyAlphabetsDirective } from './directives/only-alphabets-directive';
import { PaginationComponent } from './common-components/pagination/pagination.component';
import { CheckboxRendererComponent } from './common-components/ag-grid/checkbox-renderer/checkbox-renderer.component';
import { TranslateModule } from "@ngx-translate/core";
import { AgGridComponent } from "./common-components/ag-grid/ag-grid.component";
import { SearchInputComponent } from './common-components/search-input/search-input.component';
import { CustomNgbDropdownComponent } from './common-components/custom-ngb-dropdown/custom-ngb-dropdown.component';
import { EditDeleteIconComponent } from './common-components/ag-grid/edit-delete-icon/edit-delete-icon.component';
import { PlusIconComponent } from './common-components/ag-grid/plus-icon/plus-icon.component';
import { AgGridCustomDropdownComponent } from './common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component';
import { CustomHeaderComponent } from './common-components/ag-grid/custom-header/custom-header.component';
import { ColumnFilterComponent } from './common-components/ag-grid/column-filter/column-filter.component';
import { JwellaryImageComponent } from './common-components/ag-grid/jwellary-image/jwellary-image.component';
import { OnlyNumbersDirective } from './directives/only-number-directive';
import {NotNagativeDecimalNumberDirective} from './directives/not-negative-decimal_number.directive'
import { OnlyAlphabetsAndNumbersDirective } from './directives/only_alphabets_&_numbers';
import { NumberWithDecimalDirective } from './directives/number-with-decimal.directive';
import { NoMultipleDecimalsDirective } from './directives/no-multiple-decimals.directive';
import { InputWithIconComponent } from './common-components/input-with-icon/input-with-icon.component';
import { SelectComponent } from './common-components/select/select.component';
import { ColumnsSettingsComponent } from './common-components/ag-grid/columns-settings/columns-settings.component';
import { AgGridCustomInputComponent } from './common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component';
import { IconWithInputComponent } from './common-components/icon-with-input/icon-with-input.component';
import { SelectWithIconComponent } from './common-components/select-with-icon/select-with-icon.component';
import { PurityComponent } from './master-component/purity/purity.component';
import { CaratComponent } from './master-component/carat/carat.component';
import { UnitComponent } from './master-component/unit/unit.component';
import { LocationComponent } from './master-component/location/location.component';
import { CollectionComponent } from './master-component/collection/collection.component';
import { RemarkComponent } from './master-component/remark/remark.component';
import { CategoryComponent } from './master-component/category/category.component';
import { TextRendererComponent } from './common-components/ag-grid/text-renderer/text-renderer.component';
import { IconDatepickerComponent } from './common-components/icon-datepicker/icon-datepicker.component';
import { ColorComponent } from './master-component/color/color.component';
import { CurrencyComponent } from './master-component/currency/currency.component';
import { DiamondSizeComponent } from './master-component/diamond-size/diamond-size.component';
import { ExchangeRateComponent } from './master-component/exchange-rate/exchange-rate.component';
import { MetalComponent } from './master-component/metal/metal.component';
import { SeiveSizeComponent } from './master-component/seive-size/seive-size.component';
import { SettingTypeComponent } from './master-component/setting-type/setting-type.component';
import { UnitConversionComponent } from './master-component/unit-conversion/unit-conversion.component';
import { ScanPrintIconComponent } from './common-components/ag-grid/scan-print-icon/scan-print-icon.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MultiSelectDropdownComponent } from "./common-components/ag-grid/multi-select-dropdown/multi-select-dropdown.component";
import { CustomExpandableComponent } from './common-components/ag-grid/custom-expandable/custom-expandable.component';
import { ToggleButtonsComponent } from './common-components/ag-grid/toggle-buttons/toggle-buttons.component';
import { NgMultiSelectDropdownComponent } from './common-components/ng-multi-select-dropdown/ng-multi-select-dropdown.component';
import { ResetRenderComponent } from './common-components/ag-grid/reset-render/reset-render.component';
import { CustomSelect2Component } from './common-components/custom-select2/custom-select2.component';
import { Select2Module } from 'ng-select2-component';
import { RadioRendererComponent } from './common-components/ag-grid/radio-renderer/radio-renderer.component';
import { TimePickerComponent } from './common-components/time-picker/time-picker.component';
import { ShapeComponent } from './master-component/shape/shape.component';
import { CutComponent } from './master-component/cut/cut.component';
import { SizeComponent } from './master-component/size/size.component';
import { DocumentTypeComponent } from './master-component/document-type/document-type.component';
import { CounterComponent } from './master-component/counter/counter.component';
import { RadioButtonComponent } from './common-components/radio-button/radio-button.component';
import { DatepickerRendererComponent } from './common-components/ag-grid/datepicker-renderer/datepicker-renderer.component';
import { SaveComponent } from './common-components/pop-ups/save/save.component';
import { CustomHeaderParentComponent } from './common-components/ag-grid/custom-header-parent/custom-header-parent.component';
import { SaveChangePopupComponent } from './common-components/common-popup/save-change-popup/save-change-popup.component';
import { MatchPasswordDirective } from './directives/match-password.directive';
import { LazySrcDirective } from './directives/lazy-src.directive';
import { NavTabsComponent } from './common-components/nav-tabs/nav-tabs.component';
import { PacketTypeComponent } from './master-component/packet-type/packet-type.component';
import { AgGridInnerHTMLDisplayComponent } from './common-components/ag-grid/ag-grid-inner-htmldisplay/ag-grid-inner-htmldisplay.component';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component'
import { ZeroToOneNumberDirective } from './directives/app_zero_to_one_number';
import { AgGridButtonCellrendererComponent } from './common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.component'
import { ConfirmationPopUPComponent } from './common-components/common-popup/confirmation-pop-up/confirmation-pop-up.component'
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { PrintConfirmationComponent } from './common-components/common-popup/print-confirmation/print-confirmation.component';
import { PrintPreviewComponent } from './common-components/common-popup/print-preview/print-preview.component';
import { DeleteConfirmationComponent } from './common-components/common-popup/delete-confirmation/delete-confirmation.component';
import { AgGridCustomStatusDropdownComponent } from './common-components/ag-grid/ag-grid-custom-status-dropdown/ag-grid-custom-status-dropdown.component';
import { PreviewIconComponent } from './common-components/ag-grid/preview-icon/preview-icon.component';
import { ErrorPopupComponent } from './common-components/common-popup/error-popup/error-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RfidIconComponent } from './common-components/ag-grid/rfid-icon/rfid-icon.component';
import { InputDatepickerRendererComponent } from './common-components/ag-grid/input-datepicker-renderer/input-datepicker-renderer.component';
import { CaratPopupComponent } from './common-components/common-popup/carat-popup/carat-popup.component';
import { PurityPopupComponent } from './common-components/common-popup/purity-popup/purity-popup.component';
import { ReferAndEarnComponent } from './common-components/pop-ups/refer-and-earn/refer-and-earn.component';
import { AddImageComponent } from './common-components/common-popup/add-image/add-image.component';
import { StatusCellRendererComponent } from './common-components/ag-grid/status-cell-renderer/status-cell-renderer.component';
import { MaxLengthDirective } from './directives/max-length.directive';
import { ReceiveButtonCellComponent } from './common-components/ag-grid/receive-button-cell/receive-button-cell.component';
import { MinMaxValueDirective } from './directives/min-max-value.directive';
import { JewelryCatalogueCommonComponent} from '../shared/common-components/jewelry-catalogue-common/jewelry-catalogue-common.component';
import { ClarityComponent } from './master-component/clarity/clarity.component';
import { NumericCellRendererComponent } from './common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component';
import { DateCellRendererComponent } from './common-components/ag-grid/date-cell-renderer/date-cell-renderer.component';
import { CallApiConfirmComponent } from './common-components/common-popup/call-api-confirm/call-api-confirm.component';
import { AgGridCustomSelect2Component } from './common-components/ag-grid/ag-grid-custom-select2/ag-grid-custom-select2.component';
import { UpdateCurrentRateComponent } from './components/header/elements/update-current-rate/update-current-rate.component';
import { DropdownComponent } from './common-components/dropdown/dropdown.component';
import { TrackOrderCellrenderComponent } from './common-components/ag-grid/track-order-cellrender/track-order-cellrender.component';
import { NoPermissionViewComponent } from './common-components/no-permission-view/no-permission-view.component';
import { KeyboardShortcutsComponent } from './common-components/pop-ups/keyboard-shortcuts/keyboard-shortcuts.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MultiselectFilterComponent } from './common-components/ag-grid/column-filter/pop-ups/multiselect-filter/multiselect-filter.component';
import { FilterPipe } from './directives/filter.pipe';
import { BillingAddressComponent } from '../shared/common-components/common-voucher/popup/customer-details/tabs/billing-address/billing-address.component';
import { ShippingAddressComponent } from '../shared/common-components/common-voucher/popup/customer-details/tabs/shipping-address/shipping-address.component';
import { ProductsDetailsCommonComponent } from './common-components/jewelry-products/products-details-common/products-details-common.component';
import { ProductCheckoutComponent } from './common-components/jewelry-products/product-checkout/product-checkout.component';
import { OrderSuccessfulComponent } from './common-components/jewelry-products/order-successful/order-successful.component';
import { EditnewComponent } from './common-components/ag-grid/editnew/editnew.component';
import { RfidTagCodeComponent } from './common-components/pop-ups/rfid-tag-code/rfid-tag-code.component';
import { ClosingComponent } from './common-components/pop-ups/closing/closing.component';
import { UploadDocumentsComponent } from './common-components/upload-documents/upload-documents.component';
import { ExportDropdownComponent } from './common-components/export-dropdown/export-dropdown.component';
import { BusinessRegistrationComponent } from '../auth/onboarding/business-registration/business-registration.component';
import { BankDetailsRegistrationComponent } from '../auth/onboarding//business-registration/bank-details-registration/bank-details-registration.component';
import { CompanyRegistrationComponent } from '../auth/onboarding//business-registration/company-registration/company-registration.component';
import { UserDetailsRegistrationComponent } from '../auth/onboarding/business-registration/user-details-registration/user-details-registration.component';
import { InputWithSelectDropdownComponent } from './common-components/input-with-select-dropdown/input-with-select-dropdown.component';
import { CustomApxChartComponent } from './components/custom-apx-chart/custom-apx-chart.component';
import { PermissionGuard} from './guard/permission.guard'
import { RfidTagComponent } from '../components/inventory/stock-report/popup/rfid-tag/rfid-tag.component';
import { NotificationSidebarComponent } from './components/header/notification-sidebar/notification-sidebar.component';
import { RelativeTimePipe } from './custom-pipes/relative-time.pipe';
import { CustomFilterPipe } from './custom-pipes/custom-filter.pipe';
import { ClosingReportEditIconComponent } from './common-components/ag-grid/closing-report-edit-icon/closing-report-edit-icon.component';
import { TaskTypeComponent } from './master-component/task-type/task-type.component';
import { PrinterService} from './services/printer.service';
import { NumericTextRendererComponent } from './common-components/ag-grid/numeric-text-renderer/numeric-text-renderer.component';
import { NoEmojiDirective } from './directives/no-emoji.directive';
import { DraggableModalDirective } from './directives/app-draggable-modal.directive';
import { AddEditCommentComponent } from './common-components/pop-ups/add-edit-comment/add-edit-comment.component';
import { AppCommentComponent } from './common-components/app-comment/app-comment.component';
import { ExpensesCategoryComponent } from './master-component/expenses-category/expenses-category.component';
import { ToggleSwitchComponent } from './common-components/toggle-switch/toggle-switch.component';
import { ExpensesCategoryPopupComponent } from './common-components/common-popup/expenses-category-popup/expenses-category-popup.component';
import { LoanProductTypeComponent } from './master-component/loan-product-type/loan-product-type.component';
import { LoanReasonComponent } from './master-component/loan-reason/loan-reason.component';
import { CommonCommentComponent } from './common-components/common-comment/common-comment.component';
import { CommentSectionComponent } from './common-components/comment-section/comment-section.component';
import { DescriptionCellRenderComponent } from './common-components/ag-grid/description-cell-render/description-cell-render.component';
import { TextRendererTwoSpanComponent } from './common-components/ag-grid/text-display-renderer/text-renderer-two-span.component';
import { AdditionalAmountComponent } from './common-components/common-voucher/popup/additional-amount/additional-amount.component';
import { CustomerDetailsComponent } from './common-components/common-voucher/popup/customer-details/customer-details.component';
import { AgainstOfPopupComponent } from './common-components/common-voucher/popup/against-Of/against-of-popup.component';
import { SalesModeComponent } from './common-components/common-voucher/popup/sales-mode/sales-mode.component';
import { SaveInvoiceComponent } from './common-components/common-voucher/popup/save-invoice/save-invoice.component';
import { OpenImagePopupComponent } from './common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { AdvancedFilterPopUpComponent } from './common-components/advanced-filter-pop-up/advanced-filter-pop-up.component';
import {ImageCarouselComponent} from './common-components/image-carousel/image-carousel.component'
import { AccountLedgerAdvancedFilterComponent } from '../components/product/account-ledger/advanced-filter-popup/advanced-filter-popup.component';
import { FundTransferComponent } from './common-components/common-voucher/popup/fund-transfer/fund-transfer.component';
import { BreakTypeComponent } from './master-component/break-type/break-type.component';
import { MinTimeValidatorDirective } from './directives/min-time-validator.directive';
import { TwoHeaderlineSpanComponent } from './common-components/ag-grid/two-headerline-span/two-headerline-span.component';
import { CampaignGroupComponent } from './master-component/campaign-group/campaign-group.component';
import { SelectPrintFormatPopupComponent } from './common-components/common-voucher/popup/select-print-format-popup/select-print-format-popup.component';
import { ExchangeRatesComponent } from './common-components/common-voucher/popup/exchange-rates/exchange-rates.component';
import { CameraComponent } from './common-components/camera/camera.component';
import { CamViewComponent } from './common-components/common-popup/cam-view/cam-view.component';
import { WebcamModule } from 'ngx-webcam';
import { ColorPickerService } from 'ngx-color-picker';
import { PaymentTypePopupsComponent } from './common-components/pop-ups/payment-type-popups/payment-type-popups.component';
import { MetalSectionChildAppraisalComponent } from './common-components/metal-section-child-appraisal/metal-section-child-appraisal.component';
import { AppMetalAppraisalComponent } from './common-components/metal-section-child-appraisal/app-metal-appraisal/app-metal-appraisal.component';
import { ShimmeringEffectComponent } from './common-components/shimmering-effect/shimmering-effect.component';
import { AdvancedFilterPopupComponent } from './common-components/jewelry-catalogue-common/advanced-filter-popup/advanced-filter-popup.component';
import { FocusDirective } from './directives/focus.directive';
import { CustomMonthPickerComponent } from './common-components/custom-month-picker/custom-month-picker.component';

@NgModule({
  declarations: [
    DraggableModalDirective,
    OpenImagePopupComponent,
    AdditionalAmountComponent,
    AgainstOfPopupComponent,
    SalesModeComponent,
    SaveInvoiceComponent,
    AccountLedgerAdvancedFilterComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SvgIconComponent,
    SwiperComponent,
    RangeDatepickerComponent,
    InputComponent,
    ButtonComponent,
    CheckboxComponent,
    PaginationComponent,
    CheckboxRendererComponent,
    AgGridComponent,
    SearchInputComponent,
    CustomNgbDropdownComponent,
    EditDeleteIconComponent,
    PlusIconComponent,
    AgGridCustomDropdownComponent,
    CustomHeaderComponent,
    JwellaryImageComponent,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    OnlyAlphabetsAndNumbersDirective,
    NotNagativeDecimalNumberDirective,
    NumberWithDecimalDirective,
    NoMultipleDecimalsDirective,
    InputWithIconComponent,
    SelectComponent,
    ColumnsSettingsComponent,
    AgGridCustomInputComponent,
    IconWithInputComponent,
    SelectWithIconComponent,
    PurityComponent,
    CaratComponent,
    UnitComponent,
    LocationComponent,
    CollectionComponent,
    RemarkComponent,
    CategoryComponent,
    TextRendererComponent,
    IconDatepickerComponent,
    ColorComponent,
    CurrencyComponent,
    DiamondSizeComponent,
    ExchangeRateComponent,
    MetalComponent,
    SeiveSizeComponent,
    SettingTypeComponent,
    UnitConversionComponent,
    ScanPrintIconComponent,
    MultiSelectDropdownComponent,
    LoadDropdownDataComponent,
    MetalSectionComponent,
    MetalSectionChildComponent,
    MetalSectionReadonlyComponent,
    TotalSectionComponent,
    CustomExpandableComponent,
    CustomerDetailsComponent,
    ToggleButtonsComponent,
    NgMultiSelectDropdownComponent,
    ResetRenderComponent,
    CustomSelect2Component,
    RadioRendererComponent,
    TimePickerComponent,
    ShapeComponent,
    CutComponent,
    SizeComponent,
    DocumentTypeComponent,
    CounterComponent,
    RadioButtonComponent,
    DatepickerRendererComponent,
    SaveComponent,
    CustomHeaderParentComponent,
    SaveChangePopupComponent,
    MatchPasswordDirective,
    LazySrcDirective,
    FocusDirective,
    NavTabsComponent,
    PacketTypeComponent,
    AgGridInnerHTMLDisplayComponent,
    TwoDigitDecimaNumberDirective,
    CustomLoaderComponent,
    ZeroToOneNumberDirective,
    AgGridButtonCellrendererComponent,
    ConfirmationPopUPComponent,
    PrintConfirmationComponent,
    PrintPreviewComponent,
    PaymentSectionComponent,
    ItemListSectionComponent,
    TaxSectionComponent,
    DeleteConfirmationComponent,
    AgGridCustomStatusDropdownComponent,
    PreviewIconComponent,
    ErrorPopupComponent,
    RfidIconComponent,
    InputDatepickerRendererComponent,
    ColumnFilterComponent,
    CaratPopupComponent,
    PurityPopupComponent,
    ReferAndEarnComponent,
    AddImageComponent,
    StatusCellRendererComponent,
    MaxLengthDirective,
    ReceiveButtonCellComponent,
    MinMaxValueDirective,
    JewelryCatalogueCommonComponent,
    ClarityComponent,
    NumericCellRendererComponent,
    DateCellRendererComponent,
    CallApiConfirmComponent,
    AgGridCustomSelect2Component,
    UpdateCurrentRateComponent,
    DropdownComponent,
    TrackOrderCellrenderComponent,
    NoPermissionViewComponent,
    KeyboardShortcutsComponent,
    MultiselectFilterComponent,
    FilterPipe,
    BillingAddressComponent,
    ShippingAddressComponent,
    ProductsDetailsCommonComponent,
    ProductCheckoutComponent,
    OrderSuccessfulComponent,
    EditnewComponent,
    RfidTagCodeComponent,
    ClosingComponent,
    RfidTagComponent,
    UploadDocumentsComponent,
    ExportDropdownComponent,
    BusinessRegistrationComponent,
    CompanyRegistrationComponent,
    BankDetailsRegistrationComponent,
    UserDetailsRegistrationComponent,
    InputWithSelectDropdownComponent,
    CustomApxChartComponent,
    NotificationSidebarComponent,
    RelativeTimePipe,
    CustomFilterPipe,
    ClosingReportEditIconComponent,
    TaskTypeComponent,
    NumericTextRendererComponent,
    NoEmojiDirective,
    AddEditCommentComponent,
    AppCommentComponent,
    ExpensesCategoryComponent,
    ToggleSwitchComponent,
    ExpensesCategoryPopupComponent,
    LoanProductTypeComponent,
    LoanReasonComponent,
    CommonCommentComponent,
    CommentSectionComponent,
    DescriptionCellRenderComponent,
    TextRendererTwoSpanComponent,
    AdvancedFilterPopUpComponent,
    ImageCarouselComponent,
    FundTransferComponent,
    BreakTypeComponent,
    MinTimeValidatorDirective,
    TwoHeaderlineSpanComponent,
    CampaignGroupComponent,
    SelectPrintFormatPopupComponent,
    ExchangeRatesComponent,
    CameraComponent,
    CamViewComponent,
    PaymentTypePopupsComponent,
    MetalSectionChildAppraisalComponent,
    AppMetalAppraisalComponent,
    ShimmeringEffectComponent,
    AdvancedFilterPopupComponent,
    CustomMonthPickerComponent,
  ],
  imports: [

    CommonModule, 
    RouterModule, 
    WebcamModule,
    FormsModule, 
    ReactiveFormsModule, 
    NgbModule, 
    SwiperModule,
    AgGridModule,
    TranslateModule,
    NgxDropzoneModule,
    NgMultiSelectDropDownModule,
    Select2Module,CKEditorModule,
    DragDropModule,
    NgApexchartsModule,

    ],  
  providers: [NavService, ColorPickerService,LayoutService,PrinterService, DecimalPipe,CanDeactivateGuard,PermissionGuard],
  exports: [    DraggableModalDirective, AccountLedgerAdvancedFilterComponent,DragDropModule,CustomLoaderComponent,LazySrcDirective,FocusDirective,ResetRenderComponent,CustomExpandableComponent,NgbModule, FormsModule,WebcamModule,ColumnsSettingsComponent, ReactiveFormsModule, LoaderComponent, BreadcrumbComponent, FeatherIconsComponent, TapToTopComponent, SvgIconComponent, SwiperModule, AgGridModule,RangeDatepickerComponent ,IconDatepickerComponent,ButtonComponent ,InputComponent,CheckboxComponent,PaginationComponent ,CheckboxRendererComponent ,TranslateModule, AgGridComponent, SearchInputComponent,InputWithIconComponent,IconWithInputComponent,SelectComponent,SelectWithIconComponent,
    PacketTypeComponent,CustomNgbDropdownComponent,CustomerDetailsComponent,NgxDropzoneModule, PlusIconComponent, EditDeleteIconComponent,AgGridCustomDropdownComponent,CustomHeaderComponent,JwellaryImageComponent,OnlyAlphabetsDirective,OnlyNumbersDirective,OnlyAlphabetsAndNumbersDirective,NumberWithDecimalDirective,PurityComponent,BreakTypeComponent,CampaignGroupComponent,CaratComponent,UnitComponent,LocationComponent,CollectionComponent,RemarkComponent,CategoryComponent,TextRendererComponent,IconDatepickerComponent,ColorComponent,CurrencyComponent,CustomSelect2Component,NumericCellRendererComponent,
    DiamondSizeComponent,ExchangeRateComponent,MetalComponent,SeiveSizeComponent,SettingTypeComponent,UnitConversionComponent,NgMultiSelectDropDownModule,MultiSelectDropdownComponent,LoadDropdownDataComponent, MetalSectionComponent,MetalSectionChildComponent,MetalSectionReadonlyComponent,TotalSectionComponent,NgMultiSelectDropdownComponent,RadioRendererComponent ,TimePickerComponent,ShapeComponent,CutComponent,SizeComponent,DocumentTypeComponent,CounterComponent,RadioButtonComponent,DatepickerRendererComponent ,CustomHeaderParentComponent ,JewelryCatalogueCommonComponent,NoPermissionViewComponent
    ,FilterPipe,NgApexchartsModule,CKEditorModule,MatchPasswordDirective,NavTabsComponent,MaxLengthDirective,MinMaxValueDirective,TwoDigitDecimaNumberDirective,ZeroToOneNumberDirective,PaymentSectionComponent,ItemListSectionComponent,TaxSectionComponent,InputDatepickerRendererComponent,ColumnFilterComponent,ClarityComponent,AgGridCustomSelect2Component,DropdownComponent,KeyboardShortcutsComponent,BillingAddressComponent,ShippingAddressComponent,ProductsDetailsCommonComponent,ProductCheckoutComponent,OrderSuccessfulComponent,RfidTagCodeComponent,ClosingComponent ,RfidTagComponent,
    ExportDropdownComponent, UploadDocumentsComponent,BusinessRegistrationComponent,NoMultipleDecimalsDirective,
    CompanyRegistrationComponent,CustomFilterPipe,NotNagativeDecimalNumberDirective,
    AppCommentComponent,
    BankDetailsRegistrationComponent,
    UserDetailsRegistrationComponent,
    InputWithSelectDropdownComponent,
    CustomApxChartComponent,
    ClosingReportEditIconComponent,TaskTypeComponent,NumericTextRendererComponent,ExpensesCategoryComponent,LoanProductTypeComponent,LoanReasonComponent,ToggleSwitchComponent,
    CommonCommentComponent,CommentSectionComponent,DescriptionCellRenderComponent,StatusCellRendererComponent,AdvancedFilterPopUpComponent,CommentSectionComponent,DescriptionCellRenderComponent,StatusCellRendererComponent,
    ImageCarouselComponent,
    ShimmeringEffectComponent,
    MinTimeValidatorDirective,
    TwoHeaderlineSpanComponent,
    CustomMonthPickerComponent
  ],
})
export class SharedModule {}
