import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { StorageName } from '../../enum/common-enum';
import { StorageService } from '../../services/storage.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-with-select-dropdown',
  templateUrl: './input-with-select-dropdown.component.html',
  styleUrls: ['./input-with-select-dropdown.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class InputWithSelectDropdownComponent {
  //for input 
  @Input() inputType:string='text';
  @Input() inputName:string;
  @Input() inputPlaceholder:string='';
  @Input() inputLabel:string;
  @Input() inputId:string;
  @Input() inputValue:string='';
  @Input() isRequired:boolean;
  @Input() inputMaxlength:boolean=false;
  @Input() inputClass:string='';
  @Input() disabled :boolean;
  @Input() readOnly: boolean=false;
  @Input() selectedValue: string = '';
  @Input() buttonClass:string='';

  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() keydownEmit:EventEmitter<string> = new EventEmitter();

// for select dropdown
  @Input() id:string;
  @Input() name:string='dialcode';
  @Input() selectClass:string='';
  @Input() isDateTimePipe= false;
  @Input() isDisabled:boolean;
  @Input() options: any[] = [];
  @Input() selectedDialCodeValue: string = ''
  @Input() isValueOptionShow: boolean = true;; 
  @Input () optionDisable:number;
  @Output() dialCodeValueChanged = new EventEmitter<string>();

  @Input() showmobileIcon:boolean =false;
  @Input() showphoneIcon:boolean =false;
  @Input() labelName:boolean =false;
  @Output() valueChanged = new EventEmitter<string>();

  constructor(public storageService: StorageService){
    this.options = this.storageService.retrieve(StorageName.DIALCODE_DROPDOWN);
  }

  ngOnInit(): void {
    //  if (!this.selectedDialCodeValue) {
    //   this.selectedDialCodeValue = this.defaultDialCode;
    //  }
    
  }
}

