import { Injectable } from '@angular/core';
import { DataService} from '../../../shared/services/data.service'
import { Observable, tap } from 'rxjs';
import { ResponseModel } from '../../../shared/models/response-model.model';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class StockJournalService {

  constructor(public service:DataService,private http: HttpClient) { }

  insertStockJournal(data:any):Observable<ResponseModel>{
    let url = "api/app/stockjournal/insertstockjournalcommand";
    const json = JSON.stringify(data);
    console.log(json)
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  print(data:any):Observable<ResponseModel>{
    let url = "api/app/stockjournal/printStockJournal";
    const json = JSON.stringify(data);
    console.log(json)
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  
  
  updateStockJournal(data:any):Observable<ResponseModel>{
    let url = "api/app/stockjournal/updatestockjournalcommand";
    const json = JSON.stringify(data);
    console.log(json)
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


  getById(id:number):Observable<ResponseModel>{
    let url = `api/app/stockjournal/getstockjournalbyid?Id=${id}`;
    return this.service.get(url, true,null,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getStockTransferById(id:number):Observable<ResponseModel>{
    let url = `api/app/stockjournal/getstockjournalbyid?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }



  uploadFile(data: any, metalId: any, groupWise: any) :Observable<ResponseModel>{
    let url = "api/app/stockjournal/uploadFile/"+metalId+"/"+ groupWise;
    const json = JSON.stringify(data);
    //     const formData = new FormData();
    // formData.append('file', data);
    console.log(json)
    return this.service.postWithFile(url,data, true,null,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


getAllTransferStockJournal(data:any):Observable<ResponseModel>{
  let url ='api/app/stockreport/getalltransferstockjournal';
  console.log("api/app/stockreport/getalltransferstockjournal",JSON.stringify(data));
  return this.service.post(url,data,true).pipe<ResponseModel>(tap((response:any)=>{
  return response;
  }))
}


insertStockJournalTransfer(data:any):Observable<ResponseModel>{
  let url = "api/app/stockjournal/inserttransferstockjournal";
  const json = JSON.stringify(data);
  console.log("api/app/stockjournal/inserttransferstockjournal",json)
  return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
  return response;
    })
  );
};

updateStockJournalTransfer(data:any):Observable<ResponseModel>{
  let url = "api/app/stockjournal/updatetransferstockjournal";
  const json = JSON.stringify(data);
  console.log("api/app/stockjournal/updatetransferstockjournal",json)
  return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
  return response;
    })
  );
}

getInvoiceItemsByInvoiceNo(id)
{
  let url = `api/app/stockjournal/getInvoiceItemsByInvoiceNo?Id=${id}`;
  return this.service.get(url, true).pipe<ResponseModel>(tap((response: any) => {
  return response;
    })
  );
}


getsamplefilebypagename(data):Observable<ResponseModel>{
  let url = "api/app/samplefile/getsamplefilebypagename";
  const json =JSON.stringify(data)
  return this.service.post(url, data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}


}
