import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "product",
    loadChildren: () => import("../../components/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "transaction",
    loadChildren: () => import("../../components/transaction/transaction.module").then((m) => m.TransactionModule),
  },
  {   
    path: "orders",
    loadChildren: () => import("../../components/orders/orders.module").then((m) => m.OrdersModule),
  },
  {
    path: "inventory",
    loadChildren: () => import("../../components/inventory/inventory.module").then((m) => m.InventoryModule),
  },
  {
    path: "manufacturer",
    loadChildren: () => import("../../components/manufacturer/manufacturer.module").then((m) => m.ManufacturerModule),
  },
  {
    path: "financial-Statement",
    loadChildren: () => import("../../components/financial-statement/financial-statement.module").then((m) => m.FinancialStatementModule),
  },
  {
    path: "report",
    loadChildren: () => import("../../components/report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "settings",
    loadChildren: () => import("../../components/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "administration",
    loadChildren: () => import("../../components/administration/administration.module").then((m) => m.AdministrationModule),
  },
  {   
    path: "add-update-profile",
    loadChildren: () => import("../../auth/onboarding/edit-profile/edit-profile.module").then((m) => m.EditProfileModule),
  },
  {   
    path: "company-list",
    loadChildren: () => import("../../auth/onboarding/company-list/company-list.module").then((m) => m.CompanyListModule),
  },
  {   
    path: "add-company",
    loadChildren: () => import("../../auth/onboarding/add-company/add-company.module").then((m) => m.AddCompanyModule),
  },
];
