import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridI } from '../../../ag-grid/ag-grid.model';
import { AgGridFundTransfer } from "./fund-transfer.model";
import { IconHover } from '../../../../enum/common-enum';
@Component({
  selector: 'app-fund-transfer',
  templateUrl: './fund-transfer.component.html',
  styleUrls: ['./fund-transfer.component.scss']
})
export class FundTransferComponent {
  @Input() modalTitle: string;
  currentHoverEffect = IconHover.Pink;
  @Input() data: any;
  gridApi: any;
  agGridFundTransfer: AgGridI;
  agGriFundTransferData: any[] = [];
  constructor(public activeModal: NgbActiveModal,) { }
  ngOnInit(): void {
    this.agGridFundTransfer = AgGridFundTransfer
  }
  onGridReady(event) {
    this.gridApi = event;
    this.gridApi.api.setRowData(this.data);
    this.gridApi.api.forEachNode((node) => {
      if ((node.data.referenceInvoiceNo !== null &&
        node.data.referenceVoucherTypeId !== null &&
        node.data.referenceInvoiceNo !== null))
        node.data.active = true;
      if (node.data.active)
        node.setSelected(true);
      else
        node.setSelected(false);
    });
  }

  submit() {
    var changedData = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    const selectedRows = changedData.filter(item => item.active == true);
    let totalAmount = selectedRows.reduce((sum, row) => sum + (row.totalAmount || 0), 0);
    this.data = changedData;
    const fundTransferPopupdata: any = {
      data: selectedRows,
      totalAmount: totalAmount,
      fundTransferData: this.data,
      fundTransferIds: selectedRows.map(row => row.id)
    };

    this.activeModal.close({ data: fundTransferPopupdata, onApply: true });
  }

}

