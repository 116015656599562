import { Component, Input, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from 'ag-grid-community';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { ToastrService } from 'ngx-toastr';
import { VoucherType } from 'src/app/shared/models/common.model';
import { AgGridAgainstOfList } from 'src/app/components/transaction/invoice/invoice.model';
import { InvoiceService } from 'src/app/components/transaction/invoice/invoice.service';
import { IconHover } from '../../../../enum/common-enum';
import { Subscription } from 'rxjs';
import { AgainstOf } from 'src/app/components/transaction/invoice/invoice.model';

@Component({
  selector: 'app-against-of-popup',
  templateUrl: './against-of-popup.component.html',
  styleUrls: ['./against-of-popup.component.scss']
})
export class AgainstOfPopupComponent extends CommonService{
  @Input() data: any;
  agGridAgainstOfList: AgGridI;
  gridOptions: GridOptions;
  gridApiAgainstOf: any;
  agGridAgainstOfListRowData: any[] = [];
  saleOrdeList: any;
  requestIds = [];
  currentHoverEffect = IconHover.Pink;
  itemList = [];
  voucherdata: any;
  onRadioButtonSubscription: Subscription | undefined;
  params: any;
  constructor(
    public activeModal: NgbActiveModal,
    public renderer: Renderer2,
    public invoiceService: InvoiceService,
    public agGridService: AgGridService,
    public toaster: ToastrService,
  ) {
    super(renderer);
    this.gridOptions = {
      singleClickEdit : true, // Add other grid options as needed
    };
    
  this.onRadioButtonSubscription = this.agGridService.onRadioButtonChange$.subscribe((params:any) => {
    this.params = params;
    params?.node?.setSelected(true);
  });
  }

  ngOnInit() : void {
    this.agGridAgainstOfList = AgGridAgainstOfList;
    const element = document.querySelector('.custom_focus') as any;
    element?.focus();
  }

 onKeyDown(event: KeyboardEvent | any) {
    event?.srcElement?.parentElement?.click();
  }

  onGridReady(event) {
    this.gridApiAgainstOf = event;
    const againstOfTitles = {
      [AgainstOf.SaleOrder]: "Sale Order",
      [AgainstOf.SaleQuotation]: "Estimate/Quotation",
      [AgainstOf.SalesInvoice]: "Sales Invoice",
      [AgainstOf.DeliveryNote]: "Delivery Note",
      [AgainstOf.RepairOrder]: "Repair Order",
      [AgainstOf.PurchaseOrder]: "Purchase Order",
      [AgainstOf.PurchaseQuotation]: "Purchase Quotation",
      [AgainstOf.PurchaseInvoice]: "Purchase Invoice",
    };
    this.data.modalTitle = againstOfTitles[this.data.againstOfId] || "Invalid Selection";
    this.agGridAgainstOfListRowData = this.data.modalTitle !== "Invalid Selection"
      ? this.data?.againstofDropdownFilterData
          ?.filter((a) => a.extra3)
          ?.flatMap((item) => JSON.parse(item.extra3)) || []
      : [];
  

    this.gridApiAgainstOf.api.setRowData(this.agGridAgainstOfListRowData);
    if (this.data?.againstVoucherNo && this.data?.againstVoucherTypeId) {
      this.gridApiAgainstOf.api.forEachNode((node: any) => {
        node.data.active = (node.data?.id === this.data?.againstVoucherNo) && node.data?.voucherTypeId === this.data?.againstVoucherTypeId;
        node.setSelected(node.data.active);
      });
      this.gridApiAgainstOf.api.refreshCells({ force: true });
    }
  }

  onApply() {
    const selectedRow = this.params.api.getSelectedRows()[0];
    this.requestIds = this.params.api.getModel()?.rowsToDisplay?.filter((node) => node.isSelected())?.map((node) => node.data.id);
    if (!this.requestIds || this.requestIds.length === 0) {
      this.activeModal.close(false);
      return;
    }
    const requestData = {
      ids: this.requestIds,
      voucherType: selectedRow?.voucherTypeId,
    };
    this.invoiceService.getBySaleOrderId(requestData).subscribe({
      next: (res) => {
        if (res?.isSuccess) {
          this.itemList = res?.response?.itemList;
          this.gridApiAgainstOf?.api?.forEachNode((node) => {
            node.data.active = this.requestIds.includes(node.data.id);
            this.gridApiAgainstOf?.api?.refreshCells({ rowNodes: [node] });
          });
          if (this.data.voucherTypeId == VoucherType.SalesReturn) {
            this.updateBarcodeNo(this.itemList)
          }
          this.activeModal.close([this.itemList ?? [], selectedRow]);
        } else {
          this.toaster.error(res.errors[0]);
          this.activeModal.close();
        }
      },
      error: (err) => {

      }
    });
  }

  updateBarcodeNo(dataArray) {
    return dataArray.map(data => {
      if (data.barcodeNo) {
        data.barcodeNo = "";
      }
      if (data.detailedJson) {
        let detailedData = JSON.parse(data.detailedJson);
        for (let key in detailedData) {
          if (Array.isArray(detailedData[key])) {
            detailedData[key].forEach(item => {
              if (item.barcodeNo) {
                item.barcodeNo = "";
              }
            });
          }
        }
        data.detailedJson = JSON.stringify(detailedData);
      }
      return data;
    });
  }
 
  cleanupCheckboxes() {
    this.gridApiAgainstOf?.api?.forEachNode((node) => {
      if (node?.data?.active) {
        node.data.active = false;
        this.gridApiAgainstOf?.api?.refreshCells({ rowNodes: [node] });
      }
    });
  }

  closeModal() {
    this.cleanupCheckboxes();
    this.activeModal.dismiss();
  }
  ngOnDestroy() {
    this.onRadioButtonSubscription?.unsubscribe();
  }

}