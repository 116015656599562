<div class="wrapper">
  <div class="d-flex align-items-center">
    <!-- <div #menuButton *ngIf="params.enableMenu"
      class="customHeaderMenuButton d-flex align-items-center"
      (click)="onMenuClicked($event)"
    >
      <svg class="cursorPointer " xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11">
        <g id="Group_2549" data-name="Group 2549" transform="translate(-654 -418)">
          <rect id="Rectangle_18117" data-name="Rectangle 18117" width="3" height="3" rx="0.5" transform="translate(654 418)" fill="#7c89a4"/>
          <rect id="Rectangle_18120" data-name="Rectangle 18120" width="3" height="3" rx="0.5" transform="translate(654 422)" fill="#7c89a4"/>
          <rect id="Rectangle_18122" data-name="Rectangle 18122" width="3" height="3" rx="0.5" transform="translate(654 426)" fill="#7c89a4"/>
          <rect id="Rectangle_18118" data-name="Rectangle 18118" width="3" height="3" rx="0.5" transform="translate(658 418)" fill="#7c89a4"/>
          <rect id="Rectangle_18119" data-name="Rectangle 18119" width="3" height="3" rx="0.5" transform="translate(658 422)" fill="#7c89a4"/>
          <rect id="Rectangle_18121" data-name="Rectangle 18121" width="3" height="3" rx="0.5" transform="translate(658 426)" fill="#7c89a4"/>
        </g>
      </svg>   
    </div> -->
    <div class="customHeaderLabel clild_Label px-1 header-compress long-text">{{ params.displayName }}<span *ngIf="params.isRequired" class="text-color-danger">*</span></div>
    <div class="position-relative px-1 w-15" *ngIf="params.enableSorting">
      <app-button *ngIf="params.enableSorting"
        [buttonClass]="'up-arrow common-filter-size gap-0 custom-button'" [buttonType]="'button'"
        [buttonId]="'asc'"
        (buttonOutputEvent)="onSortRequested('asc', $event)">
      </app-button>
      <app-button *ngIf="params.enableSorting" 
      [buttonClass]="'down-arrow common-filter-size gap-0 custom-button'" [buttonType]="'button'"
      [buttonId]="'desc'"
      (buttonOutputEvent)="onSortRequested('desc', $event)">
    </app-button>
      <!-- <button *ngIf="params.enableSorting" (click)="onSortRequested('asc', $event)" [ngClass]="ascSort"
        class="customSortDownLabel h-0 border-0" type="button" >
        <svg xmlns="http://www.w3.org/2000/svg" width="8.511" height="4.256" viewBox="0 0 8.511 4.256">
          <path id="Path_1287" data-name="Path 1287" d="M1298.971-738.772l4.255-4.256,4.255,4.256h-8.511Z" transform="translate(-1298.971 743.028)" fill="#455472"/>
        </svg>     
      </button> 
      
      <button *ngIf="params.enableSorting" (click)="onSortRequested('desc', $event)" [ngClass]="descSort"
        class="customSortUpLabel h-0 border-0" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="8.511" height="4.255" viewBox="0 0 8.511 4.255">
          <path id="Path_1286" data-name="Path 1286" d="M1298.971-743.028l4.255,4.255,4.255-4.255Z" transform="translate(-1298.971 743.028)" fill="#455472"/>
        </svg> 
      </button>-->
  
    </div>
    <button *ngIf="params.moreButton" (click)="addRow(params)" [id]="params?.moreButtonId"
      class="d-flex f-10 more-button-div">
      MORE &gt;&gt;
    </button> 

    <button *ngIf="params.displayName == 'Gold Amount'" (click)="goToGoldTab(params)" class="d-flex f-10 more-button-div">
     +
    </button>
    
    <button *ngIf="params.displayName == 'Stone Amount'" (click)="goToStoneTab(params)" class="d-flex f-10 more-button-div">
     + 
    </button>
    
    
    <div *ngIf="params.selectAll" class="d-flex f-10">
      <app-checkbox [status]="allChecked()??selectAll" (check)="selectAllItems($event,params)" [lableName]="''"
        [checkBoxClass]="'justify-content-end'">
      </app-checkbox>
    </div>

    <button *ngIf="params.moreaddButton" (click)="addRow(params)" class="d-flex f-10 more-button-div"
      [ngClass]="{'pointer_none': params.isDisable, 'cursor-not-allowed opacity-50': params.isDisable}">
      MORE &gt;&gt;
    </button>

    <button *ngIf="params.MaterialIssue" class="d-flex f-10 more-button-div">
      MORE &gt;&gt;
    </button>
    


    <!-- <div (click)="onIconClick()"  class="position-absolute top-50 end-0 translate-middle"> -->
    <div (click)="onIconClick()" >
      <svg *ngIf="params.enablePlusIcon" class="cursorPointer mt-1  plusIcon-hover"  xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 21.963 21.963">
        <g id="Group_1832" data-name="Group 1832" transform="translate(838 -5635)">
          <circle  id="Ellipse_7" data-name="Ellipse 7" cx="10.982" cy="10.982" r="10.982" transform="translate(-838 5635)" fill="#b6c0d6"/>
          <path  id="Union_4" data-name="Union 4" d="M5.177-1a.686.686,0,0,1,.686.686v4.8h4.8a.686.686,0,0,1,0,1.373h-4.8v4.8a.686.686,0,0,1-1.373,0v-4.8h-4.8A.686.686,0,0,1-1,5.177a.686.686,0,0,1,.686-.686h4.8v-4.8A.686.686,0,0,1,5.177-1Z" transform="translate(-832.196 5640.804)" fill="#fff"/>
        </g>
      </svg>
      <!-- <svg *ngIf="params.enablePlusIconLight" class="cursorPointer mt-1  plusIcon-hover" xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 22 22">
        <g id="Group_3498" data-name="Group 3498" transform="translate(-0.023)">
          <circle  id="Ellipse_61" data-name="Ellipse 61" cx="11" cy="11" r="11" transform="translate(0.023)" fill="#e8ecf3"/>
          <path  id="Union_4" data-name="Union 4" d="M5.187-1a.687.687,0,0,1,.687.687V4.5h4.812a.687.687,0,1,1,0,1.375H5.875v4.812a.687.687,0,1,1-1.375,0V5.875H-.313A.687.687,0,0,1-1,5.187.687.687,0,0,1-.313,4.5H4.5V-.313A.687.687,0,0,1,5.187-1Z" transform="translate(6.023 6)" fill="#a3abb8"/>
        </g>
      </svg>     -->
      <button *ngIf="params.enablePlusIconLight" class="plus-button cursorPointer mt-1 plusIcon-hover" type="button"></button>

    </div>

    <div class="col px-1 d-flex justify-content-end" *ngIf="params?.isFilter && params?.floatingMultiselectDropDown">
      <app-button
          class=""
          showFilterIcon="true"
          [buttonClass]="'common-filter-size gap-0 h-1_8rem bordernone light_voiletButton'"
          [buttonType]="'button'"
          (buttonOutputEvent)="columnFilter?.openFilterPopUpFromHeader();"
      ></app-button>
      <div class="position-relative">
          <span class="f-10 font-semibold text-primary position-absolute count-position" *ngIf="columnFilter?.params?.appliedData?.length">{{columnFilter?.params?.appliedData?.length}}</span>
          <app-button 
              class=""
              showRefreshIcon="true"
              [buttonClass]="'px-1 py-1 gap-0 h-1_8rem bordernone light_voiletButton'"
              [buttonType]="'button'"
              (buttonOutputEvent)="columnFilter?.clearFilterFromHeader()"
          ></app-button>
      </div>
  </div>

  <app-button [buttonId]="'filterIcon'" (buttonOutputEvent)="onClickPartiesFilterIcon()"    [buttonType]="'button'" [buttonClass]="'d-flex px-2 parties_button border-0'" *ngIf="params.PartiesFilterIcon">
  </app-button>
  <svg *ngIf="params.PartiesFilterIcon" id="Component_79_31" data-name="Component 79 – 31" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20.331 20.859">
    <path id="Union_17" data-name="Union 17" d="M-12251.852-9398.895a1.75,1.75,0,0,1-.121-.671c-.006-2.207-.011-4.709,0-7.154a1.9,1.9,0,0,0-.37-1.164c-1.834-2.58-3.691-5.2-5.49-7.743l-.867-1.225a1.312,1.312,0,0,1,.243-1.906,1.979,1.979,0,0,1,.254-.148.746.746,0,0,0,.079-.039l.1-.054h18.328l.083.034a1.411,1.411,0,0,1,.833.743,1.35,1.35,0,0,1-.2,1.373q-.9,1.258-1.787,2.521l-.731,1.034c-.324.457-.646.912-.969,1.369-.938,1.325-1.91,2.7-2.871,4.043a1.924,1.924,0,0,0-.373,1.186c0,1.418,0,2.855,0,4.244,0,.488,0,.977,0,1.469a1.279,1.279,0,0,1-.975,1.415l-1.271.485c-.724.278-1.445.556-2.171.831a1.639,1.639,0,0,1-.58.112A1.217,1.217,0,0,1-12251.852-9398.895Zm-3.645-16.771c1.546,2.181,3.146,4.435,4.717,6.656a5.671,5.671,0,0,1,.395.654,3.394,3.394,0,0,1,.34,1.6v6.4l.38-.144c.67-.256,1.358-.522,2.043-.778,0-.785,0-1.579,0-2.353,0-1.01,0-2.058,0-3.085a3.767,3.767,0,0,1,.722-2.285c1.521-2.139,3.065-4.317,4.556-6.424.389-.547.778-1.1,1.165-1.645h-15.3Z" transform="translate(12259 9418.999)" fill="#4f39cd"/>
    </svg>
  <div class="d-block d-flex align-items-center justify-content-center flex-column">
      <div *ngIf="params?.arrowUpDownIcon" class="mb-1">
        <span class="">
          <svg *ngIf="arrowUpDown" class="cursorPointer" (click)="onClickArrowUpDown()" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 20 20">
              <g id="Group_45643" data-name="Group 45643" transform="translate(-457 -221)">
                  <path id="Path_35320" data-name="Path 35320" d="M22,12A10,10,0,1,0,12,22,10,10,0,0,0,22,12Z" transform="translate(455 219)" fill="#4f39cd"/>
                  <path id="Path_35320-2" data-name="Path 35320" d="M8,12l4,4m0,0,4-4m-4,4V8" transform="translate(455 219)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </g>
          </svg>
          <svg *ngIf="!arrowUpDown" class="cursorPointer" (click)="onClickArrowUpDown()" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 20 20">
              <path id="Path_35320" data-name="Path 35320" d="M22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12Z" transform="translate(-2 -2)" fill="#4f39cd"/>
              <path id="Path_35320-2" data-name="Path 35320" d="M8,12l4-4m0,0,4,4M12,8v8" transform="translate(-2 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
        </span>
      </div>
      <div *ngIf="params?.isCommonHeaderCheckbox" class="f-16 d-flex justify-content-center mb-2  mt-2">
          <app-checkbox 
          [status]="areAllChecked()??false"
          (check)="selectAll($event,params,'extra4')" [lableName]="''"
          >
        </app-checkbox>
      </div>
      <div *ngIf="params?.isContactGroupCheckbox" class="f-16 d-flex justify-content-center mb-2  mt-2">
        <app-checkbox 
        [status]="areAllChecked()??false"
        (check)="contactGroupselectAll($event,params,'active')" [lableName]="''"
        >
      </app-checkbox>
    </div>

      
  </div>
  <!-- Document  Icon-->
  <div class="" *ngIf="params?.downloadIcon"  (click)="ondownloadClick(params)">
    <app-svg-icon class="cursorPointer" name="kycDetails_icon"></app-svg-icon>
</div>
  <!--  -->

  </div>

  <app-column-filter *ngIf="params?.isFilter"
  [filterParams] = "params.filterParams"
  [isInputFilter]="params?.floatingFilter"
  [isPopUpInputFilter]="params?.popUpfloatingFilter"
  [isDropDownFilter]="params?.floatingDropDown"
  [isMultiselectDropdownFilter]="params?.floatingMultiselectDropDown"
  [params]="params"
  [columnName]="params?.displayName"
  [idColumnName]="params?.filterIdColumnName"
  [valueColumnName]="params?.filterValueColumnName"
  [gridName]="params?.gridName"
  [initialRows]="params?.initialRows"
  [filterStorageName]="params?.filterStorageName"
  [apiUrl]="params?.apiUrl"
  [sortStorageName]="params?.sortStorageName"
  [paginationStorageName] = "params?.paginationStorageName"
  [cellDisplayType]="params?.cellDisplayType"
  [isDateFilterShow]="params?.isDateFilterShow"
  [tableIndex]="params?.tableIndex"
  [isServerSidePagination]="params?.isServerSidepagination">
</app-column-filter>
</div>
