import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RfidTagComponent } from '../../../../components/inventory/stock-report/popup/rfid-tag/rfid-tag.component';
import { MetalType, ModalPopupSize } from '../../../../shared/models/common.model';
import { NotificationService } from '../../../../shared/services/notification.service';
import { StorageService } from '../../../../shared/services/storage.service';
export interface Icons{
  RFID  : boolean; 
  Barcode : boolean;
  BCL: boolean;
  CardLabel:boolean;
  Delete:boolean;
  Print:boolean;
  Whatsapp:boolean;
  Payment:boolean;
  RedDeleteIcon:boolean;
  isInfo:boolean;
  expandeble:boolean;
 }
@Component({
  selector: 'app-rfid-icon',
  templateUrl: './rfid-icon.component.html',
  styleUrls: ['./rfid-icon.component.scss']
})
export class RfidIconComponent implements ICellRendererAngularComp{
  public params: ICellRendererParams & Icons;
  canDeleteOrUpdate:boolean=true;
  Disable:boolean = false;

  ngOnInit(): void {}
  
  agInit(params: ICellRendererParams & Icons): void {
    this.params = params;
    this.canDeleteOrUpdate = params.data.canDeleteOrUpdate;
  }
  MetalType:MetalType
  constructor( private modalService: NgbModal ,
    public notificationService      : NotificationService,
     public storageService : StorageService){}


  openRFIDTagPopup(){
    var data = {...this.params.data};
    this.notificationService.openModalPopup(RfidTagComponent, data,undefined,ModalPopupSize.MD,'',false,true)
    .then((result) => {
      if (result) {
        this.updateRow(result.response)
      }
    });
  }


  updateRow(data) {
    const rowNode = this.params.api.getRowNode(this.params.rowIndex.toString());
    if (rowNode) {
      rowNode.data.rfid = data.rfid;
     // rowNode.setData(data);
      this.params.api.refreshCells({ rowNodes: [rowNode], force: true });
    }
  }

  refresh(): boolean {
    return true;
  }
}
