import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { CrmService } from '../../components/administration/crm/crm.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
declare var SimpleMDE: any; // Declare SimpleMDE to avoid TypeScript errors

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
})
export class MarkdownEditorComponent extends CommonService implements OnDestroy  {
  simplemde: any;
  @Output() calculatedMessageNumber = new EventEmitter<any>();
  @Output() updatedCharacterWordCount = new EventEmitter<any>();
@ Input () inputValue;

@Input() id = 'markdown-editor';

  constructor(
      public crmService : CrmService,
      public toaster                : ToastrService,
      public renderer               : Renderer2,

  ) {
    super(renderer);
  }
  ngOnDestroy(): void {
   this.simplemde
  }

  ngAfterViewInit() {
    this.simplemde = new SimpleMDE({
      element: document.getElementById(this.id),
      placeholder: "Type your Markdown here...",
      spellChecker: true,
      autosave: {
        enabled: true,
        uniqueId: this.id,
        delay: 1000,
      },
      status: ["autosave", "lines", "words"], // Keep default status items
      toolbar: [
        "bold", "italic", "heading", "|",
        "quote", "unordered-list", "ordered-list", "|",
        "link", "image", "|",
        {
          name: 'emoji',
          action: this.openEmojiPicker.bind(this),
          className: 'fa fa-smile-o', // FontAwesome icon for emoji
          title: 'Insert Emoji'
        },
        "|",
        {
          name: 'fontsize',
          action: this.toggleFontSizeDropdown.bind(this),
          className: 'fa fa-text-height', // dropdown icon
          title: 'Font Size'
        },
        "|",
        {
          name: 'themefont',
          action: this.toggleThemeFontDropdown.bind(this),
          className: 'fa fa-font', // dropdown icon
          title: 'Theme Font'
        },
        "|",
        "preview", "side-by-side", "fullscreen"
      ],
    });

    this.simplemde.codemirror.getWrapperElement().style.height = '100%';

    // Update character count on change
    this.simplemde.codemirror.on('change', () => {
      this.updateCharacterCount();
    });
    this.simplemde.value('');
    // Initialize the character count
    this.updateCharacterCount();
  }


  // toggle the font size dropdown
  toggleFontSizeDropdown() {
    let existingDropdown = document.querySelector('.font-size-dropdown');
    if (existingDropdown) {
      existingDropdown.remove();
      return;
    }
    // dropdown container
    const fontSizeDropdown = document.createElement('div');
    fontSizeDropdown.className = 'font-size-dropdown';
    fontSizeDropdown.style.position = 'absolute';
    fontSizeDropdown.style.zIndex = '1000';
    fontSizeDropdown.style.backgroundColor = '#fff';
    fontSizeDropdown.style.border = '1px solid #ccc';
    fontSizeDropdown.style.padding = '5px';
    fontSizeDropdown.style.borderRadius = '4px';
    fontSizeDropdown.style.maxHeight = '150px';
    fontSizeDropdown.style.overflowY = 'auto';

    const sizes = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '40px', '44px', '48px', '52px', '56px', '60px', '64px', '68px', '72px'];
    sizes.forEach((size) => {
      const sizeOption = document.createElement('div');
      sizeOption.textContent = size;
      sizeOption.style.cursor = 'pointer';
      sizeOption.style.padding = '10px';
      sizeOption.style.transition = 'background-color 0.2s';
      sizeOption.onmouseover = () => sizeOption.style.backgroundColor = '#f0f0f0';
      sizeOption.onmouseout = () => sizeOption.style.backgroundColor = '';

      sizeOption.onclick = () => {
        this.changeFontSize(size);
        fontSizeDropdown.remove();
      };
      fontSizeDropdown.appendChild(sizeOption);
    });

    // Position the dropdown  in the toolbar
    const toolbarButton = document.querySelector('.editor-toolbar .fa-text-height');
    if (toolbarButton) {
      const toolbarRect = toolbarButton.getBoundingClientRect();
      fontSizeDropdown.style.top = `${toolbarRect.bottom + window.scrollY}px`;
      fontSizeDropdown.style.left = `${toolbarRect.left + window.scrollX}px`;
      document.body.appendChild(fontSizeDropdown);
      const outsideClickListener = (event: MouseEvent) => {
        if (!fontSizeDropdown.contains(event.target as Node) && !toolbarButton.contains(event.target as Node)) {
          fontSizeDropdown.remove();
          document.removeEventListener('click', outsideClickListener);
        }
      };
      document.addEventListener('click', outsideClickListener);
    }
  }
  // font size of the editor content
  changeFontSize(size: string) {
    const cm = this.simplemde.codemirror;
    const editorElement = cm.getWrapperElement();

    if (editorElement) {
      editorElement.style.fontSize = size;
      cm.refresh();
    }
  }


  //toggle the theme font dropdown
  toggleThemeFontDropdown() {
    let existingDropdown = document.querySelector('.theme-font-dropdown');
    if (existingDropdown) {
      existingDropdown.remove();
      return;
    }
    const themeFontDropdown = document.createElement('div');
    themeFontDropdown.className = 'theme-font-dropdown';
    themeFontDropdown.style.position = 'absolute';
    themeFontDropdown.style.zIndex = '1000';
    themeFontDropdown.style.backgroundColor = '#fff';
    themeFontDropdown.style.border = '1px solid #ccc';
    themeFontDropdown.style.padding = '5px';
    themeFontDropdown.style.borderRadius = '4px';
    themeFontDropdown.style.maxHeight = '150px';
    themeFontDropdown.style.overflowY = 'auto';

    const fonts = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana', 'Trebuchet MS', 'Comic Sans MS', 'Impact', 'Lucida Console', 'Tahoma', 'Palatino Linotype', 'Helvetica', 'Frank Ruhl Libre', 'Arial Black', 'DejaVu Sans', 'Montserrat', 'Open Sans', 'Roboto', 'Lato', 'Droid Sans',];
    fonts.forEach((font) => {
      const fontOption = document.createElement('div');
      fontOption.textContent = font;
      fontOption.style.cursor = 'pointer';
      fontOption.style.padding = '5px';
      fontOption.style.transition = 'background-color 0.2s';
      fontOption.onmouseover = () => fontOption.style.backgroundColor = '#f0f0f0';
      fontOption.onmouseout = () => fontOption.style.backgroundColor = '';

      fontOption.onclick = () => {
        this.changeThemeFont(font);
        themeFontDropdown.remove();
      };
      themeFontDropdown.appendChild(fontOption);
    });
    //dropdown position
    const toolbarButton = document.querySelector('.editor-toolbar .fa-font');
    if (toolbarButton) {
      const toolbarRect = toolbarButton.getBoundingClientRect();
      themeFontDropdown.style.top = `${toolbarRect.bottom + window.scrollY}px`;
      themeFontDropdown.style.left = `${toolbarRect.left + window.scrollX}px`;
      document.body.appendChild(themeFontDropdown);

      const outsideClickListener = (event: MouseEvent) => {
        if (!themeFontDropdown.contains(event.target as Node) && !toolbarButton.contains(event.target as Node)) {
          themeFontDropdown.remove();
          document.removeEventListener('click', outsideClickListener);
        }
      };
      document.addEventListener('click', outsideClickListener);
    }
  }
  // theme font of the editor content
  changeThemeFont(font: string) {
    const cm = this.simplemde.codemirror;
    const editorElement = cm.getWrapperElement();
    if (editorElement) {
      editorElement.style.fontFamily = font;
      cm.refresh();
    }
  }


  updateCharacterCount() {
    this.calculatedMessageNumber.emit(this.simplemde.value())
  }


  openEmojiPicker() {
    const emojiPicker = document.createElement('div');
    emojiPicker.style.position = 'absolute';
    emojiPicker.style.zIndex = '1000';
    emojiPicker.style.border = '1px solid #ccc';
    emojiPicker.style.backgroundColor = 'transparent';
    emojiPicker.style.padding = '10px';

    // Add emojis to the picker
    const emojis = ['😀', '😂', '😍', '😎', '😢', '😡', '👍', '👎', '🎉'];
    emojis.forEach((emoji) => {
      const emojiButton = document.createElement('button');
      emojiButton.innerText = emoji;
      emojiButton.style.fontSize = '20px';
      emojiButton.style.cursor = 'pointer';
      emojiButton.onclick = () => this.insertEmoji(emoji);
      emojiPicker.appendChild(emojiButton);
    });

    // Position the emoji picker below the toolbar
    const toolbar = document.querySelector('.editor-toolbar');
    if (toolbar) {
      const toolbarRect = toolbar.getBoundingClientRect();
      emojiPicker.style.top = `${toolbarRect.bottom}px`;
      emojiPicker.style.left = `${toolbarRect.left}px`;
      document.body.appendChild(emojiPicker); // Append the picker to the body
    }
  }

  // Insert Emoji method
  insertEmoji(emoji: string) {
    const cm = this.simplemde.codemirror;
    const cursor = cm.getCursor();
    cm.replaceRange(emoji, cursor); // Insert emoji at the cursor position
    const emojiPicker = document.querySelector('div[style*="z-index: 1000"]'); // Select the emoji picker
    if (emojiPicker) {
      emojiPicker.remove(); // Remove the emoji picker
    }
  }

  // Optionally, a method to set Markdown content programmatically
  setMarkdownContent(content: string): void {
    this.simplemde.value(content);
  }

  // Optionally, you can create a method to get the Markdown content
  getMarkdownContent(): string {
    return this.simplemde.value(); // Retrieve the current Markdown content
  }

  onNgModelChange(value: string): void {
    this.inputValue = value
    this.updateCharacterCount();
  }

  onPaste($event: any): void {
    
  }

  onDrop(ev) {
    
  }


  resetEditor(): void {
    this.simplemde.value('');
  }

  updateCharacterAndWordCount(): void {
    this.updatedCharacterWordCount.emit(this.simplemde.value())
  }
}