import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { ResponseModel } from '../../../shared/models/response-model.model';
import { tap } from 'rxjs';
import { RegisteredUserVM } from './business-registration.model';
@Injectable({
  providedIn: 'root'
})
export class BusinessRegistrationService {

  constructor(public service:DataService) 
  { 
  }

  Register(data) 
  {
    let url = `api/app/UserOnboarding/register`;
    const json = JSON.stringify(data)
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  GetRegionaldetails(){
    let url = `api/app/regionaldetail/getregionaldetails`
    return this.service.get(url, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  checkcridavailability(crid) 
  {
    let url = `api/app/useronboarding/checkcridavailability`;
    const cridRequest = {crid}
    return this.service.post(url,cridRequest,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  
}
