import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Icons } from '../edit-delete-icon/edit-delete-icon.component';
import { Router } from '@angular/router';
import { TransactionType } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.scss']
})
export class ToggleButtonsComponent implements ICellRendererAngularComp{
  transactionType=TransactionType
  toggledata = 0
  public params: ICellRendererParams & Icons;
  currentUrl

  constructor(public route: Router){

  }

  agInit(params: ICellRendererParams & Icons): void {
    this.params = params;
    this.currentUrl = this.route.url;
    
    // this.params.value = 1;
  }

  

  refresh(): boolean {
    return false;
  }
  
  toggleValue(event){
    this.params.setValue(event)
  }


}
