import { Injectable } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { defaultPageLimit, Filters, PaginationModel } from "../common-components/ag-grid/ag-grid.model";
import { AgGridService } from "../common-components/ag-grid/ag-grid.service";
import { StorageService } from "../services/storage.service";
import { DataService } from "../services/data.service";
import { DateRangeType, StorageName } from "../enum/common-enum";

@Injectable({
  providedIn: 'root'
})
export class CommonFilterService {

  constructor(public service: DataService,
    public storageService: StorageService,
    public ggGridService: AgGridService) {
  }

  setDefaultFilter(
    apiRequestStorageName: string,
    apiRequestModel: any,
    filterStorageName: string,
    activeFilter: any = {
      setDefaultDateRangePickerData: { dateRangeType: DateRangeType.FinancialYear, columnData: [] }
    },
    setDefaultBranchData: boolean = true
  ): any {
    // Clear previous filters
    this.storageService.removeItem(filterStorageName);
  
    // Set branch filter if necessary
    if (setDefaultBranchData) {
      const layoutDetails = this.storageService.retrieve('layoutDetails');
      if (layoutDetails?.branchId) {
        this.performFilterColumnwise('branchId', [layoutDetails.branchId], undefined, filterStorageName, '');
      }
    }
  
    // Initialize fromDate and toDate
    const today = new Date();
    let fromDate: any;
    let toDate: any = today.toISOString().split('T')[0];  // Default to today's date
  
    switch (activeFilter.setDefaultDateRangePickerData.dateRangeType) {
      case DateRangeType.Today:
        fromDate = toDate;
        break;
  
      case DateRangeType.ThisWeek:
        fromDate = new Date(today.setDate(today.getDate() - 6)).toISOString().split('T')[0];
        toDate = new Date(new Date().setDate(new Date().getDate() + (6 - new Date().getDay()))).toISOString().split('T')[0];
        break;
  
      case DateRangeType.ThisMonth:
        fromDate = new Date(today.setDate(1)).toISOString().split('T')[0];
        toDate =  new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999).toISOString().split('T')[0];
        break;
  
      case DateRangeType.YearToDate:
        fromDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate() + 1 ).toISOString().split('T')[0];
        break;
  
      case DateRangeType.FinancialYear:
        const financialYear = this.storageService.retrieve('financialYear');
        fromDate = financialYear?.financialYearStartDate;
        toDate = financialYear?.financialYearEndDate;
        break;
    }
  
    // Check if columnData contains undefined or invalid values, and push 'fromDate,toDate' if necessary
    if (activeFilter?.setDefaultDateRangePickerData?.columnData?.length == 0 ||
      !activeFilter?.setDefaultDateRangePickerData?.columnData
    ) {
      // Initialize columnData if it doesn't exist and add 'fromDate,toDate'
      activeFilter.setDefaultDateRangePickerData = { ...activeFilter.setDefaultDateRangePickerData, columnData: ['fromDate,toDate'] };
    }

    // Column-wise filtering logic
    if (activeFilter.setDefaultDateRangePickerData?.columnData?.length) {
      activeFilter.setDefaultDateRangePickerData.columnData.forEach((column) => {
        const [fromColumn, toColumn] = column.split(',');
        if (fromColumn && toColumn) {
          this.performFilterColumnwise(fromColumn, undefined, fromDate, filterStorageName, 'dateFilter');
          this.performFilterColumnwise(toColumn, undefined, toDate, filterStorageName, 'dateFilter');
        }
      });
    }
  
    // Update filters and store data
    this.updateFilters(apiRequestModel, apiRequestStorageName, filterStorageName);
  }
  
  
  updateFilters(apiRequestModel,apiRequestStorageName,filterStorageName)
  {
    apiRequestModel.filters = JSON.stringify(this.processFilterData(filterStorageName));
    this.storageService.store(apiRequestStorageName, apiRequestModel);
  }


  performFilterColumnwise(valueColumnName, filterData, textInput, filterStorageName, cellDisplayType): any {
    // for decimal conversion
    let previousFilterList = this.storageService.retrieve(filterStorageName);
    // for decimal conversion
    var filterList: any = [];
    let index = previousFilterList?.findIndex(a => a.name == valueColumnName);
    if (previousFilterList != undefined)
      filterList = previousFilterList;
    if (cellDisplayType == "dateFilter")
      textInput = this.formatNgbDateToString(textInput);
    if (index != undefined && index != -1) {
      filterList[index].type = cellDisplayType == undefined ? "textFilter" : cellDisplayType;
      filterList[index].textFilter = textInput;
      filterList[index].FilterData = filterData?.length == 0 ? undefined : filterData;
    }
    else {
      if (filterData?.length == 0)
        filterData = undefined;
      filterList.push({ type: cellDisplayType == undefined ? "textFilter" : cellDisplayType, name: valueColumnName, textFilter: textInput, FilterData: filterData });

    }
    this.storageService.store(filterStorageName, filterList);
  }

  public processFilterData(filterStorageName): any {
    var filterList = this.storageService?.retrieve(filterStorageName);
    let filters = new Filters();

    if (filterList != undefined && filterList != null)
      filterList?.forEach(element => {
        if (element?.textFilter != null)
          filters = this.ggGridService?.modifyFilter(filters, element?.textFilter?.toString(), element.name, "cn", false);
        if (element?.FilterData != null) {
          const selectedIds = element?.FilterData;
          var filterIds = selectedIds?.join(',');
          filters = this.ggGridService?.modifyFilter(filters, filterIds, element?.name, "eq", false);
        }
      });
    return filters;
  }

  public getPreviousAppliedFilter(previousAppliedFilters, columnName, dropdownData, cellType) {
    var appliedFilter = previousAppliedFilters.filter(a => a.name == columnName)[0];
    if (appliedFilter != undefined) {
      switch (cellType) {
        case "multiselectDropdown":
          const ids = appliedFilter.FilterData;
          return dropdownData?.filter(a =>
            ids?.some(b => a?.id == b) 
          ) ?? [];
        case "input":
        case "dropdown":
          return appliedFilter.textFilter ?? '';
        // case "datePicker":
        //   return this.parseDateStringToNgbDate(appliedFilter.textFilter);
      }
    }
    else
    {
      switch (cellType) {
        case "multiselectDropdown":
          return []
        case "input":
        case "dropdown":
          return '';
        // case "datePicker":
        //   return this.parseDateStringToNgbDate(appliedFilter.textFilter);
      }
    }
  }

  processServersideApiResponse(res, grid, gridApi, pagination, getAll: boolean) {
    if (res.isSuccess) {
      setTimeout(() => {
        this.hideLoading(gridApi);
        var response = res?.response?.data;
        const allRows = response.filter((x) => x.isSumRow != true);
        const firstTabEntry = response.filter((x) => x.isSumRow == true);
        gridApi?.api.paginationSetPageSize(pagination == undefined || pagination?.pageSize == undefined ? defaultPageLimit : pagination?.pageSize);
        if(grid?.dataModel?.storageName === StorageName.MANUFACTURE_WORKLOG_REPORT_GRID) {
          const list = this.flattenData(allRows);
          gridApi?.api?.setRowData(list);
        } else if (grid?.dataModel?.storageName === StorageName.REPORT_KYCREPORT_GRID){
          const list = this.flattenDataForKycReport(allRows);
          gridApi?.api?.setRowData(list);
        } else {
          gridApi?.api?.setRowData(allRows);
        }
        
        if (allRows?.length ==  firstTabEntry[0]?.total_rows)
          grid.updateServersidePaginationgetallData(pagination?.page, allRows?.length);
        else
          grid.updateServersidePaginationData(firstTabEntry == undefined || firstTabEntry == null ? 0 : firstTabEntry[0]?.total_rows, firstTabEntry == undefined || firstTabEntry == null ? 0 : firstTabEntry[0]?.total_pages, pagination == undefined ? 1 : pagination?.page, pagination?.pageSize == undefined ? defaultPageLimit : pagination?.pageSize);

        gridApi?.api?.setPinnedBottomRowData(firstTabEntry);
        gridApi?.api?.refreshCells();
      }, 100);
    } else {
      this.hideLoading(gridApi);
      grid?.updateServersidePaginationgetallData(1, 0);
      gridApi?.api?.setRowData([]);
    }
  }

  storeChangedPagination(paginationModel: PaginationModel, pagination, paginationStorageName, apiRequestStorageName): any {
    var apiRequestModel = this.storageService.retrieve(apiRequestStorageName);
    pagination.page = paginationModel?.page;
    pagination.reloadGrid = paginationModel?.reloadGrid;
    pagination.reloadGridFilters = false;
    pagination.pageSize = paginationModel?.pageSize;
    if (pagination.pageSize == 1) {
      apiRequestModel.getAll = false;
      apiRequestModel.pageSize = paginationModel?.count;
    } else {
      apiRequestModel.pageSize = pagination?.pageSize;
      apiRequestModel.getAll = false;
    }
    apiRequestModel.page = pagination?.page;
    this.storageService.store(apiRequestStorageName, apiRequestModel);
    this.storageService.store(paginationStorageName, apiRequestModel);
    return pagination;
  }

  showLoading(params) {
    setTimeout(() => {
      params?.api.showLoadingOverlay();
    }, 0);
  }
  hideLoading(params) {
    params?.api?.hideOverlay();
  }


  formatNgbDateToString(ngbDate: any): string {
    if (!ngbDate.year)
      return ngbDate;

    const year = ngbDate.year;
    const month = ngbDate.month < 10 ? '0' + ngbDate.month : ngbDate.month; // Add leading zero if needed
    const day = ngbDate.day < 10 ? '0' + ngbDate.day : ngbDate.day;         // Add leading zero if needed

    return `${year}-${month}-${day}`;
  }

  parseDateStringToNgbDate(dateString: string): NgbDate {
    const dateParts = dateString.split('-');
    return new NgbDate(+dateParts[0], +dateParts[1], +dateParts[2]);
  } 


  flattenData(data: any[]): any[] { // WorkLog report page
    return data.map(item => {
      // Reduce the 'data' array into key-value pairs where 'currentDate' is the key and 'timeSpendPerDay' is the value
      const flattenedDays = item?.data?.reduce((acc, curr) => {
        acc[curr.currentDate] = curr.timeSpendPerDay;
        acc["date"] = curr?.date ;
        acc["canView"] = curr?.canView;
        return acc;
      }, {});
  
      // Merge the flattened days into the main object
      return {
        accountLedgerId: item.accountLedgerId,
        User: item.name,
        Total: item.totalTimeDateWise,
        ...flattenedDays
      };
    });
  }

  flattenDataForKycReport(data: any[]):any[]{
    return data?.map(item => {
      const ids = JSON.parse(item?.ids??'[]')
      // Reduce the 'data' array into key-value pairs where 'currentDate' is the key and 'timeSpendPerDay' is the value
      const flattenedDays = ids?.reduce((acc, curr) => {
        const docDropdown = this.storageService.retrieve(StorageName.DOCUMENT_TYPE_DROPDOWN);
        acc[curr] = docDropdown?.map((x)=>Number(x.id))?.includes(curr) ;
        // acc["date"] = curr?.date ;
        // acc["canView"] = curr?.canView;
        return acc;
      }, {});
  
      // Merge the flattened days into the main object
      return {
        ...item,
        ...flattenedDays
      };
    });
  }

}
