import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as feather from "feather-icons";
import { LayoutService } from "../../../services/layout.service";
import { NavService } from "../../../services/nav.service";
import { fadeInAnimation } from "../../../data/router-animation/router-animation";
import { MaximizeToggleService } from "src/app/shared/services/maximize-toggle-service.service";
import { Subject, Subscription, filter, takeUntil } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit {
  currentUrl
  isSidebarMaximized: boolean = false;
  private ngUnsubscribe = new Subject();
  private MaximizeSubscription :Subscription;
  constructor(private router : Router,
    private route: ActivatedRoute, public navServices: NavService, public layout: LayoutService ,public maximizeToggleService:MaximizeToggleService ,public ngbModal : NgbModal) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd),takeUntil(this.ngUnsubscribe))
      .subscribe((event: NavigationEnd) => {
        if(this.ngbModal?.hasOpenModals()){
          this.ngbModal?.dismissAll('close All')
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    this.currentUrl = this.router.url;
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  get layoutClass() {
    return "horizontal-wrapper";
  }

  ngOnInit() {
    this.MaximizeSubscription = this.maximizeToggleService.maximizeToggle$.subscribe((isMaximized) => {
      this.isSidebarMaximized = isMaximized;
      
    });
    
    document.addEventListener('fullscreenchange', this.onFullScreenChange.bind(this));
  }

  ngOnDestroy(){
    this.MaximizeSubscription?.unsubscribe();
    this.ngUnsubscribe?.complete();
    document.removeEventListener('fullscreenchange', this.onFullScreenChange.bind(this));
  }

  onFullScreenChange() {
    if (!document.fullscreenElement) {
      this.isSidebarMaximized = false;
    }
  }


}
