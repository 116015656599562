import { Component, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { id } from "@swimlane/ngx-datatable";
import { BranchList, InternalSigninRequestModel, InternalSigninResponseModel, LayoutDetails, User } from "src/app/auth/auth/auth.model";
import { AuthService } from "src/app/auth/auth/auth.service";
import { MastersService } from "src/app/components/administration/masters/masters.service";
import { EditProfileService } from "src/app/components/edit-profile/edit-profile.service";
import { StorageName } from "src/app/shared/enum/common-enum";
import { CommonService } from "src/app/shared/services/common.service";
import { SendDataService } from "src/app/shared/services/send-data.service";
import { StorageService } from "src/app/shared/services/storage.service";


@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent extends CommonService implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  branchList: any[]; 
  showDropdown: boolean = false;
  user:User;
  layoutDetails:LayoutDetails;
  branchPrefix:string
  internalSigninResponseModel     = new InternalSigninResponseModel();
  internalSigninRequestModel      = new InternalSigninRequestModel();
  permissionModel
  mastersCanView
  constructor(public router: Router, 
    private activatedRoute  : ActivatedRoute ,
    private storageservice:StorageService,
    private sendDataService:SendDataService,
    private authService     : AuthService,
    public renderer : Renderer2,
    public storageService:StorageService,
    private editProfileService :EditProfileService,
    private mastersService: MastersService) {
      super(renderer);
      this.sendDataService.sendToBranch();
    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  ngOnInit() {
    // this.layoutDetails.isUserSuperAdmin = this.storageService.retrieve('layoutDetails')?.isUserSuperAdmin??'';
    this.editProfileService.updateUser.subscribe((isUpdateDetails=>{
      if(isUpdateDetails){
        // this.user =this.storageservice.retrieve('user');
        this.setDetails();
      }
    }));
    this.setDetails();
    this.permissionModel = this.storageService.retrieve(StorageName.USER_PERMISSIONS);
    this.mastersCanView =this.permissionModel.mastersCanView;
    this.mastersService.BranchUpdated.subscribe((updatedBranch: { id: string, name: string }) => {
      this.updateBranchInList(updatedBranch);
    });
  }
  
  // for updating branch in Real Time
  updateBranchInList(updatedBranch: { id: string, name: string }) {
    const branchToUpdate = this.branchList.find(branch => branch.id === updatedBranch.id.toString());
    if (branchToUpdate) 
      branchToUpdate.name = updatedBranch.name; 
  }

  setDetails(){
    this.user =this.storageservice.retrieve('user');
    this.layoutDetails =this.storageservice.retrieve('layoutDetails');
    this.branchList = this.storageservice.retrieve('branchList');
    console.log(this.branchList);
    console.log(this.user );
    
  }
  
  getprefix(name) {
    const words = name.split(' ');
    const cleanedWords = words.map((word) => word.replace(/-/g, ''));
    const prefix = cleanedWords.map((word) => word.charAt(0)).join('').slice(0, 2);
    return prefix;
  }
  
  
  

  logoutFunc() {
    localStorage.removeItem('isCardView');
    localStorage.removeItem('isCardViewJobWorkOrder');
    localStorage.removeItem('isCardViewManufacturingProcess');
    localStorage.removeItem('defalutcheckboxState');
    localStorage.removeItem('selectedCommonDate');
    this.router.navigate(['/account/login'])
  }
  addListShow() 
  {
    this.showDropdown = !this.showDropdown;
  }
  navigate()
  {
    this.router.navigate(['add-update-profile'], {queryParams : {id : this.layoutDetails.companyId}});
  }

  redirect(){
    this.router.navigateByUrl('/Administration/masters');
    setTimeout(() => {
      this.sendDataService.sendToBranch();
    }, 100);
  }

  getUseretails(branchId){
    if(branchId !== undefined){
      console.log(branchId);
      this.internalSigninRequestModel.branchId = branchId;
      this.internalSigninRequestModel.financialYearId = this.layoutDetails.financialYearId;
      if(this.internalSigninRequestModel.branchId && this.internalSigninRequestModel.financialYearId ){
        this.authService.internalsignin(this.internalSigninRequestModel).subscribe((res) =>{
          if(res.isSuccess){
            this.internalSigninResponseModel = res?.response;
            this.setDetails();
            this.sendDataService.getUserDetailsData(true);
          }
        })
      }
  
    }
  }


  navigateCompanylist(){
    this.router.navigate(['company-list'],{relativeTo: this.activatedRoute });
  }
}
