import { Injectable } from '@angular/core';
import { DataService} from '../../../shared/services/data.service';
import { ResponseModel } from '../../../shared/models/response-model.model';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { StorageService } from '../../../shared/services/storage.service';
@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(public service:DataService,
  public storageService : StorageService) { }
  public TabId = new BehaviorSubject<any>(undefined);
  // sale order(popup)dropdown data
  getAllSaleOrderDropdownData(){
    let url = 'api/app/saleorder/getsaleorderdropdown';
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }


  
  getBySaleOrderId(data){
    let url = 'api/app/saleorder/getlistofitembyinvoiceid';
    return this.service.post(url,data,false).pipe<ResponseModel>(tap((response: any) => {
      return response;
      })
    );
  }

  insertupdatecurrencyexchangerate(data){
    let url = 'api/app/currencyexchangerate/insertupdatecurrencyexchangerate';
    return this.service.post(url,data,false).pipe<ResponseModel>(tap((response: any) => {
      return response;
      })
    );
  }

  getcurrencyexchangeratebyid(Id){
    let url = `api/app/currencyexchangerate/getcurrencyexchangeratebyid?Id=${Id}`;
        return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}