import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlusIconService } from 'src/app/shared/common-components/ag-grid/plus-icon/plus-icon.service';
import { ProductOpeningService } from '../../product-opening.service';
import { TaxModel } from '../../product-opening.model';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgForm } from '@angular/forms';
import { CreateUpdateTaxSettingRequestModel, MasterModalTitle } from 'src/app/components/administration/masters/masters.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MastersService } from 'src/app/components/administration/masters/masters.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CountryCode, StorageName } from 'src/app/shared/enum/common-enum';
import { LayoutDetails, User } from 'src/app/auth/auth/auth.model';
import { IconHover } from '../../../../../shared/enum/common-enum';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class TaxComponent extends CommonService{
  @Input() getAllDropdownModel:any = {};
  currentHoverEffect = IconHover.Pink;
  dropdownSettings             : IDropdownSettings = {};
  @Input() modalTitle          : string;
  @Input() allProductDropdown  :any
  @Input() data                :any;
  @Input() editTaxSetting      : CreateUpdateTaxSettingRequestModel;
  @ViewChild("TaxForm") TaxForm!: NgForm;
  public createUpdateTaxSettingRequestModel = new CreateUpdateTaxSettingRequestModel();
  selectedOption            : string = '';
  taxModel                  = new TaxModel()
  masterModalTitle          = MasterModalTitle;
  countryCodeEnum           = CountryCode;
  storageName               = StorageName;
  user                      = new User();
  layoutDetails             = new LayoutDetails();

  constructor(public activeModal: NgbActiveModal,
    private productOpeningService:ProductOpeningService,
    public toaster                : ToastrService,
    public renderer : Renderer2,
    public mastersService: MastersService,
    public storageService : StorageService
    ) {
      super(renderer)
    }

  onDropdownChange(value: string) {
  }

  ngOnInit() {
    this.layoutDetails.countryCode    = this.storageService.retrieve('layoutDetails')?.countryCode??'';
    this.user =   this.storageService.retrieve('user');
    this.layoutDetails = this.storageService.retrieve('layoutDetails');
    if (this.data?.editTaxSetting?.id) 
      this.createUpdateTaxSettingRequestModel = this.data?.editTaxSetting;
    
    this.getAllDropdownModel.applicableOnDropdown = this.storageService.retrieve(this.storageName.APPLICATIONON_DROPDOWN);
    this.getAllDropdownModel.taxGroupDropdown = this.storageService.retrieve(this.storageName.TAX_GROUP_DROPDOWN);
    this.getAllDropdownModel.calculationDropdown = this.storageService.retrieve(this.storageName.CALCULATION_DROPDOWN);
    
  }

  // TAX SETTING API START 
  addEditTaxSetting() {
    if (this.TaxForm.valid) {
      if (this.data?.editTaxSetting?.id) {
        this.updateTaxSetting();
      } else {
        this.createTaxSetting();
      }
    }
  }



  createTaxSetting() {
    if (this.validateForm()) {
      this.mastersService
        .insertTaxSetting(this.createUpdateTaxSettingRequestModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            this.activeModal.close(res.isSuccess);
            this.toaster.success(res.message);
          } else {
            this.toaster.error(res.errors[0]);
          }
        });
    }
  }
  
  updateTaxSetting() {
    if (this.validateForm()) {
      this.mastersService
        .updateTaxSetting(this.createUpdateTaxSettingRequestModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            this.activeModal.close(res.isSuccess);
            this.toaster.success(res.message);
          } else {
            this.toaster.error(res.errors[0]);
          }
        });
    }
  }

  //To ensure that form is not submitted without selecting  

  validateForm(): boolean {
    if (!this.createUpdateTaxSettingRequestModel.taxGroupId)
      {
     // this.toaster.error('Please select a valid option for Applicable For.');
     return false;
   }

   if (!this.createUpdateTaxSettingRequestModel.applicableOn ) {
     // this.toaster.error('Please select a valid option for Applicable For.');
     return false;
   }
    return true;
}
}