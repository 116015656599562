import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../../../report/report.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { Subscription } from 'rxjs';
import { IconHover } from '../../../../../shared/enum/common-enum';

@Component({
  selector: 'app-rfid-tag',
  templateUrl: './rfid-tag.component.html',
  styleUrls: ['./rfid-tag.component.scss']
})
export class RfidTagComponent implements OnDestroy{
  @Input() data:any;
  RFIDSCannedSubscription: Subscription;
  currentHoverEffect = IconHover.Pink;
  constructor(public activeModal: NgbActiveModal,
              public toaster    : ToastrService,
              public reportService    : ReportService,
              public storageService : StorageService
              ) {}

    ngOnInit(): void {
      this.RFIDSCannedSubscription = this.storageService.RFIDSCanned.subscribe((result) => {
        if(result != undefined && this.RFIDSCannedSubscription != undefined)
           this.data.rfid = result;
      });
    }
    
    updateRFID(){
      var updateData={id:this.data.id, rfidNumber:this.data.rfid}
      this.reportService.updaterfidtag(updateData).subscribe((res)=>{
        if(res.isSuccess){
          this.activeModal.close({isSuccess:true,response:this.data});
          this.toaster.success(res.message);
        }else this.toaster.error(res.message);
      })
    }

    dismiss(){
      this.activeModal.close({isSuccess:false,response:this.data})
    }

    ngOnDestroy(): void {
      if(this.RFIDSCannedSubscription != undefined)
        this.RFIDSCannedSubscription.unsubscribe();
    }
}