
<div class="form-group d-flex align-items-center">
    <input 
        type="radio" 
        [id]="id"
        tabindex="tabindex"
        [name]="name"
        class="radio_Button"
        [(ngModel)]="status"
        [value]="radioButtonValue"
        (change)="check.emit(radioButtonValue)"
        [required]="isRequired"
        >   
    <label [for]="id" class="pl-14 f-14 font-semibold text-gray-color m-0">{{radioButtonLabel}}<span class="digits"></span></label>
</div>  
  
