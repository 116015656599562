import { MetalSectionService } from '../metal-section/metal-section.service';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { InvoiceService } from '../../../components/transaction/invoice/invoice.service';
import { ResponseModel } from '../../models/response-model.model';
import { Subscription, catchError } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { CommonService } from "../../../shared/services/common.service";
import { CalculateOn, CalculationType, CountryCode, IconHover, MakingChargeType, StorageName } from '../../enum/common-enum';
import { ProductService } from '../../../components/product/product.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiamondCategory, LedgerItemTypeTaxes, MetalType, ModalPopupSize, VoucherType, VoucherTypeName, commonModalOptions } from '../../models/common.model';
import { AddeditcategoryComponent } from '../../../components/product/product-opening/popup/addeditcategory/addeditcategory.component';
import { PlusIconService } from '../ag-grid/plus-icon/plus-icon.service';
import { ProductOpeningComponent } from '../../../components/product/product-opening/product-opening.component';
import { CaratPopupComponent } from '../common-popup/carat-popup/carat-popup.component';
import { NavTabsComponent } from '../nav-tabs/nav-tabs.component';
import { TransactionService } from '../../../components/transaction/transaction.service';
import { StockJournalService } from '../../../components/transaction/stock-journal/stock-journal.service';
import { CompressImageService } from '../../services/compress-image.service';
import { ColDef } from 'ag-grid-community';
import { BarcodePopupComponent } from '../../../components/report/popups/barcode-popup/barcode-popup.component';
import { LoadDropdownDataService } from '../load-dropdown-data/load-dropdown-data.service';
import { AddEditLocationComponent } from '../../../components/product/product-opening/popup/add-edit-location/add-edit-location.component';
import { MetalTabs } from '../metal-section/metal-section.model';
import { WeightMachineService } from '../../services/weight-machine.service';
import { NotificationService } from '../../services/notification.service';
import { CustomSelect2Component } from '../custom-select2/custom-select2.component'
import { ToastrService } from 'ngx-toastr';
import { TextProcessingService } from '../../services/text-processing.service';
@Component({
  selector: 'app-metal-section-child',
  templateUrl: './metal-section-child.component.html',
  styleUrls: ['./metal-section-child.component.scss']
})

export class MetalSectionChildComponent extends CommonService implements AfterViewInit, OnDestroy {
  @ViewChild(NavTabsComponent) navTabsComponent: NavTabsComponent
  @ViewChild(CustomSelect2Component) customSelect2Component: CustomSelect2Component;
  @Input() data: any;
  @Input() comment: string;
  @Input() groupName: string;
  @Input() isPopup: boolean = true;

  @Output() getTabId = new EventEmitter<any>();
  @Output() commentChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() groupNameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendDataToItemList = new EventEmitter<any>();
  @Output() onGridReadyCompleted = new EventEmitter<any>();

  isGroup: boolean;
  // formName: string;
  // formModel: any;
  // metalSectionModel: any;
  activemetalId: number;
  isDisabled: boolean = false;
  tabGroupList: any[];
  filteredTabGroupList: any[];
  tabId: any = 1;
  currentHoverEffect = IconHover.Pink;
  tabItems: any[] = [];
  productTax: any[] = [];
  invoiceDocumentDesignNo = []
  storage = StorageName;
  gridApi: any = {};
  isLoading: boolean = false;
  voucherType: string;
  applicableOnProducts: any[] = [];
  invoiceDocumentResources: any[] = []
  allMetalModel: any = {};
  gridApiGoldTable: any;
  currentGridApi: any;
  gridApiSilverTable: any;
  gridApiDiamondTable: any;
  gridApiImmitationAndWatchesTable: any;
  uploadImagesList = [];
  @Input() gridDynamicHeightClass: string = 'h-150px';
  onItemListRowClickedSubscription: Subscription;
  resetInvoiceSubscription: Subscription;
  onAddRowButtonSubscription: Subscription | undefined;
  onNavTabKeyPressedSubscription: Subscription;
  barcodeScanValueSourceSubscription: Subscription;
  isImageSaved: boolean = false;
  purchaseInvoiceNoDropdownList: any;
  barcodeClickSubscription: Subscription;
  voucherTypeName: typeof VoucherTypeName = VoucherTypeName;
  @Output() fillDropdownData = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    public metalSectionService: MetalSectionService,
    public stockJournalService: StockJournalService,
    private agGridService: AgGridService,
    public renderer: Renderer2,
    public modalService: NgbModal,
    public invoiceService: InvoiceService,
    public transactionService: TransactionService,
    public storageService: StorageService,
    public productService: ProductService,
    public compressImageService: CompressImageService,
    public loadDropdownDataService: LoadDropdownDataService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private weightMachineService: WeightMachineService,
    public toaster: ToastrService,
    public textProcessingService: TextProcessingService
  ) {
    super(renderer);;
    this.onItemListRowClickedSubscription = this.storageService.onItemListRowClicked.subscribe((result) => {
      if (result != undefined) {
        result ? this.isImageSaved = true : false;
        this.onItemListRowClickedResponse(result?.data?.detailedJson, false, result?.data?.parentMetalId, result?.data);
      }
    });

    this.resetInvoiceSubscription = this.storageService.resetInvoice.subscribe((result) => {
      if (result != undefined)
        this.resetInvoiceResponse(result);
    });

    this.onNavTabKeyPressedSubscription = this.storageService.onNavTabKeyPressed.subscribe((tabId) => {
      if (tabId) {
        this.focusFirstRowFirstCell(tabId);
        this.agGridService.cameraButtonDisabled = this.checkAnyGridHasRowWithTabs();
      }
    });

    this.barcodeScanValueSourceSubscription = this.storageService.barcodeScanValueChange$.subscribe(({ barcodeNO, formName }) => {
      if (barcodeNO) {
        this.getProductDataByBarcodeNo(barcodeNO, formName)
      }
    })

    this.weightMachineService.initializeWeightMachine();
    this.listenToSerialData();
  }

  listenToSerialData() {
    this.weightMachineService.ipcService.on('serialData', (event, data) => {
      this.weightMachineService.handleSerialData(data);
      this.weightMachineService?.dataLogged?.subscribe((newData) => {
        var value = newData;
        if (value)
          this.updateGridWithReading(value);
        console.log('Serial reading :' + value);
      });

    });
  }

  updateGridWithReading(reading: string) {
    const gridApiMap = {
      1: this.gridApiGoldTable,
      2: this.gridApiSilverTable,
      4: this.gridApiDiamondTable,
      // 6: this.gridApiImmitationAndWatchesTable
    };

    this.currentGridApi = gridApiMap[this.tabId];

    if (this.currentGridApi) {
      const focusedCell = this.currentGridApi?.api?.getFocusedCell();
      let rowIndex = 0;
      rowIndex = focusedCell?.column?.colId == 'grossWt' ? focusedCell?.rowIndex : this.currentGridApi?.api?.getModel()?.getRowCount() - 1;
      console.log(focusedCell, focusedCell);
      // this.currentGridApi?.api?.stopEditing();
      this.agGridService?.focusCell(this.currentGridApi, rowIndex > 0 ? rowIndex : 0, 'grossWt', reading);
      if (focusedCell && focusedCell?.column?.colId == 'grossWt') this.currentGridApi?.api?.startEditingCell({ rowIndex, colKey: 'grossWt' });
      this.currentGridApi?.api?.refreshCells();
      this.cdr?.detectChanges();
    } else {
      console.warn('No grid API found for tabId:', this.tabId);
    }
  }
  onRapnetIconClick(params) {
    const result = params?.detail?.data?.data
    const requestModel = {
      shape: result.shapeId,
      size: result.sizeId,
      color: result.colorId,
      clarity: result.clarityId,
    }
    this.agGridService.getRapnetpriceList(requestModel).subscribe((res => {
      if (res?.isSuccess) {
        params?.detail?.data?.node.setDataValue('rate', res?.response?.toString());
        params?.detail?.data?.node.setDataValue('rapNetValuation', res?.response?.toString());
      } else {
        this.toaster.error(res?.message)
      }
    }))

  }
  onDeleteIconClick(params) {
    const result = params?.detail?.data
    if (result != undefined) {
      this.agGridService.cameraButtonDisabled = this.checkAnyGridHasRowWithTabs();
      this.onDeleteRowClickedResponse(result);
      if (!this.checkAnyGridHasRowWithTabs()) {
        this.uploadImagesList = [];
        this.isImageSaved = false;
      }
    }
  }

  ngOnInit() {

  }

  initialCalls() {
    this.loadVoucherTypeWiseData();

    this.selectTab(this.tabId);
  }

  fillPreviousRow() {
    var result = this.data?.metalSectionModel?.previousRow;
    var data = result?.detail?.data?.data;
    if (result != undefined) {
      result ? this.isImageSaved = true : false;
      if (this.data?.formName == StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE)
        this.assignMoreSelectedItems(data?.detailedJson, false, data?.parentMetalId, data);
      else
        this.onItemListRowClickedResponse(data?.detailedJson, false, data?.parentMetalId, data);
    }
  }

  onGridReady(item, gridApi) {
    switch (item) {
      case 0: this.gridApiGoldTable = gridApi;
        this.sendDataToItemList.emit(this.getGridApiOnSelectdTab()); break;
      case 1: this.gridApiSilverTable = gridApi; break;
      case 2: this.gridApiDiamondTable = gridApi; break;
      case 4: this.gridApiImmitationAndWatchesTable = gridApi; break;
    }
    this.checkAnyGridHasRowWithTabs();
    var currentTab = Number(item + 1);
    if (this.tabItems.length === item)
      this.onGridReadyCompleted.emit(true);

  }

  selectTab(tabId: number) {
    this.tabId = tabId;
    this.storageService.store('ActiveId', this.tabId)
    this.getTabId.emit(tabId);
    this.invoiceService.TabId.next(this.tabId);
    this.sendDataToItemList.emit(this.getGridApiOnSelectdTab());
    this.agGridService.cameraButtonDisabled = this.checkAnyGridHasRowWithTabs();
    var storageUDFs = new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN);
    setTimeout(() => {
      this.setUdfColumns(storageUDFs);
    }, 2000)
  }

  onKeydownBarcodeNo(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      // Prevent default Enter key behavior
      event.preventDefault();
      this.getProductDataByBarcodeNo(this.data.metalSectionModel.barcodeNo, this.data.formName);
    }
  }

  ngAfterViewInit(): void {
    this.initialCalls();
    setTimeout(() => {
      this.setTaxCustomerwise(this.data.formModel?.taxFromCustomer);
      if (this.data?.barcodeNo != undefined)
        this.getProductDataByBarcodeNo(this.data?.barcodeNo, this.data?.formName);
      this.fillPreviousRow();
      if (this.data?.metalSectionModel?.defaultActiveMetalTab)
        this.selectNavTab(this.data?.metalSectionModel?.defaultActiveMetalTab, true);
    }, 1000);
  }

  loadDesignNoDropdown() {
    const designNoDropdown = this.storageService.retrieve(StorageName.DESIGN_NO_DROPDOWN);
    if (designNoDropdown)
      this.customSelect2Component?.setDataSource(designNoDropdown, this.data?.metalSectionModel?.designNo);
    if (this.data?.metalSectionModel?.designNo)
      this.getitemdetailbydesignno(this.data?.metalSectionModel?.designNo)
  }

  resetdesignNoDropdown() {
    const designNoDropdown = this.storageService.retrieve(StorageName.DESIGN_NO_DROPDOWN);
    this.customSelect2Component?.setDataSource(designNoDropdown, '0');
  }

  onClickPlusIcon(params) {
    switch (params?.detail?.data?.displayName) {
      case 'Category': this.agGridService.openModal(AddeditcategoryComponent, params, this.storage.CATEGORY_DROPDOWN, 'categoryId'); break;
      case 'Carat': this.agGridService.openModal(CaratPopupComponent, params, this.storage.CARAT_DROPDOWN, 'caratId'); break;
      case 'Location': this.agGridService.openModal(AddEditLocationComponent, params, this.storage.LOCATION_DROPDOWN, 'locationId'); break;
      case 'Product': this.agGridService.openSlider(ProductOpeningComponent, params, this.storage.PRODUCT_DROPDOWN, 'productDetailId'); break;
    }
    // if (this.data?.formName)
    //   this.getAllDropdownDataFormWise();
  }

  getAllDropdownDataFormWise() {
    this.loadDropdownDataService.getAllDropdownDataFormWise(this.data?.formName)
      .pipe(catchError((err) => this.handleError((err))))
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess)
          this.fillDropdownData.emit(res?.response);
      })
  }


  openModalBarcodePopup(): void {
    var data = { MetalTabId: this.tabId, isbarcode: false, }
    this.notificationService.openModalPopup(BarcodePopupComponent, data, '', ModalPopupSize.XL, '', false, true).then(
      (rowData) => {
        if (rowData)
          this.assingToMetalGrid(rowData);
      })
  }

  assingToMetalGrid(data) {
    this.getProductDataByBarcodeNo(data?.barcodeNo, StorageName.FORMNAME_TRANSACTION_SALESINVOICE);
  }

  focusFirstRowFirstCell(tabId) {
    switch (tabId) {
      case 1: this.agGridService.setFocusToLastRow(this.gridApiGoldTable); break;
      case 2: this.agGridService.setFocusToLastRow(this.gridApiSilverTable); break;
      case 4: this.agGridService.setFocusToLastRow(this.gridApiDiamondTable); break;
      case 5: this.agGridService.setFocusToLastRow(this.gridApiImmitationAndWatchesTable); break;
    }
  }

  loadVoucherTypeWiseData() {

    if (this.data.formModel?.voucherType == this.voucherTypeName?.StockJournal)
      this.loadDropdownDataService.getInvoiceNo(this.data.formModel?.voucherType).subscribe({
        next: (res) => {
          if (res?.isSuccess)
            this.onTaxChangeResponse(res?.response?.taxAndMetalDetailsByVoucherType);
          else
            this.onTaxChangeResponse(undefined);
        },
        error: (err) => {

          this.onTaxChangeResponse(undefined);
        }
      });
    else
      this.setTabItems();
  }

  onDeleteRowClickedResponse(event) {
    var deletedRowId = event.data.rowId;
    if (deletedRowId != undefined) {
      var allMetals = this.getAllMetals();
      allMetals.goldItem = allMetals.goldItem.filter(a => a.rowId != deletedRowId);
      allMetals.silverItem = allMetals.silverItem.filter(a => a.rowId != deletedRowId);
      allMetals.diamondItem = allMetals.diamondItem.filter(a => a.rowId != deletedRowId);
      allMetals.imitationItem = allMetals.imitationItem.filter(a => a.rowId != deletedRowId);
      this.setAllMetals(allMetals);
    }
  }

  headerAddClick(data) {
    // var metalTypeId: any = 0;
    // if (data.params.storageName.indexOf('DIAMOND') > -1)
    //   metalTypeId = MetalType.Diamond;
    // var firstRowNode = data.params.api.getRowNode(0);
    // this.data.metalSectionModel.parentMetalId = metalTypeId;
    // switch (data.params.displayName) {
    //   case "Gold Amount":
    //     var postGoldData = {
    //       purityPer: "1.0",
    //       grossWt: firstRowNode.data.goldWt,
    //       caratId: firstRowNode.data.goldCaratId,
    //       parentMetalId: metalTypeId
    //     }
    //     this.setSelectedTab(1);
    //     setTimeout(() => { this.agGridService.appendRow(this.gridApiGoldTable, postGoldData); }, 1000);
    //     break;
    // }
  }

  lastCellTriggered(params) {
    var arrayOfMetals = [];
    var metals = this.getAllMetals();
    if (metals.goldItem.length > 0)
      arrayOfMetals.push(1);
    if (metals.silverItem.length > 0)
      arrayOfMetals.push(2);
    if (metals.diamondItem.length > 0)
      arrayOfMetals.push(4);
    if (metals.imitationItem.length > 0)
      arrayOfMetals.push(6);

    var indexGold = params.dataModel.storageName.indexOf("GOLD");
    var indexSilver = params.dataModel.storageName.indexOf("SILVER");
    var indexDiamond = params.dataModel.storageName.indexOf("DIAMOND");
    var indexImitation = params.dataModel.storageName.indexOf("IMITATION");

    var indexOfCurrentTabItem: any = [];
    if (indexGold != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Gold);
    if (indexSilver != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Silver);
    if (indexDiamond != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Diamond);
    if (indexImitation != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Imitation);
    if (indexOfCurrentTabItem.length > 0)
      this.setSelectedTab(indexOfCurrentTabItem[0]);
    else this.storageService.outOfMetalSection.next(params);
  }

  async onTaxChangeResponse(res) {
    if (this.data?.metalSectionModel?.voucherWiseData == undefined)
      this.data.metalSectionModel.voucherWiseData = res;

    this.setTabItems();
  }

  public setTabItems() {
    this.tabItems = this.data?.metalSectionModel?.voucherWiseData?.allMetalList ?? [];
    this.tabGroupList = [];
    this.tabItems.forEach(tab => {
      switch (tab.id) {
        case MetalTabs.Gold: this.tabGroupList.push({ tabId: MetalTabs.Gold, dataModel: this.data?.metalSectionModel?.agGridDataModelGold, label: 'GoldItem', tabIndex: 0 }); break;
        case MetalTabs.Silver: this.tabGroupList.push({ tabId: MetalTabs.Silver, dataModel: this.data?.metalSectionModel?.agGridDataModelSilver, label: 'SilverItem', tabIndex: 1 }); break;
        case MetalTabs.Diamond: this.tabGroupList.push({ tabId: MetalTabs.Diamond, dataModel: this.data?.metalSectionModel?.agGridDataModelDiamond, label: 'DiamondItem', tabIndex: 2 }); break;
        case MetalTabs.ImmitationAndWatches: this.tabGroupList.push({ tabId: MetalTabs.ImmitationAndWatches, dataModel: this.data?.metalSectionModel?.agGridDataModelImmitationAndWatches, label: 'ImitationItem', tabIndex: 4 }); break;
      }
    });
  }

  setUdfColumns(storageUDFs?: any) {
    if (this.data?.metalSectionModel != undefined) {
      const metalIdWiseUdfGold = storageUDFs?.filter((element) => element?.extra2 == MetalType.Gold);
      const metalIdWiseUdfSilver = storageUDFs?.filter((element) => element?.extra2 == MetalType.Silver);
      const metalIdWiseUdfDiamond = storageUDFs?.filter((element) => element?.extra2 == MetalType.Diamond);
      const metalIdWiseUdfImmitationAndWatches = storageUDFs?.filter((element) => element?.extra2 == MetalType.Imitation);
      switch (this.tabId) {
        case 1: this.agGridService.updateColumnDefinations(this.data?.metalSectionModel?.agGridDataModelGold, this.gridApiGoldTable, MetalType.Gold, metalIdWiseUdfGold); break;
        case 2: this.agGridService.updateColumnDefinations(this.data?.metalSectionModel?.agGridDataModelSilver, this.gridApiSilverTable, MetalType.Silver, metalIdWiseUdfSilver); break;
        case 4: this.agGridService.updateColumnDefinations(this.data?.metalSectionModel?.agGridDataModelDiamond, this.gridApiDiamondTable, MetalType.Diamond, metalIdWiseUdfDiamond); break;
        case 5: this.agGridService.updateColumnDefinations(this.data?.metalSectionModel?.agGridDataModelImmitationAndWatches, this.gridApiImmitationAndWatchesTable, MetalType.Imitation, metalIdWiseUdfImmitationAndWatches); break;
      }
    }
  }

  setTaxCustomerwise(taxFromCustomer) {
    if (this.data?.metalSectionModel?.voucherWiseData != undefined) {
      this.data.metalSectionModel.voucherWiseData.applicableOnInvoiceByCustomer = taxFromCustomer?.applicableOnInvoice;
      this.data.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer = taxFromCustomer?.applicableOnProducts;
    }
  }


  onCellValueChangedDetect(params) {
    switch (params.column.colId) {
      case "productDetailId": this.assignGridDataProductWise(params); break;
      case "styleId": this.assignGridDataStyleWise(params); break;
      case "itemCode": this.assignGridDataItemCodeWise(params); break;
      case "caratId": this.assignRateCaratWise(params); break;
      case "goldCaratId": this.assignGoldRateGoldCaratWise(params); break;
    }

    if (params.column.colId != "purchaseAmount")
      this.callCalulationApi(params.data, false, params.column.colId);
  }

  assignGridDataProductWise(params) {
    var productDropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
    var particularProductData = productDropdown?.find(a => a.id == parseInt(params?.node?.data?.productDetailId));
    const obj = JSON?.parse(particularProductData?.extra2);
    params.data.purityPer = params.data.purityPer == undefined || Number(params.data.purityPer ?? 0) == 0 ? '1.0' : params.data.purityPer;
    params.data.makingOn = obj[0].makingOn;
    params.data.categoryId = obj[0].categoryId.toString();
    params.data.weightPerPiece = obj[0].weightPerPiece;
    params.data.discountPer = obj[0].discountPer ?? 0;
    // if(params.data.weightPerPiece == undefined || params.data.weightPerPiece == 0)
    //   params.data.grossWt = 0;
    this.productTax = obj[0].taxesIds;//.map(a=>a.taxId);
    if (params.data.caratId == undefined || params.data.caratId == 0)
      params.data.caratId = obj[0]?.caratId;

    if (params.data.goldCaratId == undefined || params.data.goldCaratId == 0)
      params.data.goldCaratId = obj[0]?.caratId;

    if (params.colDef.headerComponentParams.storageName.indexOf('PURCHASE') != -1 || params.colDef.headerComponentParams.storageName.indexOf('MATERIAL_ISSUE') != -1 || params.colDef.headerComponentParams.storageName.indexOf('JOBWORKORDER') != -1)
      params.data.purityPer = obj[0].purityPurchase;
    else if (params.colDef.headerComponentParams.storageName.indexOf('SALE') != -1 || params.colDef.headerComponentParams.storageName.indexOf('MATERIAL_RECEIVED') != -1 || params.colDef.headerComponentParams.storageName.indexOf('JOBWORKINVOICE') != -1)
      params.data.purityPer = obj[0].puritySale;
    params.data.purityPer = Number(params.data.purityPer) == 0 ? "1.0" : this.textProcessingService.fixWeightDecimal(params.data.purityPer);

    if (params.data.cquantity == undefined)
      params.data.cquantity = obj[0].quantity;
    if (params.data.wastagePer == undefined)
      params.data.wastagePer = obj[0].wastagePer;
    params?.api?.refreshCells();
  }

  assignGridDataStyleWise(params) {
    const styleDropdown = this.storageService.retrieve(StorageName.STYLE_DROPDOWN);
    var particularStyleData = styleDropdown?.find(a => a.id == params?.node?.data?.styleId);
    const obj = (JSON?.parse(particularStyleData?.extra1)[0]);
    for (let key in obj) {
      if (key == "categoryId")
        key = "diamondCategoryId"
      params.data[key] = obj[key]?.toString();
    }
    params?.api?.refreshCells();
  }

  assignGridDataItemCodeWise(params) {
    this.transactionService.getiItemDetailByItemCode(params.data.itemCode).pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: any) => {
        for (let key in res.response)
          params.data[key] = res.response[key]?.toString();
      });
    params?.api?.refreshCells();
  }

  assignRateCaratWise(params) {
    const caratDropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN);
    var particularCaratData = caratDropdown?.find(a => a.id == params?.node?.data?.caratId);
    params.data.rate = particularCaratData?.extra2 ? Number(particularCaratData?.extra2)?.toFixed(2) : '0.00';
    params.data.gozUsdRate = this.data.formModel?.invoiceModel?.gozUsdRate;
    params?.api?.refreshCells();
  }

  assignRateGoldCaratWise(params) {
    const caratDropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN);
    var particularCaratData = caratDropdown?.find(a => a.id == params.goldCaratId);
    params.goldPurityPer = particularCaratData?.extra2 ? Number(particularCaratData?.extra3)?.toFixed(2) : '1.00';
    // params.goldRate = particularCaratData?.extra2? Number(particularCaratData?.extra2)?.toFixed(2):'0.00';
    // this.gridApiDiamondTable?.api?.refreshCells();
  }

  assignGoldRateGoldCaratWise(params) {
    const goldcaratDropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN);
    var particularCaratData = goldcaratDropdown?.find(a => a.id == params?.node?.data?.goldCaratId);
    params.data.goldRate = particularCaratData?.extra2 ? Number(particularCaratData?.extra2)?.toFixed(2) : '0.00';
    params?.api?.refreshCells();
  }

  callCalulationApi(node, IsBacodeScan = false, calculateByField, tabId = undefined, calculateTax = true) {
    var productTax = this.data?.metalSectionModel.voucherWiseData.applicableOnProducts != undefined ? this.data?.metalSectionModel.voucherWiseData.applicableOnProducts?.map((x) => { return { taxId: x.id }; }) : this.applicableOnProducts?.map((x) => { return { taxId: x.id }; });
    if (IsBacodeScan) {
      if (node.calculationType == CalculationType.Fix)
        node.rate = (node.saleAmount > 0) ? node.saleAmount : node.rate;
      node.saleAmount = 0;
      node.purchaseAmount = 0;
    }

    node.itemTaxDetails = productTax;
    node.productDetailId = node.productDetailId ?? '0';
    if ((node.makingTypeId == undefined || node.makingTypeId == 0))
      node.makingTypeId = this.storageService.retrieve(StorageName.MAKINGCHARGESTYPE_DROPDOWN).find(a => a.extra1 === 'true')?.id;
    this.gridApiGoldTable?.api?.refreshCells();
    if (tabId == undefined)
      tabId = this.tabId;
    switch (tabId) {
      case 1:
      case 2: if (calculateByField != "reverseCalculationAmount") this.calculateGoldSilverItem(node, calculateByField, tabId, IsBacodeScan);
      else this.reversecalculateGoldSilverItem(node, calculateByField, tabId); break;
      case 4: if (calculateByField != "reverseCalculationAmount") {
        this.calculateDiamondItem(node, calculateByField, IsBacodeScan);
      }
      else this.reverseCalculateDiamondItem(node); break;
      case 6: this.calculateImitationItem(node, IsBacodeScan); break;
    }
  }

  taxCalculationLoop(model, metalId, isBarcodeScan = false, fromReverse = false, calculateTaxFromInvoice = true): any {

    if (calculateTaxFromInvoice == true)
      for (const item of model.itemTaxDetails ?? []) {
        var taxSetting = this.storageService.retrieve("TaxSetting");
        var tax = taxSetting?.filter(a => a?.id == item?.taxId)[0];
        if (tax != null && tax != undefined) {
          if (isBarcodeScan == true || this.productTax.length <= 0) {
            var productDropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
            var particularProductData = productDropdown?.find(a => a.id == parseInt(model.productDetailId));
            const obj = JSON.parse(particularProductData?.extra2);
            this.productTax = obj[0].taxesIds;//.map(a=>a.taxId);
          }
          let applyTaxOnAmount: number;
          var taxRate = 0;
          var totalTaxRate = 0;
          var totalTaxRateExceptMaking = 0;
          var calculateTax = false;
          var calculationMode: any;
          if (this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer?.length > 0) {
            var element = this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer?.filter(a => a.id == tax.id.toString());
            var taxAmount = 0;
            var taxableAmount = 0;
            element.forEach(taxByCustomer => {
              applyTaxOnAmount = 0;
              taxRate = tax.rate;
              calculationMode = undefined;
              calculateTax = taxByCustomer != undefined;
              var taxOnMakingExist = false;
              switch (Number(taxByCustomer.extra3)) {
                case LedgerItemTypeTaxes.Gold:
                  if (metalId == MetalType.Gold) {
                    calculationMode = CalculateOn.ProductAmount;
                    taxOnMakingExist = this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  if (metalId == MetalType.Diamond) {
                    calculationMode = CalculateOn.GoldAmount;
                    taxOnMakingExist = this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.GoldMaking:
                  if (metalId == MetalType.Gold) {
                    calculationMode = CalculateOn.MakingCharge;
                    taxOnMakingExist = this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  if (metalId == MetalType.Diamond) {
                    calculationMode = CalculateOn.MakingCharge;
                    taxOnMakingExist = this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.Silver:
                  if (metalId == MetalType.Silver) {
                    calculationMode = CalculateOn.ProductAmount;
                    taxOnMakingExist = this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.SilverMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.SilverMaking:
                  if (metalId == MetalType.Silver) {
                    calculationMode = CalculateOn.MakingCharge;
                    taxOnMakingExist = this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.SilverMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.Diamond:
                  if (metalId == MetalType.Diamond && model.category == DiamondCategory.Jewellery)
                    calculationMode = CalculateOn.ProductAmount;

                  break;
                case LedgerItemTypeTaxes.LooseDiamond:
                  if (metalId == MetalType.Diamond && model.category == DiamondCategory.Diamonds)
                    calculationMode = CalculateOn.ProductAmount;
                  break;
                case LedgerItemTypeTaxes.CertifiedDiamond:
                  if (metalId == MetalType.Diamond && model.category == DiamondCategory.GemStones)
                    calculationMode = CalculateOn.ProductAmount;
                  break;
              }


              // if(this.data.formName.invoiceModel.internalFix == true && )
              //   calculationMode = CalculateOn.MakingCharge;
              switch (Number(calculationMode)) {
                case CalculateOn.HallmarkAmount: applyTaxOnAmount = Number(model.hallmarkAmount ?? 0)
                case CalculateOn.GoldAmount: applyTaxOnAmount = Number(model.goldAmount ?? 0); break;
                case CalculateOn.MakingCharge: applyTaxOnAmount = Number(model.makingAmount ?? 0); break;
                case CalculateOn.ProductAmount: applyTaxOnAmount = taxOnMakingExist == true ? Number(model.amount ?? 0) : Number(model.netAmount ?? 0); break;
                default: applyTaxOnAmount = 0; break;
              }
              taxableAmount += applyTaxOnAmount
              taxAmount += calculateTax ? (applyTaxOnAmount * Number(taxRate ?? 0)) / 100 : 0;
              totalTaxRate += (calculationMode != undefined) ? taxRate : 0;
              totalTaxRateExceptMaking += (calculationMode != CalculateOn.MakingCharge && calculationMode != undefined) ? Number(taxRate ?? 0) : 0;
            });
            item.taxableAmount = taxableAmount;
            item.taxAmount = taxAmount;
            item.taxAmount = this.textProcessingService.fixAmountDecimal(taxAmount);
            item.taxRate = calculateTax ? Number(totalTaxRate ?? 0) : 0;
            item.taxValue = calculateTax ? Number(totalTaxRate ?? 0) : 0;
            item.taxRateExceptMaking = totalTaxRateExceptMaking;
            item.calculateTax = calculateTax;
            item.taxName = tax.name;
          }
          else {
            calculateTax = this.productTax.some(a => a.taxId === tax.id);
            var productTaxValue = this.productTax.filter(a => a.taxId === tax.id)[0];
            const countryCode = this.storageService.retrieve('layoutDetails')?.countryCode ?? '';
            if (countryCode == CountryCode.IN) {
              var accountLedgers = this.storageService.retrieve(StorageName.ACCOUNTLEDGER_DROPDOWN).filter(a => a.id == this.data.formModel.invoiceModel.accountLedgerId)[0];
              calculateTax = false;
              if (accountLedgers?.extra1 == '1' && tax?.isStateWise == true)
                calculateTax = true;
              if (accountLedgers?.extra1 == '0' && tax?.isStateWise != true)
                calculateTax = true;
            }

            taxRate = calculateTax ? productTaxValue?.taxValue : tax.rate;
            calculationMode = productTaxValue?.calculationMode;

            var taxOnMakingExist = this.productTax.filter(a => a.calculationMode === CalculateOn.MakingCharge)?.length > 0;
            if (this.data.formModel.invoiceModel.internalFix == true)
              calculationMode = CalculateOn.MakingCharge;
            switch (Number(calculationMode)) {
              case CalculateOn.HallmarkAmount: applyTaxOnAmount = Number(model.hallmarkAmount ?? 0); break;
              case CalculateOn.MakingCharge: applyTaxOnAmount = Number(model.makingAmount ?? 0); break;
              case CalculateOn.ProductAmount: applyTaxOnAmount = (taxOnMakingExist == true ? Number(model.amount ?? 0) : Number(model.netAmount ?? 0)) - Number(model.discountAmount ?? 0); break;
              default: applyTaxOnAmount = 0; break;
            }
            item.taxableAmount = applyTaxOnAmount;
            item.taxAmount = calculateTax ? (applyTaxOnAmount * Number(taxRate ?? 0)) / 100 : 0;
            item.taxAmount = this.textProcessingService.fixAmountDecimal(item.taxAmount);
            item.taxRate = calculateTax ? Number(taxRate ?? 0) : 0;
            item.taxValue = calculateTax ? Number(taxRate ?? 0) : 0;
            item.taxRateExceptMaking = (calculateTax && calculationMode != CalculateOn.MakingCharge) ? Number(taxRate ?? 0) : 0;
            item.calculateTax = calculateTax;
            item.taxName = tax.name;
          }
        }
      }
    model.taxableAmount = model.itemTaxDetails?.reduce((acc, curr) => acc + (Number(curr.taxableAmount) || 0), 0) || 0;
    model.taxAmount = model.itemTaxDetails?.reduce((acc, curr) => acc + (Number(curr.taxAmount) || 0), 0) || 0;
    model.taxRate = model.itemTaxDetails?.filter(a => a.calculateTax == true).reduce((acc, curr) => acc + (curr.taxRate || 0), 0) || 0;
    model.taxValue = model.taxRate;
    model.taxRateExceptMaking = model.itemTaxDetails?.reduce((acc, curr) => acc + (curr.taxRateExceptMaking || 0), 0) || 0;
    return model;
  }

  taxCalculation(model, metalId, isBarcodeScan: boolean = false, fromReverse = false, calculateTax = true) {
    model = this.taxCalculationLoop(model, metalId, isBarcodeScan, fromReverse, calculateTax);

    model.netAmountWithTax = Number(model.netAmount ?? 0) + Number(model.taxAmount ?? 0);
    model.itemTaxDetails?.forEach((key, value) => { model[key.taxName] = key.taxAmount?.toString(); });
    model.diamondAmount = this.textProcessingService.fixAmountDecimal(model.diamondAmount);
    model.purchaseAmount = this.textProcessingService.fixAmountDecimal(model.purchaseAmount);
    model.saleAmount = this.textProcessingService.fixAmountDecimal(model.saleAmount);
    model.goldAmount = this.textProcessingService.fixAmountDecimal(model.goldAmount);
    model.makingAmount = this.textProcessingService.fixAmountDecimal(model.makingAmount);
    model.makingRate = this.textProcessingService.fixAmountDecimal(model.makingRate);
    model.hallmarkRate = this.textProcessingService.fixAmountDecimal(model.hallmarkRate);
    model.metalValue = this.textProcessingService.fixAmountDecimal(model.metalValue);
    model.amount = this.textProcessingService.fixAmountDecimal(model.amount);
    model.netAmount = this.textProcessingService.fixAmountDecimal(model.netAmount);
    model.makingDiscount = this.textProcessingService.fixAmountDecimal(model.makingDiscount);
    model.taxAmount = this.textProcessingService.fixAmountDecimal(model.taxAmount);
    model.netAmountWithTax = this.textProcessingService.fixAmountDecimal(model.netAmountWithTax);
    model.requestedPurityPer = this.textProcessingService.fixWeightDecimal(model.requestedPurityPer);
    model.requestedWt = this.textProcessingService.fixWeightDecimal(model.requestedWt);
    model.alloyWt = this.textProcessingService.fixWeightDecimal(model.alloyWt);
    model.goldWt = this.textProcessingService.fixWeightDecimal(model.goldWt);
    model.grossWt = this.textProcessingService.fixWeightDecimal(model.grossWt);
    model.netWt = this.textProcessingService.fixWeightDecimal(model.netWt);
    model.packetWt = this.textProcessingService.fixWeightDecimal(model.packetWt);
    model.packetLessWt = this.textProcessingService.fixWeightDecimal(model.packetLessWt);
    model.purityWt = this.textProcessingService.fixWeightDecimal(model.purityWt);
    model.finalWt = this.textProcessingService.fixWeightDecimal(model.finalWt);
    model.wastageWt = this.textProcessingService.fixWeightDecimal(model.wastageWt);
    model.goldWt = this.textProcessingService.fixWeightDecimal(model.goldWt);
    model.carat = this.textProcessingService.fixWeightDecimal(model.carat);
    model.weight = this.textProcessingService.fixWeightDecimal(model.weight);
    model.goldPurityWt = this.textProcessingService.fixWeightDecimal(model.goldPurityWt);
    model.rate = this.textProcessingService.fixAmountDecimal(model.rate);
    model.goldLossWt = this.textProcessingService.fixWeightDecimal(model.goldLossWt);
    model.goldLossValue = this.textProcessingService.fixAmountDecimal(model.goldLossValue);
    model.settingCharge = this.textProcessingService.fixWeightDecimal(model.settingCharge);
    model.settingChargeAmount = this.textProcessingService.fixAmountDecimal(model.settingChargeAmount);
    model.markUpAmount = this.textProcessingService.fixAmountDecimal(model.markUpAmount);
    model.lessWt = this.textProcessingService.fixWeightDecimal(model?.lessWt);
    model.minimumPrice = this.textProcessingService.fixWeightDecimal(model?.minimumPrice, 2);
    this.refreshAllCells();
  }

  refreshAllCells(all: boolean = false) {
    if (all == false)
      switch (this.tabId) {
        case 1: this.gridApiGoldTable?.api?.refreshCells(); break;
        case 2: this.gridApiSilverTable?.api?.refreshCells(); break;
        case 4: this.gridApiDiamondTable?.api?.refreshCells(); break;
        case 5: this.gridApiImmitationAndWatchesTable?.api?.refreshCells(); break;
      }
    else {
      this.gridApiGoldTable?.api?.refreshCells();
      this.gridApiSilverTable?.api?.refreshCells();
      this.gridApiDiamondTable?.api?.refreshCells();
      this.gridApiImmitationAndWatchesTable?.api?.refreshCells();
    }
  }



  calculateGoldSilverItem(model, calculateByField, metalid, isBarcodeScan = false, fromReverse = false, calculateTax = true) {

    model.reverseCalculationAmount = Number(model.reverseCalculationAmount ?? 0);
    model.diamondAmount = Number(model.diamondAmount ?? 0);
    model.makingAmount = Number(model.makingAmount ?? 0);
    model.makingRate = Number(model.makingRate ?? 0);
    model.hallmarkRate = Number(model.hallmarkRate ?? 0);
    model.quantity = model.quantity === 0 ? 1 : model.quantity;
    if (model.weightPerPiece != undefined && model.weightPerPiece != 0)
      model.grossWt = model.quantity * model.weightPerPiece;
    else
      model.grossWt = ((model.packetWt ?? 0) > 0) ? (Number(model.packetWt ?? 0) - Number(model.packetLessWt ?? 0)) : model.grossWt ?? 0;
    model.netWt = Number(model.grossWt ?? 0) - Number(model.lessWt ?? 0);
    model.purityWt = Number(model.netWt ?? 0) * (Number(model.purityPer == null || model.purityPer == 0 ? 1 : model.purityPer));
    model.wastageWt = (Number(model.netWt ?? 0) * (Number(model.wastagePer ?? 0))) / 100;
    model.requestedWt = (model.requestedPurityPer != undefined && Number(model.requestedPurityPer ?? 0) != 0) ? Number(model.purityWt ?? 0) / Number(model.requestedPurityPer ?? 1) : 0;
    model.alloyWt = Number(model.requestedWt ?? 0) > 0 ? (Number(model.requestedWt ?? 0) - Number(model.netWt ?? 0)) : 0;
    model.finalWt = (model.purityWt ?? 0) + Number(model.wastageWt ?? 0);
    model.rate = Number(model.ratePer) > 0 ? (Number(model.rate ?? 0) / (Number(model.ratePer) / 100)) : model.rate ?? 0;
    model = this.metalSectionService.calculateRate(model, this.data.formModel?.invoiceModel?.gozUsdRate, MetalType.Gold);
    model.rate = this.textProcessingService.fixWeightDecimal(model.rate);
    if (this.data.formName == "Stock Journal Option - 1" && this.data?.formModel?.goldRate != undefined && calculateByField != "rate")
      model.rate = this.data.formModel.goldRate;
    if (this.data.formName == "Stock Journal Option - 1" && this.data?.formModel?.goldMakingRate != undefined) {
      if (calculateByField != "makingRate")
        model.makingRate = this.data.formModel.goldMakingRate;
      if (calculateByField != "makingTypeId")
        model.makingTypeId = this.data.formModel.goldMakingTypeId;
    }

    model.metalValue = (this.data.formModel?.invoiceModel?.calculateAmountByFormulaId == 1 ? (model.grossWt ?? 0) : (model.finalWt ?? 0)) * (model.rate ?? 0); // TODO with codition 
    const applyMakingDiscount = true;
    const applyMakingOn: string = "Net";

    let caratRate: number = 0;
    let disc: number = 0;

    const applyMakingOnAmount: number = Number((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PP) ? Number(model.quantity) : Number(applyMakingOn === "Net" ? (model.netWt ?? 0) : (applyMakingOn === "Gross" ? (model.grossWt ?? 0) : (applyMakingOn === "Final" ? (model.finalWt ?? 0) : 0))));
    disc = Number((applyMakingDiscount && model.makingDiscount !== 0) ? ((applyMakingOnAmount * ((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PER_Percent) ? Number(model.rate ?? 0) : 1) * Number(model.makingRate ?? 0)) / 100) * (Number(model.makingDiscount ?? 0) / 100) / 100 : 0);

    if ((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PG) || (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PP) || (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PKg))
      model.makingAmount = ((applyMakingOnAmount * Number(model.makingRate ?? 0)) - disc) / ((Number(model.makingTypeId) === MakingChargeType.PKg) ? 1000 : 1);
    if (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PER_Percent)
      model.makingAmount = ((applyMakingOnAmount * Number(model.rate) * Number(model.makingRate ?? 0)) / 100) - disc;
    if (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.MKT)
      model.makingAmount = ((applyMakingOnAmount * caratRate) / 100 * Number(model.makingRate ?? 0)) - disc;
    if (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.FIX)
      model.makingAmount = Number(model.makingAmount ?? 0) - disc;
    model.hallmarkAmount = Number(model.quantity ?? 1) * Number(model.hallmarkRate ?? 0);

    model.amount = this.data.formModel?.invoiceModel?.metalFix ? 0 : ((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.MRP) ? Number(model.rate ?? 0) : model.metalValue);
    model.discountAmount = model.discountPer != undefined ? Number(model.amount ?? 0) * Number(model.discountPer ?? 0) : 0;
    model.netAmount = Number(model.makingAmount ?? 0) + (Number(model.amount ?? 0)) - Number(model.discountAmount ?? 0) + Number(model.diamondAmount ?? 0);
    model.makingDiscount = disc;
    this.taxCalculation(model, metalid, isBarcodeScan, fromReverse, calculateTax);
  }

  reversecalculateGoldSilverItem(model, calculateByField, metalid) {
    if (model.reverseCalculationAmount != 0 && model.reverseCalculationAmount != "" && model.reverseCalculationAmount != undefined) {
      var rate = 0;
      var mktAmt = 0;
      var netAmount = 0;
      var makingAmount = 0;
      if (parseFloat(model.taxRate) - parseFloat(model.taxRateExceptMaking) == 0) {
        netAmount = Number((Number(model.reverseCalculationAmount) * 100) / (100 + (parseFloat(model.taxRateExceptMaking))));
        makingAmount = netAmount - Number(model.amount)
      }
      else {
        var amountTax = Number(model.amount ?? 0) * (parseFloat(model.taxRateExceptMaking) / 100);
        var makingAmountWithTax = Number(model.reverseCalculationAmount) - model.amount - amountTax;
        var makingTax = 0;
        makingAmount = Number((Number(makingAmountWithTax) * 100) / (100 + (parseFloat(model.taxRate) - parseFloat(model.taxRateExceptMaking)))) - makingTax;
      }
      var makingRate = 0;

      if (model.grossWt != 0) {
        const applyMakingOn: string = "Net";
        const applyMakingOnWt: number = Number((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PP) ? Number(model.quantity) : Number(applyMakingOn === "Net" ? (model.netWt ?? 0) : (applyMakingOn === "Gross" ? (model.grossWt ?? 0) : (applyMakingOn === "Final" ? (model.finalWt ?? 0) : 0))));
        rate = (model.makingTypeId != null && model.makingTypeId != MakingChargeType.MRP) ? ((netAmount + model.discountAmount - model.makingAmount - model.diamondAmount) / model.finalWt) : (Number(model.netAmount));
        if ((model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PG) || (model.makingTypeId !== null && model.makingTypeId === MakingChargeType.PKg))
          makingRate = makingAmount / applyMakingOnWt;
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PP)
          makingRate = makingAmount / Number(model.quantity ?? 1);
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PER_Percent)
          makingAmount = (((netAmount - Number(model.amount) + Number(model.discountAmount) - Number(model.diamondAmount)) * 100) / (Number(model.rate) * applyMakingOnWt));
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.MKT)
          makingAmount = (((netAmount - Number(model.amount) + Number(model.discountAmount) - Number(model.diamondAmount)) * 100) / mktAmt);
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.FIX)
          makingRate = 0;
      }
      model.makingAmount = makingAmount;
      model.makingRate = makingRate;
      this.calculateGoldSilverItem(model, calculateByField, metalid, false, true);
    }
  }

  async calculateImitationItem(model, isBarcodeScan = false, fromReverse = 0) {
    model.packetWt = Number(model.packetWt ?? 0);
    model.packetLessWt = Number(model.packetLessWt ?? 0);
    model.grossWt = Number(model.grossWt ?? 0);
    model.amount = Number(model.amount ?? 0);
    model.hallmarkRate = Number(model.hallmarkRate ?? 0);
    model.netAmount = Number(model.quantity ?? 0) * Number(model.saleAmount ?? 0);
    model.discountAmount = (Number(model.netAmount ?? 0) * Number(model.discountPer ?? 0)) / 100;
    model.grossWt = (Number(model.packetWt ?? 0) > 0) ? (Number(model.packetWt ?? 0) - Number(model.packetLessWt ?? 0)) : Number(model.grossWt ?? 0);
    this.taxCalculation(model, MetalType.Imitation, isBarcodeScan);
  }

  async calculateDiamondItem(model, calculateByField, isBarcodeScan = false, addOtherRowsAmount: number = 0) {
    if (this.data.formName == "Stock Journal Option - 1" && this.data?.formModel?.diamondGoldRate != undefined && calculateByField != "goldRate")
      model.goldRate = this.data.formModel.diamondGoldRate;
    if (this.data.formName == "Stock Journal Option - 1" && this.data?.formModel?.diamondRate != undefined && calculateByField != "rate")
      model.rate = this.data.formModel.diamondRate;
    model = this.metalSectionService.calculateRate(model, this.data.formModel?.invoiceModel?.gozUsdRate, MetalType.Diamond);
    if (model.calculationType == CalculationType.Fix)
      model.goldRate = 0;
    if (this.data.formName == "Stock Journal Option - 1" && this.data?.formModel?.diamondMakingRate != undefined) {
      if (calculateByField != "makingRate")
        model.makingRate = this.data.formModel.diamondMakingRate;
      if (calculateByField != "makingTypeId")
        model.makingTypeId = this.data.formModel.diamondMakingTypeId;
    }

    const rowsdata = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
    model = this.metalSectionService.calculateDiamondItem(model, calculateByField, addOtherRowsAmount, rowsdata);
    if (model.diamondCategoryId != DiamondCategory.Diamonds && model.diamondCategoryId != DiamondCategory.GemStones && model.diamondCategoryId != undefined) {
      switch (calculateByField) {
        case "carat":
        case "grossWt":
        case "weight":
          model.goldWt = Number(model.grossWt ?? 0) - Number(model.weight ?? 0);
          break;
      }
      model.goldPurityWt = Number(model.goldWt ?? 0) * (Number(model.goldPurityPer == null || model.goldPurityPer == 0 ? 1 : model.goldPurityPer));
      model.goldAmount = Number(model.grossWt ?? 0) * Number(model.goldRate ?? 0);
    }
    model.goldAmount = (this.data.formModel != undefined && this.data.formModel?.invoiceModel?.metalFix) ? 0 : model.goldAmount;
    const applyMakingOnAmount: number = (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PP) ? Number(model.quantity ?? 1) : ((model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PG) ? Number(model.grossWt ?? 0) : 0);
    if ((model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PG) || (model.makingTypeId !== null && model.makingTypeId === MakingChargeType.PP) || (model.makingTypeId !== null && model.makingTypeId === MakingChargeType.PKg))
      model.makingAmount = (applyMakingOnAmount * Number(model.makingRate ?? 0)) / ((model.makingTypeId === MakingChargeType.PKg) ? 1000 : 1);
    if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PER_Percent)
      model.makingAmount = (applyMakingOnAmount * Number(model.rate ?? 0) * Number(model.makingRate ?? 0)) / 100;
    if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.MKT)
      model.makingAmount = (applyMakingOnAmount * Number(model.rate ?? 0)) / 100 * Number(model.makingRate ?? 0);
    if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.FIX)
      model.makingAmount = Number(model.makingAmount ?? 0);
    model.hallmarkAmount = Number(model.quantity ?? 1) * Number(model.hallmarkRate ?? 0);
    var preNetAmount = Number(model.amount ?? 0) + Number(model.goldLossValue ?? 0) + Number(model.settingChargeAmount ?? 0) + Number(model.markUpAmount ?? 0) + Number(model.certificateAmount ?? 0) + (this.data.formModel.invoiceModel.metalFix == true ? 0 : Number(model.goldAmount ?? 0)) + Number(model.makingAmount ?? 0) + Number(model.otherAmount ?? 0);
    model.discountAmount = (Number(preNetAmount ?? 0) * Number(model.discountPer ?? 0));
    model.netAmount = preNetAmount - Number(model.discountAmount ?? 0);
    model.purchaseAmount = model.netAmount;
    this.taxCalculation(model, MetalType.Diamond, isBarcodeScan);
    if (model.diamondCategoryId != DiamondCategory.Jewellery.toString() && rowsdata.length > 1) {
      const rowsdata = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
      const firstRowNode = rowsdata[0];
      var exceptJewelleryRow = rowsdata?.filter(row => Number(row?.diamondCategoryId) !== Number(DiamondCategory.Jewellery));
      const sumOfAmount = (exceptJewelleryRow?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfCarat = (exceptJewelleryRow?.map(a => Number(a?.carat))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfWeight = (exceptJewelleryRow?.map(a => Number(a?.weight))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfRate = (exceptJewelleryRow?.map(a => Number(a?.rate))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      firstRowNode.weight = this.textProcessingService.fixWeightDecimal(sumOfWeight);
      firstRowNode.carat = this.textProcessingService.fixWeightDecimal(sumOfCarat);
      firstRowNode.colorCode = "pink";
      this.calculateDiamondItem(firstRowNode, "carat", false, sumOfAmount);
      this.gridApiDiamondTable?.api?.refreshCells();
    }
  }

  reverseCalculateDiamondItem(model) {
    if (model.reverseCalculationAmount != 0 && model.reverseCalculationAmount != "" && model.reverseCalculationAmount != undefined) {
      var withoutTax = Number((Number(model.reverseCalculationAmount) * 100) / (100 + Number(model.taxRate)));
      var reverseAmount = withoutTax - Number(model.certificateAmount ?? 0) - Number(model.goldAmount ?? 0) - Number(model.makingAmount ?? 0) - Number(model.otherAmount ?? 0);
      var rate = reverseAmount / Number(model.carat);
      if (model.calculationType == CalculationType.Fix)
        rate = reverseAmount;
      model.rate = rate;
      this.calculateDiamondItem(model, "reverseCalculationAmount");
    }
  }

  onItemListRowClickedResponse(event, IsBarcodeScan = false, parentMetalId = undefined, result?: any) {
    var records: any;
    if (event != undefined)
      records = JSON.parse(event);

    if (IsBarcodeScan) {
      if (this.data?.metalSectionModel.voucherWiseData != undefined) {
        this.agGridService.cameraButtonDisabled = true;
        records.diamondItem?.forEach(item => {
          this.assignRateGoldCaratWise(item);
          this.callCalulationApi(item, IsBarcodeScan, "carat", 4);
        });
        records.goldItem?.forEach(item => {
          this.callCalulationApi(item, IsBarcodeScan, "grosswt", 1);
        });
        records.imitationItem?.forEach(item => {
          this.callCalulationApi(item, IsBarcodeScan, "", 6);
        });
        // in checking
      }
    }
    this.data.metalSectionModel.comment = result?.comment;
    this.data.metalSectionModel.groupName = result?.groupName;
    this.setTabItemColors(records);
    this.gridApiGoldTable?.api?.setRowData(records.goldItem);
    this.gridApiSilverTable?.api?.setRowData(records.silverItem);
    this.gridApiDiamondTable?.api?.setRowData(records.diamondItem);
    this.gridApiImmitationAndWatchesTable?.api?.setRowData(records.imitationItem);
    const rowsdata = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
    if (rowsdata?.length > 1) {
      const firstRowNode = rowsdata[0];
      var exceptJewelleryRow = rowsdata?.filter(row => Number(row?.diamondCategoryId) !== Number(DiamondCategory.Jewellery));
      const sumOfAmount = (exceptJewelleryRow?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfCarat = (exceptJewelleryRow?.map(a => Number(a?.carat))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      if (firstRowNode)
        firstRowNode.carat = this.textProcessingService.fixWeightDecimal(sumOfCarat);
      this.calculateDiamondItem(firstRowNode, 'carat', false, sumOfAmount);
    }

    this.uploadImagesList = records.invoiceItemImageResources ?? [];
    if (parentMetalId != undefined)
      records.parentMetalId = parentMetalId;
    if (records.parentMetalId != 0 && records.parentMetalId != null)
      this.selectNavTab(records.parentMetalId);
    else
      this.selectTabAccordingBarcode(records);
  }

  setTabItemColors(records) {
    if (records) {
      this.tabItems = this.tabItems.map(item => {
        if ((item.id === 1 && records.goldItem?.length > 0) ||
          (item.id === 2 && records.silverItem?.length > 0) ||
          (item.id === 4 && records.diamondItem?.length > 0) ||
          (item.id === 5 && records.imitationItem?.length > 0)
        ) {
          return { ...item, color: "lightgray" };
        } else return item;
      });
    }
  }

  addMultipleItems(element) {
    element.forEach(event => {
      if (event != undefined) {
        const records = JSON.parse(event.detailedJson);
        records.goldItem?.forEach(item => {
          this.data?.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.silverItem?.forEach(item => {
          this.data?.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.diamondItem?.forEach(item => {
          this.data?.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.imitationItem?.forEach(item => {
          this.data?.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        this.agGridService.addNewRowsWithData(this.gridApiGoldTable, records.goldItem);
        this.agGridService.addNewRowsWithData(this.gridApiSilverTable, records.silverItem);
        this.agGridService.addNewRowsWithData(this.gridApiDiamondTable, records.diamondItem);
        this.agGridService.addNewRowsWithData(this.gridApiImmitationAndWatchesTable, records.imitationItem);
      }
    });
  }

  addProductsInItemList() {
    this.allMetalModel.isMelting = this.data.formModel.invoiceModel.isMelting;
    this.allMetalModel.parentMetalId = this.data?.metalSectionModel.parentMetalId;
    this.allMetalModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId)?.map(item => ({ ...item, isNew: item.added }));
    this.allMetalModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId)?.map(item => ({ ...item, isNew: item.added }));
    this.allMetalModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId)?.map(item => ({ ...item, isNew: item.added }));
    this.allMetalModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId)?.map(item => ({ ...item, isNew: item.added }));
    this.allMetalModel.itemList = this.data.formModel.itemList;//this.gridApiForSalesInvoiceItemListTable                  ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.allMetalModel.invoiceTaxDetail = this.data.formModel.invoiceTaxDetail;//this.gridApiForSalesInvoiceTaxTable                       ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.allMetalModel.VoucherTypeId = this.data.formModel.invoiceModel.voucherTypeId;
    this.allMetalModel.invoiceItemImageResources = this.uploadImagesList;
    this.allMetalModel.comment = this.data?.metalSectionModel.comment;
    this.allMetalModel.groupName = this.data?.metalSectionModel.groupName;
    this.allMetalModel.isGroup = this.allMetalModel.groupName === "" ? false : true;
    this.transactionService.getInvoiceItemWithDescription(this.allMetalModel,)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if (this.data?.formModel?.isUpdateOldItemsForStockJournal == true) {
          this.transactionService.updateStockJournalItem(this.data.formModel, res.response).subscribe({
            next: (updateStockJournalItemResponse) => {
              this.addProductInItemListResponseProcess(res,updateStockJournalItemResponse);
              this.clearAllMetals();
            }, error: (err) => { }
          })
        }
        else
            this.addProductInItemListResponseProcess(res, undefined);
      });

    //on add button reset scoll position in all grid
    const gridApiList = [this.gridApiGoldTable, this.gridApiDiamondTable, this.gridApiSilverTable, this.gridApiImmitationAndWatchesTable,];
    for (let item of gridApiList)
      this.getColumnState(item);
  }

  addProductInItemListResponseProcess(res,stockJournalItemResponse) {
    this.data.formModel.itemTaxDetails = res.response.invoiceTaxDetail;
    this.data.formModel.invoiceModel.groupName = "";
    const invoiceDocumentResources = [...(this.data.formModel.invoiceDocumentResources || []), ...(this.invoiceDocumentDesignNo || [])];
    this.data.formModel.invoiceDocumentResources = invoiceDocumentResources;
    if(stockJournalItemResponse != undefined)
      this.storageService.onAddProductsInItemList.next(stockJournalItemResponse);
    else
      this.storageService.onAddProductsInItemList.next(res);
    if (this.data.formModel.invoiceModel.voucherTypeId !== VoucherType.PhysicalStock && this.data.formModel.invoiceModel.voucherTypeId !== VoucherType.StockJournal)
      this.clearAllMetals();
    res.isSuccess == true ? this.agGridService.cameraButtonDisabled = false : true;
    this.uploadImagesList = [];
    this.isImageSaved = false;
    this.onClear();
  }

  resetTabs() {
    this.navTabsComponent.resetTabColor();
  }

  getColumnState(gridApi) {
    if (gridApi) {
      const columnState = gridApi?.columnApi?.getColumnState();
      if (columnState && columnState?.length > 0) {
        const visibleColumnList = columnState.filter((x) => (x.hide != true) && (x.pinned != 'left'));
        const firstColumnState = visibleColumnList[0];
        const firstColumnId = firstColumnState?.colId;
        gridApi?.api?.ensureColumnVisible(firstColumnId);
      }
    }
  }

  stateWiseTaxList(data) {
    var applicableOnProducts = this.applicableOnProducts?.filter(a => a.extra2 == data[0].extra1)
    //  this.onTaxChangeResponse(applicableOnProducts);
  }

  resetInvoiceResponse(formName) {
    if (formName != undefined) {
      this.clearAllMetals();
      var previousVoucherType = this.data.formModel?.voucherType;
      var previousInvoiceModel = this.data.formModel?.invoiceModel;
      this.data.formModel = {};
      this.data.formModel.invoiceModel = {};
      this.data.formModel.invoiceModel.internalFix = previousInvoiceModel?.internalFix;
      this.data.formModel.invoiceModel.metalFix = previousInvoiceModel?.internalFix;
      this.data.formModel.invoiceModel.voucherTypeId = previousInvoiceModel?.voucherTypeId;
      this.data.formModel.invoiceModel.id = 0;
      this.data.formModel.voucherType = previousVoucherType;
    }
  }

  //#region barcode 
  getProductDataByBarcodeNo(barcodeNo, formName) {
    setTimeout(() => {
      this.fillPreviousRow();

      if (formName == undefined)
        formName = this.data.formModel.voucherType;
      if (barcodeNo) {
        this.transactionService.getPrductByBarcodeNo(barcodeNo, formName, this.data?.metalSectionModel?.voucherWiseData?.applicableOnProductsByCustomer, this.data?.formModel?.invoiceModel?.metalFix, this.data?.formModel?.invoiceModel?.internalFix).subscribe({
          next: (res) => {
            if (res?.response?.detailedJson != '{"goldItem":[],"silverItem":[],"diamondItem":[],"stoneGemsItem":[],"imitationItem":[]}') {
              this.onItemListRowClickedResponse(res?.response?.detailedJson, true);
            }
            const records = JSON.parse(res?.response?.detailedJson);
            this.selectTabAccordingBarcode(records)
          }, error: (err) => { }
        })
      }
      const navTabElement = document.querySelector('.nav-tabs') as any;
      navTabElement?.firstElementChild?.firstElementChild?.focus();
    }, 1000);
  }


  getitemdetailbydesignno(designNo) {
    if (designNo !== '' && designNo !== '0') {
      setTimeout(() => {
        // this.fillPreviousRow();
        this.transactionService.getitemdetailbydesignno({ designNo: designNo }).subscribe({
          next: (res) => {
            if (res?.isSuccess) {
              this.invoiceDocumentDesignNo = res?.response?.invoiceDocumentResources.map((element) => {
                element.designNo = res?.response?.itemList[0]?.designNo;
                return element;
              })
              this.invoiceDocumentResources = [...this.invoiceDocumentResources, ...this.invoiceDocumentDesignNo]
              const emptyStructure = '{"goldItem":[],"silverItem":[],"diamondItem":[],"stoneGemsItem":[],"imitationItem":[]}';
              res?.response?.itemList?.forEach((item) => {
                const detailedJson = item?.detailedJson;
                if (detailedJson && detailedJson !== emptyStructure) {
                  this.onItemListRowClickedResponse(detailedJson, true);

                  try {
                    const records = JSON.parse(detailedJson);
                    this.selectTabAccordingBarcode(records);
                  } catch (error) {
                    console.error('Error parsing detailedJson:', error, { detailedJson });
                  }
                }
              });
            } else {
              this.clearAllMetals();
            }

          }, error: (err) => {
            this.clearAllMetals();
          }
        })
        const navTabElement = document.querySelector('.nav-tabs') as any;
        navTabElement?.firstElementChild?.firstElementChild?.focus();
      }, 1000);
    }
  }

  clearBarcode() {
    this.data.metalSectionModel.barcodeNo = '';
  }

  selectTabAccordingBarcode(records) {
    switch (true) {
      case records.goldItem != undefined && records.goldItem.length > 0: this.selectNavTab(1); break;
      case records.silverItem != undefined && records.silverItem.length > 0: this.selectNavTab(2); break;
      case records.diamondItem != undefined && records.diamondItem.length > 0: this.selectNavTab(4); break;
      case records.imitationItem != undefined && records.imitationItem.length > 0: this.selectNavTab(6); break;
      default: this.selectNavTab(1); break;
    }
  }
  selectNavTab(tab, disableOtherTab = false) {
    if (this.navTabsComponent == undefined)
      this.selectTab(tab);
    else
      this.navTabsComponent.selectTab(tab, disableOtherTab)
    this.selectTab(tab);
  }
  //#endregion

  handleError(arg0: any): any { }

  getAllMetals(): any {
    this.allMetalModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.allMetalModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.allMetalModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.allMetalModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    return this.allMetalModel;
  }

  getAllSelectedMetals(): any {
    this.allMetalModel.goldItem = this.gridApiGoldTable?.api?.getSelectedRows();
    this.allMetalModel.silverItem = this.gridApiSilverTable?.api?.getSelectedRows();
    this.allMetalModel.diamondItem = this.gridApiDiamondTable?.api?.getSelectedRows();
    this.allMetalModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getSelectedRows();
    return this.allMetalModel;
  }

  getAllActiveMetals(): any {
    this.allMetalModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    this.allMetalModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    this.allMetalModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    this.allMetalModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    return this.allMetalModel;
  }

  getAllMetalWithRowId(rowId): any {
    this.allMetalModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    this.allMetalModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    this.allMetalModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    this.allMetalModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    return this.allMetalModel;
  }

  getAllGrid(): any {
    this.gridApi.gridApiGoldTable = this.gridApiGoldTable
    this.gridApi.gridApiSilverTable = this.gridApiSilverTable
    this.gridApi.gridApiDiamondTable = this.gridApiDiamondTable
    this.gridApi.gridApiImmitationAndWatchesTable = this.gridApiImmitationAndWatchesTable
    return this.gridApi;
  }

  setAllMetals(records) {
    this.gridApiGoldTable?.api?.setRowData(records.goldItem);
    this.gridApiSilverTable?.api?.setRowData(records.silverItem);
    this.gridApiDiamondTable?.api?.setRowData(records.diamondItem);
    this.gridApiImmitationAndWatchesTable?.api?.setRowData(records.imitationItem);
    this.selectTabAccordingBarcode(records)
  }

  setColumnsVisible() {
    var columnnames = this.data?.metalSectionModel.columnNamesToHideOrShow;
    var visible = this.data?.metalSectionModel.columnOperationToHideOrShow;
    columnnames = columnnames == undefined ? ["requestedWt", "requestedPurityPer", "alloyWt"] : columnnames
    columnnames.forEach(element => {
      if (this.gridApiGoldTable != undefined)
        this.gridApiGoldTable.columnApi.setColumnVisible(element, visible);
      if (this.gridApiSilverTable != undefined)
        this.gridApiSilverTable.columnApi.setColumnVisible(element, visible);
      if (this.gridApiDiamondTable != undefined)
        this.gridApiDiamondTable.columnApi.setColumnVisible(element, visible);
      if (this.gridApiImmitationAndWatchesTable != undefined)
        this.gridApiImmitationAndWatchesTable.columnApi.setColumnVisible(element, visible);
    });
    if (visible == false)
      this.storageService.hideColumnsPermanent.next({ columnnames: columnnames, storageName: this.gridApi.storageName?.toString() })
    else
      this.storageService.hideColumnsPermanent.next([]);
  }


  addAllMetals(records, rowId, selectedTab = undefined) {
    const goldItem = records?.goldItem?.map((item, index) => ({ ...item, rowId: rowId }));
    const silverItem = records?.silverItem?.map((item, index) => ({ ...item, rowId: rowId }));
    const diamondItem = records?.diamondItem?.map((item, index) => ({ ...item, rowId: rowId }));
    const imitationItem = records?.imitationItem?.map((item, index) => ({ ...item, rowId: rowId }));
    this.agGridService?.addNewRowsWithData(this.gridApiGoldTable, goldItem);
    this.agGridService?.addNewRowsWithData(this.gridApiSilverTable, silverItem);
    this.agGridService?.addNewRowsWithData(this.gridApiDiamondTable, diamondItem);
    this.agGridService?.addNewRowsWithData(this.gridApiImmitationAndWatchesTable, imitationItem);
    if (selectedTab != undefined)
      this.selectTabAccordingBarcode(records)
  }

  maxRowId(): any {
    var arrayOfIndividualMax = [];
    var data = this.getAllMetals();
    var indexFromGold = data.goldItem?.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdGold = (indexFromGold?.length ?? 0) == 0 ? 0 : Math.max(...indexFromGold);
    arrayOfIndividualMax.push(maxRowIdGold);
    var indexFromSilver = data.silverItem?.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdSilver = (indexFromSilver?.length ?? 0) == 0 ? 0 : Math.max(...indexFromSilver);
    arrayOfIndividualMax.push(maxRowIdSilver);
    var indexFromDiamond = data.diamondItem?.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdDiamond = (indexFromDiamond?.length ?? 0) == 0 ? 0 : Math.max(...indexFromDiamond);
    arrayOfIndividualMax.push(maxRowIdDiamond);
    var indexFromImitation = data.imitationItem?.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdImitation = (indexFromImitation?.length ?? 0) == 0 ? 0 : Math.max(...indexFromImitation);
    arrayOfIndividualMax.push(maxRowIdImitation);
    const rowIndex = Math.max(...arrayOfIndividualMax);
    return (rowIndex ?? 0) + 1;
  }

  clearAllMetals() {
    this.gridApiGoldTable?.api?.setRowData([]);
    this.gridApiSilverTable?.api?.setRowData([]);
    this.gridApiDiamondTable?.api?.setRowData([]);
    this.gridApiImmitationAndWatchesTable?.api?.setRowData([]);
    // this.data.formModel.invoiceModel.groupName = "";
  }

  onAddButtonPress(event: KeyboardEvent, nextElement: string) {
    if (event.key == 'Tab') {
      event.preventDefault();
      this.storageService.onAddButtonKeyPressed.next(event);
    }
    else if (event.key === 'Enter') {
      event.preventDefault();
      let element = document.getElementById(nextElement) as HTMLInputElement;
      // const element = this.renderer?.selectRootElement(nextInput);
      if (!element?.classList?.contains('pointer_none')) {
        element?.focus();
        element?.click();
      }
    }
  }

  setFooterCalcAndDisplay() {
    if (this.data?.metalSectionModel.isGridFooter == true) {
      this.addFooterAndCalculation(this.gridApiGoldTable);
      this.addFooterAndCalculation(this.gridApiSilverTable);
      this.addFooterAndCalculation(this.gridApiDiamondTable);
      this.addFooterAndCalculation(this.gridApiImmitationAndWatchesTable);
    }
  }

  addFooterAndCalculation(params) {
    if (this.data?.metalSectionModel?.isGridFooter) {
      const rowsdata = params?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
      const sumOfQuantity = (rowsdata?.map(a => Number(a?.quantity))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfGrossWt = (rowsdata?.map(a => Number(a?.grossWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
      const sumOfNetWt: string = (rowsdata?.map(a => Number(a?.netWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
      const sumOfFinalWt: string = (rowsdata?.map(a => Number(a?.finalWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
      const sumOfAmount: string = (rowsdata?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);
      const sumOfNetAmount: string = (rowsdata?.map(a => Number(a?.netAmount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(3);

      const totalsObject = {
        quantity: sumOfQuantity,
        grossWt: sumOfGrossWt,
        netWt: sumOfNetWt,
        finalWt: sumOfFinalWt,
        amount: sumOfAmount,
        netAmount: sumOfNetAmount,
        isFooterRow: true
      }
      params?.api?.setPinnedBottomRowData([totalsObject]);
      params?.api?.refreshCells();
    }

  }

  ngOnDestroy(): void {
    this.weightMachineService?.stopListening();
    this.uploadImagesList = [];
    this.onItemListRowClickedSubscription.unsubscribe();
    this.resetInvoiceSubscription.unsubscribe();
    if (this.onAddRowButtonSubscription != undefined)
      this.onAddRowButtonSubscription.unsubscribe();
    this.onNavTabKeyPressedSubscription.unsubscribe();
    this.barcodeScanValueSourceSubscription?.unsubscribe();

  }

  internalFixChecked(value) {
    this.data.formModel.invoiceModel.internalFix = value;
  }
  metalFixChecked(value) {
    this.data.formModel.invoiceModel.metalFix = value;
    this.metalFixUnfixCalc();
  }
  metalFixUnfixCalc() {
    this.selectAllRows();
    var allNodesGold = this.gridApiGoldTable?.api?.getSelectedNodes();
    allNodesGold.forEach(element => { this.calculateGoldSilverItem(element.data, "rate", MetalType.Gold); });
    var allNodesSilver = this.gridApiSilverTable?.api?.getSelectedNodes();
    allNodesSilver.forEach(element => { this.calculateGoldSilverItem(element.data, "rate", MetalType.Silver); });
    this.refreshAllCells(true);
    this.deselectAllRows();
  }

  KYCChecked(value) {
    this.data.formModel.invoiceModel.isKYC = value;
  }

  AMLChecked(value) {
    this.data.formModel.invoiceModel.isAML = value;
  }

  itemPresentInMetalList(): boolean {
    var getSaleInvoiceItemsDetailsModel: any = {};
    getSaleInvoiceItemsDetailsModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);

    if (getSaleInvoiceItemsDetailsModel.goldItem?.length > 0
      || getSaleInvoiceItemsDetailsModel.silverItem?.length > 0
      || getSaleInvoiceItemsDetailsModel.diamondItem?.length > 0
      || getSaleInvoiceItemsDetailsModel.imitationItem?.length > 0
    ) return true;
    else return false;
  }

  getSelectedTab(): any { return this.tabId; }

  setSelectedTab(TabId): any { this.selectNavTab(TabId); }

  deselectAllRows() {
    this.gridApiGoldTable?.api?.deselectAll();
    this.gridApiSilverTable?.api?.deselectAll();
    this.gridApiDiamondTable?.api?.deselectAll();
    this.gridApiImmitationAndWatchesTable?.api?.deselectAll();
  }

  selectAllRows() {
    this.gridApiGoldTable?.api?.selectAll();
    this.gridApiSilverTable?.api?.selectAll();
    this.gridApiDiamondTable?.api?.selectAll();
    this.gridApiImmitationAndWatchesTable?.api?.selectAll();
  }

  setColumnVisible(columnName, visible) {
    this.gridApiGoldTable?.columnApi?.setColumnVisible(columnName, visible);
    this.gridApiSilverTable?.columnApi?.setColumnVisible(columnName, visible);
    this.gridApiDiamondTable?.columnApi?.setColumnVisible(columnName, visible);
    this.gridApiImmitationAndWatchesTable?.columnApi?.setColumnVisible(columnName, visible);
  }

  //#region upload items images start
  // async onSelect(event) {
  //   if (event.addedFiles.length > 1)
  //     await this.resourceMultipleFile(event.addedFiles);
  //   else
  //     await this.resourceFile(event.addedFiles[0]);
  // }

  // async resourceFile(item: any) {
  //   await this.processFile(item, true);
  // }

  // async resourceMultipleFile(items: any) {
  //   for (let index = 0; index < items.length; index++) {
  //     const isLastFile = index === items.length - 1;
  //     await this.processFile(items[index], isLastFile);
  //   }
  // }

  // async processFile(file: any, isLastFile: boolean) {
  //   
  //   try {
  //     const compressedFile = await this.compressImageService.compress(file).toPromise();
  //     const base64String = await this.readThis(compressedFile);
  //     if (base64String) {
  //       
  //       const img = { base64Resource: base64String };
  //       this.uploadImagesList.push(img);
  //       if (isLastFile)
  //         this.openAddImagePopup();
  //     } else console.error("Failed to get base64 string.");
  //   } catch (error) { console.error("Error processing file:", error); }
  // }
  //#endregion



  openAddImagePopup(img?: any) {
    this.notificationService.openAddImagePopup(this.uploadImagesList).then(
      (selectedImages: any) => {
        if (selectedImages === false) return;
        if (selectedImages?.length) {
          this.uploadImagesList = [...selectedImages];
          this.isImageSaved = true;
        } else if (selectedImages && this.uploadImagesList && this.isImageSaved) {
          this.uploadImagesList = [...selectedImages];
        } else if (selectedImages) { } else {
          this.uploadImagesList = [];
        }
      }
    )
  }

  //#region upload items images end


  // uploadImages(event) {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     
  //     this.openAddImagePopup(reader.result.toString());
  //   }
  // }

  checkAnyGridHasRow() {
    const gridAPIs = [
      this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay,
      this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay,
      this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay,
      this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay
    ];

    return gridAPIs.some(rows => rows && rows.length > 0);
  }

  checkAnyGridHasRowWithTabs(): boolean {
    const gridApis = {
      1: this.gridApiGoldTable?.api,
      2: this.gridApiSilverTable?.api,
      4: this.gridApiDiamondTable?.api,
      6: this.gridApiImmitationAndWatchesTable?.api,
    };
    const currentApi = gridApis[this.tabId];
    const rowsToDisplay = currentApi?.getModel()?.rowsToDisplay;
    return rowsToDisplay && rowsToDisplay.length > 0;
  }

  getGridApiOnSelectdTab() {
    const gridApis = {
      1: this.gridApiGoldTable?.columnApi,
      2: this.gridApiSilverTable?.columnApi,
      4: this.gridApiDiamondTable?.columnApi,
      6: this.gridApiImmitationAndWatchesTable?.columnApi,
    };
    const displayedColumns = gridApis[this.tabId]?.getAllDisplayedColumns();;
    const displayedColDefs: ColDef[] = displayedColumns?.map(column => column?.getColDef());
    const headerNames = displayedColDefs?.map(colDef => colDef?.headerName).filter(name => name?.trim() !== '');
    // const fileName:any =  this.tabItems.find(x=>x.id==this.tabId).name;
    let tabNames: any = {
      1: 'Gold',
      2: 'Silver',
      4: 'Diamond',
      5: 'Stone',
      6: 'Imitation',
    }
    const fileName = tabNames[this.tabId] || '';
    return { headerNames, fileName };
  }

  onCommentChange(newComment: string) {
    this.comment = newComment;
    this.commentChange.emit(newComment);
  }

  onGroupNameChange(newGroupName: string) {
    this.groupName = newGroupName;
    this.groupNameChange.emit(newGroupName);
  }


  sendHeaderData() {
    this.sendDataToItemList.emit(this.getGridApiOnSelectdTab())
  }

  purchaseInvoiceNoChange(event) {
    this.data.formModel.invoiceModel.againstVoucherNo = event;
  }

  assignMoreSelectedItems(event, IsBarcodeScan = false, parentMetalId = undefined, result?: any) {
    this.onItemListRowClickedResponse(event, false, parentMetalId, result)
  }

  checkedValue() {

    const allRows = [
      ...this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data) || [],
      ...this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data) || [],
      ...this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data) || [],
      ...this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data) || []
    ];

    const diamondRows = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data) || [];
    const hasDiamondCategoryId = diamondRows?.length > 0 ? diamondRows.find(item => !item?.diamondCategoryId || item?.diamondCategoryId == 0) ? false : true : false;
    const hasProductDetailId = allRows?.length > 0 ? allRows.find(item => !item?.productDetailId || item?.productDetailId == 0) ? false : true : false;
    const hasNetAmountWithTax = this.data?.formModel?.invoiceModel?.doNotAllowZeroAmountProduct ? allRows?.length > 0 && !allRows?.some(item => !item?.netAmountWithTax || item?.netAmountWithTax == 0) : true;

    const result = diamondRows.length > 0
      ? hasDiamondCategoryId && hasProductDetailId && hasNetAmountWithTax
      : hasProductDetailId && hasNetAmountWithTax;;
    this.agGridService.cameraButtonDisabled = result;
    return result;
  }

  addRowEventForCode(code) {
    const productCodeName = code?.trim();
    // if (!productCodeName) return this.toaster.error('Code cannot be empty spaces');
    const productDropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
    const productCodeWiseFilterList = productDropdown?.filter((x) => x?.extra3?.toLowerCase() == productCodeName?.toLowerCase());
    // if (!productCodeWiseFilterList?.length) return this.toaster.error('Product not found');

    if (!productCodeWiseFilterList?.map((x) => x?.extra1)?.includes(this.tabId?.toString())) {
      const productNames = productCodeWiseFilterList?.filter((x) => x?.extra1 != this.tabId).map((y) => y?.name);
      const tabList = productNames?.map(item => item?.split(" - ")?.pop());
      // this.toaster.error(`The product is available in ${tabList?.join(', ')}`,'',{timeOut: 2000});
      return;
    }
    const product = productCodeWiseFilterList?.find((a) => a?.extra3?.toLowerCase() === productCodeName?.toLowerCase() && a?.extra1 == this.tabId);
    const data = { quantity: 1, added: true, edited: false, deleted: false, purityPer: '1.0', makingTypeId: MakingChargeType.FIX, productDetailId: product?.id }
    switch (this.tabId) {
      case 1: this.agGridService.appendNewRow(this.gridApiGoldTable, data); break;
      case 2: this.agGridService.appendNewRow(this.gridApiSilverTable, data); break;
      case 4: this.agGridService.appendNewRow(this.gridApiDiamondTable, data); break;
      case 5: this.agGridService.appendNewRow(this.gridApiImmitationAndWatchesTable, data); break;
    }
  }

  onClear() {
    this.data.metalSectionModel.comment = null;
    this.data.metalSectionModel.groupName = null;
    this.resetdesignNoDropdown()
    this.storageService.onclearDesignNo.next(true);
  }



}