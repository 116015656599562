
<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">{{ data?.modalTitle ? data.modalTitle : 'Barcode' }}</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form autocomplete="off" class="theme-form d-flex flex-fill flex-column justify-content-between" ngNativeValidate #AdditionalAmountForm="ngForm">
    <div class="modal-body px-1 pb-0">
        <div class="row mx-0 gap-2 flex-fill">
            <div class="col p-0">
                <div class="h-100 position-relative">
                    <app-ag-grid 
                        [id]="data.isbarcode ? 'ModalHeight' : 'addDiamond'"
                        [class]="'all_aggrid_tables position-relative d-block'"
                        [dataModel]="agGriBarcodePopupGrid" 
                        [rowData]="agGridLossTrackingRowData"
                        [columnSettings]="true"
                        (rowClicked)="rowClicked($event)"
                        (gridReady)="onGridReady($event)"
                        (onPagination)="onPagination($event)"
                        >
                    </app-ag-grid>
                </div>
            </div>
        </div>
    </div>
</form>