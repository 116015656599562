<div>
    <!-- <ng-container *ngIf="!requestModel?.invoiceDocumentResources?.length">
        <div ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)" class="rounded child_image d-flex align-items-center justify-content-center border-0 h-auto cursorPointer ">
            <ng-container *ngTemplateOutlet="svg"></ng-container>
        </div> 
     </ng-container> -->
     <ng-container>
         <div (click)="openAddImagePopup(requestModel?.invoiceDocumentResources ?? [])" class="d-flex align-items-center">
          <ng-container *ngTemplateOutlet="svg"></ng-container>
         </div>
     </ng-container>
</div>

<ng-template #svg>
  <svg id="notificationIcon"  class="cursorPointer " xmlns="http://www.w3.org/2000/svg" width="35" height="27" viewBox="0 0 40.835 41.17">
    <g id="Group_42927" data-name="Group 42927" transform="translate(-1511.915 -150)">
      <g id="Group_42926" data-name="Group 42926" transform="translate(-250.285 -285.148)">
        <g id="Rectangle_1101" data-name="Rectangle 1101" transform="translate(1762.2 440.318)" fill="#fff" stroke="#4f39cd" stroke-width="1">
          <rect width="35.61" height="36" rx="8" stroke="none"/>
          <rect x="0.5" y="0.5" width="34.61" height="35" rx="7.5" fill="none"/>
        </g>
        <g id="Group_42887" data-name="Group 42887" transform="translate(880.313 445.149)">
          <path id="Path_3628" data-name="Path 3628" d="M902.617,826.121l6.18,6.487c.105.11.21.221.314.332a.9.9,0,0,1,.25.643q-.005,7.518,0,15.035a3.446,3.446,0,0,1-3.047,3.488,3.342,3.342,0,0,1-.456.014h-13.6a3.434,3.434,0,0,1-3.477-3.048,4.178,4.178,0,0,1-.013-.534c0-6.292.017-12.584-.012-18.876a3.516,3.516,0,0,1,2.639-3.5c.022-.005.041-.028.061-.043Zm-.927,1.216h-9.356a2.173,2.173,0,0,0-2.309,2.327q0,9.425,0,18.851a2.175,2.175,0,0,0,2.323,2.339h13.423a2.185,2.185,0,0,0,2.322-2.321q0-7.1,0-14.2v-.278h-.346c-1.159,0-2.318.011-3.476-.008a2.7,2.7,0,0,1-2.563-2.519c-.034-1.288-.015-2.578-.02-3.867C901.69,827.567,901.69,827.468,901.69,827.337Zm5.521,5.45-4.22-4.442a.56.56,0,0,0-.031.108c0,.991-.01,1.983.011,2.973a1.42,1.42,0,0,0,1.333,1.354C905.25,832.807,906.2,832.787,907.211,832.787Z" transform="translate(0 -826.121)" fill="#4f39cd"/>
          <path id="Path_3629" data-name="Path 3629" d="M920.959,867.521v.267c0,2.114,0,4.227-.008,6.341a1,1,0,0,1-.176.581.606.606,0,0,1-1.042-.252,1.35,1.35,0,0,1-.04-.375q0-3.145,0-6.29v-.268l-.07-.037c-.054.071-.1.148-.164.214q-1.12,1.21-2.243,2.416a.7.7,0,0,1-.664.282.611.611,0,0,1-.38-1.01c.275-.332.579-.639.873-.954q1.356-1.457,2.714-2.913a.664.664,0,0,1,1.137,0q1.758,1.88,3.51,3.766a.649.649,0,0,1,.029.947.622.622,0,0,1-.94-.08q-1.154-1.23-2.3-2.47a1.451,1.451,0,0,1-.147-.23Z" transform="translate(-21.277 -856.583)" fill="#4f39cd"/>
          <path id="Path_3630" data-name="Path 3630" d="M915.073,918.768q2.563,0,5.125,0a.63.63,0,1,1,.115,1.256,1.718,1.718,0,0,1-.228.007h-10c-.059,0-.119,0-.177,0a.651.651,0,0,1-.612-.652.627.627,0,0,1,.649-.606Q912.51,918.765,915.073,918.768Z" transform="translate(-16.034 -898.363)" fill="#4f39cd"/>
        </g>
      </g>
   <text style="font-weight: bold;" x="1553.25" y="158.5" fill=" #5843CF" font-size="11" text-anchor="middle" alignment-baseline="middle">{{requestModel?.invoiceDocumentResources?.length ? requestModel?.invoiceDocumentResources?.length :''}}</text>
    </g>
  </svg>
</ng-template>