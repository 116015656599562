export class EditProfileModel {
    personalDetails = new PersonalDetailsModel();
    companyDetails  = new CompanyDetailsModel();
    companyAddress  = new CompanyAddressModel();
    bankDetails     = new BankDetailsModel();
    loginDetails    = new LoginDetailsModel();
    planDetails     = new PlanDetailsModel();  
}

export class PersonalDetailsModel
{
    id          : number;
    fullName    : string;
    username    : string;
    contactNo   : any;
    emailId     : string;
    addressId   : string;
    fullAddress : string;
    userProfile : string;
    dialCode    : string;
    isProfileChanged: boolean = false;
}

export class CompanyDetailsModel
{
    id                  : number;
    shopName            : string;
    businessLicenceNo   : string;
    pancard             : string;
    email               : string;
    registrationDate    : string;
    registrationCountry : string;
    officeNo            : string;
    crid                : string;
    installedBy         : number;
    gstTrn              : string;
    companyLogo         : string;
    companyLogoUrl      : string;
    isCompanyLogoChanged: boolean;
    ParentCompanyId     : any;
}

export class CompanyAddressModel
{
    id                  : number;
    addressLine1        : string="";
    addressLine2        : string="";
    stateId             : number;
    distId              : number;
    talukaId            : number;
    talukaName          : string;
    pinCode             : number;
    addressTypeId       : string;
    comment             : string;
    countryName         : string;
    countryId           : number;
}

export class BankDetailsModel
{
    id                 : number;
    bankAccountNumber  : string;
    branchName         : string;
    bankName           : string;
    branchCode         : string;
    ifscCode           : string;
    accountHolderName  : string;
}

export class  LoginDetailsModel 
{
    id                  : number;
    username            : string;
    password?            : string;
    confirmPassword?     : string;
    isPasswordUpdate?    : boolean;
}


export class  PlanDetailsModel 
{

}

export enum EditProfileTab {
    PersonalDetails = 1,   
    companyDetails  = 2,
    BankDetails     = 3,
    LoginDetails    = 4,
    planDetails     = 5,
  }