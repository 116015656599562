
<app-advanced-filter-pop-up  (applyFilter)="onClickAdvancedFilter()" (clearFilter)="clearFields()">
  <form class="theme-form">
      <div class="modal-body mx-2 py-0">
          <div class="row mx-0">
            <div class="col-12 px-0 d-flex align-items-center mb-2 gap-3">
                <div class="col d-flex align-items-center">
                     <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                         Date Range
                     </div>
                     <div class="col">
                         <app-range-datepicker 
                         #rangePicker 
                         [startDate]="advanceFilterRequest.fromDate"  
                         [endDate]="advanceFilterRequest.toDate" 
                         (startDateEmit)="advanceFilterRequest.fromDate = $event;"  
                         (endDateEmit)="advanceFilterRequest.toDate = $event"  >
                     </app-range-datepicker>
                     </div>
                </div>
                <div class="col d-flex align-items-center">
                     <div class="w-100px f-14 font-semibold text-gray-color text-nowrap mb-0">
                         SKU
                     </div>
                     <div class="col">
                     <app-input 
                         [inputName]="'sku'" 
                         [inputType]="'text'" 
                         [inputId]="'sku'"
                         [isRequired]="false"
                         [inputValue]="advanceFilterRequest?.sku" 
                         (inputValueEmit)="advanceFilterRequest.sku = $event"
                         >
                     </app-input>
                 </div>
                </div>
             </div>
              <div class="col-12 px-0 d-flex align-items-center mb-2">
                  <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Branch
                  </div>
                  <div class="col">
                  <app-ng-multi-select-dropdown
                  [isShowlable]="false" 
                  [isPlusIcon]="false" 
                  [name]="'branchId'" 
                  [getAllDropdownModel]="data.getAllDropdownModel?.branchDropdown" 
                  [selectedIds]="selectedBranchIds"
                  [data]="data.getAllDropdownModel?.branchDropdown" 
                  [placeholder]="'Select Branch'"
                  (onSelectItem)="advanceFilterRequest.selectBranchs= $event" 
                  [id]="'branchId'" 
                  >
              </app-ng-multi-select-dropdown>
                  </div>
              </div>
              <div class="col-12 px-0 d-flex align-items-center mb-2">
                  <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Metal
                  </div>
                  <div class="col">
                      <app-ng-multi-select-dropdown
                          [isShowlable]="false" 
                          [isPlusIcon]="false" 
                          [name]="'metalDropdown'" 
                          [getAllDropdownModel]="data.getAllDropdownModel?.metalDropdown" 
                          [data]="data.getAllDropdownModel?.metalDropdown" 
                          [selectedIds]="selectedMetalIds"
                          [placeholder]="'Select Metal'"
                          (onSelectItem)="advanceFilterRequest.selectedMetals = $event;onChangeDepartment($event)" 
                          [id]="'metalId'" 
                          >
                       </app-ng-multi-select-dropdown>
                  </div>
              </div>
              <div class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                    Product
                </div>
                <div class="col">
                        <app-ng-multi-select-dropdown
                        [isShowlable]="false"
                        [isPlusIcon]="false"
                        [name]="'productId'" 
                        [getAllDropdownModel]="data.getAllDropdownModel?.productDropdown"
                        [data]="filteredProductDropdown"
                        [placeholder]="'Select Product'" 
                        [id]="'productId'"
                        [selectedIds]="selectedProductIds"
                        [isRequired]="true" 
                        (onSelectItem)="advanceFilterRequest.selectedProducts=$event" 
                        >
                        </app-ng-multi-select-dropdown>
                    </div>
            </div>
              <div class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                    Title
                </div>
                <div class="col">
                    <app-input 
                        [inputName]="'title'" 
                        [inputType]="'text'" 
                        [inputId]="'title'"
                        [inputValue]="advanceFilterRequest?.title"
                        (inputValueEmit)="advanceFilterRequest.title = $event"
                        [isRequired]="false" 
                        >
                        </app-input>
                </div>
            </div>
              <div class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                  <div class="col d-flex align-items-center">
                      <div class="w-120px f-14 font-semibold text-gray-color text-nowrap mb-0">
                          Design No.
                      </div>
                         <app-input 
                         [inputName]="'designNo'" 
                         [inputType]="'text'" 
                         [inputId]="'designNo'"
                         maxLength="12"
                         [isRequired]="false"
                         [inputValue]="advanceFilterRequest?.design" 
                         (inputValueEmit)="advanceFilterRequest.design = $event">
                         </app-input>
                 </div>
                  <div class="col d-flex align-items-center">
                       <div class="w-120px f-14 font-semibold text-gray-color text-nowrap mb-0">
                       Barcode 
                       </div>
                        <app-input 
                        [inputName]="'barcodeNo'" 
                        [inputType]="'text'" 
                        [inputId]="'barcodeNo'"
                        [inputValue]="advanceFilterRequest?.barcode"
                        (inputValueEmit)="advanceFilterRequest.barcode = $event"
                        [isRequired]="false" 
                        >
                        </app-input>
                  </div>
              </div>
            
              <div class="col-12 px-0 gap-3 d-flex align-items-center mb-1">
                  <div class="col d-flex align-items-center">
                       <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                        JC No
                       </div>
                       <div class="col">
                        <app-input 
                        [inputName]="'jc'" 
                        [inputType]="'email'" 
                        [inputId]="'jc'"
                        [inputValue]="advanceFilterRequest?.jcno"
                        (inputValueEmit)="advanceFilterRequest.jcno = $event"
                        [isRequired]="false" 
                        >
                        </app-input> 
                       </div>
                  </div>
                  <div class="col d-flex align-items-center">
                      <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                        BOM No.
                       </div>
                       <div class="col">
                        <app-input 
                        [inputName]="'bom'" 
                        [inputType]="'text'" 
                        [inputId]="'bom'"
                        [inputValue]="advanceFilterRequest?.bfm"
                        (inputValueEmit)="advanceFilterRequest.bfm = $event"
                        [isRequired]="false" 
                        >
                        </app-input>
                       </div>
                  </div>
              </div>
          </div>  
      </div>
  </form>
  </app-advanced-filter-pop-up>
  