import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconHover } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss']
})
export class SaveComponent {
  currentHoverEffect = IconHover.Pink;
  constructor(public activeModal:NgbActiveModal){
  }

}
