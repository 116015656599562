import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconHover } from '../../../../../../../shared/enum/common-enum';


@Component({
  selector: 'app-field-value',
  templateUrl: './field-value.component.html',
  styleUrls: ['./field-value.component.scss']
})
export class FieldValueComponent implements OnInit {
  currentHoverEffect = IconHover.Pink
  fieldValue='';
  @Input()fieldValues = [];
  @Input()data : any ;

  index :number;
  @ViewChild('FieldValueForm') fieldValueForm  : NgForm

  constructor(public activeModal : NgbActiveModal){

  }

  ngOnInit(): void {
  }

  OnSaveFieldsValues(){
    this.activeModal.close(this.data?.fieldValues);
  }

  onAddValue(fieldValue){
    if(this.fieldValueForm.valid){
      if(this.index == null || this.index == undefined ){
        this.data?.fieldValues.push(fieldValue);
        this.fieldValue = '';
        this.index = null;
      }else{
        this.data.fieldValues[this.index] = fieldValue;
        this.index = null;
        this.fieldValue = '';
      }
      this.fieldValueForm?.resetForm();
    } else {
      const hideSubmitBtn = document.getElementById('hide');
      hideSubmitBtn?.click()
    }
    const input = document.getElementById('value');
    input?.focus() 
  }

  edit(item , index){
    this.fieldValue = item;
    this.index = index; 
  }

  deleteFieldValue(item ,i){
    this.data?.fieldValues.splice(i ,1)
    this.fieldValueForm?.resetForm();
  }
  
  check(value){
    return this.data?.selectedData.map((x)=> x?.id)?.includes(value);
   }

  add(selected){
    if(this.data?.selectedData.map((x)=> x?.id)?.includes(selected?.id)){
      const index = this.data?.selectedData?.findIndex((x)=>x.id == selected.id)
      this.data?.selectedData?.splice(index ,1);
    }else{
      this.data?.selectedData?.push(selected);
    }
  }

  selectAll(event){
    this.data.selectedData = [];
    if(event){
      this.data.selectedData = [...this.data?.data]
    }
  }

  apply(){
    this.activeModal.close(this.data?.selectedData);
  }

}
