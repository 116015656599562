import { EditDeleteIconComponent } from 'src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component';
import { AgColDefsI, AgGridI, paginationModel } from '../../../shared/common-components/ag-grid/ag-grid.model';
import { CheckboxRendererComponent } from 'src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component';
import { TextRendererComponent } from 'src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { exportRequest } from '../../Report/report.model';
import { AgGridCustomDropdownComponent } from 'src/app/shared/common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component';
import { StorageService } from 'src/app/shared/services/storage.service';
import { NumericCellRendererComponent } from 'src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component';
import { DateCellRendererComponent } from 'src/app/shared/common-components/ag-grid/date-cell-renderer/date-cell-renderer.component';

export class Masters {
}

const BranchesListColumnDef: AgColDefsI[] = [
    { headerName: "Branch Name", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'name',
    cellClass:'px-0 custom-column-group-cell text-primary',
    lockPosition:true,
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    editable:false, 
    minWidth: 180, flex:2,
  },
  // { headerName: "Company ID", 
  //   headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  //   field: 'companyId',
  //   sortable:true,
  //   cellClass:'px-2 custom-column-group-cell', minWidth: 90, flex: 1,
  //   editable:false,
  // },
  { headerName: "Contact", 
    headerClass: "px-1 custom-column-group-cell border-0 text-gray-color f-14 text-gray-color font-semibold ag_header_end",        
    field: 'mobileNumber1',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    editable:false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    minWidth: 180, flex: 1,
  },
  { headerName: 'Mail ID', 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell h-100 border-0 f-14 text-gray-color font-semibold",
    field: 'email', 
    cellClass:'px-2 custom-column-group-cell',
    editable:false,
    resizable: true,
    minWidth: 180, flex: 2, 
    cellRenderer: TextRendererComponent,
  },
  { headerName: "Address", 
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'fullAddress',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell f-12',
    editable:false,
    resizable: true, 
    minWidth: 280, flex: 2,
    cellRenderer: TextRendererComponent,
  },
  { headerName: "Status", 
  headerCheckboxSelection: false, 
  headerClass: "custom-column-group-cell px-0",
  field: 'active',  
  sortable:true, 
  cellRenderer: CheckboxRendererComponent, 
  cellRendererParams: { showLabel: false }, 
  cellClass:'px-2 custom-column-group-cell d-flex justify-content-center', 
  editable:false,
  resizable: true, 
  lockPosition:'right',
  suppressMovable: false,
  cellClassRules: {
    'non-editable-cell': params => !params.data.editable
  },
  minWidth: 75, 
  maxWidth:75 ,
}
  ,
  { headerName: "", 
  editable:false,
  field:'editdeleteicon',
  cellRenderer: EditDeleteIconComponent,
  cellClass:' custom-column-group-cell',
  // cellRendererParams :{focusOnColumn:'name' ,isEditRowOnEdit:false},
  cellRendererParams: (params: any) => {
    const storageService = new StorageService();
    const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
    return {
      hasDeletePermission: userPermissions?.mastersCanDelete,
      focusOnColumn:'name' ,isEditRowOnEdit:false
    };
  },
  lockPosition:'right',
  suppressMovable: false,
  resizable: false,  
  minWidth: 50, maxWidth:50,
}
  ,
];

const BucketListColumnDef: AgColDefsI[] = [
    { headerName: "Name", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'name',
    cellClass:'px-0 custom-column-group-cell text-primary', 
    editable:false,
    lockPosition:true,
    suppressMovable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    minWidth: 90, flex:2,
  },
  { headerName: "Min. Qty.", 
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'minimumQuantity',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    editable:false,
    resizable: true, 
    minWidth: 70, flex:1,
    cellRenderer: NumericCellRendererComponent,
  },
  { headerName: "Max. Qty.", 
    headerClass: "px-1 custom-column-group-cell border-0 text-gray-color f-14 text-gray-color font-semibold ag_header_end",        
    field: 'maximumQuantity',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    editable:false,
    resizable: true,
    minWidth: 70, flex:1,
    cellRenderer: NumericCellRendererComponent,
  },
  { headerName: 'Min. Wt.', 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'minimumWeight', 
    cellClass:'px-2 custom-column-group-cell text-end',
    editable:false,
    resizable: true,
    minWidth: 70, flex:1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isWeight:true},
  },
  { headerName: "Max. Wt.", 
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'maximumWeight',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    editable:false,
    resizable: true,
    minWidth: 70, flex:1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isWeight:true},
  },
  { headerName: "Weight", 
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'weight',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    editable:false,
    resizable: true,
    minWidth: 70, flex:1,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isWeight:true},
  },
  { headerName: "Status", 
    headerCheckboxSelection: false, 
    headerClass: "custom-column-group-cell px-0",
    field: 'active',  
    sortable:true, 
    cellRenderer: CheckboxRendererComponent,
    cellRendererParams: { showLabel: false }, 
    cellClass:'px-2 custom-column-group-cell d-flex justify-content-center',
    lockPosition:'right',
    suppressMovable: false,
    resizable: false,  
    editable:false,
    cellClassRules: {
      'non-editable-cell': params => !params.data.editable
    },
    minWidth: 75, 
    maxWidth:75 ,
  }
  ,
  { headerName: "", 
  editable:false,
  field:'editdeleteicon',
  cellRenderer: EditDeleteIconComponent,
  cellClass:' custom-column-group-cell',
  // cellRendererParams :{focusOnColumn:'name' ,isEditRowOnEdit:true}, 
  cellRendererParams: (params: any) => {
    const storageService = new StorageService();
    const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
    return {
      hasDeletePermission: userPermissions?.mastersCanDelete,
      focusOnColumn:'name' ,isEditRowOnEdit:true
    };
  },
  lockPosition:'right',
  suppressMovable: false,
  resizable: false, 
  minWidth: 50, 
  maxWidth:50
}
  ,
];


const TaxSettingListColumnDef: AgColDefsI[] = [
{ headerName: "Name", 
  headerComponentParams: {enableMenu:false},
  sortable:true,
  headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: 'name',
  cellClass:'px-0 custom-column-group-cell text-primary', 
  editable:false,
  lockPosition:true,
  suppressMovable: false,
  resizable: true, 
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:true},
  minWidth: 80, flex:2,
},
{ headerName: "Applicable On", 
  headerClass: "px-1 custom-column-group-cell border-0 text-gray-color f-14 text-gray-color font-semibold",        
  field: 'applicableOnName',
  sortable:true,
  cellClass:'px-2 custom-column-group-cell',
  minWidth: 80, flex:2,
  editable:false,
  resizable: true,
  cellRenderer: TextRendererComponent,
},
{ headerName: "Rate", 
  headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
  field: 'rate',
  sortable:true,
  cellClass:'px-2 custom-column-group-cell text-end', minWidth: 80,flex:2,
  editable:false,
  resizable: true,
  cellRendererParams: {isRate:true},
  cellRenderer:NumericCellRendererComponent,
},

// 
{ headerName: "Tax Group", 
  headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: 'taxGroupName',
  sortable:true,
  cellClass:'px-2 custom-column-group-cell',
  minWidth: 80,flex:2,
  editable:false,
  resizable: true,
  cellRenderer: TextRendererComponent,
},
{ headerName: "Comment", 
  headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: 'comment',
  sortable:true,
  cellClass:'px-2 custom-column-group-cell',
  minWidth: 80,flex:2,
  editable:false,
  resizable: true,
  cellRenderer: TextRendererComponent,
},
{ headerName: "Status", 
  headerCheckboxSelection: false, 
  headerClass: "custom-column-group-cell px-0",
  field: 'active',  
  sortable:true, 
  cellRenderer: CheckboxRendererComponent,
  cellRendererParams: { showLabel: false }, 
  cellClass:'px-2 custom-column-group-cell d-flex justify-content-center',
  suppressMovable: false,
  resizable: false, 
  minWidth: 75, 
  maxWidth:75 ,
  editable:false,
  cellClassRules: {
    'non-editable-cell': params => !params.data.editable
  }
}
,
{ headerName: "", 
  editable:false,
  field:'editdeleteicon',
  cellRenderer: EditDeleteIconComponent,
  cellClass:' custom-column-group-cell',
  cellRendererParams: (params: any) => {
    const storageService = new StorageService();
    const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
    return {
      hasDeletePermission: userPermissions?.mastersCanDelete,
      focusOnColumn:'branchName' ,isEditRowOnEdit:true
    };
  },
  lockPosition:'right',
  suppressMovable: false,
  resizable: false,  
  minWidth: 50, maxWidth:50
}
,
];

const StyleListColumnDef: AgColDefsI[] = [
  { headerName: "Style", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'style',
    cellClass:'px-2 custom-column-group-cell',
    cellRenderer:TextRendererComponent,
    suppressMovable: false,
    resizable: true, 
    editable:false, 
    minWidth: 120, flex:1,
  },
  { headerName: "Category", 
    headerClass: "px-0 custom-column-group-cell border-0 text-gray-color f-14 font-semibold",        
    field: 'categoryName',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell',
    editable:false,
    cellRenderer:TextRendererComponent,
    resizable: true,
    minWidth: 120, flex: 2,
  },
  { headerName: 'Product', 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'productName', 
    cellClass:'px-2 custom-column-group-cell',
    editable:false,
    resizable: true,
    minWidth: 150, flex: 2, 
    cellRenderer:TextRendererComponent,
  },
  { headerName: "Gross wt.", 
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'grossWt',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end f-12',
    editable:false,
    resizable: true,
    minWidth: 130,  
    maxWidth: 130,
    cellRenderer:NumericCellRendererComponent,
    cellRendererParams: {isWeight:true},
  }
];

const CreditCardListColumnDef: AgColDefsI[] = [
  { headerName: "Name", 
  headerComponentParams: {enableMenu:false},
  sortable:true,
  headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: 'name',
  cellClass:'px-0 custom-column-group-cell text-primary', 
  editable:false,
  lockPosition:'left',
  resizable: true,
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:true},
  minWidth: 200, flex:2,
},
{ headerName: "Account Group", 
  headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: 'accountLedgerName',
  sortable:true,
  cellClass:'px-2 custom-column-group-cell',
  editable:false,
  resizable: true, 
  minWidth: 180, flex:1,
  cellRenderer: NumericCellRendererComponent,
},
{ headerName: "Commission Group", 
  headerClass: "px-1 custom-column-group-cell border-0 text-gray-color f-14 text-gray-color font-semibold",        
  field: 'commissionGroupName',
  sortable:true,
  cellClass:'px-2 custom-column-group-cell',
  editable:false,
  resizable: true, 
  minWidth: 180, flex:1,
  cellRenderer: NumericCellRendererComponent,
},
{ headerName: 'Commission %', 
  sortable:true,
  headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
  field: 'commissionPercent', 
  cellClass:'px-2 custom-column-group-cell text-end',
  editable:false,
  resizable: true, 
  minWidth: 180, flex:1,
  cellRenderer: TextRendererComponent,
},
{ headerName: "Status", 
  headerCheckboxSelection: false, 
  headerClass: "custom-column-group-cell px-0",
  field: 'active',  
  sortable:true, 
  cellRenderer: CheckboxRendererComponent,
  cellRendererParams: { showLabel: false }, 
  cellClass:'px-2 custom-column-group-cell d-flex justify-content-center',
  resizable: false,  
  editable:false,
  cellClassRules: {
    'non-editable-cell': params => !params.data.editable
  },
  minWidth: 75, 
  maxWidth:75 ,
}
];

const ExchangeRateListColumnDef: AgColDefsI[] = [
  { headerName: "Metal", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'metal',
    cellClass:'px-0 custom-column-group-cell text-primary', 
    editable:false,
    lockPosition:true,
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
    minWidth: 80, flex:2,
  },
  { headerName: "Unit", 
    headerClass: "px-1 custom-column-group-cell border-0 text-gray-color f-14 text-gray-color font-semibold",        
    field: 'unit',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell',
    minWidth: 80, flex:2,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
  },
  { headerName: "Currency", 
    headerClass: "px-1 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
    field: 'currency',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell', minWidth: 80,flex:2,
    editable:false,
    resizable: true,
    cellRenderer:NumericCellRendererComponent,
  },
  
  // 
  { headerName: "Date", 
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'date',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    minWidth: 60,
    editable:false,
    resizable: true,
    cellRenderer: DateCellRendererComponent,
    cellRendererParams :{date: true},
  },
  { headerName: "Rate @", 
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'currencyRate',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    minWidth: 80,flex:2,
    editable:false,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isRate:true},
  },
  { headerName: "Unit Conversion Rate", 
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'conversionRate',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    minWidth: 80,flex:2,
    editable:false,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isRate:true},
  },
  { headerName: "Metal Rate", 
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'metalRate',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    minWidth: 80,flex:2,
    editable:false,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isRate:true},
  },
  { headerName: "Metal Rate AED Per Gram", 
    headerClass: "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
    field: 'metalRateInAED',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell text-end',
    minWidth: 80,flex:2,
    editable:false,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isRate:true},
  },

  ];
  

export const AgGridDataModelCreditCardList: AgGridI = {
  colDefs: CreditCardListColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.ADMINISTRATION_MASTERS_CREDITCARD_GRID,
  rowHeight:28,
}

export const AgGridDataModelStyleList: AgGridI = {
  colDefs: StyleListColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.ADMINISTRATION_MASTERS_STYLES_GRID,
  rowHeight:28,
}


export const AgGridDataModelBranchesList: AgGridI = {
    colDefs: BranchesListColumnDef,
    rowSelection: "multiple",
    storageName : StorageName.ADMINISTRATION_MASTERS_BRANCHES_GRID,
    rowHeight:28,
}


export const AgGridDataModelBucketList: AgGridI = {
    colDefs: BucketListColumnDef,
    rowSelection: "multiple",
    storageName : StorageName.ADMINISTRATION_MASTERS_BUCKET_GRID,
    rowHeight:28,
}


export const AgGridDataModelTaxSettingList: AgGridI = {
  colDefs: TaxSettingListColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.ADMINISTRATION_MASTERS_TAXSETTING_GRID,
  rowHeight:28,
}

export const AgGridDataModelExchangeRateList: AgGridI = {
  colDefs: ExchangeRateListColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.ADMINISTRATION_MASTERS_EXCHANGERATE_GRID,
  rowHeight:28,
}


export enum MasterModalTitle{
  AddBranchDetail  = "Branch Details",
  EditBranchDetail = "Edit Branch Details",
  AddBucket        = "Add Bucket",
  EditBucket       = "Edit Bucket",
  AddTaxSetting    = "Add Tax",
  EditTaxSetting   = "Edit Tax",
  AddCreditCard    = "Credit Card",
  EditCreaditCard  = "Edit Credit Card",
  AddExchangeRate   = "Exchange Rate",
  EditExchangeRate  = "Edit Exchange Rate",
}

// branch region start 
export class CreateUpdateBranchRequestModel{
  id              : number;
  name?           : string;
  active?         : boolean = true;
  addressId?      : number;
  addressLine1?   : string|null;
  addressLine2?   : string|null;
  email?          : string|null;
  countryId       : any;
  stateId?        : number;
  districtId?     : number;
  talukaId?       : number;
  pinCode?        : number;
  mobileNumber1   : number;
  mobileNo1DialCode : string;
  mobileNumber2   : number;
  mobileNo2DialCode: string;
  isSystemGenerated : boolean;
}  

export class BranchMainModel {
  branchModel: CreateUpdateBranchRequestModel;
  cloneDataModel: CloneDataModel;
}

export class CloneDataModel {
  branchId: number;
  cloneTableNames: string[];
}

export class GetDistrictData{
  id              : number;
  name?           : string;
}

// CreditCard region start
export class CreateUpdateCreditCardRequestModel{
  id                  : number;
  name?               : string;
  active?             : boolean = true;
  accountLedgerId?    : number;
  AccountLedgerName?  : string;
  commissionGroup?    : number;
  CommissionGroupName?: string;
  commissionPercent?  : number;
}
export class GetAllCreditCardrequestModel extends paginationModel{
  exportRequest : exportRequest[];
  getAll = true ;
}

// bucket region start
export class CreateUpdateBucketRequestModel{
  id              : number;
  name?           : string;
  active?          : boolean;
  barcodeNo?       : number;
  minimumQuantity? : number;
  maximumQuantity? : number;
  minimumWeight?   : number;
  maximumWeight?   : number;
  weight?          : number;
}

 // TaxSetting Model
 export class CreateUpdateTaxSettingRequestModel{
  id?              : number;
  name             : string;
  active?          : boolean = true;
  applicableOn     : number;
  comment?         : string ;
  rate?            : number = 0;
  calculationMode? : number;
  isStateWise?     : boolean;
  isEdit?          : boolean = false;
  taxGroupId?      : number ;
}



export enum MasterTab {
  Masters      = 1,      
  Branch       = 2,
  Bucket       = 3,
  TaxSetting   = 4,
  Style        = 5,
  UserDefined  = 6,           
  CreditCard   = 7,           
  ExchangeRate = 8,           
}
export class CompanyDropdownModelList{

}
export class StateDropdownModelList{

}

