<div class="position-relative">
  <div
    class="popup-content flex-column d-flex justify-content-center align-items-center position-relative overflow-hidden ">
    <app-svg-icon [name]="'Bg-white-cross'" class="cross-button close-button position-absolute cursorPointer"
      (click)="closeWebCam()">
    </app-svg-icon>
    <!-- [videoOptions]="videoOptions"  -->
    <div *ngIf="data?.stream">
      <!-- web cam image will be shown here -->
      <webcam [trigger]="$trigger" (imageCapture)="snapshot($event)" (cameraSwitched)="switch($event)"
        [allowCameraSwitch]="false" [imageQuality]="1"></webcam>
    </div>

  </div>
  <div class="position-absolute bottom-0 capture-area rounded-bottom-4 w-100">
    <div class="position-relative w-100 h-100">
      <app-svg-icon [name]="'camera-snap-button'"
        class="cursorPointer position-absolute top-50 start-50 translate-middle" (click)="captureImage()">
      </app-svg-icon>
      <app-svg-icon *ngIf="availableVideoInputs?.length > 1" [name]="'switchcamera'" class="cursorPointer position-absolute top-50 end-0 translate-middle"
        (click)="switchCam()">
      </app-svg-icon>
    </div>
  </div>
</div>