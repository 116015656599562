import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { commonModal, ModalPopupSize } from '../models/common.model';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CamViewComponent } from '../common-components/common-popup/cam-view/cam-view.component';
import { AddImageComponent } from '../common-components/common-popup/add-image/add-image.component';
import { ImgUploadAttachKey, SingleOrMultipleUpload } from '../enum/common-enum';
@Injectable({
  providedIn: 'root',
  
})
export class NotificationService {
  constructor(private modalService: NgbModal, private http: HttpClient , public toaster?:ToastrService) { }

  sucesspopup(message) {
    Swal.fire({
      position: 'top-right',
      width: 350,
      heightAuto: false,
      icon: 'success',
      text: message,
      showConfirmButton: false,
      timer: 1500
    })
  }
  
  openModalPopup(component: any, data: any, title: string, popupSize: string = ModalPopupSize.LG, windowClass: string = "", fullscreen?: boolean, centered?: boolean): Promise<any> {
    commonModal.size = popupSize;
    commonModal.windowClass = windowClass;
    commonModal.fullscreen = fullscreen;
    commonModal.centered = centered??true;

    const modalOption: NgbModalOptions = {
      ...commonModal,
    };
    const modalRef = this.modalService.open(component, modalOption);
    if (title != undefined)
      modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.data = data;
    this.makeDraggable();

    return modalRef.result.then(
      (result) => {
        if (result) {
          return result; // Returning the result back to the calling method
        }
      },
      (reason) => {
        // Handle dismissal case if needed
        return null;
      }
    );
  }

  public makeDraggable() {
    const modalElement = document.querySelector('.modal-dialog') as HTMLElement;
    if (modalElement) {
      let offsetX: number, offsetY: number;

      const dragHandle = modalElement.querySelector('.modal-header') as HTMLElement;
      if (dragHandle) {
        dragHandle.addEventListener('mousedown', (e: MouseEvent) => {
          e.preventDefault(); // Prevent default browser behavior

          // Calculate initial offsets
          offsetX = e.clientX;
          offsetY = e.clientY;

          // Function to handle mouse movement
          const onMouseMove = (event: MouseEvent) => {
            const newLeft = event.clientX - offsetX;
            const newTop = event.clientY - offsetY;

            // Apply the new position, ensuring size is maintained
            // modalElement.style.position = 'absolute';
            modalElement.style.left = `${newLeft}px`;
            modalElement.style.top = `${newTop}px`;
          };

          // Function to handle mouse release
          const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
          };

          // Add event listeners for mouse move and release
          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', onMouseUp);
        });
      }
    }
  }

  errorPopup(message: string, title: string = null) {
    const subject = new Subject<SweetAlertResult>();
    this.loadSvgIcon('assets/svg/confirmation-popup/error-icon.svg').subscribe((svgContent) => {
      Swal.fire({
        title: title ? title : '',
        html: `<p class="sub-info font-regular text-gray d-flex align-items-center f-14 mb-2 justify-content-center text-center">${message}</p>`, // Display error message dynamically
        iconHtml: svgContent,
        position: 'center',
        width:'auto',
        heightAuto: false,
        showCloseButton: true,
        customClass: {
          htmlContainer: 'swalHtml',
          confirmButton: 'swal-custom-confirm-button swal-confirm-error-button',
          actions: 'swal-custom-actions',
          popup: 'swal-custom-popup'
        },
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    });
    return subject.asObservable();
  }

  warningPopup(message: string) {
    const subject = new Subject<SweetAlertResult>();
    this.loadSvgIcon('assets/svg/confirmation-popup/worning-icon.svg').subscribe((svgContent) => {
      Swal.fire({
        title: '',
        html: `<p class="sub-info font-regular text-gray d-flex align-items-center f-14 mb-2 justify-content-center text-center">${message}</p>`, // Display message dynamically
        iconHtml: svgContent,
        position: 'center',
        width: 350,
        heightAuto: false,
        customClass: {
          htmlContainer: 'swalHtml',
          confirmButton: 'swal-custom-confirm-button',
          actions: 'swal-custom-actions',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          subject.next(result);
        }
      });
    });
    return subject.asObservable();
  }

  loadSvgIcon(filePath: string): Observable<string> {
    return this.http.get(filePath, { responseType: 'text' });
  }

  confirmationPopup(title, subtitle, icon, showDenyButton = true, confirmButtonText = 'Yes', denyButtonText = `No`, showDeleteButton = false) {
    var subject = new Subject<SweetAlertResult>();
    this.loadSvgIcon('assets/svg/confirmation-popup/' + icon + '.svg').subscribe((svgContent) => {
      Swal.fire({
        title: title,
        html: '<p class="sub-info font-regular text-gray d-flex align-items-center f-14 mb-2 justify-content-center text-center">' + subtitle + '</p>',
        iconHtml: svgContent,
        showDenyButton: showDenyButton,
        showCancelButton: showDeleteButton,
        showCloseButton: true,
        width: 300,
        confirmButtonText: confirmButtonText,
        denyButtonText: denyButtonText,
        // cancelButtonText: showDontSaveButton ? dontSaveButtonText : deleteButtonText,
        customClass: {
          title: 'sweetAlertTitle',
          htmlContainer: 'swalHtml',
          confirmButton: 'swal-custom-confirm-button', // Yes button
          denyButton: 'swal-custom-deny-button',
          cancelButton: 'swal-custom-deny-button',       // No button
          actions: 'swal-custom-actions',
        },
        // confirmButtonColor: '#3085d6'
      }).then((result) => {
        if (result.isDismissed && result.dismiss === Swal.DismissReason.close) {
          //close button click
        } else if (result.isConfirmed) {
          subject.next(result);  // Yes button click
        } else if (result.isDenied) {
          subject.next(result);  // No button click
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          subject.next(result);  // Don't Save or Cancel button click
        }
      });
    });
    return subject.asObservable();
  }
  
  openAddImagePopup(imgList: any[] , isMultipleUpload = true, imgObjKey = ImgUploadAttachKey.Base64Resource , allData? , isAction= false) {
    var data = {
      imagesList: [...imgList],
      imgObjKey ,
      isMultipleUpload,
      allData,
      isAction
    };
    return this.openModalPopup(AddImageComponent, data, "Add Image", ModalPopupSize.MD, "AddImagePopUp")
    .then((result: any) => {
      if(result == 'close') return false;
      if(result?.length && imgObjKey == ImgUploadAttachKey.ImageUrl && isMultipleUpload){
        let updatedArr = result.map(obj => {
          let { base64Resource, ...rest } = obj;
          return { imageUrl: base64Resource, ...rest }; 
        });
        return updatedArr??[]
      } else if( !isMultipleUpload){
        return result[0]?.base64Resource ?? null;
      } 

      return result??[];
    })
  }
}