import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaximizeToggleService {
  private maximizeToggleSubject = new Subject<boolean>();
  maximizeToggle$ = this.maximizeToggleSubject.asObservable();
  isHeaderToggle:boolean;
  constructor(private zone: NgZone) {}

  private isFullscreenListenerActive = false; // Track if the listener is already added

  toggleMaximize(isMaximized: boolean) {
    if(this.isHeaderToggle){
      this.maximizeToggleSubject.next(false);
    }else{
      this.maximizeToggleSubject.next(isMaximized);
    }
  }
  

  toggleFullScreen() {
    const isFullScreen = this.isFullScreen();

    this.toggleMaximize(!isFullScreen);
    if (isFullScreen) {
      this.exitFullScreen();
    } else {
      this.enterFullScreen();
    }
  }

  toggleFullScreenStock() {
    const isFullScreen = this.isFullScreenStock();

    this.toggleMaximize(!isFullScreen);
    if (isFullScreen) {
      this.exitFullScreen();
    } else {
      this.enterFullScreen();
    }
  }

  isFullScreenStock(): boolean {
    return !!(
      document.fullscreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).msFullscreenElement
    );
  }
  isFullScreen(): boolean {
    return (
      document.fullscreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).msFullscreenElement
    );
  }


  enterFullScreen() {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if ((document.documentElement as any).mozRequestFullScreen) {
      (document.documentElement as any).mozRequestFullScreen();
    } else if ((document.documentElement as any).webkitRequestFullscreen) {
      (document.documentElement as any).webkitRequestFullscreen();
    } else if ((document.documentElement as any).msRequestFullscreen) {
      (document.documentElement as any).msRequestFullscreen();
    }
  }

  exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }
  }

  startFullscreenListener() {
    // Avoid adding the listener multiple times
    if (!this.isFullscreenListenerActive) {
      this.isFullscreenListenerActive = true;

      document.addEventListener('fullscreenchange', () => {
        this.zone.run(() => {
          this.toggleMaximize(this.isFullScreenStock());
          
        });
      });
    }
  }
  setHeaderFlag(isHeader){   
    this.isHeaderToggle =isHeader;
  }
  // Optional: Cleanup method to remove the listener
  removeFullscreenListener() {
    if (this.isFullscreenListenerActive) {
      document.removeEventListener('fullscreenchange', () => {
        this.zone.run(() => {
          this.toggleMaximize(this.isFullScreenStock());
        });
      });
      this.isFullscreenListenerActive = false;
    }
  }
}
