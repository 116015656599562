<select  
    [id]="id" 
    [name]="name" 
    [tabIndex]="'0'"
    [required]="isRequired"    
    [disabled]="isDisabled"
    [class]="selectClass +' form-select pe-4 text-truncate'" 
    [(ngModel)]="selectedValue"
    (ngModelChange)="valueChanged.emit($event)"
    [ngClass]="{'readonly-field': isDisabled}"
    #selectInput="ngModel"
>
    <!-- Show options only if isValueOptionShow is true -->
    <ng-container *ngIf="isValueOptionShow; else optionSection">
        <option *ngIf="defaultOption" value="0"></option>
        <option *ngFor="let option of options" [value]="option.id" [disabled]="isEstimate == false ? option?.id == optionDisable : isOptionDisableds(option.id)">
            {{ truncateText(isDateTimePipe ? (option?.name | date:'medium'): option?.name,60)}}

        </option>
    </ng-container>

    <!-- Show options only if isValueOptionShow is false -->
    <ng-template #optionSection>
        <option *ngFor="let option of options" [value]="option.id" [disabled]="option.id == optionDisable">
            {{ isDateTimePipe ? (option.name | date:'medium'): option.name}}
        </option>
    </ng-template>
</select>
