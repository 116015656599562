<div class="d-flex align-items-center justify-content-end f-13">
    <div class="col add_Export_dropdown position-relative"> 
        <div ngbDropdown #exportData="ngbDropdown" class="d-inline-block">
            <button type="button" class="btn w-100" id="dropdownBasic2" ngbDropdownToggle [disabled]="isDisabled">
                &nbsp;
                &nbsp;
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <div class="form-check d-flex export-hover-item" ngbDropdownItem (click)="downloadXlSFile(exportAs?.Excel)">
                    <div class="d-flex align-items-center"><app-svg-icon name="export_excel" class="cursorPointer"></app-svg-icon></div>
                    <label for="excel" class="d-flex align-items-center ps-1 mb-0 cursorPointer f-12 font-regular color-dark-gray mb-0">
                        Excel
                    </label>
                </div>
                <div *ngIf="showPdfOption" class="form-check d-flex export-hover-item" ngbDropdownItem (click)="downloadXlSFile(exportAs?.PDF)">
                    <div class="d-flex align-items-center"><app-svg-icon name="export_pdf" class="cursorPointer"></app-svg-icon></div>
                    <label for="pdf" class="d-flex align-items-center ps-2 mb-0 cursorPointer f-12 font-regular color-dark-gray mt-1">
                        PDF
                    </label>
                </div>
                <div *ngIf="showCustomOption" class="form-check d-flex export-hover-item" ngbDropdownItem (click)="downloadXlSFile(exportAs?.CUSTOM)">
                    <div class="d-flex align-items-center">
                        <app-svg-icon name="export_custom" class="cursorPointer"></app-svg-icon>
                    </div>
                    <label class="d-flex align-items-center ps-2 mb-0 cursorPointer f-12 font-regular color-dark-gray mt-1">
                        Custom
                    </label>
                </div>
            </div>
        </div>
        <div class="add_ExportImport_button" (click)="isDisabled?'':exportData?.toggle()">
            <button type="button" class="btn  p-0" id="export">
                <span class="primary-color-1 font-semibold">
                    Export
                </span>
            </button>
        </div>
    </div>
</div>