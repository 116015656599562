import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerDetailsComponent } from '../../customer-details.component';
import { CustomerDetails } from '../../customer-details.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent extends CommonService{
  @ViewChild(CustomerDetailsComponent) customerDetailsComponent: CustomerDetailsComponent;
  @Input() customerDetails : CustomerDetails;
  @Input() getAllDropdownModel  : any = {};
  
  storageName=StorageName;
  isFromAccountLedger:boolean = false;
  LedgerId: any;

  onChangeCountryName(id){
    this.getAllDropdownModel.billingAddressStateList = this.getAllDropdownModel?.stateDropdown?.filter((x)=>x?.extra1 == id || x.id ==null);
   }

   getDialCodeId(id){
    this.customerDetailsComponent?.getDialcodeByCountryId(id);
  }
}