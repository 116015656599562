import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {PaginationBaseModel} from '../../components/common-model/common-model'
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() collectionSize;
  @Input() lastPage:any;
  @Output() emitPaginationModel = new EventEmitter<any>();
num=1
  constructor() { }
  
   paginationModel = new PaginationBaseModel();

  ngOnInit(): void {

    
  }

  onPageChange(){
    this.emitPaginationModel.emit(this.paginationModel);
  
  }
}
