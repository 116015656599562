
    <div class="modal-header d-flex align-items-center justify-content-center position-relative">
        <div><span class="font-semibold text-primary">{{ modalTitle }}</span></div>
        <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
        <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
    </div>
    <form autocomplete="off" class="theme-form d-flex flex-fill flex-column justify-content-between" ngNativeValidate #AdditionalAmountForm="ngForm">
        <div class="modal-body px-1 pb-0">
            <div class="row mx-0 gap-2 flex-fill">
                <div class="col p-0">
                    <div class="h-100 position-relative">
                        <app-ag-grid id="addDiamond" 
                            [class]="'all_aggrid_tables position-relative d-block'"
                            [dataModel]="agGridDataModel" 
                            [columnSettings]="true"
                            (onPlusIconClick)="onClickPlusIcon($event)"
                            (cellValueChanged)="onCellValueChangedDetect($event)"
                            (gridReady)="onGridReady($event)"
                            >
                        </app-ag-grid>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer  align-items-center justify-content-center pt-2 pb-2 mx-2">
            <hr class="m-0">
            <div class="d-flex ml-2 cursorPointer blueText_white_bg_button f-14 font-semibold more-button-div"
                (click)="OnSaveAdditionalAmount('Metal Exchange')">
                Metal Exchange
            </div>
             <div class="d-flex ml-2 cursorPointer blueText_white_bg_button f-14 font-semibold more-button-div"
                (click)="OnSaveAdditionalAmount('Old Jewellery')">
                Old Jewellery
            </div>
        </div>
    </form>
