import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { catchError } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setting-type',
  templateUrl: './setting-type.component.html',
  styleUrls: ['./setting-type.component.scss']
})
export class SettingTypeComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllSettingTypeData          : any[]=[];
  isAdd                       : boolean = false;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllSettingTypeList();
}

add(){
  this.isAdd = true;
  const obj = {id:0, name:'',active:true}
  this.getAllSettingTypeData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`setting-typeName${obj.id}`);
    element?.focus();
  },0)
}

cancel(){
  this.isAdd = false;
  this.getAllSettingTypeData = this.getAllSettingTypeData.filter((x)=>x.id != 0)
}

edit(item,formData){
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
}

  // *********** SETTINGTYPE API START *********
  // **********get all SettingType *********
  getAllSettingTypeList() {
    this.administrationService.getAllSettingType().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.getAllSettingTypeData = res.response??[];
        }else{           
          this.getAllSettingTypeData = [];
        }  
      },
      error:(err)=>{
        this.getAllSettingTypeData = [];
      }
    })
  }
  
 // *************insert SettingType **************
  InserSettingTypeList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertSettingType(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllSettingTypeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update SettingType *********
    UpdateSettingTypeList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateSettingType(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            
            this.isAdd = false;
            this.getAllSettingTypeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete SettingType *********
    DeleteSettingTypeList(id) {
      this.administrationService.deleteSettingType(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllSettingTypeList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** SETTINGTYPE API END *********
}
