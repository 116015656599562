import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { catchError } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { GetAllSettingResponseModel } from 'src/app/components/administration/administration.model';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllSettingData          : any[]=[];

  copyGetAllSettingData       : any[]=[];
  editSettingData             = new GetAllSettingResponseModel();
  isAdd                       : boolean = false;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllSettingList();
}

add(){
  this.isAdd = true;
  const obj = {id:0, name:'',active:true,value:0,settingTypeId:0}
  this.getAllSettingData?.push(obj);
  this.copyGetAllSettingData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`settingName${obj.id}`);
    element?.focus();
  },0)
}

cancel(){
  // 
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllSettingData = this.getAllSettingData.filter((x)=>x.id != 0)
    this.copyGetAllSettingData = this.copyGetAllSettingData.filter((x)=>x.id != 0)
  }
  else if(this.editSettingData?.id && this.editSettingData?.isEdit){
    const index = this.copyGetAllSettingData.findIndex((x)=>x.id == this.editSettingData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllSettingData))[index];
    this.getAllSettingData[index] = singleData;
    this.editSettingData = new GetAllSettingResponseModel()
  }  
}


edit(item,formData){
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editSettingData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
 }


  // *********** SETTING API START *********
  // **********get all Setting *********
  getAllSettingList() {
    this.administrationService.getAllSetting().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.editSettingData = new GetAllSettingResponseModel();
          this.getAllSettingData = res.response??[];
           this.copyGetAllSettingData = JSON.parse(JSON.stringify(this.getAllSettingData))??[];
        }else{           
          this.getAllSettingData = [];
          this.copyGetAllSettingData = [];
        }  
      },
      error:(err)=>{
        this.getAllSettingData = []; 
        this.copyGetAllSettingData = [];
      }
    })
  }
  
 // *************insert Setting **************
  InserSettingList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertSetting(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllSettingList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update Setting *********
    UpdateSettingList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateSetting(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            
            this.isAdd = false;
            this.getAllSettingList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete Setting *********
    DeleteSettingList(id) {
      this.administrationService.deleteSetting(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllSettingList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** SETTING API END *********



}



