import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';
import { StorageService } from '../../services/storage.service';
import { CalculationType, StorageName } from '../../enum/common-enum';
import { MetalSectionModel } from './metal-section.model';
import { DiamondCategory, ModalPopupSize } from '../../models/common.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MetalSectionChildComponent } from '../metal-section-child/metal-section-child.component';
import { NotificationService } from '../../services/notification.service';
import { MetalSectionReadonlyComponent } from '../metal-section-readonly/metal-section-readonly.component';

@Injectable({providedIn: 'root'})
export class MetalSectionService {
  constructor(public service: DataService, public storageService: StorageService, public modalService: NgbModal,
    public notificationService : NotificationService
  ) { }

  openMetalSectionPopup(metalSectionModel, formModel, formName, barcodeNo = undefined ,designNo?) {
    var data = { formName: formName, formModel: formModel, metalSectionModel: metalSectionModel, barcodeNo: barcodeNo ,designNo:designNo}
    const modalRefResult = this.notificationService.openModalPopup(MetalSectionChildComponent, data, 'Enter your item', ModalPopupSize.XL, 'print-popup' , false , true);
    return modalRefResult;
  }


  setVisibility(storageName: StorageName | StorageName): any {
    var metalSectionModel = new MetalSectionModel();
    switch (storageName) {
      case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_SALEINVOICE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_SALEINVOICE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_SALEINVOICE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_SALEINVOICE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_SALEINVOICE_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_SALESQUOTATION_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_SALESQUOTATION_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_SALESQUOTATION_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_SALESQUOTATION_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_SALESQUOTATION_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_DELIVERYNOTE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_DELIVERYNOTE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_DELIVERYNOTE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_DELIVERYNOTE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_DELIVERYNOTE_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_PURCHASEINVOICE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_PURCHASEINVOICE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_PURCHASEINVOICE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_PURCHASEINVOICE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_PURCHASEINVOICE_IMITATION_ITEM_GRID);
        break;
        case StorageName.FORMNAME_TRANSACTION_PURCHASEQUOTATION:
          metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_PURCHASEQUOTATION_GOLD_ITEM_GRID);
          metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_PURCHASEQUOTATION_SILVER_ITEM_GRID);
          metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_PURCHASEQUOTATION_DIAMOND_ITEM_GRID);
          metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_PURCHASEQUOTATION_STONE_GEMS_ITEM_GRID);
          metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_PURCHASEQUOTATION_IMITATION_ITEM_GRID);
          break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_JOBWORKINVOICE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_JOBWORKINVOICE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_JOBWORKINVOICE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_JOBWORKINVOICE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_JOBWORKINVOICE_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESORDER:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_SALESORDER_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_SALESORDER_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_SALESORDER_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_SALESORDER_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_SALESORDER_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_PURCHASEORDER_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_PURCHASEORDER_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_PURCHASEORDER_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_PURCHASEORDER_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_PURCHASEORDER_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_PURCHASERETURN_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_PURCHASERETURN_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_PURCHASERETURN_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_PURCHASERETURN_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_PURCHASERETURN_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_SALESRETURN_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_SALESRETURN_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_SALESRETURN_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_SALESRETURN_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_SALESRETURN_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_JOBWORKORDER_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_JOBWORKORDER_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_JOBWORKORDER_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_JOBWORKORDER_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_JOBWORKORDER_IMITATION_ITEM_GRID);
        break;

      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_STOCKOUT:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter = true;
        metalSectionModel.showCommentAndAddField = false;
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_IMITATION_ITEM_GRID);
        break;

      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK:
        metalSectionModel.showCunsumptionAndProductionHeaders = true;
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter = false;
        metalSectionModel.showCommentAndAddField = true;
        metalSectionModel.addButton = false;
        metalSectionModel.agGridDataModelGold.moreButton = true;
        metalSectionModel.agGridDataModelSilver.moreButton = true;
        metalSectionModel.agGridDataModelDiamond.moreButton = true;
        metalSectionModel.agGridDataModelStoneGEMS.moreButton = true;
        metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = true;
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_IMITATION_ITEM_GRID);
        metalSectionModel.tableIndex = 0;
        metalSectionModel.selectAll = true;
        metalSectionModel.showMetalSelectionTabs = true;
        break;
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_STOCKOUT:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter = true;
        metalSectionModel.showCommentAndAddField = false;
        metalSectionModel.agGridDataModelGold.storageName = StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_GOLD_ITEM_GRID;
        metalSectionModel.agGridDataModelSilver.storageName = StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_SILVER_ITEM_GRID;
        metalSectionModel.agGridDataModelDiamond.storageName = StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_DIAMOND_ITEM_GRID;
        metalSectionModel.agGridDataModelStoneGEMS.storageName = StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_STONE_GEMS_ITEM_GRID;
        metalSectionModel.agGridDataModelImmitationAndWatches.storageName = StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_IMITATION_ITEM_GRID;
        break;
      case StorageName.FORMNAME_MANUFACTURER_MANUFACTURING_PROCESS_MATERIAL_ISSUE:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter = true;
        metalSectionModel.showCommentAndAddField = false;
        metalSectionModel.agGridDataModelGold.storageName = StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_GOLD_ITEM_GRID;
        metalSectionModel.agGridDataModelSilver.storageName = StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_SILVER_ITEM_GRID;
        metalSectionModel.agGridDataModelDiamond.storageName = StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_DIAMOND_ITEM_GRID;
        metalSectionModel.agGridDataModelStoneGEMS.storageName = StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_STONE_GEMS_ITEM_GRID;
        metalSectionModel.agGridDataModelImmitationAndWatches.storageName = StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_IMITATION_ITEM_GRID;
        break;
      case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_CREDITNOTE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_CREDITNOTE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_CREDITNOTE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_CREDITNOTE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_CREDITNOTE_IMITATION_ITEM_GRID);
        break;
      case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold, StorageName.TRANSACTION_DEBITNOTE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver, StorageName.TRANSACTION_DEBITNOTE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond, StorageName.TRANSACTION_DEBITNOTE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS, StorageName.TRANSACTION_DEBITNOTE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches, StorageName.TRANSACTION_DEBITNOTE_IMITATION_ITEM_GRID);
        break;
    }
    return metalSectionModel;
  }

  setNewColDef(grirDataModel, storageName): any {
    grirDataModel.colDefs = grirDataModel.colDefs.map((item, index) => ({
      ...item,
      headerComponentParams: {
        ...item.headerComponentParams,
        storageName: storageName
      },
      storageName: storageName
    }));
    grirDataModel.storageName = storageName;
    return grirDataModel;
  }

  calculateDiamondItem(model, calculateByField, addOtherRowsAmount: number = 0, rows: any[] = []): any {
    model.goldPurityPer = Number(model.goldPurityPer ?? 1);
    model.rate = Number(model.rate ?? 0);
    model.amount = Number(model.amount ?? 0);
    model.certificateAmount = Number(model.certificateAmount ?? 0);
    model.goldAmount = Number(model.goldAmount ?? 0);
    model.makingAmount = Number(model.makingAmount ?? 0);
    model.otherAmount = Number(model.otherAmount ?? 0);
    model.hallmarkRate = Number(model.hallmarkRate ?? 0);
    model.discountPer = Number(model.discountPer ?? 0);
    model.purchaseAmount = Number(model.purchaseAmount ?? 0);
    model.quantity = Number(model.quantity ?? 0) == 0 ? 1 : Number(model.quantity);
    if (model.calculationType == CalculationType.CaratRateWise || model.calculationType == CalculationType.Fix)
      switch (calculateByField) {
        case "weight": model.carat = Number(model.weight ?? 0) * 5; break;
        case "carat": model.weight = Number(model.carat ?? 0) / 5; break;
        case "goldWt": model.weight = Number(model.carat ?? 0) / 5; break;
        default: model.carat = Number(model.weight ?? 0) * 5; break;
      }
    var exceptJewelleryRow = rows?.filter(row => Number(row?.diamondCategoryId) !== Number(DiamondCategory.Jewellery));
    if (addOtherRowsAmount == 0 && model.diamondCategoryId == DiamondCategory.Jewellery ) 
      if (rows.length != exceptJewelleryRow.length)
        addOtherRowsAmount = (exceptJewelleryRow?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
    model.goldLossValue = Number(model.goldLossWt ?? 0) * Number(model.goldRate ?? 0);
    var otherRowsQty = (exceptJewelleryRow?.map(a => Number(a?.quantity))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);;
    model.settingChargeAmount = Number(model.settingCharge ?? 0) * Number(otherRowsQty ?? 0);
    model.amount = (Number(model.calculationType) === CalculationType.Fix ? Number(model.rate ?? 0) + addOtherRowsAmount : Number(model.carat ?? 0) * Number(model.rate ?? 0) + addOtherRowsAmount) ;
    model.discountAmount = model.discountPer != undefined ? Number(model.amount ?? 0) * Number(model.discountPer ?? 0) : 0;
    if (model.diamondCategoryId == DiamondCategory.Diamonds || model.diamondCategoryId == DiamondCategory.GemStones)
      model.grossWt = model.weight;
    else
      model.grossWt = (Number(model.packetWt ?? 0) > 0) ? (Number(model.packetWt ?? 0) - Number(model.packetLessWt ?? 0)) : Number(model.grossWt ?? 0);
      var preNetAmount = Number(model.amount ?? 0) + Number(model.goldLossValue ?? 0) + Number(model.settingChargeAmount ?? 0) + Number(model.markUpAmount ?? 0) + Number(model.certificateAmount ?? 0) + (model.metalFix == true ? 0 : Number(model.goldAmount ?? 0)) + Number(model.makingAmount ?? 0) + Number(model.otherAmount ?? 0);
      model.discountAmount = (Number(preNetAmount ?? 0) * Number(model.discountPer ?? 0));
      model.netAmount = preNetAmount   -  Number(model.discountAmount ?? 0) ;
      model.netAmountWithTax = Number(model.netAmount ?? 0) + Number(model.taxAmount ?? 0);
      // model.goldPurityWt = Number(model.goldWt ?? 0) * (Number(model.goldPurityPer == null || model.goldPurityPer == 0 ? 1 : model.goldPurityPer));
      if (model.diamondCategoryId != DiamondCategory.Diamonds && model.diamondCategoryId != DiamondCategory.GemStones && model.diamondCategoryId != undefined) {
        switch (calculateByField) {
          case "carat":
          case "grossWt":
          case "weight":
            model.goldWt = Number(model.grossWt ?? 0) - Number(model.weight ?? 0);
            break;
        }
        model.goldPurityWt = Number(model.goldWt ?? 0) * (Number(model.goldPurityPer == null || model.goldPurityPer == 0 ? 1 : model.goldPurityPer));
        model.goldAmount = Number(model.grossWt ?? 0) * Number(model.goldRate ?? 0);
      }
    return model;
  }
}