import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { catchError } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { GetAllQaulityResponseModel } from 'src/app/components/administration/administration.model';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllQualityData              : any[]=[];
  copyGetAllQualityData          : any[]=[];
  isAdd                          : boolean = false;
  editQualityData                = new GetAllQaulityResponseModel();

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllQualityList();
}

add(){
  this.isAdd = true;
  const obj = {id:0, name:'',active:true}
  this.getAllQualityData?.push(obj);
  this.copyGetAllQualityData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`qualityname${obj.id}`);
    element?.focus();
  },0)
}

cancel(){
  // 
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllQualityData = this.getAllQualityData.filter((x)=>x.id != 0);
    this.copyGetAllQualityData = this.copyGetAllQualityData.filter((x)=>x.id != 0);
  }
  else if(this.editQualityData?.id && this.editQualityData?.isEdit){
    const index = this.copyGetAllQualityData.findIndex((x)=>x.id == this.editQualityData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllQualityData))[index];
    this.getAllQualityData[index] = singleData;
    this.editQualityData = new GetAllQaulityResponseModel();
  }  
}

edit(item,formData){
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editQualityData = {...item};
  // 
  // 
}

checkIsEdit(arr:any[]):boolean{
return arr?.some((x)=>x.isEdit)
}

  // *********** QUALITY API START *********
  // **********get all Quality *********
  getAllQualityList() {
    this.administrationService.getAllQuality().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.editQualityData = new GetAllQaulityResponseModel();
          this.getAllQualityData = res.response??[];
          this.copyGetAllQualityData = JSON.parse(JSON.stringify(this.getAllQualityData))??[];
        }else{           
          this.getAllQualityData = [];
          this.copyGetAllQualityData = [];
        }  
      },
      error:(err)=>{
        this.getAllQualityData = [];
        this.copyGetAllQualityData = [];
      }
    })
  }
  
 // *************insert Quality **************
  InserQualityList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertQuality(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllQualityList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update Quality *********
    UpdateQualityList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateQuality(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            
            this.getAllQualityList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete Quality *********
    DeleteQualityList(id) {
      this.administrationService.deleteQuality(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllQualityList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** QUALITY API END *********
}
