import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { JobWorkStatus, OrderDetail, OrdetTrackingStatus, Timeline } from './order-tracking.model';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import {OrderTrackingService} from './order-tracking.services'
import { HttpStatusCode } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetOrderTrackingDetail } from './order-tracking.model';
import { VoucherType } from 'src/app/shared/models/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { IconHover } from '../../../shared/enum/common-enum';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent extends CommonService {
  @Input() data:any={}
  orderTrackingNameObj : any;
  storageName = StorageName;
  currentHoverEffect = IconHover.Pink;
  processLine = 0;
  stageStatusEnum = OrdetTrackingStatus;
  progressBarValue: number = 30;
  formName = StorageName.FORMNAME_ORDERS_ORDERTRACKING;
  jobWorkStatus = JobWorkStatus
  @Input() applicantId: number = 0;
  orderTrackingDetail = new GetOrderTrackingDetail();
  orderDetail = new OrderDetail();
  previewImg 
  @Input() jobworkOrderId : boolean=false;
  
constructor(public sendDataService : SendDataService,
  public orderTrackingService : OrderTrackingService,
  public toaster     : ToastrService,
  public activeModal : NgbActiveModal,
  public renderer: Renderer2,
  ) {
  super(renderer);
};

  // getProgressBarValue(): number {
  //  return 500;
  // }

  ngOnInit(): void {
    this.data
   this.getOrderTrackingDetail()
  }
  getOrderTrackingDetail() {
    this.orderTrackingDetail.id =this.jobworkOrderId ? this.data?.paramsData.jobworkOrderId : this.data?.paramsData.againstVoucherNo;
    this.orderTrackingDetail.voucherTypeId = this.jobworkOrderId ? this.data?.paramsData.jobworkOrderVoucherTypeId : this.data?.paramsData.voucherTypeId || this.data?.paramsData.againstVoucherTypeId;
    this.orderTrackingService.getOrderTrackingDetail(this.orderTrackingDetail)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.orderDetail = res.response;
          this.previewImg  =  (this.orderDetail?.imageUrls?.filter((x)=>x?.imageUrl)[0]?.imageUrl)??null;
          if(this.orderDetail?.orderFlow?.length){
            this.processLine = (this.orderDetail?.orderFlow?.length - 1)*120;
          }
        }
      });
  }

  ngOnDestroy(): void {

  }

   handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }
}