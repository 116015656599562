import { paginationModel } from "src/app/shared/common-components/ag-grid/ag-grid.model";

export enum BlockUnblockStatus {
    Unblock = '0',
    Block = '1',
}

export class GetAllContactGroupList {
    serialNo?: number;
    groupName?: string;
    createdDate?: string;
    userCount?: number;
}

export class GetAllWhatsappMarketingRequestModel extends paginationModel{
    pageSize = 25;
    page=1;
    getAll= false;
    categoryId: number;
  }

  export class InsertEmailRequestModel {
    campaignMasterModel: CampaignMasterModel;
    campaignMappingModel: CampaignMappingModel[];
  }
  
  export class CampaignMasterModel {
    campaignTypeId: number;
    campaignName:string;
    body:string;
    date:string;
    statusId: number;
    templateId: number;
    subject: string;
    attachmentPath:string;
   groupId:number;
  }
  
  export class CampaignMappingModel {
    accountLedgerId: number;
    campaignTypeId: number;
    statusId: number;
    statusMessage: string;
    contactInfo: string;
  }

  export class GetallEmailRequestModel extends paginationModel {
    fromDate: string;
    toDate: string;
    pageSize: number = 25;
    filters: any;
  }
  
  export class GetallWhatsappInsertRequestModel {
    caption: string;
    attachmentPath:string;
    date:string;
    statusName: string;
  }

  export class GetallWhatsappRequestModel extends paginationModel {
    fromDate: string;
    toDate: string;
    pageSize: number = 25;
    filters       : string ;
    exportAs      :number;
    exportRequest : exportRequest[];
  }

  export class exportRequest {
    gridName : string;
    columnToInclude : any
  }