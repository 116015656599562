
<app-advanced-filter-pop-up  (applyFilter)="onClickAdvancedFilter()" (clearFilter)="clearFields()">
  <form class="theme-form">
      <div class="modal-body mx-2 py-0">
          <div class="row mx-0">
              <div class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                 <div class="col d-flex align-items-center">
                      <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                          Date Range
                      </div>
                      <div class="col">
                          <app-range-datepicker 
                          #rangePicker [startDate]="advanceFilterRequest.fromDate"  
                          [endDate]="advanceFilterRequest.toDate" 
                          (startDateEmit)="advanceFilterRequest.fromDate = $event;" 
                           (endDateEmit)="advanceFilterRequest.toDate = $event"  >
                          </app-range-datepicker>
                      </div>
                  </div>
                  <div class="col d-flex align-items-center">
                    <div class="w-135px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Invoice No.
                   </div>
                   <div class="col">
                    <app-input 
                    [inputName]="'invoiceNo'" 
                    [inputType]="'text'" 
                    [inputId]="'invoiceNo'"
                    [isRequired]="false"
                    [inputValue]="advanceFilterRequest?.invoiceNo" 
                    (inputValueEmit)="advanceFilterRequest.invoiceNo = $event"
                    [disabled]="data?.modalFilterData?.elementsToHide"
                    >
                    </app-input>
                   </div>
                  </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                  Branch
                </div>
               <div class="col">
               <app-select 
               [options]="data.getAllDropdownModel?.branchDropdown" 
               [selectedValue]="advanceFilterRequest?.branchId"
               (valueChanged)="advanceFilterRequest.branchId = $event" 
               [id]="'branchId'" 
               [name]="'branchId'"
               >
           </app-select>
              </div>
              </div>
              <div class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                  Ledger
                </div>
                <div class="col">
                  <app-ng-multi-select-dropdown
                  [isShowlable]="false" 
                  [isPlusIcon]="false" 
                  [name]="'ledger'" 
                  [getAllDropdownModel]="" 
                  [selectedIds]="selectedLedgerIds"
                  [data]="data.getAllDropdownModel?.accountLedgerDropdown" 
                  [placeholder]="'Select Ledger'"
                  (onSelectItem)="advanceFilterRequest.selectedAccountLedgers= $event" 
                  [disabled]="data?.modalFilterData?.elementsToHide"
                  [id]="'accountledgerid'" 
                  >
                </app-ng-multi-select-dropdown>
              </div>
             </div>
              <div class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                  <div class="col d-flex align-items-center">
                       <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                       Ledger Type
                       </div>
                       <div class="col">
                        <app-ng-multi-select-dropdown
                        [isShowlable]="false" 
                        [isPlusIcon]="false" 
                        [name]="'ledgerType'" 
                        [getAllDropdownModel]="" 
                        [selectedIds]="selectedLegerTypesIds"
                        [data]="data.getAllDropdownModel?.ledgerTypeDropdown" 
                        [placeholder]="'Select Ledger Type'"
                        (onSelectItem)="advanceFilterRequest.selectedLedgerTypes= $event" 
                        [disabled]="data?.modalFilterData?.elementsToHide"
                        [id]="'ledgerTypeId'" 
                        >
                        </app-ng-multi-select-dropdown>
                       </div>
                  </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                <div class="col d-flex align-items-center">
                     <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Type of Voucher
                     </div>
                     <div class="col">
                      <app-ng-multi-select-dropdown
                      [isShowlable]="false" 
                      [isPlusIcon]="false" 
                      [name]="'VoucherType'" 
                      [getAllDropdownModel]="" 
                      [selectedIds]="selectedVoucherTypeIds"
                      [data]="data.getAllDropdownModel?.voucherTypeDropdown" 
                      [placeholder]="'Select Type of Voucher'"
                      (onSelectItem)="advanceFilterRequest.selectedVoucherTypes= $event" 
                      [id]="'VoucherTypeId'" 
                      >
                      </app-ng-multi-select-dropdown>
                     </div>
                </div>
              </div>
              <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                <div class="col d-flex align-items-center">
                     <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Type of Against Voucher
                     </div>
                    <div class="col">
                    <app-ng-multi-select-dropdown
                    [isShowlable]="false" 
                    [isPlusIcon]="false" 
                    [name]="'againstVoucherTypeId'" 
                    [selectedIds]="selectedagainstVoucherTypeId"
                    [data]="data.getAllDropdownModel?.voucherTypeDropdown" 
                    [placeholder]="'Select Type Of Against Voucher'"
                    (onSelectItem)="advanceFilterRequest.selectedagainstVoucherType= $event" 
                    [id]="'againstVoucherTypeId'" 
                    >
                  </app-ng-multi-select-dropdown>
                  </div>
                 </div>
              </div>     
              <div class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
                <div *ngIf="!data?.modalFilterData?.elementsToHide" class="col d-flex align-items-center">
                  <div class="col d-flex align-items-center">
                    <div class="w-155px f-14 font-semibold text-gray-color text-nowrap mb-0">
                      Against Inv.No
                    </div>
                    <div class="col">
                     <app-input 
                       [inputName]="'invoiceNo'" 
                       [inputType]="'text'" 
                       [inputId]="'invoiceNoId'"
                       [isRequired]="false"
                       [inputValue]="advanceFilterRequest?.againstInvoiceNo" 
                       (inputValueEmit)="advanceFilterRequest.againstInvoiceNo = $event"
                       >
                     </app-input>
                    </div>
                  </div>
                </div>
                <div class="col d-flex align-items-center">
                  <div class="col d-flex align-items-center">
                    <div class="w-135px f-14 font-semibold text-gray-color text-nowrap mb-0">
                       Comment
                    </div>
                    <div class="col">
                      <app-input 
                        [inputName]="'comment'" 
                        [inputType]="'text'" 
                        [inputId]="'comment'"
                        [isRequired]="false"
                        [inputValue]="advanceFilterRequest?.comment" 
                        (inputValueEmit)="advanceFilterRequest.comment = $event"
                        >
                      </app-input>
                    </div>
                  </div>
               </div>
             </div>
            </div>  
      </div>
  </form>
</app-advanced-filter-pop-up>
  

