import { StorageName } from "../../enum/common-enum";
import { AgColDefsI, AgGridI } from "../ag-grid/ag-grid.model";
import { CustomHeaderComponent } from "../../../shared/common-components/ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "../../../shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { JwellaryImageComponent } from "../../../shared/common-components/ag-grid/jwellary-image/jwellary-image.component";
import { AgGridInnerHTMLDisplayComponent } from "../../../shared/common-components/ag-grid/ag-grid-inner-htmldisplay/ag-grid-inner-htmldisplay.component";
import { TextRendererComponent } from "../ag-grid/text-renderer/text-renderer.component";
import { NumericCellRendererComponent } from "../ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { CheckboxRendererComponent } from "../ag-grid/checkbox-renderer/checkbox-renderer.component";
export class ItemListSectionModel {

}

export const ItemListImageColumn: AgColDefsI = {
  headerName: "",
  headerClass:"px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: "imageUrls",
  cellRenderer: JwellaryImageComponent,
  cellClass: "custom-column-group-cell",
  minWidth: 36,
  maxWidth: 36,
  editable:false,
  cellRendererParams:{fromDetailJson: true},
  headerComponentParams: { enableSorting: false},
  resizable: true,
  extraField:'JewelleryImg',
  cellStyle: { cursor: 'pointer' },
  suppressMovable:true,
  suppressColumnMove:true,
  lockPosition:'left',


};




export const ItemListGroupNameColumn: AgColDefsI = {
  headerName: "Group Name",
  headerComponent: CustomHeaderComponent,
  headerClass:"px-0 custom-column-group-cell f-14 text-gray-color font-semibold justify_content_end",
  field: "groupName",
  sortable: true,
  editable: false,
 // lockPosition:'left',
  cellRenderer: TextRendererComponent,
  cellClass: "custom-column-group-cell text-gray-color font-regular f-12",
  flex:1,
  // headerComponentParams:{
  //   enableSorting: false,
  //   filterValueColumnName: "invoiceNo",
  //   filterIdColumnName: "invoiceNo",
  //   isInputFilter: true,
  // },
};

export const ItemListDescriptionColumn: AgColDefsI = {
  headerName: "Description",
  headerComponent: CustomHeaderComponent,
  sortable: true,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold justify_content_start",
  field: "description",
  minWidth: 150,
  cellClass: "custom-column-group-cell text-primary text-underline",
  editable:false,
 // lockPosition:'left',
  resizable: true,
  cellRenderer: AgGridInnerHTMLDisplayComponent,
  headerComponentParams: { enableSorting: false},
  cellRendererParams :{isCursor:true},
};

export const ItemListMakingColumn: AgColDefsI = {
  headerName: "Making",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold  ag_header_end",
  field: "makingAmount",
  cellRenderer: NumericCellRendererComponent,
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 80,
  headerComponentParams: { enableSorting: false},
};
export const ItemListBarcodeNoColumn: AgColDefsI = {
  headerName: "Barcode",
  headerComponent: CustomHeaderComponent,
  cellRenderer: TextRendererComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold justify_content_end barcode",
  field: "barcodeNo",
  sortable: true,
  cellClass: " custom-column-group-cell text-end barcode",
  minWidth: 80,
  extraField:'Barcodepopup',
  cellStyle: { cursor: 'pointer' },
  headerComponentParams: { enableSorting: false},
};

export const CustomerNameColumn: AgColDefsI = {
  headerName: "Customer Name",
  headerComponent: CustomHeaderComponent,
  cellRenderer: TextRendererComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold justify_content_end",
  field: "accountLedgerName",
  sortable: true,
  cellClass: " custom-column-group-cell",
  width:200,
  minWidth: 120,
  headerComponentParams: { enableSorting: false},
};

export const ItemListGrossWtColumn: AgColDefsI = {
  headerName: "Gross Wt.",
  headerComponent: CustomHeaderComponent,
  cellRenderer: NumericCellRendererComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold justify_content_end ag_header_end",
  field: "grossWt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  width:80,
  minWidth: 80,
  headerComponentParams: { enableSorting: false},
};
export const ItemListFinalWtColumn: AgColDefsI = {
  headerName: "Final Wt.",
  headerComponent: CustomHeaderComponent,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams:{isWeight:true},
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold justify_content_end ag_header_end",
  field: "finalWt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  width:80,
  minWidth: 80,
  headerComponentParams: { enableSorting: false},
};

export const ItemListGoldPurityWtColumn: AgColDefsI = {
  headerName: "Gold Wt",
  headerComponent: CustomHeaderComponent,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams:{isWeight:true},
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold justify_content_end ag_header_end",
  field: "goldPurityWt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  width:80,
  minWidth: 80,
  headerComponentParams: { enableSorting: false},
};

export const ItemListTaxColumn: AgColDefsI = {
  headerName: "Tax",
  headerComponent: CustomHeaderComponent,
  cellRenderer: NumericCellRendererComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold  ag_header_end",
  field: "taxAmount",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 80,
  headerComponentParams: { enableSorting: false},
};

export const ItemListAmtColumn: AgColDefsI = {
  headerName: "Amount",
  headerComponent: CustomHeaderComponent,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams:{isAmt:true},
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold  ag_header_end",
  field: "amount",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 80,
  headerComponentParams: { enableSorting: false},
};

export const ItemListNetAmtColumn: AgColDefsI = {
  headerName: "Net Amt",
  headerComponent: CustomHeaderComponent,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams:{isAmt:true},
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold  ag_header_end",
  field: "netAmount",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 80,
  headerComponentParams: { enableSorting: false},
};

export const ItemListNetAmtWithTaxColumn: AgColDefsI = {
  headerName: "Net Amt With Tax",
  headerComponent: CustomHeaderComponent,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams:{isAmt:true},
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold ag_header_end",
  field: "netAmountWithTax",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 100,
  headerComponentParams: { enableSorting: false},
};

export const ItemListActionColumn: AgColDefsI = {
  headerName: "",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  field: "action",
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  // Pass gridOptions to the cell renderer component
  
  cellRendererParams: {
    editIcon: true,
    hasViewPermission : true
    // tableId: StorageName.FORMNAME_TRANSACTION_SALESINVOICE_GOLD_TOP_TABLE,
  },
  headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false},
  cellClass: "mx-0 text-end px-1",
  minWidth: 80,
  maxWidth: 80,
};


export const ItemListCheckboxColumn: AgColDefsI = {
  headerName: "Active", 
  headerCheckboxSelection: false,
  minWidth: 36,
  maxWidth: 36, 
  headerClass: "custom-column-group-cell",
  field: 'active',
  lockPosition:'left',
  editable:false,
  cellRenderer: CheckboxRendererComponent,
  cellRendererParams: { showLabel: false }, 
  cellClass:'custom-column-group-cell font-regular', 
  resizable:true,
//   cellClassRules: {
//     'non-editable-cell': params => !params.data.editable
// }
}

const ItemListColumnDef: AgColDefsI[] = [
  ItemListCheckboxColumn,
  ItemListGroupNameColumn,
  ItemListImageColumn,
  ItemListBarcodeNoColumn,
  ItemListDescriptionColumn,
  ItemListGrossWtColumn,
  ItemListFinalWtColumn,
  ItemListGoldPurityWtColumn,
  ItemListMakingColumn,
  ItemListTaxColumn,
  ItemListAmtColumn,
  ItemListNetAmtColumn,
  ItemListNetAmtWithTaxColumn,
  ItemListActionColumn,
];

export const agGridDataModelItemList: AgGridI = {
  colDefs: ItemListColumnDef,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_PRODUCTLIST_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[],
  rowHeight:28,
  // tableId : StorageName.TRANSACTION_SALEINVOICE_PRODUCTLIST_GRID
};

export class ItemSectionModel {
  storageName : string;
  showCheckboxColumn: boolean = false;
  hideColumnConditionally: boolean = true;
  showImportAndDownloadFile: boolean = true;
  setVisibility(storageName?: any)
  {
    switch (storageName) {
      case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_SALEINVOICE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_SALESQUOTATION_PRODUCTLIST_GRID
        break;
        case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_DELIVERYNOTE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_PURCHASEINVOICE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_JOBWORKINVOICE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_PURCHASERETURN_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_SALESRETURN_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESORDER:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_SALESORDER_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_PURCHASEORDER_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_ORDERS_REPAIRORDER:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.ORDERS_REPAIRORDER_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_REPAIRINVOICE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_REPAIRINVOICE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_MATERIAL_ISSUE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_MATERIAL_RECEIVE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_CREDITNOTE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
        this.showCheckboxColumn = false;
        this.storageName = StorageName.TRANSACTION_DEBITNOTE_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_INVENTORY_PHYSICALSTOCK:
        this.showCheckboxColumn = true;
        this.storageName = StorageName.INVENTORY_PHYSICALSTOCK_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_INVENTORY_JEWELRY_CATALOGUE_BILL_OF_MATERIAL:
        this.showCheckboxColumn = false;
        this.showImportAndDownloadFile = false;
        this.hideColumnConditionally = false;
        this.storageName = StorageName.INVENTORY_JEWELRY_CATALOGUE_BILL_OF_MATERIAL_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTIN:
        this.showCheckboxColumn = false
        this.storageName = StorageName.INVENTORY_CONSIGNMENT_IN_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTOUT:
        this.showCheckboxColumn = false
        this.storageName = StorageName.INVENTORY_CONSIGNMENT_OUT_PRODUCTLIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
        this.showCheckboxColumn = false
        this.storageName = StorageName.MANUFACTURER_JOBWORK_ORDER_PRODUCT_LIST_GRID
        break;
      case StorageName.FORMNAME_INVENTORY_CREATEMEMO:
        this.showCheckboxColumn = false;
        this.showImportAndDownloadFile = false;
        this.storageName = StorageName.INVENTORY_CREATEMEMO_PRODUCT_LIST_GRID
        break;
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL2:
        this.showCheckboxColumn = true;
        this.showImportAndDownloadFile = false;
        this.storageName = StorageName.TRANSACTION_STOCKJOURNAL2_PRODUCTLIST_GRID
        break;
        case StorageName.FORMNAME_PRODUCT_ADD_APPRAISAL:
          this.showCheckboxColumn = false
          this.storageName = StorageName.PRODUCT_ADD_APPRAISAL_PRODUCTLIST_GRID
          break;
    }
  }
} 
