import { Observable, tap } from 'rxjs';
import { ResponseModel } from '../../../../../shared/models/response-model.model';
import { DataService } from './../../../../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { CustomerDetails} from './customer-details.model';
import { GetAccountLedgerRequestModel } from 'src/app/components/Product/account-ledger/account-ledger.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsService {

  constructor(public service:DataService) { 
  }

  getCustomerDetailsAllDropdown(formName:string){
    let url = `api/App/Dropdown/getAllDropdowns?formName=${formName}`;
    return this.service.get(url,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getaccountledgerbyid(id:number){
    let url = `api/app/accountledger/getaccountledgerbyid?Id=${id}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }


  getDistrictDetailsByStateId(stateId:any){
    let url = `api/app/dropdown/getdistrict?StateID=${stateId}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }


  insertAccountLedger(customerDetails:CustomerDetails){
    let url = 'api/app/accountledger/create';
    const json = JSON.stringify(customerDetails);
    
    return this.service.post(url,customerDetails,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }

  updateAccountLedger(customerDetails:CustomerDetails){
    let url = 'api/app/accountledger/updateaccountledger';
    const json =JSON.stringify(customerDetails);
    
    return this.service.putWithId(url,customerDetails,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }

   // **********get all AccountLedger setting *********
   getAllAccountLedger(getAccountLedgerRequestModel : GetAccountLedgerRequestModel):Observable<ResponseModel>{
    let url = "api/app/accountledger/getallaccountledger";
    const json = JSON.stringify(getAccountLedgerRequestModel);
    return this.service.post(url, getAccountLedgerRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  

  // *************delete AccountLedger  **************
  deleteAccountLedger(id:number):Observable<ResponseModel>{
    let url = `api/app/accountledger/deleteaccountledger?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

// *************upload file AccountLedger  **************
  uploadFile(data:any):Observable<ResponseModel>{
    let url = "api/app/accountledger/uploadaccountledgerfile";
    const json = JSON.stringify(data);
    const formData = new FormData();
    formData.append('file', data);
    
    return this.service.postWithFile(url,formData, true,null,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
