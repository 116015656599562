import { Component, OnInit, ViewChild } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutDetails, User } from '../../../auth/auth.model';
import { StorageService } from '../../services/storage.service';
import { SendDataService } from '../../services/send-data.service';
import { Subscription } from 'rxjs';
import { ModalPopupSize } from '../../models/common.model';
import { KeyboardShortcutsComponent } from '../../common-components/pop-ups/keyboard-shortcuts/keyboard-shortcuts.component';
import { KeyboardShortcutsTitle } from '../../common-components/pop-ups/keyboard-shortcuts/keyboard-shortcuts.model';
import { DatePipe } from '@angular/common';
import { InputDatepickerRendererComponent } from '../../common-components/ag-grid/input-datepicker-renderer/input-datepicker-renderer.component';
import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { environment } from '../../../../environments/environment';
import { LayoutService } from '../../services/layout.service';
import { StorageName,IconHover } from '../../enum/common-enum';
import { NotificationService } from '../../services/notification.service';
import { AppInfoService } from '../../../../../app-version/app-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers:[DatePipe]
})
export class FooterComponent implements OnInit {
  public showYoutubeicon :boolean = false;
  public today: number = Date.now();
  public menuItems: Menu[];
  user:User;
  layoutDetails:LayoutDetails;
  public branchSubscription:Subscription;
  public userInfoSubscription:Subscription;
  keyboardShortcutsTitle = KeyboardShortcutsTitle;
  invoiceDate         : any;
  ipAddress : string = environment.baseUrl;
  version :string =this.appInfoService.version;
  crid: any;
  pinnedVouchers: { voucherTypeId: string; icon: string; title:string }[] = []; 
  @ViewChild(InputDatepickerRendererComponent) inputDatepickerRendererComponent: InputDatepickerRendererComponent;
  currentHoverEffect = IconHover.Pink;
  constructor( public navServices: NavService, 
    private router: Router, 
    public storageservice:StorageService,
    private sendDataService: SendDataService,
    private datePipe: DatePipe,
    public dataService:  DataService,
    public layoutService  : LayoutService,
    public notificationService: NotificationService,
    private dateService: DateService,
    private appInfoService: AppInfoService    
  ) { 

    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {

        if (event instanceof NavigationEnd) {
          if (event.url === '/transaction/saleinvoice' || event.url === '/orders/sale-order' || event.url === '/orders/ordertracking' || event.url === '/orders/orderlist' || event.url === '/orders/cart' || event.url === '/inventory/jewelry-Catalogue' || event.url === '/inventory/jewelry-Catalogue-Details' ) {
            this.showYoutubeicon = true;
          } else {
            this.showYoutubeicon = false;
          }
        } 
      });
    });

    this.crid = this.storageservice.retrieve(StorageName.COMPANY_REGISTRATION_ID);
  }

  ngOnInit(): void {
    this.branchSubscription = this.sendDataService.ShowUserDetails.subscribe((res=>{
      if(res){
        this.setDetails()
      }
     }))
    this.userInfoSubscription = this.sendDataService.userInfo$.subscribe((data) => {
      if (data) {
        this.user = data;
        this.pinnedVouchers = this.getPinnedVouchers(this.user?.quickAccessData || "[]");
      }
    });
    this.setDetails();
    this.pinnedVouchers = this.getPinnedVouchers(this.user?.quickAccessData || "[]");
  }

  setDetails(){
    this.user = this.storageservice.retrieve(StorageName.USER_INFO);
    this.layoutDetails =this.storageservice.retrieve('layoutDetails');

  }

  openReferAndEarnPopup(){
    this.notificationService.openModalPopup(KeyboardShortcutsComponent  ,undefined,'' ,ModalPopupSize.LG,"refer_And_scan-popup" , false , true).then(
      (result)=>{
        if(result){

        }
      }
    )
  }
  // openReferAndEarnPopup(){
  //   const modalOption: NgbModalOptions = {
  //     centered: true,
  //     backdrop: "static",
  //     keyboard: false,
  //     size: "md",
  //     windowClass:"refer_And_scan-popup"
  //   } 
  
  //   const madalRef = this.notificationService.open(ReferAndEarnComponent, modalOption);
      
  // }

  openKeybordShortcutkeyPopup(modalTitle?:string){
    var data = {
      modalTitle : modalTitle,
    }
    this.notificationService.openModalPopup(KeyboardShortcutsComponent , data ,undefined ,ModalPopupSize.LG,"KeybordShortcut-popup").then(
      (result)=>{
        if(result){

        }
      }
    )
  }
  
  selectedRangeDates(event?: any) {
    this.invoiceDate = event;
    const date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.storageservice.store('selectedCommonDate',date);
    this.dateService.setSelectedDate(event);
    // this.inputDatepickerRendererComponent.getStoreddate();
  } 
  onYoutube(){
    const url = "https://www.youtube.com/@JewelSteps";
    window.open(url, "_blank");
  }

  onWhatsAppSupportClick() {
    const phoneNumber = '971509542169';
    const message = 'Hello! I would like to request more information about the JewelSteps Jewellery ERP Software.';
    const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  getPinnedVouchers(voucherTypeIds: string): { voucherTypeId: string; icon: string; title: string }[] {
    const parsedIds: number[] = JSON.parse(voucherTypeIds);
    const findIcons = (items: any[]): { voucherTypeId: string; icon: string; title: string }[] => {
      const pinnedVouchers: { voucherTypeId: string; icon: string; title: string }[] = [];
      for (const item of items) {
        if (parsedIds.includes(item.voucherTypeId)) {
          pinnedVouchers.push({
            voucherTypeId: item.voucherTypeId,
            icon: item.icon,
            title: item.voucherTypeName
          });
        }
        if (item.children && item.children.length > 0) {
          pinnedVouchers.push(...findIcons(item.children));
        }
      }
      return pinnedVouchers;
    };
    return findIcons(this.menuItems);
  }
  

  navigateToInvoice(voucherTypeId: number): void {
    const path = this.findPathByVoucherTypeId(voucherTypeId, this.menuItems);
    if (path) {
      this.router.navigate([path]);
    } else {
      console.warn(`Path not found for voucherTypeId: ${voucherTypeId}`);
    }
  }

  findPathByVoucherTypeId(voucherTypeId: number, items: any[]): string | null {
    for (const item of items) {
      if (item.voucherTypeId === voucherTypeId) {
        return item.path; 
      }
      if (item.children && item.children.length > 0) {
        const childPath = this.findPathByVoucherTypeId(voucherTypeId, item.children);
        if (childPath) {
          return childPath; 
        }
      }
    }
    return null; 
  }
  
  isActive(voucherTypeId: string): boolean {
    const path = this.findPathByVoucherTypeId(+voucherTypeId, this.menuItems);
    return this.router.url === path; 
  }
  
  ngOnDestroy(){
    this.branchSubscription?.unsubscribe();
    this.userInfoSubscription?.unsubscribe();
  }

}
