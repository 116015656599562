
<div class="container-fluid d-flex ps-4 flex-column pt-3 h-100">
  <form class="theme-form" ngNativeValidate #CompanyForm="ngForm">
    <div class="row">
      <div class="col-2">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <span class="f-14 font-semibold text-light text-nowrap mb-0">Add Company Logo</span>
          </div>
          <div class="col mt-2">
              <div class="upload-image">
                      <img alt="" [src]="isBase64Image(usermodel.companyLogo)" />
              </div>
              <div class="d-flex mt-2">
                  <label class="common_button white_button px-2 mb-0  cursorPointer d-flex justify-content-center align-items-center"  (click)="clearImage()">Clear</label>
                  <label for="upload_logo"
                    class="svg-hover common_button cursorPointer ms-2  blue_button mb-0  d-flex justify-content-center align-items-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.835 18.417">
                        <g id="Group_3267" data-name="Group 3267" transform="translate(0 3)">
                          <path id="Path_1893" data-name="Path 1893"
                            d="M-1425.71-48.6a5.207,5.207,0,0,1,.239-.683,2.276,2.276,0,0,1,2.126-1.345c1-.01,1.993,0,2.989,0h.218c.044-.213.072-.414.129-.606a1.762,1.762,0,0,1,1.541-1.226c.507-.028,1.015-.03,1.523-.031q2.237-.005,4.474,0a1.944,1.944,0,0,1,1.253.391,1.75,1.75,0,0,1,.693,1.238c.007.067.02.134.03.2,0,.005.008.01.027.033h.327q1.413,0,2.827,0a2.347,2.347,0,0,1,2.406,2.011,2.443,2.443,0,0,1,.021.425c0,3.22-.018,6.441.012,9.661a2.43,2.43,0,0,1-2.454,2.454c-2.2-.015-4.406,0-6.609,0q-4.657,0-9.314,0a2.4,2.4,0,0,1-2.343-1.615c-.045-.133-.077-.271-.115-.407ZM-1415.3-37.14q3.944,0,7.889,0a2.077,2.077,0,0,0,.5-.045,1.334,1.334,0,0,0,.992-1.427q0-4.759,0-9.517a1.912,1.912,0,0,0-.044-.463,1.29,1.29,0,0,0-1.349-.976c-1.22,0-2.44,0-3.66,0a.535.535,0,0,1-.583-.578c0-.149,0-.3,0-.447a.767.767,0,0,0-.86-.848q-1.4,0-2.806,0c-1,0-2.006,0-3.009,0a.73.73,0,0,0-.778.756,4.079,4.079,0,0,0,0,.447.606.606,0,0,1-.666.678c-1.186-.02-2.372-.009-3.558,0a1.929,1.929,0,0,0-.482.056,1.263,1.263,0,0,0-.94,1.29q0,3.559,0,7.118,0,1.322,0,2.644a1.278,1.278,0,0,0,.524,1.07,1.474,1.474,0,0,0,.941.253Z"
                            transform="translate(1425.71 52.492)" fill="#5843cf" />
                          <path id="Path_1894" data-name="Path 1894"
                            d="M-1331.484,16.319a4.891,4.891,0,0,1,4.879,4.872,4.894,4.894,0,0,1-4.873,4.879,4.895,4.895,0,0,1-4.878-4.875A4.887,4.887,0,0,1-1331.484,16.319Zm3.793,4.924a3.782,3.782,0,0,0-3.781-3.83,3.787,3.787,0,0,0-3.8,3.791,3.766,3.766,0,0,0,3.782,3.8A3.766,3.766,0,0,0-1327.69,21.243Z"
                            transform="translate(1341.893 -12.055)" fill="#5843cf" />
                          <path id="Path_1895" data-name="Path 1895"
                            d="M-1157.769,17.058a.96.96,0,0,1-.973.957.981.981,0,0,1-.97-.969.982.982,0,0,1,.992-.967A.961.961,0,0,1-1157.769,17.058Z"
                            transform="translate(1176.195 -11.83)" fill="#5843cf" />
                        </g>
                      </svg>
                    </span>
                    <span>Upload</span>
                  </label>
                  <input type="file" id="upload_logo" class="invisible d-none" (change)="onChangeImage($event)">
              </div>
     
          </div>
        </div>

      </div>
      <div class="col-5 ">
        <div class="row">
          <div class="col-12  pb-3">
            <div class="row">
                <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                  <span class="f-14 font-semibold text-light text-nowrap mb-0">Business Name
                  <span class="text-color-danger">*</span>
                </span>
                </div>
                <div class="col ">
                  <app-input
                    onlyAlphabetsAndNumbers
                    [inputName]="'shopName'"
                    [inputType]="'text'"
                    [inputId]="'shopName'"
                    [inputValue]="usermodel.shopName"
                    (inputValueEmit)="usermodel.shopName = $event ;!fromAddCompany? getStartingCharacters(usermodel?.shopName):'';!fromAddCompany?checkIsCridAvailable(usermodel?.crid):''"
                    [isRequired]="true"
                    (keydown.enter)="onKeydown($event,fromAddCompany?'businessLicenceNoId' : 'crid')"
                    [ngClass]="{'input-border-red' : ((CompanyForm?.form?.controls?.shopName?.touched || CompanyForm?.form?.controls?.shopName?.dirty) && CompanyForm?.form?.controls?.shopName?.invalid)}">
                  </app-input>
                </div>
            </div>
          </div>
          <!-- end shop name -->
          <div class="col-12 pb-3">
            <div class="row">
                <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                  <span class="f-14 font-semibold text-light text-nowrap mb-0">CRID
                  <span class="text-color-danger">*</span>
                </span>
                </div>
                <div class="col" [ngClass]="{'pe-none':fromAddCompany}">
                  <app-input
                    onlyAlphabetsAndNumbers
                    [inputName]="'crid'"
                    [inputType]="'text'"
                    [inputId]="'crid'"
                    [inputValue]="usermodel.crid"
                    (inputValueEmit)="usermodel.crid = $event;checkIsCridAvailable(usermodel?.crid)"
                    [isRequired]="true"
                    [maxLength]="8"
                    [minLength]="6"
                    (keydown.enter)="onKeydown($event, 'businessLicenceNoId')"
                    [ngClass]="{'input-border-red' : ((CompanyForm?.form?.controls?.crid?.touched || CompanyForm?.form?.controls?.crid?.dirty) && CompanyForm?.form?.controls?.crid?.invalid)}">
                  </app-input>
                  <span *ngIf="(!isCridAvailabilityCheck.isCridAvailable && isCridAvailabilityCheck?.count > 0)" class="hintMessage">That ID is already is Taken.Please Try Another</span>
                  <span *ngIf="(isCridAvailabilityCheck?.count > 0)"  class="closeAndRightIcon position-absolute d-flex h-100 align-items-center end-0 pe-3 pointer">
                    <svg *ngIf="!isCridAvailabilityCheck?.isCridAvailable" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                        <g id="Group_42950" data-name="Group 42950" transform="translate(-1154 -243)">
                          <circle id="Ellipse_684" data-name="Ellipse 684" cx="11" cy="11" r="11" transform="translate(1154 243)" fill="#f92b35"/>
                          <path id="Union_238" data-name="Union 238" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(1160.001 248.999)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>
                      <svg *ngIf="isCridAvailabilityCheck?.isCridAvailable" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                        <g id="Group_42949" data-name="Group 42949" transform="translate(-1154 -243)">
                          <circle id="Ellipse_684" data-name="Ellipse 684" cx="11" cy="11" r="11" transform="translate(1154 243)" fill="#1ede58"/>
                          <path id="Path_1214" data-name="Path 1214" d="M654.936,654.014l4.483,4.483,3.095-3.094,4.547-4.547" transform="translate(504.001 -400.676)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>
                </span>
                </div>
            </div>
          </div>
          <!-- end shop name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Business License No
                </span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'businessLicenceNo'"
                  [inputType]="'text'"
                  [inputId]="'businessLicenceNoId'"
                  [inputValue]="usermodel.businessLicenceNo"
                  (inputValueEmit)="usermodel.businessLicenceNo = $event"
                  (keydown.enter)="onKeydown($event, 'panCard')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end business l no name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">National Id/Driving <br>Lisence<br/></span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'panCard'"
                  [inputType]="'text'"
                  [inputId]="'panCard'"
                  [inputValue]="usermodel.panCard"
                  (inputValueEmit)="usermodel.panCard = $event"
                  (keydown.enter)="onKeydown($event, 'registrationDateId')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end pancard dob -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Registration Date
                </span>
              </div>
              <!-- <div class="col ">
                <app-input
                  [inputName]="'registrationDate'"
                  [inputType]="'date'"
                  [inputId]="'registrationDateId'"
                  [inputValue]="usermodel?.registrationDate"
                  (inputValueEmit)="selectDate($event)"
                  [isRequired]="true"
                  (keydown.enter)="onKeydown($event, 'emailId')">
                </app-input>
              </div> -->
              <div class="col">
                  <app-input-datepicker-renderer 
                  [id]="'registrationDateId'" 
                  (inputValueEmit)="selectDate($event)"  
                  (keydown.enter)="onKeydown($event, 'emailId')"></app-input-datepicker-renderer>
              </div>
            </div>
          </div>
          <!-- end registrationDate  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">Email
                <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'businessEmail'"
                  [inputType]="'email'"
                  [inputId]="'emailId'"
                  [inputValue]="usermodel?.businessEmail"
                  (inputValueEmit)="usermodel.businessEmail = $event"
                  [isRequired]="true"
                  [isEmailPattern]="true"
                  [ngClass]="{'input-border-red' : ((CompanyForm?.form?.controls?.businessEmail?.touched || CompanyForm?.form?.controls?.businessEmail?.dirty) && CompanyForm?.form?.controls?.businessEmail?.invalid)}"
                  (keydown.enter)="onKeydown($event, 'officeNoId')">
                </app-input>
                <div *ngIf="((CompanyForm?.form?.controls?.businessEmail?.touched || CompanyForm?.form?.controls?.businessEmail?.dirty) && CompanyForm?.form?.controls?.businessEmail?.invalid)" class="text-yellow font-regular f-11 position-absolute">Please enter a valid email address</div>
              </div>
            </div>
          </div>
          <!-- end email -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Office Number
                </span>
              </div>
              <div class="col ">
                <app-input 
                onlyNumbers
                [inputName]="'officeNo'" 
                [inputType]="'text'" 
                [inputId]="'officeNoId'"
                [inputValue]="usermodel.officeNo" 
                (inputValueEmit)="usermodel.officeNo = $event" 
                (keydown.enter)="onKeydown($event, 'addressLine1Id')"
                >
                </app-input>
              </div>
            </div>
          </div>
          <!-- end office no -->
        </div>
        <!-- end row -->
      </div>
      <!-- end first col-5 -->
      <div class="col-5">
        <div class="row">
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">Address Line 1
                </span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'addressLine1'"
                  [inputType]="'text'"
                  [inputId]="'addressLine1Id'"
                  [inputValue]="usermodel.addressLine1"
                  (inputValueEmit)="usermodel.addressLine1 = $event"
                  (keydown.enter)="onKeydown($event, 'addressLine2Id')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end addressLine1 name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Address Line 2
                </span>
              </div>
              <div class="col ">
                <app-input
                [inputName]="'addressLine2'"
                [inputType]="'text'"
                [inputId]="'addressLine2Id'"
                [inputValue]="usermodel.addressLine2"
                (inputValueEmit)="usermodel.addressLine2 = $event"
                (keydown.enter)="onKeydown($event, 'country')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end addressLine2 name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Country
                </span>
              </div>
              <div class="col ">
                <!-- <app-input
                [inputName]="'country'"
                [inputType]="'text'"
                [inputId]="'country'"
                [inputValue]="usermodel.country"
                (inputValueEmit)="usermodel.country = $event"
                [isRequired]="true"
                (keydown.enter)="onKeydown($event, 'state')"
                >
                </app-input> -->
                <app-select
                [options]="getAllDropdownModel?.countryDropdown"
                [selectedValue]="usermodel?.countryId"
                (valueChanged)="usermodel.countryId= $event; onChangeCountryName($event)"
                [id]="'country'"
                [name]="'country'"
                [isDisabled]="false"
                [selectClass]="'white-color'"
                (keydown.enter)="onKeydown($event, 'state')">
                </app-select>
              </div>
            </div>
          </div>
          <!-- end country  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  State/Province 
                  <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col ">
                <app-select
                [options]="getAllDropdownModel?.stateList"
                [selectedValue]="usermodel?.stateId"
                (valueChanged)="usermodel.stateId= $event; getStateName($event)"
                [id]="'state'"
                [name]="'state'"
                [isDisabled]="false"
                [isRequired]="true"
                [selectClass]="'white-color'"
                [ngClass]="{'select-dropdown-border-red' : ((CompanyForm?.form?.controls?.state?.touched || CompanyForm?.form?.controls?.state?.dirty) && CompanyForm?.form?.controls?.state?.invalid)}"
                (keydown.enter)="onKeydown($event, 'pinCodeId')">
                </app-select>
              </div>
            </div>
          </div>
          <!-- end State  -->
          <!-- <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  District/Area 
                </span>
              </div>
              <div class="col ">
                <app-select
                [options]="districList"
                [selectedValue]="usermodel.districtId"
                (valueChanged)="usermodel.districtId= $event; getDistricName($event)"
                [id]="'district'"
                [name]="'district'"
                [isDisabled]="false"
                [isRequired]="true">
                </app-select>
              </div>
            </div>
          </div> -->
          <!-- end district  -->
          <!-- <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Taluka 
                </span>
              </div>
              <div class="col ">
                <app-input
                [inputName]="'taluka'"
                [inputType]="'text'"
                [inputId]="'taluka'"
                [inputValue]="usermodel.taluka"
                (inputValueEmit)="usermodel.taluka = $event"
                [isRequired]="true"
                (keydown.enter)="onKeydown($event, 'pinCodeId')">
                </app-input>
              </div>
            </div>
          </div> -->
          <!-- end taluka  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Zip Code
                </span>
              </div>
              <div class="col ">
                <app-input
                [isOnlyAlphabetsAndNumbers]="true"
                [inputName]="'pinCode'"
                [inputType]="'text'"
                [inputId]="'pinCodeId'"
                [inputValue]="usermodel.pinCode"
                (inputValueEmit)="usermodel.pinCode = $event"
                (keydown.enter)="onKeydown($event, 'gstTrn')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end pinCode  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  GST/TRN/HST                
                </span>
              </div>
              <div class="col ">
                <app-input
                [inputName]="'gstTrn'"
                [inputType]="'text'"
                [inputId]="'gstTrn'"
                [inputValue]="usermodel.gstTrn"
                (inputValueEmit)="usermodel.gstTrn = $event"
                >
                </app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <!-- end second -->
    </div>
  </form>
</div>
