import { Component, Input, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridI, PaginationModel } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { AgGridLedgerDetailsGrid } from '../../financial-statement.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { FinancialStatementService } from '../../financial-statement.service';
import { AgGridComponent } from 'src/app/shared/common-components/ag-grid/ag-grid.component';
import { IconHover } from '../../../../shared/enum/common-enum';

@Component({
  selector: 'app-ledger-details',
  templateUrl: './ledger-details.component.html',
  styleUrls: ['./ledger-details.component.scss']
})
export class LedgerDetailsComponent extends CommonService{
  ledgerDetailsGridApi          : any;
  agGridledgerDetailsGrid       : AgGridI;
  agGridledgerDetails           : any[] = [];
  currentHoverEffect = IconHover.Pink;
  pagination = new PaginationModel();
  @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>
  fromDate
  toDate
  data:any={};
  apiRequestModel : any = {};
  apiRequestStorageName = StorageName.APIREQUEST_FINANCIAL_STATEMENT_LEDGER_DETAILS_GRID;

  constructor(public activeModal: NgbActiveModal, public renderer : Renderer2, public storageService:StorageService
    ,public commonFilterService:CommonFilterService,
    public financialStatementService:FinancialStatementService

  ) {
    super(renderer);
    const financialYearDates = this.storageService.retrieve('financialYear');
    this.apiRequestModel.fromDate = financialYearDates?.financialYearStartDate;
    this.apiRequestModel.toDate = financialYearDates?.financialYearEndDate;
    this.storageService.store(this.apiRequestStorageName, this.apiRequestModel);
  }

  ngOnInit(): void {
    this.agGridledgerDetailsGrid  = AgGridLedgerDetailsGrid;
    this.agGridledgerDetailsGrid.showPagination =false;
  }

  onGridReady(event ){
    this.ledgerDetailsGridApi  = event; 
    this.GetallLedgerDetailsReport();
  }
  
  GetallLedgerDetailsReport(){
    this.showLoading(this.ledgerDetailsGridApi);
    this.apiRequestModel = this.storageService.retrieve(this.apiRequestStorageName);
    this.financialStatementService.getallLedgerDetails(this.apiRequestModel).subscribe({
      next: (res) => {
        this.commonFilterService.processServersideApiResponse(res,this.grids.first,this.ledgerDetailsGridApi,this.pagination,this.apiRequestModel.getAll);
      },
      error: (err) => {
          this.hideLoading(this.ledgerDetailsGridApi);          
          this.ledgerDetailsGridApi?.api?.setRowData([]);
      }
    })
  }

  apply(){
    
  }
}
