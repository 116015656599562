<div class="d-flex  download_icon-hover commonInputContainer align-items-center w-100 position-relative">
    <button [class]="buttonClass +' Icon'" [style.width.px]="labelName ? 90 : null">
        
        <ng-container *ngIf="showNameIcon">
            <app-svg-icon name="name" [ngbTooltip]="ngbToolTipContent"></app-svg-icon>
            <span *ngIf="isRequired" class="text-color-danger w-auto">*</span>
            <!-- <svg *ngIf="placeHolderRequired" class="mb-3" width="6" height="6" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5568 6.9091L2.66475 4.51137L0.642023 5.81251L0.0227051 4.73297L2.17043 3.63637L0.0227051 2.53978L0.642023 1.46024L2.66475 2.76137L2.5568 0.363647H3.78975L3.6818 2.76137L5.70452 1.46024L6.32384 2.53978L4.17611 3.63637L6.32384 4.73297L5.70452 5.81251L3.6818 4.51137L3.78975 6.9091H2.5568Z" fill="#F35237"/></svg> -->
        </ng-container>
        <ng-container *ngIf="showmobileIcon">
            <app-svg-icon name="mobile" ngbTooltip="Mobile"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showpanIcon">
            <app-svg-icon name="pan" ngbTooltip="Pan Card"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showadharIcon">
            <app-svg-icon name="adhar" ngbTooltip="Aadhar Card"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showgstIcon">
            <app-svg-icon name="gst" ngbTooltip="GST"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showmailIcon">
            <app-svg-icon name="mail"ngbTooltip="Mail ID"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showphoneIcon">
            <app-svg-icon name="phone" ngbTooltip="Phone"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showdayIcon">
            <app-svg-icon name="day"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showdateIcon">
            <app-svg-icon name="date"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showregistrationIcon">
            <app-svg-icon name="registration"></app-svg-icon>
        </ng-container>
        <ng-container *ngIf="showExportAsIcon">
            <app-svg-icon name="pdfAndxls"></app-svg-icon>
        </ng-container>

        <span *ngIf="labelName">{{InputLabel}}</span>

    </button>  

    <input 
    appFocus="{{ enableFocus }}" 
    autocomplete="off"
    [type]="inputType" 
    [id]="inputId" 
    [name]="inputName" 
    [placeholder]="inputPlaceholder"
    [maxlength]="inputMaxlength"
    [(ngModel)]="inputValue" 
    [class]="inputClass +' form-control'"
    (ngModelChange)="inputValueEmit.emit(inputValue)"
    [required]="isRequired"
    [disabled]="disabled"
    [readOnly]="readOnly"
    [pattern]="isEmailPattern?emailPattern:null"
    [email]="inputType == 'email' ? true :false" 
    *ngIf="(decimalPlaces == 0 && !isOnlyNumbers && !isOnlyAlphabetsAndNumbers && !isOnlyAlphabets)"
    >
    <input 
        appFocus="{{ enableFocus }}" 
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [email]="inputType == 'email' ? true :false" 
        onlyNumbers *ngIf="isOnlyNumbers"
        [allowDecimal]="isAllowDecimal" 
        >
    <input
        appFocus="{{ enableFocus }}"  
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [email]="inputType == 'email' ? true :false" 
        onlyAlphabetsAndNumbers *ngIf="isOnlyAlphabetsAndNumbers"
        >
    <input 
        appFocus="{{ enableFocus }}" 
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="' '"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [email]="inputType == 'email' ? true :false" 
        onlyAlphabets *ngIf="isOnlyAlphabets"
        [allowDecimal]="isAllowDecimal" 
        >
        <!-- <label [for]="inputId" *ngIf="placeHolderRequired" class="col-form-label"><span><svg class="mx-5 mb-3" width="6" height="6" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5568 6.9091L2.66475 4.51137L0.642023 5.81251L0.0227051 4.73297L2.17043 3.63637L0.0227051 2.53978L0.642023 1.46024L2.66475 2.76137L2.5568 0.363647H3.78975L3.6818 2.76137L5.70452 1.46024L6.32384 2.53978L4.17611 3.63637L6.32384 4.73297L5.70452 5.81251L3.6818 4.51137L3.78975 6.9091H2.5568Z" fill="#F35237"/></svg></span></label> -->
    <input 
        appFocus="{{ enableFocus }}" 
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [email]="inputType == 'email' ? true :false" 
        appNumberWithDecimal *ngIf="decimalPlaces > 0"
        [decimalPlaces]="decimalPlaces"
        >

        
</div>


