import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-custom-apx-chart',
  templateUrl: './custom-apx-chart.component.html',
  styleUrls: ['./custom-apx-chart.component.scss']
})
export class CustomApxChartComponent extends CommonService implements OnInit{
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ApexOptions>;
  @Input() color: string;
  @Input() totalCustomer: string;
  @Input() ammount: string;
  @Input() series: number = 77;
  @Input() rate: string = "30%";
  @Input() backGroundColor: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() colorCode : string;
  amountColor: string;

  constructor(public renderer: Renderer2,) {
    super(renderer);
  }

  ngOnInit(): void {
    this.isMacOS()
    this.chartOption()
    this.setAmountColor()
  }
  isMacOS() {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  }
chartOption(){
  this.chartOptions = {
 
    series: [this.series],
         chart: {
           height:this.isMacOS() ? '80' : '130',
           width:this.isMacOS() ? '70' : '100',
           type: "radialBar",
           toolbar: {
             show: false
           }
         },
         plotOptions: {
           radialBar: {
             startAngle: -200,
             endAngle: 190,
             hollow: {
               margin: 15,
               size: '80%',
               image: `./assets/images/dashboard/${this.text??'hand_blue'}.svg`,
               imageWidth:this.isMacOS() ? 130 : 110,
               imageHeight: this.isMacOS() ? 130 : 110,
               imageClipped: false,
               position: "front",
             },
             track: {
              background: this.colorCode ??  '#4F39CD',
               strokeWidth: "25%",
               margin: 0, 
               dropShadow: {
                 enabled: false,
                 top: -3,
                 left: 0,
                 blur: 4,
                 opacity: 0.35
               }
             },
   
             dataLabels: {
               show: false,
   
               value: {
                 formatter: function(val) {
                   return parseInt(val.toString(), 10).toString();
                   
                 },
                 color: "#111",
                 fontSize: "36px",
                 show: true
               }
             }
           }
         },
         title:{
           text: this.text,
         },
         colors: [
          this.colorCode ??  '#4F39CD'
        ],
         fill: {
         },
         stroke: {
           lineCap: "round",
           width: -8,
         },
     
  };
 }

 setAmountColor() {
  this.amountColor = this.colorCode ??  '#4F39CD';
}

}
