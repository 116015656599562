import { Component, Input, OnInit, Output, Renderer2 ,EventEmitter, ViewChild, ElementRef, AfterViewInit} from "@angular/core";
import { EditProfileModel, PersonalDetailsModel } from "../edit-profile.model";
import { CommonService } from "src/app/shared/services/common.service";
import { EditProfileService } from './../edit-profile.service';
import { ResponseModel } from "src/app/shared/models/response-model.model";
import { catchError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { StorageService } from "src/app/shared/services/storage.service";
import { NgForm } from "@angular/forms";
import { NotificationService } from "src/app/shared/services/notification.service";

@Component({
  selector: "app-personal-details",
  templateUrl: "./personal-details.component.html",
  styleUrls: ["./personal-details.component.scss"],
})
export class PersonalDetailsComponent extends CommonService implements OnInit ,AfterViewInit {
  @Input() personalDetails = new PersonalDetailsModel();
  @Output() refreshDetails = new EventEmitter();
  @ViewChild("personalDetailsForm") personalDetailsForm!: NgForm;
  @ViewChild('submitButton') submitButton: ElementRef;
  constructor(
    public renderer: Renderer2,
    public editProfileService : EditProfileService,
    public toaster          : ToastrService,
    public storageService   :StorageService,
    public notificationService: NotificationService,
    ) {
    super(renderer);
  }
  ngAfterViewInit(): void {
  
  }
  ngOnInit(): void {}


  updatePersonalDetailsData(){
    if(this.submitButton.nativeElement){
      this.submitButton?.nativeElement?.click();
    }
  }

  UpdatepersonalDetails() {
    if(this.personalDetailsForm?.valid){
      this.personalDetails.contactNo = this.personalDetails.contactNo==''?null:this.personalDetails.contactNo
    this.editProfileService.updatePersonalDetails(this.personalDetails)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess) {
          this.toaster.success(res.message);
          // const user = this.storageService.retrieve('user');
          // user.fullName = res?.response?.fullName;
          // user.contactNo = res?.response?.contactNo;
          // user.emailId = res?.response?.emailId;
          // user.userProfileUrl = res?.response?.userProfileUrl;
          // this.storageService.store('user',user);
          // this.editProfileService.updateUser.next(undefined)
          this.personalDetails = new PersonalDetailsModel();
          this.refreshDetails.emit();
        } else {
          this.toaster.error(res.errors[0]);
          this.notificationService.errorPopup(res?.errors[0]);
        }
      });
  } else {
    this.markFormAsTouchedAndDirty(this.personalDetailsForm);
  }
}
}
