<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <a routerLink="/">
    <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
    <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
  </a>
  <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
  <div class="toggle-sidebar" (click)="sidebarToggle()">
    <i data-feather="grid" class="status_toggle middle sidebar-toggle"></i>
    <!-- <app-feather-icons icon="'grid'" class="status_toggle middle sidebar-toggle"></app-feather-icons> -->
  </div>
</div>
<div class="logo-icon-wrapper">
  <a href="javascript:void(0)">
    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
  </a>
</div>
<nav class="sidebar-main mx-3">
  <!-- <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()"> -->
    <!-- <i data-feather="arrow-left" ></i> -->
    <!-- <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div> -->



  <div  id="sidebar-menu" [ngStyle]="{ marginLeft: this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px' }">
    <ul class="sidebar-links custom-scrollbar">
      <div class="col-12 d-flex align-items-center justify-content-end">
        <div class="col-lg-8 col-md-6 d-flex {{dropdownShow && isDashboardActive() ?'col-xl-10':'col-xl-9'}}">
          <div
          class="leftarrow pe-2"
          id="leftarrow"
          [class.d-none]="leftArrowNone"
          (click)="scrollLeft()"
        >
          <app-svg-icon class="cursorPointer" [name]="'polygon1'"></app-svg-icon>
          </div>    
          <div #widgetsContent class="simplebar-wrapper sidebar-wrap">
            <div class="simplebar-mask">
              <div class="simplebar-offset">
                <div class="simplebar-content-wrapper">
                  <div class="simplebar-content">
                    <li class="back-btn">
                      <a href="javascript:void(0)">
                        <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
                      </a>
                      <div class="mobile-back text-end" (click)="sidebarToggle()">
                        <span>Back</span>
                        <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                      </div>
                    </li>
  
                    <li (click)="onItemClick($event,menuItem)" [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'" *ngFor="let menuItem of menuItems" [ngClass]="{ active: menuItem.active }">
                      <div *ngIf="menuItem.headTitle1">
                        <h6 class="lan-1">{{ menuItem.headTitle1 | translate }}</h6>
                      </div>
  
                      <label class="badge badge-{{ menuItem.badgeType }}" *ngIf="menuItem.badgeType && menuItem.badgeValue">{{ menuItem.badgeValue }}</label>
                      <!-- Sub -->
                      <a 
                      draggable="false"
                      href="javascript:void(0)"
                      class="sidebar-link sidebar-title" 
                      [class.link-nav]="!menuItem.children" 
                      *ngIf="menuItem.type === 'sub'" 
                      [ngClass]="{ active: menuItem.active }" 
                      >
                      <!-- (click)="toggletNavActive(menuItem)" -->
                        <!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
                        <app-feather-icons [icon]="menuItem.icon" [ngClass]="currentHoverEffect"></app-feather-icons>
                        <span>{{ menuItem.title | translate}}</span>
                        <!-- <div class="according-menu">
                          <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
                        </div> -->
                      </a>
                      <!-- Link -->
                      <a 
                      [routerLink]="!menuItem.type ? null : [menuItem.path]" 
                      routerLinkActive="active" class="sidebar-link sidebar-title" 
                      [class.link-nav]="!menuItem.children" [ngClass]="{ active: menuItem.active }" 
                      *ngIf="menuItem.type === 'link'">
                        <!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
                        <span>{{ menuItem.title | translate}}</span>
                        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
                        <!-- <div class="according-menu">
                          <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
                        </div> -->
                      </a>
                      <!-- External Link -->
                      <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children" [ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'extLink'">
                        <!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
                        <span>{{ menuItem.title | translate}}</span> 
                        <!-- <div class="according-menu">
                          <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
                        </div> -->
                        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
                      </a>
                      <!-- External Tab Link -->
                      <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children" [ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'extTabLink'">
                        <!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
                        <span>{{ menuItem.title | translate}}</span>
                        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
                        <!-- <div class="according-menu">
                          <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
                        </div> -->
                      </a>
                      <!-- 2nd Level Menu -->
                      <ul class="sidebar-submenu" [ngClass]="{ active: menuItem.active }" [ngClass]="{ 'menu-open': menuItem.active, 'menu-close': !menuItem.active }" *ngIf="menuItem.children" [style.display]="menuItem.active ? 'block' : 'none'">
                        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{ active: childrenItem.active,'d-none':childrenItem?.isHidden }" >
                          <!-- Sub -->
                          <a class="submenu-title" href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
                            <span> {{ childrenItem.title | translate }}</span>
                            <div class="according-menu">
                              <i class="fa fa-angle-{{ childrenItem.active ? 'down' : 'right' }} pull-right" *ngIf="childrenItem.children"></i>
                            </div>
                          </a>
                          <!-- Link -->
                          <a (click)="onClick($event,true)" class="submenu-title" [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'d-none':childrenItem?.isHidden}">
                            <app-feather-icons [icon]="childrenItem.icon" [ngClass]="currentHoverEffect"></app-feather-icons>
                            {{ childrenItem.title | translate }}
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                          </a>
                          <!-- External Link -->
                          <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                            {{ childrenItem.title | translate }}
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                          </a>
                          <!-- External Tab Link -->
                          <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink'">
                            <span>{{ childrenItem.title | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                          </a>
                          <!-- 3rd Level Menu -->
                          <ul class="nav-sub-childmenu submenu-content" *ngIf="childrenItem.children" [ngClass]="{ active: childrenItem.active }" [ngClass]="{ 'menu-open': childrenItem.active, 'menu-close': !childrenItem.active }" [style.display]="childrenItem.active ? 'block' : 'none'">
                            <li *ngFor="let childrenSubItem of childrenItem.children" [ngClass]="{ active: childrenSubItem.active }">
                              <!-- Link -->
                              <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]" *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                                {{ childrenSubItem.title | translate }}
                              </a>
                              <!-- External Link -->
                              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                                {{ childrenSubItem.title | translate }}
                              </a>
                              <!-- External Tab Link -->
                              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
                                {{ childrenSubItem.title | translate }}
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <div class="col-2 pe-3"*ngIf="dropdownShow && isDashboardActive()">
                      <app-select [options]="filteredOptions" 
                      [defaultOption]="true"
                      [isDisabled]="false"
                      [selectedValue]="selectedValue"
                      (valueChanged)="updateRecentlyUsedDashboard($event)"
                      [id]="'dashboard'" 
                      [name]="'dashboard'">
                     </app-select>
                    </div>
      
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="rightarrow ps-2"
            id="rightarrow"
            [class.d-none]="rightArrowNone"
            (click)="scrollRight()"
          >
            <app-svg-icon class="cursorPointer" [name]="'polygon2'"></app-svg-icon>
          </div>
        </div>
       
        <div class="col-lg-4 col-md-6 {{dropdownShow && isDashboardActive() ?'col-xl-2':'col-xl-3'}}">
            <div class="d-flex align-items-center gap-2 justify-content-end">
              <!-- <div class="col-5 pe-3"*ngIf="dropdownShow && isDashboardActive()">
                <app-select [options]="filteredOptions" 
                [defaultOption]="true"
                [isDisabled]="false"
                [selectedValue]="selectedValue"
                (valueChanged)="onSelectionChange($event)"
                [id]="'dashboard'" 
                [name]="'dashboard'">
               </app-select>
              </div> -->
              <div class="mb-1" *ngIf="showHeaderPageNameAndInvoieNum?.invoiceNumber">
                <div class="pin-icon" (click)="togglePin()">
                  <app-svg-icon [name]="isPinned ? 'pin' : 'pinGray'" class="cursorPointer"></app-svg-icon>
                </div>
              </div>                           
               <li class="maximize" (click)="toggleFullScreen()">
                <div class="cursorPointer header-icon-hover " [ngClass]="currentHoverEffect"><i data-feather=maximize></i></div>
              </li>
               <h6 class="f-16 text-primary font-semibold mb-0 pe-0 ps-4 ms-1 gradient-background">{{showHeaderPageNameAndInvoieNum?.pageName}} &nbsp;</h6> <span *ngIf="showHeaderPageNameAndInvoieNum?.invoiceNumber" class="f-16 text-gray-color">No. :&nbsp;</span> <span *ngIf="showHeaderPageNameAndInvoieNum?.invoiceNumber" class="f-16 text-gray-color"> {{showHeaderPageNameAndInvoieNum?.invoiceNumber  || 'NA'}}</span>      
            </div>
        </div>
      </div>
    </ul>
  </div>



  <!-- <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div> -->
</nav>

<!-- <div class="border-right">&nbsp;</div> -->