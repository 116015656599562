import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { VoucherType, VoucherTypeName } from "../models/common.model";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  isHidden?:boolean;
  bookmark?: boolean;
  children?: Menu[];
  voucherTypeId?:number;
  voucherTypeName?: string;
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  public isMobile: boolean = false;
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;
  
  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;
  VoucherType : typeof VoucherType = VoucherType;
  voucherTypeName : typeof VoucherTypeName = VoucherTypeName;
  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    { path: "/dashboard", title: "", icon: 'dashboard', type: "link", bookmark: false },
    {
      title: "HEADER.MENUITEMS.PRODUCT",
      icon: 'productMenu',
      active: true,
      type: "sub",
      children: [
        { path: "/product/product-opening", title: "HEADER.MENUITEMS.PRODUCT.PRODUCTOPENING", icon: 'productOpening', type: "link" },
        { path: "/product/ledger-opening", title: "HEADER.MENUITEMS.PRODUCT.LEDGEROPENING", icon: '', type: "link", isHidden: true },
        { path: "/product/account-ledger", title: "HEADER.MENUITEMS.PRODUCT.ACCOUNTLEDGER", icon: 'accountLedger', type: "link" },
        { path: "/product/parties", title: "HEADER.MENUITEMS.PRODUCT.PARTIES", icon: 'parties', type: "link" },
        { path: "/product/appraisal", title: "HEADER.MENUITEMS.PRODUCT.APPRAISAL", icon: 'appraisal', type: "link" },
        { path: "/product/add-appraisal", icon: 'addAppraisal', type: "link", isHidden: true, voucherTypeId: VoucherType.Appraisal, voucherTypeName: VoucherTypeName.Appraisal },
      ],
    },
    {
      title: "HEADER.MENUITEMS.TRANSACTION",
      icon: 'transactionMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/transaction/saleinvoice", title: "HEADER.MENUITEMS.TRANSACTION.SALEINVOICE", icon: 'saleInvoice', type: "link", voucherTypeId: VoucherType.SalesInvoice, voucherTypeName: VoucherTypeName.SalesInvoice },
        { path: "/transaction/sale-fixing-direct", title: "HEADER.MENUITEMS.TRANSACTION.SALEFIXINGDIRECT", icon: 'saleFixingDirect', type: "link", voucherTypeId: VoucherType.SaleFixingDirectInvoice, voucherTypeName: VoucherTypeName.SaleFixingDirectInvoice },
        { path: "/transaction/estimate", title: "HEADER.MENUITEMS.TRANSACTION.SALESQUOTATION", icon: 'salesQuotation', type: "link", voucherTypeId: VoucherType.SalesQuotation, voucherTypeName: VoucherTypeName.SalesQuotation },
        { path: "/transaction/sale-return", title: "HEADER.MENUITEMS.TRANSACTION.SALERETURN", icon: 'salesReturn', type: "link", voucherTypeId: VoucherType.SalesReturn, voucherTypeName: VoucherTypeName.SalesReturn },
        { path: "/transaction/purchase-invoice", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEINVOICE", icon: 'purchaseInvoice', type: "link", voucherTypeId: VoucherType.PurchaseInvoice, voucherTypeName: VoucherTypeName.PurchaseInvoice },
        { path: "/transaction/purchase-fixing-direct", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEFIXINGDIRECT", icon: 'purchaseFixingDirect', type: "link", voucherTypeId: VoucherType.PurchaseFixingDirectInvoice, voucherTypeName: VoucherTypeName.PurchaseFixingDirectInvoice },
        { path: "/transaction/purchase-quotation", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEQUOTATION", icon: 'purchaseQuotation', type: "link", voucherTypeId: VoucherType.PurchaseQuotation, voucherTypeName: VoucherTypeName.PurchaseQuotation },
        { path: "/transaction/purchase-return", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASERETURN", icon: 'purchaseReturn', type: "link", voucherTypeId: VoucherType.PurchaseReturn, voucherTypeName: VoucherTypeName.PurchaseReturn },
        { path: "/transaction/payment-voucher", title: "HEADER.MENUITEMS.TRANSACTION.PAYMENTVOUCHER", icon: 'paymentVoucher', type: "link", voucherTypeId: VoucherType.PaymentVoucher, voucherTypeName: VoucherTypeName.PaymentVoucher },
        { path: "/transaction/receipt-voucher", title: "HEADER.MENUITEMS.TRANSACTION.RECEIPTVOUCHER", icon: 'receiptVoucher', type: "link", voucherTypeId: VoucherType.ReceiptVoucher, voucherTypeName: VoucherTypeName.ReceiptVoucher },
        { path: "/transaction/material-in", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_IN", icon: 'materialIn', type: "link", voucherTypeId: VoucherType.MaterialIn, voucherTypeName: VoucherTypeName.MaterialIn },
        { path: "/transaction/material-out", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_OUT", icon: 'materialOut', type: "link", voucherTypeId: VoucherType.MaterialOut, voucherTypeName: VoucherTypeName.MaterialOut },
        { path: "/transaction/deliverynote", title: "HEADER.MENUITEMS.TRANSACTION.DELIVERYNOTE", icon: 'deliveryNote', type: "link", voucherTypeId: VoucherType.DeliveryNote, voucherTypeName: VoucherTypeName.DeliveryNote },
        { path: "/transaction/old-jewelry-scrap-invoice", title: "HEADER.MENUITEMS.TRANSACTION.OLDJEWElRY_SCRAP_INVOICE", icon: 'oldJewelryScrapInvoice', type: "link", voucherTypeId: VoucherType.OldJewelryScrapInvoice, voucherTypeName: VoucherTypeName.OldJewelryScrapInvoice },
        { path: "/transaction/old-jewellery", title: "HEADER.MENUITEMS.TRANSACTION.OLDJEWELLERY", icon: 'oldJewelryScrap', type: "link", },
        { path: "/transaction/material-issue", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_ISSUE", icon: 'materialIssue', type: "link", voucherTypeId: VoucherType.MaterialIssue, voucherTypeName: VoucherTypeName.MaterialIssue },
        { path: "/transaction/material-receive", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_RECEIVE", icon: 'materialReceive', type: "link", voucherTypeId: VoucherType.MaterialReceive, voucherTypeName: VoucherTypeName.MaterialReceive },
        { path: "/transaction/credit-note", title: "HEADER.MENUITEMS.TRANSACTION.CREDITNOTE", icon: 'creditNote', type: "link", voucherTypeId: VoucherType.CreditNote, voucherTypeName: VoucherTypeName.CreditNote },
        { path: "/transaction/debit-note", title: "HEADER.MENUITEMS.TRANSACTION.DEBITNOTE", icon: 'debitNote', type: "link", voucherTypeId: VoucherType.DebitNote, voucherTypeName: VoucherTypeName.DebitNote },
        { path: "/transaction/stock-journal", title: "HEADER.MENUITEMS.TRANSACTION.STOCKJOURNAL", icon: 'stockJournalOption1', type: "link", voucherTypeId: VoucherType.StockJournal, voucherTypeName: VoucherTypeName.StockJournal },
        { path: "/transaction/stock-journal-2", title: "HEADER.MENUITEMS.TRANSACTION.STOCK-JOURNAL-2", icon: 'stockJournalOption2', type: "link" },
        { path: "/transaction/pdc-entry", title: "HEADER.MENUITEMS.TRANSACTION.PDCENTRY", icon: 'pdcEntry', type: "link" },
        { path: "/transaction/contra-voucher", title: "HEADER.MENUITEMS.TRANSACTION.CONTRAVOUCHER", icon: 'countraVoucher', type: "link", voucherTypeId: VoucherType.ContraVoucher, voucherTypeName: VoucherTypeName.ContraVoucher },
        { path: "/transaction/journal-voucher", title: "HEADER.MENUITEMS.TRANSACTION.JOURNALVOUCHER", icon: 'journalVoucher', type: "link", voucherTypeId: VoucherType.JournalVoucher, voucherTypeName: VoucherTypeName.JournalVoucher },
        { path: "/transaction/repair-invoice", title: "HEADER.MENUITEMS.TRANSACTION.REPAIRINVOICE", icon: 'repairInvoice', type: "link", voucherTypeId: VoucherType.RepairInvoice, voucherTypeName: VoucherTypeName.RepairInvoice },
        { path: "/transaction/pos", title: "HEADER.MENUITEMS.TRANSACTION.POS", type: "link" },
        { path: "/transaction/investment-fund", title: "HEADER.MENUITEMS.TRANSACTION.INVESTMENTFUND", icon: 'investmentLayawaysFund', type: "link", voucherTypeId: VoucherType.InvestmentFund, voucherTypeName: VoucherTypeName.InvestmentFund },
        { path: "/transaction/expenses", title: "HEADER.MENUITEMS.TRANSACTION.EXPENSES", icon: 'expenses', type: "link", voucherTypeId: VoucherType.ExpenseInvoice, voucherTypeName: VoucherTypeName.ExpenseInvoice },
        { path: "/transaction/loans", title: "HEADER.MENUITEMS.TRANSACTION.LOANS", icon: 'loans', type: "link" },
        { path: "/transaction/bank-reconciliation", title: "HEADER.MENUITEMS.TRANSACTION.BANKRECONCILIATION", icon: 'bankReconciliation', type: "link" },
        { path: "/transaction/jobwork-invoice", type: "link", icon: 'jobworkInvoice', isHidden: true, voucherTypeId: VoucherType.JobworkInvoice, voucherTypeName: VoucherTypeName.JobworkInvoice },
        { path: "/transaction/jobwork-order", type: "link", icon: 'jobworkOrder', isHidden: true, voucherTypeId: VoucherType.JobworkOrder, voucherTypeName: VoucherTypeName.JobworkOrder },
        { path: "/transaction/sale-fixing", type: "link", isHidden: true, icon: 'purchaseFixing', voucherTypeId: VoucherType.SaleFixing, voucherTypeName: VoucherTypeName.SaleFixing },
        { path: "/transaction/purchase-fixing", type: "link", isHidden: true, icon: 'salesFixing', voucherTypeId: VoucherType.PurchaseFixing, voucherTypeName: VoucherTypeName.PurchaseFixing },
      ],
    },
    {
      title: "HEADER.MENUITEMS.ORDERS",
      icon: 'orderMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/orders/sale-order", title: "HEADER.MENUITEMS.TRANSACTION.SALEORDER", icon: 'salesOrder', type: "link", voucherTypeId: VoucherType.SalesOrder, voucherTypeName: VoucherTypeName.SalesOrder },
        { path: "/orders/repair-order", title: "HEADER.MENUITEMS.ORDERS.REPAIRORDER", icon: 'repairOrder', type: "link", voucherTypeId: VoucherType.RepairOrder, voucherTypeName: VoucherTypeName.RepairOrder },
        { path: "/orders/sale-repair-order-process", title: "HEADER.MENUITEMS.ORDERS.SALEREPAIRORDERPROCESS", icon: 'saleRepairOrderProcess', type: "link" },
        { path: "/orders/purchase-order", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEORDER", icon: 'purchaseOrder', type: "link", voucherTypeId: VoucherType.PurchaseOrder, voucherTypeName: VoucherTypeName.PurchaseOrder },
        { path: "/orders/job-work-order-manufacturing", title: "HEADER.MENUITEMS.MANUFACTURER.JOBWORKORDER", icon: 'jobworkOrderManufacturing', type: "link" },
        { path: "/orders/jobwork-order-outsource", title: "HEADER.MENUITEMS.ORDERS.JOBWORKORDEROUTSOURCE", icon: 'jobworkOrderOutsource', type: "link" },
        { path: "/orders/catalogue-quotation", title: "HEADER.MENUITEMS.ORDERS.CATALOGUEQUOTATION", icon: 'catalogQuotation', type: "link", voucherTypeId: VoucherType.CatalogueQuotation, voucherTypeName: VoucherTypeName.CatalogueQuotation },
        // { path: "/orders/cart", title: "HEADER.MENUITEMS.ORDERS.CART", type: "link" },
        // { path: "/orders/orderlist", title: "HEADER.MENUITEMS.ORDERS.ORDERLIST", type: "link" },
        // { path:"/orders/ordertracking" , title:"HEADER.MENUITEMS.ORDERS.ORDERTRACKING" , type: "link"},
        // { path:"/orders/joborder" , title:"HEADER.MENUITEMS.ORDERS.JOBORDER" , type: "link"}
      ],
    },
    {
      title: "HEADER.MENUITEMS.INVENTORY",
      icon: 'inventoryMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/inventory/jewelry-Catalogue", title: "HEADER.MENUITEMS.INVENTORY.JEWELRYCATALOGUE", icon: 'jewelleryCatalog', type: "link" },
        { path: "/inventory/jewelry-Catalogue/jewelry-Catalogue-detail", title: "HEADER.MENUITEMS.INVENTORY.JEWELRY_CATALOGUE_DETAIL", icon: 'jewelleryCatalogDetails', type: "link", isHidden: true, voucherTypeId: VoucherType.JewelryCatalogue, voucherTypeName: VoucherTypeName.JewelryCatalogue },
        { path: "/inventory/physical-stock", title: "HEADER.MENUITEMS.REPORT.PHYSICAL_STOCK", icon: 'physicalStock', type: "link", voucherTypeId: VoucherType.PhysicalStock, voucherTypeName: VoucherTypeName.PhysicalStock },
        { path: "/inventory/consignment-in", title: "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_IN", icon: 'memoConsignmentIn', type: "link", voucherTypeId: VoucherType.ConsignmentIn, voucherTypeName: VoucherTypeName.ConsignmentIn },
        { path: "/inventory/consignment-out", title: "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_OUT", icon: 'memoconsignmentOut', type: "link", voucherTypeId: VoucherType.ConsignmentOut, voucherTypeName: VoucherTypeName.ConsignmentOut },
        { path: "/inventory/memo-report", title: "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_ITEMS", icon: 'memoConsignmentItems', type: "link" },
        { path: "/inventory/stock-history", title: "HEADER.MENUITEMS.INVENTORY.STOCK-SUMMARY", icon: 'stockHistory', type: "link" },
        { path: "/inventory/gold-silver-analysis", title: "HEADER.MENUITEMS.INVENTORY.GOLD_SILVER_ANALYSIS", icon: 'goldSilverAnalysis', type: "link" },
        { path: "/inventory/diamond-caratwise", title: "HEADER.MENUITEMS.INVENTORY.DIAMOND_STONE_WEIGHT_REPORT", icon: 'diamondStoneWeightReport', type: "link" },
        { path: "/inventory/gold-and-silver", title: "HEADER.MENUITEMS.INVENTORY.GOLD_AND_SILVER", icon: 'goldSilver', type: "link" },
        { path: "/inventory/diamond-and-stones", title: "HEADER.MENUITEMS.INVENTORY.DIAMOND_AND_STONE", icon: 'diamondStone', type: "link" },
        { path: "/inventory/immitation-and-watches", title: "HEADER.MENUITEMS.INVENTORY.IMITATION_AND_WATCHES", icon: 'imitationWatches', type: "link" },
        { path: "/inventory/rfid", title: "HEADER.MENUITEMS.INVENTORY.RFID", icon: 'rfid', type: "link" },
        { path: "/inventory/barcode", title: "HEADER.MENUITEMS.INVENTORY.BARCODE", icon: 'barcode', type: "link" },
        { path: "/inventory/stock-valuation", title: "HEADER.MENUITEMS.INVENTORY.STOCK_VALUATION", icon: 'stockValuation', type: "link" },
        { path: "/inventory/stock-summary-report", title: "HEADER.MENUITEMS.INVENTORY.STOCK_SUMMARY_REPORT", icon: 'stockSummeryReport', type: "link" },
        { path: "/inventory/stock-transfer", title: "HEADER.MENUITEMS.INVENTORY.STOCK_TRANSFER", icon: 'stockTransfer', type: "link" },
        { path: "/inventory/stock-transfer-history", title: "HEADER.MENUITEMS.INVENTORY.STOCK_TRANSFER_HISTORY", icon: 'stockTransferHistory', type: "link" },
      ],

    },
    {
      title: "HEADER.MENUITEMS.MANUFACTURER",
      icon: 'manufacturerMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/manufacturer/department", title: "HEADER.MENUITEMS.MANUFACTURER.DEPARTMENT", icon: 'department', type: "link" },
        { path: "/manufacturer/department-report", title: "HEADER.MENUITEMS.MANUFACTURER.DEPARTMENTREPORT", icon: 'departmentReport', type: "link" },
        { path: "/manufacturer/jobwork-queue", title: "HEADER.MENUITEMS.MANUFACTURER.JOBWORKQUEUE", icon: 'jobworkQueue', type: "link", voucherTypeId: VoucherType.JobworkQueue, voucherTypeName: VoucherTypeName.JobworkQueue },
        { path: "/manufacturer/manufacturing-process", title: "HEADER.MENUITEMS.MANUFACTURER.MANUFACTURINGPROCESS", icon: 'manufacturingProcess', type: "link" },
        { path: "/manufacturer/jobwork-queue-report", title: "HEADER.MENUITEMS.MANUFACTURER.JOBWORKQUEUEREPORT", icon: 'jobworkQueueReport', type: "link" },
        { path: "/manufacturer/loss-tracking", title: "HEADER.MENUITEMS.MANUFACTURER.LOSSTRACKING", icon: 'lossTracking', type: "link" },
        { path: "/manufacturer/closing-report", title: "HEADER.MENUITEMS.MANUFACTURER.CLOSINGREPORT", icon: 'closingReport', type: "link" },
        { path: "/manufacturer/worklog-report", title: "HEADER.MENUITEMS.MANUFACTURER.WORKLOGREPORT", icon: 'workLogReport', type: "link" },
        { path: "/manufacturer/jobcard-report", title: "HEADER.MENUITEMS.MANUFACTURER.JOBCARDREPORT", icon: 'jobCardReport', type: "link" },
        { path: "/manufacturer/jobcard-print", title: "HEADER.MENUITEMS.MANUFACTURER.JOBCARDPRINT", icon: 'jobCardPrint', type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.FINANCIALSTATEMENT",
      icon: 'finantialStatementMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/financial-Statement/trial-balance", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.TRIALBALANCE", icon: 'trialBalance', type: "link" },
        { path: "/financial-Statement/balance-sheet", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.BALANCESHEET", icon: 'balanceSheet', type: "link" },
        { path: "/financial-Statement/profit-and-loss", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.PROFITLOSS", icon: 'profitLoss', type: "link" },
        { path: "/financial-Statement/cash-flow", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.CASHFLOW", icon: 'cashFlow', type: "link" },
        { path: "/financial-Statement/fund-flow", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.FundFUlow", icon: 'fundFlow', type: "link" },
        { path: "/financial-Statement/chart-of-account", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.CHARTOFACCOUNT", icon: 'chartofAccount', type: "link" },
        { path: "/financial-Statement/tax-return", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.TAXRETURN", icon: 'taxReturn', type: "link" },
        { path: "/financial-Statement/sale-analysis", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.SALEANALYSIS", icon: 'salesAnalysis', type: "link" },
        { path: "/financial-Statement/purchase-analysis", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.PURCHASEANALYSIS", icon: 'salesAnalysis', type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.REPORT",
      icon: 'reportMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/report/invoice", title: "HEADER.MENUITEMS.REPORT.INVOICE", icon: 'transactionReport', type: "link" },
        { path: "/report/accountledger-report", title: "HEADER.MENUITEMS.REPORT.ACCOUNTLEDGERREPORT", icon: 'accountLedgerReport', type: "link" },
        { path: "/report/day-report", title: "HEADER.MENUITEMS.REPORT.DAYREPORT", icon: 'dayReport', type: "link" },
        { path: "/report/cashbank-book", title: "HEADER.MENUITEMS.REPORT.CASHBANKBOOK", icon: 'cashBankBook', type: "link" },
        { path: "/report/ageing-report", title: "HEADER.MENUITEMS.REPORT.AGEINGREPORT", icon: 'ageingReport', type: "link" }, 
        { path: "/report/daily-sales-and-collection-summary", icon: 'dailySalesCollectionSummery', title: "HEADER.MENUITEMS.REPORT.DAILY_SALES_AND_COLLECTION_SUMMARY", type: "link" },
        { path: "/report/borrowers-report", title: "HEADER.MENUITEMS.REPORT.BORROWERSREPORT", icon: 'borrowersReport', type: "link" },
        { path: "/report/kyc-report", title: "HEADER.MENUITEMS.REPORT.KYCREPORT", icon: 'kycReport', type: "link" },
        { path: "/report/Ledger-Balance-Report", title: "HEADER.MENUITEMS.REPORT.LEDGERBALANCEREPORT", icon: 'ledgerBalanceReport', type: "link" },
        { path: "/report/fixing-position", title: "HEADER.MENUITEMS.REPORT.FIXING_POSITION", icon: 'fixingPosition', type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.SETTINGS",
      icon: 'settingMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/settings/set-software", title: "HEADER.MENUITEMS.SETTINGS.SETSOFTWARE", icon: 'setSoftware', type: "link" },
        { path: "/settings/voucher-type", title: "HEADER.MENUITEMS.SETTINGS.VOUCHERTYPE", icon: 'voucherType', type: "link" },
        { path: "/settings/data-import-export", title: "HEADER.MENUITEMS.SETTINGS.DATAIMPORTEXPORT", icon: 'dataImportExport', type: "link" },
        { path: "/settings/data-export-tally", title: "HEADER.MENUITEMS.SETTINGS.DATAEXPORTINTALLY", icon: 'dataExportInTally', type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.ADMINISTRATION",
      icon: 'administrationMenu',
      active: false,
      type: "sub",
      children: [
        { path: "/administration/usermanagment", title: "HEADER.MENUITEMS.SETTINGS.USER MANAGMENT", icon: 'userManagement', type: "link" },
        { path: "/administration/masters", title: "HEADER.MENUITEMS.SETTINGS.MASTERS", icon: 'masters', type: "link" },
        { path: "/administration/activityLog", title: "HEADER.MENUITEMS.SETTINGS.ACTIVITYLOG", icon: 'activityLog', type: "link" },
        { path: "/administration/crm", title: "HEADER.MENUITEMS.SETTINGS.CRM", icon: 'crm', type: "link" },
      ],
    },
    
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(JSON.parse(JSON.stringify(this.MENUITEMS)));

}
