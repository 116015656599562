<form class="d-flex flex-column h-100" ngNativeValidate #MetalchildAppraisal="ngForm">
  <div class="flex-fill" >
    <ng-container >
    <div class="row" *ngIf="tabId !=3">
      <div class="col-12">
        <div class="row">
          <div class="col-8">
            <div class="row">
              <div class="col d-flex align-items-center mb-2">
                <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Category</label>
                <div class="col">
                  <app-select 
                  [options]="filterdiamondCategoryDropdown"
                  [selectedValue]="formModel?.diamondCategoryId"
                  (valueChanged)="formModel.diamondCategoryId = $event;onCellValueChangedDetect(formModel ,'diamondCategoryId')"
                  [id]="'diamondCategoryId'" 
                  [name]="'diamondCategoryId'"
                  [isDisabled]="tabId==1"
                  [isRequired]="true"
                 >
                </app-select>
                </div>
              </div>
              <div class="col-6">
                <div class="col d-flex align-items-center mb-2">
                  <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Calc. Type</label>
                  <div class="col">
                    <app-select 
                      [id]="'calculationType'+tabName" 
                      [name]="'calculationType'+tabName"
                      [options]="data?.getAllDropdownModel?.calculationTypeDropdown" 
                      [selectedValue]="formModel?.calculationType"
                      (valueChanged)="formModel.calculationType = $event;onCellValueChangedDetect(formModel ,'calculationType')"
                      [isRequired]="false"
                      (keydown.enter)="onKeydown($event, 'productDetailId'+tabName)"
                      >
                    </app-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex align-items-center mb-2">
                <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Product</label>
                <div class="col">
                  <app-select 
                    [id]="'productDetailId'+tabName" 
                    [name]="'productDetailId'+tabName" 
                    [options]="filterProductDropdown"
                    [selectedValue]="formModel?.productDetailId"
                    (valueChanged)="formModel.productDetailId = $event ;onCellValueChangedDetect(formModel ,'productDetailId')"
                    [isRequired]="false"
                    (keydown.enter)="onKeydown($event, 'grossWt'+tabName)"
                    >
                  </app-select>
                </div>
              </div>
            </div>
           <div class="row">
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Gross Wt</label>
              <div class="col">
                <app-input 
                  [inputName]="'grossWt'+tabName" 
                  [inputType]="'text'" 
                  [inputId]="'grossWt'+tabName" 
                  [inputValue]="formModel?.grossWt"
                  (inputValueEmit)="formModel.grossWt = $event;onCellValueChangedDetect(formModel ,'grossWt')"
                  [isRequired]="false"
                  [decimalRestrictedDigit]="3" 
                  [applyDecimalOrPercent]="true"
                  (keydown.enter)="onKeydown($event, 'carat'+tabName)"
                  >
                </app-input>
              </div>
            </div>
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Carat</label>
              <div class="col">
                <app-input
                  [decimalRestrictedDigit]="3" 
                  [applyDecimalOrPercent]="true"
                  [inputName]="'carat'+tabName" 
                  [inputType]="'text'" 
                  [inputId]="'carat'+tabName" 
                  [inputValue]="formModel?.carat"
                  (inputValueEmit)="formModel.carat = $event;onCellValueChangedDetect(formModel ,'carat')"
                  [isRequired]="false"
                  (keydown.enter)="onKeydown($event, 'weight'+tabName)"
                  >
                </app-input>
              </div>
            </div>
           </div>
           <div class="row">
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Diamond Wt.</label>
              <div class="col">
                <app-input 
                  [decimalRestrictedDigit]="3" 
                  [applyDecimalOrPercent]="true"
                  [inputName]="'weight'+tabName" 
                  [inputType]="'text'" 
                  [inputId]="'weight'+tabName"
                  [readOnly]="true"
                  [inputValue]="formModel?.weight"
                  (inputValueEmit)="formModel.weight= $event;onCellValueChangedDetect(formModel ,'weight')" 
                  [isRequired]="false"
                  (keydown.enter)="onKeydown($event, 'quantity'+tabName)"
                  >
                </app-input>
              </div>
            </div>
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Qty.</label>
              <div class="col">
                <app-input 
                  [applyDecimalOrPercent]="true"
                  [inputName]="'quantity'+tabName" 
                  [inputType]="'text'" 
                  [inputId]="'quantity'+tabName" 
                  [inputValue]="formModel?.quantity"
                  (inputValueEmit)="formModel.quantity = $event;onCellValueChangedDetect(formModel ,'quantity')" 
                  [isRequired]="false"
                  (keydown.enter)="onKeydown($event, 'rate'+tabName)"
                  >
                </app-input>
              </div>
            </div>
           </div>
            <div class="row">
              <div class="col d-flex align-items-center mb-2">
                <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Rate</label>
                <div class="col">
                  <app-input 
                    [decimalRestrictedDigit]="3" 
                    [applyDecimalOrPercent]="true"
                    [inputName]="'rate'+tabName" 
                    [inputType]="'text'" 
                    [inputId]="'rate'+tabName"
                    [inputValue]="formModel?.rate"
                    (inputValueEmit)="formModel.rate = $event ;onCellValueChangedDetect(formModel ,'rate')"  
                    [isRequired]="false"
                    (keydown.enter)="onKeydown($event, tabId == appraisalTabEnum.DiamondAndGemstone ? 'shapeCategoryId' + tabName : 'goldCaratId'+ tabName)"
                    >
                  </app-input>
                </div>
              </div>
              <div class="col d-flex align-items-center mb-2">
                <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Amount</label>
                <div class="col">
                  <app-input 
                    [decimalRestrictedDigit]="2" 
                    [applyDecimalOrPercent]="true"
                    [inputName]="'amount'+tabName" 
                    [inputType]="'text'" 
                    [inputId]="'amount'+tabName" 
                    [readOnly]="true"
                    [inputValue]="formModel?.amount"
                    (inputValueEmit)="formModel.amount = $event;onCellValueChangedDetect(formModel ,'amount')"  
                    [isRequired]="false"
                    >
                  </app-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex align-items-center mb-2">
                <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Gold Carat</label>
                <div class="col">
                  <app-select 
                  [id]="'goldCaratId'+tabName" 
                  [name]="'goldCaratId'+tabName" 
                  [options]="filtercaratDropdown"
                  [selectedValue]="formModel?.goldCaratId"
                  (valueChanged)="formModel.goldCaratId = $event;CalculateGoldRate($event);onCellValueChangedDetect(formModel ,'goldCaratId')"
                  [isRequired]="false"
                  [isDisabled]="tabId == appraisalTabEnum.DiamondAndGemstone"
                  (keydown.enter)="onKeydown($event, 'goldWt'+tabName)"
                  >
                </app-select>
                </div>
              </div>
              <div class="col d-flex align-items-center mb-2">
                <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Gold Wt.</label>
                <div class="col">
                  <app-input 
                    [decimalRestrictedDigit]="3"
                    [isBlureApplicable]="true"
                    [applyDecimalOrPercent]="true" 
                    [inputName]="'goldWt'+tabName" 
                    [inputType]="'text'" 
                    [inputId]="'goldWt'+tabName" 
                    [isRequired]="false"
                    [readOnly]="tabId == appraisalTabEnum.DiamondAndGemstone"
                    (keydown.enter)="onKeydown($event, 'goldRate'+tabName)"
                    [inputValue]="formModel?.goldWt"
                    (inputValueEmit)="formModel.goldWt = $event;onCellValueChangedDetect(formModel ,'goldWt')"
                    >
                  </app-input>
                </div>
              </div>
            </div>
           <div class="row">
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Gold Rate</label>
              <div class="col">
                <app-input 
                  [decimalRestrictedDigit]="3" 
                  [applyDecimalOrPercent]="true"
                  [isBlureApplicable]="true"
                  [inputName]="'goldRate'+tabName" 
                  [inputType]="'text'" 
                  [inputId]="'goldRate'+tabName" 
                  [inputValue]="formModel?.goldRate"
                  (inputValueEmit)="formModel.goldRate = $event;onCellValueChangedDetect(formModel ,'goldRate')"
                  [isRequired]="false"
                  [readOnly]="tabId == appraisalTabEnum.DiamondAndGemstone"
                  (keydown.enter)="onKeydown($event, 'goldAmount'+tabName)"
                  >
                </app-input>
              </div>
            </div>
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Gold Amt.</label>
              <div class="col">
                <app-input 
                  [decimalRestrictedDigit]="2"
                  [isBlureApplicable]="true"
                  [applyDecimalOrPercent]="true"  
                  [inputName]="'goldAmount'+tabName" 
                  [inputType]="'text'" 
                  [inputId]="'goldAmount'+tabName" 
                  [isRequired]="false"
                  [inputValue]="formModel?.goldAmount"
                  [readOnly]="tabId == appraisalTabEnum.DiamondAndGemstone"
                  (inputValueEmit)="formModel.goldAmount = $event;onCellValueChangedDetect(formModel ,'goldAmount')"
                  (keydown.enter)="onKeydown($event, 'shapeCategoryId'+tabName)"
                  >
                </app-input>
              </div>
            </div>
           </div>
           <div class="row">
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Shape Cat.</label>
              <div class="col">
                <app-select 
                  [id]="'shapeCategoryId'+tabName" 
                  [name]="'shapeCategoryId'+tabName" 
                  [options]="data?.getAllDropdownModel?.shapeCategoryDropdown"
                  [selectedValue]="formModel?.shapeCategoryId"
                  (valueChanged)="formModel.shapeCategoryId = $event;onCellValueChangedDetect(formModel ,'shapeId')"
                  [isRequired]="false"
                  [isDisabled]="tabId == appraisalTabEnum.Jewellery"
                  (keydown.enter)="onKeydown($event, 'shapeId'+tabName)"
                  >
                </app-select>
              </div>
            </div>
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Shape</label>
              <div class="col">
                <app-select 
                  [id]="'shapeId'+tabName" 
                  [name]="'shapeId'+tabName" 
                  [options]="data?.getAllDropdownModel?.shapeDropdown"
                  [selectedValue]="formModel?.shapeId"
                  (valueChanged)="formModel.shapeId = $event;onCellValueChangedDetect(formModel ,'shapeId')"
                  [isRequired]="false"
                  [isDisabled]="tabId == appraisalTabEnum.Jewellery"
                  (keydown.enter)="onKeydown($event, 'cutId'+tabName)"
                  >
                </app-select>
              </div>
            </div>
           </div>
           <div class="row">
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Cut</label>
              <div class="col">
                <app-select 
                  [id]="'cutId'+tabName" 
                  [name]="'cutId'+tabName" 
                  [options]="data?.getAllDropdownModel?.cutDropdown"
                  [isRequired]="false"
                  [isDisabled]="tabId == appraisalTabEnum.Jewellery"
                  [selectedValue]="formModel?.cutId"
                  (valueChanged)="formModel.cutId = $event;onCellValueChangedDetect(formModel ,'cutId')"
                  (keydown.enter)="onKeydown($event, 'colorId'+tabName)"
                  >
                </app-select>
              </div>
            </div>
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Color</label>
              <div class="col">
                <app-select 
                  [id]="'colorId'+tabName" 
                  [name]="'colorId'+tabName" 
                  [options]="data?.getAllDropdownModel?.colorDropdown"
                  [selectedValue]="formModel?.colorId"
                  (valueChanged)="formModel.colorId = $event;onCellValueChangedDetect(formModel ,'colorId')"
                  [isRequired]="false"
                  [isDisabled]="tabId == appraisalTabEnum.Jewellery"
                  (keydown.enter)="onKeydown($event, 'clarityId'+tabName)"
                  >
                </app-select>
              </div>
            </div>
           </div>
           <div class="row">
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Clarity</label>
              <div class="col">
                <app-select 
                  [id]="'clarityId'+tabName" 
                  [name]="'clarityId'+tabName" 
                  [options]="data?.getAllDropdownModel?.clarityDropdown"
                  [selectedValue]="formModel?.clarityId"
                  (valueChanged)="formModel.clarityId = $event;onCellValueChangedDetect(formModel ,'clarityId')"
                  [isRequired]="false"
                  [isDisabled]="tabId == appraisalTabEnum.Jewellery"
                  (keydown.enter)="onKeydown($event, 'seiveSizeId'+tabName)"
                  >
                </app-select>
              </div>
            </div>
            <div class="col d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Seive Size</label>
              <div class="col">
                <app-select 
                  [id]="'seiveSizeId'+tabName" 
                  [name]="'seiveSizeId'+tabName" 
                  [options]="data?.getAllDropdownModel?.seiveSizeDropdown"
                  [selectedValue]="formModel?.seiveSizeId"
                  (valueChanged)="formModel.seiveSizeId = $event;onCellValueChangedDetect(formModel ,'seiveSizeId')"
                  [isRequired]="false"
                  [isDisabled]="tabId == appraisalTabEnum.Jewellery"
                  (keydown.enter)="onKeydown($event, 'rapNetValuation'+tabName)"
                  >
                </app-select>
              </div>
            </div>
           </div>
           <div class="row">
            <div class="col-6 pe-2 d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Repnet Val.</label>
              <div class="col d-flex align-items-center">
                <app-input 
                  [decimalRestrictedDigit]="2" 
                  [applyDecimalOrPercent]="true"
                  [inputName]="'rapNetValuation'+tabName" 
                  [inputType]="'text'" 
                  [inputId]="'rapNetValuation'+tabName" 
                  [isRequired]="false"
                  [readOnly]="tabId == appraisalTabEnum.Jewellery"
                  [inputValue]="formModel?.rapNetValuation"
                  (inputValueEmit)="formModel.rapNetValuation = $event;onCellValueChangedDetect(formModel ,'rapNetValuation')"
                  >
                </app-input>
                <app-svg-icon  [ngClass]="{'cursorPointer': isRapnetIconEnabled && tabId === 2, 'pointer_none opacity-50': !isRapnetIconEnabled || tabId !== 2}" name="raapnet" (click)="onRapnetIconClick()"></app-svg-icon>
              </div>
            </div>
             <!-- Scanox Planner region -->
             <div class="col-6 scanox_Planner d-flex align-items-center mb-2">
              <label class="w-100px f-14 font-semibold text-gray-color mb-0" for="">Scanox Planner</label>
              <div class="col w-50 input-with-button d-flex align-items-center justify-content-between" [ngClass]="{'readonly-field':tabId === appraisalTabEnum.Jewellery}">
                <p class="text-truncate text-gray-color ms-1 f-10 mt-3">{{FileName}}</p>
                <div class="d-flex gap-2 align-items-center pe-2" >
                  <app-svg-icon *ngIf="FileName" [ngClass]="currentHoverEffect" [height]="10" [width]="10" (click)="clearInput()" name="cross" class="cursorPointer"></app-svg-icon>
                  <app-svg-icon *ngIf="FileName" [height]="22" [width]="22" (click)="UploadScannoxPlanner()" name="importFile" class="cursorPointer"></app-svg-icon>
                  <input #fileUpload type="file" [id]="'scanox'+tabName" [name]="'scanox'+tabName" (change)="onInputButtonClick($event)" class="d-none">
                  <label *ngIf="!FileName" [for]="'scanox'+tabName"  class="mb-0 cursorPointer  isLabelShow-custom mb-0 text-primary f-12">
                    Import
                  </label>
                </div>
              </div>
            </div>
            <!-- Scanox Planner region End -->
           </div>
          </div>
          <div class="col h_22rem overflow-y-auto">
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF1')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF1">{{ getUDFName('UDF1') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF1'+tabName"
                        [name]="'udF1'+tabName"
                        [options]="udfDropdowns?.UDF1?.options"
                        [selectedValue]="formModel?.udF1"
                        (valueChanged)="formModel.udF1 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF2'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF2')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF2"> {{ getUDFName('UDF2') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF2'+tabName"
                        [name]="'udF2'+tabName"
                        [options]="udfDropdowns?.UDF2?.options"
                        [selectedValue]="formModel?.udF2"
                        (valueChanged)="formModel.udF2 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF3'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF3')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF3">{{ getUDFName('UDF3') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF3'+tabName"
                        [name]="'udF3'+tabName"
                        [options]="udfDropdowns?.UDF3?.options"
                        [selectedValue]="formModel?.udF3"
                        (valueChanged)="formModel.udF3 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF4'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF4')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF4">{{ getUDFName('UDF4') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF4'+tabName"
                        [name]="'udF4'+tabName"
                        [options]="udfDropdowns?.UDF4?.options"
                        [selectedValue]="formModel?.udF4"
                        (valueChanged)="formModel.udF4 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF5'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF5')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF5">{{ getUDFName('UDF5') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF5'+tabName"
                        [name]="'udF5'+tabName"
                        [options]="udfDropdowns?.UDF5?.options"
                        [selectedValue]="formModel?.udF5"
                        (valueChanged)="formModel.udF5 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF6'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF6')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF6">{{ getUDFName('UDF6') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF6'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF6'+tabName"
                        [inputValue]="formModel?.udF6" 
                        (inputValueEmit)="formModel.udF6 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF7'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF7')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF7">{{ getUDFName('UDF7') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF7'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF7'+tabName"
                        [inputValue]="formModel?.udF7" 
                        (inputValueEmit)="formModel.udF7 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF8'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF8')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF8">{{ getUDFName('UDF8') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF8'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF8'+tabName"
                        [inputValue]="formModel?.udF8" 
                        (inputValueEmit)="formModel.udF8 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF9'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF9')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF9">{{ getUDFName('UDF9') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF9'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF9'+tabName"
                        [inputValue]="formModel?.udF9" 
                        (inputValueEmit)="formModel.udF9 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF10'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF10')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF10">{{ getUDFName('UDF10') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF10'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF10'+tabName"
                        [inputValue]="formModel?.udF10" 
                        (inputValueEmit)="formModel.udF10 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF11'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF11')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF11">{{ getUDFName('UDF11') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF11'+tabName"
                        [name]="'udF11'+tabName"
                        [options]="udfDropdowns?.UDF11?.options"
                        [selectedValue]="formModel?.udF11"
                        (valueChanged)="formModel.udF11 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF12'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF12')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF12">{{ getUDFName('UDF12') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF12'+tabName"
                        [name]="'udF12'+tabName"
                        [options]="udfDropdowns?.UDF12?.options"
                        [selectedValue]="formModel?.udF12"
                        (valueChanged)="formModel.udF12 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF13'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF13')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF13">{{ getUDFName('UDF13') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF13'+tabName"
                        [name]="'udF13'+tabName"
                        [options]="udfDropdowns?.UDF13?.options"
                        [selectedValue]="formModel?.udF13"
                        (valueChanged)="formModel.udF13 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF14'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF14')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF14">{{ getUDFName('UDF14') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF14'+tabName"
                        [name]="'udF14'+tabName"
                        [options]="udfDropdowns?.UDF14?.options"
                        [selectedValue]="formModel?.udF14"
                        (valueChanged)="formModel.udF14= $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF15'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF15')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF15">{{ getUDFName('UDF15') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF15'+tabName"
                        [name]="'udF15'+tabName"
                        [options]="udfDropdowns?.UDF15?.options"
                        [selectedValue]="formModel?.udF15"
                        (valueChanged)="formModel.udF15 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF16'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF16')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF16">{{ getUDFName('UDF16') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF16'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF16'+tabName"
                        [inputValue]="formModel?.udF16" 
                        (inputValueEmit)="formModel.udF16 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF17'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF17')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF17">{{ getUDFName('UDF17') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF17'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF17'+tabName"
                        [inputValue]="formModel?.udF17" 
                        (inputValueEmit)="formModel.udF17 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF18'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF18')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF18">{{ getUDFName('UDF18') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF18'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF18'+tabName"
                        [inputValue]="formModel?.udF18" 
                        (inputValueEmit)="formModel.udF18 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF19'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF19')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF19">{{ getUDFName('UDF19') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF19'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF19'+tabName"
                        [inputValue]="formModel?.udF19" 
                        (inputValueEmit)="formModel.udF19 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF20'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF20')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF20">{{ getUDFName('UDF20') }}</label>
                <div class="col">
                    <app-input    
                        [inputName]="'udF20'+tabName" 
                        [inputType]="'text'" 
                        [inputId]="'udF20'+tabName"
                        [inputValue]="formModel?.udF20" 
                        (inputValueEmit)="formModel.udF20 = $event"  
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF21'+tabName)" 
                        >
                    </app-input>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF21')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF21">{{ getUDFName('UDF21') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF21'+tabName"
                        [name]="'udF21'+tabName"
                        [options]="udfDropdowns?.UDF21?.options"
                        [selectedValue]="formModel?.udF21"
                        (valueChanged)="formModel.udF21 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF22'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF22')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF22">{{ getUDFName('UDF22') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF22'+tabName"
                        [name]="'udF22'+tabName"
                        [options]="udfDropdowns?.UDF22?.options"
                        [selectedValue]="formModel?.udF22"
                        (valueChanged)="formModel.udF22 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF23'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF23')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF23">{{ getUDFName('UDF23') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF23'+tabName"
                        [name]="'udF23'+tabName"
                        [options]="udfDropdowns?.UDF23?.options"
                        [selectedValue]="formModel?.udF23"
                        (valueChanged)="formModel.udF23 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF24'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF24')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF24">{{ getUDFName('UDF24') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF24'+tabName"
                        [name]="'udF24'+tabName"
                        [options]="udfDropdowns?.UDF24?.options"
                        [selectedValue]="formModel?.udF24"
                        (valueChanged)="formModel.udF24 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF25'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF25')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF25">{{ getUDFName('UDF25') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF25'+tabName"
                        [name]="'udF25'+tabName"
                        [options]="udfDropdowns?.UDF25?.options"
                        [selectedValue]="moformModeldel?.udF25"
                        (valueChanged)="formModel.udF25 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF26'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF26')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF26">{{ getUDFName('UDF26') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF26'+tabName"
                        [name]="'udF26'+tabName"
                        [options]="udfDropdowns?.UDF26?.options"
                        [selectedValue]="formModel?.udF26"
                        (valueChanged)="formModel.udF26 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF27'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF27')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF27">{{ getUDFName('UDF27') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF27'+tabName"
                        [name]="'udF27'+tabName"
                        [options]="udfDropdowns?.UDF27?.options"
                        [selectedValue]="formModel?.udF27"
                        (valueChanged)="formModel.udF27 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF28'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF28')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF28">{{ getUDFName('UDF28') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF28'+tabName"
                        [name]="'udF28'+tabName"
                        [options]="udfDropdowns?.UDF28?.options"
                        [selectedValue]="formModel?.udF28"
                        (valueChanged)="formModel.udF28 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF29'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF29')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF29">{{ getUDFName('UDF29') }}</label>
                <div class="col"> 
                    <app-select 
                        [id]="'udF29'+tabName"
                        [name]="'udF29'+tabName"
                        [options]="udfDropdowns?.UDF29?.options"
                        [selectedValue]="formModel?.udF29"
                        (valueChanged)="formModel.udF29 = $event"
                        [isRequired]="false"
                        (keydown.enter)="onKeydown($event, 'udF30'+tabName)">
                    </app-select>
                </div>
            </div>
            <div class="col d-flex align-items-center mb-2" *ngIf="isUDFActive('UDF30')">
                <label class="w-120px f-14 font-semibold text-gray-color mb-0 text-truncate" for="UDF30">{{ getUDFName('UDF30') }}</label>
                <div class="col">
                    <app-select 
                        [id]="'udF30'+tabName"
                        [name]="'udF30'+tabName"
                        [options]="udfDropdowns?.UDF30?.options"
                        [selectedValue]="formModel?.udF30"
                        (valueChanged)="formModel.udF30 = $event"
                        [isRequired]="false"
                        >
                    </app-select>
                </div>
            </div> 
        </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <app-button 
            class="my-0 me-3 " 
            [buttonLabel]="'Add'" 
            [buttonClass]="'blue_button'" 
            [buttonType]="'button'"
            [showPlusIcon]="true" 
            (buttonOutputEvent)="AddRowToMetalGrid()"
            >
          </app-button>
          <app-button 
            class="m-0" 
            [buttonLabel]="'Continue'" 
            [buttonId]="'continue'" 
            [buttonClass]="'bluebutton'"
            [buttonType]="'button'" 
            (buttonOutputEvent)="Continue()"
            >
          </app-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container >
    <div *ngIf="tabId ==3">
      <div class="row">
        <div class="col-12 d-flex flex-column gap-3 pe-0 border-gary-right">
          <div class="col-12 d-flex align-items-center justify-content-between px-2 py-2 border-right-0 border text-primary font-semibold">
            Customer Details
          </div>
          <div class="col-12 d-flex gap-4 mb-4">
            <div class="h-200 w-200 border-gray">
              <img [src]="isBase64Image(accountLedgerData?.photoUrl)"  alt="img" class="w-100 h-100 cursorPointer">
            </div>
            <div class="d-flex flex-column gap-2">
              <h5 class="text-primary font-semibold f-16 mb-2 cursorPointer" (click)="data?.formModel?.invoiceModel?.accountLedgerId?openCustomerDetailsPopup('Edit'):''">{{accountLedgerData?.ledgerName ||'NA'}}</h5>
              <div class="d-flex align-items-center mb-1">
                <div class="f-14 font-regular color-dark-gray mb-0 line-consistent">Contact :</div>&nbsp;<div
                  class="f-14 font-semibold color-dark-gray">{{accountLedgerData?.contactNo ||'NA'}}</div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <div class="f-14 font-regular color-dark-gray mb-0 line-consistent">Email :</div>&nbsp;<div
                  class="f-14 font-semibold color-dark-gray">{{accountLedgerData?.email ||'NA'}}</div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <div class="f-14 font-regular color-dark-gray mb-0 line-consistent">Trade No.:</div>&nbsp;<div
                  class="f-14 font-semibold color-dark-gray">{{accountLedgerData?.gstNo ||'NA'}}</div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <div class="f-14 font-regular color-dark-gray mb-0 line-consistent">Address :</div>&nbsp;<div
                  class="f-14 font-semibold color-dark-gray"> {{accountLedgerData?.fullAddress  ||'NA'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
</div>
</form>