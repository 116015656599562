<div>
    <div class="modal-body custom-modal-body p-0 position-relative">
        <div class="justify-content-end d-flex cursorPointer p-2 position-absolute end-0 top-0 z-index" (click)="activeModal.close(true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.999" height="16.002" viewBox="0 0 17.999 18.002">
                <path id="Union_4" data-name="Union 4" d="M-1051-8106l-8,8,8-8-8-8,8,8,8-8-8,8,8,8Z" transform="translate(1059.999 8115)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>
        </div>
        
        <div class="custom-carousel-image">
            <ngb-carousel #carousel [activeId]="'ngb-slide-'+ data?.activeSlideId || 0">
                <ng-container *ngIf="!isBase64Resource">
                    <ng-template [id]="'ngb-slide-'+i"  *ngFor="let item of jewelryCatalogue.length==0?data?.jewelryCatalogue:jewelryCatalogue; let i = index" ngbSlide>
                            <div class="pic-img-wrapper">
                                <img *ngIf="getCategoryFromBase64Url(item?.imageUrl) == FileCategory.IMAGE"  class="preview" [src]="isBase64Image(item?.imageUrl)" [alt]="'Slide ' + (i + 1)" /> 
                                <video controls *ngIf="getCategoryFromBase64Url(item?.imageUrl) == FileCategory.VIDEO" [muted]="true" loop autoplay class="w-100 h-100 cursorPointer">
                                    <source [src]="isBase64Image(item?.imageUrl)">
                                    Does not support.
                                </video>
                                <img *ngIf="!item?.imageUrl || getCategoryFromBase64Url(item?.imageUrl) == FileCategory.EXCEL || getCategoryFromBase64Url(item?.imageUrl) == FileCategory.PDF || getCategoryFromBase64Url(item?.imageUrl) == FileCategory.OTHER" class="img-fluid h-100 w-100 cursorPointer" src="./assets/svg/nopreviewImage.svg" alt="previewImage" (click)="openImgPopUp()" onerror="this.src = './assets/svg/nopreviewImage.svg'">

                            </div>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="isBase64Resource" >
                    <ng-template [id]="'ngb-slide-'+i" *ngFor="let item of jewelryCatalogue.length==0?data?.jewelryCatalogue:jewelryCatalogue; let i = index" ngbSlide>
                        <div class="pic-img-wrapper">
                            <video controls *ngIf="getCategoryFromBase64Url(item?.base64Resource) == FileCategory.VIDEO" [muted]="true" loop autoplay class="w-100 h-100 cursorPointer">
                                <source [src]="isBase64Image(item?.base64Resource)">
                                Does not support.
                            </video>
                            <img *ngIf="getCategoryFromBase64Url(item?.base64Resource) == FileCategory.IMAGE" class="preview" [src]="isBase64Image(item?.base64Resource)" [alt]="'Slide ' + (i + 1)" />
                            <img *ngIf="!item?.base64Resource || getCategoryFromBase64Url(item?.base64Resource) == FileCategory.EXCEL || getCategoryFromBase64Url(item?.base64Resource) == FileCategory.PDF || getCategoryFromBase64Url(item?.base64Resource) == FileCategory.OTHER" class="img-fluid h-100 w-100 cursorPointer" src="./assets/svg/nopreviewImage.svg" alt="previewImage" (click)="openImgPopUp()" onerror="this.src = './assets/svg/nopreviewImage.svg'">
                        </div>
                    </ng-template> 
                </ng-container>
            </ngb-carousel>
        </div>
    </div>
</div>
