import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { GetAllSeivesizeResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-seive-size',
  templateUrl: './seive-size.component.html',
  styleUrls: ['./seive-size.component.scss']
})
export class SeiveSizeComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllSeivesizeData          : any[]=[];

  copyGetAllSeivesizeData     : any[]=[];
  editSeivesizeData           = new GetAllSeivesizeResponseModel();
  isAdd                       : boolean = false;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllSeivesizeList();
}

add(){
if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, name:'',active:true}
  this.getAllSeivesizeData?.push(obj);
  this.copyGetAllSeivesizeData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`seiveSizeName${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllSeivesizeData = this.getAllSeivesizeData.filter((x)=>x.id != 0)
    this.copyGetAllSeivesizeData = this.copyGetAllSeivesizeData.filter((x)=>x.id != 0);
  }
  else if(this.editSeivesizeData?.id && this.editSeivesizeData?.isEdit){
    const index = this.copyGetAllSeivesizeData.findIndex((x)=>x.id == this.editSeivesizeData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllSeivesizeData))[index];
    this.getAllSeivesizeData[index] = singleData;
    this.editSeivesizeData = new GetAllSeivesizeResponseModel();
  }  
}

edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editSeivesizeData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
 }

  // *********** SEIVE-SIZE API START *********
  // **********get all seive-size *********
  getAllSeivesizeList() {
    this.administrationService.getAllSeivesize().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.editSeivesizeData = new GetAllSeivesizeResponseModel();
          this.getAllSeivesizeData = res.response??[];
           this.copyGetAllSeivesizeData = JSON.parse(JSON.stringify(this.getAllSeivesizeData))??[];
        }else{           
          this.getAllSeivesizeData = []; 
          this.copyGetAllSeivesizeData = [];
        }  
      },
      error:(err)=>{
        this.getAllSeivesizeData = [];
        this.copyGetAllSeivesizeData = [];
      }
    })
  }
  
 // *************insert seive-size **************
  InserSeivesizeList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertSeivesize(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllSeivesizeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update seive-size *********
    UpdateSeivesizeList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateSeivesize(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            
            this.isAdd = false;
            this.getAllSeivesizeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete seive-size *********
    DeleteSeivesizeList(id) {
    if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.deleteSeivesize(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllSeivesizeList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** SEIVE-SIZE API END *********
}
