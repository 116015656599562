<div class="d-flex align-items-center justify-content-center">
    <div class="pe-2">
        <svg class="scanIcon-hover" xmlns="http://www.w3.org/2000/svg" width="16.286" height="16.284" viewBox="0 0 16.286 16.284">
            <g id="Group_2696" data-name="Group 2696" transform="translate(2496.324 672.339)">
              <path id="Path_1453" data-name="Path 1453" d="M-2494.929-670.945v.2c0,.546,0,1.092,0,1.638,0,.189-.047.234-.235.235q-.455,0-.91,0c-.2,0-.25-.052-.25-.258q0-1.48,0-2.961c0-.2.051-.249.241-.249h2.994c.179,0,.233.056.234.24,0,.309,0,.618,0,.926,0,.177-.05.225-.227.226-.546,0-1.092,0-1.637,0Z" transform="translate(0 0)" fill="#5843cf"/>
              <path id="Path_1454" data-name="Path 1454" d="M-2204.542-670.94h-.206c-.546,0-1.092,0-1.637,0-.136,0-.229-.04-.23-.192,0-.336,0-.673,0-1.009a.175.175,0,0,1,.2-.193q1.538,0,3.077,0c.163,0,.2.106.2.245q0,1.365,0,2.729c0,.514.029.5-.5.493-.215,0-.43,0-.645,0s-.254-.04-.254-.25c0-.54,0-1.081,0-1.621Z" transform="translate(-276.892 -0.005)" fill="#5843cf"/>
              <path id="Path_1455" data-name="Path 1455" d="M-2494.923-380.549h.206c.546,0,1.092,0,1.638,0,.136,0,.229.04.229.192q0,.5,0,1.009a.176.176,0,0,1-.2.193h-3.076a.175.175,0,0,1-.194-.2q0-1.538,0-3.077a.176.176,0,0,1,.2-.194c.331,0,.662,0,.992,0,.16,0,.207.088.206.233,0,.546,0,1.092,0,1.638C-2494.923-380.693-2494.923-380.634-2494.923-380.549Z" transform="translate(-0.006 -276.9)" fill="#5843cf"/>
              <path id="Path_1456" data-name="Path 1456" d="M-2204.544-380.555v-.206c0-.546,0-1.092,0-1.638,0-.182.047-.229.223-.23.32,0,.64,0,.959,0a.187.187,0,0,1,.212.213q0,1.522,0,3.044a.186.186,0,0,1-.213.212h-3.043a.187.187,0,0,1-.213-.213c0-.32,0-.64,0-.959,0-.176.048-.22.232-.221.546,0,1.092,0,1.637,0Z" transform="translate(-276.89 -276.894)" fill="#5843cf"/>
              <path id="Path_1457" data-name="Path 1457" d="M-2372.244-552.711c0-.524-.012-1.048.006-1.572a3.98,3.98,0,0,1,.117-.68c.023-.108.1-.181.218-.141a.181.181,0,0,1,.119.252,3.448,3.448,0,0,0-.116,1.112c0,.861,0,1.721,0,2.582,0,.061.016.134-.012.178-.038.06-.113.136-.168.133s-.127-.083-.158-.146a.477.477,0,0,1-.007-.2q0-.761,0-1.523Z" transform="translate(-118.584 -112.039)" fill="#5843cf"/>
              <path id="Path_1458" data-name="Path 1458" d="M-2270.413-566.921c-.023-.494-.03-.99-.073-1.483a1.918,1.918,0,0,0-.358-.946c-.082-.115-.094-.22.025-.3s.2.018.265.113a2.775,2.775,0,0,1,.461,1.494c.015.793,0,1.587.005,2.38,0,.127-.032.227-.174.228s-.173-.109-.173-.231q0-.628,0-1.256Z" transform="translate(-215.446 -98.12)" fill="#5843cf"/>
              <path id="Path_1459" data-name="Path 1459" d="M-2207.834-563.21c-.018-.517-.027-1.035-.058-1.552a4.363,4.363,0,0,0-.112-.616c-.024-.116-.011-.219.115-.252a.173.173,0,0,1,.226.159,12.172,12.172,0,0,1,.19,1.353c.031.737.008,1.477.009,2.216,0,.13-.009.252-.177.262-.125.008-.209-.1-.21-.263,0-.436,0-.871,0-1.307Z" transform="translate(-275.556 -101.981)" fill="#5843cf"/>
              <path id="Path_1460" data-name="Path 1460" d="M-2297.461-465.21a2.737,2.737,0,0,1-.5,1.79,2.58,2.58,0,0,1-1.295.955c-.109.038-.208.035-.268-.084s-.014-.2.16-.26a2.262,2.262,0,0,0,1.064-.751,2.294,2.294,0,0,0,.49-1.492c0-.232,0-.463,0-.695,0-.123.033-.225.183-.218s.165.1.163.22C-2297.463-465.59-2297.461-465.436-2297.461-465.21Z" transform="translate(-188.073 -197.245)" fill="#5843cf"/>
              <path id="Path_1461" data-name="Path 1461" d="M-2334.275-499.85c0,.375,0,.749,0,1.124a.639.639,0,0,0,.67.678.5.5,0,0,1,.2.009c.058.029.134.1.131.151s-.076.161-.129.168a1,1,0,0,1-1.219-.992c0-.771,0-1.542,0-2.314,0-.128.022-.243.177-.242s.176.119.176.245Q-2334.276-500.437-2334.275-499.85Z" transform="translate(-154.544 -163.503)" fill="#5843cf"/>
              <path id="Path_1462" data-name="Path 1462" d="M-2359.606-592.536a2.682,2.682,0,0,1,1.461.4c.038.023.074.048.109.074.087.065.152.15.085.252s-.174.1-.287.033a2.481,2.481,0,0,0-2.426-.193,1.989,1.989,0,0,0-.821.673c-.116.161-.273.16-.325,0a.246.246,0,0,1,.045-.189A2.608,2.608,0,0,1-2359.606-592.536Z" transform="translate(-128.559 -76.273)" fill="#5843cf"/>
              <path id="Path_1463" data-name="Path 1463" d="M-2369.622-445.56a2.653,2.653,0,0,1-2.5-2.646c0-.126.031-.227.174-.229s.159.1.175.214a4.39,4.39,0,0,0,.143.808,2.292,2.292,0,0,0,2.086,1.525.586.586,0,0,1,.259.108.134.134,0,0,1,.03.129.192.192,0,0,1-.123.088A1.371,1.371,0,0,1-2369.622-445.56Z" transform="translate(-118.707 -213.999)" fill="#5843cf"/>
              <path id="Path_1464" data-name="Path 1464" d="M-2298.716-490.833a11.448,11.448,0,0,1-.078,1.284,1.69,1.69,0,0,1-.914,1.212.415.415,0,0,1-.059.029.2.2,0,0,1-.274-.113c-.064-.121.006-.179.106-.231a1.532,1.532,0,0,0,.8-1,1.587,1.587,0,0,0,.054-.39c.006-.618,0-1.236,0-1.853,0-.1.007-.192.125-.221.133-.034.216.043.218.211,0,.358,0,.717,0,1.076Z" transform="translate(-187.577 -172.239)" fill="#5843cf"/>
              <path id="Path_1465" data-name="Path 1465" d="M-2354.148-568.883a12.4,12.4,0,0,1,.075-1.284,1.575,1.575,0,0,1,.922-1.194c.11-.054.223-.087.3.047.065.11.02.2-.132.285a1.47,1.47,0,0,0-.8,1.366c-.016.623,0,1.246-.008,1.869a.289.289,0,0,1-.1.215c-.12.073-.233-.024-.234-.194,0-.369,0-.739,0-1.108Z" transform="translate(-135.887 -96.465)" fill="#5843cf"/>
              <path id="Path_1466" data-name="Path 1466" d="M-2300.712-373.39c-.154.008-.233-.036-.244-.16-.015-.157.1-.176.214-.193a4.7,4.7,0,0,0,2.256-.993c.24-.192.451-.42.674-.633.081-.078.157-.175.276-.067s.1.2,0,.313a5.177,5.177,0,0,1-3.131,1.729A.177.177,0,0,1-2300.712-373.39Z" transform="translate(-186.725 -283.724)" fill="#5843cf"/>
              <path id="Path_1467" data-name="Path 1467" d="M-2314.015-571.155c0,.237,0,.474,0,.711,0,.12-.041.214-.174.212s-.169-.087-.17-.213c0-.479.013-.961-.036-1.435a1.412,1.412,0,0,0-1.352-1.225c-.027,0-.055,0-.083,0-.112-.011-.19-.058-.185-.186s.091-.17.2-.165a1.855,1.855,0,0,1,1.341.582,1.808,1.808,0,0,1,.455,1.258C-2314.013-571.463-2314.015-571.309-2314.015-571.155Z" transform="translate(-172.334 -94.506)" fill="#5843cf"/>
              <path id="Path_1468" data-name="Path 1468" d="M-2301.432-515.4v-1.472c0-.05-.016-.113.008-.146.047-.062.114-.141.178-.146s.113.089.16.147c.017.02,0,.064,0,.1q0,1.48,0,2.96a1.023,1.023,0,0,1-.234.672c-.064.077-.142.156-.253.094-.094-.053-.077-.186-.018-.31a1.217,1.217,0,0,0,.146-.458C-2301.42-514.436-2301.432-514.916-2301.432-515.4Z" transform="translate(-186.079 -148.315)" fill="#5843cf"/>
              <path id="Path_1469" data-name="Path 1469" d="M-2429.722-548.086c0-.43-.008-.861,0-1.291.007-.275.036-.549.062-.823a.206.206,0,0,1,.234-.2c.139.034.126.138.113.25a5.829,5.829,0,0,0-.056.657c-.006.938,0,1.876,0,2.813a1.256,1.256,0,0,1,0,.132c-.01.106-.06.18-.176.179a.164.164,0,0,1-.173-.18c0-.121,0-.243,0-.364Q-2429.722-547.5-2429.722-548.086Z" transform="translate(-63.653 -116.542)" fill="#5843cf"/>
              <path id="Path_1470" data-name="Path 1470" d="M-2353.688-475.109c0-.226,0-.453,0-.679,0-.127.021-.246.178-.241s.167.112.166.237c0,.381,0,.761,0,1.142a1.5,1.5,0,0,0,1.426,1.528c.127.009.239.043.228.2s-.125.159-.243.154a1.82,1.82,0,0,1-1.744-1.727c-.011-.2,0-.408,0-.612Z" transform="translate(-136.326 -187.626)" fill="#5843cf"/>
              <path id="Path_1471" data-name="Path 1471" d="M-2220.03-466.6c0-.308,0-.523,0-.738,0-.115.057-.191.182-.188a.164.164,0,0,1,.168.185,6.763,6.763,0,0,1-.3,2.626c-.13.332-.3.648-.456.969a.169.169,0,0,1-.26.088.18.18,0,0,1-.053-.283A4.445,4.445,0,0,0-2220.03-466.6Z" transform="translate(-263.344 -195.754)" fill="#5843cf"/>
              <path id="Path_1472" data-name="Path 1472" d="M-2389.083-362.916a5.045,5.045,0,0,1-3.221-1.311c-.087-.078-.144-.163-.055-.269s.193-.065.285.016a4.834,4.834,0,0,0,2.4,1.148c.26.044.525.057.789.077.122.009.205.047.2.186s-.1.159-.208.155C-2388.962-362.918-2389.023-362.916-2389.083-362.916Z" transform="translate(-99.323 -294.162)" fill="#5843cf"/>
              <path id="Path_1473" data-name="Path 1473" d="M-2425.521-628.946c.088-.255.156-.482.243-.7a4.836,4.836,0,0,1,1.847-2.293c.023-.015.046-.031.069-.046a.179.179,0,0,1,.263.041c.067.1.016.188-.072.255-.2.154-.414.294-.6.462a4.521,4.521,0,0,0-1.341,2.2c-.012.042-.011.1-.039.124-.062.06-.144.15-.208.142S-2425.471-628.884-2425.521-628.946Z" transform="translate(-67.671 -38.531)" fill="#5843cf"/>
              <path id="Path_1474" data-name="Path 1474" d="M-2357.779-611.878a3.473,3.473,0,0,1,1.3.248c.169.065.227.174.158.285s-.173.113-.288.066a3.167,3.167,0,0,0-1.273-.233,3.242,3.242,0,0,0-1.747.52c-.115.075-.219.116-.316-.01-.075-.1-.025-.213.123-.311A3.715,3.715,0,0,1-2357.779-611.878Z" transform="translate(-130.315 -57.786)" fill="#5843cf"/>
              <path id="Path_1475" data-name="Path 1475" d="M-2315.813-535.3q0,1.446,0,2.891c0,.06.011.136-.02.176a.227.227,0,0,1-.173.086.208.208,0,0,1-.135-.134,1.406,1.406,0,0,1-.009-.313c.006-.567.019-1.135.019-1.7,0-.628-.013-1.256-.018-1.885a.255.255,0,0,1,.042-.168.193.193,0,0,1,.171-.047.24.24,0,0,1,.117.169c.012.308.006.618.006.927Z" transform="translate(-172.199 -129.926)" fill="#5843cf"/>
              <path id="Path_1476" data-name="Path 1476" d="M-2334.831-553.552a4.668,4.668,0,0,1,.034-.559.967.967,0,0,1,1.074-.809.993.993,0,0,1,.878.96c0,.066,0,.132,0,.2,0,.107-.052.2-.159.185-.071-.013-.143-.1-.185-.174-.029-.05-.006-.129-.009-.2a.659.659,0,0,0-.632-.638.637.637,0,0,0-.639.64c-.005.27,0,.54,0,.81,0,.126-.021.243-.179.241s-.176-.119-.174-.245,0-.275,0-.413Z" transform="translate(-154.349 -112.222)" fill="#5843cf"/>
              <path id="Path_1477" data-name="Path 1477" d="M-2301.47-396.864a.193.193,0,0,1-.2-.178c-.012-.119.074-.15.179-.168a3.879,3.879,0,0,0,2.7-1.788l.018-.028c.066-.1.145-.181.27-.1s.1.191.029.287a8.474,8.474,0,0,1-.639.8,4.147,4.147,0,0,1-2.2,1.158C-2301.362-396.875-2301.406-396.872-2301.47-396.864Z" transform="translate(-186.042 -261.095)" fill="#5843cf"/>
              <path id="Path_1478" data-name="Path 1478" d="M-2315.882-630.008a4.3,4.3,0,0,1,2.871.976,6.1,6.1,0,0,1,.46.451.191.191,0,0,1,.018.293c-.1.1-.192.026-.267-.056a4,4,0,0,0-2.37-1.27c-.211-.034-.427-.038-.642-.054-.119-.009-.2-.068-.172-.189C-2315.973-629.92-2315.908-629.972-2315.882-630.008Z" transform="translate(-172.358 -40.458)" fill="#5843cf"/>
              <path id="Path_1479" data-name="Path 1479" d="M-2427.416-448.65c-.044.052-.082.136-.13.143a.244.244,0,0,1-.2-.076,7.06,7.06,0,0,1-.479-.691,5.187,5.187,0,0,1-.7-2.714c0-.121.024-.231.169-.235s.186.109.184.237a4.814,4.814,0,0,0,1.073,3.169A.985.985,0,0,1-2427.416-448.65Z" transform="translate(-64.418 -210.378)" fill="#5843cf"/>
              <path id="Path_1480" data-name="Path 1480" d="M-2267.955-638.42c.073.027.121.04.164.061a4.841,4.841,0,0,1,2.463,2.537c.045.105.118.218-.03.3-.112.061-.234-.009-.3-.169a4.581,4.581,0,0,0-2.058-2.269c-.1-.052-.2-.09-.293-.151-.047-.031-.111-.116-.1-.144A.662.662,0,0,1-2267.955-638.42Z" transform="translate(-218.117 -32.418)" fill="#5843cf"/>
              <path id="Path_1481" data-name="Path 1481" d="M-2241.263-513.864q0,.867,0,1.735c0,.027.007.065-.009.081-.058.062-.125.117-.188.175-.054-.073-.156-.145-.156-.218,0-1.107.014-2.213.024-3.32,0-.05-.016-.11.007-.146.037-.058.094-.13.153-.14s.13.053.159.105a.442.442,0,0,1,.031.208c0,.507,0,1.014,0,1.521Z" transform="translate(-243.442 -149.711)" fill="#5843cf"/>
              <path id="Path_1482" data-name="Path 1482" d="M-2393.193-628.469c0,.087-.065.131-.174.148a3.94,3.94,0,0,0-1.7.684,3.709,3.709,0,0,0-.976,1.024c-.021.033-.04.066-.063.1a.181.181,0,0,1-.271.055.258.258,0,0,1-.018-.245,3.879,3.879,0,0,1,1.1-1.2,4.4,4.4,0,0,1,1.88-.765C-2393.281-628.691-2393.2-628.627-2393.193-628.469Z" transform="translate(-95.487 -41.734)" fill="#5843cf"/>
              <path id="Path_1483" data-name="Path 1483" d="M-2391.421-586.35a3.929,3.929,0,0,1,.395-1.987,7.041,7.041,0,0,1,.513-.766c.069-.1.167-.16.278-.057a.192.192,0,0,1-.009.294,3.4,3.4,0,0,0-.827,2.457c.006.2,0,.408,0,.612,0,.116-.047.2-.17.2s-.177-.077-.179-.191C-2391.424-585.959-2391.421-586.13-2391.421-586.35Z" transform="translate(-100.259 -79.448)" fill="#5843cf"/>
              <path id="Path_1484" data-name="Path 1484" d="M-2391.089-499.025c0,.5-.01.99.005,1.485a2.676,2.676,0,0,0,.111.531c.026.106.06.212-.075.265-.119.047-.222-.012-.254-.176a6.612,6.612,0,0,1-.162-1.039c-.024-.76-.008-1.52-.008-2.281,0-.028,0-.055,0-.083.01-.108.063-.178.178-.175a.162.162,0,0,1,.17.182c0,.276,0,.551,0,.826v.463Z" transform="translate(-100.208 -164.241)" fill="#5843cf"/>
              <path id="Path_1485" data-name="Path 1485" d="M-2228.978-490.9c-.033.538-.049,1.078-.106,1.614a4.313,4.313,0,0,1-.206.762.2.2,0,0,1-.254.154c-.11-.027-.147-.15-.089-.289a4.069,4.069,0,0,0,.288-1.6c0-.59,0-1.18,0-1.771a.3.3,0,0,1,.082-.213c.113-.087.249.006.25.164,0,.392,0,.783,0,1.175Z" transform="translate(-254.868 -172.101)" fill="#5843cf"/>
              <path id="Path_1486" data-name="Path 1486" d="M-2234.83-583.83c0,.352,0,.6,0,.848,0,.116-.037.206-.158.208s-.18-.084-.181-.209c-.005-.479,0-.959-.023-1.437a4.309,4.309,0,0,0-.5-1.8.422.422,0,0,1-.064-.2.63.63,0,0,1,.082-.2c.073.037.18.055.211.114a6.506,6.506,0,0,1,.361.776A4.8,4.8,0,0,1-2234.83-583.83Z" transform="translate(-249.047 -81.932)" fill="#5843cf"/>
              <path id="Path_1487" data-name="Path 1487" d="M-2378.194-407.774a3.478,3.478,0,0,1-2.81-1.608c-.015-.023-.029-.047-.042-.071-.051-.1-.107-.2.015-.275s.22-.024.29.094a2.991,2.991,0,0,0,.871.935,3.071,3.071,0,0,0,1.661.571c.055,0,.11,0,.165.012a.156.156,0,0,1,.153.179.157.157,0,0,1-.17.162C-2378.106-407.772-2378.15-407.774-2378.194-407.774Z" transform="translate(-110.123 -250.962)" fill="#5843cf"/>
              <path id="Path_1488" data-name="Path 1488" d="M-2384.071-384.334a4.369,4.369,0,0,1-2.944-1.393c-.142-.149-.176-.294-.079-.353.152-.094.24.023.333.121a3.934,3.934,0,0,0,2.857,1.287c.128,0,.241.023.234.184s-.129.169-.251.164C-2383.971-384.327-2384.021-384.331-2384.071-384.334Z" transform="translate(-104.346 -273.563)" fill="#5843cf"/>
              <path id="Path_1489" data-name="Path 1489" d="M-2360.912-647.435c-.322-.058-.591-.119-.863-.154a5.012,5.012,0,0,0-2.559.328c-.056.023-.117.067-.169.059-.079-.012-.188-.042-.22-.1-.052-.093.015-.18.119-.213.35-.111.7-.247,1.054-.324a5.594,5.594,0,0,1,2.555.065c.042.01.1.016.121.046.039.051.088.127.074.177S-2360.894-647.453-2360.912-647.435Z" transform="translate(-125.762 -23.303)" fill="#5843cf"/>
              <path id="Path_1490" data-name="Path 1490" d="M-2270.755-594.823c0,.066.022.144-.005.2a.312.312,0,0,1-.168.16c-.108.023-.167-.062-.173-.171-.012-.214-.01-.43-.035-.643a3.043,3.043,0,0,0-1.209-2.173c-.088-.067-.191-.134-.125-.268.053-.108.177-.119.295-.034a3.33,3.33,0,0,1,1.329,2.081c.059.279.078.567.115.85Z" transform="translate(-213.931 -71.23)" fill="#5843cf"/>
              <path id="Path_1491" data-name="Path 1491" d="M-2409.312-476.4a4.2,4.2,0,0,0,.419,2.092c.045.088.1.172.139.262s.09.18-.022.245-.195.025-.262-.083a3.927,3.927,0,0,1-.6-1.947c-.02-.506,0-1.014-.008-1.52,0-.12.031-.217.163-.221s.178.1.176.224C-2409.314-477.063-2409.312-476.782-2409.312-476.4Z" transform="translate(-82.837 -186.155)" fill="#5843cf"/>
              <path id="Path_1492" data-name="Path 1492" d="M-2301.062-420.759c-.034-.031-.114-.071-.123-.124a.3.3,0,0,1,.069-.2c.023-.032.089-.036.136-.047a3.164,3.164,0,0,0,2.231-1.913c.053-.122.082-.3.274-.228s.082.225.038.335a3.386,3.386,0,0,1-2.542,2.157Z" transform="translate(-186.505 -238.038)" fill="#5843cf"/>
              <path id="Path_1493" data-name="Path 1493" d="M-2409.692-569.743a6.133,6.133,0,0,1,.17-1.861c.049-.169.109-.334.166-.5.032-.093.112-.147.191-.1.058.038.128.154.109.2a8.1,8.1,0,0,0-.3,2.908c0,.188,0,.375,0,.562,0,.119-.051.19-.177.186s-.159-.077-.159-.187C-2409.691-568.882-2409.692-569.234-2409.692-569.743Z" transform="translate(-82.795 -95.689)" fill="#5843cf"/>
            </g>
          </svg>
                 
    </div>
    
    <div *ngIf="params?.data?.active ==true" class="cursorPointer" (click)="handlePrintClick(params)">
        <svg class="labelprintIcon-hover" xmlns="http://www.w3.org/2000/svg" width="16.913" height="16.911" viewBox="0 0 16.913 16.911">
            <g id="Group_2823" data-name="Group 2823" transform="translate(-2078.789 -6813.232)">
              <path id="Path_1390" data-name="Path 1390" d="M2083.877,6813.232h6.738a.438.438,0,0,0,.089.033,2.476,2.476,0,0,1,2.057,2.428c0,.538,0,1.077,0,1.616v.185h.5a2.476,2.476,0,0,1,2.377,1.9c.019.083.044.166.067.249v4.624c-.047.168-.084.339-.141.5a2.476,2.476,0,0,1-2.323,1.646h-.475c0,.764,0,1.5,0,2.238a1.474,1.474,0,0,1-1.5,1.493q-4.02,0-8.04,0a1.476,1.476,0,0,1-1.492-1.5c0-.682,0-1.365,0-2.047v-.186h-.514a2.473,2.473,0,0,1-2.425-2.425q-.006-2.039,0-4.078a2.462,2.462,0,0,1,2.074-2.379c.28-.039.567-.035.865-.052v-.179q0-.792,0-1.585a2.475,2.475,0,0,1,1.833-2.4C2083.668,6813.286,2083.773,6813.26,2083.877,6813.232Zm-2.151,10.537c-.113,0-.211,0-.309,0a.489.489,0,0,1-.48-.5.5.5,0,0,1,.55-.485h11.593a.495.495,0,0,1,0,.989c-.1,0-.205,0-.307,0v1.652h.374a1.5,1.5,0,0,0,1.564-1.571q0-1.9,0-3.8a1.831,1.831,0,0,0-.051-.44,1.482,1.482,0,0,0-1.513-1.127h-11.924a1.473,1.473,0,0,0-1.434,1.445c-.017,1.348-.026,2.7,0,4.045a1.525,1.525,0,0,0,1.937,1.418Zm.994.006v.163q0,2.311,0,4.622a.523.523,0,0,0,.6.594h7.857a.523.523,0,0,0,.6-.6q0-2.3,0-4.605v-.177Zm9.052-6.287c0-.584,0-1.151,0-1.717a1.5,1.5,0,0,0-1.553-1.547q-2.973,0-5.945,0a1.494,1.494,0,0,0-1.553,1.547c0,.522,0,1.046,0,1.568,0,.048.006.1.009.149Z" transform="translate(0 0)" fill="#5843cf"/>
              <path id="Path_1391" data-name="Path 1391" d="M2128.781,6958.687c-.258,0-.516,0-.775,0a.495.495,0,1,1,0-.989h1.549a.495.495,0,1,1,0,.989C2129.3,6958.688,2129.039,6958.687,2128.781,6958.687Z" transform="translate(-46.553 -138.089)" fill="#5843cf"/>
              <path id="Path_1392" data-name="Path 1392" d="M2231.109,7078.462c-.429,0-.857,0-1.286,0a.5.5,0,1,1,0-.988q1.286,0,2.572,0a.5.5,0,1,1,0,.988C2231.966,7078.464,2231.538,7078.462,2231.109,7078.462Z" transform="translate(-143.862 -252.58)" fill="#5843cf"/>
              <path id="Path_1393" data-name="Path 1393" d="M2231.108,7126.369c-.429,0-.857,0-1.286,0a.5.5,0,1,1,0-.99h2.572a.5.5,0,1,1,0,.99C2231.966,7126.371,2231.537,7126.369,2231.108,7126.369Z" transform="translate(-143.862 -298.373)" fill="#5843cf"/>
            </g>
        </svg>       
    </div>
     
    <div *ngIf="params?.data?.active ==false">
      <svg xmlns="http://www.w3.org/2000/svg" width="16.913" height="16.911" viewBox="0 0 16.913 16.911">
          <g id="Group_2823" data-name="Group 2823" transform="translate(-2078.789 -6813.232)">
            <path id="Path_1390" data-name="Path 1390" d="M2083.877,6813.232h6.738a.438.438,0,0,0,.089.033,2.476,2.476,0,0,1,2.057,2.428c0,.538,0,1.077,0,1.616v.185h.5a2.476,2.476,0,0,1,2.377,1.9c.019.083.044.166.067.249v4.624c-.047.168-.084.339-.141.5a2.476,2.476,0,0,1-2.323,1.646h-.475c0,.764,0,1.5,0,2.238a1.474,1.474,0,0,1-1.5,1.493q-4.02,0-8.04,0a1.476,1.476,0,0,1-1.492-1.5c0-.682,0-1.365,0-2.047v-.186h-.514a2.473,2.473,0,0,1-2.425-2.425q-.006-2.039,0-4.078a2.462,2.462,0,0,1,2.074-2.379c.28-.039.567-.035.865-.052v-.179q0-.792,0-1.585a2.475,2.475,0,0,1,1.833-2.4C2083.668,6813.286,2083.773,6813.26,2083.877,6813.232Zm-2.151,10.537c-.113,0-.211,0-.309,0a.489.489,0,0,1-.48-.5.5.5,0,0,1,.55-.485h11.593a.495.495,0,0,1,0,.989c-.1,0-.205,0-.307,0v1.652h.374a1.5,1.5,0,0,0,1.564-1.571q0-1.9,0-3.8a1.831,1.831,0,0,0-.051-.44,1.482,1.482,0,0,0-1.513-1.127h-11.924a1.473,1.473,0,0,0-1.434,1.445c-.017,1.348-.026,2.7,0,4.045a1.525,1.525,0,0,0,1.937,1.418Zm.994.006v.163q0,2.311,0,4.622a.523.523,0,0,0,.6.594h7.857a.523.523,0,0,0,.6-.6q0-2.3,0-4.605v-.177Zm9.052-6.287c0-.584,0-1.151,0-1.717a1.5,1.5,0,0,0-1.553-1.547q-2.973,0-5.945,0a1.494,1.494,0,0,0-1.553,1.547c0,.522,0,1.046,0,1.568,0,.048.006.1.009.149Z" transform="translate(0 0)" fill="#7c89a4"/>
            <path id="Path_1391" data-name="Path 1391" d="M2128.781,6958.687c-.258,0-.516,0-.775,0a.495.495,0,1,1,0-.989h1.549a.495.495,0,1,1,0,.989C2129.3,6958.688,2129.039,6958.687,2128.781,6958.687Z" transform="translate(-46.553 -138.089)" fill="#7c89a4"/>
            <path id="Path_1392" data-name="Path 1392" d="M2231.109,7078.462c-.429,0-.857,0-1.286,0a.5.5,0,1,1,0-.988q1.286,0,2.572,0a.5.5,0,1,1,0,.988C2231.966,7078.464,2231.538,7078.462,2231.109,7078.462Z" transform="translate(-143.862 -252.58)" fill="#7c89a4"/>
            <path id="Path_1393" data-name="Path 1393" d="M2231.108,7126.369c-.429,0-.857,0-1.286,0a.5.5,0,1,1,0-.99h2.572a.5.5,0,1,1,0,.99C2231.966,7126.371,2231.537,7126.369,2231.108,7126.369Z" transform="translate(-143.862 -298.373)" fill="#7c89a4"/>
          </g>
      </svg>
  </div>
</div>