import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AgGridService } from '../ag-grid.service';
import { ICellRendererParams } from 'ag-grid-community';
import { DropdownModel } from 'src/app/shared/models/common.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ColumnCellType, PaymentMethod, StorageName } from 'src/app/shared/enum/common-enum';
import { Router } from '@angular/router';
import { convertTimeToCustomFormat, convertUtcToLocalDate, convertUtcToLocalDateCustom, convertUtcToLocalDateTime, formatUtcToTimeString } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-text-renderer-two-span',
  templateUrl: './text-renderer-two-span.component.html',
  styleUrls: ['./text-renderer-two-span.component.scss']
})
export class TextRendererTwoSpanComponent implements ICellRendererAngularComp {
  params;
  name: string;
  isCursor: boolean = false;
  displayAmount: boolean = false;
  invoiceNumber: boolean = false;
  value: string;
  dateTime: boolean;
  worklogReport:boolean=false;
  constructor(public agGridService: AgGridService, public storageService : StorageService,
    public route : Router
  ) {}
  ngOnInit(): void {}
  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    if (this.params?.invoiceNumber) {
      this.value = this.params?.data?.invoiceNo;
    } else if (this.params?.displayAmount && this.params?.data?.installmentAmount) {
      this.value = `(${this.params?.data?.installmentAmount})`;
    }else if(this.params?.startTime){
      this.value = this.params?.data?.startTime;
    }
     else {
      this.value = undefined;
    }
   const valuesOrFunction = params.colDef.cellEditorParams?.values;
    var values = typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction;
    if(values == undefined){
      this.name = params.value;
      this.isCursor = this.params?.isCursor??false;
    }
    else{
      this.name = params.value;      
    }
    this.updateName(params.value,values);
    if(this.params?.data?.startTime || this.params?.data?.timeSpent){
      this.valueFormat()
    }
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    this.params = params;
    this.updateName(params.value);
    switch(this.params.colDef.field)
     {
      default:
         this.name = params.value;
         break;
     }
    this.isCursor = this.params?.isCursor??false;
    return true;
  }

  updateName(value: any,defaultvalues : any= undefined): void {
    this.name =  value;
  }
  onClick(event){
   if( this.isCursor)
    {
      const customEvent = new CustomEvent('textCellClick', {
        detail : { data: this.params },
      });
      this.params.api.dispatchEvent(customEvent);
    }
  }

  valueFormat = () => {
    if (this.params.data.startTime) {
      this.value = this.params ? convertUtcToLocalDateCustom(this.params?.data?.startTime) : '';
    } 
     if (this.params?.data?.timeSpent) {
      this.name = this.params ? convertTimeToCustomFormat(this.params?.data?.timeSpent) : '';
    }
    if (this.params?.displayAmount && (!this.params?.data?.installmentAmount || this.params?.data?.installmentAmount === 0)) {
      // Hide value if displayAmount is true but installmentAmount is 0 or null
      this.value = undefined;
    }
    // else {
    //   this.value = this.params ? convertUtcToLocalDate(this.params?.value) : '';
    // }
  };
}


