import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { commonModalOptions, ModalPopupSize } from '../../models/common.model';
import { AddEditCommentComponent } from '../pop-ups/add-edit-comment/add-edit-comment.component';
import { NgClass } from '@angular/common';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-comment',
  templateUrl: './app-comment.component.html',
  styleUrls: ['./app-comment.component.scss']
})
export class AppCommentComponent implements OnChanges {
  @Input() formModel: any
  @Input() buttonLabel: string;
  @Input() badgeCount: any
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() buttonClass: string;

  constructor(private modalService: NgbModal,public notificationService: NotificationService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formModel && this.formModel?.invoiceModel?.comment) {
      this.badgeCount = JSON.parse(this.formModel.invoiceModel.comment);
    }
  }
  addEditComment(formModel){
    let data = {};
    if (formModel.invoiceModel.id || formModel?.invoiceModel?.comment) {
      data = {
        comments: JSON.parse(formModel?.invoiceModel?.comment)
      };
      this.badgeCount = JSON.parse(formModel?.invoiceModel?.comment);
    }
    this.notificationService.openModalPopup(AddEditCommentComponent,data,undefined,ModalPopupSize.LG,'',false,true).then(
      (result)=>{
       if (result) {
        this.badgeCount = result;
        formModel.invoiceModel.comment = JSON.stringify(result);
       } else {
      return false;
   }})
  }

  // addEditComment(formModel) {
  //   const modalOption: NgbModalOptions = {
  //     ...commonModalOptions,
  //   };
  //   const modalRef = this..open(AddEditCommentComponent, modalOption);
  //   if (formModel.invoiceModel.id || formModel?.invoiceModel?.comment) {
  //     modalRef.componentInstance.comments = JSON.parse(formModel?.invoiceModel?.comment);
  //     this.badgeCount = JSON.parse(formModel?.invoiceModel?.comment);
  //   }
  //   modalRef.result.then((result: []) => {
  //     if (result) {
  //       this.badgeCount = result;
  //       formModel.invoiceModel.comment = JSON.stringify(result);
  //     } else {
  //     }
  //   }, (reason) => {
  //   });
  // }

  isCommentAvailable(comment: string) {
    if (comment) {
      let comm = JSON.parse(comment)
      return comm.length > 0 ? true : false;

    }
  }

}
