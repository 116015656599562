import { Component, EventEmitter, Input, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { AgGridI, PaginationModel, } from '../../../../../shared/common-components/ag-grid/ag-grid.model';
import { StorageName } from '../../../../../shared/enum/common-enum';
import { AgGridComponent } from '../../../../../shared/common-components/ag-grid/ag-grid.component';
import { ExcelConverterServiceService } from '../../../../../shared/services/excel-converter-service.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendDataService } from '../../../../../shared/services/send-data.service';
import { AgGridButtonCellrendererService } from '../../../../../shared/common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.service';
import { Router } from '@angular/router';
import { CommonService } from '../../../../../shared/services/common.service';
import { GridOptions } from 'ag-grid-community';
import {AgGridAccountLedgerDetailInfo } from './accountledger-detail-info.model';
import { VoucherType } from '../../../../../shared/models/common.model';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { AccountledgerDetailInfoAdvancedFilterComponent } from './advanced-filte-popup/advanced-filte-popup.component';
import { CommonFilterService } from '../../../../../shared/services/common-filter.service';
import { ToastrService } from 'ngx-toastr';
import { FileReaderService } from '../../../../../shared/services/file.reader.service';
import { ReportService } from '../../../../report/report.service';

@Component({
  selector: 'app-accountledger-detail-info',
  templateUrl: './accountledger-detail-info.component.html',
  styleUrls: ['./accountledger-detail-info.component.scss']
})
export class AccountledgerDetailInfoComponent extends CommonService {
  formName                        = StorageName.FORMNAME_REPORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT;
  gridApiAccountLedgerDetailInfo  : any;
  storageName                     = StorageName;
  public viewButtonSubscription   : Subscription;
  apiRequestModel                 : any = {};
  getAllDropdownModel             : any = {};
  agGridDataModel: AgGridI;
  gridApi:any;
  // apiRequestModel = new apiRequestModel();
  @Input() defaultFilterData;
  @Input() data : any = {};
  @Input() isnavigateFromAmount;
  // @Input() fromDate;
  // @Input() toDate;
  apiRequestStorageName = StorageName.APIREQUEST_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID;
  paginationStorageName : StorageName.PAGINATION_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID;
  @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>;
  pagination = new PaginationModel();
  @Output() changeFilterval = new EventEmitter<any>();
  filterCountValue:any =2;
  constructor(
    private reportService: ReportService, 
    public toaster: ToastrService,
    public excelConverterServiceService: ExcelConverterServiceService, 
    public storageService: StorageService, private modalService: NgbModal, 
    public renderer: Renderer2, 
    public sendDataService: SendDataService, 
    private agGridButtonCellrendererService: AgGridButtonCellrendererService, 
    private router: Router,
    public fileReaderService : FileReaderService,
    public notificationService : NotificationService,
    public commonFilterService:CommonFilterService,

  ) {
    super(renderer)
  
    this.viewButtonSubscription = this.agGridButtonCellrendererService.viewHistoryClicked$.subscribe((params: any) => {
      const data = params.data
      if (data)
        this.navigateToInvoicePage(data?.typeOfVoucher, data?.voucherNo, data?.stockJournalTypeId, data.againstVoucherTypeId, data.againstVoucherNo)
    });
  }
  navigateToInvoicePage(voucherTypeName: string, id: number, stockJournalTypeId: number, againstVoucherTypeId = undefined, againstVoucherNo = undefined) {
    var voucherTypeId = this.getEnumValue(VoucherType, voucherTypeName);
    this.reportService.navigateToInvoice(voucherTypeId, id, stockJournalTypeId, againstVoucherTypeId, againstVoucherNo);
  }
  ngOnInit(): void {
    this.agGridDataModel = AgGridAccountLedgerDetailInfo;
    this.sendDataService.displayPageName(this.formName);
    if (this.defaultFilterData.ledgerId)
      this.storageService.store(StorageName.STORED_SELECTED_REPORT_VIEW_ALL_LEDGERAMT_LEDGERID, this.defaultFilterData.ledgerId);
  }

  gridOptionsCurrentStock: GridOptions = {
    suppressClickEdit: true
  };

  onGridReady(event) {
    this.gridApiAccountLedgerDetailInfo = event;
    this.setDefaultFilter();
  }

  setDefaultFilter() {
    if(this.data?.elementsToHide){
      this.filterCountValue = 2;
    }else this.filterCountValue = 3;

    this.storageService.removeItem(this.agGridDataModel.filterStorageName);
    this.apiRequestModel = this.defaultFilterData;
    const parsedData = JSON.parse(this.apiRequestModel.filters);
    parsedData.rules.forEach(rule => {
      if (rule.field === 'fromDate' || rule.field === 'toDate')
        this.commonFilterService.performFilterColumnwise(rule.field, undefined, rule.data, this.agGridDataModel.filterStorageName, 'dateFilter');
      else
        this.commonFilterService.performFilterColumnwise(rule.field, undefined, rule.data, this.agGridDataModel.filterStorageName, '');
    });
    this.commonFilterService.performFilterColumnwise('ledgerid', [this.defaultFilterData.ledgerId], undefined, this.agGridDataModel.filterStorageName, '');
    this.storageService.store(this.apiRequestStorageName,this.apiRequestModel);

    this.getAccountLedgerDetailAmtInfo();
  }

  attachedAppliedRequstData(gridApi) {
    const requestModel = {
      fromDate: this.apiRequestModel?.fromDate,
      toDate: this.apiRequestModel?.toDate
    };
    gridApi.api.appliedRequestModelData = requestModel;
  }

  getAccountLedgerDetailAmtInfo() {
    this.showLoading(this.gridApiAccountLedgerDetailInfo);
    this.apiRequestModel = this.storageService.retrieve(this.apiRequestStorageName);
    this.reportService.getAccountLedgerDetailInfo(this.apiRequestModel).subscribe({
      next: (res) => {
        this.commonFilterService.processServersideApiResponse(res,this.grids.first,this.gridApiAccountLedgerDetailInfo,this.pagination,this.apiRequestModel.getAll);
      },
      error: (err) => {
          this.hideLoading(this.gridApiAccountLedgerDetailInfo);          
          this.gridApi?.api?.setRowData([]);
      }
    })
    this.changeFilterval.emit(this.filterCountValue);
  }
 
  onPagination(pagination: PaginationModel) {
    this.storeChangedPagination(pagination);
    this.getAccountLedgerDetailAmtInfo();
  }

  storeChangedPagination(paginationModel: PaginationModel) {
    this.pagination = this.commonFilterService.storeChangedPagination(paginationModel, this.pagination,this.paginationStorageName,this.apiRequestStorageName);
  }

  fillDropdownData() {
    this.getAllDropdownModel.branchDropdown = this.storageService.retrieve(StorageName.BRANCH_DROPDOWN);
    this.getAllDropdownModel.voucherTypeDropdown= this.storageService.retrieve(StorageName.VOUCHER_TYPE_DROPDOWN);
    this.getAllDropdownModel.ledgerTypeDropdown =this.storageService.retrieve(StorageName.LEDGERTYPE_DROPDOWN);
    this.getAllDropdownModel.accountGroupDropdown= this.storageService.retrieve(StorageName.ACCOUNTGROUP_DROPDOWN);
    this.getAllDropdownModel.typeOfVoucherDropdown= this.storageService.retrieve(StorageName.FILTER_VOUCHER_TYPE_TYPEOFVOUCHER);
    this.getAllDropdownModel.againstDropdown = this.storageService.retrieve(StorageName.AGAINST_DROPDOWN);
    this.getAllDropdownModel.accountLedgerDropdown = this.storageService.retrieve(StorageName.ACCOUNTLEDGER_DROPDOWN);
  }

  handleError(error: any) {
    console.error('An error occurred:', error);
    return throwError('Something went wrong');
  }


  callApiForExportData(){
    const exportAs = this.apiRequestModel.exportAs;
    this.reportService.getAccountLedgerDetailInfo(this.apiRequestModel).subscribe(
      {
        next: (res) => {
        this.fileReaderService.exportFile(res, exportAs);
        },error(error) {
        console.error('Error in downloading Excel/Pdf file:', error);
        },
       }
    );
  }

 
  openAdvanceFilterPopUp(){
    var data = { getAllDropdownModel: this.getAllDropdownModel, agGridDataModel: this.agGridDataModel, apiRequestModel: this.apiRequestModel, defaultFilterData: this.defaultFilterData , modalFilterData : this.data}
    this.notificationService.openModalPopup(AccountledgerDetailInfoAdvancedFilterComponent, data, 'Advance Filter').then(
      (resultData) => {
        if (resultData) {
          if (this.data?.elementsToHide) {
            resultData.filters.rules = resultData.filters.rules?.filter(item => item.field !== 'branchId');
          }
          this.apiRequestModel.filters = JSON.stringify(resultData.filters);
            this.storageService.store(this.apiRequestStorageName,this.apiRequestModel );
            this.filterCountValue = this.filterCount(resultData);
            this.getAccountLedgerDetailAmtInfo();
        }
      }
    );
  }

  resetFilter(){
    this.setDefaultFilter();
    
  }

  ngOnDestroy(): void {
    this.defaultFilterData = '';
    this.agGridDataModel.rows = [];
    this.gridApiAccountLedgerDetailInfo?.api?.setRowData([]);
    this.viewButtonSubscription.unsubscribe();
    this.storageService.removeItem(StorageName.STORED_SELECTED_REPORT_VIEW_ALL_LEDGERAMT_LEDGERID);
  }

}
