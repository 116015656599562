import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AddEditComment } from '../pop-ups/add-edit-comment/add-edit-comment.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-common-comment',
  templateUrl: './common-comment.component.html',
  styleUrls: ['./common-comment.component.scss']
})
export class CommonCommentComponent implements OnInit ,OnChanges{
  @Input() formModel : any ;

  @Input() addeditComment = new AddEditComment()
  required:boolean = false;
 @Input() isLargeFont: boolean = false;
 @Input() cardHeight: boolean = false;

  @Input() comments = [];
  index :number;
  @ViewChild('addEditCommentForm') addEditCommentForm  : NgForm


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formModel && this.formModel?.invoiceModel?.comment) {
      if (this.formModel.invoiceModel.id || this.formModel?.invoiceModel?.comment) {
        this.comments = JSON.parse(this.formModel?.invoiceModel?.comment);
       }
    }
  }


  ngOnInit() {
  }
  onClick(){
    if(this.addeditComment?.comment){
      this.required = false;
      this.onAddValue()
    }else{this.required = true
    }
  }
  onAddValue(){
    if(this.addEditCommentForm.valid){
      if (this.comments == null || this.comments == undefined) {
        this.comments = [];
      }
      
      if(this.index == null || this.index == undefined ){
        this.addeditComment.dateTime = new Date();
        // this.comments.push(this.addeditComment);
        this.comments?.unshift({ ...this.addeditComment });
        if (this.formModel && this.formModel.invoiceModel) {
        this.formModel.invoiceModel.comment = JSON.stringify(this.comments);
        }
        
         this.addeditComment = new AddEditComment();
         this.index = null;
      } else {
        // this.comments[this.index] = this.addeditComment;
        this.comments[this.index] = { ...this.addeditComment };
        if (this.formModel && this.formModel.invoiceModel) {
          this.formModel.invoiceModel.comment = JSON.stringify(this.comments);
        }
        this.index = null;
        this.addeditComment = new AddEditComment();
      }
      this.addEditCommentForm?.resetForm();
    }
     else {
      const hideSubmitBtn = document.getElementById('hide');
      hideSubmitBtn?.click();
    }
    const input = document.getElementById('value');
    input?.focus();
  }

  edit(item , index){
    this.addeditComment = { ...item };
    this.index = index; 
    const input = document.getElementById('value');
    input?.focus();

  }

  deletecomment(item ,i){
    this.comments.splice(i ,1);
    if (this.formModel && this.formModel.invoiceModel) {
    this.formModel.invoiceModel.comment = JSON.stringify(this.comments);
    }
    this.addEditCommentForm?.resetForm();
  }


}
