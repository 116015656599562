import { Component, Input } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TransactionService } from 'src/app/components/Transaction/transaction.service';
import { SaleOrderProcessService } from 'src/app/components/orders/sale-order-process/sale-order-process.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { IconHover } from '../../../enum/common-enum';
@Component({
  selector: 'app-rfid-tag-code',
  templateUrl: './rfid-tag-code.component.html',
  styleUrls: ['./rfid-tag-code.component.scss']
})
export class RfidTagCodeComponent {
  @Input() data:any;
  currentHoverEffect = IconHover.Pink;
  message = '';
  messages: string[] = [];
  @Input()isjobworkorderlist:boolean;
  private hubConnection: HubConnection | undefined;
  constructor(public activeModal: NgbActiveModal,
              public toaster    : ToastrService,
              public storageService : StorageService,
              public saleOrderProcessService :SaleOrderProcessService,
              private transactionService   : TransactionService,
              ) {}
    ngOnInit(): void {
      var tokenValue = 'Bearer '+this.storageService.retrieve("authorizationDataIdToken");
      const USER_INFO = this.storageService.retrieve(StorageName.USER_INFO); 
      const options: signalR.IHttpConnectionOptions = {
       accessTokenFactory: () => {
         return tokenValue;
       }
     };
     // this.GetallrfdcStock();
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.baseUrl+`RFIDTrigger?companyId=${USER_INFO?.id}`,{  skipNegotiation: true,
       transport: signalR.HttpTransportType.WebSockets})
      .configureLogging(signalR.LogLevel.Information)
      .build();
   
    this.hubConnection.start().catch
    ((err) => 
    console.error(err.toString()
    ));
   
    this.hubConnection.on('Send', (data: any) => {
      const received = `Received: ${data}`;
      this.data.rfid = data;
    //  this.storageService.RFIDSCanned.next(data);
    //  if (this.hubConnection != undefined)
    //   this.hubConnection.invoke('Send', JSON.stringify(scannedItem));

    });
    }

    // sendMessage(): void {
    //   const data = `Sent: ${this.message}`;
    //   if (this.hubConnection) {
    //     this.hubConnection.invoke('Send', data);
    //   }
    //   this.messages.push(data);
    // }

    updateRFID(){    
      console.log(this.data);
      
    if(this.data?.isjobworkorderlist){
        var updateData={id:this.data?.data?.id, rfid:this.data?.rfid}
        this.transactionService.updatejobworkorderrfid(updateData).subscribe((res)=>{
          if(res.isSuccess){
            this.activeModal.close({isSuccess:true,response:this.data});
            this.toaster.success(res.message);
          }else this.toaster.error(res.message);
        })
      }
      else{
        var updateData={id:this.data?.params?.data?.id, rfid:this.data?.rfid}
        this.saleOrderProcessService.updatesaleorderrfidcode(updateData).subscribe((res)=>{
          if(res.isSuccess){
            this.activeModal.close({isSuccess:true,response:this.data});
            this.toaster.success(res.message);
          }else this.toaster.error(res.message);
        })
      }
    }
    dismiss(){
      this.activeModal.close({isSuccess:false})
    }
    ngOnDestroy(): void {
      this.hubConnection.stop().catch
      ((err) => 
      console.error(err.toString()
      ));
    }
}
