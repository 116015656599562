import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { catchError, Observable, Subject, tap } from 'rxjs';
import { ResponseModel } from '../../../shared/models/response-model.model';
import { CampaignMappingModel, InsertWhatsAppCampaignLedgerMappingRequestModel, InsertWhatsAppCampaignRequestModel } from './whatsapp/insert-whatsapp-campaign/insert-whatsapp-campaign.model';
import { ContactGroupsRequestModel, InsertUpdateRequestModel } from './contact-groups/contact-groups.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SettingsService } from '../../Settings/settings.service';
import { CampaignManagerStatus, StorageName } from 'src/app/shared/enum/common-enum';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetallEmailRequestModel, GetallWhatsappInsertRequestModel, GetallWhatsappRequestModel, InsertEmailRequestModel } from './crm.model';
import { EventEmitter } from 'stream';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  public previouscellClickedId: number = null;
  campaigngroupLedgerMappingModel = new InsertWhatsAppCampaignLedgerMappingRequestModel();
  constructor(public service: DataService,public storageService : StorageService, public settingsService : SettingsService ,public toastr?: ToastrService , public ngbModal? : NgbModal) { }

  private taskInProgress$ = new Subject<boolean>();
  public activePopUpDismiss = new Subject<boolean>();

  getTaskStatus(): Observable<boolean> {
    return this.taskInProgress$.asObservable();
  }

  startBackgroundTask(requestAcountLedgerList, message, path, campaignGroupId) {
    this.taskInProgress$.next(true);
  
    const campaignMappingModel = []; // To store all responses and errors
  
    const tasks = requestAcountLedgerList?.map((element) => {
      const whatsAppSecretKey = this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).secretKey;
  
      if (!whatsAppSecretKey) {
        return Promise.resolve({ error: 'Secret key missing', element }); // Handle missing secret key
      }
  
      const phoneNo = element?.contactInfo?.replace('-', '');
      const isGroup = false;
      const isNewsletter = false;
  
      if (path) {
        const filename = 'Invoice';
        const caption = message;
        const body = {
          phone : phoneNo,
          isGroup : isGroup,
          isNewsletter : isNewsletter,
          filename : filename,
          caption : caption,
          path : path.trim(),
        };
  
        return this.settingsService.whatsAppIntegrationSendImage(whatsAppSecretKey, body)
          .pipe(catchError((err) => [{ status: 'failed', element }]))
          .toPromise();
      } else {
        const body = {
          phone : phoneNo,
          isGroup : isGroup,
          isNewsletter : isNewsletter,
          message : message,
        };
  
        return this.settingsService.whatsAppIntegrationSendMessage(whatsAppSecretKey, body)
          .pipe(catchError((err) => [{ status: 'failed', element }]))
          .toPromise();
      }
    });
  
    Promise.allSettled(tasks) // Collect both resolved and rejected promises
      .then((responses) => {
        responses.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            const ledgerInfo =  structuredClone(requestAcountLedgerList[index]);
            ledgerInfo.statusId = result.value?.status == 'success' ? CampaignManagerStatus.Completed : CampaignManagerStatus.Failed;
            ledgerInfo.statusMessage = result.value?.status;
            campaignMappingModel.push(ledgerInfo);
            // this.ngbModal.dismissAll();
            this.activePopUpDismiss.next(true);
          } 
          
          // else {
          //   results.push({ error: result.reason, element: requestAcountLedgerList[index] });
          // }
        });
  
      })
      .catch((error) => {
        console.error('Unexpected error:', error);
      })
      .finally(() => {
        this.taskInProgress$.next(false);
    
        if (campaignGroupId) {
          const insertWhatsAppCampaignLedgerMappingRequestModel = new InsertWhatsAppCampaignLedgerMappingRequestModel();
          insertWhatsAppCampaignLedgerMappingRequestModel.campaignMappingModel = campaignMappingModel;
          insertWhatsAppCampaignLedgerMappingRequestModel.campaignGroupId = campaignGroupId;
          this.insertWhatsAppCampaignLedgerMappingList(insertWhatsAppCampaignLedgerMappingRequestModel).subscribe({
            next: (res) => {
              if (res?.isSuccess) this.toastr.success(res?.message)
            }, error: (err) => {
            }
          });
        }
      });
  }
  

  // *************insert WhatsAppCampaign **************
  insertWhatsAppCampaignList(insertWhatsAppCampaignRequestModel:InsertWhatsAppCampaignRequestModel):Observable<ResponseModel>{
    let url = "api/app/whatsappcampaign/insertwhatsappcampaign";
    const json = JSON.stringify(insertWhatsAppCampaignRequestModel);
    return this.service.post(url,insertWhatsAppCampaignRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************insert WhatsAppCampaign Ledger Mapping **************
  insertWhatsAppCampaignLedgerMappingList(insertWhatsAppCampaignLedgerMappingRequestModel:InsertWhatsAppCampaignLedgerMappingRequestModel):Observable<ResponseModel>{
    let url = "api/app/whatsappcampaign/insertwhatsappcampaignledgermapping";
    const json = JSON.stringify(insertWhatsAppCampaignLedgerMappingRequestModel);
    return this.service.post(url,insertWhatsAppCampaignLedgerMappingRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

 // *************getByid WhatsAppCampaign **************
  getWhatsAppCampaignById(id){
    let url = `api/app/whatsappcampaign/getwhatsappcampaignbyid?Id=${id}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
      if(response.isSuccess == true)
        return response;
        })
      );
  }

  blockUnblockContactDetails(data:any):Observable<ResponseModel>{
    let url = "api/app/accountledger/blockorunblockaccountlegdercontactdetails";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************Get All Contact Group List WhatsApp Campaign **************
  getallcontactgrouplist(data:any):Observable<ResponseModel>{
    let url = "api/app/contactgroup/getallcontactgrouplist";
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
      if(response.isSuccess == true)
        return response;
        })
      );
    }

    getLedgerByContactGroupsId1(Id:number): Observable<ResponseModel> {
      let url = "api/app/contactgroup/getallledgerbycampiagngroupid";
      const json = JSON.stringify(Id);
      return this.service.post(url, true ,false, false).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    getLedgerByContactGroupsId(contactGroupsRequestModel: ContactGroupsRequestModel): Observable<ResponseModel> {
      let url = "api/app/contactgroup/getallledgerbycampaigngroupid";
      const json = JSON.stringify(contactGroupsRequestModel);
      return this.service.post(url, json, true, false, false).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    insertUpdateContactGtoup(insertinsertUpdateRequestModel:InsertUpdateRequestModel):Observable<ResponseModel>{
      let url = "api/app/contactgroup/insertupdatecontactgroup";
      const json = JSON.stringify(insertinsertUpdateRequestModel);
      return this.service.post(url,insertinsertUpdateRequestModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    deleteContact(contactGroupsRequestModel: ContactGroupsRequestModel): Observable<ResponseModel> {
      let url = "api/app/contactgroup/deletecontactgroup";
      const json = JSON.stringify(contactGroupsRequestModel);
      return this.service.post(url, json, true, false, false).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // import file
    getSampleFileByPageName(data):Observable<ResponseModel>{
      let url = "api/app/samplefile/getsamplefilebypagename";
      const json =JSON.stringify(data)
      return this.service.post(url, data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // import upload file api
    uploadFileAccountLedgerFromCampaignManager(file:any,id:any,groupName:string,isActive):Observable<ResponseModel>{
      let url = `api/app/accountledger/uploadaccountledgerfromcampaignmanager/${id}/${groupName}/${isActive}`;
      const formData = new FormData();
      formData.append('file', file);
      return this.service.postWithFile(url,formData, true,null,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    insertEmail(data:InsertEmailRequestModel): Observable<ResponseModel> {
      let url = "api/app/emailcampaign/insertemailcampaign";
      const json = JSON.stringify(data);
      return this.service.post(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    getEmail(id): Observable<ResponseModel> {
      let url = `api/app/emailcampaign/getemailcampaignbyid?Id=${id}`;
      return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
      if(response.isSuccess == true)
        return response;
        })
      );
  }

 /******Campaign Service Start*******/
 getallCampaignWhatsapp(data:GetallWhatsappRequestModel): Observable<ResponseModel> {
  let url = "api/app/whatsappcampaign/getallwhatsappcampaign";
  const json = JSON.stringify(data);
  return this.service.post(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getallCampaignEmail(data:GetallEmailRequestModel): Observable<ResponseModel> {
  let url = "api/app/emailcampaign/getallemailcampaign";
  const json = JSON.stringify(data);
  return this.service.post(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
insertwhatsapp(data:GetallWhatsappInsertRequestModel): Observable<ResponseModel> {
  let url = "api/app/whatsappcampaign/insertwhatsappcampaign";
  const json = JSON.stringify(data);
  return this.service.post(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getbyidwhatsapp(data): Observable<ResponseModel> {
  let url = "app/whatsappcampaign/getwhatsappcampaignbyid";
  const json = JSON.stringify(data);
  return this.service.get(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// *************delete Campaign Service  **************
deleteWhatAppService(id:number):Observable<ResponseModel>{
let url = `api/app/whatsappcampaign/deletewhatsappcampaign?Id=${id}`;
return this.service.delete(url, true).pipe<ResponseModel>(
  tap((response: any) => {
    return response;
  })
);
}
deleteEmailService(id:number):Observable<ResponseModel>{
let url = `api/app/emailcampaign/deleteemailcampaign?Id=${id}`;
return this.service.delete(url, true).pipe<ResponseModel>(
  tap((response: any) => {
    return response;
  })
);
}


///////////////get all whatsappmarketing////////////
getAllwhatsapptemplate(data ,anonymous?:any){
let url = "api/app/marketingtemplate/getallmarketingtemplate"
if (anonymous) {
url = anonymous;
}
return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
return response;
})
);
};
/////////////////////////////////////////

 /******Campaign Service End*******/
 getMarketingTemplateTypeList():Observable<ResponseModel>{
  let url:any ="api/app/marketingtemplate/getmarketingtemplatetypes";
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

}
