import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-open-image-popup',
  templateUrl: './open-image-popup.component.html',
  styleUrls: ['./open-image-popup.component.scss']
})
export class OpenImagePopupComponent extends CommonService implements OnInit{

  carouselWidth: number = 396;
  carouselHeight: number = 350;

  @Input() selectedIndex: number;
  @Input() data: any={};
  isBase64Resource: boolean=false;
  constructor(public activeModal: NgbActiveModal,public renderer : Renderer2,){
    super(renderer);
  }
  jewelryCatalogue = [];
  ngOnInit(): void {
    console.log(this.data);
    
    console.log(this.data?.jewelryCatalogue);
    const obj = this.data?.jewelryCatalogue[0]??this.data?.jewelryCatalogue[0];
    const isCheck = "base64Resource" in obj;
    if(isCheck){
      this.isBase64Resource = true
    }else{
      this.isBase64Resource = false
    }

   }

   isMp4File(url: string): boolean {
    return url?.includes('.mp4');
  }
   
}
